import BaseView from "../baseview";
import {i18nHelper} from "../../modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, IDynfieldLayoutPage} from "../../openapi";
import DynfieldsLayoutEditModal from "views/dynfields-settings/dynfields-layout-edit-modal";
import DynfieldsSingleLayoutEditModal from "views/dynfields-settings/dynfields-layout-single-attribute-edit-modal";
import DynfieldsSingleLayoutAttributesModal from "views/dynfields-settings/dynfields-single-layout-attributes-modal";
//import DynfieldLayoutEditModal from "views/dynfieldLayouts-settings/dynfieldLayout-edit-modal";

export default class DynfieldLayoutsSettings extends BaseView {

    idPrefix = "dynflsw";
    title:string;
    dynfieldLayoutEditModal:DynfieldsLayoutEditModal;
    dynfieldsSingleLayoutEditModal:DynfieldsSingleLayoutEditModal;
    dynfieldsSingleLayoutAttributesModal:DynfieldsSingleLayoutAttributesModal
    config():webix.ui.layoutConfig {
        this.title = i18nHelper.getTranslation("Dynfield Layouts Settings");
        return this.getCoreLayout();
    }

    init():void {
        const container = webix.$$(`${this.idPrefix}DynfieldLayoutsSettingsContainer`) as webix.ui.layout;
        container.addView(this.getDatatable());
    }

    getCoreLayout():webix.ui.scrollviewConfig {
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                padding:13,
                css:"cog-content",
                width:0,
                height:0,
                autoWidth:true,
                rows: [
                    this.getTopButtons(),
                    this.getHeader(),
                    {
                        view:"layout",
                        id:`${this.idPrefix}MainContainer`,
                        height:0,
                        gravity:1,
                        minHeight:300,
                        css:"cog-box-content",
                        rows:[
                            {
                                view:"layout",
                                id:`${this.idPrefix}DynfieldLayoutsSettingsContainer`,
                                rows:[]
                            }
                        ]
                    }
                ]
            }
        }
    }

    getHeader():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:44,
            cols: [
                {
                    view: "template",
                    type: "header",
                    css: "cog-box-header-no-border",
                    template: this.title,
                    borderless: true
                },
            ]
        }
    }

    getTopButtons():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:40,
            gravity:1,
            css:{
                "margin":"2px 0"
            },
            cols:[
                {
                    view:"spacer",
                    width:0,
                },
                {
                    view:"button",
                    width:190,
                    inputWidth:190,
                    css:"cog-button-big",
                    label:i18nHelper.getTranslation("New dynfield layout"),
                    click:() => {
                        this.openDynfieldLayoutDetailsModal("");
                    }
                }
            ]
        }
    }

    getDatatable():webix.ui.datatableConfig {
        this.showLoader();
        return {
            view: "datatable",
            id: `${this.idPrefix}DynfieldLayoutsListTable`,
            resizeRow:false,
            hover:"cog-datatable-hover",
            tooltip:true,
            columns:[
                {
                    id:"page_url",
                    fillspace:true,
                    sort:"text",
                    header: [i18nHelper.getTranslation("Page URL"),  { content:"textFilter"}],
                },
                {
                    id:"object_type",
                    fillspace:true,
                    sort:"text",
                    header: [i18nHelper.getTranslation("Object type"),  { content:"richSelectFilter" }],
                },
                {
                    width:42,
                    id:"action-details",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Edit dynfield layout"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-magnifying-glass'></span>`;
                    }
                },
                {
                    width:42,
                    id:"action-edit",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Edit layout attributes"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-table-layout'></span>`;
                    }
                },
                {
                    width:42,
                    id:"action-delete",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Delete dynfield layout"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-trash'></span>`;
                    }
                }
            ],
            url:() => {
                return CognovisRestDynamicService.getDynfieldLayoutPages({})
                    .then(layouts => {
                        this.hideLoader();
                        return layouts;
                    });
            },
            on: {
                onItemClick:(row) => {
                    const table = webix.$$(`${this.idPrefix}DynfieldLayoutsListTable`) as webix.ui.datatable;
                    const item:IDynfieldLayoutPage = table.getItem(row);
                    switch(row.column) {
                        case "action-details":
                            this.openDynfieldLayoutDetailsModal(item.page_url);
                            break;
                        case "action-edit":
                            this.openDynfieldsSingleLayoutEditModal(item)
                            break;
                        case "action-delete":
                            this.attemptToDeleteDynfieldLayout(item);
                            break;
                    }
                }
            }
        }
    }

    openDynfieldLayoutDetailsModal(dynfieldPageUrl:string):void {
        if(!this.dynfieldLayoutEditModal) {
            this.dynfieldLayoutEditModal = this.ui(DynfieldsLayoutEditModal) as DynfieldsLayoutEditModal;
        }
        if(dynfieldPageUrl) {
            this.dynfieldLayoutEditModal.openModal(dynfieldPageUrl, () => {
                this.refreshTable();
            });
        } else {
            this.dynfieldLayoutEditModal.openModal("", () => {
                this.refreshTable();
            });
        }
    }

    openDynfieldsSingleLayoutEditModal(layout:IDynfieldLayoutPage):void {
        if(!this.dynfieldsSingleLayoutAttributesModal) {
            this.dynfieldsSingleLayoutAttributesModal = this.ui(DynfieldsSingleLayoutAttributesModal) as DynfieldsSingleLayoutAttributesModal;
        }
        if(layout) {
            this.dynfieldsSingleLayoutAttributesModal.openModal(layout, () => {
                this.refreshTable();
            });
        }
    }

    attemptToDeleteDynfieldLayout(dynfieldLayout:IDynfieldLayoutPage):void {
        const declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_delete_dynfield_layout?");
        webix.confirm({
            title: dynfieldLayout.page_url,
            type: "confirm-warning",
            text: declineOrAccept,
            width: 480
        })
            .then(() => {
                this.deleteDynfieldLayout(dynfieldLayout)
            });
    }

    deleteDynfieldLayout(dynfieldLayout:IDynfieldLayoutPage):void {
        CognovisRestDynamicService.deleteDynfieldLayouts({
            pageUrl:dynfieldLayout.page_url
        }).then(() => {
            this.refreshTable();
        });
    }

    refreshTable():void {
        const table = webix.$$(`${this.idPrefix}DynfieldLayoutsListTable`) as webix.ui.datatable;
        if(table) {
            this.showLoader();
            table.clearAll();
            CognovisRestDynamicService.getDynfieldLayoutPages({})
                .then(layouts => {
                    this.hideLoader();
                    table.define("data", layouts);
                    table.refresh();
                });
        }
    }

    showLoader():void {
        const container = webix.$$(`${this.idPrefix}DynfieldLayoutsSettingsContainer`) as webix.ui.layout & {showProgress?:(text:string) => void};
        webix.extend(container, webix.ProgressBar);
        container.showProgress(i18nHelper.getTranslation("Please_wait"));
    }

    hideLoader():void {
        const container = webix.$$(`${this.idPrefix}DynfieldLayoutsSettingsContainer`) as webix.ui.layout & {hideProgress?:() => void};
        container.hideProgress();
    }
}
