import {CognovisRestInvoiceService, IntranetCostStatus } from '../../../sources/openapi';
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import BaseView from '../baseview';
import { PriceHelper } from '../../../sources/modules/price-helpers/price-helpers';
import { CognovisNavigator } from "../../modules/cognovis-navigator/cognovis-navigator";
import { IJetApp } from 'webix-jet';
import CognovisProfile from '../../../sources/modules/cognovis-profile/cognovis-profile';
import { UserProfile } from '../../../sources/modules/cognovis-profile/profiles-types';
import { config } from '../../../sources/config';
import SendMailModal from 'views/modals/send-mail-modal';
import LinkedObjectsModal from 'views/modals/linked-objects-modal';

export default class CustomerView extends BaseView {

    sendInvoiceByEmailModal:SendMailModal;
    isUserPoOrPm = false;
    projectId:number;
    refreshFunction:()=>void

    constructor(app:IJetApp, projectId:number, refreshFunction:()=>void) {
        super(app, "", {});
        this.projectId = projectId;
        this.refreshFunction = refreshFunction;
        if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
            this.isUserPoOrPm = true;
        }
    }
    
    config(): webix.ui.layoutConfig {
        return {
            view: "layout",
            rows: [
                { view: "template", type: "header", css:"cog-box-header", template: "Customer"},
                {
                    view: "datatable",
                    tooltip:true,
                    id:"customer-costs-table",
                    autoheight:true,
                    minHeight:200,
                    columns: [
                        { 
                            id: "customer", 
                            header: i18nHelper.getTranslation("Customer"), 
                            fillspace:true,
                            maxWidth:350,
                            template: function (obj) {
                                if(obj.quote.company?.name) {
                                    return `<span class='text-link-no-effect'>${obj.quote?.company?.name}</span>`;
                                } else if(obj.invoice?.company?.name) {
                                    return `<span class='text-link-no-effect'>${obj.invoice?.company?.name}</span>`;
                                } else {
                                    return "";
                                }
                            }
                        },
                        { 
                            id: "quote",
                            css: "webix-datatable-link", 
                            width:150, 
                            header: i18nHelper.getTranslation("Quote"),
                            template: function (obj) {
                                if(obj.quote?.invoice?.name) {
                                    return `${obj.quote?.invoice?.name}`;
                                } else {
                                    return "";
                                }
                            }
                        },
                        //box icon
                        {
                            width: 30,
                            template: function (obj) {
                                if(obj.quote?.invoice?.id) {
                                    return `<span class='fas fa-square-full' webix_tooltip='${obj.quote?.cost_status.name}' style='color:${obj.quote?.cost_status?.icon_or_color};'></span>`;
                                } else {
                                    return "";
                                }
                            }

                        },
                        {
                            id: "quote_accept",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            hidden:!this.isUserPoOrPm,
                            width: 30,
                            template: function (obj) {
                                if(obj.quote?.invoice?.id) {
                                    const acceptableQuoteStatus = [IntranetCostStatus.SEND,IntranetCostStatus.CREATED,IntranetCostStatus.OUTSTANDING,IntranetCostStatus.REQUESTED]
                                    if(acceptableQuoteStatus.includes(obj.quote.cost_status.id)) {
                                        return `<span id="acceptQuoteIcon${obj.quote.invoice.id}" class='fa fa-user-check blue-fa-icon'></span>`;
                                    } else {
                                        return `<span id="acceptQuoteIcon${obj.quote.invoice.id}" style="opacity:0.4; cursor:not-allowed;" class='fa fa-user-check blue-fa-icon'></span>`;
                                    }
                                } else {
                                    return "";
                                }
                            }
                        },
                        //edit button
                        // {
                        //     id: "quote_edit",
                        //     header: i18nHelper.getTranslation("_empty_"),
                        //     css: "cog-icon",
                        //     hidden:!this.isUserPoOrPm,
                        //     width: 30,
                        //     template: function (obj) {
                        //         if(obj.quote?.invoice?.id) {
                        //             return `<span class='fa fa-edit blue-fa-icon'></span>`;
                        //         } else {
                        //             return "";
                        //         }
                        //     }

                        // },
                        //send button
                        {
                            id: "quote_send",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            hidden:!this.isUserPoOrPm,
                            width: 30,
                            template: function (obj) {
                                if(obj.quote?.invoice?.id) {
                                    return `<span class='fa fa-paper-plane blue-fa-icon'></span>`;
                                } else {
                                    return "";
                                }
                            }
                        },
                        {
                            id:"quote_linked_objects",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            tooltip:i18nHelper.getTranslation("Show_linked_objects"),
                            width:32,
                            template: function (obj) {
                                if(obj.quote?.invoice?.id) {
                                    let disabledCss = "disabled";
                                    if(obj.quote?.linked_objects_p) {
                                        disabledCss = "";
                                    }
                                    return `<span class='fa-solid fa-link blue-fa-icon ${disabledCss}'></span>`;
                                } else {
                                    return "";
                                }
                            },                        
                        },
                        {
                            id:"quote_subproject",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            width:30,
                            tooltip: function (obj) {
                                if (obj.quote?.project) {
                                    return obj.quote?.project.name
                                } else {
                                    return ""
                                }
                            },
                            projectId: this.projectId,
                            template: function (obj) {
                                if(obj.quote?.invoice) {
                                    if(obj.quote?.project.id != this.projectId) {
                                        return `<span class='fa-solid fa-diagram-subtask'></span>`;
                                    } else {
                                        return "";
                                    }
                                } else {
                                    return ""
                                }
                            },                     
                        },
                        { 
                            id: "quote_amount", 
                            header: {text:i18nHelper.getTranslation("Amount"),css:"price-display"},
                            width:70,
                            css:"price-display",
                            template:(obj) => {
                                if(obj.quote?.amount) {
                                    return PriceHelper.formatPrice(obj.quote?.amount, obj.quote?.currency);
                                } else {
                                    return "";
                                }
                            }
                        },
                        { 
                            id: "quote_date", 
                            width:90,
                            header: i18nHelper.getTranslation("Date"),
                            template:(obj) => {
                            if(obj.quote?.effective_date) {
                                const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
                                const formattedDate = myFormat(new Date(obj.quote?.effective_date));
                                return formattedDate
                            } else {
                                return ""
                            }
                        } },
                        {
                            id:"spacer1",
                            header:" ",
                            fillspace:true
                        },
                        { 
                            id: "invoice", 
                            header: i18nHelper.getTranslation("Invoice"), 
                            css: "webix-datatable-link", 
                            width:130, 
                            template:(obj) => {
                                if(obj.invoice?.invoice?.name) {
                                    return obj.invoice.invoice.name;
                                } else {
                                    return "";
                                }
                            }
                        },
                        //box icon
                        {
                            width: 30,
                            template: function (obj) {
                                if(obj.invoice?.cost_status?.id) {
                                    return `<span class='fas fa-square-full' webix_tooltip='${obj.invoice?.cost_status.name}' style='color:${obj.invoice?.cost_status?.icon_or_color};'></span>`;
                                } else {
                                    return "";
                                }
                            }

                        },
                        {
                            id: "invoice_send",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            hidden:!this.isUserPoOrPm,
                            width: 30,
                            template: function (obj) {
                                if(obj.invoice?.invoice) {
                                    return `<span class='fa fa-paper-plane blue-fa-icon'></span>`;
                                } else {
                                    return "";
                                }
                            },
                        },
                        {
                            id:"invoice_linked_objects",
                            header: i18nHelper.getTranslation("_empty_"),
                            tooltip: function(obj) {
                                if(obj.invoice?.linked_objects_p) {
                                    return i18nHelper.getTranslation("Show_linked_objects")
                                } else {
                                    return ""
                                }
                            },
                            css: "cog-icon",
                            width:32,
                            template: function (obj) {
                                if(obj.invoice?.invoice) {
                                    if(obj.invoice?.linked_objects_p) {
                                        return `<span class='fa fa-link blue-fa-icon'></span>`;    
                                    } else {
                                        return ""
                                    }
                                } else {
                                    return "";
                                }
                            },                        
                        },
                        {
                            id:"invoice_subproject",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            width:30,
                            tooltip: function (obj) {
                                if (obj.invoice?.project) {
                                    return obj.invoice?.project.name
                                } else {
                                    return ""
                                }
                            },
                            projectId: this.projectId,
                            template: function (obj) {
                                if(obj.invoice?.invoice) {
                                    if(obj.invoice?.project.id != this.projectId) {
                                        return `<span class='fa-solid fa-diagram-subtask'></span>`;
                                    } else {
                                        return "";
                                    }
                                } else {
                                    return ""
                                }
                            },                        
                        },
                        { 
                            id: "invoice_fee", 
                            header: {text:i18nHelper.getTranslation("Fee"),css:"price-display"},
                            width:100,
                            css:"price-display",
                            template:(obj) => {
                            if(obj.invoice?.amount) {
                                return PriceHelper.formatPrice(obj.invoice?.amount, obj.invoice.currency);
                            } else {
                                return "";
                            }
                        }},
                        { 
                            id: "invoice_date", 
                            header: i18nHelper.getTranslation("Date"),
                            width:90,
                            template:(obj) => {
                            if(obj.invoice?.effective_date) {
                                const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
                                const formattedDate = myFormat(new Date(obj.invoice?.effective_date));
                                return formattedDate
                            } else {
                                return ""
                            }
                        }},

                    ],
                    onClick:{
                        "fa-paper-plane":function(event,row) {
                            if(!this.$scope.sendInvoiceByEmailModal) {
                                this.$scope.sendInvoiceByEmailModal = this.$scope.ui(SendMailModal) as SendMailModal;
                            }
                            const item = this.getItem(row);
                            if(row.column === "quote_send") {
                                const invoiceId = item.quote?.invoice?.id;
                                if(invoiceId) {
                                    this.$scope.sendInvoiceByEmailModal.setCustomAfterSendAction(this.$scope.refreshFunction);
                                    this.$scope.sendInvoiceByEmailModal.openModal(invoiceId); 
                                }                 
                            }
                            if(row.column === "invoice_send") {
                                const invoiceId = item.invoice?.invoice?.id;
                                if(invoiceId) {
                                    this.$scope.sendInvoiceByEmailModal.setCustomAfterSendAction(this.$scope.refreshFunction);
                                    this.$scope.sendInvoiceByEmailModal.openModal(invoiceId); 
                                }  
                            }
                        },
                    },
                    on: {
                        onItemClick:function(row, event)  {
                            const item = this.getItem(row);
                            if(row.column === "customer") {
                                if(item.quote?.company?.id) {
                                    CognovisNavigator.goToObjectDetails(item.quote?.company?.id, "im_company");
                                }
                                if(item.invoice?.company?.id) {
                                    CognovisNavigator.goToObjectDetails(item.invoice?.company?.id, "im_company");
                                }
                            }
                            if(row.column === "quote") {
                                //window.location.href = `${config.restUrl}/invoice_document?invoice_id=${item.quote?.invoice?.id}&preview_p=false&type=pdf&api_key=${bearerToken}`;
                                CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${item.quote?.invoice?.id}&project_id=${this.$scope.projectId}`);
                            }
                            if(row.column === "invoice" && item.invoice?.invoice?.id) {
                                CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${item.invoice?.invoice?.id}&project_id=${this.$scope.projectId}`);
                                //window.location.href = `${config.restUrl}/invoice_document?invoice_id=${item.invoice?.invoice?.id}&preview_p=false&type=pdf&api_key=${bearerToken}`;
                            }
                            if(row.column === "quote_accept") {
                                if(item.quote?.cost_status.id === IntranetCostStatus.CREATED) {
                                    CognovisRestInvoiceService.putInvoice({
                                        invoiceId:item.quote?.invoice?.id,
                                        requestBody: {
                                            cost_status_id:IntranetCostStatus.ACCEPTED,
                                            currency_iso:"EUR",
                                            company_id:item.quote?.company.id,
                                        }
                                    })
                                    .then(() => {
                                        const bearerToken = webix.storage.local.get("cognovis_bearer_token");
                                        window.location.href = `${config.restUrl}/invoice_document?invoice_id=${item.quote?.invoice?.id}&preview_p=false&type=pdf&api_key=${bearerToken}`;
                                        const element = document.getElementById(`acceptQuoteIcon${item.quote.invoice.id}`);
                                        element.style.opacity = "0.4";

                                    });
                                }
                            }
                            if(row.column === "quote_linked_objects" && item.quote?.linked_objects_p) {
                                const quoteId = item.quote?.invoice?.id;
                                LinkedObjectsModal.show(quoteId, (event.clientX-event.layerX),  (event.clientY-event.layerY));
                            }
                            if(row.column === "invoice_linked_objects" && item.invoice?.linked_objects_p) {
                                const invoiceId = item.invoice?.invoice?.id;
                                LinkedObjectsModal.show(invoiceId, (event.clientX-event.layerX),  (event.clientY-event.layerY));
                            }
                            if(row.column === "quote_subproject" && item.quote?.project.id) {
                                const subProjectId = item.quote?.project?.id;
                                //CognovisNavigator.navigateTo(`/main/financial-overview.financial-overview?project_id=${subProjectId}`);
                                window.location.href = `${config.portalUrl}/#!/financial-overview?project_id=${subProjectId}`;
                                window.location.reload();
                            }
                            if(row.column === "invoice_subproject" && item.invoice?.project.id) {
                                const subProjectId = item.invoice?.project?.id;
                                // CognovisNavigator.navigateTo(`/main/financial-overview.financial-overview?project_id=${subProjectId}`);
                                window.location.href = `${config.portalUrl}/#!/financial-overview?project_id=${subProjectId}`;
                                window.location.reload();
                            }
                        }
                    },
                    data:[]
                }
            ]
        }
    }

}