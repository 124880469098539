/* eslint-disable @typescript-eslint/no-unused-vars */
import { config } from "../../config";

/** @deprecated use OpenApi instead */
export default class CognovisAjax {
  static get(endpoint: string, additionlParams?: { key: string; value: string } | unknown): Promise<ICognovisServerResponse> {
    if (this.ajaxPossible()) {
      const url = this.getUrl(endpoint);
      return webix.ajax().get(url, additionlParams);
    } else {
      return Promise.reject("Ajax not executed: missing credentials");
    }
  }

  static post(endpoint: string, additionlParams?: { key: string; value: string } | unknown): Promise<ICognovisServerResponse> {
    if (this.ajaxPossible()) {
      const url = this.getUrl(endpoint);
      //const additionlParamsAsString = new URLSearchParams(additionlParams).toString();
      return webix
        .ajax()
        .headers({
          "Content-type": "application/json",
        })
        .post(url, additionlParams);
    } else {
      return Promise.reject("Ajax not executed: missing credentials");
    }
  }

  static put(endpoint: string): Promise<ICognovisServerResponse> {
    if (this.ajaxPossible()) {
      const url = this.restUrl + endpoint + this.getUrlCredentials();
      return webix.ajax().put(url);
    } else {
      return Promise.reject("Ajax not executed: missing credentials");
    }
  }

  static delete(endpoint: string, additionlParams?: { key: string; value: string } | unknown): Promise<ICognovisServerResponse> {
    if (this.ajaxPossible()) {
      const url = this.restUrl + endpoint + this.getUrlCredentials();
      return webix.ajax().del(url);
    } else {
      return Promise.reject("Ajax not executed: missing credentials");
    }
  }

  static getUrl(endpoint: string, additionlParams?: unknown, forceLoginInfo?: boolean): string {
    let url = this.restUrl + endpoint + this.getUrlCredentials(forceLoginInfo);
    if (additionlParams) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const additionlParamsAsString = new URLSearchParams(additionlParams as any ).toString();
      url = this.restUrl + endpoint + this.getUrlCredentials(forceLoginInfo) + "&" + additionlParamsAsString;
    }
    return url;
  }
  /** @deprecated use OpenApi instead */
  static getOptions<T>(idColumn: string, valueColumn: string, url: string, additionlParams?: unknown, rootKey = "data", additionalKeys: string[] = []): Promise<T[]>{
    // eslint-disable-next-line deprecation/deprecation
    return CognovisAjax.get(url, additionlParams)
    .then(res => {
      const data = res.json()[rootKey.toString()];
      const newArr:unknown[] = []
      let index = 0;
      data.forEach(user => {
        newArr.push({
          id: user[idColumn.toString()],
          value: user[valueColumn.toString()],
        });
        additionalKeys.forEach(key => {
          newArr[index.toString()][key.toString()] = user[key.toString()];
        });
        index++;
      });
      return newArr as T[]
    });
  }

  static getUrlCredentials(forceLoginInfo?: boolean, asObject?: boolean): ICognovisCredentials | string {
    const loginInfo = webix.storage.local.get("wjet_user");
    const swaInfo = webix.storage.session.get("swa_user");
    // We need to prepare let in case we would later return object instead of string
    let urlWithCredentials = "";
    const credentialsAsObject = { format: "json", user_id: "", auto_login: "" };
    if (swaInfo && !forceLoginInfo) {
      urlWithCredentials = "?format=json&user_id=" + swaInfo.user_id + "&auto_login=" + swaInfo.token;
      credentialsAsObject.user_id = swaInfo.user_id;
      credentialsAsObject.auto_login = swaInfo.token;
    } else {
      if (loginInfo) {
        urlWithCredentials = "?format=json&user_id=" + loginInfo.user_id + "&auto_login=" + loginInfo.token;
        credentialsAsObject.user_id = loginInfo.user_id;
        credentialsAsObject.auto_login = loginInfo.token;
      }
    }
    if (asObject) {
      return credentialsAsObject;
    } else {
      return urlWithCredentials;
    }
  }

  static ajaxPossible(): boolean {
    const credentials = this.getUrlCredentials();
    if (credentials) {
      return true;
    } else {
      return false;
    }
  }

  static isUserLogged(): boolean {
    const credentials = this.getUrlCredentials();
    if ((credentials as string).length > 0) {
      return false;
    } else {
      return false;
    }
  }
  private static restUrl: string = config.restUrl;
}
