import * as usermanager from "@xbs/usermanager";
import { CognovisPleaseWaitWindow } from "../../../services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { i18nHelper } from "../../../modules/i18n-helper/i18n-helper";
import UsersManagerBackend from "../users-manager-backend";

export default class UmCustomUsersGrid extends usermanager.views['users/grid'] {

    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
    
    config() {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const grid = super.config();
        grid.on.onAfterRender = () => {
            this.hideLoaderWhenLoaded(grid);
        }
        const columns = grid.columns;
        const emailColumn = columns.find(column => column.id === "email");
        if(emailColumn) {
            emailColumn.template = (obj) => {
                return `<a href="mailto:${obj.email}" class="text-link">${obj.email}</a>`
            }
        }
        return grid;
    }

    hideLoaderWhenLoaded(grid) {
        setTimeout(() => {
            const um = webix.$$(`umUsersManager`) as webix.ui.usermanager;
            const grid = um.queryView({view:"datatable"}, "all")[0];
            if(grid?.count && grid.count() > 0 && UsersManagerBackend.metaLoaded) {
                this.cognovisPleaseWaitWindow.hide();
            } else {
                this.hideLoaderWhenLoaded(grid);
            }
        },1000);
    }

}