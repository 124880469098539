import BaseView from "./baseview";
import {
    ITransProject,
    WebixPortalTranslationService,
} from "../../sources/openapi/index";
import { WebixHelpers } from "../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { IJetApp, IJetView } from "webix-jet";
import { CognovisNavigator } from "../../sources/modules/cognovis-navigator/cognovis-navigator";

export default class ProjectDetails extends BaseView {

    //showPMDetail: PMDetail;
    webixHelperClass: WebixHelpers;
    projectId:number;
    currentProject:ITransProject;

    constructor(app:IJetApp) {
        super(app, "", {});
        this.webixHelperClass = container.resolve(WebixHelpers);
    }

    urlChange() {
        const projectId = this.getParam("project_id", true);
        //const projectId = webix.storage.session.get("projectId");
        this.projectId = projectId;
        // We need to open left navigation in case it is not opened yet (e.g direct URL was used by user)
        // if(!this.leftNavigationService.projectViewMode) {
        //     this.leftNavigationService.openProjectViewMode(this.projectId);
        // }
        WebixPortalTranslationService.getTransProjects({
            projectId:projectId
        })
        .then((res) => {
            if(!res[0]) {   
                CognovisNavigator.navigateTo("/main/project-overview.projects");
            } else {
                const targetLanguagesIds = res[0].target_language.map(language => language.id);
                webix.storage.session.put("current_project_target_languages", targetLanguagesIds); 
            }
            const projectObj = res[0];
            this.currentProject = projectObj;
            this.app.callEvent("parent_class::project-data-loaded", [this.currentProject]);
            const sidebar = webix.$$("sidebarMainSidebar") as webix.ui.sidebar;
            //sidebar.$scope.openProjectDetails(res[0], "project-info.project-info");
            sidebar.$scope.openProject(res[0]);
            const targetLanguagesIds = projectObj.target_language.map(language => language.id);
            const sourceLanguageId = projectObj.source_language.id;
            webix.storage.session.put("current_project_source_language", sourceLanguageId);
            webix.storage.session.put("current_project_target_languages", targetLanguagesIds);
            if(this.app) {
                (this.app as IJetApp & {currentProject:ITransProject}).currentProject = projectObj;
            }
        });
    }

    getProjectId():number {
        return this.projectId;
    }

    getProject():ITransProject {
        return this.currentProject;
    }

    setProjectId(projectId:number):void {
        this.projectId = projectId;
    }


}
