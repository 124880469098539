import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import CognovisBasicPortlet from "../../modules/cognovis-basic-portlet";
import vacationsCreationModal from "./vacations-creation-modal";
import { CognovisRestAbsencesService, CognovisRestService, ICategory, IUserAbsence, } from "../../openapi";
import { UserProfile } from "../../modules/cognovis-profile/profiles-types";
import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { IJetApp } from "webix-jet";
import VacationsCreationModal from "./vacations-creation-modal";

export default class VacationsPortlet extends CognovisBasicPortlet {

    idPrefix = "ab";
    title:string;
    vacationsCreationModal:VacationsCreationModal;
    absencesTypes:ICategory[];
    currentUserId:number;
    currentUser:{id:number, value:string, name:string};
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

    constructor(app:IJetApp, user:{id:number, value:string, name:string}, portletId:number, title?:string) {
        super(app, {});
        this.portletId = portletId;
        this.currentUser = user;
        if(title) {
            this.title = title;
        } 
    }

    init():void {
        super.init();
        this.setHeaderTitle(this.title);
        CognovisRestService.getImCategory({categoryType:"Intranet Absence Type"})
        .then(absencesTypes => {
            this.absencesTypes = absencesTypes;
            this.components = [
                {
                    view:"layout",
                    minHeight:400,
                    cols:[
                        this.getAbsencesTable()
                    ]
                }
            ];
            this.insertComponents();
        });
    }

	getAbsencesTable():webix.ui.datatableConfig {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
		const table = {
            id:`${this.idPrefix}AbsencesTable`,
			view:"datatable",
			tooltip:true,
			localId:`${this.idPrefix}AbsencesTable`,
			columns:[
				{
					id:"absence_id",
					map:"#absence.id#",
					hidden:true
				},
				{
					id:"absence_name",
					header:[i18nHelper.getTranslation("Absence_name")],
					map:"#absence.name#",
					tooltip:false,
					fillspace:true,
					template:(obj) => {
						const properCategory = this.absencesTypes.find(absenceType => absenceType.category_id === obj.absence_type.id);
						if(properCategory && properCategory.aux_string2) {
							return `<span style='color:#${properCategory.aux_string2}'>${obj.absence_name}</span>`;
						} else {
							return obj.absence_name
						}
					}
				},
				{
					id:"start_date",
					map:"#start_date#",
					fillspace:true,
					tooltip:false,
					header:[i18nHelper.getTranslation("Absence_start_date")],
					template:(obj) => {
						const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
						const formattedDate = myFormat(new Date(obj.start_date));
						return formattedDate
					}
				},
				{
					id:"end_date",
					map:"#end_date#",
					fillspace:true,
					tooltip:false,
					header:[i18nHelper.getTranslation("Absence_end_date")],
					template:(obj) => {
						const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
						const formattedDate = myFormat(new Date(obj.end_date));
						return formattedDate
					}
				},
				{
					id:"duration",
					tooltip:false,
					map:"#duration#",
					header:[i18nHelper.getTranslation("Duration")],
				},
				{
					width:40,
					tooltip:(obj) => {
						if(obj.description.length > 0) {
							return obj.description
						} else {
							return ""
						}
					},
                    template:(obj) => {
						let cssClasses = `webix_icon fas fa-comment`;
						if(obj.description.length === 0) {
							cssClasses = cssClasses + ` greyedout`
						}
                        return `<span style='color:grey' class='${cssClasses}'></span>`;
                    }
				},
				{
					width:40,
					tooltip:false,
                    template:() => {
                        return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-trash'></span>`;
                    }
				}
			],
			onClick:{
                "fa-trash":(e,row) => {
					const table = this.$$(`${this.idPrefix}AbsencesTable`) as webix.ui.datatable;
                    const absence = table.getItem(row);
					this.attemptToDeleteAbsence(absence);
				},
			},
			url:() => 
				CognovisRestAbsencesService.getUserAbsence({ownerId:this.currentUser.id, groupId:UserProfile.EMPLOYEE})
				.then(absences => {
					return absences
				})
		}
		return table;
	}

	attemptToDeleteAbsence(absence:IUserAbsence):void {
		const confirmWarning = `${i18nHelper.getTranslation(`You_want_to_remove`)} ${absence.absence.name}?`;
		webix.confirm({
			title: i18nHelper.getTranslation(`any_are_you_sure_message`),
			type: "confirm-warning",
			text: confirmWarning,
			width: 480
		})
		.then(() => {
			this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
			CognovisRestAbsencesService.deleteUserAbsence({absenceId:absence.absence.id})
			.then(res => {
				this.cognovisPleaseWaitWindow.hide();
				if(res[0]["err_msg"]) {
					webix.alert({
						title: "Error",
						width:400,
						text:res[0]["err_msg"],
						type:"alert-error"
					});
				} else {
					this.refreshAbsencesTable();	
				}
			})
			.catch(err => {
				this.cognovisPleaseWaitWindow.hide();
				webix.alert({
					title: "Error",
					width:400,
					text: err,
					type:"alert-error"
				});
			});
		});
	}

	getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            height:44,
            css: "cog-box-header", 
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: i18nHelper.getTranslation("My_absences"), 
                    borderless: true 
                },
                { 
                    id:`${this.idPrefix}AddVacationButton`,
                    view: "button", 
                    type: "icon", 
                    icon: "wxi-plus", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true ,
                    click:() => {
						if(!this.vacationsCreationModal) {
							this.vacationsCreationModal = this.ui(vacationsCreationModal) as vacationsCreationModal;
						}
						this.vacationsCreationModal.openModal(this.currentUser, () => {
							this.refreshAbsencesTable();
						});
                    }
                }
            ]
        };
        return header;
	}
	
	refreshAbsencesTable():void {
		const table = this.$$(`${this.idPrefix}AbsencesTable`) as webix.ui.datatable;
		table.clearAll();
		CognovisRestAbsencesService.getUserAbsence({ownerId:this.currentUser.id, groupId:UserProfile.EMPLOYEE})
		.then(absences => {
			table.define("data", absences);
			table.refresh();
		});
    }
    
}