import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import { IntranetNotesType, IntranetNotesStatus } from "../../../sources/openapi";


export default class ObjectNotesModal extends CognovisBasicModal {

    idPrefix = "onm";
    mode:string;
    modalTitle:string;
    currentObject:unknown;
    noteToDisplay:string;
    saveCommentAction:(inputedNote:string, notyType?:IntranetNotesType, noteStatus?:IntranetNotesStatus) => void;
    editor:string;
    cognovisPleaseWaitWindow:CognovisPleaseWaitWindow;
    fieldsConfig:ICognovisFormFieldConfig[]= [
        {
            name:"note",
            hidden:false,
            editable:true
        },
        {
            name:"note_type",
            hidden:true,
            editable:true
        },
        {
            name:"note_status",
            hidden:true,
            editable:true
        }
    ];

    openNotesModal<WithNote>(mode:string, modalTitle:string, object:WithNote, noteToDisplay:string, editor = "cke5", fieldsConfig?:ICognovisFormFieldConfig[], saveCommentAction?:(inputedNote:string, notyType?:IntranetNotesType, noteStatus?:IntranetNotesStatus) => void):void {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.mode = mode;
        if(fieldsConfig && fieldsConfig.length > 0) {
            this.fieldsConfig = fieldsConfig;
        }
        this.modalTitle = modalTitle;
        this.editor = editor;
        const modal = (this.getRoot() as webix.ui.window);
        this.currentObject = object;
        this.noteToDisplay = noteToDisplay;
        this.saveCommentAction = saveCommentAction;
        modal.show(); 
        const actionButtons = this.getActionButtons();
        const modalContent = this.getContent();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, this.modalTitle);
        if(this.editor === "cke5") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$$("commentsCkEditor5") as any).getEditor(true)
            .then(editor => {
                if(this.mode === "file-note-readonly") {
                    editor.isReadOnly = true;
                } else {
                    editor.editing.view.focus();
                }
            });
        } else {
            const editor = this.$$("commentsCkEditor5") as webix.ui.richtext;
            editor.focus();
        }
    }

    closeModal():void {
        super.close();
    }

    getContent():webix.ui.layoutConfig {
        let modal = {
            view:"form",
            width:640,
            localId:`${this.idPrefix}ObjectNoteModal`,
            elements:[
                this.getNoteTypeSelector(),
                this.getNoteStatusSelector(),
                {
                    view:"textarea",
                    localId:"commentsCkEditor5",
                    name:"note",
                    height:360,
                    css:{},
                    mode:"document",
                    value:`${this.noteToDisplay}`,
                    placeholder:"No comment here yet...",
                }
            ]
        } 
        if(this.editor === "cke5") {
            modal = {
                view:"form",
                width:640,
                localId:`${this.idPrefix}ObjectNoteModal`,
                elements:[
                    this.getNoteTypeSelector(),
                    this.getNoteStatusSelector(),
                    {
                        view:"ckeditor5",
                        localId:"commentsCkEditor5",
                        name:"note",
                        height:360,
                        css:{
                            "overflow-y":"scroll"
                        },
                        mode:"document",
                        value:`${this.noteToDisplay}`,
                        placeholder:"No comment here yet...",
                    }
                ]
            }
        } 
        return modal;
    }

    getNoteTypeSelector():webix.ui.richselectConfig {
        return {
            view:"richselect",
            name:"note_type",
            placeholder:i18nHelper.getTranslation("Select note type"),
            hidden:this.fieldsConfig.find(field => field.name === "note_type")?.hidden,
            value:this.fieldsConfig.find(field => field.name === "note_type")?.value,
            disabled:!this.fieldsConfig.find(field => field.name === "note_type")?.editable,
            suggest: {
                body: {
                    url: () => 
                        CognovisCategory.getCategory(
                            "Intranet Notes Type"
                        ),
                },
            },
        }
    }

    getNoteStatusSelector():webix.ui.richselectConfig {
        return {
            view:"richselect",
            name:"note_status",
            placeholder:i18nHelper.getTranslation("Select note status"),
            hidden:this.fieldsConfig.find(field => field.name === "note_status")?.hidden,
            value:this.fieldsConfig.find(field => field.name === "note_status")?.value,
            disabled:!this.fieldsConfig.find(field => field.name === "note_status")?.editable,
            suggest: {
                body: {
                    url: () => 
                        CognovisCategory.getCategory(
                            "Intranet Notes Status"
                        ),
                },
            },
        }
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: (this.mode === "file-note-readonly" ?  `${i18nHelper.getTranslation(`Ok`)}`: `${i18nHelper.getTranslation(`Cancel`)}`),
                            align: "left",
                            click:() => {
                                super.hide();
                            }
                        },
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`save`)}`,
                            align: "right",
                            hidden:this.mode === "file-note-readonly",
                            click:() => {
                                const form = this.$$(`${this.idPrefix}ObjectNoteModal`) as webix.ui.form;
                                super.hide();
                                this.saveCommentAction(form.getValues().note, form.getValues().note_type, form.getValues().note_status);
                            }
                        }, 
                    ] 
                };
        return buttons;
    }


}