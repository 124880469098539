import {injectable} from "tsyringe";

@injectable()
export default class HtmlTemplateHelper {

    replaceParams(htmlTemplate:string, params:unknown):string {
        let newHtmlTemplate = htmlTemplate;
        const regexPattern = /[^{{}]+(?=}})/g;
        const matched = htmlTemplate.match(regexPattern);
        matched.forEach(matchedExpression => {
            newHtmlTemplate = newHtmlTemplate.replace("{{"+matchedExpression+"}}",params[matchedExpression.toString()]);
        });
        return newHtmlTemplate;
    }
}