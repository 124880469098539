import BaseView from '../baseview'
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { IInvoice} from '../../../sources/openapi';
import { PriceHelper } from '../../../sources/modules/price-helpers/price-helpers';
import { CognovisNavigator } from "../../modules/cognovis-navigator/cognovis-navigator";
import { IJetApp } from 'webix-jet';
import SendMailModal from 'views/modals/send-mail-modal';
import LinkedObjectsModal from 'views/modals/linked-objects-modal';
import { config } from '../../../sources/config';

export default class FreelancerView extends BaseView {

    freelancerFiancialDocuments:{
        purchaseOrder:IInvoice,
        creditNote:IInvoice
    }[];
    sendInvoiceByEmailModal:SendMailModal;
    refreshFunction:()=>void;
    projectId:number;

    constructor(app:IJetApp, projectId:number,refreshFunction:()=>void) {
        super(app, "", {});
        this.refreshFunction = refreshFunction;
        this.projectId = projectId;
    }

    config(): webix.ui.layoutConfig {
        return {
            view: "layout",
            rows: [
                { view: "template", type: "header", css:"cog-box-header", template: i18nHelper.getTranslation("Freelancer") },
                {
                    view: "datatable",
                    tooltip:true,
                    id:"freelancer-costs-table",
                    autoheight:true,
                    minHeight:200,
                    columns: [
                        { 
                            id: "freelancer", 
                            header:i18nHelper.getTranslation("Freelancer"), 
                            fillspace: true,
                            maxWidth:350,
                            template: function (obj) {
                                if(obj.purchaseOrder?.company?.name) {
                                    return `<span class='text-link-no-effect'>${obj.purchaseOrder?.company?.name}</span>`;
                                } else if(obj.creditNote?.company?.name) {
                                    return `<span class='text-link-no-effect'>${obj.creditNote?.company?.name}</span>`;
                                } else {
                                    return "";
                                }
                            }
                        },
                        { 
                            id: "purchase_order", 
                            css: "webix-datatable-link", 
                            translation_key: "Purchase_Order", 
                            header:i18nHelper.getTranslation("Purchase_Order"), 
                            width:130,
                            template: function (obj) {
                                if(obj.purchaseOrder?.invoice?.name) {
                                    return `${obj.purchaseOrder?.invoice?.name}`;
                                } else {
                                    return "";
                                }
                            }
                        },
                        {
                            width: 30,
                            template: function (obj) {
                                if(obj.purchaseOrder?.invoice?.id) {
                                    return `<span class='fas fa-square-full' webix_tooltip='${obj.purchaseOrder?.cost_status.name}' style='color:${obj.purchaseOrder?.cost_status?.icon_or_color};'></span>`;
                                } else {
                                    return "";
                                }
                            }

                        },
                        {
                            id:"send-button-po",
                            css: "cog-icon",
                            width: 40,
                            header: i18nHelper.getTranslation("_empty_"),
                            template: function (obj) {
                                if(obj.purchaseOrder?.invoice?.id ) {
                                    return `<span class='fa fa-paper-plane blue-fa-icon parent-doc'></span>`;
                                } else {
                                    return "";
                                }
                            },
                        },
                        {
                            id:"po_linked_objects",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            tooltip:i18nHelper.getTranslation("Show_linked_objects"),
                            width:32,
                            template: function (obj) {
                                if(obj.purchaseOrder?.invoice?.id) {
                                    let disabledCss = "disabled";
                                    if(obj.purchaseOrder?.linked_objects_p) {
                                        disabledCss = "";
                                    }
                                    return `<span class='fa fa-link blue-fa-icon ${disabledCss}'></span>`;
                                } else {
                                    return "";
                                }
                            },                        
                        },
                        {
                            id:"po_subproject",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            width:30,
                            tooltip: function (obj) {
                                if (obj.purchaseOrder?.project) {
                                    return obj.purchaseOrder?.project.name
                                } else {
                                    return ""
                                }
                            },
                            projectId: this.projectId,
                            template: function (obj) {
                                if(obj.purchaseOrder?.invoice) {
                                    if(obj.purchaseOrder?.project.id != this.projectId) {
                                        return `<span class='fa-solid fa-diagram-subtask'></span>`;
                                    } else {
                                        return "";
                                    }
                                } else {
                                    return ""
                                }
                            },                        
                        },
                        { 
                            id: "purchase_order_fee",
                            header:{text:i18nHelper.getTranslation("Fee"), 
                            css:"price-display"}, 
                            width:100, 
                            css:'price-display',
                            template:(obj) => {
                                if(obj.purchaseOrder?.amount) {
                                    return PriceHelper.formatPrice(obj.purchaseOrder?.amount, obj.purchaseOrder?.currency_name);
                                } else {
                                    return "";
                                }
                            }
                        },
                        { 
                            id: "purchase_order_date", 
                            header:i18nHelper.getTranslation("Date"), 
                            width:90, 
                            template:(obj) => {
                                if(obj.purchaseOrder?.effective_date) {
                                    const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
                                    const formattedDate = myFormat(new Date(obj.purchaseOrder?.effective_date));
                                    return formattedDate
                                } else {
                                    return ""
                                }
                            } 
                        },
                        {
                            id:"spacer1",
                            header:" ",
                            fillspace:true
                        },
                        { 
                            id: "credit_note", 
                            header:i18nHelper.getTranslation("Credit_Note"),  
                            css: "webix-datatable-link", 
                            width:130, 
                            template:(obj) => {
                                if(obj.creditNote?.invoice?.name) {
                                    return obj.creditNote.invoice?.name;
                                } else {
                                    return "";
                                }
                            }
                        },
                        //box icon
                        {
                            width: 40,
                            template: function (obj) {
                                if(obj.creditNote?.invoice?.id) {
                                    return `<span class='fas fa-square-full' webix_tooltip='${obj.creditNote?.cost_status.name}' style='color:${obj.invoice?.cost_status?.icon_or_color};'></span>`;
                                } else {
                                    return "";
                                }
                            }
                        },
                        {
                            css: "cog-icon",
                            id:"send-button-credit-note",
                            header: i18nHelper.getTranslation("_empty_"),
                            width: 40,
                            template: function (obj) {
                                if(obj.creditNote?.invoice) {
                                    return `<span class='fa fa-paper-plane blue-fa-icon'></span>`;
                                } else {
                                    return "";
                                }
                            },
                        },
                        {
                            id:"cn_linked_objects",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            tooltip:i18nHelper.getTranslation("Show_linked_objects"),
                            width:32,
                            template: function (obj) {
                                if(obj.creditNote?.invoice?.id) {
                                    let disabledCss = "disabled";
                                    if(obj.creditNote?.linked_objects_p) {
                                        disabledCss = "";
                                    }
                                    return `<span class='fa fa-link blue-fa-icon ${disabledCss}'></span>`;
                                } else {
                                    return "";
                                }
                            },                        
                        },
                        {
                            id:"cn_subproject",
                            header: i18nHelper.getTranslation("_empty_"),
                            css: "cog-icon",
                            width:30,
                            tooltip: function (obj) {
                                if (obj.creditNote?.project) {
                                    return obj.creditNote?.project.name
                                } else {
                                    return ""
                                }
                            },
                            projectId: this.projectId,
                            template: function (obj) {
                                if(obj.creditNote?.invoice) {
                                    if(obj.creditNote?.project.id != this.projectId) {
                                        return `<span class='fa-solid fa-diagram-subtask'></span>`;
                                    } else {
                                        return "";
                                    }
                                } else {
                                    return ""
                                }
                            },                        
                        },
                        { 
                            id: "credit_note_fee", 
                            header: {text:i18nHelper.getTranslation("Fee"), 
                            css:'price-display' }, 
                            width:100, 
                            css:'price-display', 
                            template:(obj) => {
                                if(obj.creditNote?.amount) {
                                    return PriceHelper.formatPrice(obj.creditNote?.amount, obj.creditNote?.currency_name);
                                } else {
                                    return "";
                                }
                            }
                        },
                        { 
                            id: "credit_note_date", 
                            header:i18nHelper.getTranslation("Date"), 
                            width:90,
                            template:(obj) => {
                                if(obj.creditNote?.effective_date) {
                                    const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
                                    const formattedDate = myFormat(new Date(obj.creditNote?.effective_date));
                                    return formattedDate;
                                } else {
                                    return ""
                                }
                            }
                        }

                    ],
                    onClick:{
                        "fa-paper-plane":function(event,row) {
                            if(!this.$scope.sendInvoiceByEmailModal) {
                                this.$scope.sendInvoiceByEmailModal = this.$scope.ui(SendMailModal) as SendMailModal;
                            }
                            const item = this.getItem(row);
                            if(row.column === "send-button-po") {
                                const invoiceId = item.purchaseOrder?.invoice?.id;
                                if(invoiceId) {
                                    this.$scope.sendInvoiceByEmailModal.setCustomAfterSendAction(this.$scope.refreshFunction);
                                    this.$scope.sendInvoiceByEmailModal.openModal(invoiceId); 
                                }                  
                            }
                            if(row.column === "send-button-credit-note") {
                                const invoiceId = item.creditNote?.invoice?.id;
                                if(invoiceId) {
                                    this.$scope.sendInvoiceByEmailModal.setCustomAfterSendAction(this.$scope.refreshFunction);
                                    this.$scope.sendInvoiceByEmailModal.openModal(invoiceId); 
                                }  
                            }
                        }
                    },
                    on: {
                        onItemClick:function(row, event)  {
                            const item = this.getItem(row);
                            if(row.column === "freelancer") {
                                if(item.purchaseOrder?.company?.id) {
                                    CognovisNavigator.goToObjectDetails(item.purchaseOrder?.company?.id, "im_company");
                                }
                                if(item.creditNote?.company?.id) {
                                    CognovisNavigator.goToObjectDetails(item.creditNote?.company?.id, "im_company");
                                }
                            }
                            if(row.column === "purchase_order") {
                                //window.location.href = `${config.restUrl}/invoice_document?invoice_id=${item.purchaseOrder?.invoice?.id}&preview_p=false&type=pdf&api_key=${bearerToken}`;
                                CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${item.purchaseOrder?.invoice?.id}&project_id=${this.$scope.projectId}`);
                            }
                            if(row.column === "credit_note" && item.creditNote?.invoice?.id) {
                                //window.location.href = `${config.restUrl}/invoice_document?invoice_id=${item.creditNote?.invoice?.id}&preview_p=false&type=pdf&api_key=${bearerToken}`;
                                CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${item.creditNote.invoice?.id}&project_id=${this.$scope.projectId}`);
                            }
                            if(row.column === "po_linked_objects"  && item.purchaseOrder?.linked_objects_p) {
                                const poId = item.purchaseOrder?.invoice?.id;
                                LinkedObjectsModal.show(poId, (event.clientX-event.layerX),  (event.clientY-event.layerY));
                            }
                            if(row.column === "cn_linked_objects"  && item.creditNote.linked_objects_p) {
                                const cnId = item.creditNote?.invoice?.id;
                                LinkedObjectsModal.show(cnId, (event.clientX-event.layerX),  (event.clientY-event.layerY));
                            }
                            if(row.column === "po_subproject" && item.purchaseOrder?.project.id) {
                                const subProjectId = item.purchaseOrder?.project?.id;
                                //CognovisNavigator.navigateTo(`/main/financial-overview.financial-overview?project_id=${subProjectId}`);
                                window.location.href = `${config.portalUrl}/#!/financial-overview?project_id=${subProjectId}`;
                                window.location.reload();
                            }
                            if(row.column === "cn_subproject" && item.creditNote?.project.id) {
                                const subProjectId = item.creditNote?.project?.id;
                                // CognovisNavigator.navigateTo(`/main/financial-overview.financial-overview?project_id=${subProjectId}`);
                                window.location.href = `${config.portalUrl}/#!/financial-overview?project_id=${subProjectId}`;
                                window.location.reload();
                            }
                        }
                    },
                    data:[]
                }
            ]
        }
    }

}