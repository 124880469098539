

webix.protoUI({
	name:"cog-dtp-suggest",
    defaults: { 
      type:"calendar",
    },
    setMasterValue:function(data, refresh, config){
		const text = data.id ? this.getItemText(data.id) : (data.text || data.value);
		if (this._settings?.master) { 
			const calendar = $$(this._settings.master) as webix.ui.calendar;
			if (refresh && data.id) {
				calendar.refresh();
			} else if (calendar["options_setter"]) {
				calendar.setValue(data.$empty?"":data.id);
			} else if (calendar["setValueHere"]) {
				calendar["setValueHere"](text, data, this._get_details(config));
			} else {
				calendar.setValue(text);
			}
		} else if (this._last_input_target) {
			this._set_input_value(text);
			if (!refresh) {
				if (this._last_input_target) {
					this._last_input_target.focus();
				}
			}
      }
      this.callEvent("onValueSuggest", [data, text]);
    },
  }, webix.ui.suggest);
  
  webix.protoUI({
	$cssName: 'datepicker',
	name:"cog-datepicker",
    defaults:{
      timepicker:true,
    },
    $init(config){
		const common = webix.extend(config, this.defaults);
		config.suggest = config.suggest || this.getDefaultSuggest(common);
		webix.extend(config.suggest, {
			view:"cog-dtp-suggest"
		}, true);
    },
    getDefaultSuggest(common) {
		const timepicker = common.timepicker;
		return {
			type: "calendar",
			point: common.point === false ? false : true,
			body: {
				height: 240 + (timepicker || common.icons ? 30 : 0),
				width: 250,
				multiselect: common.multiselect, 
				timepicker: timepicker,
				type: common.type,
				icons: common.icons,
				timeIcon: common.timeIcon
			}
		}
	}
}, webix.ui.datepicker);
  