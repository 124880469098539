import { ITimesheetEntry, ITransProject } from "../../../../sources/openapi";
import CognovisBasicModal from "../../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../../sources/modules/i18n-helper/i18n-helper";
import { DatetimeHelper } from "../../../../sources/modules/datetime-helpers/datetime-helpers";

type LoggedHoursPerUser = {
    userId:number,
    userName:string,
    taskName:string,
    totalHours:number;    
};

export default class LoggedHoursPerUserModal extends CognovisBasicModal {

    idPrefix = "lhpum";
    projectNr:string;
    originalData:ITimesheetEntry[] = [];

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ",740,520);
        this.projectNr = this._parent["_parent"].currentProject.project_nr;
        return mainLayout;
    }

    openModal(timesheetEntries:ITimesheetEntry[]):void {
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const modalContent = this.getContent(timesheetEntries);
        const translatedTitle = i18nHelper.getTranslation("Logged_hours_per_user");
        const modalTitle = `${this.projectNr}: ${translatedTitle}`;
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle); 
    }

    closeModal():void {
        this.hide();
    }

    getContent(timesheetEntries:ITimesheetEntry[]):webix.ui.layoutConfig {
        const loggedHoursPerUser = this.prepareDataForTable(timesheetEntries);
        const layout = {
            view:"layout",
            rows:[
                this.getToolbar(),
                this.getSummedHoursDatatable(loggedHoursPerUser),
                this.getUngrouppedHoursDatatable(timesheetEntries),
            ]
        };
        return layout;
    }

    getToolbar():webix.ui.toolbarConfig {
        const toolbar = {
            view:"toolbar",
            gravity:1,
            rows:[
                {
                    cols:[
                            {
                                view:"button",
                                type:"icon",
                                icon: "far fa-file-excel",
                                tooltip:i18nHelper.getTranslation("XLS: Summed hours per user"),
                                align: "left",
                                width:30,
                                inputWidth:28,
                                click:() => {
                                    const tableByLocalId = this.$$(`${this.idPrefix}LoggedHoursPerUserModal`) as webix.ui.datatable;
                                    const tableByUniqueId = webix.$$(`${tableByLocalId.config.id}`);
                                    const datetime = DatetimeHelper.parseDateWithHour(new Date());
                                    webix.toExcel(tableByUniqueId, {
                                        filename:`${this.projectNr}_${datetime}_sum`,
                                        name:`${this.projectNr}_${datetime}_sum`,
                                    });
                                }
                            },
                            {
                                view:"button",
                                type:"icon",
                                icon: "fa-sharp fa-solid fa-file-excel",
                                tooltip:i18nHelper.getTranslation("XLS: Ungroupped hours"),
                                align: "left",
                                inputWidth:28,
                                click:() => {
                                    const tableByLocalId = this.$$(`${this.idPrefix}LoggedUngroupedHoursPerUserModal`) as webix.ui.datatable;
                                    const tableByUniqueId = webix.$$(`${tableByLocalId.config.id}`);
                                    const datetime = DatetimeHelper.parseDateWithHour(new Date());
                                    webix.toExcel(tableByUniqueId, {
                                        filename:`${this.projectNr}_${datetime}_ungroupped`,
                                        name:`${this.projectNr}_${datetime}_ungroupped`,
                                    });
                                }
                            }
                    ]
                }

            ]
        };
        return toolbar
    }

    getSummedHoursDatatable(loggedHoursPerUser:LoggedHoursPerUser[]):webix.ui.datatableConfig {
        const datatable = {
            view:"datatable",
            localId:`${this.idPrefix}LoggedHoursPerUserModal`,
            tooltip:true,
            columns:[
                {
                    id:"userId",
                    hidden:true
                },
                {
                    id:"userName",
                    header: [i18nHelper.getTranslation("User"), { content: "multiSelectFilter" }],
                    fillspace:true
                },
                {
                    id:"taskName",
                    header: [i18nHelper.getTranslation("Task"), { content: "multiSelectFilter" }],
                    fillspace:true
                },
                {
                    id:"totalHours",
                    header: [i18nHelper.getTranslation("Total_hours"), { content: "numberFilter" }],
                    sort:"int"
                }
            ],
            data:loggedHoursPerUser
        };
        return datatable
    }

    getUngrouppedHoursDatatable(ungrouppedLoggedHours:ITimesheetEntry[]):webix.ui.datatableConfig {
        const datatable = {
            view:"datatable",
            localId:`${this.idPrefix}LoggedUngroupedHoursPerUserModal`,
            tooltip:true,
            hidden:true,
            columns:[
                {
                    id:"userId",
                    map:"#user.id#",
                    hidden:true
                },
                {
                    id:"userName",
                    header: [i18nHelper.getTranslation("User"), { content: "multiSelectFilter" }],
                    map:"#user.name#",
                    fillspace:true
                },
                {
                    id:"Day",
                    header: [i18nHelper.getTranslation("Day")],
                    map:"#day#",
                    fillspace:true,
                    template:(obj) => {
                        const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
                        const formattedDate = myFormat(new Date(obj.day));
                        return formattedDate
                    }
                },
                {
                    id:"taskName",
                    header: [i18nHelper.getTranslation("Task"), { content: "multiSelectFilter" }],
                     map:"#task.name#",
                    fillspace:true
                },
                {
                    id:"totalHours",
                    header: [i18nHelper.getTranslation("Total_hours"), { content: "numberFilter" }],
                    map:"#hours#",
                    sort:"int"
                }
            ],
            data:ungrouppedLoggedHours
        };
        return datatable
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
 
                    ] 
                };
        return buttons;
    }

    prepareDataForTable(timesheetEntries:ITimesheetEntry[]):LoggedHoursPerUser[] {
        this.originalData = timesheetEntries;
        timesheetEntries.map(entry => {
            entry["hashKey"] = `${entry.user.id}|${entry.task.id}`; 
        });
        const arrayUniqueByKey = [...new Map(timesheetEntries.map(entry =>
            [entry["hashKey"], entry])).values()];
        const arr = [];
        arrayUniqueByKey.map(uniqueEntries => {
            arr.push({
                userId:uniqueEntries.user.id,
                userName:uniqueEntries.user.name,
                taskName:timesheetEntries.filter(entry => entry["hashKey"] === uniqueEntries["hashKey"])[0].task.name,
                totalHours:timesheetEntries.filter(entry => entry["hashKey"] === uniqueEntries["hashKey"]).reduce((a, b) => +a + +b.hours, 0),
            });
        });
        return arr;
    }

}