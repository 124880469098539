export enum UserProfile {
    PO_ADMIN = 459,
    CUSTOMER = 461,
    EMPLOYEE = 463,
    FREELANCER = 465,
    PROJECT_MANAGER = 467,
    SENIOR_MANAGER = 469,
    ACCOUNTING = 471,
    SALES = 473,
    HR_MANAGER = 585,
    FREELANCER_MANAGER = 10739,
    HELPDESK = 29057
}
