/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetCostCenterType {
    /**
     * Department without budget responsabilities
     */
    COST_CENTER = 3001,
    PROFIT_CENTER = 3002,
    INVESTMENT_CENTER = 3003,
    SUBDEPARTMENT = 3004,
}
