import { CognovisRestCompanyService, ICompanyBody } from "../../../../sources/openapi";
import { JetView } from "webix-jet";
import ErrorMessagePopup from "../../modals/error-success-message-popup/error-message.popup";
import SuccessMessagePopup from "../../modals/error-success-message-popup/success-message.popup";

import "../../modals/modals.style.css";

export default class AddMemberModal extends JetView {
    showErrorMessagePopup: ErrorMessagePopup;
    showSuccessMessagePopup: SuccessMessagePopup;

    config(): webix.ui.windowConfig {
        return {
            view: "window",
            modal: true,
            close: true,
            position: "top",
            head: "Add New Member",
            id: "formWindow",
            width: 500,
            css: "new-member-window",
            body: ({
                view: "form",
                id: "addMemberForm",
                view_id: "addMemberForm",
                css: "new-member-window__addmember__form",
                elements: [
                    {
                        view: "text",
                        label: "First Names",
                        labelPosition: "top",
                        placeholder: "First Name",
                        name: "first_names",
                        id: "firstNames",
                        view_id: "firstNames",
                        css: "first__names",
                        tooltip: "First Names",
                        borderless: true,
                    },
                    {
                        view: "text",
                        type: "text",
                        label: "Last Name",
                        labelPosition: "top",
                        placeholder: "Last Name",
                        name: "last_name",
                        id: "lastName",
                        view_id: "lastName",
                        css: "last__name",
                    },
                    {
                        view: "text",
                        type: "email",
                        label: "Email",
                        labelPosition: "top",
                        placeholder: "Email",
                        name: "email",
                        id: "email",
                        view_id: "email",
                        css: "member__email",
                    },
                    {
                        view: "text",
                        type: "tel",
                        label: "Phone",
                        labelPosition: "top",
                        placeholder: "Phone",
                        name: "phone",
                        id: "phone",
                        view_id: "phone",
                        css: "member__phone",
                    },
                    {
                        view: "button",
                        value: "Save",
                        id: "save_new_member",
                        view_id: "save_new_member",
                        inputWidth: 150,
                        height: 40,
                        align: "left",
                        css:
                            "save__newmember__button add__newmember__save__btn",
                        click: () => {
                            const addMemberForm: webix.ui.form = webix.$$(
                                "addMemberForm"
                            ) as webix.ui.form;

                            if (addMemberForm.validate()) {
                                const formValues = addMemberForm.getValues() as unknown as ICompanyBody;

                                CognovisRestCompanyService.postCompany({requestBody:formValues})
                                    .then(() => {
                                        (this.showSuccessMessagePopup.getRoot() as webix.ui.popup).show();

                                        //const newUserId = res.rest_oid;
                                        addMemberForm.clear();
                                        /*CognovisAjax.get(sencha_invite_new_user",
                                            {newUserId,
                                                company_id: localStorage.getItem("logged_user_company_id"),
                                            });*/
                                    })
                                    .catch((err) => {
                                        const unstringifiedResponse = JSON.parse(
                                            err.response
                                        );
                                        const errResponse =
                                            unstringifiedResponse.message;

                                        webix.message({
                                            type: "error",
                                            text: errResponse,
                                        });
                                        addMemberForm.clear();
                                    });

                                webix.$$("formWindow").hide();
                            } else {
                                (this.showErrorMessagePopup.getRoot() as webix.ui.popup).show();
                            }
                        },
                    },
                ],
                rules: {
                    first_names: webix.rules.isNotEmpty,
                    last_name: webix.rules.isNotEmpty,
                    email: webix.rules.isEmail,
                },
            } as unknown) as webix.ui.baseview,
        };
    }
    init(): void {
        this.showSuccessMessagePopup = this.ui(
            SuccessMessagePopup
        ) as SuccessMessagePopup;
        this.showErrorMessagePopup = this.ui(
            ErrorMessagePopup
        ) as ErrorMessagePopup;
    }
}
