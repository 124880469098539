/* eslint-disable security/detect-non-literal-fs-filename */
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import {CognovisRestTimesheetService, ITimesheetTask} from "../../../sources/openapi";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import LoggedHoursModal from "./record-time/logged-hours-modal";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import LoggedHoursPerUserModal from "./record-time/logged-hours-per-user-modal";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import TimesheetTaskModal from "./timesheet-task-modal";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";
import { IJetApp } from "webix-jet";

export default class ProjectTimesheetPortlet extends CognovisBasicPortlet {
    
    projectId:number;
    projectName:string;
    idPrefix = "pott";
    tableName = "timesheetTasksTable";
    portletId:number;
    title:string;
    newTimesheetTaskModal:CognovisBasicModal;
    loggedHoursModal:LoggedHoursModal;
    webixHelpers: WebixHelpers;
    loggedHoursPerUserModal:LoggedHoursPerUserModal;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    timesheetTaskModal:TimesheetTaskModal;
    justUploadedFile: string | boolean = false;


    constructor(app:IJetApp, projectId:number, portletId?:number, title?:string) {
        super(app, {});
        this.projectId = projectId;
        this.portletId = portletId;
        if(this.title) {
            this.title = title;
        }
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
    }


    init():void {
        super.init();
        CognovisRestTimesheetService.getTimesheetTask({
            projectId:this.projectId
        })
        .then(timesheetTasks => {
            // Change task_billable
            timesheetTasks.map(task => {
                if(task.billable_units > 0) {
                    task["task_billable_p"] = true;
                } else {
                    task["task_billable_p"] = false;
                }
                task["edit"] = false;
            });
            const rows = {
                view:"layout",
                autoheight:true,
                autowidth:true,
                gravity:1,
                rows:[this.getTimesheetTasksDatatable(timesheetTasks)]
            };
            const portlet = {
                view: "layout",
                gravity:1,
                css:"cog-box-content",
                id:`${this.idPrefix}TimeSheetTasksList`,
                rows:[
                    rows
                ],
            };
            this.components = [portlet];
            this.insertComponents();
        });
       
    }
    
    getHeader():webix.ui.layoutConfig {
        //`${this.idPrefix}XLSUploader
        const header = {
            view:"layout",
            height:44,
            css: "cog-box-header", 
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: i18nHelper.getTranslation("Time_tracking_tasks"), 
                    borderless: true 
                },
                {
                    view:"button",
                    type:"icon",
                    width:28,
                    align: "right", 
                    icon:"fas fa-upload",
                    tooltip:i18nHelper.getTranslation("Upload XLS or CSV"),
                    click:() => {
                        this.openUploader()
                    }
                },
                {
                    view:"button",
                    type:"icon",
                    width:28,
                    align: "right", 
                    icon:"far fa-user-clock",
                    tooltip:i18nHelper.getTranslation("Logged hours per user"),
                    click:() => {
                        this.openLoggedHoursPerUserModal();
                    }
                },
                { 
                    view: "button", 
                    type: "icon", 
                    icon: "far fa-clock", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true,
                    tooltip:i18nHelper.getTranslation("Logged hours"),
                    click:() => {
                        this.openTimesheetEntryModal();
                    }
                },
                { 
                    view: "button", 
                    type: "icon", 
                    icon: "fal fa-plus", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true ,
                    tooltip:i18nHelper.getTranslation("Add time tracking task"),
                    click:() => {
                        this.addOrEditTimesheetTask()
                    }
                },
                this.getUploaderUI()
            ]
        };
        return header;
    }

    getTimesheetTasksDatatable(timesheetTasks:ITimesheetTask[]):webix.ui.datatable {
        const datatable = {
            id:`${this.tableName}`,
            view:"datatable",
            tooltip:true,
            borderless:true,
            columns:[
                {
                    view: "template",
                    width: 30,
                    tooltip: function (obj) {
                        return obj.task_type.name
                    },
                    template: function (obj) {
                        return `<i style='margin-left:-4px; color:${obj.task_status.icon_or_color}' class='${obj.task_type.icon_or_color}'></i>`
                    }
                },
                {
                    id:"task_name",
                    header:i18nHelper.getTranslation("Task_name"),
                    map:"#task.name#",
                    tooltip: "",
                    fillspace: true,
                    editor: "text"
                },
                {
                    id: "task_billable_p",
                    header: i18nHelper.getTranslation("EUR"),
                    tooltip: i18nHelper.getTranslation("Billable"),
                    width:80,
                    template: (obj) => {
                        let checked = "";
                        if(obj.task_billable_p) {
                            checked = `checked`;
                        }
                        return `<input ${checked} onclick="return false;" value="true" type="checkbox"/>`
                    },
                },
                {
                    id: "planned_units",
                    header: i18nHelper.getTranslation("Planned_units"),
                    tooltip: "",
                    editor: "text",
                    format: webix.i18n.numberFormat
                },
                {
                    id: "logged_hours",
                    tooltip: "",
                    header: i18nHelper.getTranslation("Logged_hours"),
                },
                {
                    view: "template",
                    css: "edit",
                    width: 30,
                    template: function (obj) {
                        return `<span class='webix_icon wxi-${obj.edit ? "check" : "pencil"}' id='edit-${obj.edit ? "check" : "pencil"}'></span>`;
                    }
                },
                {
                    view: "template",
                    css: "edit",
                    width: 30,
                    template: function () {
                        return `<span class='webix_icon wxi-trash'></span>`;
                    }
                }
            ],
            onClick: {
                "wxi-pencil": (event, row) => {
                    const table = ($$(this.tableName) as webix.ui.datatable);
                    const timesheetTask = table.getItem(row.row);
                    this.addOrEditTimesheetTask(timesheetTask);
                },
                "wxi-trash": (event, row) => {
                    const table = ($$(this.tableName) as webix.ui.datatable);
                    const task = table.getItem(row);
                    const confirmWarning = `${i18nHelper.getTranslation(`You_want_to_remove`)}?`;
                    webix.confirm({
                        title: i18nHelper.getTranslation(`any_are_you_sure_message`),
                        type: "confirm-warning",
                        text: confirmWarning,
                        width: 480
                    })
                    .then(() => {
                        CognovisRestTimesheetService.deleteTimesheetTask({
                            taskId:task.task.id
                        })
                        .then(deletedTasks => {
                            if(deletedTasks[0]?.err_msg) {
                                webix.alert({
                                    title: "Error",
                                    text: deletedTasks[0].err_msg,
                                    type:"alert-error"
                                });
                            } else {
                                this.refreshTimesheetTasksTable()
                            }
                            
                        });
                    });
                },
            },
            data:timesheetTasks

        };
        return datatable as unknown as webix.ui.datatable
    }

    addOrEditTimesheetTask(timesheetTask?:ITimesheetTask):void {
        if(!this.timesheetTaskModal) {
            this.timesheetTaskModal = this.ui(TimesheetTaskModal) as TimesheetTaskModal;
        }
        this.timesheetTaskModal.openModal(this.projectId, timesheetTask, () => {
            this.refreshTimesheetTasksTable();
        });
    }

    refreshTimesheetTasksTable():void {
        const timesheetTasksTable = (webix.$$(`${this.tableName}`) as webix.ui.datatable);
        timesheetTasksTable.clearAll();
        CognovisRestTimesheetService.getTimesheetTask({
            projectId:this.projectId
        })
        .then(timesheetTasks => {
            // Change task_billable
            timesheetTasks.map(task => {
                //if(task.billable_units > task.planned_units) {
                if(task.billable_units > 0) {
                    task["task_billable_p"] = true;
                } else {
                    task["task_billable_p"] = false;
                }
                task["edit"] = false;
            });
            timesheetTasksTable.define("data", timesheetTasks);
            timesheetTasksTable.refresh();
        });
    }


    openTimesheetEntryModal():void {
        if(!this.loggedHoursModal) {
            this.loggedHoursModal = this.ui(LoggedHoursModal) as LoggedHoursModal;
        }
        this.loggedHoursModal.openModal(this.projectId, false, false, () => {
            this.refreshTimesheetTasksTable();
        });
    }

    openLoggedHoursPerUserModal():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        if(!this.loggedHoursPerUserModal) {
            this.loggedHoursPerUserModal = this.ui(LoggedHoursPerUserModal) as LoggedHoursPerUserModal;
        }
        CognovisRestTimesheetService.getTimesheetEntry({projectId:this.projectId})
        .then(timesheetEntries => {
            this.cognovisPleaseWaitWindow.hide();
            this.loggedHoursPerUserModal.openModal(timesheetEntries);
        });
    }

    getUploaderUI():webix.ui.uploaderConfig {
        return {
            view: "uploader",
            id: `${this.idPrefix}XLSUploader`,
            width: 1,
            height: 1,
            upload: this.webixHelpers.getUrlWithApiKey(
                "upload_fs_file"
            ),
            on: {
                onUploadComplete: (
                    file
                ) => {
                    this.addTasksFromFiles([file]);
                },
            },
        };
    }

    openUploader(): void {
        const csvUploader = (webix.$$(`${this.idPrefix}XLSUploader`) as webix.ui.uploader);
        csvUploader.fileDialog();
    }

    addTasksFromFiles(files: IUploadedFile[]): void {
        if(files[0]) { 
            CognovisRestTimesheetService.postTimesheetTaskFromFile({
                projectId:this.projectId,
                filename: files[0].filename,
                path: files[0].path,
            })
            .then(timesheetTasks => {
                this.refreshTimesheetTasksTable();
            });
        }
    }
}