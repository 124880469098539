/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ICollmexAccdoc } from '../models/ICollmexAccdoc';
import type { ICompany } from '../models/ICompany';
import type { ImCompanyId } from '../models/ImCompanyId';
import type { ImInvoiceId } from '../models/ImInvoiceId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IntranetCollmexRestService {

    /**
     * Returns an array of bookings
     * @returns ICollmexAccdoc bookings in collmex
     * @throws ApiError
     */
    public static getCollmexAccdoc({
        invoiceId,
        companyId,
    }: {
        /**
         * Invoice to which this booking relates
         */
        invoiceId?: ImInvoiceId,
        /**
         * Company for which we need to get the bookings
         */
        companyId?: ImCompanyId,
    }): CancelablePromise<Array<ICollmexAccdoc>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collmex_accdoc',
            query: {
                'invoice_id': invoiceId,
                'company_id': companyId,
            },
        });
    }

    /**
     * Imports the company to collmex
     * @returns ICompany Object of the Company which we updated.
     * @throws ApiError
     */
    public static postImportCompanyToCollmex({
        companyId,
    }: {
        /**
         * Company which we want to import to collmex.
         */
        companyId: ImCompanyId,
    }): CancelablePromise<ICompany> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/import_company_to_collmex',
            query: {
                'company_id': companyId,
            },
        });
    }

}
