/* eslint-disable security/detect-non-literal-fs-filename */
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { IJetApp, JetView } from "webix-jet";
import {CognovisRestCompanyService, CognovisRestService, IntranetCompanyType, IntranetProjectStatus, IntranetProjectType, IntranetSkillType, IObjectSkill, ITransProject, WebixPortalFreelancerService, WebixPortalProjectService, WebixPortalTranslationService } from "../../../sources/openapi";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import { container } from "tsyringe";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";


export default class ProjectBasicInformationPortlet extends CognovisBasicPortlet {
    
    projectId:number;
    currentProject:ITransProject;
    currentProjectSkills:IObjectSkill[];
    idPrefix = "pbi";
    editionModal:CognovisBasicModal;
    cognovisPleaseWaitWindow:CognovisPleaseWaitWindow;
    portletId:number;

    constructor(app:IJetApp, projectId:number, portletId?:number, title?:string) {
        super(app, {});
        this.projectId = projectId;
        this.portletId = portletId;
        if(title) {
            this.title = title;
        }        
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
    }

    init():void {
        super.init();
        WebixPortalTranslationService.getTransProjects({
            projectId:this.projectId
        })
        .then(projectBasicInfo => {     
            this.currentProject = projectBasicInfo[0];
            WebixPortalFreelancerService.getObjectSkill({
                objectId:this.projectId,
                skillTypeId:IntranetSkillType.BUSINESS_SECTOR 
            })
            .then(projectSkills => {
                this.currentProjectSkills = projectSkills;
                this.components = [...this.getFields(projectBasicInfo[0], projectSkills)];
                this.insertComponents();
            });
        });
    }

    urlChange():void {
        const newProjectId = this.getParam("project_id", false);
        if(this.projectId !== newProjectId) {
            //window.location.reload();
        }
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: i18nHelper.getTranslation("Project_basic_information"), 
                    borderless: true 
                },
                { 
                    view: "button", 
                    type: "icon", 
                    icon: "far fa-edit", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true,
                    click:() => {
                        this.openEditionModal(this.currentProject, this.currentProjectSkills)
                    }
                },
            ]
        };
        return header;
    }
    
    getFields(projectBasicInfo:ITransProject, projectSkills:IObjectSkill[]):webix.ui.textConfig[] {
        // Format dates
        const properFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
        let startDate = "";
        if(projectBasicInfo?.start_date) {
            startDate = properFormat(new Date(projectBasicInfo?.start_date));
        }
        let endDate = "";
        if(projectBasicInfo?.end_date) {
            endDate = properFormat(new Date(projectBasicInfo?.end_date));
        }
        let companyReferenceNumber = projectBasicInfo.company_project_nr;
        if(companyReferenceNumber === "null") {
            companyReferenceNumber = "";
        }
        let subjectArea = "";
        if(projectBasicInfo.subject_area?.name) {
            subjectArea = projectBasicInfo.subject_area?.name;
        }
        let finalCompany = "";
        if(projectBasicInfo.final_company?.name) {
            finalCompany = projectBasicInfo.final_company?.name;
        }
        let parentProject = "";
        let parentProjectField;
        if(projectBasicInfo.parent?.name) {
            parentProject = projectBasicInfo.parent?.name;
            parentProjectField = this.createLinkedField(i18nHelper.getTranslation("parent_project"), projectBasicInfo.parent.id, projectBasicInfo.parent?.name, "im_project", `#!/project-info?project_id=${projectBasicInfo.parent.id}`);
        } else {
            parentProjectField = this.createField(i18nHelper.getTranslation("parent_project"), parentProject);
        }
        let processingTime = "";
        if(projectBasicInfo.processing_time) {
            processingTime = projectBasicInfo?.processing_time.toString();
        }
        if(!processingTime && !endDate) {
            processingTime = "TBD";
        }
        const projectInfoFields:webix.ui.layoutConfig[] = [
            this.createField(i18nHelper.getTranslation("project_name"), projectBasicInfo.project?.name),
            this.createField(i18nHelper.getTranslation("project_nr"), projectBasicInfo.project_nr),
            this.createField(i18nHelper.getTranslation("project_manager"), projectBasicInfo.project_lead?.user.name),
            this.createField(i18nHelper.getTranslation("customer_contact"), projectBasicInfo.company_contact?.name),
            this.createLinkedField(i18nHelper.getTranslation("Customer"), projectBasicInfo.company.id, projectBasicInfo.company?.name, "im_company", `#!/company?company_id=${projectBasicInfo.company.id}`),
            this.createField(i18nHelper.getTranslation("final_company"), finalCompany),
            this.createField(i18nHelper.getTranslation("project_type"), projectBasicInfo.project_type?.name),
            parentProjectField,
            this.createField(i18nHelper.getTranslation("source_language"),projectBasicInfo.source_language?.name),
            this.createField(i18nHelper.getTranslation("target_languages"),projectBasicInfo.target_language.map(tl => tl.name).join(", ")),
            this.createField(i18nHelper.getTranslation("subject_area"),subjectArea),
            this.createField(i18nHelper.getTranslation("Specific_subject"), projectSkills.map(skill => skill.skill.name).join(", ")),
            this.createField(i18nHelper.getTranslation("processing_time"),processingTime),
            this.createField(i18nHelper.getTranslation("Complexity_type"),projectBasicInfo.complexity_type?.name),
            this.createField(i18nHelper.getTranslation("start_date"),startDate),
            this.createField(i18nHelper.getTranslation("end_date"),endDate),
            this.createField(i18nHelper.getTranslation("reference_name"), companyReferenceNumber),
            this.createField(i18nHelper.getTranslation("Status"),projectBasicInfo.project_status.name, true),
        ];  
        return projectInfoFields
    }

    createField(label:string, value:string, bold = false, url?:string):webix.ui.layoutConfig {
        const labelAndValuePair = {
            view:"layout",
            paddingX:13,
            borderless:true,
            height:31,
            css:"cog-box-content",
            cols:[
                {
                    view:"label",
                    borderless:true,
                    label:label,
                    css:bold ? "thicker-font" : "",
                },
                {
                    view:"label",
                    borderless:true,
                    id:`${this.idPrefix}Field${label}`,
                    gravity:2.5,
                    label:url ?  "<span class='text-link'>" +value + "</span>" : value,
                    css:bold ? "thicker-font" : "",
                    on: {
                        onItemClick:function() {
                            if (url !== undefined) {
                                window.open(url).focus();
                            }
                        }
                    }
                }
            ]
        };
        return labelAndValuePair
    }

    createLinkedField(label:string, objectId:number, objectName:string, objectType:string, url:string) {
        const labelAndValuePair = {
            view:"layout",
            paddingX:13,
            borderless:true,
            height:31,
            css:"cog-box-content",
            cols:[
                {
                    view:"label",
                    borderless:true,
                    label:label
                },
                {
                    view:"cogObjectLink",
                    borderless:true,
                    objectId:objectId,
                    objectName:objectName,
                    objectType:objectType,
                    id:`${this.idPrefix}Field${label}`,
                    gravity:2.5
                }
            ]
        };
        return labelAndValuePair     
    }


    openEditionModal(project:ITransProject, projectSkills:IObjectSkill[]):void {
        const modalBody = {
            body:{
                width:800,
                //height:580,
                autoheight: true,
                rows:[
                    {
                        view:"spacer",
                        height:10
                    },
                    {
                        view:"layout",
                        cols:[
                            {
                                view:"spacer",
                                width:20
                            },
                            {
                                view:"layout",
                                rows:[this.getEditableFields(this.currentProject, projectSkills)],
                                autoheight:true
                            },
                            {
                                view:"spacer",
                                width:20
                            },
                        ]
                    },
                ]
            }
        } as webix.ui.layoutConfig;     
        const translatedButtons = [
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Cancel"),
                click:() => {
                    this.editionModal.hide();
                }
            },
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("save"),
                click:() => {
                    this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                    const form = (webix.$$(`${this.idPrefix}ProjectEditionModalForm`) as webix.ui.form);
                    const values = form.getValues();
                    WebixPortalTranslationService.putTransProject({
                        projectId:project.project.id,
                        requestBody:{
                            project_status_id:values.projectStatus,
                            project_name:values.projectName,
                            source_language_id:project.source_language.id,
                            target_language_ids:values.targetLanguages,
                            subject_area_id:values.subjectArea,
                            complexity_type_id:values.complexityType,
                            company_id:project.company.id,
                            project_nr:project.project_nr,
                            parent_id:values.parentProject,
                            project_type_id:values.projectType,
                            customer_contact_id:values.customerContact,
                            final_company_id:values.finalCompany,
                            processing_time:values.processingTime,
                            start_date:values.startDate,
                            end_date:values.endDate,
                            company_project_nr:values.companyReferenceNumber,
                        }
                    })
                    .then(() => {
                        if(project.project_lead.user.id !== Number(values.projectManager)) {
                            // If PM was modified, we make additional call to post
                            return WebixPortalProjectService.postProjectTakeOver({
                                projectId:project.project.id,
                                newProjectLeadId:values.projectManager
                            })
                        }
                    })
                    .then(() => {
                        if(values.specificSubject == undefined) {
                            return WebixPortalFreelancerService.deleteObjectSkill({
                                objectId: project.project.id,
                                skillTypeId: IntranetSkillType.BUSINESS_SECTOR
                            })
                        } else {
                            return WebixPortalFreelancerService.postObjectSkill({
                                objectId: project.project.id,
                                skillIds: [values.specificSubject]
                            })
                        }
                    })
                    .then(() => {
                        this.cognovisPleaseWaitWindow.hide();
                        window.location.reload();        // Before reload we need to check if project manager wasn't modified
                    })
                }
            },
        ] as webix.ui.buttonConfig[];
        const buttonsLayout = {
                view:"layout",
                padding:0,
                cols:translatedButtons
        } as webix.ui.layoutConfig; 
        if(!this.editionModal) {
            this.editionModal = this.ui(CognovisBasicModal) as CognovisBasicModal;
        }
        this.editionModal.open(modalBody, buttonsLayout, `Edit project: ${project.project.name}`);
    }


    getEditableFields(project:ITransProject, projectSkills:IObjectSkill[]):webix.ui.formConfig {
        let companyReferenceNumber = project.company_project_nr;
        if(companyReferenceNumber === "null") {
            companyReferenceNumber = "";
        }
        const editableFields = {
            view:"form",
            css:{
                "border":"none"
            },
            id:`${this.idPrefix}ProjectEditionModalForm`,
            elements:
            [
                {
                    view:"text",
                    name:"projectName",
                    id:`${this.idPrefix}editionModalProjectName`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("project_name"),
                    value:project.project.name
                },
                {
                    view:"combo",
                    name:"projectManager",
                    id:`${this.idPrefix}editionModalProjectManager`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("project_manager"),
                    options:[],
                    on:{
                        onAfterRender:() => {
                            CognovisRestService.getBizObjectMembers({
                                objectId:this.projectId
                            })
                            .then(projectMembers => {
                                const preparedForCombo = projectMembers.map(member => ({id:member.member.user.id,value:member.member.user.name}));
                                const combo = webix.$$(`${this.idPrefix}editionModalProjectManager`) as webix.ui.combo;
                                combo.define("options", preparedForCombo);
                                combo.setValue(project.project_lead?.user.id.toString());
                            });
                        }
                    }
                },
                {
                    view:"combo",
                    name:"customerContact",
                    id:`${this.idPrefix}editionModalCustomerContact`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("customer_contact"),
                    options:[],
                    on:{
                        onAfterRender:() => {
                            CognovisRestCompanyService.getCompanyContacts({
                                companyId:project.company.id
                            })
                            .then(companyContacts => {
                                const preparedForCombo = companyContacts.map(member => ({id:member.contact.id,value:member.contact.name}));
                                const combo = webix.$$(`${this.idPrefix}editionModalCustomerContact`) as webix.ui.combo;
                                combo.define("options", preparedForCombo);
                                combo.setValue(project.company_contact.id.toString());
                            });
                        }
                    }
                },
                {
                    view:"combo",
                    name:"finalCompany",
                    id:`${this.idPrefix}editionModalFinalCompany`,
                    labelWidth:160,
                    label: i18nHelper.getTranslation("final_company"),
                    value:project.final_company?.id,
                    suggest: {
                        body: {
                            url: () => {
                                return CognovisRestCompanyService.getCompanies({
                                    companyTypeIds:[IntranetCompanyType.FINAL_COMPANY]
                                })
                                .then(finalCompanies => {
                                    const finalComapniesForCombo = finalCompanies.map(company => ({id:company.company.id, value:company.company.name}))
                                    return finalComapniesForCombo;
                                });
                            },
                        },
                    },
                },
                {
                    view:"combo",
                    name:"projectType",
                    id:`${this.idPrefix}editionModalProjectType`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("project_type"),
                    value:project.project_type.id,
                    suggest: {
                        body: {
                            url: () =>
                                CognovisCategory.getCategory(
                                    "Intranet Project Type"
                                ),
                        },
                    }
                },
                {
                    view:"multiselect",
                    name:"targetLanguages",
                    id:`${this.idPrefix}editionModalTargetLanguages`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("target_languages"),
                    value:project.target_language.map(lang => lang.id).join(","),
                    suggest: {
                        body: {
                            url: () =>
                                CognovisCategory.getCategory(
                                    "Intranet Translation Language"
                                ),
                        },
                    },
                },
                {
                    view:"combo",
                    name:"parentProject",
                    id:`${this.idPrefix}editionModalParentProject`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("Parent_project"),
                    value:project.parent?.id,
                    suggest: {
                        filter:function(item, value){
                            let result = false;
                            const exploded = item.value.split(" - ");
                            const searchableValues = []
                            exploded.map(val => {
                                searchableValues.push(val.toString().toLowerCase())
                            });
                            searchableValues.map(val => {
                                if(value) {
                                    if(val.toString().toLowerCase().indexOf(value.toLowerCase()) === 0) {
                                        result = true;
                                    }
                                }
                            });
                            return result;                              
                        },
                        body: {
                            url: () => this.getPossibleParentProjects(),
                            // template:"#template#"
                        },
                    },
                },
                {
                    view:"combo",
                    name:"subjectArea",
                    id:`${this.idPrefix}editionModalSubjectArea`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("subject_area"),
                    value:project.subject_area?.id,
                    suggest: {
                        body: {
                            url: () =>
                                CognovisCategory.getCategory(
                                    "Intranet Translation Subject Area"
                                ),
                        },
                    },
                },
                {
                    view:"combo",
                    name:"complexityType",
                    id:`${this.idPrefix}editionModalComplexityType`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("Complexity_type"),
                    value:project.complexity_type.id,
                    suggest: {
                        body: {
                            url: () =>
                                CognovisCategory.getCategory(
                                    "Intranet Translation Complexity"
                                ),
                        },
                    },
                },
                {
                    view:"combo",
                    name:"specificSubject",
                    id:`${this.idPrefix}editionModalSpecificSubject`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("Specific_subject"),
                    value:projectSkills[0]?.skill?.id,
                    suggest: {
                        body: {
                            url: () => 
                                CognovisCategory.getCategory(
                                    "Intranet Skill Business Sector"
                                ),
                        },
                    },
                },
                {
                    view:"text",
                    name:"companyReferenceNumber",
                    id:`${this.idPrefix}editionModalCompanyReferenceNumber`,
                    labelWidth:160,
                    label:"Reference number",
                    value:companyReferenceNumber
                },
                {
                    view: "datepicker",
                    name:"startDate",
                    format:"%d.%m.%Y %H:%i",
                    value:"",
                    labelWidth:160,
                    timepicker: true,
                    id:`${this.idPrefix}editionModalStartDate`,
                    label: i18nHelper.getTranslation("start_date"),
                    placeholder: i18nHelper.getTranslation("start_date"),
                    on: {
                        onAfterRender:function() {
                            if(project.start_date) {
                                this.setValue(new Date(project.start_date));
                            } else {
                                this.setValue("");
                            }
                        }
                    }
                },                
                {
                    view: "datepicker",
                    name:"endDate",
                    format:"%d.%m.%Y %H:%i",
                    value: "",
                    labelWidth:160,
                    timepicker: true,
                    id:`${this.idPrefix}editionModalEndDate`,
                    label: i18nHelper.getTranslation("end_date"),
                    placeholder: i18nHelper.getTranslation("end_date"),
                    on: {
                        onAfterRender:function() {
                            if(project.end_date) {
                                this.setValue(new Date(project.end_date));
                            } else {
                                this.setValue("");
                            }
                        }
                    }
                },
                {
                    view:"text",
                    type:"number",
                    name:"processingTime",
                    id:`${this.idPrefix}editionModalProcessingTime`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("processing_time"),
                    value:project.processing_time
                },
                {
                    view:"combo",
                    name:"projectStatus",
                    id:`${this.idPrefix}editionModalProjectStatus`,
                    labelWidth:160,
                    label:i18nHelper.getTranslation("Status"),
                    value:project.project_status.id,
                    suggest: {
                        body: {
                            url: () =>
                                CognovisCategory.getCategory(
                                    "Intranet Project Status"
                                ),
                        },
                    },
                }
            ]
        }
        return editableFields;
    }

    getPossibleParentProjects():Promise<ICombobox[]> {
        return WebixPortalProjectService.getUserProjects({
            userId:UserStateManager.getCurrentlyLoggedUser().id
        })
        .then(potentialParentProjects => {
            // const potentialParentProjectsParsedForCombo = potentialParentProjects.map(project => ({id:project.project.id, value:`${project.project_nr} - ${project.project.name}`, template:`<div><i style='margin-left:-4px; color:${project["project_status"]["icon_or_color"]};' class='${project["project_type"]["icon_or_color"]}'></i><span style='font-size:0.8rem; color:grey; margin-left:6px;'>${project.project_nr} ${project.project.name}</span></div>`}));
            const potentialParentProjectsParsedForCombo = potentialParentProjects.map(project => ({id:project.project.id, value:`${project.project_nr} - ${project.project.name}`}))
            return potentialParentProjectsParsedForCombo
        });   
    }

    setPossibleParentProjects(selectedProjectTypeId:IntranetProjectType):void {
        this.getPossibleParentProjects()
        .then(potentialParentProjectsParsedForCombo => {
            const parentProjectIdField = webix.$$(`${this.idPrefix}editionModalParentProject`) as webix.ui.combo;
            if(parentProjectIdField) {
                parentProjectIdField.define("options", potentialParentProjectsParsedForCombo);
                parentProjectIdField.refresh();
            }
        });
    }

}