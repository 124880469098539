/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetGanttTaskType {
    STANDARD = 9500,
    PROJECTMANAGEMENT = 9501,
    SOFTWARE_DEVELOPMENT = 9502,
    FINALIZATION = 9505,
    PROOFREADING = 9508,
    COPYWRITING = 9509,
}
