import * as chat from "@xbs/chat";
import { promise } from "@xbs/webix-pro";
import { CognovisRestService, IUser} from "../../openapi";


// This is backend for general chat
export default class GeneralChatBackend extends chat.services.Backend {
    
    idPrefix:"cc";
    currentChatUsers:IChatUser[] = [];

    users():Promise<IChatUser[]> {
        return CognovisRestService.getGroupMembers({
            groupId:463
        })
        .then(internalCompanyMembers => {
            const employees = internalCompanyMembers.map(employee => {
                if(employee.user) {
                    return {
                        avatar: employee.portrait_url,
                        email: employee.screen_name,
                        id: employee.user.id,
                        name:employee.user.name,
                        status: this.getUserActivityStatus(employee)                
                    }
                }
            });
            this.currentChatUsers = employees;
            return promise.resolve(this.currentChatUsers);
        });
    }

    getUserActivityStatus(user:IUser):number {
        const twentyMinutes = 1200*20;
        if(user.seconds_since_last_request && user.seconds_since_last_request <= twentyMinutes) {
            return 2
        } else {
            return 1
        }
    }

}
 