import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import BaseView from "views/baseview";
import { ICompany, WebixPortalPortletService, IWebixPortlet } from "../../../sources/openapi";
import CompanyCreationModal from "views/modals/company-creation-modal";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import PriceListTable from "./price-list-table";
import ObjectNotesPortlet from "views/portlets/object-notes-portlet";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import CompanyProjects from "./company-projects";
import UsersListPortlet from "views/portlets/users-list-portet";
import MessagesPortlet from "views/portlets/messages-portlet";
import MainTranslatorsPorltet from "views/users-overview/main-translators-portlet";
import CompanyBasicInfoPortlet from "./company-basic-info-portlet";
import { JetView } from "webix-jet";
import DynCompanyBasicInfoPortlet from "./dyn-company-basic-info-portlet";

export default class CompanyDetails extends BaseView {
    
    idPrefix = "cd";
    companyId:number;
    companyData:ICompany;
    companyEditModal:CompanyCreationModal;
    webixHelpers:WebixHelpers;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

    config():webix.ui.layoutConfig {
        this.companyId = this.getParam("company_id", false);
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        const layout = {
            view:"scrollview",
            gravity:1,
            css: "cog-content",
            autowidth:true,
            id:`${this.idPrefix}MainContainer`,
            body:{
                padding:13,
                id:`${this.idPrefix}MainContainerBody`,
                rows:[
                    {
                        view:"toolbar",
                        css: "cog-content",
                        cols:[
                            {
                                view:"button",
                                type:"icon",
                                align:"left",
                                icon: "mdi mdi-arrow-left",
                                width: 30,
                                css: "cog-content",
                                click:() => {
                                    CognovisNavigator.back("main/companies-overview.companies");
                                }
                            }
                        ]
                    }, 
                ]
            }
        } as webix.ui.layoutConfig;
        return layout
    }

    init():void {
        this.cognovisPleaseWaitWindow.hide();
        const mainContainer = webix.$$(`${this.idPrefix}MainContainerBody`) as webix.ui.layout;
            WebixPortalPortletService.getWebixPortlets({objectId:this.companyId, pageUrl:"company-details"})
            .then(portlets => {
                const portletsArr = this.createPortlets(portlets);
                const rows = this.figureOutProperLayout(portletsArr);
                rows.map(row => {
                    mainContainer.addView(row);
                });
        });
    }

    createPortlets(components:IWebixPortlet[] ):webix.ui.layoutConfig[] {
        const views:webix.ui.layoutConfig[] = [];
        const sortedComponents = components.sort((a,b) => (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0))
        sortedComponents.map(webixPortlet => {
            let portlet;
            switch(webixPortlet.portlet_type.name) {
                case "CompanyBasicInfoPortlet":
                    //portlet = new CompanyBasicInfoPortlet(this.app, this.companyId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = new DynCompanyBasicInfoPortlet(this.app, this.companyId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "CompanyProjects":
                    portlet = new CompanyProjects(this.app, this.companyId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`))
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "PriceListTable":
                    portlet = new PriceListTable(this.app, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "UserListPortlet":
                    portlet = new UsersListPortlet(this.app, this.companyId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "MessagesPortlet":
                    portlet = new MessagesPortlet(this.app, {companyId:this.companyId}, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "UserNotesPortlet":
                    portlet = new ObjectNotesPortlet(this.app, this.companyId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "MainTranslatorsPortlet":
                    portlet = new MainTranslatorsPorltet(this.app, this.companyId ,"", "im_company", webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
            }
            views.push(portlet);
        });
        return views;
    }

    convertJetViewToLayout(jetview:JetView, portletId:number, name:string, position:string):webix.ui.layoutConfig {
        return {
            view:"layout",
            name:name,
            componentPosition:position,
            portletId:portletId,
            rows:[
                jetview
            ]
        } as webix.ui.layoutConfig
    }

    figureOutProperLayout(portlets:webix.ui.layoutConfig[]):webix.ui.layoutConfig[] {
        const layoutArr:webix.ui.layoutConfig [] = [];
        const totalRows = Math.ceil(portlets.length / 2);
        const alreadyUsedLeftPortlets = [];
        const alreadyUsedRightPortlets = [];
        const leftPortlets = portlets.filter(portlet => portlet["componentPosition"] === "left");
        const rightPortlets = portlets.filter(portlet => portlet["componentPosition"] === "right");
        for(let index = 1; index <= totalRows; index ++) {
            let leftPortlet = {};
            let rightPortlet = {};
            // Find next unused left portlet
            leftPortlets.reverse().map(lPortlet => {
                if(alreadyUsedLeftPortlets.indexOf(lPortlet["portletId"]) === -1) {
                    leftPortlet = lPortlet;
                }
            });
            // Find next unused right portlet
            rightPortlets.reverse().map(rPortlet => {
                if(alreadyUsedRightPortlets.indexOf(rPortlet["portletId"]) === -1) {
                    rightPortlet = rPortlet;
                }
            });
            const layout = {
                view:"layout",
                height:0,
                rows:[
                    {
                        view:"spacer",
                        height:13
                    },
                    {
                        view:"layout",
                        cols:[
                            leftPortlet,
                            {
                                view:"spacer",
                                width:13
                            },
                            rightPortlet
                        ]
                    }
                ]
            } as webix.ui.layoutConfig
            if(leftPortlet) {
                alreadyUsedLeftPortlets.push(leftPortlet["portletId"]);
            }
            if(rightPortlet) {
                alreadyUsedRightPortlets.push(rightPortlet["portletId"]);
            }
            layoutArr.push(layout);
        };
        return layoutArr
    }


}