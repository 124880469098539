/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IDynfieldObjectType } from '../models/IDynfieldObjectType';
import type { IReportModule } from '../models/IReportModule';
import type { IReportModuleBody } from '../models/IReportModuleBody';
import type { NumberFloat } from '../models/NumberFloat';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebixPortalReportManagerService {

    /**
     * Returns the \"modules\" for the report manager. Literally a list of reports and their configuration text
     * @returns IReportModule Array of modules
     * @throws ApiError
     */
    public static getReportModules(): CancelablePromise<Array<IReportModule>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/report_modules',
        });
    }

    /**
     * Returns the \"objects\" definition for the report manager
     * @returns IDynfieldObjectType Generic Response description for cog_rest::get::report_object_types
     * @throws ApiError
     */
    public static getReportObjectTypes(): CancelablePromise<Array<IDynfieldObjectType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/report_object_types',
        });
    }

    /**
     * @returns IReportModule Single Report module
     * @throws ApiError
     */
    public static postReportModule({
        requestBody,
    }: {
        /**
         * Name/Config information of the module
         */
        requestBody: IReportModuleBody,
    }): CancelablePromise<IReportModule> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/report_module',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a module
     * @returns string Generic description
     * @throws ApiError
     */
    public static deleteReportModule({
        reportModuleId,
    }: {
        /**
         * ID of the report
         */
        reportModuleId: NumberFloat,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/report_module',
            query: {
                'report_module_id': reportModuleId,
            },
        });
    }

    /**
     * Updates a report module
     * @returns IReportModule Single Report module
     * @throws ApiError
     */
    public static putReportModule({
        reportModuleId,
        requestBody,
    }: {
        /**
         * ID of the report
         */
        reportModuleId: NumberFloat,
        /**
         * Name/Config information of the module
         */
        requestBody: IReportModuleBody,
    }): CancelablePromise<IReportModule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/report_module',
            query: {
                'report_module_id': reportModuleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
