/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetSalutation {
    DEAR_MR_ = 41000,
    DEAR_MRS_ = 41001,
    DEAR_LADIES_AND_GENTLEMEN = 41002,
    LIEBER = 41003,
    LIEBE = 41004,
}
