import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { IJetApp } from "webix-jet";
import DynfieldsBasicLayout from "../../../sources/modules/cog-dynfields/dynfields-basic-layout";
import { CognovisRestDynamicService, IdValue } from "../../../sources/openapi";

interface IDynfieldBasicLayout {
    getObjectData:(objectId:number) => Promise<{[key: string]: string | number}>
}

export default class DynProjectInfoPortlet extends DynfieldsBasicLayout implements IDynfieldBasicLayout {

    objectId:number;
    portletId:number;
    title:string;
    predefinedFields:webix.ui.baseviewConfig[];

    constructor(app:IJetApp, objectId:number, portletId?:number, title?:string) {
        const customTemplates = [
            {
                field:"company_id",
                template:(id:number, value:string) => {
                    return `<a class='text-link' href='/#!/company?company_id=${id}'>${value}</a>`;
                }
            },
            {
                field:"processing_time",
                template:(id:number, value:string) => {
                    if(value === "") {
                        return `<span>tbd</span> <i id="to_be_determined" class='fa fa-question-circle' aria-hidden='true'></i>`;
                    } else {
                        return value;
                    }
                }
            },
        ];
        super(app, objectId, "dpip", { objectType: "im_project", pageUrl: "/#!/project-info", objectId:objectId, portletId:portletId, customTemplates:customTemplates}, i18nHelper.getTranslation("Project Basic Information"), i18nHelper.getTranslation("Edit project"), (data:unknown, callback?:() => void) => {
            this.submit(objectId, data as IdValue);
            callback();
        });
        this.predefinedFields = [
            // {
            //     view:"multiselect",
            //     name:"target_language_ids",
            //     label:i18nHelper.getTranslation("target_languages"),
            //     sortOrder:48,
            //     editable:false
            // }
        ] as unknown as webix.ui.baseviewConfig[];

        this.objectId = objectId;
        if(title) {
            this.title = title;
        }
        this.portletId = portletId;
        //this.webixHelpers = container.resolve(WebixHelpers);
    }

    config():webix.ui.layoutConfig {
        return {
            view:"layout",
            rows:[
                this.getCoreLayout()
            ]
        }
    }

    init():void {
        this.showLoader();
        this.getObjectData(this.objectId)
        .then(project => {
            return this.getDynfields(this.dynfieldsConfig.objectType, this.dynfieldsConfig.pageUrl)
            .then(dynfields => {
                this.setData(project);
                this.insertComponents(); 
                this.addTooltips();
            });
        });
    }


    getObjectData(objectId:number):Promise<{[key: string]: string | number}> {
        return CognovisRestDynamicService.getDynamicObject({
            objectId:objectId,
            pageUrl:this.dynfieldsConfig.pageUrl
        })
        .then(userData => {
            return this.prepareData(userData);
        });
    }

    submit(objectId:number, data:IdValue):void {
        CognovisRestDynamicService.putDynamicObject({
            requestBody:{
                object_id:objectId,
                id_values:data
            }
        })
        .then(res => {
            this.setData(this.prepareData(res));
            this.injectData();
            this.addTooltips();
        });
    }

    addTooltips():void {
        const tbdElement = document.getElementById("to_be_determined");
        if(tbdElement) {
            webix.TooltipControl.addTooltip(tbdElement, i18nHelper.getTranslation("To be determined"));
        }  
    }


}