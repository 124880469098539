/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IBizObjectMember } from '../models/IBizObjectMember';
import type { Id } from '../models/Id';
import type { IError } from '../models/IError';
import type { IGetBasicProjectInfo } from '../models/IGetBasicProjectInfo';
import type { IGetCompanyNewProjectDefaults } from '../models/IGetCompanyNewProjectDefaults';
import type { ImProjectId } from '../models/ImProjectId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IntegerInt3230 } from '../models/IntegerInt3230';
import type { IUserForSwitching } from '../models/IUserForSwitching';
import type { IUserProject } from '../models/IUserProject';
import type { UserId } from '../models/UserId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebixPortalProjectService {

    /**
     * Handler for GET rest call which return basic project info for portals.<br>        This is a replacement for 'portlets' used in old interface.<br>        Currently used in FreelancerPortal and CustomerPortal, PM App needs more details in that section.<br><br>        This procedure decides which values to display.
     * @returns IGetBasicProjectInfo Generic Response description for cog_rest::get::basic_project_info
     * @throws ApiError
     */
    public static getBasicProjectInfo({
        projectId,
    }: {
        /**
         * ProjectId for which to show the project_info
         */
        projectId?: IntegerInt32,
    }): CancelablePromise<Array<IGetBasicProjectInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/basic_project_info',
            query: {
                'project_id': projectId,
            },
        });
    }

    /**
     * Get the most often used values for the creation of a new project
     * @returns IGetCompanyNewProjectDefaults object of customer defaults
     * @throws ApiError
     */
    public static getCompanyNewProjectDefaults({
        customerId,
    }: {
        /**
         * company_id of customer
         */
        customerId: IntegerInt32,
    }): CancelablePromise<IGetCompanyNewProjectDefaults> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/company_new_project_defaults',
            query: {
                'customer_id': customerId,
            },
        });
    }

    /**
     * Returns the project_members for a project. Extends the biz object members with freelancers
     * @returns IBizObjectMember Array of Members for this project
     * @throws ApiError
     */
    public static getProjectMembers({
        projectId,
    }: {
        /**
         * Project for which we want to see the project_members
         */
        projectId: ImProjectId,
    }): CancelablePromise<Array<IBizObjectMember>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project_members',
            query: {
                'project_id': projectId,
            },
        });
    }

    /**
     * Returns all projects of which user is a member
     * @returns IUserProject Generic Response description for cog_rest::get::user_projects
     * @throws ApiError
     */
    public static getUserProjects({
        userId,
    }: {
        /**
         * User for whom we want to get the list of his active project
         */
        userId?: Id,
    }): CancelablePromise<Array<IUserProject>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_projects',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * Handler for GET call for an PO Admin to get possible users wcich Admin can fake
     * @returns IUserForSwitching Array of possible 'to switch' users
     * @throws ApiError
     */
    public static getUserSwitches({
        realUserId,
        limit,
    }: {
        /**
         * user_id of real user (admin user_id)
         */
        realUserId?: IntegerInt32,
        /**
         * how many should we display
         */
        limit?: IntegerInt3230,
    }): CancelablePromise<Array<IUserForSwitching>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_switches',
            query: {
                'real_user_id': realUserId,
                'limit': limit,
            },
        });
    }

    /**
     * it literally means updating im_projects project_lead_id.<br>        If no new_project_lead_id is provided, we use rest_user_id as default.
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static postProjectTakeOver({
        projectId,
        newProjectLeadId,
    }: {
        /**
         * project in which we want to do the 'take_over'
         */
        projectId: ImProjectId,
        /**
         * user who now will became new pm (new project_lead_id in `im_projects`)
         */
        newProjectLeadId?: UserId,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/project_take_over',
            query: {
                'project_id': projectId,
                'new_project_lead_id': newProjectLeadId,
            },
        });
    }

}
