import { IJetApp, JetView } from "webix-jet";

export default class CognovisBasicModal extends JetView {

    defaultWidth:600;
    defaultHeight:480;

    constructor(app:IJetApp) {
        super(app,{});
    }

    config():webix.ui.windowConfig {
        const windowLayoutConfig = this.getMainLayout(" ", this.defaultWidth, this.defaultHeight);
        return windowLayoutConfig;
    }

    getMainLayout(title:string, width?:number, height?:number):webix.ui.windowConfig {
        const windowLayoutConfig = {
            view: "window",
            css:"cognovis-basic-modal",
            localId:"cognovisBasicModal",
            position: "center",
            head:`${title}`,
            width: width,
            height:height,
            modal: true,
            close:true,
            body: {
                rows:[
                ]
            }
        } as unknown as webix.ui.windowConfig
        return windowLayoutConfig;
    }

    open(content:webix.ui.layoutConfig, buttons:webix.ui.layoutConfig, title?:string):void {
        const window = (this.getRoot() as webix.ui.window);
        this.setContent(content, buttons, title);
        window.show();
    }

    close():void {
        const window = (this.getRoot() as webix.ui.window);
        window.close();
    }

    hide():void {
        const window = (this.getRoot() as webix.ui.window);
        window.hide();
    }

    setContent(content:webix.ui.layoutConfig, buttons:webix.ui.layoutConfig, newTitle?:string):void {
        const parentContainer = (this.$$("cognovisBasicModal") as webix.ui.window);
        const body = {
            rows:[
                content,
                {
                    view:"layout",
                    css:"action-buttons-container",
                    cols:[buttons]
                }
            ]
        };
        if(newTitle) {
            if(parentContainer.getHead().getChildViews()[0]) {
                (parentContainer.getHead().getChildViews()[0] as webix.ui.template).setHTML(newTitle);
            }
        }
        webix.ui(body, parentContainer.getBody());
    }

    setTitle(newTitle:string):void {
        const parentContainer = (this.$$("cognovisBasicModal") as webix.ui.window);
        if(parentContainer.getHead().getChildViews()[0]) {
            (parentContainer.getHead().getChildViews()[0] as webix.ui.template).setHTML(newTitle);
        }
    }

}