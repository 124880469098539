/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean0 } from '../models/Boolean0';
import type { IDayOff } from '../models/IDayOff';
import type { IError } from '../models/IError';
import type { ImProfileId } from '../models/ImProfileId';
import type { ImUserAbsenceId } from '../models/ImUserAbsenceId';
import type { IUserAbsence } from '../models/IUserAbsence';
import type { IUserAbsenceBody } from '../models/IUserAbsenceBody';
import type { PersonId } from '../models/PersonId';
import type { StringDate } from '../models/StringDate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestAbsencesService {

    /**
     * Returns the list of days a user is off due to public holidays or weekends. Useful in displaying datepickers.
     * @returns IDayOff Array of days off
     * @throws ApiError
     */
    public static getDaysOff({
        startDate,
        endDate,
    }: {
        /**
         * Which is the start date we look at
         */
        startDate: StringDate,
        /**
         * Which is the last day to consider for days off
         */
        endDate: StringDate,
    }): CancelablePromise<Array<IDayOff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/days_off',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }

    /**
     * Information about user absence.
     * @returns IUserAbsence Array of Absences
     * @throws ApiError
     */
    public static getUserAbsence({
        absenceId,
        ownerId,
        groupId,
        onlyFuture,
    }: {
        /**
         * Absence we would like to get information for
         */
        absenceId?: ImUserAbsenceId,
        /**
         * User we want to get absences for
         */
        ownerId?: PersonId,
        /**
         * group_id which we are looking for
         */
        groupId?: ImProfileId,
        /**
         * only search for absences which end_date is in future
         */
        onlyFuture?: Boolean0,
    }): CancelablePromise<Array<IUserAbsence>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_absence',
            query: {
                'absence_id': absenceId,
                'owner_id': ownerId,
                'group_id': groupId,
                'only_future': onlyFuture,
            },
        });
    }

    /**
     * Create a new vacation
     * @returns IUserAbsence Currently created absence after running through all workflows
     * @throws ApiError
     */
    public static postUserAbsence({
        requestBody,
    }: {
        /**
         * Information of the user absence
         */
        requestBody: IUserAbsenceBody,
    }): CancelablePromise<IUserAbsence> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user_absence',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove an absence if possible
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteUserAbsence({
        absenceId,
    }: {
        /**
         * Absence we want to be able to delete
         */
        absenceId: ImUserAbsenceId,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user_absence',
            query: {
                'absence_id': absenceId,
            },
        });
    }

}
