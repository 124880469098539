/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Id } from '../models/Id';
import type { IMail } from '../models/IMail';
import type { IMailBody } from '../models/IMailBody';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IPostSendMail } from '../models/IPostSendMail';
import type { PartyId } from '../models/PartyId';
import type { StringSentDateDesc } from '../models/StringSentDateDesc';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestMailService {

    /**
     * Returns an array of Mails send with project-open if they are stored in the database (mail log)
     * @returns IMail Mail messages we stored in the database
     * @throws ApiError
     */
    public static getMessage({
        partyId,
        companyId,
        contextId,
        limit,
        orderBy,
    }: {
        /**
         * ID of the party who was involved in an e-mail
         */
        partyId?: PartyId,
        /**
         * ID of the company which was involved in an e-mail
         */
        companyId?: Id,
        /**
         * ID of the object which is the context for the email (like invoice / project / assignment)
         */
        contextId?: IntegerInt32,
        /**
         * how many messages do we return - defaults to 300
         */
        limit?: IntegerInt32,
        /**
         * How do we want to order
         */
        orderBy?: StringSentDateDesc,
    }): CancelablePromise<Array<IMail>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/message',
            query: {
                'party_id': partyId,
                'company_id': companyId,
                'context_id': contextId,
                'limit': limit,
                'order_by': orderBy,
            },
        });
    }

    /**
     * Sends a mail
     * @returns IPostSendMail with the message_id
     * @throws ApiError
     */
    public static postSendMail({
        requestBody,
    }: {
        /**
         * Mail object
         */
        requestBody: IMailBody,
    }): CancelablePromise<IPostSendMail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/send_mail',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
