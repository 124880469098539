/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetTranslationFileType {
    OUT = 600,
    IN = 601,
    MSEXCEL = 602,
    MSPOWERPOINT = 604,
    TRADOS_OUT_PACKAGE = 606,
    TRADOS_RETURN_PACKAGE = 607,
    ZIP_OUT = 608,
    ZIP_IN = 609,
    OTHER = 610,
    IDML = 611,
}
