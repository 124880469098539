import { JetView } from "webix-jet";
import "./success-error-message.style.css";

export default class SuccessMessagePopup extends JetView {
  config(): webix.ui.popupConfig {
    return {
      view: "popup",
      width: 500,
      height: 120,
      position: "top",
      css: "popup__wrapper",
      body: {
        view: "layout",
        type: "clean",
        rows: [
          {
            view: "template",
            css: "success",
            template: `
            <h4>Success!</h4>
            <p>Your form was successfully submitted</p>`,
          },
        ],
      } as unknown as webix.ui.baseview
    };
  }
}
