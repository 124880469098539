/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetVatType {
    PROVIDER_WITH_TAX = 42000,
    PROVIDER_SMALL_BUSINESS_ = 42001,
    LATE_2020_PROVIDER = 42002,
    PROVIDER_EU = 42010,
    PROVIDER_WORLD = 42020,
    MATERIAL_BASED_TAXATION = 42021,
    CUSTOMER_EU_TAX_FREE = 42030,
    CUSTOMER_WORLDWIDE = 42040,
    CUSTOMER_TAX = 42050,
    LATE_2020_CUSTOMER = 42051,
}
