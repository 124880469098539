import { JetView, IJetApp } from "webix-jet";
import Session from "../models/session";
import { UserProfile } from "../modules/cognovis-profile/profiles-types";
import CognovisProfile from "../modules/cognovis-profile/cognovis-profile";
import { IJetConfig } from "webix-jet/dist/types/interfaces";

export default class BaseView extends JetView {

    constructor(app: IJetApp, name: string, config: IJetConfig| unknown) {
        super(app, config);
        const isUserLogged = Session.status();
        isUserLogged.then(userLogged => {
            if (!userLogged) {
                app.show("/login");
            }
            // CognovisProfile.getPossibleUserProfiles();
            CognovisProfile.isUserWithinGroup(UserProfile.PO_ADMIN)
                .then(() => {
                    // do nothing for now
                });
        });
    }
}


