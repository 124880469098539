import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { CognovisRestCompanyService, CognovisRestService, IMainTranslator, IntranetCompanyStatus, WebixPortalFreelancerService } from "../../../sources/openapi";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";


export default class MainTranslatorDetailsModal extends CognovisBasicModal {

    idPrefix = "mtp";
    mode:IMainTranslatorsPortletMode;
    afterSubmitAction:() => void;
    mainTranslatorData:IMainTranslator;
    objectId:number;
    objectName:string;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Add main translator`), 740,360);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(objectId:number, objectName:string, mode:IMainTranslatorsPortletMode,  afterSubmitAction?:() => void, mainTranslatorData?:IMainTranslator):void {
        this.objectId = objectId;
        this.objectName = objectName;
        this.mode = mode;
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
        if(mainTranslatorData) {
            this.mainTranslatorData = mainTranslatorData;
        }
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig);
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
    }


    closeModal():void {
        this.hide();
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`,
                            align: "right",
                            click:() => {
                                this.submitForm()
                            }
                        }  
                    ] 
                };
        return buttons;
    }


    getContent():webix.ui.layoutConfig {
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}SkillsTableContainer`,
                        rows:[
                            {
                                view:"layout",
                                rows:[
                                    this.getForm()
                                ]
                            }
                        ]
                    },                    
                ]
            }
        };
        return layout
    }

    comboDefineAndSetValue(combo:webix.ui.combo, id:number, value:string):void {
        combo.enable();
        const comboSuggest = combo.getList() as webix.ui.list;
        comboSuggest.clearAll();
        comboSuggest.define("data", [{
            id:id,
            value:value
        }]);
        comboSuggest.refresh();
        combo.setValue(id.toString());
        combo.disable();
    }

    getForm():webix.ui.formConfig {
        return {
            view:"form",
            localId:`${this.idPrefix}MainTranslatorForm`,
            elements:[
                {
                    view:"combo",
                    localId:`${this.idPrefix}MainTranslatorCompanyCombo`,
                    label:i18nHelper.getTranslation("Company"),
                    labelWidth:200,
                    name:"companyId",
                    on: {
                        onAfterRender:() => {
                            const me = this.$$(`${this.idPrefix}MainTranslatorCompanyCombo`) as webix.ui.combo;
                            if(this.mode === "im_company") {
                                me.enable();
                                me.setValue(this.objectId.toString());
                                me.disable();
                            }
                        },
                        onChange:() => {
                            if(this.mode === 'im_company') {
                                const me = this.$$(`${this.idPrefix}MainTranslatorCompanyCombo`) as webix.ui.combo;
                                const companyId = Number(me.getValue());
                                CognovisRestService.getUser({
                                    profileId: 465
                                })
                                .then((possibleContacts) => {
                                    const usersCombo = this.$$(`${this.idPrefix}MainTranslatorUserCombo`) as webix.ui.combo;
                                    const possibleContactsForCombo = possibleContacts.map(user => ({id:user.user.id, value:user.user.name}));
                                    const usersComboSuggest = usersCombo.getList() as webix.ui.list;
                                    usersComboSuggest.clearAll();
                                    usersComboSuggest.define("data", possibleContactsForCombo);
                                    usersComboSuggest.refresh();
                                });
                            }
                        }
                    },
                    suggest: {
                        body: {
                            url: () => {
                                return CognovisRestCompanyService.getCompanies({
                                    companyStatusIds: [IntranetCompanyStatus.ACTIVE],
                                })
                                .then(companyInfo => {
                                    const customerOptionsForCombo = companyInfo.map(company => ({id:company.company.id, value:company.company.name}))
                                    return customerOptionsForCombo;
                                });
                            }
                        },
                    },
                },
                {
                    view:"combo",
                    label:i18nHelper.getTranslation("User"),
                    localId:`${this.idPrefix}MainTranslatorUserCombo`,
                    labelWidth:200,
                    name:"userId",
                    on: {
                        onAfterRender:() => {
                            const me = this.$$(`${this.idPrefix}MainTranslatorUserCombo`) as webix.ui.combo;
                            if(this.mode === "user") {
                                me.enable();
                                this.comboDefineAndSetValue(me, this.objectId, this.objectName);
                                me.disable();
                            }
                        }
                    },
                    suggest: {
                        body: []
                    }
                },
                {
                    view:"combo",
                    label:i18nHelper.getTranslation("Source_Language"),
                    labelWidth:200,
                    name:"sourceLanguageId",
                    suggest: {
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet Translation Language"),
                        },
                    }
                },
                {
                    view:"combo",
                    label:i18nHelper.getTranslation("Target_Language"),
                    labelWidth:200,
                    name:"targetLanguageId",
                    suggest: {
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet Translation Language"),
                        },
                    }
                }
            ]
        } as unknown as webix.ui.formConfig
    }

    submitForm():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const form = this.$$(`${this.idPrefix}MainTranslatorForm`) as webix.ui.form;
        const values = form.getValues({
            hidden: true,
            disabled: true
        });
        WebixPortalFreelancerService.postMainTranslator({
            requestBody:{
                customer_id:values.companyId,
                freelancer_id:values.userId,
                source_language_id:values.sourceLanguageId,
                target_language_id:values.targetLanguageId
            }
        })
        .then(res => {
            if(this.afterSubmitAction) {
                this.afterSubmitAction();
            }
            this.cognovisPleaseWaitWindow.hide();
            this.closeModal();
        });
    }

}