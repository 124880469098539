import { singleton } from "tsyringe";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";


@singleton()
export class MyAssignmentsTopTabs {

    idPrefix = "matt";
    currentView = "current-assignments";

    getTopTabs(currentView:string):webix.ui.layoutConfig {
        this.currentView = currentView;
        const tabs = {
            view:"layout",
            css: {
                "border-bottom":"1px solid #DADEE0 !important"
            },
            cols: [
                {
                    view:"layout",
                    id:`${this.idPrefix}TopTabsContainer`,
                    gravity:50,
                    cols:[
                        { 
                            view: "button", 
                            value: i18nHelper.getTranslation("Current_assignments"),
                            id:`${this.idPrefix}TopTabsCurrentAssignments`,
                            width:260,
                            css:(this.currentView === "current-assignments" ? "toptab active" : "toptab"),
                            click: () => {
                                this.currentView = "current-assignments";
                                CognovisNavigator.navigateTo("main/assignments.my-assignments");
                            },
                        },
                        { 
                            view: "button", 
                            value: i18nHelper.getTranslation("Closed_assignments"),
                            id:`${this.idPrefix}TopTabsClosedAssignments`,
                            width:260,
                            css:(this.currentView === "closed-assignments" ? "toptab active" : "toptab"),
                            click: () => {
                                this.currentView = "closed-assignments";
                                CognovisNavigator.navigateTo("main/assignments.my-closed-assignments");
                            },
                        },
                    ],
                },
                {
                    view:"spacer",
                    gravity:50
                }
            ],

        };
        return tabs;
    }

    updateTabsCss(url:string):void {
        const tabsContainer = webix.$$(`${this.idPrefix}TopTabsContainer`) as webix.ui.layout;
        const tabs = tabsContainer.queryView({view:"button"}, "all") as webix.ui.button[];
        tabs.map(tab => {
            webix.html.removeCss(tab.getNode(), "toptab active");
        });
        tabs.map(tab => {
            const viewUrl = tab.config["viewUrl"];
            console.log(viewUrl)
            if(viewUrl && viewUrl === url) {
                webix.html.addCss(tab.getNode(), "toptab active");
            } 
        });
       
    }

    updateRfqBadge(numberOfRequests:number):void {
        const button = (webix.$$(`${this.idPrefix}TopTabsRequests`) as webix.ui.button);
        button.config.badge = numberOfRequests;
        button.refresh();
    }
}