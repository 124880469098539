import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import {
    CognovisRestDynamicService,
    IDynfieldLayoutAttribute,
    IDynfieldLayoutPage,
    IViewColumn
} from "../../../sources/openapi";
import DynviewsSingleColumnEditModal from "views/dynviews-settings/dynviews-single-column-edit-modal";
import DynviewsEditModal from "views/dynviews-settings/dynviews-edit-modal";
import DynfieldsLayoutSingleAttributeEditModal
    from "views/dynfields-settings/dynfields-layout-single-attribute-edit-modal";

export default class DynfieldsSingleLayoutAttributesModal extends CognovisBasicModal {

    pageUrl:string;
    objectType:string;
    idPrefix = "dynfslaem";
    dynfieldsLayoutSingleAttributeEditModal:DynfieldsLayoutSingleAttributeEditModal
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle: string;
    afterSubmitAction: () => void;


    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 800, 620);  // adjust size if needed
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(layout:IDynfieldLayoutPage, afterSubmitAction?: () => void): void {
        this.modalTitle = i18nHelper.getTranslation("Layout page attributes");
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTitle);
        this.cognovisPleaseWaitWindow.hide();
        const modal = (this.getRoot() as webix.ui.window);
        modal.show();
        if(layout) {
            this.pageUrl = layout.page_url;
            this.objectType = layout.object_type;
            this.loadColumns();
        }
    }

    getContent(): webix.ui.layoutConfig {
        // Define your layout
        const layout = {
            view: "scrollview",
            id:`${this.idPrefix}DynfieldsSingleLayoutAttributesContainer`,
            body: {
                padding: 13,
                rows: [
                    this.getTopButtons(),
                    {
                        view: "datatable",
                        id: `${this.idPrefix}DynfieldsSingleLayoutAttributesTable`,
                        columns: [
                            {
                                id: "pretty_name",
                                header: i18nHelper.getTranslation("Pretty name"),
                                fillspace: true
                            },
                            // {
                            //     width:42,
                            //     id:"view-details",
                            //     header:" ",
                            //     tooltip:i18nHelper.getTranslation("Edit view column"),
                            //     template:() => {
                            //         return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-solid fa-magnifying-glass'></span>`;
                            //     }
                            // },
                            {
                                width:42,
                                id:"action-delete",
                                header:" ",
                                tooltip:i18nHelper.getTranslation("Delete view column"),
                                template:() => {
                                    return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-trash'></span>`;
                                }
                            }
                        ],
                        on: {
                            onItemClick: (row) => {
                                const table = webix.$$(`${this.idPrefix}DynfieldsSingleLayoutAttributesTable`) as webix.ui.datatable;
                                const item: IDynfieldLayoutAttribute = table.getItem(row);
                                switch (row.column) {
                                    case "view-details":
                                        this.openLayoutSingleAttributeEditModal(this.pageUrl, this.objectType, item);
                                        break;
                                    case "action-delete":
                                        this.attemptToDeleteSingleLayoutAttribute(item)
                                        break;
                                }
                            }
                        }
                    }
                ]
            }
        };
        return layout;
    }

    getTopButtons():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:40,
            gravity:1,
            css:{
                "margin":"2px 0"
            },
            cols:[
                {
                    view:"spacer",
                    width:0,
                },
                {
                    view:"button",
                    width:180,
                    inputWidth:180,
                    inputHeight:34,
                    height:34,
                    css:"cog-button-big",
                    label:i18nHelper.getTranslation("New layout attribute"),
                    click:() => {
                        this.openLayoutSingleAttributeEditModal(this.pageUrl, this.objectType,undefined);
                    }
                }
            ]
        }
    }

    loadColumns(): void {
        CognovisRestDynamicService.getDynfieldLayoutPageAttributes({ pageUrl: this.pageUrl, objectType: this.objectType})
            .then(pageAttributes => {
                const datatable = ($$(`${this.idPrefix}DynfieldsSingleLayoutAttributesTable`) as webix.ui.datatable);
                if(datatable) {
                    datatable.clearAll();
                    datatable.define("data", pageAttributes);
                }
            });
    }

    getActionButtons(): webix.ui.layoutConfig {
        const buttons = {
            view: "layout",
            padding: 0,
            cols: [
                {
                    view: "button",
                    value: `${i18nHelper.getTranslation('Close')}`,
                    click: () => {
                        this.hide();
                    }
                }
            ]
        };
        return buttons;
    }

    openLayoutSingleAttributeEditModal(pageUrl:string, objectType:string, dynfieldLayoutAttr:IDynfieldLayoutAttribute): void {
        if(!this.dynfieldsLayoutSingleAttributeEditModal) {
            this.dynfieldsLayoutSingleAttributeEditModal = this.ui(DynfieldsLayoutSingleAttributeEditModal) as DynfieldsLayoutSingleAttributeEditModal;
        }
        this.dynfieldsLayoutSingleAttributeEditModal.openModal(this.pageUrl, this.objectType, dynfieldLayoutAttr, () => {
            this.refreshTable();
        });
    }

    attemptToDeleteSingleLayoutAttribute(dynfieldLayoutAttr:IDynfieldLayoutAttribute):void {
        const declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_delete_layout_attribute?");
        webix.confirm({
            title:dynfieldLayoutAttr.pretty_name,
            type: "confirm-warning",
            text: declineOrAccept,
            width: 480
        })
            .then(() => {
                this.deleteSingleLayoutAttribute(dynfieldLayoutAttr.attribute_id, this.pageUrl)
            });
    }

    deleteSingleLayoutAttribute(attributeId:number, pageUrl:string):void {
        CognovisRestDynamicService.deleteDynfieldLayoutPageAttributes({
            pageUrl:pageUrl,
            attributeId:attributeId
        }).then(() => {
            this.refreshTable();
        })

    }

    refreshTable():void {
        const table = webix.$$(`${this.idPrefix}DynfieldsSingleLayoutAttributesTable`) as webix.ui.datatable;
        if(table) {
            this.showLoader();
            table.clearAll();
            this.loadColumns();
            this.hideLoader();
        }
    }

    showLoader():void {
        const container = webix.$$(`${this.idPrefix}DynfieldsSingleLayoutAttributesContainer`) as webix.ui.layout & {showProgress?:(text:string) => void}
        webix.extend(container, webix.ProgressBar);
        container.showProgress(i18nHelper.getTranslation("Please_wait"));
        if(container.showProgress) {
            container.showProgress(i18nHelper.getTranslation("Please_wait"));
        }
    }

    hideLoader():void {
        const container = webix.$$(`${this.idPrefix}DynfieldsSingleLayoutAttributesContainer`) as webix.ui.layout & {hideProgress?:() => void}
        container.hideProgress();
    }

}
