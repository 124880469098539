import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { IJetApp } from "webix-jet";
import DynfieldsBasicLayout from "../../modules/cog-dynfields/dynfields-basic-layout";
import { CognovisRestDynamicService, CognovisRestRelationshipService, CognovisRestService, ICompanyContact, IdValue, IGroup, IIdValue, IUser } from "../../../sources/openapi";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";

interface IDynfieldBasicLayout {
    getPredefinedFields:() => webix.ui.baseviewConfig[];
    getObjectData:(objectId:number) => Promise<{[key: string]: string | number}>;
}

export default class DynUserBasicInfoPortlet extends DynfieldsBasicLayout implements IDynfieldBasicLayout {

    userId:number;
    companyId:number;
    currentUser:IUser;
    currentObject:any;
    currentContact:ICompanyContact;
    webixHelpers:WebixHelpers;
    title:string;
    portletId:number;

    constructor(app:IJetApp, userId:number, title?:string, portletId?:number) {
        super(app, userId, "mpp", { objectType: "person", pageUrl: "account-info", objectId:userId, portletId:portletId}, i18nHelper.getTranslation("My_profile"), i18nHelper.getTranslation("Edit my data"), (data:unknown, callback?:() => void) => {
            this.submit(userId, data as IdValue);
            callback();
        });
        this.objectId = userId;
        if(title) {
            this.title = title;
        }
        this.portletId = portletId;
        this.webixHelpers = container.resolve(WebixHelpers);
    }

    config():webix.ui.layoutConfig {
        return {
            view:"layout",
            rows:[
                this.getCoreLayout()
            ]
        }
    }

    init():void {
        this.showLoader();
        this.getObjectData(this.objectId)
        .then(user => {
            return this.getDynfields(this.dynfieldsConfig.objectType, this.dynfieldsConfig.pageUrl)
            .then(dynfields => {
                this.predefinedFields = this.getPredefinedFields();
                this.setData(user);
                this.setTopAdditionalLayoutElements(this.getAvatarContainer());
                this.setBottomAdditionalLayoutElements(this.getPortraitUploader());
                this.insertComponents();
            });
        });
    }

    getObjectData(userId:number):Promise<{[key: string]: string | number}> {
        return CognovisRestService.getUser({
            userIds:[userId]
        })
        .then(user => {
            this.currentUser = user[0];
            return CognovisRestDynamicService.getDynamicObject({
                objectId:userId,
                pageUrl:this.dynfieldsConfig.pageUrl
            })
            .then(userData => {
                return this.prepareData(userData);
            });
        });
    }

    prepareData(values:IIdValue[]):{} {
        const parsedValues = {};
        values.map(value => {
            parsedValues[value["id"]] = value["value"];
            parsedValues[`${value["id"]}_display`] = "";
            if(value["display_value"].length > 0) {
                parsedValues[`${value["id"]}_display`] = value["display_value"];
            } 
        });
        return parsedValues
    }

    getAvatarContainer():webix.ui.baseviewConfig {
        return {
            view:"button",
            id:`${this.idPrefix}UserPortrait`,
            template:`<div style='text-align:center;' class='anon-avatar-bg'><img alt="" style='margin:0 auto; border-radius:50%; border:2px solid #ffffff; width:150px; height:150px;' src='${this.currentUser.portrait_url}'/></div>`,
            borderless:true,
            height:160,
            click:() => this.attemptToUploadPortrait()
        } as unknown as webix.ui.baseviewConfig
    }

    getPredefinedFields():webix.ui.textConfig[] {
        const predefinedFields = [
            {
                view:"text",
                name:"password",
                type:"password",
                label:"password",
                labelWidth:270
            }
        ]
        return []
    }

    attemptToUploadPortrait():void {
        const userPortraitUploader = (this.$$(`UserPortraitUploader`) as webix.ui.uploader);
        userPortraitUploader.fileDialog();
    }

    getPortraitUploader():webix.ui.uploaderConfig {
        const uploader = {
            view: "uploader",
            localId: `UserPortraitUploader`,
            name: "files",
            autosend: false,
            css:{
                "width":"0px",
                "z-index":"-2 !important"
            },
            width:0,
            upload: this.webixHelpers.getUrlWithApiKey("upload_cr_file"),
            value:"",
            formData: null,
            datatype: "json",
            on: {
                onAfterFileAdd:() => {
                    // First we check for existing avatar->user relationship
                    CognovisRestRelationshipService.getRelationships({
                        objectIdOne:this.objectId,
                        relType:"user_portrait_rel"
                    })
                    .then(portraitsRels => {
                        const fileUploader = (this.$$(`UserPortraitUploader`)) as webix.ui.uploader;
                        if(portraitsRels[portraitsRels.length - 1]) {
                            fileUploader.define("formData",{
                                file_item_id:portraitsRels[portraitsRels.length -1].object_two.id,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(fileRes => {
                                //
                            });
                        } else {
                            fileUploader.define("formData",{
                                context_id:this.objectId,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(filesRes => {
                            CognovisRestRelationshipService.postRelationship(
                                {
                                    requestBody:{
                                        object_id_one:this.objectId,
                                        object_id_two:filesRes.file_id,
                                        rel_type:"user_portrait_rel"
                                    }
                                }
                            )
                            });
                        }
                        window.location.reload();
                    });
                }
            }
        }
        return uploader
    }

    submit(objectId:number, data:IdValue):void {
        CognovisRestDynamicService.putDynamicObject({
            requestBody:{
                object_id:objectId,
                id_values:data
            }
        })
        .then(res => {
            this.setData(this.prepareData(res));
            this.injectData();
        });
    }
    
}