import { config } from "../../../sources/config";

export class FileHelper {

    static downloadCrFile(fileId:number):void {
        const crDownloadFileEndpoint = `download_cr_file`;
        const bearerToken = webix.storage.local.get("cognovis_bearer_token");
        window.location.href = `${config.restUrl}/${crDownloadFileEndpoint}?file_id=${fileId}&api_key=${bearerToken}`;
    }
    
    static downloadFinanceDocument(invoiceId:number):void {
        const financialDocumentEndpoint = `invoice_document`;
        const bearerToken = webix.storage.local.get("cognovis_bearer_token");
        window.location.href = `${config.restUrl}/${financialDocumentEndpoint}?invoice_id=${invoiceId}&api_key=${bearerToken}`;     
    }

    static downloadAsCsv(projectId:number):void {
        const csvEdnpoint = `trans_tasks_as_file?project_id=${projectId}`;
        const bearerToken = webix.storage.local.get("cognovis_bearer_token");
        window.location.href = `${config.restUrl}/${csvEdnpoint}&api_key=${bearerToken}`;     
    }

    static downloadCrFileUrl(fileId:number,bearerToken?:string):string {
        const crDownloadFileEndpoint = `download_cr_file`;
        if (!bearerToken) {
            const bearerToken = webix.storage.local.get("cognovis_bearer_token");
            return `${config.restUrl}/${crDownloadFileEndpoint}?file_id=${fileId}&api_key=${bearerToken}`;
        } 
        return `${config.restUrl}/${crDownloadFileEndpoint}?file_id=${fileId}&api_key=${bearerToken}`;
    }

    static getImageUrl(url:string):string {
        return `${config.serverUrl}${url}`;
    }

}