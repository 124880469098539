
import { ProjectsTableBuilder } from "views/project-overview/projects-table-builder";
import { IJetApp} from "webix-jet";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { IntranetProjectStatus } from "../../../sources/openapi";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";


export default class CompanyProjects extends CognovisBasicPortlet  {
    
    companyId:number;
    idPrefix = "psp";
    tableId:string;
    projectsTableBuilder:ProjectsTableBuilder;
    portletId:number;
    title:string;

    constructor(app:IJetApp, companyId:number, portletId?:number, title?:string) {
        super(app, {});
        this.companyId = companyId;
        this.tableId = `${this.idPrefix}-CompanyProjectsTable-${this.companyId}`;
        this.projectsTableBuilder = container.resolve(ProjectsTableBuilder);
        this.portletId = portletId;
        if(title) {
            this.title = title;
        }
    }
    
    init():void {
        super.init();
        const portlet = {
            view: "layout",
            gravity:1,
            css:"cog-box-content",
            localId:`${this.idPrefix}CompanyProjects`,
            rows:[
                this.getCompanyProjectsTable()
            ],
        };
        this.components = [portlet];
        this.insertComponents();
        
    }

    getCompanyProjectsTable():webix.ui.datatableConfig {
        const CompanyProjectsTable = this.projectsTableBuilder.buildProjectsTable(
            this.tableId, 
            ["project_nr", "project_name", "cost_invoices_cache", "deadline", "project_status"],
            [IntranetProjectStatus.OPEN, IntranetProjectStatus.POTENTIAL, IntranetProjectStatus.DELIVERED, IntranetProjectStatus.CLOSED],
            false,
            [],
            (total:number) => {
                const container = this.$$(`${this.idPrefix}CompanyProjects`) as webix.ui.layout;
                if(total > 0) {
                    //container.show();
                } else {
                    //container.hide();
                }
            },
            [
                {"companyId":this.companyId}
            ]
        );
        return CompanyProjectsTable
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: i18nHelper.getTranslation("Company_projects"), 
                    borderless: true
                }
            ]
        };
        return header;
    }

}