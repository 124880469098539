import { container } from "tsyringe";
import { CognovisRestService, ICategoryObject, IFreelancePackage, ITransWorkflowStep, IAssignment, IntranetFreelanceAssignmentStatus, IntranetTransTaskType, WebixPortalAssignmentService, ITransProject, IntranetCustKolibriRestService, WebixPortalTranslationService, CognovisRestInvoiceService, IntranetCostStatus} from "../../openapi/index";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import  { CognovisTransHelper } from  "../../modules/cognovis-trans-helper/cognovis-trans-helper";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import EditBatches from "./modals/edit-batches/edit-batches";
import UpdateAssignmentDeadline from "./modals/update-assignment-deadline/update-assignment-deadline";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import UpdateAssignmentRateModal from "./modals/update-assignment-rate/update-assignment-rate";
import { CognovisOkButtonWindow } from "../../../sources/services/cognovis-ok-button-window";
import AssignmentRatingDetails from "./modals/assignment-rating-details/assignment-rating-details";
import ObjectNotesModal from "../modals/object-notes-modal";
import CognovisFileUploaderModal from "../../../sources/modules/file-helpers/cognovis-file-uploader-modal";
import { DatetimeHelper } from "../../../sources/modules/datetime-helpers/datetime-helpers";
import ProjectDetails from "views/project-details";
import { WebixAlertConfig } from "@xbs/webix-pro";

export default class Assignments extends ProjectDetails {

    projectId:number;
    idPrefix = "ao";
    webixHelpers:WebixHelpers;
    projectWorkflowSteps:ITransWorkflowStep[] = [];
    projectTargetLanguages:ICategoryObject[] = [];
    existingAssignments:IAssignment[] = [];
    grouppedFreelancerPackages:ICognovisGrouppedFreelancePackages<IFreelancePackage>[] = [];
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    cognovisTransHelper:CognovisTransHelper;
    roles:{stageKey:string, translatedName:string}[] = [];
    batchesEditionModal:EditBatches;
    updateAssignmentDeadlineModal:UpdateAssignmentDeadline;
    updateAssignmentRateModal:UpdateAssignmentRateModal;
    currentlyOpenedAssignment:number;
    currentlyOpenedSlotIndex:number;
    modalWindow:any;
    cognovisOkWindow: CognovisOkButtonWindow;
    assignmentNotesModal:ObjectNotesModal;
    fileUploaderModal:CognovisFileUploaderModal;
    panelsThatShouldStayOpen:string[] = [];

    config():webix.ui.scrollviewConfig {
        this.webixHelpers = container.resolve(WebixHelpers);
        this.projectId = this.getParam("project_id", true);
        if(this.getParam("languages", true)) {
            this.panelsThatShouldStayOpen = this.getParam("languages", true).split(",");
        }
        this.cognovisTransHelper = container.resolve(CognovisTransHelper);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.cognovisOkWindow = container.resolve(CognovisOkButtonWindow);
        const layout = this.getMainLayout();
        return layout;
    }

    init():void {
        this.assignmentNotesModal = this.ui(ObjectNotesModal) as ObjectNotesModal;
        this.loadLayout(this.panelsThatShouldStayOpen);
    }

    urlChange():void {
        const newProjectId = this.getParam("project_id", true);
        if(newProjectId !== this.projectId) {
            this.loadLayout();
        }
        super.urlChange();
    }

    loadLayout(openedLanguagePanels:string[] = []):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        if(openedLanguagePanels.length === 0) {
            openedLanguagePanels = this.panelsThatShouldStayOpen;
        }
        this.getPanels(openedLanguagePanels)
        .then(panels => {
            this.setPanels(panels);
            this.cognovisPleaseWaitWindow.hide();
        })
        .catch(() => {
            this.cognovisPleaseWaitWindow.hide();
        });
    }

    getMainLayout():webix.ui.scrollviewConfig {
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                view: "layout",
                type: "clean",
                css:"cog-content",
                id:"assignmentsOverviewContainer",
                autoheight:true,
                padding:13,
                responsive: true,
                rows: [
                    {
                        view:"toolbar",
                        css:"cog-content",
                        cols: [
                            { 
                                view: "button", 
                                value: i18nHelper.getTranslation(`New_Assignment`),
                                width:180, 
                                id:"newAssignmentButton",
                                align:'left', 
                                css: "cog-button-big",
                                click:() => {
                                    CognovisNavigator.navigateTo(`/main/assignments.freelancer-selection?project_id=${this.projectId}`);
                                } 
                            },
                            { 
                                view: "button", 
                                value:i18nHelper.getTranslation(`Edit_Batches`),
                                id:"aoEditBatchesButton",
                                //type: "icon", 
                                // icon: "fa fa-television", 
                                width:180,
                                css: "cog-button-big",
                                align:'left', 
                                click:() => {
                                    this.openBatchesModal();
                                }
                            },
                            { 
                                view: "button", 
                                value:i18nHelper.getTranslation(`Import_Trados_Packages`),
                                id:"aoImportTradosPackages",
                                width:220,
                                css: "cog-button-big",
                                align:'left', 
                                hidden:true,
                                on: {
                                    onAfterRender:() => {
                                        IntranetCustKolibriRestService.getUpdatePackages({projectId:this.projectId})
                                        .then(res => {
                                            if(res.length > 0) {
                                                const button = webix.$$("aoImportTradosPackages") as webix.ui.button;
                                                button.show();
                                            }
                                        });
                                    }
                                },
                                click:() => {
                                    this.importPackagesFromTrados(this.projectId)
                                }
                            }
                        ],
                    },
                    {
                        view:"layout",
                        //maxHeight:500,
                        id:"assignmentsOverviewPanelsContainer",
                        rows:[]
                    }
                ],
            },
        };
    }

    getPanels(openedPanels:string[] = []):Promise<webix.ui.layoutConfig> {
        return CognovisRestService.getImCategory({categoryType:"Intranet Skill Role"})
        .then(roles => {
            roles.map((role) => {
                if(role.aux_string1) {
                    this.roles.push({
                        stageKey:role.aux_string1,
                        translatedName:role.category_translated
                    })
                }
            });
        })
        .then(() => {
                return WebixPortalAssignmentService.getProjectWorkflowSteps({
                projectId:this.projectId
                })
            .then(workflowSteps => {    
                this.projectWorkflowSteps = workflowSteps;
                return workflowSteps;
            })
            .then(() => {    
                return WebixPortalAssignmentService.getTranslationAssignments({
                    projectId:this.projectId
                })
                .then(assignments => {
                    this.existingAssignments = assignments.filter(assignment => assignment.assignment_status.id !== IntranetFreelanceAssignmentStatus.DENIED && assignment.assignment_status.id !== IntranetFreelanceAssignmentStatus.ASSIGNED_OTHER);
                    return this.existingAssignments
                });
            })
            .then(() => {
                return this.getProjectPackages(this.projectId, this.projectWorkflowSteps)
                .then(freelancePackages => {
                    // First create target languages array
                    this.projectTargetLanguages = freelancePackages.map(flPackage => ({id:flPackage.targetLanguageId, name:flPackage.targetLanguageName}));
                    const panels = this.buildLanguagePanels(freelancePackages, openedPanels);
                    return panels;
                });
            });   
        })
    }

    openBatchesModal():void {
        if(!this.batchesEditionModal) {
            this.batchesEditionModal = this.ui(EditBatches) as EditBatches;
        } 
        this.batchesEditionModal.openBatchesEditionModal(this.projectWorkflowSteps, () => {
            window.location.reload();
            //this.loadLayout();
        });
    }


    openModalWithId<TypeOfModal>(modalComponentId:string, typeOfModal:TypeOfModal, additionalData:ICognovisModalAdditionlData):void {
        const modal = this.$$(modalComponentId) as webix.ui.window;
        if(!modal) {
            this.modalWindow = this.ui(typeOfModal) as unknown as TypeOfModal;
        }
        this.modalWindow.openModal(additionalData, () => {
            this.loadLayout();
        });
    }

    setPanels(panels:webix.ui.layoutConfig):void {
        const mainView = webix.$$("assignmentsOverviewPanelsContainer") as webix.ui.layout;
        const childViews = mainView.getChildViews();
        childViews.forEach(view => {
            mainView.removeView(view);
        }); 
        mainView.addView(panels);
    }

    getProjectPackages(projectId:number, projectWorkflowSteps:ITransWorkflowStep[]):Promise<ICognovisGrouppedFreelancePackages<IFreelancePackage>[]> {
        return WebixPortalAssignmentService.getPackages({
            projectId:projectId
        })
        .then(ungrouppedFreelancePackages=> {
            // Group packages
            const grouppedFreelancePackages = this.cognovisTransHelper.groupFreelancePackages(ungrouppedFreelancePackages, projectWorkflowSteps);
            return grouppedFreelancePackages;
        });
    }

    buildLanguagePanels(freelancePackages:ICognovisGrouppedFreelancePackages<IFreelancePackage>[], openedPanels:string[]):webix.ui.layoutConfig {
        const panelsArr = {
            view:"layout",
            autoheight:true,
            rows:[],
        };
        const possibleTargetLanguages = this.cognovisTransHelper.extractUniqueTargetLanguages(freelancePackages);
        possibleTargetLanguages.map(targetLang => {
            const tlFilteredGrouppedPackages = freelancePackages.filter(flPackage => flPackage.targetLanguageId === targetLang.id);
            const innerContent = this.getLangaugeDatatable(tlFilteredGrouppedPackages,targetLang,this.projectWorkflowSteps, this.existingAssignments);
            let totalUnassignedSlots = 0;
            tlFilteredGrouppedPackages.map(flPackageOne => {
                flPackageOne.packages.map(flPackageTwo => {
                    if(!flPackageTwo.freelancePackage.has_assignments) {
                        totalUnassignedSlots ++;
                    }
                })
            });
            let isCollapsed = true;
            if(possibleTargetLanguages.length === 1 || (totalUnassignedSlots > 0 || openedPanels.indexOf(targetLang.name) > -1)) {
                isCollapsed = false;
            }
            panelsArr.rows.push({view: "accordion", collapsed:isCollapsed,fillspace:true, multi: true,rows:[innerContent]});
            panelsArr.rows.push({view:"spacer", height:13});
        });
        return panelsArr;
    }

    findAssignment(freelancePackageId:number, existingAssignments:IAssignment[]):IAssignment[] {
        const assignments = existingAssignments.filter(flPackage => flPackage.freelance_package.id === freelancePackageId);
        return assignments;
    }

    findProperRole(taskTypeId:number,roles:{stageKey:string, translatedName:string}[], slotIndex:number):string {
        const typeNameFromEnum = IntranetTransTaskType[taskTypeId.toString()].toLocaleLowerCase();
        let roleAsText = "";
        const role = roles.find(role => role.stageKey === typeNameFromEnum);
        if(role) {
            roleAsText = role.translatedName;
        } else {
            roleAsText = `${i18nHelper.getTranslation("Freelancer")} ${slotIndex + 1}`;
        }
        return roleAsText
    }

    getLangaugeDatatable(tlFilteredGrouppedPackages:ICognovisGrouppedFreelancePackages<IFreelancePackage>[], targetLanguage:ICategoryObject, projectWorkflowSteps:ITransWorkflowStep[], existingAssignments:IAssignment[]):webix.ui.accordionitemConfig {
        const submenuIcons = this.getPackageSubmenuIcons();
        const submenu = webix.$$('fpSubmenu') as webix.ui.menu;
        // We create submenu only once
        if(!submenu) {
            webix.ui({
                view:"contextmenu",
                id:`fpSubmenu`,
                width:190,
                data:submenuIcons,
                template: (obj) => {return `<span class='webix_icon ${obj.icon}'></span> ${obj.value}`},
                click:function(id){ 
                    const mainContext = this.$context["obj"].$scope;
                    mainContext.executeSubmenutAction(id, this.currentlyOpenedSlotIndex);
                },
            });
        }
        const targetLanguageName = targetLanguage.name;
        const columnsArr = [
            { 
                header: i18nHelper.getTranslation("Batch"),  
                id:"packageName", 
                minWidth:200, 
                tooltip:function(obj) {
                    return obj.packageTaskNames;
                },
                template:(obj) => {
                    return `<i  class="cog-icon batch-edit-name-icon fas fa-edit"></i>     ${obj.packageName}`;
                }
            },
        ] as { header: string; id?: string;  width?: number; minWidth?:number; maxWidth?:number; css?:any; align?:string; fillspace?:boolean; tooltip?():string 
        | boolean, template?:string | ((row)=>string)}[]
        projectWorkflowSteps.map((projectWorkflowStep, index) => {
            const slotIndex = index;
            // Get correct (ungroupped Freelance Package)
            // Extract correct assignment
            const flRoleInWorkflow = this.findProperRole(projectWorkflowStep.task_type.id,this.roles,slotIndex);
            let iconsAlign = "left";
            if(index === (projectWorkflowSteps.length-1)) {
                iconsAlign = "right";
            }
            columnsArr.push(
                { 
                    id: `fl-${slotIndex}`, 
                    header: flRoleInWorkflow, 
                    fillspace:true,
                    tooltip:() => "",
                    template:(row) => {
                        const packagesInThatGroup = row.grouppedByType[projectWorkflowStep.task_type.id].packages;
                        const slot = packagesInThatGroup[0];
                        if(slot) {
                            const asignments = this.findAssignment(slot.freelancePackage.freelance_package.id, existingAssignments);
                            if(asignments.filter(assignment => assignment.assignment_status.id !== IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED).length > 0) {
                                // Format display
                                const assigneeNamesDisplayArr = [];
                                asignments.map(assignment => {
                                    assigneeNamesDisplayArr.push(`<a href="/#!/user-details?user_id=${assignment.assignee.id}" class="text-link">${assignment.assignee.name}</a>`);
                                    // assigneeNamesDisplayArr.push(`<a href="/#!/assignment-details?assignment_id=${assignment.assignment.id}" class="text-link">${assignment.assignee.name}</a>`);
                                });
                                
                                const assigneeNamesStr = assigneeNamesDisplayArr.join(", ");
                                return assigneeNamesStr;
                            } else {
                                const autoFocusInputId = `${slot.freelancePackage.freelance_package.id}`;
                                return `<a href='#!/freelancer-selection?project_id=${this.projectId}&auto_focus_id=${autoFocusInputId}' class='orange-text-link'>${i18nHelper.getTranslation("Select")}</a>`;
                            }
                        } else {
                            return "";
                        }
                    }
                },
                this.getActionIconsColumn(projectWorkflowStep,slotIndex,this.existingAssignments),
                {   
                    id: `flSubmenuButton`, 
                    width:60,
                    align:iconsAlign,
                    css:{
                        "text-align":iconsAlign
                    },
                    header:"",
                    tooltip:() => "",
                    template:(row) => {
                        const slot = row.grouppedByType[projectWorkflowStep.task_type.id].packages[0];
                        if(slot) { 
                            const assignments = this.findAssignment(slot.freelancePackage.freelance_package.id, existingAssignments);
                            if(assignments.filter(assignment => assignment.assignment_status.id !== IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED).length > 0) {
                                let buttonsHtml = ""
                                assignments.map((assignment) => {
                                    const submenuButtonId = `aoSubmenuButton-${assignment.assignment.id}`;
                                    buttonsHtml = buttonsHtml + `<span style='text-align:left;' cog-fl-index='${slotIndex}' cog-assignment-id='${assignment.assignment.id}' cog-assignment-status-id='${assignment.assignment_status.id}' cog-submenu-button-id='${submenuButtonId}' class='assignment-submenu-icon webix_icon wxi-dots'></span><br/>`;
                                });
                                return buttonsHtml;
                            } else {
                                return "";
                            }
                        } else {
                            return "";
                        }
                        
                    },
                },
            );
        });
        let totalUnassignedSlots = 0;
        tlFilteredGrouppedPackages.map(flPackageOne => {
            flPackageOne.packages.map(flPackageTwo => {
                if(!flPackageTwo.freelancePackage.has_assignments) {
                    totalUnassignedSlots ++;
                }
            })
        });
        let innerHeight = (tlFilteredGrouppedPackages.length * 40) + 62;
        if(innerHeight <= 160) {
            innerHeight = 160;
        }
        const datatable = {
            header: () => {
                if(totalUnassignedSlots > 0) {
                    return `${targetLanguageName}<span class='rounded-number-indicator'>${totalUnassignedSlots}</span>`
                } else {
                    return `${targetLanguageName}`
                }
            },
            autoheight:true,
            body: {
                view: "datatable",
                autoWidth:true,
                tooltip: true,
                height:innerHeight,
                fixedRowHeight:false,
                columns:columnsArr,
                data:tlFilteredGrouppedPackages,
                resizeColumn:true,
                on:{
                    onAfterLoad:function(){
                        webix.delay(function(){
                            if(this.config.columns[1]?.id) {
                                this.adjustRowHeight(this.config.columns[1]?.id); 
                                this.adjustRowHeight(this.config.columns[1]?.id); 
                            } 
                            if(this.config.columns[4]?.id) {
                                this.adjustRowHeight(this.config.columns[4]?.id); 
                            } 
                            if(this.config.columns[7]?.id) {
                                this.adjustRowHeight(this.config.columns[7]?.id); 
                            } 
                            this.adjustRowHeight(this.config?.columns[1]?.id); 
                        this.render();
                        }, this);
                    },
                    onColumnResize:function(){
                        if(this.config.columns[1]?.id) {
                            this.adjustRowHeight(this.config.columns[1]?.id); 
                        } 
                        if(this.config.columns[4]?.id) {
                            this.adjustRowHeight(this.config.columns[4]?.id); 
                        } 
                        if(this.config.columns[7]?.id) {
                            this.adjustRowHeight(this.config.columns[7]?.id); 
                        } 
                        this.render();
                    },
                },
                onClick: {
                    //description modal functionality
                    "wxi-download": function(event, cell)  {
                        const columnConfig = this.getColumnConfig(cell.column);
                        const stepId = columnConfig["step"].task_type.id;
                        const item = this.getItem(cell);
                        const freelancePackageId = item.grouppedByType[parseInt(stepId)].packages[0].freelancePackage.freelance_package.id;
                        const targetLanguageName = item.grouppedByType[parseInt(stepId)].packages[0].freelancePackage.target_language.name;
                        const assignment = this.$scope.findAssignment(freelancePackageId, this.$scope.existingAssignments)[0];
                    },
                    "mdi-file-upload":function(event, cell) {
                        if(!this.$scope.fileUploaderModal) {
                            this.$scope.fileUploaderModal = this.$scope.ui(CognovisFileUploaderModal) as CognovisFileUploaderModal
                        }
                        const freelancePackageId =  parseInt(event.target.attributes["cog-flpackage-id"]["nodeValue"]);
                        const assignment = this.$scope.findAssignment(freelancePackageId, this.$scope.existingAssignments)[0];
                        const freelancePackageName = assignment.freelance_package.name;
                        const modalTitle = `${freelancePackageName}`;
                        this.$scope.fileUploaderModal.openModal(freelancePackageId, "write", {}, modalTitle, "", () => {
                            const columnConfig = this.getColumnConfig(cell.column);
                            const item = this.getItem(cell);
                            const stepId = columnConfig["step"].task_type.id;
                            const targetLanguageName = item.grouppedByType[parseInt(stepId)].packages[0].freelancePackage.target_language.name;
                            this.$scope.loadLayout([targetLanguageName]);
                        });
                    },
                    "wxi-dots":function(e, id) {
                        this.currentlyOpenedAssignment = 0;
                        const menu = webix.$$("fpSubmenu") as webix.ui.menu;
                        const assignmentStatusId = parseInt(e.target.attributes["cog-assignment-status-id"].value);
                        const assignmentId = parseInt(e.target.attributes["cog-assignment-id"]["nodeValue"]);
                        this.$scope.currentlyOpenedAssignment = assignmentId;
                        this.$scope.currentlyOpenedSlotIndex = parseInt(e.target.attributes["cog-fl-index"]["nodeValue"]);
                        menu.show(e.target);
                        menu["$context"] = { obj:this, id:id };
                        this.$scope.togglePackageSubmenutIcons(assignmentStatusId, menu);
                    },
                    "mdi-package-variant-closed": function(event, cell) {
                        if(!this.$scope.fileUploaderModal) {
                            this.$scope.fileUploaderModal = this.$scope.ui(CognovisFileUploaderModal) as CognovisFileUploaderModal
                        }
                        const columnConfig = this.getColumnConfig(cell.column);
                        const stepId = columnConfig["step"].task_type.id;
                        const item = this.getItem(cell);
                        const freelancePackageId = item.grouppedByType[parseInt(stepId)].packages[0].freelancePackage.freelance_package.id;
                        const assignment = this.$scope.findAssignment(freelancePackageId, this.$scope.existingAssignments)[0];
                        const modalTitle = `${assignment.assignment.name}`;
                        this.$scope.fileUploaderModal.openModal(assignment.assignment.id, "read", modalTitle);
                    },
                    "fa-coins": function(event, cell) {
                        
                        const columnConfig = this.getColumnConfig(cell.column);
                        const stepId = columnConfig["step"].task_type.id;
                        const item = this.getItem(cell);
                        const freelancePackageId = item.grouppedByType[parseInt(stepId)].packages[0].freelancePackage.freelance_package.id;
                        const assignment = this.$scope.findAssignment(freelancePackageId, this.$scope.existingAssignments)[0];
                        if(assignment && assignment?.purchase_order?.id) {
                            const invoiceId = assignment.purchase_order?.id;
                            CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${invoiceId}`);
                        }
                    },
                    "mdi-star": function(e) {
                        const assignmentId = parseInt(e.target.attributes["cog-assignment-id"]["nodeValue"]);
                        this.$scope.currentlyOpenedAssignment = assignmentId;
                        this.$scope.displayAssignmentRatingDetails();
                    },
                    // "fa-comment-dots": function(event, cell) {
                    //     const columnConfig = this.getColumnConfig(cell.column);
                    //     const stepId = columnConfig["step"].task_type.id;
                    //     const item = this.getItem(cell);
                    //     const freelancePackage = (item.grouppedByType[parseInt(stepId)].packages[0].freelancePackage) as IFreelancePackage;
                    //     const buttonId = event.target.attributes["id"].value;
                    //     const buttonEl = webix.toNode(buttonId);
                    //     let currentComment = buttonEl["comment"];
                    //     if(!currentComment) {
                    //         currentComment = freelancePackage.package_comment;
                    //     }
                    //     this.$scope.openNotesModal(buttonId, currentComment, freelancePackage);
                    // },
                    "mdi-calendar-clock": function (event, cell){
                        const updateDeadlinePossible = event.target.attributes["cog-update-deadline-possible"].value;
                        const columnConfig = this.getColumnConfig(cell.column);
                        const stepId = columnConfig["step"].task_type.id;
                        const item = this.getItem(cell);
                        const freelancePackage = item.grouppedByType[parseInt(stepId)].packages[0].freelancePackage;
                        const freelancePackageId = freelancePackage.freelance_package.id;
                        const assignment = this.$scope.findAssignment(freelancePackageId, this.$scope.existingAssignments)[0];
                        if(assignment && updateDeadlinePossible) {
                            this.$scope.currentlyOpenedAssignment = assignment.assignment.id;
                            this.$scope.attemptUpdateDeadline();
                        }
                    },
                    "fa-edit":function (event,cell)  {
                        const item = this.getItem(cell);
                        const freelancePackageName = item.packages[0].freelancePackage.freelance_package.name;
                        this.$scope.openBatchNameEditorModal(freelancePackageName, item.packages);
                    }
                }
            }
        };
        return datatable
    }

    getActionIconsColumn(projectWorkflowStep:ITransWorkflowStep, index:number, existingAssignments:IAssignment[]):{ id: string; header: string; width?: number; template?:string | ((row)=>string)} {        
        const actionIcons = { 
            id: `fla-${index}`, 
            header: ``, 
            width:208,
            align:"left",
            css:{'text-align':'left'},
            step:projectWorkflowStep,
            template:(row) => {
                let arrowIconColorClass = "lightgrey";
                let packageIconColorClass = "lightgrey";
                let checkmarkIconColorClass = "lightgrey";
                let commentIconColorClass = "lightgrey";
                let ratingIconColorClass = "lightgrey";
                let clockIconColorClass = "blue";
                const slot = row.grouppedByType[projectWorkflowStep.task_type.id].packages[0];
                if(slot) {     
                    let assignmentsIconsHtml = "";
                    const assignments = this.findAssignment(slot.freelancePackage.freelance_package.id, existingAssignments);
                    if(slot?.freelancePackage?.package_comment && slot?.freelancePackage?.package_comment.length > 0) {
                        commentIconColorClass = "blue";
                    }
                    if(assignments.filter(assignment => assignment.assignment_status.id !== IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED).length > 0) {
                        assignments.forEach(assignment => {
                            let ratingIconValue = `webix_tooltip='${assignment.assignment.id} - ${assignment.rating_value}'`;
                            if (assignment.purchase_order !== null) {
                                checkmarkIconColorClass = "blue";
                            } else {
                                checkmarkIconColorClass = "lightgrey";
                            }
                            if (assignment.rating_value !== null) {
                                ratingIconColorClass = "green";
                                ratingIconValue = `webix_tooltip='${assignment.rating_value.toFixed(2)}'`;
                            } else {
                                ratingIconColorClass = "lightgrey";
                                ratingIconValue = "";
                            }
                            if (assignment.package_files == null || assignment.package_files.length == 0) {
                                arrowIconColorClass = "orange";
                            } else {
                                arrowIconColorClass = "blue";
                            }
                            
                            if(assignment.assignment_files?.length > 0) {
                                packageIconColorClass = "blue";
                            }

                            const deadlineNotParsed = assignment.assignment_deadline;
                            const properFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
                            const deadline = new Date(deadlineNotParsed);
                            
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const diffTime = ((deadline as unknown as number) - (new Date() as unknown as number));
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                            if(diffDays < 0 && commentIconColorClass !== "lightgray") {
                                clockIconColorClass = "orange";
                            }
                            else if(diffDays === 1 && commentIconColorClass !== "lightgray") {
                                clockIconColorClass = "yellow";
                            }
                            let udpateDeadlinePossible = false;
                            const allStatusesIds = Object.values(IntranetFreelanceAssignmentStatus).filter(value => typeof value === 'number') as number[];
                            // Update deadline icon is special, because we define its visibility other way (aka -> 'Not visible in statuses')
                            const notVisibleStatusesForActionUpdateDeadline = [
                                IntranetFreelanceAssignmentStatus.WORK_DELIVERED, IntranetFreelanceAssignmentStatus.DELIVERY_ACCEPTED, 
                                IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED, IntranetFreelanceAssignmentStatus.ASSIGNMENT_CLOSED
                            ];
                            const possibleStatusesForActionUpdateDeadline = allStatusesIds.filter(assignmentStatusId => notVisibleStatusesForActionUpdateDeadline.indexOf(assignmentStatusId) === -1 );                         
                            if(possibleStatusesForActionUpdateDeadline.indexOf(assignment.assignment_status.id) > -1) {
                                udpateDeadlinePossible = true;
                            }
                            if(!udpateDeadlinePossible) {
                                clockIconColorClass = "lightgrey";
                            }
                            let formattedDeadline = i18nHelper.getTranslation("Update_assignment_deadline");
                            if(deadlineNotParsed) {
                                const date = new Date(DatetimeHelper.parseDateForDatepicker(deadlineNotParsed));
                                formattedDeadline = `${properFormat(date)}`;
                            }
                            const statusIcon = `<span style="color:${assignment.assignment_status.icon_or_color}" webix_tooltip='${i18nHelper.getTranslation("Assignment_status")}: <b>${assignment.assignment_status.name}</b>' class='fas fa-square assignment-overview-status-icon'></span>`;
                            const downloadIcon = `<span webix_tooltip='${i18nHelper.getTranslation("Upload_package_file")}' id='ao-dpi-${slot.freelancePackage.freelance_package.id}-${slot.freelancePackage.package_type.id}-${index}' class='assignment-overview-icon ${arrowIconColorClass} webix_icon mdi mdi-file-upload' cog-assignment-id='${assignment.assignment.id}' cog-flpackage-id='${slot.freelancePackage.freelance_package.id}'></span>`;
                            const packageIcon = `<span webix_tooltip='${i18nHelper.getTranslation("Download_return_file")}' id='ao-pi-${slot.freelancePackage.freelance_package.id}-${slot.freelancePackage.package_type.id}-${index}'  class='assignment-overview-icon ${packageIconColorClass} webix_icon mdi mdi-package-variant-closed'></span>`;
                            const checkMarkIcon = `<span webix_tooltip='${i18nHelper.getTranslation("Go_to_invoice")}' id='ao-poi-${slot.freelancePackage.freelance_package.id}-${slot.freelancePackage.package_type.id}-${index}'  style='font-size:17px' class='assignment-overview-icon ${checkmarkIconColorClass} webix_icon far fa-coins'></span>`;
                            //const commentIcon = `<span webix_tooltip='${i18nHelper.getTranslation("Create_or_update_assignment_note")}' id='ao-ci-${slot.freelancePackage.freelance_package.id}-${slot.freelancePackage.package_type.id}-${index}'  class='assignment-overview-icon ${commentIconColorClass} cursor-pointer webix_icon fas fa-comment-dots'></span>`;
                            const ratingIcon = `<span webix_tooltip='${i18nHelper.getTranslation("Rating_overview")}' id='ao-ri-${slot.freelancePackage.freelance_package.id}-${slot.freelancePackage.package_type.id}-${index}'  cog-assignment-id='${assignment.assignment.id}' ${ratingIconValue} class='assignment-overview-icon ${ratingIconColorClass} webix_icon mdi mdi-star'></span>`;
                            const deadlineIcon = `<span webix_tooltip='${formattedDeadline}' cog-update-deadline-possible='${udpateDeadlinePossible}' id='ao-di-${slot.freelancePackage.freelance_package.id}-${slot.freelancePackage.package_type.idopenNOte}-${index}'  cog-assignment-id='${assignment.assignment.id}' class='${clockIconColorClass} assignment-overview-icon webix_icon mdi mdi-calendar-clock'></span>`;
                            const icons = `${statusIcon} ${downloadIcon} ${packageIcon} ${checkMarkIcon} ${ratingIcon} ${deadlineIcon}<br/>`;
                            assignmentsIconsHtml = assignmentsIconsHtml + icons;
                        });

                    }
                    return assignmentsIconsHtml;
                } else {
                    return "";
                }
            }
        };
        return actionIcons
    }

    getPackageSubmenuIcons():{id:string, value:string, icon:string, visibleStatusesIds:IntranetFreelanceAssignmentStatus[]}[]{
        // E.g 'Show assignment' is always visible, so we can simply dynamicly create array of all enums
        const allStatusesIds = Object.values(IntranetFreelanceAssignmentStatus).filter(value => typeof value === 'number') as number[];
        // Update deadline icon is special, because we define its visibility other way (aka -> 'Not visible in statuses')
        const notVisibleStatusesForActionUpdateDeadline = [
            IntranetFreelanceAssignmentStatus.WORK_DELIVERED, IntranetFreelanceAssignmentStatus.DELIVERY_ACCEPTED, 
            IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED, IntranetFreelanceAssignmentStatus.ASSIGNMENT_CLOSED
        ];
        const visibleStatusesForActionUpdateDeadline = allStatusesIds.filter(assignmentStatusId => notVisibleStatusesForActionUpdateDeadline.indexOf(assignmentStatusId) === -1 );
        const submenuIcons
            :{id:string, value:string, icon:string, visibleStatusesIds:IntranetFreelanceAssignmentStatus[]}[] 
        = [
            // {
            //     id: "aoActionUpdateDeadline", value: "Update deadline",  icon: "far fa-calendar-alt", 
            //     // NOT visible in case: 4225, 4226, 4230, 4231.
            //     visibleStatusesIds:visibleStatusesForActionUpdateDeadline
            //     //visibleStatusesIds:allStatusesIds

            // },
            {
                id: "aoActionUpdateFee", value: i18nHelper.getTranslation("Update_fee"), icon: "fas fa-euro-sign", 
                visibleStatusesIds:[
                    IntranetFreelanceAssignmentStatus.CREATED, IntranetFreelanceAssignmentStatus.REQUESTED, IntranetFreelanceAssignmentStatus.ACCEPTED
                ]
                // Visible in case: 4220, 4221
                //visibleStatusesIds:allStatusesIds
            },
            {
                id: "aoActionRemindFreelancer", value: i18nHelper.getTranslation("Remind_freelancer"), icon: "mdi mdi-bullhorn", 
                visibleStatusesIds:[
                    IntranetFreelanceAssignmentStatus.REQUESTED
                ]
                // Visible in case 4221
            },
            {
                id: "aoActionReassign", value: i18nHelper.getTranslation("Reassign"), icon: "mdi mdi-account", 
                visibleStatusesIds:[
                    IntranetFreelanceAssignmentStatus.CREATED, IntranetFreelanceAssignmentStatus.REQUESTED, IntranetFreelanceAssignmentStatus.DENIED,
                    IntranetFreelanceAssignmentStatus.DELIVERY_REJECTED, IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED
                ]
                // 4220,4221, 4223, 4227, 4230
            },
            {
                id: "aoActionDeleteAssignment", value: i18nHelper.getTranslation("Delete_assignment"), icon: "mdi mdi-trash-can", 
                visibleStatusesIds:[
                    IntranetFreelanceAssignmentStatus.CREATED, IntranetFreelanceAssignmentStatus.REQUESTED, IntranetFreelanceAssignmentStatus.DENIED
                ]
                // 4220, 4221, 4223
            },
            {
                id: "aoActionCancelAssignment", value: i18nHelper.getTranslation("Cancel_assignment"), icon: "fas fa-times", 
                visibleStatusesIds:[
                    IntranetFreelanceAssignmentStatus.ACCEPTED, IntranetFreelanceAssignmentStatus.WORK_DELIVERED
                ]
                // 4222
            },
            {
                id: "aoActionShowAssignment", value: i18nHelper.getTranslation("Show_assignment"), icon: "mdi mdi-magnify", 
                visibleStatusesIds:allStatusesIds
            },
           
        ];
        return submenuIcons;
    }

    togglePackageSubmenutIcons(assignmentStatusId:IntranetFreelanceAssignmentStatus, menu:webix.ui.menu):void {
        const submenuActionButtons = this.getPackageSubmenuIcons();
        submenuActionButtons.map(submenuItem => {
            if(submenuItem.visibleStatusesIds.indexOf(assignmentStatusId) > -1) {
                menu.showItem(submenuItem.id)
            } else {
                menu.hideMenuItem(submenuItem.id)
            }
        });
    }

    executeSubmenutAction(actionId:string):void {
        switch(actionId) {
            case "aoActionUpdateDeadline":
                this.attemptUpdateDeadline();
                break;
            case "aoActionUpdateFee":
                this.attemptUpdateAssignmentFee();
                break;
            case "aoActionRemindFreelancer":
                this.attemptRemindFreelancer();
                break;
            case "aoActionReassign":
                this.attemptReassignFreelancePackage();
                break;
            case "aoActionDeleteAssignment":
                this.attemptDeleteAssignment();
                break;
            case "aoActionCancelAssignment":
                this.attemptCancelAssignment();
                break;
            case "aoActionShowAssignment":
                this.goToAssignment()
                break;
        }
    }

    goToAssignment():void {
        const assignment = this.existingAssignments.find(assignment => assignment.assignment.id === this.currentlyOpenedAssignment);
        const assignmentId = assignment.assignment.id;
        CognovisNavigator.navigateTo(`main/assignments.assignment-details?assignment_id=${assignmentId}`);
    }
    
    displayAssignmentRatingDetails():void {
        // Find out proper assignment
        const assignment = this.existingAssignments.find(assignment => assignment.assignment.id === this.currentlyOpenedAssignment);
        if(assignment.rating !== null) {
            this.openModalWithId("assignmentRatingDetailsModal", AssignmentRatingDetails, {assignment:assignment}); 
        }
    }

    attemptUpdateDeadline():void {
        // Find out proper assignment
        const assignment = this.existingAssignments.find(assignment => assignment.assignment.id === this.currentlyOpenedAssignment);
        this.openModalWithId("updateAssignmentDeadlineModal", UpdateAssignmentDeadline, {assignment:assignment});
    }

    attemptUpdateAssignmentFee():void {
        // Find out proper assignment
        const assignment = this.existingAssignments.find(assignment => assignment.assignment.id === this.currentlyOpenedAssignment);
        this.openModalWithId("updateAssignmentRateModal", UpdateAssignmentRateModal, {assignment:assignment});
    }

    attemptRemindFreelancer():void {
        // Find out proper assignment
        const assignment = this.existingAssignments.find(assignment => assignment.assignment.id === this.currentlyOpenedAssignment);
        WebixPortalAssignmentService.postAssignmentRemindFreelancer({
            assignmentId: assignment.assignment.id
        })
        .then(res => {
            webix.alert({
                text:res.message,
                width:300,
                css:"cog-import-fp-trados-modal"
            });
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    attemptReassignFreelancePackage():void {
        // Find out proper assignment
        const assignment = this.existingAssignments.find(assignment => assignment.assignment.id === this.currentlyOpenedAssignment);
        const deletedAssignmentStatusIds = [
            IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED,
            IntranetFreelanceAssignmentStatus.DELIVERY_REJECTED,
            IntranetFreelanceAssignmentStatus.ASSIGNED_OTHER,
            IntranetFreelanceAssignmentStatus.DENIED
        ];
        const autoFocusInputId = `${assignment.freelance_package.id}`;
        if(deletedAssignmentStatusIds.indexOf(assignment.assignment_status.id) > -1) {
            WebixPortalAssignmentService.deleteTranslationAssignments({
                assignmentIds:[this.currentlyOpenedAssignment]
            })
            .then((errors) => {
                this.show(`/main/assignments.freelancer-selection?project_id=${this.projectId}&auto_focus_id=${autoFocusInputId}`);
            })
            .catch(err => {
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            });
            
        } else {
            const assignment = this.existingAssignments.find(assignment => assignment.assignment.id === this.currentlyOpenedAssignment);
            const confirmWarning = `${i18nHelper.getTranslation(`You_want_to_remove`)}: ${assignment.assignment.name}<br/>`;
            webix.confirm({
                title: i18nHelper.getTranslation(`any_are_you_sure_message`),
                type: "confirm-warning",
                text: confirmWarning,
                width: 480
            })
            .then(() => {
                WebixPortalAssignmentService.deleteTranslationAssignments({
                    assignmentIds:[this.currentlyOpenedAssignment]
                })
                .then((errors) => {
                    this.show(`/main/assignments.freelancer-selection?project_id=${this.projectId}&auto_focus_id=${autoFocusInputId}`);
                })
                .catch(err => {
                    webix.alert({
                        title: err.message,
                        text: err.body.message,
                        type: "alert-error",
                        width: 500,
                        css:"cog-remove-tasks-modal"
                    });
                });
            });
        }
    }

    attemptDeleteAssignment():void {
        const assignment = this.existingAssignments.find(assignment => assignment.assignment.id === this.currentlyOpenedAssignment);
        const confirmWarning = `${i18nHelper.getTranslation(`You_want_to_remove`)}: ${assignment.assignment.name}<br/>`;
        webix.confirm({
            title: i18nHelper.getTranslation(`any_are_you_sure_message`),
            type: "confirm-warning",
            text: confirmWarning,
            width: 480
        })
        .then((result) => {
            if(result) {
                WebixPortalAssignmentService.deleteTranslationAssignments({
                    assignmentIds:[this.currentlyOpenedAssignment]
                })
                .then(() => {
                    this.loadLayout();
                });
            }
        });
    }

    attemptCancelAssignment():void {
        const assignment = this.existingAssignments.find(assignment => assignment.assignment.id === this.currentlyOpenedAssignment);
        const confirmWarning = `${i18nHelper.getTranslation("lt_Cancel_accepted_assignment")}: ${assignment.assignment.name} ? <br/> ${i18nHelper.getTranslation("lt_Assignment_and_PO_to_be_removed")}`
        webix.confirm({
            title: i18nHelper.getTranslation(`any_are_you_sure_message`),
            type: "confirm-warning",
            text: confirmWarning,
            width: 500,
            css:"cog-remove-tasks-modal"
        })
        .then(result => {
            if(result && assignment.purchase_order.id) {
                this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                CognovisRestInvoiceService.getInvoice({ invoiceIds: [assignment.purchase_order.id] })
                .then(purchaseOrders => {
                    if (purchaseOrders[0]) {
                        const assignmentPurchaseOrder = purchaseOrders[0];
                        CognovisRestInvoiceService.putInvoice({
                            invoiceId:assignmentPurchaseOrder.invoice.id,
                            requestBody:{
                                company_id:assignmentPurchaseOrder.company.id,
                                company_contact_id:assignmentPurchaseOrder.company_contact.id,
                                cost_status_id:IntranetCostStatus.DELETED,
                                cost_type_id:assignmentPurchaseOrder.cost_type.id,
                                template_id:assignmentPurchaseOrder.template.id,
                                payment_method_id:assignmentPurchaseOrder.payment_method.id,
                                payment_term_id:assignmentPurchaseOrder.payment_term.id,
                                currency_iso:"EUR",
                                effective_date:assignmentPurchaseOrder.effective_date,
                                cost_center_id:assignmentPurchaseOrder.cost_center?.id,
                                delivery_date:assignmentPurchaseOrder.delivery_date
                            }
                        });
                        WebixPortalAssignmentService.putTranslationAssignments({
                            assignmentId:assignment.assignment.id,
                            requestBody:{
                                assignment_status_id:IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED
                            }
                        })
                        .then(() => {
                            this.cognovisPleaseWaitWindow.hide();
                            this.loadLayout();
                        });
                    } else {
                        this.cognovisPleaseWaitWindow.hide();
                    }
                });
            }
        });
    }

    openNotesModal(buttonId:string, currentComment:string, freelancePackage?:IFreelancePackage):void {
        const buttonEl = webix.toNode(buttonId);
        this.assignmentNotesModal.openNotesModal<IFreelancePackage>("assignment-note", i18nHelper.getTranslation("Assignment Note"), freelancePackage, currentComment, "cke5", [], (inputedNote:string) => {
            return WebixPortalAssignmentService.putPackages({
                freelancePackageId:freelancePackage.freelance_package.id,
                requestBody:{
                    package_comment:inputedNote
                } 
            })
            .then(res => {
                buttonEl["comment"] = inputedNote;
                const currentClass = buttonEl.className;
                const newClass = currentClass.concat(" blue");
                buttonEl.className = newClass;
                return res
            })
            .catch(() => {
                this.loadLayout();
            })
        });      
    }

    importPackagesFromTrados(projectId:number):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        IntranetCustKolibriRestService.putUpdatePackages({
            projectId:projectId
        })
        .then(potentialFreelancePackages => {
            let result = "";
            this.cognovisPleaseWaitWindow.hide();
            if(potentialFreelancePackages.length > 0) {
                potentialFreelancePackages.map((freelancePackage, index) => {
                    const color = (freelancePackage.success) ? "green" : "red";
                    const temp = `<p style="color:${color}">${(index+1)}. ${freelancePackage.freelance_package.name} ${freelancePackage.task_type.name}: <br/>${freelancePackage.message}`;
                    result = result + temp + "</p>";
                });
            } else {
                result = i18nHelper.getTranslation("No_files_found_for_import");     
            }
            webix.alert({
                text:result,
                width:300,
                css:"cog-import-fp-trados-modal"
            })
            .then(() => {
                window.location.reload();
            });
        });
    }

    openBatchNameEditorModal(freelancePackageName:string, packages:[]):void {
        const modal = webix.ui({
            view:"window",
            height:420,
            localId:`${this.idPrefix}EditBatchNamePopup`,
            width:760,
            modal:true,
            close:true,
            position:"center",
            head:i18nHelper.getTranslation(`Edit_batch_name`),
            body: {
                view:"form",
                localId:`${this.idPrefix}EditBatchNameForm`,
                elements:[
                    {
                        view:"text", 
                        localId:`${this.idPrefix}EditBatchNameInput`,
                        name: "new_batch_name", 
                        label: i18nHelper.getTranslation(`Batch_name`),
                        labelWidth:100,
                        value:freelancePackageName
                    },
                    {
                        view:"button", 
                        css:"cog-button-big",
                        localId:`${this.idPrefix}SaveNewBatchNameModalButton`,
                        label:"Ok",
                        hotkey:"enter",
                        click:(button:webix.ui.button) => {
                            const btn = webix.$$(button.toString()) as webix.ui.button;
                            const parent:webix.ui.form = btn.getParentView() as webix.ui.form;
                            const values = parent.getValues();
                            const window:webix.ui.window = parent.getParentView() as webix.ui.window;
                            const newFreelancePackageName = values.new_batch_name;
                            if(newFreelancePackageName) {
                                let collectedErrorMessage = "";
                                this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                                packages.map((flPackage, index) => {
                                    const targetLanguage = flPackage["freelancePackage"]["target_language"]["name"];
                                    const flPackageId = flPackage["freelancePackage"]["freelance_package"]["id"];
                                    const flPackageName = flPackage["freelancePackage"]["freelance_package"]["name"];
                                    //const taskIds = (flPackage["freelancePackage"]["tasks"] as []).map(task => task["task"]["id"]);
                                    if(flPackageId) {
                                        WebixPortalAssignmentService.putPackages({
                                            freelancePackageId:flPackageId,
                                            requestBody:{
                                                package_name:newFreelancePackageName
                                            }
                                        })
                                        .then(() => {
                                            // We reload after last call to backend
                                            if(packages.length === (index + 1)) {
                                                this.cognovisPleaseWaitWindow.hide();
                                                this.loadLayout([targetLanguage]);
                                                window.close();
                                            }
                                        })
                                        .catch(err => {
                                           collectedErrorMessage = `${collectedErrorMessage} <p><b>${flPackageName}</b>: ${err.body.message}</p>`; 
                                            // We reload after last call to backend
                                            if(packages.length === (index + 1)) {
                                                this.cognovisPleaseWaitWindow.hide();
                                                webix.alert({
                                                    title: i18nHelper.getTranslation("Error"),
                                                    text: collectedErrorMessage,
                                                    minWidth:500,
                                                    type: "alert-error",
                                                    callback:() => {
                                                        (window as webix.ui.window).close();
                                                        this.loadLayout([targetLanguage]);
                                                    }
                                                } as WebixAlertConfig);
                                            }
                                        });
                                    }
                                });
                            }
                            // const newPackageNameField = this.$$(`${this.idPrefix}EditBatchNameInput`) as webix.ui.button;
                            // const newPackageName = newPackageNameField.getValue();
                            // WebixPortalAssignmentService.postPackages({
                            //     projectId:this.projectId,
                            //     requestBody:{
                            //         package_type_id:this.currentlySelectedWorfkflowStep as unknown as IntranetTransTaskType,
                            //         package_name:newPackageName,
                            //         target_language_id:this.currentlySelectedTargetLanguage
                            //     }
                            // })
                            // .then((res) => {
                            //     const packageName = res[0].freelance_package.name
                            //     const packageId = res[0].freelance_package.id
                            //     //this.insertNewBatch(packageName,packageId);
                            //     (modal as webix.ui.window).close();
                            // });

                        }
                    }
            ]
            }
        });
        modal.show();
        const textield = modal.queryView({ view:"text"}) as webix.ui.text;
        const currentValue = textield.getValue();
        textield.setValue("");
        textield.focus();
        textield.setValue(currentValue);
    }

}
