import { CognovisRestPricingService } from "../../sources/openapi";
import { i18nHelper } from "../../sources/modules/i18n-helper/i18n-helper";
import { CognovisCategory } from "../../sources/modules/cognovis-category/cognovis-category";

webix.protoUI({
    name:"cogWebixMaterialPicker",
    id:"cogWebixMaterialPicker",
    idPrefix:`cogWebixMaterialPicker`,
    $cssName:"combo",
    $init() {
        this.$ready.push(this._Init);
    },
    $getSize:function(x, y){
        return webix.ui.view.prototype.$getSize.call(this, x, y);
    },
    _Init: function (config) {
        this.config.placeholder = i18nHelper.getTranslation("Click here to select material");
        this.existingData = this.config.existingData;
        if(this.existingData) {
            this.filtersReady = true;
        }
        this.refresh();
    },
    filtersReady:false,
    existingData:undefined,
    selectMaterial:function(material) {
        this.define("options", [material]);
        this.config.value = material.id;
        if(this.config.onChangeEvent) {
            this.config.onChangeEvent();
        }
        this.refresh();
    },
    $getValue:function() {
        return this.getValue();
    },
    getCombo:function(parent, comboName) {
        const combo = parent.queryView({view:"combo", name:comboName});
        return combo
    },
    getFilterValues:function(parent) {
        const params = {};
        const taskFilter = this.getCombo(parent, "task_type");
        if(taskFilter && taskFilter.getValue()) {
            params["taskTypeId"] = taskFilter.getValue();
        }
        const uomFilter = this.getCombo(parent, "uom");
        if(uomFilter && uomFilter.getValue()) {
            params["materialUomId"] = uomFilter.getValue();
        }
        const slFilter = this.getCombo(parent, "source_language");
        if(slFilter && slFilter.getValue()) {
            params["sourceLanguageId"] = slFilter.getValue();
        }
        const tlFilter = this.getCombo(parent, "target_language");
        if(tlFilter && tlFilter.getValue()) {
            params["targetLanguageId"] = tlFilter.getValue();
        }
        const saFilter = this.getCombo(parent, "subject_area");
        if(saFilter && saFilter.getValue()) {
            params["subjectAreaId"] = saFilter.getValue();
        }
        return params;
    },
    setFilterDefaultValues:function(parent) {
        if(this.existingData?.material?.task_type?.id) {
            const filter = this.getCombo(parent, "task_type");
            if(filter) {
                filter.blockEvent();
                filter.setValue(this.existingData?.material?.task_type?.id);
                filter.unblockEvent();
            }
        }
        if(this.existingData?.material?.source_language?.id) {
            const filter = this.getCombo(parent, "source_language");
            if(filter) {
                filter.blockEvent();
                filter.setValue(this.existingData?.material?.source_language?.id);
                filter.unblockEvent();
            }
        }
        if(this.existingData?.material?.target_language?.id) {
            const filter = this.getCombo(parent, "target_language");
            if(filter) {
                filter.blockEvent();
                filter.setValue(this.existingData?.material?.target_language?.id);
                filter.unblockEvent();
            }
        }
        if(this.existingData?.material?.uom?.id) {
            const filter = this.getCombo(parent, "uom");
            if(filter) {
                filter.blockEvent();
                filter.setValue(this.existingData?.material?.uom?.id);
                filter.unblockEvent();
            }
        }
        if(this.existingData?.material?.subject_area?.id) {
            const filter = this.getCombo(parent, "subject_area");
            if(filter) {
                filter.blockEvent();
                filter.setValue(this.existingData?.material?.subject_area?.id);
                filter.unblockEvent();
            }
        }
    },
    openMaterialPicker:function(parent) {
        const popup = webix.ui({
            view:"window",
            close:true,
            position: "center",
            title:this.config.modalTitle,
            width:1100,
            height:600,
            modal:true,
            body:{
                view:"layout",
                padding:20,
                rows:[
                    this.getFilters(),
                    this.getMaterialsDatatable([])
                ]
            }
        });
        popup.show();
        const datatable = popup.queryView({view:"datatable"});
        webix.extend(datatable, webix.ProgressBar);
        if(this.filtersReady) {
            this.setFilterDefaultValues(popup);
            const filterValues = this.getFilterValues(popup);
            setTimeout(() => {
                this.loadData(datatable, filterValues);
            },100);
        } else {
            this.clearFilters(popup);
            datatable.showOverlay(i18nHelper.getTranslation("Please use filters first"));
        }
    },
    loadData:function(datatable, params) {
        datatable.hideOverlay();
        (datatable as webix.ui.datatable & {showProgress:(info:string) => void}).showProgress(i18nHelper.getTranslation("Please_wait"));
        CognovisRestPricingService.getMaterial(params)
        .then(materials => {
            datatable.clearAll();
            datatable.define("data", materials);
            datatable.refresh();
            datatable.hideOverlay();
        });
    },
    clearData:function(datatable) {
        datatable.hideOverlay();
        datatable.showOverlay(i18nHelper.getTranslation("Please use filters first"));
        datatable.clearAll();
    },
    clearFilters:function(parent) {
        const datatable = parent.queryView({view:"datatable"});
        this.clearData(datatable);
        const slFilter = this.getCombo(parent, "source_language")
        const tlFilter = this.getCombo(parent, "target_language")
        const uomFilter = this.getCombo(parent, "uom")
        const taskFilter = this.getCombo(parent, "task_type")
        const saFilter = this.getCombo(parent, "subject_area");
        slFilter.setValue("");
        tlFilter.setValue("");
        uomFilter.setValue("");
        taskFilter.setValue("");
        saFilter.setValue("");
    },
    taskCategories:function() {
        return CognovisCategory.getCategory("Intranet Project Type")
        .then(projArr => {
            const modifiedProjArr = projArr.map(cat => {
                return  {
                    id:cat["id"],
                    value:`${cat["value"]} (${i18nHelper.getTranslation("project")})`
                }
            });
            const ganttArr = CognovisCategory.getCategory("Intranet Gantt Task Type")
            .then(ganttArr => {
                const modifiedGanttArr = ganttArr.map(cat => {
                    return  {
                        id:cat["id"],
                        value:`${cat["value"]} (${i18nHelper.getTranslation("Gantt")})`
                    }
                });
                const transArr = CognovisCategory.getCategory("Intranet Trans Task Type")
                .then(taskArr => {
                    const modifiedTaskArr = taskArr.map(cat => {
                        return  {
                            id:cat["id"],
                            value:`${cat["value"]} (${i18nHelper.getTranslation("task")})`
                        }
                    });
                    modifiedProjArr.concat(modifiedGanttArr)
                    return modifiedProjArr.concat(modifiedTaskArr) 
                });
                return transArr
            })
            return ganttArr
        })
    },
    getFilters:function() {
        const me = this;
        return {
            view:"layout",
            cols:[
                {
                    view:"combo",
                    name:"task_type",
                    placeholder:i18nHelper.getTranslation("task_type"),
                    suggest: {
                        fitMaster:false,
                        minWidth:330,
                        data: me.taskCategories()
                    },
                    on: {
                        onChange:function(value) {
                            const window = this.getTopParentView();
                            // Get other filters values
                            const params = me.getFilterValues(window);
                            // Replace param with new value
                            params["taskTypeId"] = Number(value);
                            const datatable = window.queryView({view:"datatable"});
                            me.loadData(datatable, params);
                        }
                    }
                },
                {
                    view:"combo",
                    name:"uom",
                    placeholder:i18nHelper.getTranslation("Uom"),
                    suggest: {
                        fitMaster:false,
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet UoM"),
                        },
                    },
                    on: {
                        onChange:function(value) {
                            const window = this.getTopParentView();
                            // Get other filters values
                            const params = me.getFilterValues(window);
                            // Replace param with new value
                            params["materialUomId"] = Number(value);
                            const datatable = window.queryView({view:"datatable"});
                            me.loadData(datatable, params);
                        }
                    }
                },
                {
                    view:"combo",
                    name:"source_language",
                    placeholder:i18nHelper.getTranslation("source_language"),
                    suggest: {
                        fitMaster:false,
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet Translation Language"),
                        },
                    },
                    on: {
                        onChange:function(value) {
                            const window = this.getTopParentView();
                            // Get other filters values
                            const params = me.getFilterValues(window);
                            // Replace param with new value
                            params["sourceLanguageId"] = Number(value);
                            const datatable = window.queryView({view:"datatable"});
                            me.loadData(datatable, params);
                        }
                    }
                },
                {
                    view:"combo",
                    name:"target_language",
                    placeholder:i18nHelper.getTranslation("target_language"),
                    suggest: {
                        fitMaster:false,
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet Translation Language"),
                        },
                    },
                    on: {
                        onChange:function(value) {
                            const window = this.getTopParentView();
                            // Get other filters values
                            const params = me.getFilterValues(window);
                            // Replace param with new value
                            params["targetLanguageId"] = Number(value);
                            const datatable = window.queryView({view:"datatable"});
                            me.loadData(datatable, params);
                        }
                    }
                },
                {
                    view:"combo",
                    name:"subject_area",
                    placeholder:i18nHelper.getTranslation("subject_area"),
                    suggest: {
                        fitMaster:false,
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet Translation Subject Area"),
                        },
                    },
                    on: {
                        onChange:function(value) {
                            const window = this.getTopParentView();
                            // Get other filters values
                            const params = me.getFilterValues(window);
                            // Replace param with new value
                            params["subjectAreaId"] = Number(value);
                            const datatable = window.queryView({view:"datatable"});
                            me.loadData(datatable, params);
                        }
                    }
                },
                {
                    view:"button",
                    value:i18nHelper.getTranslation("Clear"),
                    css:"cog-icon-green",
                    inputWidth:120,
                    width:120,
                    on:{
                        onItemClick:function() {
                            const window = this.getTopParentView();
                            me.clearFilters(window);
                        }
                    }
                },
                {
                    view:"button",
                    value:i18nHelper.getTranslation("Create"),
                    css:"cog-icon-green",
                    inputWidth:120,
                    width:120,
                    on:{
                        onItemClick:function() {
                            const window = this.getTopParentView();
                            const datatable = window.queryView({view:"datatable"});
                            const params = {};

                            // Check also for other filter
                            const tlFilter = window.queryView({view:"combo", name:"target_language"});
                            if(tlFilter && tlFilter.getValue()) {
                                params["targetLanguageId"] = tlFilter.getValue();
                            }
                            const slFilter = window.queryView({view:"combo", name:"source_language"});
                            if(slFilter && slFilter.getValue()) {
                                params["sourceLanguageId"] = slFilter.getValue();
                            }
                            const taskFilter = window.queryView({view:"combo", name:"task_type"});
                            if(taskFilter && taskFilter.getValue()) {
                                params["taskTypeId"] = taskFilter.getValue();
                            }
                            const saFilter = window.queryView({view:"combo", name:"subject_area"});
                            if(saFilter && saFilter.getValue()) {
                                params["subjectAreaId"] = saFilter.getValue();
                            }

                            const uomFilter = window.queryView({view:"combo", name:"uom"})
                            if(uomFilter && uomFilter.getValue()) {
                                params["materialUomId"] = uomFilter.getValue();
                            }
                            
                            CognovisRestPricingService.postMaterial({
                                requestBody: {
                                    task_type_id: taskFilter.getValue(),
                                    source_language_id: slFilter.getValue(),
                                    target_language_id: tlFilter.getValue(),
                                    material_uom_id: uomFilter.getValue(),
                                    subject_area_id: saFilter.getValue(),
                                }
                            })
                            .then(_material => {
                                me.clearData(datatable);
                                me.loadData(datatable, params);
                                this.refresh();
                            })
                            .catch(err => {
                                webix.alert({
                                    title: err.message,
                                    text: err.body.message,
                                    type: "alert-error",
                                    width: 500,
                                    css:"cog-remove-tasks-modal"
                                });
                            })
                        }
                    }
                }
            ]
        }
    },
    getMaterialsDatatable:function(materials:IMaterial[]) {
        const me = this;
        return {
            view:"datatable",
            css:"cog-clickable-table",
            select:"row",
            columns:[
                {
                    name:"material_id",
                    map:"#material.id#",
                    hidden:true
                }, 
                {
                    view: "template",
                    width: 30,
                    tooltip: function (obj) {
                        return obj.material_type.name
                    },
                    template: function (obj) {
                        return `<i style='margin-left:-4px; color:${obj.material_status?.icon_or_color}' class='${obj.material_type?.icon_or_color}'></i>`
                    }
                },
                {
                    name:"material_name",
                    header: [i18nHelper.getTranslation("Name")],
                    map:"#material.name#",
                    fillspace:true
                },
                {
                    name:"uom",
                    map:"#material_uom.name#",
                    header: [i18nHelper.getTranslation("Uom")],
                    template:(obj) => {
                        if(obj.material_uom) {
                            return obj.material_uom.name
                        } else {
                            return ""
                        }
                    }
                },
                {
                    name:"task_type",
                    header: [i18nHelper.getTranslation("Type")],
                    template:(obj) => {
                        if(obj.task_type) {
                            return obj.task_type.name
                        } else {
                            return ""
                        }
                    }
                },
                {
                    name:"source_language",
                    map:"#source_language?.name#",
                    header: [i18nHelper.getTranslation("source_language")],
                    template:(obj) => {
                        if(obj.source_language) {
                            return obj.source_language.name
                        } else {
                            return ""
                        }
                    }
                },
                {
                    name:"target_language",
                    map:"#target_language?.name#",
                    header: [i18nHelper.getTranslation("target_language")],
                    template:(obj) => {
                        if(obj.target_language) {
                            return obj.target_language.name
                        } else {
                            return ""
                        }
                    }
                },
                {
                    name:"subject_area",
                    minWidth:200,
                    header: [i18nHelper.getTranslation("subject_area")],
                    template:(obj) => {
                        if(obj.subject_area) {
                            return obj.subject_area.name
                        } else {
                            return ""
                        }
                    }
                }
            ],
            onDblClick:{
                "webix_view" :function(event, id) {
                    const selectedItem = this.getItem(id.row);
                    const selectedItemObj = {id:selectedItem.material.id, value:selectedItem.material.name};
                    const window = this.getTopParentView() as webix.ui.window;
                    window.hide();
                    me.selectMaterial(selectedItemObj);
                }
            },
            data:materials
        }
    },
    on_click:{
        "webix_view":function(ev,id) {
            if(ev.target.className !== "webix_inp_label") {
                this.openMaterialPicker(this);
                this.disabled = true;
                this.refresh();
            }
        }
    },
}, webix.ui.richselect, webix.MouseEvents, webix.ValidateData, webix.ui.view, webix.EventSystem);
