/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetPaymentTerm {
    N0_DAYS = 80100,
    N7_DAYS = 80107,
    N14_DAYS = 80114,
    N15_DAYS = 80115,
    N19_DAYS = 80119,
    N21_DAYS = 80121,
    N24_DAYS = 80124,
    N30_DAYS = 80130,
    N60_DAYS = 80160,
}
