/* eslint-disable @typescript-eslint/no-explicit-any */
import { singleton } from "tsyringe";

@singleton()
export class CognovisPleaseWaitWindow {

    lastDialogUsedId:string;

    show(params:ICognovisPleaseWaitWindowParams):void {
        const pleaseWaitDialogId = "cognovis-please-wait-dialog";
        const existingWindow = webix.$$(pleaseWaitDialogId);
        if(existingWindow) {
            (existingWindow as webix.ui.window).close();
        }
        this.lastDialogUsedId = pleaseWaitDialogId;
        const pleaseWaitDialog = webix.ui({
            view:"window",
            id:pleaseWaitDialogId,
            height:130,
            width:260,
            modal:true,
            css: {
                padding:10,
                backgroundColor:'red'
            },
            borderless:true,
            position:"center",
            headHeight:0,
            body:{
                view:"layout",
                rows:[
                    {
                        view:"template",
                        type:"header",
                        height:5,
                        css:{"background-color":"#0083A3"}
                    },
                    {
                        template:`<h4 style='text-align:center; font-weight:normal'>${params.message}</h4>`,
                    }
                ]
            }
        });
        webix.extend($$(pleaseWaitDialogId), webix.ProgressBar);
        pleaseWaitDialog.show();
        ($$(pleaseWaitDialogId) as any).showProgress();

    }

    hide():void {
        const window = ($$(this.lastDialogUsedId) as webix.ui.window);
        if(window) {
            window.close();
        }
    }

}