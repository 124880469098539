import BaseView from "../../../baseview";

export default class UpdateFees extends BaseView {
    config(): webix.ui.windowConfig {
        return {
            view: "window",
            modal: true,
            position: "center",
            width: 478,
            height: 108,
            close: true,
            hidden: false,
            id: "update-fees-window",
            head: {
                view: "toolbar",
                elements: [
                    { template: "Update Fees" },
                    {
                        view: "icon",
                        icon: "wxi-close",
                        click: function () {
                            $$(this.getParentView()).hide();
                        },
                    },
                ],
            },
            body: ({
                view: "form",
                elements: [
                    {
                        view: "layout",
                        cols: [
                            { rows: [{ view: "text", name: "units", placeholder: "units", value: "50544", width: 114 }] },
                            { rows: [{ view: "combo", name: "unit of measure", width: 114, options: [{ id: "s-word", value: "s-word" }] }] },
                            { rows: [{ view: "button", value: "Save and close", css: "button save-and-close", width: 177 }] },
                        ],
                    },
                ],
            } as unknown) as webix.ui.window,
        };
    }
}
