import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import ObjectNotesModal from "views/modals/object-notes-modal";
import { CognovisRestTimesheetService, ITimesheetEntry } from "../../../sources/openapi";
import { UserStateManager } from "../../services/user-state-manager";
import CognovisBasicModal from "../cognovis-basic-modal/cognovis-basic-modal";
import { DatetimeHelper } from "../datetime-helpers/datetime-helpers";
import { i18nHelper } from "../i18n-helper/i18n-helper";
import { WebixHelpers } from "../webix-helpers/webix-helpers";
import NewTimesheetEntryModal from "./new-timesheet-entry-modal";
import { time } from "console";


export default class DailyTimesheetEntriesModal extends CognovisBasicModal {

    idPrefix = "dtem";
    itemId: string;
    currentUser:{id:number, value:string, name:string};
    webixHelpers: WebixHelpers;
    isThatNewRow = false;
    selectedDate:Date;
    notesModal:ObjectNotesModal;
    newTimesheetEntryModal:NewTimesheetEntryModal;
    currentTimesheetEntries:ITimesheetEntry[] = [];
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    totalLoggedHoursToday = 0;
    calendarAfterUpdateAction?:() => void;

    config():webix.ui.windowConfig {
        const currentUserId = UserStateManager.getCurrentlyLoggedUser().id;
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.webixHelpers = container.resolve(WebixHelpers);
        const loggedUserData = webix.storage.session.get("logged_user_data");
        this.currentUser =   {
            id:currentUserId,
            value:`${loggedUserData.first_names} ${loggedUserData.last_name}`,
            name:`${loggedUserData.first_names} ${loggedUserData.last_name}`
        };
        const mainLayout = super.getMainLayout(" ",1000,700);
        return mainLayout;
    }

    openModal(selectedDate:Date, timesheetEntries:ITimesheetEntry[], calendarAfterUpdateAction?:() => void):void {
        this.selectedDate = selectedDate;
        if(calendarAfterUpdateAction) {
            this.calendarAfterUpdateAction = calendarAfterUpdateAction;
        }
        const filteredTimesheetEntries = this.filterTimesheetEntries(timesheetEntries, selectedDate);
        this.totalLoggedHoursToday = filteredTimesheetEntries.reduce((a, b) => +a + +b.hours, 0);
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const modalContent = this.getContent(filteredTimesheetEntries);
        const prettyDate = selectedDate.toLocaleDateString('de-DE');
        const modalTitle = `${this.currentUser.name} ${prettyDate}`;
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle); 
    }

    closeModal():void {
        this.hide();
    }

    getContent(filteredTimesheetEntries:ITimesheetEntry[]):webix.ui.layoutConfig {
        const layout = {
            view:"layout",
            rows:[
                this.getToolbar(),
                this.getDatatable(filteredTimesheetEntries)
            ]
        };
        return layout;
    }

    getDatatable(filteredTimesheetEntries:ITimesheetEntry[]):webix.ui.datatableConfig {
        const datatable = {
            view:"datatable",
            localId:`${this.idPrefix}MyLoggedHoursDaily`,
            tooltip:true,
            columns:[
                {
                    id:"timesheetEntryId",
                    map:"#hour_id#",
                    hidden:true
                },
                {
                    id:"projectNr",
                    header: [i18nHelper.getTranslation("Project"), { content: "multiSelectFilter"}],
                    map:"#project.name#",
                    sort:"text",
                    fillspace:true,
                    tooltip:false
                },
                {
                    id:"taskName",
                    header: [i18nHelper.getTranslation("Task"), { content: "multiSelectFilter"}],
                    map:"#task.name#",
                    sort:"text",
                    fillspace:true,
                    tooltip:false,
                },
                {
                    id:"duration",
                    header: [i18nHelper.getTranslation("Duration"), { content: "numberFilter"}],
                    sort:"int",
                    tooltip:false,
                    editor:"text"
                },
                {
                    view: "template",
                    css:"edit",
                    width: 40,
                    tooltip:(obj:ITimesheetEntry) => {
                        return obj.note;
                    },
                    template: function (obj:ITimesheetEntry) {
                        let cssClasses = `webix_icon fas fa-comment`;
						if(obj.note.length === 0) {
							cssClasses = cssClasses + ` greyedout`
						}
                        return `<span style='color:grey' class='webix_icon fas fa-comment ${cssClasses}'></span>`;
                    }
                },
                {
					width:40,
					tooltip:false,
                    template: (obj) => {
                        return `<span class='webix_icon fas ${obj.edit ? "fa-check" : "fa-edit"}' id='edit-${obj.edit ? "fa-check" : "fa-edit"}'></span>`;
                    }
				},
                {
					width:40,
					tooltip:false,
                    template:() => {
                        return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-trash'></span>`;
                    }
				}
            ],
            data:filteredTimesheetEntries,
            onClick:{
                "fa-trash":(event:Event, element:{column:string, row:number}) => {
                    const table = this.getDatatableView() as webix.ui.datatable;
                    const item:ITimesheetEntry = table.getItem(element.row);
                    this.attemptToDeleteTimesheetEntry(item);             
                },
                "fa-edit":(event:Event, element:{column:string, row:number}) => {
                    const table = this.getDatatableView() as webix.ui.datatable;
                    table.define("editable", true);
                    this.editStart(event, element);       
                },
                "fa-check": (event:Event, element:{column:string, row:number}) => {
                    const table = this.getDatatableView() as webix.ui.datatable;
                    table.define("editable", false);
                    this.editEnd(event, element);
                },
                "fa-comment":(event:Event, element:{column:string, row:number}) => {
                    const table = this.getDatatableView() as webix.ui.datatable;
                    const item:ITimesheetEntry = table.getItem(element.row);
                    if(!this.notesModal) {
                        this.notesModal = this.ui(ObjectNotesModal) as ObjectNotesModal;
                    }
                    this.notesModal.openNotesModal("write", i18nHelper.getTranslation("Timesheet entry comment"), item.hour_id, item.note, "",[], (newComment:string) => {
                        CognovisRestTimesheetService.putTimesheetEntry({
                            hourId:item.hour_id,
                            requestBody:{
                                note:newComment,
                                hours: item.hours         
                            }
                        })
                        .then(() => {
                            this.refreshTimesheetEntriesTable();
                        });
                    });
                }
            },
            on:{
                onAfterLoad:function() {
                    const table = this as webix.ui.datatable;
                    const results = table.serialize();
                    if(results.length === 0) {
                        this.showOverlay(i18nHelper.getTranslation("No_hours_logged"));
                    }
                }
            }
        };
        return datatable
    }

    getDatatableView():webix.ui.datatable {
        return this.$$(`${this.idPrefix}MyLoggedHoursDaily`) as webix.ui.datatable;
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
            {
                view:"layout",
                padding:0,
                cols:[
                    { 
                        view: "button", 
                        value: `${i18nHelper.getTranslation(`Close`)}`,
                        align: "right",
                        click:() => {
                            this.closeModal();
                        }
                    },  

                ] 
            };
        return buttons;
    }

    getTimesheetEntries(userId:number):Promise<ITimesheetEntry[]> {
        const myFormat = webix.Date.dateToStr("%Y-%m-%d", false);
        const parsedDate = myFormat(this.selectedDate);
        return CognovisRestTimesheetService.getTimesheetEntry({
            userId:userId,
            startDate:parsedDate,
            endDate:parsedDate
        })
        .then(entries => {
            entries.map((entry:ITimesheetEntry & {duration:string}) => {
                entry.duration = this.webixHelpers.convertHoursDecimalToDuration(entry.hours.toString());
            });
            const filteredEntries = this.filterTimesheetEntries(entries, this.selectedDate);
            this.totalLoggedHoursToday = filteredEntries.reduce((a, b) => +a + +b.hours, 0);
            this.currentTimesheetEntries = filteredEntries;
            return filteredEntries
        });
    }

    filterTimesheetEntries(timesheetEntries:ITimesheetEntry[], selectedDate:Date):ITimesheetEntry[] {
        const filtered = timesheetEntries.filter(entry => {
            const parsedDay = new Date(entry.day.split(" ")[0]);
            const isSameDay = DatetimeHelper.isSameDay(parsedDay, selectedDate);
            if(isSameDay) {
                return true;
            }
        });
        return filtered
    }

    refreshTimesheetEntriesTable(calendarAfterUpdateAction?:() => void):void {
        const table = this.getDatatableView() as webix.ui.datatable;
        table.showOverlay(i18nHelper.getTranslation("Please_wait"));
        table.clearAll();
        this.getTimesheetEntries(this.currentUser.id)
        .then(filteredEntries => {
            table.hideOverlay();
            table.define("data", filteredEntries);
            table.refresh();
            if(calendarAfterUpdateAction) {
                calendarAfterUpdateAction();
            }
            this.updateTotalHoursContainer();
        });
    }

    getToolbar():webix.ui.layoutConfig {
        const toolbar =  { 
            view:"button", 
            css:"cog-icon-green",
            value:i18nHelper.getTranslation("Log_hours"),
            localId:`${this.idPrefix}AddNewEntryButton`,
            gravity:9,
            inputWidth:90, 
            align:"right",
            click:() => this.addNewEntry()
        };
        const totalDuration = this.webixHelpers.convertHoursDecimalToDuration(this.totalLoggedHoursToday.toString());
        let totalInfoClass = "total-hours noentries";
        if(this.totalLoggedHoursToday >= 6) {
            totalInfoClass = "total-hours";
        }
        return {
            view:"layout",
            cols:[
                {
                    width:300,
                    localId:`${this.idPrefix}TotalHoursContainer`,
                    borderless:true,
                    paddingY:20,
                    template:`<div class='${totalInfoClass}'>${i18nHelper.getTranslation("Total")}: ${totalDuration}</div>`
                },
                toolbar
            ]
        }
    }

    addNewEntry(): void {
        if(!this.newTimesheetEntryModal) {
            this.newTimesheetEntryModal = this.ui(NewTimesheetEntryModal) as NewTimesheetEntryModal;
        }
        this.newTimesheetEntryModal.openModal(this.selectedDate, () => {
            this.refreshTimesheetEntriesTable(this.calendarAfterUpdateAction);
        });
    }

    attemptToDeleteTimesheetEntry(timesheetEntry:ITimesheetEntry):void {
        const whatToRemove = this.webixHelpers.convertHoursDecimalToDuration(timesheetEntry.hours.toString());
        const whenLogged = DatetimeHelper.getPrettyDate(new Date(timesheetEntry.day));
        const confirmWarning = `${i18nHelper.getTranslation(`You_want_to_remove`)}: ${whatToRemove} - ${whenLogged}?`;
        webix.confirm({
            title: i18nHelper.getTranslation(`any_are_you_sure_message`),
            type: "confirm-warning",
            text: confirmWarning,
            width: 480
        })
        .then(() => {
            this.deleteTimesheetEntry(timesheetEntry.hour_id)
        });    
    }

    deleteTimesheetEntry(entryId:number):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        CognovisRestTimesheetService.deleteTimesheetEntry({
            hourId:entryId
        })
        .then(deletedTasks => {
            this.cognovisPleaseWaitWindow.hide();
            if(deletedTasks.length > 0) {
                let allErrorsText = "";
                deletedTasks.map((error, index) => {
                    const temp = `${index+1}. ${error.err_msg}</br>`; 
                    allErrorsText = allErrorsText + temp;
                });
                webix.alert({
                    title: "Error",
                    width:400,
                    text: allErrorsText,
                    type:"alert-error"
                });
            } else {
                this.cognovisPleaseWaitWindow.hide();
                this.refreshTimesheetEntriesTable();        
            }
        });
    }

    markAsCurrentlyEdited(item: string): void {
        const table = this.getDatatableView() as webix.ui.datatable;
        // clear any current selection
        this.clearEditMode();
        const vals = table.getItem(item);
        vals.edit = true;
        table.refresh(this.itemId);
    }

    clearEditMode(): void {
        const table = this.getDatatableView() as webix.ui.datatable;
        table.editStop();
        //this.itemId = "";
        if (this.itemId) {
            table.getItem(this.itemId).edit = false;
            table.refresh(this.itemId);
        }
    }

    updateTotalHoursContainer():void {
       const container = this.$$(`${this.idPrefix}TotalHoursContainer`) as webix.ui.template;
       const totalDuration = this.webixHelpers.convertHoursDecimalToDuration(this.totalLoggedHoursToday.toString());
       let totalInfoClass = "total-hours noentries";
       if(this.totalLoggedHoursToday >= 6) {
           totalInfoClass = "total-hours";
       }
       container.setHTML(`<div class='${totalInfoClass}'>${i18nHelper.getTranslation("Total")}: ${totalDuration}</div>`);
    }

    editStart(event:Event, element:{column:string, row:number}):void {
        const table = this.getDatatableView() as webix.ui.datatable;
        this.itemId = element.row.toString();
        this.markAsCurrentlyEdited(this.itemId);
        table.editStop();
        table.editCell(element.row, "duration");
    }

    editEnd(event: Event, element:{column:string, row:number}):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        this.itemId = element.row.toString();
        const table = this.getDatatableView() as webix.ui.datatable;
        const item = table.getItem(this.itemId) as ITimesheetEntry & {duration:string};
        this.clearEditMode();
        CognovisRestTimesheetService.putTimesheetEntry({
            hourId:item.hour_id,
            requestBody:{
                hours:this.webixHelpers.convertDurationToHoursDecimal(item.duration)          
            }
        })
        .then(res => {
            this.refreshTimesheetEntriesTable(this.calendarAfterUpdateAction);
            this.cognovisPleaseWaitWindow.hide();
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal",
            });
        });
    }

}