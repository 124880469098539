import { IAssignment, WebixPortalAssignmentService } from "../../../../../sources/openapi";
import CognovisBasicModal from "../../../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../../../sources/modules/i18n-helper/i18n-helper";
import { WebixHelpers } from "../../../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { DatetimeHelper } from "../../../../../sources/modules/datetime-helpers/datetime-helpers";


export default class UpdateAssignmentDeadline extends CognovisBasicModal {

    webixHelpers:WebixHelpers;
    currentAssignment:IAssignment;
    afterSaveAction:() => void;

    init():void {
        this.webixHelpers = container.resolve(WebixHelpers);
    }

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Update_Assignment_Deadline`), 640, 480);
        return mainLayout;
    }

    openModal(additionalData:ICognovisModalAdditionlData, afterSaveAction?:() => void):void {
        if(afterSaveAction) {
            this.afterSaveAction = afterSaveAction;
        }
        const cognovisBasicModal = this.$$("updateAssignmentDeadlineModal") as webix.ui.window;
        if(cognovisBasicModal) {
            cognovisBasicModal.show();
        } else {
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
        }
        const actionButtons = this.getActionButtons();
        const assignment = additionalData.assignment as IAssignment;
        this.currentAssignment = assignment;
        let formattedCurrentDeadline = "";
        if(assignment.assignment_deadline) {
            formattedCurrentDeadline = DatetimeHelper.parseDateForDatepicker((assignment.assignment_deadline));
        } else {
            formattedCurrentDeadline = this.webixHelpers.formatDate(new Date());
        }
        const modalContent = this.getContent(formattedCurrentDeadline);
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig);
    }

    closeModal():void {
        this.close();
    }

    getContent(currentDeadline:string):webix.ui.layoutConfig {
        const modal = {
                view:"form",
                localId:"updateAssignmentDeadlineModal",
                elements:[
                    {
                        view: "datepicker", 
                        localId:"uamNewDeadline",
                        name: "new_assignment_deadline", 
                        label: i18nHelper.getTranslation(`New_assignment_deadline`),
                        labelWidth:200,
                        format:"%d.%m.%Y %H:%i",
                        timepicker:true,
                        editable:true,
                        suggest: {
                            type: "calendar",
                            body: {
                                timepicker: true,
                                calendarTime: "%H:%i",
                                localId:"uamNewDeadlineCalendar",
                                on: {
                                    onBeforeDateSelect:function() {
                                        this.detachEvent("onAfterDateSelect");
                                        const popup = this.getParentView();
                                        const datepicker = webix.$$(popup.config.master) as webix.ui.datepicker;
                                        this.attachEvent("onAfterDateSelect", function(date) {
                                            datepicker.setValue(date)
                                        });
                                    }
                                },
                                icons: [
                                    {
                                        template: function(){
                                            return "<span class='webix_cal_icon_done webix_cal_icon'>"
                                                +i18nHelper.getTranslation("Done")
                                                +"</span>";
                                        },
                                        on_click:{
                                            "webix_cal_icon_done": function(){
                                                this.hide();
                                            }
                                        }
                                    },
                                ]

                            }
                        },
                        value:currentDeadline
                    }
                ]
        }
        return modal;
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Cancel`)}`, 
                            align: "left",
                            click:() => {
                                this.close();
                            }
                        },
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`save`)}`,
                            align: "right",
                            click:() => {
                                const datetimepicker = this.$$("uamNewDeadline") as webix.ui.datepicker;
                                const newDeadline = datetimepicker.getValue();
                                const formattedCurrentDeadline = this.webixHelpers.formatDate(new Date(newDeadline));
                                this.saveNewDeadline(this.currentAssignment, formattedCurrentDeadline);
                            }
                        }, 
                    ] 
                };
        return buttons;
    }


    saveNewDeadline(assignment:IAssignment, newDeadline:string):void {
        let startDate = assignment.start_date;
        if(!startDate) {
            startDate = "";
        }
        const updatedAssignmentObj = {
            assignment_id:assignment.assignment.id,
            uom_id:assignment.uom.id,
            assignment_deadline:newDeadline,
            start_date:startDate,
            assignment_status_id:assignment.assignment_status.id
        };
        assignment.assignment_deadline = newDeadline;
        WebixPortalAssignmentService.putTranslationAssignments({
            assignmentId:updatedAssignmentObj.assignment_id,
            requestBody:updatedAssignmentObj
        })
        .then(() => {
            // Something will happen here
            if(this.afterSaveAction) {
                this.afterSaveAction();
            }
            this.close();
        })
    }
}