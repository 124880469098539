/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetUom {
    HOUR = 320,
    DAY = 321,
    UNIT = 322,
    SWORD = 324,
    TEXT = 330,
    MINPRICE = 331,
}
