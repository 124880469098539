import { last } from "cypress/types/lodash";

export class DatetimeHelper {

    static parseDateForDatepicker(date:string):string {
        if(typeof date === "string" && date.indexOf(".") > -1) {
            const dateObj = new Date(date.split(".")[0]);
            const dateAsProperString = `${dateObj.getFullYear()}-${(dateObj.getMonth()+1)}-${dateObj.getDate()} ${dateObj.getHours()}:${dateObj.getMinutes()}`;
            return DatetimeHelper.addLeadingZeros(dateAsProperString);
        } else {
            return "";
        }
    }

    static addLeadingZeros(dateString) {
        const parts = dateString.split(" ");
        const datePart = parts[0].split("-");
        const timePart = parts[1].split(":");
      
        const paddedDatePart = datePart.map((part) => part.padStart(2, "0"));
        const paddedTimePart = timePart.map((part) => part.padStart(2, "0"));
      
        const formattedDate = paddedDatePart.join("-");
        const formattedTime = paddedTimePart.join(":");
      
        return `${formattedDate} ${formattedTime}`;
    }

    static formatDateFromDatepicker(dateObj:Date):string {
        // adjust 0 before single digit date
        const date = ("0" + dateObj.getDate()).slice(-2);
        // current month
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        // current year
        const year = dateObj.getFullYear();
        // current hours
        const hours = dateObj.getHours();
        let hoursParsed = hours.toString();
        if(hours < 10) {
            hoursParsed = `0${hours}`;
        }
        // current minutes
        const minutes = dateObj.getMinutes();
        let minutesParsed = minutes.toString();
        if(minutes < 10) {
            minutesParsed = `0${minutes}`
        }
        // current seconds
        const seconds = dateObj.getSeconds();
        let secondsParsed = seconds.toString();
        if(seconds < 10) {
            secondsParsed = `0${seconds}`
        }
        // prints date & time in YYYY-MM-DD HH:MM:SS format
        const finalDate = year + "-" + month + "-" + date + " " + hoursParsed + ":" + minutesParsed + ":" + secondsParsed;
        return finalDate;
    }

    static removeTimezone(date:string, alsoReformat?:boolean) {
        if(date.indexOf("+") > -1) {
            if(alsoReformat) {
                return this.reformatDateWithTz(date.split("+")[0]);
            } else {
                return date.split("+")[0];
            }
        } else {
            return date;
        }
    }

    static reformatDate(dateStr:string):string {
      const dArr = dateStr.split("-"); 
      return dArr[2]+ "." +dArr[1]+ "." +dArr[0]; 
    }

    static reformatDateWithTz(dateStr:string):string {
        const removedTz = dateStr.split(" ");
        const dArr = removedTz[0].split("-"); 
        return dArr[2]+ "." +dArr[1]+ "." +dArr[0] + " "+ dateStr.split(" ")[1];
      }

    static getPrettyDate(date:Date):string {
        return `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
    }

    static parseDateFromDatepicker(date:Date):string {
        return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    }

    static parseDateWithHour(date:Date):string {
        return `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()} ${date.getHours()}_${date.getMinutes()}`;
    }

    static isValidDate(strDate:string):boolean {
        const myDateStr= new Date(strDate);
        if( ! isNaN ( myDateStr.getMonth() ) ) {
           return true;
        }
        return false;
    }

    static isSameDay(d1:Date, d2:Date):boolean {
        return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
    }

    static getFirstMonthDay(date:Date = new Date()):Date {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return firstDay
    }    

    static getLastMonthDay(date:Date = new Date()):Date {
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return lastDay
    }  


}