/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean0 } from '../models/Boolean0';
import type { IError } from '../models/IError';
import type { ImProjectId } from '../models/ImProjectId';
import type { ImTimesheetTaskId } from '../models/ImTimesheetTaskId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { ITimesheetEntry } from '../models/ITimesheetEntry';
import type { ITimesheetEntryBody } from '../models/ITimesheetEntryBody';
import type { ITimesheetTask } from '../models/ITimesheetTask';
import type { ITimesheetTaskBody } from '../models/ITimesheetTaskBody';
import type { PersonId } from '../models/PersonId';
import type { StringDate } from '../models/StringDate';
import type { UserId } from '../models/UserId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestTimesheetService {

    /**
     * Get the logged hours for a project and or user
     * @returns ITimesheetEntry Timesheet entries
     * @throws ApiError
     */
    public static getTimesheetEntry({
        projectId,
        userId,
        hourId,
        startDate,
        endDate,
    }: {
        /**
         * Project or Task for which we want to get hours.
         */
        projectId?: IntegerInt32,
        /**
         * User who logged the hours in the system
         */
        userId?: UserId,
        /**
         * Timesheet entry (if we want to get a single one)
         */
        hourId?: IntegerInt32,
        /**
         * Which is the start date we look at
         */
        startDate?: StringDate,
        /**
         * Which is the end date we look at
         */
        endDate?: StringDate,
    }): CancelablePromise<Array<ITimesheetEntry>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/timesheet_entry',
            query: {
                'project_id': projectId,
                'user_id': userId,
                'hour_id': hourId,
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }

    /**
     * @returns ITimesheetEntry Timesheet entries
     * @throws ApiError
     */
    public static postTimesheetEntry({
        projectId,
        requestBody,
    }: {
        /**
         * Project or Task for which we want to enter hours.
         */
        projectId: IntegerInt32,
        /**
         * Timesheet entry information
         */
        requestBody: ITimesheetEntryBody,
    }): CancelablePromise<ITimesheetEntry> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/timesheet_entry',
            query: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove a timesheet entry if possible
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteTimesheetEntry({
        hourId,
    }: {
        /**
         * Timesheet Entry we want to remove
         */
        hourId: IntegerInt32,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/timesheet_entry',
            query: {
                'hour_id': hourId,
            },
        });
    }

    /**
     * @returns ITimesheetEntry Timesheet entries
     * @throws ApiError
     */
    public static putTimesheetEntry({
        hourId,
        requestBody,
    }: {
        /**
         * Timesheet Entry we want to update
         */
        hourId: IntegerInt32,
        /**
         * Timesheet entry information
         */
        requestBody: ITimesheetEntryBody,
    }): CancelablePromise<ITimesheetEntry> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/timesheet_entry',
            query: {
                'hour_id': hourId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Information about tasks in the system. Typically for those in a project
     * @returns ITimesheetTask Array of Tasks (Timesheet)
     * @throws ApiError
     */
    public static getTimesheetTask({
        taskId,
        projectId,
        taskAssigneeId,
    }: {
        /**
         * Task we would like to get information for
         */
        taskId?: ImTimesheetTaskId,
        /**
         * Project we want to get the tasks for
         */
        projectId?: ImProjectId,
        /**
         * User we want to get the timesheets for
         */
        taskAssigneeId?: PersonId,
    }): CancelablePromise<Array<ITimesheetTask>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/timesheet_task',
            query: {
                'task_id': taskId,
                'project_id': projectId,
                'task_assignee_id': taskAssigneeId,
            },
        });
    }

    /**
     * Create a new timesheet task
     * @returns ITimesheetTask Newly created task
     * @throws ApiError
     */
    public static postTimesheetTask({
        projectId,
        requestBody,
    }: {
        /**
         * Project in which we want to create the timesheet task
         */
        projectId: ImProjectId,
        /**
         * Task information
         */
        requestBody: ITimesheetTaskBody,
    }): CancelablePromise<ITimesheetTask> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/timesheet_task',
            query: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a timesheet task if it has no logged hours or we migrate them to the (parent) project
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteTimesheetTask({
        taskId,
        migrateHoursP,
    }: {
        /**
         * Project in which we want to create the timesheet task
         */
        taskId: ImProjectId,
        /**
         * Should we migrate any hours to the project?
         */
        migrateHoursP?: Boolean0,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/timesheet_task',
            query: {
                'task_id': taskId,
                'migrate_hours_p': migrateHoursP,
            },
        });
    }

    /**
     * Update a timesheet task
     * @returns ITimesheetTask Newly created task
     * @throws ApiError
     */
    public static putTimesheetTask({
        taskId,
        requestBody,
    }: {
        /**
         * Task which we want to update
         */
        taskId: ImTimesheetTaskId,
        /**
         * Task information
         */
        requestBody: ITimesheetTaskBody,
    }): CancelablePromise<ITimesheetTask> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/timesheet_task',
            query: {
                'task_id': taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Upload a file to mass import timesheet tasks from XLS or CSV
     * @returns ITimesheetTask Array of Tasks (Timesheet)
     * @throws ApiError
     */
    public static postTimesheetTaskFromFile({
        projectId,
        path,
        filename,
    }: {
        /**
         * to which project we want to mass upload timesheet tasks
         */
        projectId: ImProjectId,
        /**
         * location of the file on the harddisk which we import the CSV from
         */
        path: string,
        /**
         * Name of the file (original filename) - used to identify XLS
         */
        filename: string,
    }): CancelablePromise<Array<ITimesheetTask>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/timesheet_task_from_file',
            query: {
                'project_id': projectId,
                'path': path,
                'filename': filename,
            },
        });
    }

}
