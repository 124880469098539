import { config } from "../config";

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function status():Promise<boolean> {
    return new webix.promise((resolve, reject) => {
      const localStorageCredentials = webix.storage.local.get("wjet_user");
      const bearerToken = webix.storage.local.get("cognovis_bearer_token");
      if (!isEmpty(localStorageCredentials) || !isEmpty(bearerToken)) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  }

function isUserAdmin(): Promise<boolean> {
  return new webix.promise((resolve, reject) => {
    const localStorageCredentials = webix.storage.local.get("wjet_user");
    if (localStorageCredentials.site_wide_admin) {
      resolve(true);
    } else {
      reject(false);
    }
  });
}

function login(user: string, pass: string): Promise<ICognovisServerResponse> {
  const url = config.restUrl + "auth_token";
  return webix.ajax(url, {
    username: user,
    password: pass,
  }) as Promise<ICognovisServerResponse>
}

function logout(): Promise<null> {
  return new webix.promise(resolve => {
    webix.storage.local.remove("wjet_user");
    webix.storage.session.remove("swa_user");
    webix.storage.local.remove("current_user_profiles");
    webix.storage.local.remove("cognovis_bearer_token");
    webix.storage.session.remove("cognovis_faked_user_data");
    webix.storage.session.remove("current_user_profiles");
    webix.storage.session.remove("cached_cognovis_categories");
    webix.storage.local.remove("cognovis_translations");
    resolve(null);
  });
}

export default {
  status,
  login,
  logout,
  isUserAdmin,
};
