/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntegerInt32 } from '../models/IntegerInt32';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestPermissionService {

    /**
     * Checks permissions on an object based of cog_rest permission procs
     * @returns string Generic description
     * @throws ApiError
     */
    public static postTogglePermission({
        objectId,
        groupId,
        action,
    }: {
        /**
         * id of the object we want to toggle permission for
         */
        objectId: IntegerInt32,
        /**
         * id of the group (or user) for whom want to toggle permission
         */
        groupId: IntegerInt32,
        /**
         * action type we want to execute
         */
        action: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/toggle_permission',
            query: {
                'object_id': objectId,
                'group_id': groupId,
                'action': action,
            },
        });
    }

}
