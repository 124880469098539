import { ICategoryObject, IFreelancePackage, ITransWorkflowStep} from "../../../sources/openapi";
import { injectable } from "tsyringe";

// CognovisTransHelper (Cognobis Translaion Data Helper)
// Helper class which is responsible for data manipulations on FrelancePackages, Assignments etc.
@injectable()
export class CognovisTransHelper {

    groupFreelancePackages(freelancePackages:IFreelancePackage[], projectWorkflowSteps:ITransWorkflowStep[]):ICognovisGrouppedFreelancePackages<IFreelancePackage>[] {
        // Creating empty arrays
        const grouppedPackagesArr = [];
        const packs = [];
        // First loop task is to only figure out unique key, which consits of comma seperated task_ids
        // It shuold be unique
        freelancePackages.map(freelancePackage => {
            const taskIdsArr = [];
            freelancePackage?.tasks?.map(transTask => {
                taskIdsArr.push(transTask.task.id);
            });
            const taskIdsStr = taskIdsArr.join(",");
            if(taskIdsStr.length > 0 && freelancePackage && freelancePackage.freelance_package && freelancePackage.freelance_package.id) {
                packs.push({
                    packageId:freelancePackage.freelance_package.id,
                    taskIdsStringified:taskIdsStr,
                    freelancePackage:freelancePackage
                });
            }
        });
        // Next we use 'reduce' function to group packages by previously created "task_ids" key
        const grouppedPacks =  packs.reduce(function (r, a) {
            r[a.taskIdsStringified] = r[a.taskIdsStringified] || [];
            r[a.taskIdsStringified].push(a);
            return r;
        }, Object.create(null));

        // We create our goal array of groupped packages
        Object.values(grouppedPacks).map(grouppedPack => {
            // Packages names
            const packagesNamesArr = [];
            (grouppedPack as ICognovisGrouppedFreelancePackage<IFreelancePackage>[]).map(pack => {
                packagesNamesArr.push(pack.freelancePackage.freelance_package.name);
            });
            const uniquePackageNames = packagesNamesArr.filter(function(item, pos) {
                return packagesNamesArr.indexOf(item) == pos;
            })
            const packagesNamesAsString = `${uniquePackageNames[0]}`
            //const packagesNamesAsString = uniquePackageNames.join(",<br/><br/>");
            //const packagesNamesAsString = uniquePackageNames.map(function(val, index) { return `${(index+1).toString()}.${val}`; }).join(",");

            //Task names
            const packageTaskNamesArr = [];
            (grouppedPack as ICognovisGrouppedFreelancePackage<IFreelancePackage>[]).map(pack => {
                pack.freelancePackage.tasks.map(transTask => {
                    packageTaskNamesArr.push(transTask.task.name);
                });
            });
            const packageTaskNamesAsString = packageTaskNamesArr
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((taskName, index) => `${index+1}. ${taskName}<br/>`);
            const packagesTasksNamesWithHeader = `<b>Tasks</b>:</br>${packageTaskNamesAsString}`
            grouppedPackagesArr.push({
                packageName:packagesNamesAsString,
                packageTaskNames:packagesTasksNamesWithHeader.replace(/,/g, ''),
                packages:grouppedPack
            });
        });

        // Groupping by type
        // First let's create an empty object, so we can later append
        grouppedPackagesArr.map(freelancePackage => {
            freelancePackage.grouppedByType = {};
        });
        grouppedPackagesArr.map(freelancePackage => {
            const alreadyUsedStepIds = [];
            projectWorkflowSteps.map(step => {
                if(alreadyUsedStepIds.indexOf(step.task_type.id) === -1) {
                    freelancePackage.grouppedByType[step?.task_type?.id] = {packages:[]};
                    alreadyUsedStepIds.push(step.task_type.id);
                }
            });
        });
        grouppedPackagesArr.map(freelancePackage => {
            freelancePackage.packages.map(notGrouppedPackage => {
                if(freelancePackage.grouppedByType[notGrouppedPackage.freelancePackage?.package_type?.id]) {
                    freelancePackage.grouppedByType[notGrouppedPackage.freelancePackage?.package_type?.id].packages.push(notGrouppedPackage);
                }
            });
        });

        // Groupping by language
        // First let's create an empty object, so we can later append
        grouppedPackagesArr.map(freelancePackage => {
            freelancePackage.grouppedByTargetLanguage = {};
        });
        // We extract all possible target languages
        const possibleTargetLanguages = [];
        const alreadyUsedTlIds = [];
        grouppedPackagesArr.map(freelancePackage => {
            freelancePackage.packages.map(singlePackage => {
                const targetLanguage = singlePackage.freelancePackage["target_language"];
                if(alreadyUsedTlIds.indexOf(targetLanguage.id) === -1) {
                    possibleTargetLanguages.push(targetLanguage);
                    alreadyUsedTlIds.push(targetLanguage.id);
                }
            });
        });

        grouppedPackagesArr.map(freelancePackage => {
            freelancePackage["allLanguages"] = possibleTargetLanguages;
            const targetLanguage = freelancePackage.packages[0].freelancePackage["target_language"];
            freelancePackage["targetLanguageId"] = targetLanguage.id;
            freelancePackage["targetLanguageName"] = targetLanguage.name;
        });

        

        return grouppedPackagesArr;
    }

    extractUniqueTargetLanguages(freelancePackages:ICognovisGrouppedFreelancePackages<IFreelancePackage>[]):ICategoryObject[] {
        // Creating empty arrays
        const targetLanguagesArr = [];
        const targetLanguagesAlreadyUsedIds = [];
        // First loop task is to only figure out unique key, which consits of comma seperated task_ids
        // It shuold be unique
        freelancePackages.map(freelancePackage => {
            const allPackagesInGroup = freelancePackage.packages;
            allPackagesInGroup.map(flPackage => {
                const targetLanguage = flPackage.freelancePackage["target_language"];
                if(targetLanguagesAlreadyUsedIds.indexOf(targetLanguage.id) === -1) {
                    targetLanguagesArr.push(targetLanguage as ICategoryObject);
                    targetLanguagesAlreadyUsedIds.push(targetLanguage.id);
                }  
            })
        });
        return targetLanguagesArr
    }

}