import { UserStateManager } from "../../../sources/services/user-state-manager";
import {
  CognovisRestService,
  IGroup,
} from "../../../sources/openapi/index";
import { UserProfile } from "./profiles-types";

export default class CognovisProfile {

  static getPossibleUserProfiles(): string[] {
    const possibleUserProfiles = [];
    for (const item in UserProfile) {
      const groupId = UserProfile[item.toString()];
      const groupName = item;
      if (isNaN(Number(item))) {
        possibleUserProfiles.push({
          group_id: groupId,
          group_name: groupName,
        });
      }
    }
    return possibleUserProfiles;
  }

  static getCurrentUserProfiles(): Promise<IGroup[]> {
    const cachedUserProfiles = webix.storage.session.get("current_user_profiles");
    if (cachedUserProfiles && cachedUserProfiles.length > 0) {
      return Promise.resolve().then(() => {
        return webix.storage.session.get("current_user_profiles");
      });
    } else {
      return CognovisRestService.getUserGroups({})
      .then((currentUserProfiles) => {
        webix.storage.session.put("current_user_profiles", currentUserProfiles);
        return currentUserProfiles;
      });
    }
  }

  static isUserInGivenGroup(askedRole:UserProfile):boolean {
    const userGroups = UserStateManager.getCurrentUserGroups();
    const foundedP = userGroups.find(userGroup => userGroup.group_id === askedRole);
    if(foundedP) {
      return true
    } else {
      return false
    }
  }

  static isUserAnEmployee():boolean {
    if(!CognovisProfile.isUserInGivenGroup(UserProfile.EMPLOYEE) && (CognovisProfile.isUserInGivenGroup(UserProfile.FREELANCER) || CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER))) {
        return true
    } else {
        return false
    }
  }

  static isUserWithinGroup(group: UserProfile): Promise<boolean> {
    return this.getCurrentUserProfiles()
    .then(
      (
        currentUserProfiles: { group_id: UserProfile; group_name: string }[]
      ) => {
        let isWithinGroup = false;
        currentUserProfiles.forEach((userProfile) => {
          if (userProfile.group_id === group) {
            isWithinGroup = true;
          }
        });
        return isWithinGroup;
      }
    );
  }

  static isUserProjectManagerOrAdmin():Promise<boolean> {
    return this.getCurrentUserProfiles()
    .then((currentUserProfiles: { group_id: UserProfile; group_name: string }[]) => {
        const onlyIds = currentUserProfiles.map(group => group.group_id);
        if(onlyIds.indexOf(UserProfile.PO_ADMIN) > -1 || onlyIds.indexOf(UserProfile.PROJECT_MANAGER) > -1) {
            return true
        } else {
            return false;
        }
    })   
  }
  
}
