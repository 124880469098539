/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean1 } from '../models/Boolean1';
import type { IError } from '../models/IError';
import type { ImProjectId } from '../models/ImProjectId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IWebixNotification } from '../models/IWebixNotification';
import type { IWebixNotificationAction } from '../models/IWebixNotificationAction';
import type { IWebixNotificationBody } from '../models/IWebixNotificationBody';
import type { PersonId } from '../models/PersonId';
import type { WebixNotificationId } from '../models/WebixNotificationId';
import type { WebixNotificationType } from '../models/WebixNotificationType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebixPortalNotificationService {

    /**
     * Webix Notification
     * @returns IWebixNotification Array of notifications
     * @throws ApiError
     */
    public static getWebixNotification({
        notificationId,
        projectId,
        recipientId,
        contextId,
        notificationTypeId,
        excludeRead,
    }: {
        /**
         * Notification which we want to get
         */
        notificationId?: WebixNotificationId,
        /**
         * Project for which we want to get the notifications
         */
        projectId?: ImProjectId,
        /**
         * User for whom we want to read the notifications
         */
        recipientId?: PersonId,
        /**
         * Context for which we would like to get a list of notifications
         */
        contextId?: IntegerInt32,
        /**
         * Type of notification we are looking for
         */
        notificationTypeId?: WebixNotificationType,
        /**
         * should we exclude read notifications (yes is default)
         */
        excludeRead?: Boolean1,
    }): CancelablePromise<Array<IWebixNotification>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/webix_notification',
            query: {
                'notification_id': notificationId,
                'project_id': projectId,
                'recipient_id': recipientId,
                'context_id': contextId,
                'notification_type_id': notificationTypeId,
                'exclude_read': excludeRead,
            },
        });
    }

    /**
     * Webix Notification
     * @returns IWebixNotification Object with the created notification
     * @throws ApiError
     */
    public static postWebixNotification({
        requestBody,
    }: {
        /**
         * Notification Information
         */
        requestBody: IWebixNotificationBody,
    }): CancelablePromise<IWebixNotification> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webix_notification',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Webix Notification
     * Deletes a notification
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteWebixNotification({
        notificationId,
    }: {
        notificationId: WebixNotificationId,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/webix_notification',
            query: {
                'notification_id': notificationId,
            },
        });
    }

    /**
     * Webix Notification
     * @returns IWebixNotification Object with the created notification
     * @throws ApiError
     */
    public static putWebixNotification({
        notificationId,
        requestBody,
    }: {
        /**
         * Notification we want to update
         */
        notificationId: WebixNotificationId,
        /**
         * Notification Information
         */
        requestBody: IWebixNotificationBody,
    }): CancelablePromise<IWebixNotification> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/webix_notification',
            query: {
                'notification_id': notificationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IWebixNotificationAction Array of actions we can perform on that notification
     * @throws ApiError
     */
    public static getWebixNotificationAction({
        notificationId,
    }: {
        /**
         * Notification which we want to get
         */
        notificationId: WebixNotificationId,
    }): CancelablePromise<Array<IWebixNotificationAction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/webix_notification_action',
            query: {
                'notification_id': notificationId,
            },
        });
    }

    /**
     * Marks a notification as read
     * @returns IWebixNotification Object of notification
     * @throws ApiError
     */
    public static postWebixNotificationRead({
        notificationId,
    }: {
        /**
         * Notification we want to mark as read
         */
        notificationId: WebixNotificationId,
    }): CancelablePromise<IWebixNotification> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webix_notification_read',
            query: {
                'notification_id': notificationId,
            },
        });
    }

    /**
     * Runs a schedule proc for notifications
     * @returns IWebixNotification Array of notifications
     * @throws ApiError
     */
    public static postWebixNotificationSchedule({
        scheduleProc,
    }: {
        /**
         * Name of the schedule procedure to call in the webix namespace
         */
        scheduleProc: string,
    }): CancelablePromise<Array<IWebixNotification>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webix_notification_schedule',
            query: {
                'schedule_proc': scheduleProc,
            },
        });
    }

}
