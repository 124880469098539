

export class PermissionsHelper {

    static userHasViewPermission(objectPermission:string):boolean {
        if (objectPermission.indexOf("view") > -1) {
            return true
        } else {
            return false
        }   
    }

    static userHasReadPermission(objectPermission:string):boolean {
        if (objectPermission.indexOf("read") > -1) {
            return true
        } else {
            return false
        }
    }

    static userHasWritePermission(objectPermission:string):boolean {
        if (objectPermission.indexOf("write") > -1) {
            return true
        } else {
            return false
        }
    }

    static userHasAdminPermission(objectPermission:string):boolean {
        if (objectPermission.indexOf("admin") > -1) {
            return true
        } else {
            return false
        }   
    }

}