/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean0 } from '../models/Boolean0';
import type { ICostCenter } from '../models/ICostCenter';
import type { IError } from '../models/IError';
import type { IInvoice } from '../models/IInvoice';
import type { IInvoiceBody } from '../models/IInvoiceBody';
import type { IInvoiceBodyPut } from '../models/IInvoiceBodyPut';
import type { IInvoiceItem } from '../models/IInvoiceItem';
import type { IInvoiceItemBody } from '../models/IInvoiceItemBody';
import type { IInvoiceItemBodyPut } from '../models/IInvoiceItemBodyPut';
import type { IInvoiceItemsSortOrderPut } from '../models/IInvoiceItemsSortOrderPut';
import type { IInvoiceTimesheetTasksPut } from '../models/IInvoiceTimesheetTasksPut';
import type { IMail } from '../models/IMail';
import type { IMailPerson } from '../models/IMailPerson';
import type { ImCompanyId } from '../models/ImCompanyId';
import type { ImCostCenterId } from '../models/ImCostCenterId';
import type { ImInvoiceId } from '../models/ImInvoiceId';
import type { ImInvoiceIds } from '../models/ImInvoiceIds';
import type { ImProjectId } from '../models/ImProjectId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IntranetCostCenterStatus } from '../models/IntranetCostCenterStatus';
import type { IntranetCostCenterType } from '../models/IntranetCostCenterType';
import type { IntranetCostStatus } from '../models/IntranetCostStatus';
import type { IntranetCostStatusIds } from '../models/IntranetCostStatusIds';
import type { IntranetCostType } from '../models/IntranetCostType';
import type { IntranetCostTypeIds } from '../models/IntranetCostTypeIds';
import type { NumberFloat } from '../models/NumberFloat';
import type { Pagination } from '../models/Pagination';
import type { PersonId } from '../models/PersonId';
import type { StringPdf } from '../models/StringPdf';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestInvoiceService {

    /**
     * Return a list of cost centers the user can see for selection
     * @returns ICostCenter Array of Cost Centers
     * @throws ApiError
     */
    public static getCostCenters({
        costCenterTypeId,
        costCenterStatusId,
        parentId,
    }: {
        /**
         * What kind of cost centers are we looking for
         */
        costCenterTypeId?: IntranetCostCenterType,
        /**
         * What kind of cost centers are we looking for
         */
        costCenterStatusId?: IntranetCostCenterStatus,
        /**
         * Parend Cost Center for which we are looking for siblings
         */
        parentId?: ImCostCenterId,
    }): CancelablePromise<Array<ICostCenter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cost_centers',
            query: {
                'cost_center_type_id': costCenterTypeId,
                'cost_center_status_id': costCenterStatusId,
                'parent_id': parentId,
            },
        });
    }

    /**
     * Return invoice data for the selected invoices (or all per filter)<br><br>    Note invoice is a placeholder for every kind of invoice (invoice, credit_memo, provider bill, quote, cancellation invoice ........)
     * @returns IInvoice Array of the invoice information
     * @throws ApiError
     */
    public static getInvoice({
        companyId,
        invoiceIds,
        projectId,
        costTypeIds,
        costStatusIds,
        pagination,
    }: {
        /**
         * Company which is the recipient / provider for this invoice (aka the 'other' party)
         */
        companyId?: ImCompanyId,
        /**
         * invoice_ids which we want to view the invoice for
         */
        invoiceIds?: ImInvoiceIds,
        /**
         * id for which we need quotes
         */
        projectId?: ImProjectId,
        /**
         * Limit to only documents of this cost_type
         */
        costTypeIds?: IntranetCostTypeIds,
        /**
         * Limit to only documents of this cost status
         */
        costStatusIds?: IntranetCostStatusIds,
        /**
         * Sort by property cost_name or effective_date is sensible
         */
        pagination?: Pagination,
    }): CancelablePromise<Array<IInvoice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invoice',
            query: {
                'company_id': companyId,
                'invoice_ids': invoiceIds,
                'project_id': projectId,
                'cost_type_ids': costTypeIds,
                'cost_status_ids': costStatusIds,
                'pagination': pagination,
            },
        });
    }

    /**
     * Update an existing invoice
     * @returns IInvoice Object of the invoice information
     * @throws ApiError
     */
    public static postInvoice({
        requestBody,
    }: {
        /**
         * Invoice we would like to update
         */
        requestBody: IInvoiceBody,
    }): CancelablePromise<IInvoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/invoice',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Allow multiple invoices to be set to deleted at once. Should be deprecated and you should use PUT with a new cost_status_id
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteInvoice({
        invoices,
    }: {
        /**
         * invoice_ids which we want to delete
         */
        invoices?: ImInvoiceIds,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/invoice',
            query: {
                'invoices': invoices,
            },
        });
    }

    /**
     * Update an existing invoice
     * @returns IInvoice Object of the invoice information
     * @throws ApiError
     */
    public static putInvoice({
        invoiceId,
        requestBody,
    }: {
        /**
         * Invoice which we want to update
         */
        invoiceId: ImInvoiceId,
        /**
         * Invoice we would like to update
         */
        requestBody: IInvoiceBodyPut,
    }): CancelablePromise<IInvoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/invoice',
            query: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Return a file for the invoice back to the browser directly
     * @returns string Generic description
     * @throws ApiError
     */
    public static getInvoiceDocument({
        invoiceId,
        previewP,
        type,
    }: {
        /**
         * Invoice for which we want to generate the document
         */
        invoiceId: ImInvoiceId,
        /**
         * If we are not generating a preview we store the file in the content repository
         */
        previewP?: Boolean0,
        /**
         * Filetype we want to generate
         */
        type?: StringPdf,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invoice_document',
            query: {
                'invoice_id': invoiceId,
                'preview_p': previewP,
                'type': type,
            },
        });
    }

    /**
     * Provide the line items from project-open for a specific invoice
     * @returns IInvoiceItem Array of line items for given invoice_id
     * @throws ApiError
     */
    public static getInvoiceItem({
        invoiceId,
        itemId,
        onlyNotInvoicedP,
    }: {
        /**
         * Invoice for which to return the line items
         */
        invoiceId: ImInvoiceId,
        /**
         * ID of the item we want to retrieve within the invoice. Will result in an array of length 1
         */
        itemId?: IntegerInt32,
        /**
         * should we filter out items which were already invoiced?
         */
        onlyNotInvoicedP?: Boolean0,
    }): CancelablePromise<Array<IInvoiceItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invoice_item',
            query: {
                'invoice_id': invoiceId,
                'item_id': itemId,
                'only_not_invoiced_p': onlyNotInvoicedP,
            },
        });
    }

    /**
     * Create completely new invoice_item
     * @returns IInvoiceItem Array of line items for given invoice_id
     * @throws ApiError
     */
    public static postInvoiceItem({
        invoiceId,
        requestBody,
    }: {
        /**
         * invoice id in which we generate the line item
         */
        invoiceId: ImInvoiceId,
        /**
         * Information for a new line item.
         */
        requestBody: IInvoiceItemBody,
    }): CancelablePromise<IInvoiceItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/invoice_item',
            query: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the provided line items if it is possible to remove them<br><br>        Will only remove all or none. If there are no errors, then the removal was successful.
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteInvoiceItem({
        invoiceItemId,
    }: {
        /**
         * invoice_items_id id of line item which try to remove
         */
        invoiceItemId: IntegerInt32,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/invoice_item',
            query: {
                'invoice_item_id': invoiceItemId,
            },
        });
    }

    /**
     * Update existing line item
     * @returns IInvoiceItem line item which was updated
     * @throws ApiError
     */
    public static putInvoiceItem({
        itemId,
        requestBody,
    }: {
        /**
         * id of line item
         */
        itemId: IntegerInt32,
        /**
         * single line item which we overwrite
         */
        requestBody: IInvoiceItemBodyPut,
    }): CancelablePromise<IInvoiceItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/invoice_item',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the data necessary to send the invoice via mail to the client
     * @returns IMail Mail object to fill out {} the template
     * @throws ApiError
     */
    public static getInvoiceMail({
        invoiceId,
    }: {
        /**
         * Invoice for which we want to get the mail parties
         */
        invoiceId: ImInvoiceId,
    }): CancelablePromise<IMail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invoice_mail',
            query: {
                'invoice_id': invoiceId,
            },
        });
    }

    /**
     * Returns a list of potential recipients for the invoice_id
     * @returns IMailPerson List of Recipients
     * @throws ApiError
     */
    public static getInvoiceMailRecipient({
        invoiceId,
    }: {
        /**
         * Invoice for which we want to get the senders.
         */
        invoiceId: ImInvoiceId,
    }): CancelablePromise<Array<IMailPerson>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invoice_mail_recipient',
            query: {
                'invoice_id': invoiceId,
            },
        });
    }

    /**
     * Returns a list of potential sender for the invoice_id
     * @returns IMailPerson List of senders
     * @throws ApiError
     */
    public static getInvoiceMailSender({
        invoiceId,
        projectId,
    }: {
        /**
         * Invoice for which we want to get the senders.
         */
        invoiceId?: ImInvoiceId,
        /**
         * Project for which we want to get the senders.
         */
        projectId?: ImProjectId,
    }): CancelablePromise<Array<IMailPerson>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invoice_mail_sender',
            query: {
                'invoice_id': invoiceId,
                'project_id': projectId,
            },
        });
    }

    /**
     * Cancel an existing invoice and create a cancellation invoice for it.
     * @returns IInvoice Information of the copied invoice
     * @throws ApiError
     */
    public static postInvoiceCancellation({
        invoiceId,
    }: {
        /**
         * Invoice which we want to cancel. Needs write as we update the status.
         */
        invoiceId: ImInvoiceId,
    }): CancelablePromise<IInvoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/invoice_cancellation',
            query: {
                'invoice_id': invoiceId,
            },
        });
    }

    /**
     * Generate an invoice out of a quote
     * @returns IInvoice Information of the copied invoice
     * @throws ApiError
     */
    public static postInvoiceCopy({
        sourceInvoiceIds,
        targetCostTypeId,
        filedP,
        noItemsP,
    }: {
        /**
         * Invoices which we want to turn into a single new one of a specific type
         */
        sourceInvoiceIds: ImInvoiceIds,
        /**
         * Type of the new generated invoice
         */
        targetCostTypeId: IntranetCostType,
        /**
         * Should we file the source invoice_ids after we created the new one?
         */
        filedP?: Boolean0,
        /**
         * Should we incluce line items?
         */
        noItemsP?: Boolean0,
    }): CancelablePromise<IInvoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/invoice_copy',
            query: {
                'source_invoice_ids': sourceInvoiceIds,
                'target_cost_type_id': targetCostTypeId,
                'filed_p': filedP,
                'no_items_p': noItemsP,
            },
        });
    }

    /**
     * Sends all created provider bills to the providers of the project. set them to a new status
     * @returns IInvoice Array of the invoice information
     * @throws ApiError
     */
    public static postSendFinancialDocuments({
        projectId,
        costTypeIds,
        fromPartyId,
        newCostStatusId,
        costStatusId,
        ccAddr,
    }: {
        /**
         * Project which we send the messages for
         */
        projectId: ImProjectId,
        /**
         * Which cost type should we send the financial_documents for
         */
        costTypeIds: IntranetCostTypeIds,
        /**
         * Who is the sender of the E-mails. Defaults to the project_lead_id
         */
        fromPartyId?: PersonId,
        /**
         * New cost status which we set the bill to. Defaults to outstanding
         */
        newCostStatusId?: IntranetCostStatus,
        /**
         * Status of the documents we want to send. Defaults to created
         */
        costStatusId?: IntranetCostStatus,
        /**
         * Whom should we send the bills in CC to
         */
        ccAddr?: string,
    }): CancelablePromise<Array<IInvoice>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/send_financial_documents',
            query: {
                'from_party_id': fromPartyId,
                'project_id': projectId,
                'new_cost_status_id': newCostStatusId,
                'cost_type_ids': costTypeIds,
                'cost_status_id': costStatusId,
                'cc_addr': ccAddr,
            },
        });
    }

    /**
     * Import the timesheet tasks into the invoice.<br><br>        For a quote it will use the billable hours of the timesheet tasks plus<br>        the planned budget hours from the project.<br><br>        For an invoice it will use the actual hours logged.
     * @returns IInvoiceItem Array of line items for given invoice_id
     * @throws ApiError
     */
    public static putImportTimesheetTasks({
        invoiceId,
        requestBody,
    }: {
        /**
         * Invoice which we want to update
         */
        invoiceId: ImInvoiceId,
        /**
         * Hours information
         */
        requestBody: IInvoiceTimesheetTasksPut,
    }): CancelablePromise<Array<IInvoiceItem>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/import_timesheet_tasks',
            query: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update all line items order for given invoice_id<br>    Idea is that we only send array of integers and order of ids is new order for whole document
     * @returns IInvoiceItem Array of line items for given invoice_id
     * @throws ApiError
     */
    public static putInvoiceItemSortOrder({
        invoiceId,
        requestBody,
    }: {
        /**
         * id of invoice for which we change invoice_item orders
         */
        invoiceId: NumberFloat,
        /**
         * put body holding invoice_items_sort_order (array of integers)
         */
        requestBody: IInvoiceItemsSortOrderPut,
    }): CancelablePromise<Array<IInvoiceItem>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/invoice_item_sort_order',
            query: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Allow the company_contact_id to reply to a financial invoice document (typically a quote)
     * @returns IInvoice Object of the invoice information
     * @throws ApiError
     */
    public static putInvoiceReply({
        invoiceId,
        costStatusId,
        note,
    }: {
        /**
         * Invoice the contact is looking at
         */
        invoiceId: ImInvoiceId,
        /**
         * Status of the invoice
         */
        costStatusId: IntranetCostStatus,
        /**
         * Additional information provided by the contact
         */
        note?: string,
    }): CancelablePromise<IInvoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/invoice_reply',
            query: {
                'invoice_id': invoiceId,
                'cost_status_id': costStatusId,
                'note': note,
            },
        });
    }

}
