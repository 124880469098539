/* eslint-disable security/detect-non-literal-fs-filename */
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import { UserProfile } from "../../../sources/modules/cognovis-profile/profiles-types";
import ProjectDetails from "views/project-details";
import PmProjectView from "./pm-project-view";
import CustomerProjectView from "./customer-project-view";
import { JetApp, JetView } from "webix-jet";

export default class ProjectInfo extends JetView {

    config():webix.ui.layoutConfig {
        // Check user role and decide which view should be displayed
        if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
            return {
                view:"layout",
                rows:[PmProjectView]
            }
        }
        if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
            return {
                view:"layout",
                rows:[CustomerProjectView]
            }
        }
    }

}
