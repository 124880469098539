
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import BaseView from "views/baseview";
import { IAssignmentQualityReport, WebixPortalAssignmentService } from "../../../sources/openapi";
import DeliveryRating from "views/assignments/modals/delivery-rating/delivery-rating";

export default class FreelancerRatingOverview extends BaseView {

    idPrefix = "fro";
    deliveryRatingModal:DeliveryRating;
    //currentFreelancer:INamedId;

    config():Promise<webix.ui.layoutConfig> {
        const freelancerId = Number(this.getParam("freelancer_id", false));
        return WebixPortalAssignmentService.getAssignmentQualityReport({
            assigneeId:freelancerId
        })
        .then(reports => {
            const mainLayout = {
                view:"layout",
                padding:20,
                rows:[
                    {
                        view:"template",
                        type:"header",
                        template:`${i18nHelper.getTranslation("Ratings")}:${reports[0].assignee.name}`
                    },
                    this.getRatingsDatatable(reports)
                ]
            }
            return mainLayout
        });
    }

    getRatingsDatatable(reports:IAssignmentQualityReport[]):webix.ui.datatableConfig {
        const columns = this.getDatatableColumns(reports);
        const data = reports;
        const datatable = {
            view:"datatable",
            columns:columns,
            tooltip:true,
            data:data,
            onClick:{
                "fa-edit":function(e,row) {
                    const item = this.getItem(row);
                    this.$scope.openRatingEditionModal(item.assignment.id, item);
                }
            }
        }
        return datatable;
    }

    getDatatableColumns(reports:IAssignmentQualityReport[]):{id:string, width?:number, header?:string, tooltip?:(obj)=>string,map?:string, fillspace?:boolean, template?:(obj) => string}[] {
        const columns = [
            {
                id:"project_nr",
                header: i18nHelper.getTranslation("Project_nr"), 
                map:"#project_nr#",
                width:100,
            },
            {
                id:"project_name",
                header:i18nHelper.getTranslation("Project_name"),
                map:"#project.name#",
                fillspace:true,
            },
            {
                id:"task_type",
                header:i18nHelper.getTranslation("Type"),
                map:"#task_type.name#",
            },

        ] as {id:string, width:number, header?:string, tooltip?:(obj)=>string, map?:string, fillspace?:boolean, template?:(obj) => string}[]
        // Find out unique rating categories 
        const uniqueRatingCats = [];
        reports.map(report => {
            if(report.rating) {
                report.rating.map(singleRating => {
                    if(uniqueRatingCats.indexOf(singleRating.quality_type.name) === -1) {
                        uniqueRatingCats.push(singleRating.quality_type.name);
                    }
                });
            }
        });
      
        uniqueRatingCats.map(catRating => {
            columns.push({
                id:catRating,
                header:catRating,
                map:"",
                width:120,
                fillspace:false,
                template:(obj) => {
                    let displayedValue = "";
                    obj?.rating?.map(rating => {
                        if(rating.quality_type?.name === catRating) {
                            displayedValue = `${rating["quality_value"]}/5`
                        }
                    });
                    return displayedValue
                }
            })
        });
        columns.push({
            id:"overall",
            header:i18nHelper.getTranslation("Overall_rating"),
            width:120,
            tooltip:(obj) => {
                let total = 0;
                let countPossibles = 0;
                obj.rating.map(rating => {
                    if(rating["quality_value"] !== "") {
                        total = total + parseInt(rating["quality_value"])
                        countPossibles++;
                    }
                });
                const average = (total/countPossibles);
                return average.toFixed(2)             
            },
            fillspace:false,
            template:(obj) => {
                let total = 0;
                let countPossibles = 0;
                obj?.rating?.map(rating => {
                    if(rating["quality_value"] !== "") {
                        total = total + parseInt(rating["quality_value"])
                        countPossibles++;
                    }
                });
                const average = (total/5);
                const percentage = ((average*100)/countPossibles);
                return `<ul><li><div class="stars"><div class="percent" style="width: ${percentage}%;"></div></div></li></ul>`;
            }
        });
        columns.push(
            {
                id:"edit",
                width:40,
                template:() => {
                    return `<span style='color:#59a8c2 !important;' class='webix_icon fas fa-edit'></span>`;
                }
            }
        )
        return columns;
    }


    openRatingEditionModal(assignmentId:number, report:IAssignmentQualityReport):void {
        if(!this.deliveryRatingModal) {
            this.deliveryRatingModal = this.ui(DeliveryRating) as DeliveryRating;
        }
        WebixPortalAssignmentService.getTranslationAssignments({
            assignmentId:assignmentId
        })
        .then(assignments => {
            this.deliveryRatingModal.openModalForRatingEdition(assignments[0], report, () => {
               
            });
        });
    }

}