/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISearchResult } from '../models/ISearchResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebixPortalSearchService {

    /**
     * @returns ISearchResult Results which match the query and for which the current user has access
     * @throws ApiError
     */
    public static getWebixSearch({
        query,
    }: {
        /**
         * String which we are querying for
         */
        query: string,
    }): CancelablePromise<Array<ISearchResult>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/webix_search',
            query: {
                'query': query,
            },
        });
    }

}
