import BaseView from "../baseview";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import { CognovisRestInvoiceService } from "../../openapi/services/CognovisRestInvoiceService";
import { IInvoice, IInvoiceItem } from "../../../sources/openapi";
import LineItemsDescription from "views/invoice-detail/modals/line-items-description";
import { IJetApp } from "webix-jet/dist/types/interfaces";
import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import DiscountsModal from "views/modals/discounts-modal";
import ObjectNotesModal from "views/modals/object-notes-modal";
import InvoiceItemDetailsModal from "./modals/invoice-item-details-modal";
import {config} from "../../config";
export class InvoiceItems extends BaseView {

    invoiceData: IInvoice;
    isInvoiceEditable = false;
    showDescModal: LineItemsDescription;
    currencySymbol: string;
    tableName = "list-items-table";
    projectId: number;
    itemId: string;
    isThatNewRow = false;
    discountsModal:DiscountsModal;
    notesModal:ObjectNotesModal;
    invoiceItemEditModal:InvoiceItemDetailsModal;
    newLineItemDefaults = {
        item: {
            id:-1,
            name:""
        },
        item_name:"",
        item_uom:{
            id: 324,
            name: "S-Word"
        },
        source_language:{
            id:"",
            name:""
        },
        target_language:{
            id:"",
            name:""
        },
        item_units:0,
        price_per_unit:0,
        net_amount:0,
        edit:true
    }
    minHeight = 300;

    constructor(app: IJetApp, invoiceData: IInvoice, isInvoiceEditable = false) {
        super(app, "", {});
        this.invoiceData = invoiceData;
        this.isInvoiceEditable = isInvoiceEditable;        
    }

    config(): webix.ui.layoutConfig { 
        const layout = this.getLayout();
        return layout;
    }

    init():void {
        this.fillDatatable();
    }

    fillDatatable():void {
        const table = webix.$$("list-items-table") as webix.ui.treetable;
        if(table) {
            table.clearAll();
            this.getLineItems()
            .then(lineItems => {
                table.define("data",lineItems);
                table.refresh();
                this.updateTotalAmountsWithLineItems();
            });
        }
    }

    getLayout(): webix.ui.layoutConfig {
        const isInvoiceEditable = this.isInvoiceEditable;
        return {
            view: "layout",
            rows: [
                {
                    view: "label",
                    type: "header",
                    label:this.invoiceData.invoice.name,
                    //label: this.invoiceData.project?.name,
                    id: "invoice-name",
                    css: "cog-box-content padding-left-10",
                    borderless: true,
                },
                {
                    view: "datatable",
                    id: "list-items-table",
                    select: "row",
                    multiselect: true,
                    footer: true,
                    scroll:false,
                    navigation: true,
                    minHeight:this.minHeight,
                    tooltip: true,
                    tabIndex: -1,
                    on: {
                        onAfterDrop:(context, event) => {
                            this.updateSortOrder(event,context);
                        },
                        onAfterLoad:() => {
                            this.resizeTable();
                        }
                    },
                    drag:true,
                    columns: [
                        {
                            id: "item_name",
                            header: i18nHelper.getTranslation("Item_name"),
                            tooltip:(obj) => {
                                return obj.item.name
                            },
                            fillspace: true,
                            editor: "text",
                            map:"#item.name#",
                        },
                        {
                            view: "template",
                            header: "",
                            width: 50,
                            template: (obj) => {
                                let cssColor = "";
                                if(obj.description) {
                                    cssColor = "text-green";
                                }
                                return `<span class='fa fa-eye ${cssColor}'></span>`;
                            },
                            tooltip:(obj) => {
                                return obj.description;
                            }
                        },
                        { 
                            editor:"combo", 
                            id: "source_language", 
                            map: "#source_language?.id#", 
                            header: i18nHelper.getTranslation("Source_language"),
                            tooltip: false,
                            options: () => {
                                return CognovisCategory.getCategory("Intranet Translation Language")
                                .then(res => {
                                    const langs = [{ id: "", value: "" }, ...res];
                                    return langs;               
                                });
                            },
                        },
                        { 
                            editor:"combo", 
                            id: "target_language", 
                            map: "#target_language?.id#", 
                            header: i18nHelper.getTranslation("Target_language"), 
                            tooltip: false, 
                            options: () => {
                                return CognovisCategory.getCategory("Intranet Translation Language")
                                .then(res => {
                                    const langs = [{ id: "", value: ""}, ...res];
                                    return langs;               
                                });
                            },
                        },
                        {
                            id: "item_units",
                            header:i18nHelper.getTranslation("Units"),
                            tooltip:false,
                            editor: "text",
                        },
                        {
                            editor:"combo",
                            id: "item_uom",
                            map: "#item_uom.id#",
                            tooltip: false,
                            header: i18nHelper.getTranslation("Unit_of_measure"),
                            options:() => CognovisCategory.getCategory("Intranet UoM")
                        },
                        {
                            id: "price_per_unit",
                            header: i18nHelper.getTranslation("Price_per_unit"),
                            editor: "text",
                            tooltip: "Price per unit",
                            template:function(obj) {
                                if(obj.price_per_unit) {
                                    return PriceHelper.formatPrice(obj.price_per_unit);
                                } else {
                                    return PriceHelper.formatPrice(0);
                                }          
                            }
                        },
                        {
                            id: "net_amount",
                            header:  i18nHelper.getTranslation("Net_amount"),
                            tooltip:false,
                            width:120,
                            footer: [
                                { text:  i18nHelper.getTranslation("Subtotal")},
                                { text:  i18nHelper.getTranslation("Value_added_tax")},
                                { text:  i18nHelper.getTranslation("Grand_total") },
                            ],
                            template:function(obj) {
                                return PriceHelper.formatPrice(obj["net_amount"])
                            }
                        },
                        {
                            view: "template",
                            id:"summary",
                            width:100,
                            footer: [
                                {
                                    text: PriceHelper.formatPrice(this.invoiceData.amount),
                                    css:{
                                        "text-align":"right"
                                    },
                                    id: "subTotal",
                                },
                                {
                                    text: PriceHelper.formatPrice(this.invoiceData.vat_amount),
                                    css:{
                                        "text-align":"right"
                                    },
                                    id: "vat",
                                },
                                {
                                    text: PriceHelper.formatPrice(this.invoiceData.grand_total),
                                    css:{
                                        "text-align":"right"
                                    },
                                    id: "totalSum",
                                },
                            ],
                            translateHeader:false,
                            header:{
                                text:`${this.isInvoiceEditable ? '<div style="text-align:right;"><span class="fa fa-plus"></span></div>' : ''}`,
                            },
                            template: function (obj) {
                                let disabledCssClass = "";
                                if(!isInvoiceEditable) {
                                    disabledCssClass = "display-none";
                                } 
                                return `<div style='text-align:right; width:100%'><span class="fa fa-trash-alt ${disabledCssClass}"></span> <span class='${disabledCssClass} webix_icon wxi-${obj.edit ? "check icon-red" : "pencil"}' id='edit-${obj.edit ? "check icon-red" : "pencil"}'></span></div>`;
                            }
                        },
                    ],

                    onClick: {
                        //description modal functionality
                        "fa-eye": (event, cell) => {
                            if(!this.notesModal) {
                                this.notesModal = this.ui(ObjectNotesModal) as ObjectNotesModal;
                            }
                            const table = $$("list-items-table") as webix.ui.datatable;
                            const lineItem:IInvoiceItem = table.getItem(cell);
                            this.notesModal.openNotesModal("write", i18nHelper.getTranslation("Invoice item note"), lineItem.item.id, lineItem.description, 'cke5', [], (newDescription:string) => {
                                CognovisRestInvoiceService.putInvoiceItem({
                                    itemId:lineItem.item.id,
                                    requestBody:{
                                        description:this.trimDescription(newDescription)
                                    }
                                })
                                .then(() => {
                                    this.refreshTable();
                                })
                                .catch(err => {
                                    webix.alert({
                                        title: err.message,
                                        text: err.body.message,
                                        type: "alert-error",
                                        width: 500,
                                        css:"cog-remove-tasks-modal"
                                    });
                                })
                            })
                        },
                        //description modal functionality
                        "fa-plus": () => {
                            this.addNewLineItem();
                        },
                        //start editing item
                        "wxi-pencil": (event, row) => {
                            const table = $$(this.tableName) as webix.ui.datatable;
                            if(this.isInvoiceEditable && config.linteItemEditMode === "inline") {
                                
                                table.define("editable", true);
                                this.isThatNewRow = false;
                                this.editStart(event, row);
                            }
                            if(this.isInvoiceEditable && config.linteItemEditMode === "modal") {
                                if(!this.invoiceItemEditModal) {
                                    this.invoiceItemEditModal = this.ui(InvoiceItemDetailsModal) as InvoiceItemDetailsModal;
                                }
                                const item = table.getItem(row.row);
                                this.invoiceItemEditModal.openModal(item, () => {
                                    this.refreshTable();
                                });
                                table.define("editable", true);
                                this.isThatNewRow = false;
                                this.editStart(event, row);
                            }
                        },

                        //save edited item
                        "wxi-check": (event, row) => {
                            if(this.isInvoiceEditable) {
                                const table = $$(this.tableName) as webix.ui.datatable;
                                table.define("editable", false);
                                this.editEnd(event, row);
                            }
                        },

                        "fa-trash-alt": (event, cell) => {
                            if(this.isInvoiceEditable) {
                                const table = $$(this.tableName) as webix.ui.datatable;
                                const lineItem:IInvoiceItem = table.getItem(cell.row);
                                this.attemptToRemoveLineItem(lineItem);
                            }
                        }
                    },
                },
            ],
        };
    }

    getLineItems():Promise<IInvoiceItem[]> {
        const invoicesQuery = {
            invoiceId: this.invoiceData.invoice.id,
        };
        return CognovisRestInvoiceService.getInvoiceItem(invoicesQuery)
        .then((response) => {
            const lineItems = response;
            lineItems.forEach((lineItem) => {
                const netAmount = Number(lineItem.price_per_unit) * Number(lineItem.item_units);
                const netAmountFormatted = PriceHelper.formatPrice(netAmount);
                lineItem["net_amount"] = netAmountFormatted;
            });
            return lineItems;
        });
    }
    

    refreshTable():void {
        const table = $$("list-items-table") as webix.ui.datatable;
        table.clearAll(false);
        this.getLineItems()
        .then(lineItems => {
            table.define("data",lineItems);
            this.updateTotalAmountsWithLineItems();
            this.resizeTable();
        });
    }

    resizeTable():void {
        const table = webix.$$("list-items-table") as webix.ui.datatable;
        const totalItems = table.serialize().length;
        const newTableHeight = (totalItems * 40) + 160;
        if(newTableHeight > this.minHeight) {
            table.config.height = newTableHeight;
        }
        table.resize();
    }

    addNewLineItem():void {
        this.isThatNewRow = true;
        const table = $$("list-items-table") as webix.ui.datatable;
        const data = this.newLineItemDefaults;
        const id = table.add(webix.copy(data), 0) as string;
        table.define("editable", true);
        table.editCell(id, "item_name");
    }

    addNewLineItemModal():void {
        this.isThatNewRow = true;
        if(!this.invoiceItemEditModal) {
            this.invoiceItemEditModal = this.ui(InvoiceItemDetailsModal) as InvoiceItemDetailsModal;
        }
        this.invoiceItemEditModal.openModal({}, () => {
            this.refreshTable();
        });
    }

    editStart(event: Event, elementId: string): void {
        const table = $$(this.tableName) as webix.ui.datatable;

        if (this.itemId && typeof this.itemId !== "undefined") {
            //form.clearValidation();
            if (table.getItem(this.itemId)) {
                table.getItem(this.itemId).edit = false;
                table.refresh(this.itemId);
            }
        }
        this.itemId = elementId["row"];
        this.markAsCurrentlyEdited(this.itemId);
        table.editStop();
        table.editCell(elementId, "item_name");
        this.isThatNewRow = false;
    }

    markAsCurrentlyEdited(item: string): void {
        const table = $$(this.tableName) as webix.ui.datatable;
        // clear any current selection
        this.clearEditMode();
        const vals = table.getItem(item);
        vals.edit = true;
    }

    clearEditMode(): void {
        const table = $$(this.tableName) as webix.ui.datatable;
        table.editStop();
        this.itemId = "";
        if (this.itemId) {
            table.getItem(this.itemId).edit = false;
            table.refresh(this.itemId);
        }
    }

    bindShowDescModalToUi(lineItem:IInvoiceItem): void {
        this.showDescModal = this.ui(LineItemsDescription) as LineItemsDescription;
        const saveAction = (desc:string):void => {
            CognovisRestInvoiceService.putInvoiceItem({
                itemId:lineItem.item.id,
                requestBody:{
                   item_name:lineItem.item.name,
                   item_units:lineItem.item_units,
                   description:desc,
                   price_per_unit:lineItem.price_per_unit,
                   //sort_order:""
                }
            })
            .then(() => {
                this.refreshTable();
            })
            .catch(err => {
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            })
        };
        this.showDescModal.showModal(lineItem, this.isInvoiceEditable,saveAction);
    }

    attemptToRemoveLineItem(lineItem:IInvoiceItem):void {
        const confirmWarning = `${i18nHelper.getTranslation(`You_want_to_remove`)}: ${lineItem.item.name} ?<br/>`;
        webix.confirm({
            title: i18nHelper.getTranslation(`any_are_you_sure_message`),
            type: "confirm-warning",
            text: confirmWarning,
            width: 480
        })
        .then(() => {
            this.deleteListItem(lineItem.item.id);
            this.refreshTable();
        });
    }

    deleteListItem(lineItemId:number): void {
        CognovisRestInvoiceService.deleteInvoiceItem({invoiceItemId:lineItemId})
        .then(response => {
            if(response.length === 0) {
                this.refreshTable();
            } else {
                // let errorMessages = "";
                // response.map(error => {
                //     errorMessages = `${errorMessages}<br/>${error.err_msg}`;
                // });
                // webix.message({ type: "error", text: errorMessages });
                this.refreshTable();
            }
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    updateSortOrder(event: Event, row: IWebixDatatableRow):void {
        const itemId = row.start;
        const table = ($$(this.tableName) as webix.ui.datatable);
        const items:IInvoiceItem[] = table.serialize();
        const itemsNewSortOrder = [];
        items.map((item:IInvoiceItem, index:number) => {
            item.sort_order = index + 1;
            itemsNewSortOrder.push(item.item.id);
        });
        CognovisRestInvoiceService.putInvoiceItemSortOrder({
            invoiceId:this.invoiceData.invoice.id,
            requestBody:{
                invoice_items_sort_order:itemsNewSortOrder
            } 
        })
        .then(res => {
            this.refreshTable();
        });
    }

    editEnd(event: Event, row: IWebixDatatableRow): void {
        this.itemId = row.row;
        const table = ($$(this.tableName) as webix.ui.datatable);
        table.editStop();
        const lineItem = table.getItem(this.itemId);
        const requestBody = {
            item_name:lineItem.item_name,
            item_units:lineItem.item_units,
            price_per_unit:lineItem.price_per_unit,
            target_language_id:lineItem.target_language,
            source_language_id:lineItem.source_language,
            item_uom_id:lineItem.item_uom.toString(),
            description:lineItem.description
        };
        if(this.isThatNewRow) {
            CognovisRestInvoiceService.postInvoiceItem({
                invoiceId:this.invoiceData.invoice.id,
                requestBody: requestBody
            })  
            .then(() => {
                this.refreshTable();                 
            })
            .catch(err => {
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            });
        } else {
            CognovisRestInvoiceService.putInvoiceItem({
                itemId:lineItem.item.id,
                requestBody: requestBody
            })
            .then(lineItem => {
                if(lineItem) {
                    this.refreshTable();
                } else {
                    webix.message({ type: "error", text: "ooops something went wrong" });
                }
            })
            .catch(err => {
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            });
        }
    }

    updateTotalAmountsWithLineItems():void {
        const table = $$("list-items-table") as webix.ui.datatable;
        const invoiceItems = table.serialize();
        const subtotal = invoiceItems.reduce((a, b) => {
            const netAmount = Number(b.price_per_unit) * Number(b.item_units);
            return a + netAmount
        },0);
        const vatAmount = subtotal * this.invoiceData.vat;
        const grandTotal = subtotal + vatAmount;      
        const footerElements = table.getColumnConfig("summary").footer;
        // Subtotal
        if(footerElements[0]) {
            footerElements[0].text = PriceHelper.formatPrice(subtotal);
        }
        // Vat
        if(footerElements[1]) {
            footerElements[1].text = PriceHelper.formatPrice(vatAmount);
        }
        // Total amount
        if(footerElements[2]) {
            footerElements[2].text = PriceHelper.formatPrice(grandTotal);
        }
        table.refreshColumns();
    }

    openDiscountsModal(invoiceData:IInvoice):void {
        if(!this.discountsModal) {
            this.discountsModal = this.ui(DiscountsModal) as DiscountsModal;
        }
        this.discountsModal.openModal(invoiceData, () => {
            location.reload();
        });
    }

    trimDescription(desc:string):string {
        if(desc.substring(0,3) === "<p>" && desc.substring(desc.length - 4, desc.length) === "</p>") {
            desc = desc.slice(3);
            desc = desc.slice(0, -4);
        }
        return desc.trim();
    }

}
