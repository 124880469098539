import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import { container, singleton } from "tsyringe";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { IntranetCompanyStatus } from "../../../sources/openapi";
import { IntranetCompanyType } from "../../../sources/openapi";
import { CompaniesTableBuilder } from "./companies-table-builder";


@singleton()
export class CompaniesTableTopTabs {

    idPrefix = "cttt";
    currentView = "customers";
    companiesTableBuilder:CompaniesTableBuilder;
    companyCreationAction:() => void;

    getCompaniesTableTopTabs(currentView:string,  companyCreationAction:() => void):webix.ui.layoutConfig {
        if(!CognovisProfile.isUserAnEmployee()) {
            this.companyCreationAction = companyCreationAction;
            this.currentView = currentView;
            const tabs = {
                view:"layout",
                css: {
                    "border-bottom":"1px solid #DADEE0 !important"
                },
                cols: [
                    {
                        view:"layout",
                        id:`${this.idPrefix}TopTabsContainer`,
                        gravity:50,
                        cols:[
                            { 
                                view: "button", 
                                value: i18nHelper.getTranslation("Customers"),
                                id:`${this.idPrefix}TopTabsCustomers`,
                                width:260,
                                css:(this.currentView === "customers" ? "toptab active" : "toptab"),
                                click: () => {
                                    this.setCurrentView("customers");
                                    this.reloadTable([IntranetCompanyStatus.ACTIVE],[IntranetCompanyType.CUSTOMER])
                                },
                            },
                            { 
                                view: "button", 
                                value: i18nHelper.getTranslation("Providers"),
                                id:`${this.idPrefix}TopTabsProviders`,
                                width:260,
                                css:(this.currentView === "providers" ? "toptab active" : "toptab"),
                                click: () => {
                                    this.setCurrentView("providers");
                                    this.reloadTable([IntranetCompanyStatus.ACTIVE],[IntranetCompanyType.PROVIDER])
                                },
                            },
                            { 
                                view: "button", 
                                value: i18nHelper.getTranslation("Final_Companies"),
                                width:260,
                                css:(this.currentView === "final-companies" ? "toptab active" : "toptab"),
                                id:`${this.idPrefix}TopTabsFinalCompanies`,
                                click: () => {
                                    this.setCurrentView("final_companies");
                                    this.reloadTable([IntranetCompanyStatus.ACTIVE],[IntranetCompanyType.FINAL_COMPANY])
                                },
                            },
                            {
                                view:"spacer",
                                width:4
                            },
                            {
                                view:"button",
                                type:"icon",
                                icon:"fal fa-plus", 
                                tooltip:i18nHelper.getTranslation("Create new company"),
                                width:36,
                                click:() => {
                                    this.companyCreationAction();
                                }

                            }
                        ],
                    },
                    {
                        view:"spacer",
                        gravity:50
                    },
                ],
            };
            return tabs;
        } else {
            return {
                view:"spacer",
                height:1
            }
        }
    }

    reloadTable(companiesStatusesIds:IntranetCompanyStatus[], companiesTypesIds:IntranetCompanyType[]):void {
        const table = webix.$$(`coCompaniesDbTable`) as webix.ui.datatable;
        this.companiesTableBuilder = container.resolve(CompaniesTableBuilder);
        this.companiesTableBuilder.reloadTable(companiesStatusesIds, companiesTypesIds);
    }

    setCurrentView(newView:string) {
        this.currentView = newView;
        this.updateTabsActivity();
    }

    updateTabsActivity():void {
        const container = webix.$$(`${this.idPrefix}TopTabsContainer`) as webix.ui.layout;
        const childs = container.getChildViews();
        childs.map(child => {
            webix.html.removeCss(child.getNode(), "active");
        });
    }

}