/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebixPortalSystemService {

    /**
     * Start the whole process of password reset
     * @returns string Generic description
     * @throws ApiError
     */
    public static putForgotPasswordUrl({
        email,
        username,
    }: {
        /**
         * Email of the user who lost his password
         */
        email?: string,
        /**
         * Username of the user who lost his password
         */
        username?: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/forgot_password_url',
            query: {
                'email': email,
                'username': username,
            },
        });
    }

}
