import { CognovisRestReportService, IReportColumn, IReportRow } from "../../openapi/index";
import * as pivot from "@xbs/pivot";
import { runInThisContext } from "vm";

export default class ReportsPivotBackend extends pivot.services.Backend {

    rows:IReportRow[];
    columns:IReportColumn[];
    static dataset:unknown[];
    static useDefaultStructure = true;
    static dataStructure:IPivotDatatableStructure;
    static defaultStructure:IPivotDatatableStructure;
    static dataLoaded = false;

    data():Promise<unknown[]> {
        return webix.promise.resolve(ReportsPivotBackend.dataset as unknown)
    }

    static fetchData(reportId:number) {
        if(reportId) {
            return CognovisRestReportService.getReport({
                reportId:Number(reportId)
            })
            .then(data => {
                const arr = [];
                data.report_rows.map(row => {
                    const obj = {};
                    row.cells.map(cell => {
                        if(cell.id === "hours") {
                            if(cell.display_value === "") {
                                obj[cell.id] = Number(0);
                            } else {
                                obj[cell.id] = Number(cell.display_value);
                            }
                        } else {
                            obj[cell.id] = cell.display_value;
                        }
                    });
                    arr.push(obj);
                });
                ReportsPivotBackend.setStructure(data.report_columns);
                ReportsPivotBackend.setDefaultStructure(data.report_columns);
                ReportsPivotBackend.dataset = arr;
                ReportsPivotBackend.dataLoaded = true;
                return arr
            });
        }
    }

    static setStructure(columns:IReportColumn[]) {
        const structure:IPivotDatatableStructure = {
            rows:[],
            columns:[],
            values:[],
            filters:[]
        };
        columns.map(column => {
            if(column.column_position === "values") {
                structure[column.column_position].push({name:column.variable_name, operation: ["sum"]});
            } else {
                structure[column.column_position].push(column.variable_name);
            }
        });
        ReportsPivotBackend.dataStructure = structure
    }

    static setDefaultStructure(columns:IReportColumn[]) {
        const structure:IPivotDatatableStructure = {
            rows:[],
            columns:[],
            values:[],
            filters:[]
        };
        columns.map((column:IReportColumn, index:number) => {
            if(index < 2) {
                structure["rows"].push(column.variable_name);
            }
            if(index >= 2 && index <= 3) {
                structure["columns"].push(column.variable_name);
            }
            if(index >= 3 && index <= 4) {
                structure["values"].push({name:column.variable_name, operation: ["sum"]});
            }
            if(index >= 4 && index <= 5) {
                //structure["filters"].push(column.variable_name);
            }
        });
        ReportsPivotBackend.defaultStructure = structure
    }

    static getStructure():IPivotDatatableStructure {
        if(ReportsPivotBackend.isStructureAvailable()) {
            return ReportsPivotBackend.dataStructure
        } else {
            return ReportsPivotBackend.defaultStructure
        }
    }

    static isStructureAvailable():boolean {
        if(ReportsPivotBackend.dataStructure.rows.length > 0 && ReportsPivotBackend.dataStructure.columns.length > 0) {
            return true
        } else {
            return false
        }
    }

  }