import * as usermanager from "@xbs/usermanager";

export default class UmCustomRolesForm extends usermanager.views["roles/form"] {

    config() {
        const ui = super.config();
        // Hiding delete button
        if(ui.rows[0]?.cols[1]) {
            ui.rows[0].cols[1].hidden = true;
        }
        if(ui.rows[1]) {
            ui.rows[1].hidden = true;
            ui.rows[3].hidden = true;
        }
        let copySeparator;
        if(ui.rows[3]) {
            ui.rows[3].hidden = true;
            copySeparator = ui.rows[3];
        }
        ui.rows.push({
            view:"text",
            name:"icon",
            inputWidth: 370,
            label:"Icon"
        });
        ui.rows.push(copySeparator)
        return ui
    }
}