import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import CompanyCreationModal from "views/modals/company-creation-modal";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisRestCompanyService, IntranetCollmexRestService, ICompany, CognovisRestRelationshipService } from "../../../sources/openapi";
import { container } from "tsyringe";
import { IJetApp } from "webix-jet";


export default class CompanyBasicInfoPortlet extends CognovisBasicPortlet {

    idPrefix = "cbip";
    companyId:number;
    companyData:ICompany;
    companyEditModal:CompanyCreationModal;
    webixHelpers:WebixHelpers;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    portletId:number;

    constructor(app:IJetApp, companyId:number, portletId?:number, title?:string) {
        super(app, {});
        this.companyId = companyId;
        this.webixHelpers = container.resolve(WebixHelpers);
        this.portletId = portletId;
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.title = "";
    }

    init():void {
        super.init();
        this.companyId = this.getParam("company_id", false);
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        CognovisRestCompanyService.getCompanies({companyId:this.companyId})
        .then(company => {
            this.companyData = company[0];
            this.setHeaderTitle(this.companyData.company.name);
            const sidebar = webix.$$(`sidebarMainSidebar`) as webix.ui.sidebar;
            sidebar.$scope.openCompanyDetails(this.companyData);
            const portlet = this.getCompanyBasicInfoLayout(company[0]);
            this.components = [
                {
                    view:"layout",
                    minHeight:700,
                    cols:[
                        this.getLogoUploader(),
                        portlet
                    ]
                }

            ];
            this.insertComponents();
        })
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    localId:`${this.idPrefix}UserInfoHeader`,
                    template:this.title,
                    borderless: true 
                }
            ]
        };
        return header;
    }

    getCompanyBasicInfoLayout(company:ICompany):webix.ui.layoutConfig {
        const userBasicInfoLayout = {
                view:"layout",
                gravity:0.6,
                css:"cog-box-content",
                rows:[
                    {
                        view:"spacer",
                        height:3,
                        borderless:true
                    },
                    {
                        view:"spacer",
                        height:20,
                        borderless:true
                    },
                    {
                        view:"button",
                        id:`${this.idPrefix}UserPortrait`,
                        template:`<div style='text-align:center;'><img alt="" style='margin:0 auto; border:2px solid #ffffff; height:150px;' src='${company.logo_url}'/></div>`,
                        // template:`<div style='text-align:center;' class='anon-avatar-bg'><img alt="" style='margin:0 auto; border-radius:50%; border:2px solid #ffffff; width:150px; height:150px;' src='assets/images/logo.svg'/></div>`,
                        //template:`<div style='text-align:center;' class='anon-avatar-bg'><i class="huge-fa-icon fas fa-address-card"></i></div>`,
                        height:160,
                        click:() => this.attemptToUploadLogo()
                    },
                    this.getIconMultipleLinesTemplate("fa-building", [
                        `${company.company.name}`, 
                        `${company.company_type?.name}`
                    ], 80, 24),
                    this.getIconMultipleLinesTemplate("fa-map-marker-alt", [company.address_line1, company.address_line2, company.address_postal_code, company.address_city, company.address_country_full], 150, 24),
                    this.getIconLabelTemplate("fas fa-globe", company?.url, "", 60, "url"),
                    this.getIconLabelTemplate("fas fa-envelope", company.primary_contact?.name, "", 60, "email"),
                    this.getIconLabelTemplate("fas fa-coins", company.accounting_contact?.name, "", 60, "email"),
                    this.getIconLabelTemplate("fas fa-phone-alt", company.phone, "", 60,  "phone"),
                    //this.getIconLabelTemplate("fas fa-info", company.referral_source?.name, "", 50),
                    this.getIconMultipleLinesTemplate("fa-coins", [
                        this.displayCollmex(company), 
                        `<span class='info-label'>${i18nHelper.getTranslation("VAT ID")}</span><br/><span class='info-value'>${company.vat_number}</span>`, 
                        `<span class='info-label'>${i18nHelper.getTranslation("Tax classification")}</span><br/><span class='info-value'>${company.vat_type?.name}</span>`, 
                        `<span class='info-label'>${i18nHelper.getTranslation("Payment term")}</span><br/><span class='info-value'>${company.payment_term?.name}</span>`, 
                        `<span class='info-label'>${i18nHelper.getTranslation("Default quote template")}</span><br/><span class='info-value'>${company.default_quote_template?.name}</span>`,
                        `<span class='info-label'>${i18nHelper.getTranslation("Default invoice template")}</span><br/><span class='info-value'>${company.default_invoice_template?.name}</span>`
                    ], 240, 55),
                    // this.getIconLabelTemplate("fas fa-info", company.default_quote_template?.name, "", 50),
                    // this.getIconLabelTemplate("fas fa-info", company.default_invoice_template?.name, "", 50),
                    //this.getIconFlagTemplate("fi-gr", company.address_country_full),
                    {
                        view:"spacer",
                        height:6,
                        borderless:true
                    },
                    {
                        view:"template",
                        id:`${this.idPrefix}CompanyPhone`,
                        borderless:true,
                        height:25,
                        template:""
                    },
                    {
                        view:"template",
                        id:`${this.idPrefix}CompanyEmail`,
                        borderless:true,
                        height:25,
                        template:""
                    },
                    {
                        view:"spacer",
                        height:20,
                        borderless:true
                    },
                ]
        } as webix.ui.layoutConfig;
        return userBasicInfoLayout
    }

    getIconMultipleLinesTemplate(icon:string, lines:string[], containerHeight = 120, lineHeight = 25):webix.ui.layoutConfig {
        const rows = [];
        lines.map(line => {
            if(line && line.length > 0 && line.indexOf("undefined") === -1) {
                rows.push({
                    template:line,
                    height:lineHeight,
                    borderless:true,
                    onClick:{
                        "create-collmex":() => {
                            this.createCollmexId();
                        }
                    }
                });
            }
        });
        const layout = {
            view:"layout",
            height:containerHeight,
            paddingY:10,
            borderless:true,
            cols:[
                {
                    view:"spacer",
                    width:13
                },
                {
                    template:`<i class="cog-icon-dark noclick far ${icon}"/>`,
                    borderless:true,
                    width:40,
                },
                {
                    view:"layout",
                    rows:rows
                },
                {
                    view:"spacer",
                    width:13
                },
            ]
        };
        return layout
    }

    getIconLabelTemplate(icon:string, label:string, label2:string,  height:number, link?:string):webix.ui.layoutConfig {
        let hidden = true;
        if(label) {
            hidden = false;
        }
        switch(link) {
            case "email":
                label2 = `<a class='text-link' href='mailto:${label2}'>${label2}</a>`;
                break;
            case "phone":
                label2 = `<a class='text-link' href='mailto:${label2}'>${label2}</a>`;
                break;
            default:
                label = label;
        }
        const layout = {
            view:"layout",
            height:height,
            hidden:hidden,
            paddingY:10,
            borderless:true,
            cols:[
                {
                    view:"spacer",
                    width:13
                },
                {
                    template:`<i class="cog-icon-dark noclick ${icon}"/>`,
                    borderless:true,
                    width:40
                },
                {
                    view:"layout",
                    rows:[
                        {
                            template:label,
                            height:40,
                            borderless:true
                        },
                        {
                            template:label2,
                            height:40,
                            borderless:true
                        },
                    ]
                },
                {
                    view:"spacer",
                    width:13
                },
            ]
        };
        return layout
    }

    displayCollmex(company:ICompany):string {
        if(company["collmex_id"]) {
            return `<span class='info-label'>${i18nHelper.getTranslation("Collmex ID")}</span><br/><span class='info-value'>${company["collmex_id"]}</span>`;
        } else {
            return `<span class='create-collmex text-link'>${i18nHelper.getTranslation("Create Collmex Id")}</span>`;
        }
    }

    createCollmexId():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        IntranetCollmexRestService.postImportCompanyToCollmex({companyId:this.companyId})
        .then(res => {
            window.location.reload();
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        })
        .finally(() => {
            this.cognovisPleaseWaitWindow.hide();
        });
    }

    openCompanyEditModal():void {
        if(!this.companyEditModal) {
            this.companyEditModal = this.ui(CompanyCreationModal) as CompanyCreationModal
        }
        this.companyEditModal.openModal(`${i18nHelper.getTranslation("Edit")} ${this.companyData.company?.name}`, 1024,800, [], this.companyData?.company_type.id, () => {
            //window.location.reload();
        }, this.companyData);
    }

    attemptToUploadLogo():void {
        const companyLogoUploader = (this.$$(`CompanyLogoUploader`) as webix.ui.uploader);
        companyLogoUploader.fileDialog();
    }

    getLogoUploader():webix.ui.uploaderConfig {
        const uploader = {
            view: "uploader",
            localId: `CompanyLogoUploader`,
            name: "files",
            autosend: false,
            width:1,
            upload: this.webixHelpers.getUrlWithApiKey("upload_cr_file"),
            value:i18nHelper.getTranslation("Add file"),
            formData: null,
            datatype: "json",
            on: {
                onAfterFileAdd:() => {
                    // First we check for existing avatar->user relationship
                    CognovisRestRelationshipService.getRelationships({
                        objectIdOne:this.companyId,
                        relType:"im_company_logo_rel"
                    })
                    .then(portraitsRels => {
                        const fileUploader = (this.$$(`CompanyLogoUploader`)) as webix.ui.uploader;
                        if(portraitsRels[portraitsRels.length - 1]) {
                            fileUploader.define("formData",{
                                file_item_id:portraitsRels[portraitsRels.length -1].object_two.id,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(fileRes => {
                                //
                            });

                        } else {
                            fileUploader.define("formData",{
                                context_id:this.companyId,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(filesRes => {
                                CognovisRestRelationshipService.postRelationship(
                                    {
                                        requestBody:{
                                            object_id_one:this.companyId,
                                            object_id_two:filesRes.file_id,
                                            rel_type:"im_company_logo_rel"
                                        }
                                    }
                                )
                            });
                        }
                        window.location.reload();
                        //CognovisNavigator.navigateTo(`/main/companies-overview.company-details?company_id=${this.companyId}`);
                    });
                }
            }
        }
        return uploader
    }

}