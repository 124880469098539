import CognovisBasicModal from "../modules/cognovis-basic-modal/cognovis-basic-modal";
import { UserStateManager } from "../../sources/services/user-state-manager";
import { CognovisRestService, CognovisRestSystemService, ITranslation } from "../../sources/openapi";


export default class i18TranslationModal extends CognovisBasicModal {

    idPrefix = "tm";
    translationKey:string;
    color:string;
    fullKeyMode:boolean = false;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 600,220);
        return mainLayout;
    }

    openModal(translationKey:string, color:string, fullKeyMode?:boolean):void {
        this.translationKey = translationKey;
        this.color = color;
        const modal = (this.getRoot() as webix.ui.window);
        this.fullKeyMode = fullKeyMode;
        let packageKey = "webix-portal";
        if(this.fullKeyMode) {
            packageKey = translationKey.split(".")[0];
            translationKey = translationKey.split(".")[1];
        }
        CognovisRestService.getI18N({
            messageKey:translationKey,
            packageKey:`${packageKey}`
        })
        .then(translations => {
            modal.show(); 
            const modalContent = this.getContent(translations);
            const modalTitle = `Translate: @ ${translationKey} @`;
            const actionButtons = this.getActionButtons();
            this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
        });
    }

    closeModal():void {
        this.hide();
    }

    getContent(translations:ITranslation[]):webix.ui.layoutConfig {
        let value = this.translationKey;
        if(translations.length > 0 && translations?.find(translation => translation.locale === "en_US")) {
            value = translations.find(translation => translation.locale === "en_US").message
        }
        if(translations.length > 0 && translations?.find(translation => translation.locale === UserStateManager.currentlyLoggedUser.userData.locale)) {
            value = translations.find(translation => translation.locale === UserStateManager.currentlyLoggedUser.userData.locale).message
        }
        const css = `cog-translator-box ${this.color}`;
        let defaultLanguage = UserStateManager.currentlyLoggedUser.userData.locale;
        if(this.color === "red") {
            defaultLanguage = "en_US";
        }
        const languageOptions = [
            "en_US"
        ];
        if(this.color !== "red") {
            languageOptions.push("de_DE");
        }
        const layout = {
            view:"scrollview",
            body:{
                padding:30,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}TranslateModalContainer`,
                        rows:[
                            // {
                            //     view:"label",
                            //     labelWidth:120,
                            //     label:`${this.translationKey}`
                            // },
                            {
                                view:"text",
                                localId:`${this.idPrefix}Message`,
                                labelWidth:100,
                                label:"Translation:",
                                css:css,
                                value:value
                            },
                            {
                                view:"combo",
                                localId:`${this.idPrefix}Locale`,
                                label:"Language:",
                                labelWidth:100,
                                value:defaultLanguage,
                                options:languageOptions
                            }
                        ]
                    },
                 
                    
                ]
            }
        };
        return layout
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `Cancel`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        { 
                            view: "button", 
                            value: `Save`,
                            align: "right",
                            click:() => {
                                const message = (this.$$(`${this.idPrefix}Message`) as webix.ui.text).getValue();
                                const locale = (this.$$(`${this.idPrefix}Locale`) as webix.ui.text).getValue();
                                if(message && locale) {
                                    let params = {
                                        message_key:this.translationKey,
                                        message:message,
                                        locale:locale,
                                        package_key:"webix-portal"
                                    };
                                    if(this.fullKeyMode) {
                                        const splitted = this.translationKey.split(".");
                                        const packageKey = splitted[0];
                                        const transKey = splitted[1];
                                        if(splitted.length > 1) {
                                            params = {
                                                message_key:transKey,
                                                message:message,
                                                locale:locale,
                                                package_key:packageKey
                                            }
                                        }
                                    }  
                                    CognovisRestSystemService.postI18N({
                                        requestBody:params
                                    })
                                    .then(() => {
                                        this.closeModal();
                                    })
                                }
                            }
                        },    
                    ] 
                };
        return buttons;
    }

}