/* eslint-disable deprecation/deprecation */
import { JetView } from "webix-jet";

import CognovisAjax from "../../../modules/cognovis-ajax/cognovis-ajax";
import './pm-detail-popup.style.css';
export default class PMDetail extends JetView {
  config(): unknown {
    const columns = [{ column: "first_names" }, { column: "email" }];


    return CognovisAjax.get("user", { columns: columns }).then(res => {
      const data = res.json().data[0];

      const PM_DETAILS = {
        contact_name: data.object_name,
        contact_email: data.email,
        contact_no: "+582449494",
        contact_img: "https://img.icons8.com/plasticine/100/000000/user-male.png",
      };

      return {
        view: "window",
        close: true,
        modal: true,
        position: "top",
        id: "modal-layout",
        width: 320,
        css: "project__contact__modal",
        body: ({
          view: "layout",
          reponsive: true,
          type: "clean",
          css: "contact-details-layout",
          height: 200,
          rows: [
            {
              view: "template",
              template: `<img src=${PM_DETAILS.contact_img}/>`,
              height: 70,
            },

            {
              view: "template",
              css: "contact-details-layout__project__name",
              template: PM_DETAILS.contact_name,
              height: 30,
            },
            {
              view: "template",
              template: `<p class="email">Email: <a href=mailto:'${PM_DETAILS.contact_email}'>${PM_DETAILS.contact_email}</span></p>`,
              height: 30,
            },
            {
              view: "template",
              template: `<p class="phone">Phone: <span>${PM_DETAILS.contact_no}</span></p>`,
              height: 30,
            },
          ],
        } as unknown) as webix.ui.baseview,
      };
    });
  }
}
