import { injectable } from "tsyringe";
import { i18nHelper } from "../../../../../sources/modules/i18n-helper/i18n-helper";
import { IAssignment, ICategoryArray, ICategory } from "../../../../../sources/openapi";


@injectable()
export default class RatingsLayout {

    idPrefix = "dr";

    getRatingSectionsLayout(assignment:IAssignment, qualityTypes:ICategoryArray, qualityLevels:ICategoryArray, qualityTypesHelps?:ICategory[]):webix.ui.layoutConfig {
        const ratingSection = {
            view:"layout",
            rows:[
                {
                    view:"template",
                    borderless:true,
                    height:10,
                    template:() => {
                        return `<span style='font-size:1.2em; display:block; position:absolute; top:10px; font-weight:bold'>${i18nHelper.getTranslation("Rating")}</span>`
                    }
                },
                {
                    view:"layout",
                    rows:[
                        this.getRatingsHeader(qualityLevels),
                        {
                            view:"spacer",
                            height:10
                        },
                        this.getRatingRows(qualityTypes, qualityLevels, qualityTypesHelps)
                    ]
                },
                {
                    view:"spacer",
                    height:20,
                },
                ...this.getCommentSection()
            ]
        };
        return ratingSection;
    }

    getRatingsHeader(qualityLevels:ICategoryArray):webix.ui.layoutConfig {
        const cols = [];
        qualityLevels.map(qualityLevel => {
            cols.push({
                view:"template",
                borderless:true,
                width:50,
                height:40,
                template:`<div style="position:absolute; left:28px;">${qualityLevel.name}</div>`,
                css:{
                    "transform": "rotate(-180deg)",
                    "text-overflow":"visible",
                    "white-space":"nowrap",
                    "writing-mode":"vertical-rl"
                }
            });
        });
        const ratingsHeader = {
            view:"layout",
            cols:[
                {
                    view:"spacer",
                    width:900,
                    height:120
                },
                ...cols
            ]
        };
        return ratingsHeader;
    }


    getRatingRows(qualityTypes:ICategoryArray, qualityLevels:ICategoryArray, qualityTypesHelps?:ICategory[]):webix.ui.layoutConfig {
        const rows = [];
        qualityTypes.map(qualityType => {
            let helptext = "";
            qualityTypesHelps.map(cat => {
                if(cat.category_id === qualityType.id && cat.aux_string1) {
                    helptext = cat.aux_string1;
                }
            });
            const checkboxes = [];
            qualityLevels.map(qualityLevel => {
                checkboxes.push({
                    view:"checkbox",
                    align:"center",
                    qualityType:qualityType,
                    qualityLevel:qualityLevel,
                    helptext:helptext,
                    css:"rating-checkbox",
                    width:50,
                    height:30,
                    id:`${this.idPrefix}Checkbox-${qualityType.id}-${qualityLevel.id}`,
                    on:{
                        onChange:function() {
                            if(this.getValue()) {
                                const currentId = this.config.id;
                                const currentQualityId = this.config.qualityType.id;
                                const parent = this.getParentView()as webix.ui.layout;
                                const checkboxes = parent.getParentView().queryView({view:"checkbox"}, "all");
                                checkboxes.forEach(checkbox => {
                                    if(currentQualityId === checkbox.config.qualityType.id && currentId !== checkbox.config.id) {
                                        checkbox.setValue(false);
                                    } 
                                });
                            }
                        }
                    }
                })
            })
            rows.push({
                view:"layout",
                height:40,
                cols:[
                    {
                        view:"label",
                        tooltip:() => {
                            return ""
                        },
                        label:`<span webix_tooltip='${helptext}' class='webix_icon fa-solid fa-circle-info'></span><span>${qualityType.name}</span>`
                    },
                    ...checkboxes
                ]
            })
        });
        const ratingsRows = {
            view:"layout",
            cols:[
                {
                    view:"layout",
                    id:`${this.idPrefix}PossibleRatingsContainer`,
                    rows:rows
                },
            ]
        };
        return ratingsRows
    }

    getCommentSection():webix.ui.layoutConfig[] {
        const commentsSection = [
            {
            view:"label",
                template:() => {
                    return `<span style='font-size:1.2em; font-weight:bold'>${i18nHelper.getTranslation("Comments")}</span>`
                }
            },
            {
                view:"ckeditor5",
                id:`${this.idPrefix}CommentsCkEditor5`,
                localId:`${this.idPrefix}CommentsCkEditor5`,
                height:200,
                width:500,
                css: {
                    "border-width":"1px 1px !important",
                    "box-sizing":"border-box"
                }
            }
        ]
        return commentsSection
    }

}