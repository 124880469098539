/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean1 } from '../models/Boolean1';
import type { IComponentPlugin } from '../models/IComponentPlugin';
import type { IDeleteDynfieldAttribute } from '../models/IDeleteDynfieldAttribute';
import type { IDeleteDynfieldLayoutPageAttributes } from '../models/IDeleteDynfieldLayoutPageAttributes';
import type { IDeleteDynfieldLayouts } from '../models/IDeleteDynfieldLayouts';
import type { IDeleteDynfieldWidget } from '../models/IDeleteDynfieldWidget';
import type { IDynamicAttribute } from '../models/IDynamicAttribute';
import type { IDynamicObjectBody } from '../models/IDynamicObjectBody';
import type { IDynamicSubtype } from '../models/IDynamicSubtype';
import type { IDynfieldAttr } from '../models/IDynfieldAttr';
import type { IDynfieldAttrBody } from '../models/IDynfieldAttrBody';
import type { IDynfieldLayoutAttribute } from '../models/IDynfieldLayoutAttribute';
import type { IDynfieldLayoutPage } from '../models/IDynfieldLayoutPage';
import type { IDynfieldLayoutPageAttributesBody } from '../models/IDynfieldLayoutPageAttributesBody';
import type { IDynfieldLayoutPageBody } from '../models/IDynfieldLayoutPageBody';
import type { IDynfieldPermission } from '../models/IDynfieldPermission';
import type { IDynfieldTypeMap } from '../models/IDynfieldTypeMap';
import type { IDynfieldTypeMapBody } from '../models/IDynfieldTypeMapBody';
import type { IDynfieldWidget } from '../models/IDynfieldWidget';
import type { IDynviewRow } from '../models/IDynviewRow';
import type { IDynviewTable } from '../models/IDynviewTable';
import type { IError } from '../models/IError';
import type { IIdValue } from '../models/IIdValue';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IPostDynfieldAttribute } from '../models/IPostDynfieldAttribute';
import type { IPostDynfieldLayoutPageAttributes } from '../models/IPostDynfieldLayoutPageAttributes';
import type { IPostDynfieldLayoutPages } from '../models/IPostDynfieldLayoutPages';
import type { IPostDynfieldTypeMap } from '../models/IPostDynfieldTypeMap';
import type { IPostDynfieldWidget } from '../models/IPostDynfieldWidget';
import type { IPostView } from '../models/IPostView';
import type { IPostViewColumn } from '../models/IPostViewColumn';
import type { IPutDynfieldAttribute } from '../models/IPutDynfieldAttribute';
import type { IPutDynfieldLayoutPageAttributes } from '../models/IPutDynfieldLayoutPageAttributes';
import type { IPutDynfieldLayoutPages } from '../models/IPutDynfieldLayoutPages';
import type { IPutDynfieldWidget } from '../models/IPutDynfieldWidget';
import type { IPutView } from '../models/IPutView';
import type { IPutViewColumn } from '../models/IPutViewColumn';
import type { IView } from '../models/IView';
import type { IViewBody } from '../models/IViewBody';
import type { IViewColumn } from '../models/IViewColumn';
import type { IViewColumnBody } from '../models/IViewColumnBody';
import type { IWidgetBody } from '../models/IWidgetBody';
import type { StringDefault } from '../models/StringDefault';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestDynamicService {

    /**
     * Returns an array of portlets to display for an object at a given URL - they are component_plugins in the database, hence the<br>    component_plugin datatype
     * @returns IComponentPlugin Array of portlets to display.
     * @throws ApiError
     */
    public static getComponentPlugins({
        pageUrl,
    }: {
        /**
         * URL in which we want to display the portlets
         */
        pageUrl: string,
    }): CancelablePromise<Array<IComponentPlugin>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/component_plugins',
            query: {
                'page_url': pageUrl,
            },
        });
    }

    /**
     * Returns the widget information for a given object_type.
     * @returns IDynamicAttribute Array of attributes to display / edit
     * @throws ApiError
     */
    public static getDynamicAttributes({
        objectType,
        objectSubtypeId,
        objectId,
        pageUrl,
        parameterObjectJson,
    }: {
        /**
         * Object Type we look for (e.g. persons)
         */
        objectType: string,
        /**
         * Category ID for the subtype we want to get information for
         */
        objectSubtypeId?: IntegerInt32,
        /**
         * Object we look at. Necessary for persons or anything with multiple subtypes. Only use if you can't pass a single subtype_id
         */
        objectId?: IntegerInt32,
        /**
         * What is the URL where we display the form. should be in the form of /#!/...... - determines sort_order and which attributes to actually display (irrespective of subtype configuration)
         */
        pageUrl?: StringDefault,
        /**
         * JSON object with additional key/values that might be needed by the widget to return widget_data
         */
        parameterObjectJson?: string,
    }): CancelablePromise<Array<IDynamicAttribute>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynamic_attributes',
            query: {
                'object_type': objectType,
                'object_subtype_id': objectSubtypeId,
                'object_id': objectId,
                'page_url': pageUrl,
                'parameter_object_json': parameterObjectJson,
            },
        });
    }

    /**
     * Returns an array of key/value pairs for an object
     * @returns IIdValue Array of id+values for the object. ID is the attribute_name and value is the corresponding value.
     * @throws ApiError
     */
    public static getDynamicObject({
        objectId,
        pageUrl,
    }: {
        /**
         * Object we want to retrieve. Will check read permission
         */
        objectId: IntegerInt32,
        /**
         * PageURL to limit the values we want to return for the object
         */
        pageUrl?: StringDefault,
    }): CancelablePromise<Array<IIdValue>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynamic_object',
            query: {
                'object_id': objectId,
                'page_url': pageUrl,
            },
        });
    }

    /**
     * Returns an array of key/value pairs for an object
     * @returns IIdValue Array of id+values for the object. ID is the attribute_name and value is the corresponding value.
     * @throws ApiError
     */
    public static putDynamicObject({
        requestBody,
        pageUrl,
        replaceSkillsP,
    }: {
        /**
         * Object we want to update
         */
        requestBody: IDynamicObjectBody,
        /**
         * PageURL to limit the values we want to return for the object
         */
        pageUrl?: StringDefault,
        /**
         * should we replace skills ?
         */
        replaceSkillsP?: Boolean1,
    }): CancelablePromise<Array<IIdValue>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dynamic_object',
            query: {
                'page_url': pageUrl,
                'replace_skills_p': replaceSkillsP,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Return the dynamic subtypes for an attribute to know how it is configured
     * @returns IDynamicSubtype Array of subtypes and if we show the attribute for them
     * @throws ApiError
     */
    public static getDynamicSubtypes({
        attributeId,
    }: {
        /**
         * dynfield attribute we want to get subtype permission info
         */
        attributeId?: IntegerInt32,
    }): CancelablePromise<Array<IDynamicSubtype>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynamic_subtypes',
            query: {
                'attribute_id': attributeId,
            },
        });
    }

    /**
     * Retrieve attributes for dynamic fields based on either attribute_id or page_url
     * @returns IDynfieldAttr Array containing attributes for dynamic fields
     * @throws ApiError
     */
    public static getDynfieldAttributes({
        attributeId,
        objectType,
    }: {
        /**
         * (optional) Filters results based on attribute_id
         */
        attributeId?: IntegerInt32,
        /**
         * (optional) type of the object
         */
        objectType?: string,
    }): CancelablePromise<Array<IDynfieldAttr>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynfield_attributes',
            query: {
                'attribute_id': attributeId,
                'object_type': objectType,
            },
        });
    }

    /**
     * Retrieve layout attributes based on the page_url and object_type.
     * @returns IDynfieldLayoutAttribute The array containing layout attributes for dynamic fields
     * @throws ApiError
     */
    public static getDynfieldLayoutPageAttributes({
        attributeId,
        pageUrl,
        objectType,
    }: {
        /**
         * id of the attribute. It's optional.
         */
        attributeId?: IntegerInt32,
        /**
         * (optional) Filters results based on page_url. Defaults to 'default'.
         */
        pageUrl?: StringDefault,
        /**
         * (optional) Filters results based on object_type. Defaults to 'person'.
         */
        objectType?: string,
    }): CancelablePromise<Array<IDynfieldLayoutAttribute>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynfield_layout_page_attributes',
            query: {
                'attribute_id': attributeId,
                'page_url': pageUrl,
                'object_type': objectType,
            },
        });
    }

    /**
     * Create a new dynamic field layout page attribute entry in the database based on the provided parameters.
     * @returns IPostDynfieldLayoutPageAttributes of the layout page attribute just added to the database
     * @throws ApiError
     */
    public static postDynfieldLayoutPageAttributes({
        requestBody,
    }: {
        /**
         * The layout page attribute information we want to update
         */
        requestBody: IDynfieldLayoutPageAttributesBody,
    }): CancelablePromise<IPostDynfieldLayoutPageAttributes> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dynfield_layout_page_attributes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Purge a single attribute associated with a layout page from the database based on its page_url and attribute_id
     * @returns IDeleteDynfieldLayoutPageAttributes of errors found
     * @throws ApiError
     */
    public static deleteDynfieldLayoutPageAttributes({
        pageUrl,
        attributeId,
    }: {
        /**
         * Page URL of the layout the attribute is associated with
         */
        pageUrl: string,
        /**
         * Attribute ID we want to purge from the database
         */
        attributeId: IntegerInt32,
    }): CancelablePromise<Array<IDeleteDynfieldLayoutPageAttributes>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/dynfield_layout_page_attributes',
            query: {
                'page_url': pageUrl,
                'attribute_id': attributeId,
            },
        });
    }

    /**
     * Update the dynamic field layout page attribute in the database based on the provided parameters.
     * @returns IPutDynfieldLayoutPageAttributes of the layout page attribute just updated in the database
     * @throws ApiError
     */
    public static putDynfieldLayoutPageAttributes({
        requestBody,
    }: {
        /**
         * The layout page attribute information we want to update
         */
        requestBody: IDynfieldLayoutPageAttributesBody,
    }): CancelablePromise<IPutDynfieldLayoutPageAttributes> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dynfield_layout_page_attributes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve layouts for dynamic fields based on either attribute_id or page_url
     * @returns IDynfieldLayoutPage Array containing layouts for dynamic fields
     * @throws ApiError
     */
    public static getDynfieldLayoutPages({
        objectType,
        pageUrl,
    }: {
        /**
         * type of object to which that layout page is associated with
         */
        objectType?: string,
        /**
         * page_url of the page
         */
        pageUrl?: string,
    }): CancelablePromise<Array<IDynfieldLayoutPage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynfield_layout_pages',
            query: {
                'object_type': objectType,
                'page_url': pageUrl,
            },
        });
    }

    /**
     * Create a new dynamic field layout page entry in the database based on the provided parameters.
     * @returns IPostDynfieldLayoutPages of the layout page just added to the database
     * @throws ApiError
     */
    public static postDynfieldLayoutPages({
        requestBody,
    }: {
        /**
         * The layout page information we want to provide
         */
        requestBody: IDynfieldLayoutPageBody,
    }): CancelablePromise<IPostDynfieldLayoutPages> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dynfield_layout_pages',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update a dynamic field layout page entry in the database based on the provided parameters.
     * @returns IPutDynfieldLayoutPages of the updated layout page
     * @throws ApiError
     */
    public static putDynfieldLayoutPages({
        requestBody,
    }: {
        /**
         * The layout page information we want to provide
         */
        requestBody: IDynfieldLayoutPageBody,
    }): CancelablePromise<IPutDynfieldLayoutPages> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dynfield_layout_pages',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDynfieldPermission Permission details for dynamic fields
     * @throws ApiError
     */
    public static getDynfieldPermissions({
        objectType,
        attributeId,
    }: {
        /**
         * Optional object type filter
         */
        objectType?: string,
        /**
         * Optional attribute id filter
         */
        attributeId?: IntegerInt32,
    }): CancelablePromise<Array<IDynfieldPermission>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynfield_permissions',
            query: {
                'object_type': objectType,
                'attribute_id': attributeId,
            },
        });
    }

    /**
     * @returns IDynfieldTypeMap Permission details for dynamic fields
     * @throws ApiError
     */
    public static getDynfieldTypesMap({
        objectType,
        attributeId,
    }: {
        /**
         * Optional object type filter
         */
        objectType: string,
        /**
         * Optional attribute id filter
         */
        attributeId?: IntegerInt32,
    }): CancelablePromise<Array<IDynfieldTypeMap>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynfield_types_map',
            query: {
                'object_type': objectType,
                'attribute_id': attributeId,
            },
        });
    }

    /**
     * @returns IDynfieldWidget Array of dynamic field widgets
     * @throws ApiError
     */
    public static getDynfieldWidgets({
        widgetId,
        widgetName,
    }: {
        /**
         * widget_id in case we want to query by id
         */
        widgetId?: IntegerInt32,
        /**
         * widget_name in case we want to query by name
         */
        widgetName?: string,
    }): CancelablePromise<Array<IDynfieldWidget>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynfield_widgets',
            query: {
                'widget_id': widgetId,
                'widget_name': widgetName,
            },
        });
    }

    /**
     * Returns configuration for DynView
     * @returns IDynviewTable array of dynviews (it's almost always single object)
     * @throws ApiError
     */
    public static getDynviewColumns({
        viewId,
    }: {
        /**
         * id of dynview which we want to build
         */
        viewId: IntegerInt32,
    }): CancelablePromise<Array<IDynviewTable>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynview_columns',
            query: {
                'view_id': viewId,
            },
        });
    }

    /**
     * Returns configuration for DynView
     * @returns IDynviewRow Array of dynview rows together with fields
     * @throws ApiError
     */
    public static getDynviewRows({
        viewId,
        parameterObjectJson,
    }: {
        /**
         * id of dynview which we want to build
         */
        viewId: IntegerInt32,
        /**
         * JSON object with additional key/values that might be needed by the widget to return widget_data
         */
        parameterObjectJson?: string,
    }): CancelablePromise<Array<IDynviewRow>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dynview_rows',
            query: {
                'view_id': viewId,
                'parameter_object_json': parameterObjectJson,
            },
        });
    }

    /**
     * Returns configuration for DynView columns
     * @returns IViewColumn array of dynview columns (im_view_columns)
     * @throws ApiError
     */
    public static getViewColumns({
        viewId,
    }: {
        /**
         * id of dynview for which we want to get the columns.
         */
        viewId: IntegerInt32,
    }): CancelablePromise<Array<IViewColumn>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/view_columns',
            query: {
                'view_id': viewId,
            },
        });
    }

    /**
     * Returns configuration for DynView
     * @returns IView array of dynviews (im_views)
     * @throws ApiError
     */
    public static getViews({
        viewIds,
    }: {
        /**
         * ids of dynviews which we want to get. We get all in case its empty.
         */
        viewIds?: string,
    }): CancelablePromise<Array<IView>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/views',
            query: {
                'view_ids': viewIds,
            },
        });
    }

    /**
     * @returns IPostDynfieldAttribute of the attribute added
     * @throws ApiError
     */
    public static postDynfieldAttribute({
        requestBody,
    }: {
        /**
         * The attribute information we want to update.
         */
        requestBody: IDynfieldAttrBody,
    }): CancelablePromise<IPostDynfieldAttribute> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dynfield_attribute',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Purge an attribute from the system using the defined procedure.
     * @returns IDeleteDynfieldAttribute of errors encountered during deletion.
     * @throws ApiError
     */
    public static deleteDynfieldAttribute({
        attributeId,
    }: {
        /**
         * Attribute we want to purge from the system.
         */
        attributeId: IntegerInt32,
    }): CancelablePromise<Array<IDeleteDynfieldAttribute>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/dynfield_attribute',
            query: {
                'attribute_id': attributeId,
            },
        });
    }

    /**
     * @returns IPutDynfieldAttribute details of the attribute.
     * @throws ApiError
     */
    public static putDynfieldAttribute({
        attributeId,
        requestBody,
    }: {
        /**
         * ID of the attribute.
         */
        attributeId: IntegerInt32,
        /**
         * The attribute information we want to update.
         */
        requestBody: IDynfieldAttrBody,
    }): CancelablePromise<IPutDynfieldAttribute> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dynfield_attribute',
            query: {
                'attribute_id': attributeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Operate on the dynfield_type_attribute_map based on provided parameters.
     * @returns IPostDynfieldTypeMap details of the attribute type map
     * @throws ApiError
     */
    public static postDynfieldTypeMap({
        requestBody,
    }: {
        /**
         * post data we want to insert/edit
         */
        requestBody: IDynfieldTypeMapBody,
    }): CancelablePromise<IPostDynfieldTypeMap> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dynfield_type_map',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IPostDynfieldWidget of the widget added
     * @throws ApiError
     */
    public static postDynfieldWidget({
        requestBody,
    }: {
        /**
         * The widget information we want to provide
         */
        requestBody: IWidgetBody,
    }): CancelablePromise<IPostDynfieldWidget> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dynfield_widget',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Purge a single widget from the database
     * @returns IDeleteDynfieldWidget of errors found
     * @throws ApiError
     */
    public static deleteDynfieldWidget({
        widgetId,
    }: {
        /**
         * Widget we want to purge from the database
         */
        widgetId: IntegerInt32,
    }): CancelablePromise<Array<IDeleteDynfieldWidget>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/dynfield_widget',
            query: {
                'widget_id': widgetId,
            },
        });
    }

    /**
     * @returns IPutDynfieldWidget details updated
     * @throws ApiError
     */
    public static putDynfieldWidget({
        widgetId,
        requestBody,
    }: {
        /**
         * id of the widget
         */
        widgetId: IntegerInt32,
        /**
         * The widget information we want to provide
         */
        requestBody: IWidgetBody,
    }): CancelablePromise<IPutDynfieldWidget> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/dynfield_widget',
            query: {
                'widget_id': widgetId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IPostView for the object as an array with user information
     * @throws ApiError
     */
    public static postView({
        requestBody,
    }: {
        /**
         * The view information we want to provide
         */
        requestBody: IViewBody,
    }): CancelablePromise<IPostView> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/view',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Purge a view and all its related columns from the database
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteView({
        viewId,
    }: {
        /**
         * View we want to purge from the database
         */
        viewId: IntegerInt32,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/view',
            query: {
                'view_id': viewId,
            },
        });
    }

    /**
     * @returns IPutView for the object as an array with user information
     * @throws ApiError
     */
    public static putView({
        viewId,
        requestBody,
    }: {
        /**
         * id of the view
         */
        viewId: IntegerInt32,
        /**
         * The view information we want to provide
         */
        requestBody: IViewBody,
    }): CancelablePromise<IPutView> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/view',
            query: {
                'view_id': viewId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IPostViewColumn of the column added
     * @throws ApiError
     */
    public static postViewColumn({
        requestBody,
    }: {
        /**
         * The column information we want to provide
         */
        requestBody: IViewColumnBody,
    }): CancelablePromise<IPostViewColumn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/view_column',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Purge a single column from the database
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteViewColumn({
        columnId,
    }: {
        /**
         * Column we want to purge from the database
         */
        columnId: IntegerInt32,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/view_column',
            query: {
                'column_id': columnId,
            },
        });
    }

    /**
     * @returns IPutViewColumn for the object as an array with user information
     * @throws ApiError
     */
    public static putViewColumn({
        columnId,
        requestBody,
    }: {
        /**
         * id of the column
         */
        columnId: IntegerInt32,
        /**
         * The column information we want to provide
         */
        requestBody: IViewColumnBody,
    }): CancelablePromise<IPutViewColumn> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/view_column',
            query: {
                'column_id': columnId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Purge a single layout and associated attributes from the database based on its page_url
     * @returns IDeleteDynfieldLayouts of errors found
     * @throws ApiError
     */
    public static deleteDynfieldLayouts({
        pageUrl,
    }: {
        /**
         * Page URL of the layout we want to purge from the database
         */
        pageUrl: string,
    }): CancelablePromise<Array<IDeleteDynfieldLayouts>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/dynfield_layouts',
            query: {
                'page_url': pageUrl,
            },
        });
    }

}
