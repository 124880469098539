import { CognovisRestFileService, ICrFile, ICrFolder } from "../../../sources/openapi";
import CognovisBasicModal from "../cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../i18n-helper/i18n-helper";
import { FileHelper } from "./file-helpers";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import ObjectNotesModal from "../../views/modals/object-notes-modal";
import { config } from "../../../sources/config";
import { downloadZip } from "client-zip"
import { WebixAlertConfig } from "@xbs/webix-pro";

export default class CognovisFileUploaderModal extends CognovisBasicModal {

    idPrefix = "cfu";
    files:ICrFile[];
    originalFilesIds:number[] = [];
    projectNr:string;
    mode = "read";
    entityId:number;
    entityFolderId:number;
    webixHelpers:WebixHelpers;
    fileNotesModal:ObjectNotesModal;
    customCloseModalAction:(files) => void;
    predefinedFileNote:string;
    folderSelectorDisabled = true;
    defaultFolderName:string;
    potentialFolders:ICrFolder[] = [];
    uploadSuccessMessage:string;

    config():webix.ui.windowConfig {
        this.webixHelpers = container.resolve(WebixHelpers);
        this.uploadSuccessMessage = i18nHelper.getTranslation("File_was_successfully_uploaded");
        if(!this.fileNotesModal) {
            this.fileNotesModal = this.ui(ObjectNotesModal) as ObjectNotesModal;
        }
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Files`), 600, 470);
        return mainLayout;
    }

    openModal(entityId:number, mode = "read", folderConfig:{allowFolderSelection:boolean, useEntityId?:boolean, entityFolderId?:number, disableFoldersSelection?:boolean, defaultFolderName?:string, uploadSuccessMessage?:string}, modalTitle?:string,  predefinedFileNote?:string, customCloseModalAction?:(files) => void):void {
        if(mode) {
            this.mode = mode;
        }
        if(predefinedFileNote) {
            this.predefinedFileNote = predefinedFileNote;
        }
        if(folderConfig.defaultFolderName) {
            this.defaultFolderName = folderConfig.defaultFolderName;
        } else {
            this.defaultFolderName = "";
        }
        if(customCloseModalAction) {
            this.customCloseModalAction = customCloseModalAction;
        }
        if(folderConfig.uploadSuccessMessage) {
            this.uploadSuccessMessage = folderConfig.uploadSuccessMessage;
        }
        this.entityId = entityId;
        if(folderConfig.entityFolderId) {
            this.entityFolderId = folderConfig.entityFolderId;
        } else {
            this.entityFolderId = 0;
        }
        if(modalTitle) {
            const filesTranslation = i18nHelper.getTranslation("Files");
            this.projectNr = modalTitle;
            modalTitle = `${modalTitle} ${filesTranslation}`;
        }
        this.folderSelectorDisabled = !folderConfig.allowFolderSelection;
        const actionButtons = [];
        const modal = (this.getRoot() as webix.ui.window);
        const params:{
            projectId?:number,
            folderId?:number
        } = {};
        if(folderConfig.useEntityId) {
            params.projectId = entityId;
        } else {
            params.folderId = this.entityFolderId
        }
        if(this.entityFolderId) {
            // Call to cr_folders
            CognovisRestFileService.getCrFolders(params)
            .then(folders => {
                if(folders.length > 0) {
                    this.potentialFolders = folders;
                }
                modal.show();
                const modalContent = this.getContent();
                this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
                const fileUploader = this.$$(`${this.idPrefix}FileUploader`) as webix.ui.uploader;
                if(fileUploader) {
                    fileUploader.define("formData",{
                        context_id:entityId
                    });
                }
            });
        } else {
            modal.show();
            const modalContent = this.getContent();
            this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
            const fileUploader = this.$$(`${this.idPrefix}FileUploader`) as webix.ui.uploader;
            if(fileUploader) {
                fileUploader.define("formData",{
                    context_id:entityId
                });
            }
        }
    }


    closeModal():void {
        if(this.customCloseModalAction) {
            this.customCloseModalAction(this.files);
            this.close();
        } else {
            this.close();
        }
    }

    getFiles(entityId:number):Promise<ICrFile[]> {
        return CognovisRestFileService.getCrFile({
            parentId:entityId
        })
        .then(files => {
            return files;
        })
    }

    getJustUploadedFiles(files:ICrFile[]):ICrFile[] {
        if(this.originalFilesIds.length === 0) {
            return files
        } else {
            const newFiles = [];
            files.map(file => {
                if(this.originalFilesIds.indexOf(file.file_id) === -1) {
                    newFiles.push(file);
                }
            });
            return newFiles
        }
    }
    
    setFiles(files:ICrFile[]):void {
        this.originalFilesIds = files.map(file => file.file_id);
        const filesTable = this.$$(`${this.idPrefix}FilesTable`) as webix.ui.datatable;
        this.files = files;
        filesTable.clearAll();
        filesTable.define("data", files);
        filesTable.refresh();
    }

    getContent():webix.ui.layoutConfig {
        const rows = this.getRows(this.potentialFolders, this.defaultFolderName);
        const layout = {
            localId: `${this.idPrefix}CognovisFileUploader`,
            view:"layout",
            gravity:1,
            width:600,
            padding:20,
            rows:rows
        }
        return layout;
    }

    getRows(folders:ICrFolder[], defaultFolderName?:string):webix.ui.layoutConfig[] {
        const rows = [
            {
                view:"layout",
                cols:[
                    this.getFolderSelector(this.potentialFolders, defaultFolderName),
                    {
                        view:"spacer",
                    },
                    {
                        view:"layout",
                        width:150,
                        rows:[
                            {
                                view:"button",
                                label: "<span class='text'>" + i18nHelper.getTranslation("Download all") + '</span>',
                                align:"right",
                                css: "cog-button-big",
                                click:async () => {
                                    const filesDatatable = this.$$(`${this.idPrefix}FilesTable`) as webix.ui.datatable;
                                    const files = filesDatatable.serialize();
                                    const filesToDownload = [];
                                    for(let i = 0; i<files.length; i++) {
                                        filesToDownload[i] = {name:files[i].name, lastModified: new Date(), input:await fetch(FileHelper.downloadCrFileUrl(files[i].file_id))};
                                    }
                                    downloadZip(filesToDownload).blob()
                                    .then(res => {
                                        const link = document.createElement("a");
                                        link.href = URL.createObjectURL(res);
                                        const folderSelector = (this.$$(`${this.idPrefix}FolderSelector`) as webix.ui.combo);
                                        const folderName = folderSelector.getText();
                                        let filename = `${this.projectNr}.zip`;
                                        if(folderName) {
                                            filename = `${this.projectNr}_${folderName}.zip`;
                                        }
                                        link.download = filename;
                                        link.click();
                                        link.remove();
                                    });
                                }
                            }
                        ]
                    },
                ]
            },
            {
                view:"layout",
                cols:[
                    {
                        view:"spacer",
                    },
                    this.getUploader()
                ]
            },
            this.getUploadedFilesTable()      
        ] as webix.ui.layoutConfig[]
        return rows 
    }

    getFolderSelector(folders:ICrFolder[], defaultFolderName:string):webix.ui.layoutConfig {
        const defaultFolderId = folders.find(folder => folder.folder.name === defaultFolderName)?.folder.id;
        const folderSelector = {
            view:"layout",
            localId: `${this.idPrefix}FolderSelectorContainer`,
            margin:13,
            rows:[ 
                {
                    view:"combo",
                    localId: `${this.idPrefix}FolderSelector`,
                    placeholder:i18nHelper.getTranslation("Select_folder"),
                    //value:defaultFolderId,
                    options:folders.map(crF => ({id:crF.folder.id, value:crF.folder.name})),
                    on:{
                        onAfterRender:() => {
                            const folderCombo = this.$$(`${this.idPrefix}FolderSelector`) as webix.ui.combo;
                            if(defaultFolderId) {
                                folderCombo.setValue(defaultFolderId.toString());
                            } else {
                                folderCombo.setValue("");
                            }
                            if(this.folderSelectorDisabled) {
                                folderCombo.hide();
                            } else {
                                folderCombo.show();
                            }
                            
                        },
                        onChange:() => {
                            const folderCombo = this.$$(`${this.idPrefix}FolderSelector`) as webix.ui.combo;
                            const currentFolderId = Number(folderCombo.getValue());
                            this.getFiles(currentFolderId)
                            .then(files => {
                                this.setFiles(files);
                            });
                        }
                    }
                },
            ]

        } 
        return folderSelector
    }

    getUploader():webix.ui.uploaderConfig {
        const uploader = {
            view: "uploader",
            localId: `${this.idPrefix}FileUploader`,
            name: "files",
            autosend: false,
            hidden:this.mode === "read",
            upload: this.webixHelpers.getUrlWithApiKey("upload_cr_file"),
            value:i18nHelper.getTranslation("Add file"),
            formData: null,
            datatype: "json",
            align: "right",
            css: "cog-button-big",
            inputWidth: 150,
            on: {
                onAfterFileAdd:(item) => {
                    if(item.size > config.maxUploadFileSize) {
                        webix.alert({
                            title: i18nHelper.getTranslation("Error"),
                            text: i18nHelper.getTranslation("File_is_too_large"),
                            minWidth:500,
                            type: "alert-error",
                            callback:() => {
                                return
                            }
                        } as WebixAlertConfig);
                    } else {
                        const uploadId = this.getProperId();
                        let predefinedNote = "";
                        if(this.predefinedFileNote) {
                            predefinedNote = this.predefinedFileNote;
                        }
                        this.fileNotesModal.openNotesModal("file-note", i18nHelper.getTranslation("File Note"), {} as unknown, predefinedNote, "cke5", [],
                            (inputedNote) => {
                                const fileUploader = (this.$$(`${this.idPrefix}FileUploader`)) as webix.ui.uploader;
                                fileUploader.define("formData",{
                                    context_id:uploadId,
                                    description:inputedNote
                                });
                                fileUploader.send(() => {
                                    if(this.customCloseModalAction) {
                                        //this.customCloseModalAction(this.files);
                                    }
                                });
                            }
                        );
                    }

                },
                onUploadComplete:(response) => {
                    const uploadId = this.getProperId();
                    this.getFiles(uploadId)
                    .then(files => {
                        const pleaseWaitDialog = webix.$$("cognovis-please-wait-dialog") as webix.ui.window;
                        if(pleaseWaitDialog) {
                            pleaseWaitDialog.close();
                        }
                        webix.alert({
                            type:"info", 
                            text:this.uploadSuccessMessage,
                            callback:() => {
                                if(this.customCloseModalAction) {
                                    this.customCloseModalAction(this.files);
                                }
                            }
                        } as unknown as WebixAlertConfig);
                        const newFiles = this.getJustUploadedFiles(files);
                        this.setFiles(files);
                        const newFilesCount = newFiles.length;
                        for(let i = 0; i<=newFiles.length;i++) {
                            if(newFiles[i] && (i + 1) === newFilesCount) {
                                CognovisRestFileService.postUploadToFolderCompleted({
                                    folderId: newFiles[i].parent.id,
                                    fileItemIds:newFiles.map(newFile => newFile.file_id),
                                    description: newFiles[i].description
                                });
                            }
                        }
                    });
                }
            },
        }
        return uploader
    }

    getProperId():number {
        let uploadId = this.entityId;
        const folderCombo = this.$$(`${this.idPrefix}FolderSelector`) as webix.ui.combo;
        if(folderCombo) {
            const folderId = folderCombo.getValue();
            if(folderId) {
                uploadId = Number(folderCombo.getValue());
            }
        }
        return uploadId;
    }

    getUploadedFilesTable():webix.ui.datatableConfig {
        const datatable = {
            view:"datatable",
            localId: `${this.idPrefix}FilesTable`,
            //autoheight:true,   
            height:300,         
            padding:10,
            tooltip:true,
            scroll:"y",
            //adjust:true,
            type: 'wide',
            columns:[
                {
                    id:"filename",
                    header:i18nHelper.getTranslation(`Filename`),
                    map:"#name#",
                    fillspace:true
                },
                {
                    id:"publish_date",
                    header:i18nHelper.getTranslation(`Publish_Date`),
                    map:"#publish_date#",
                    template:(obj) => {
                        const properFormat = webix.Date.dateToStr("%d.%m.%Y",false)
                        return properFormat(new Date(obj?.publish_date));
                    }
                },
/*                {
                    id:"size",
                    header:i18nHelper.getTranslation(`Size`),
                    width:140,
                    template:function(obj) {
                        const sizeInKbs = obj.content_size / 1000;
                        return `${sizeInKbs} KBs`
                    }
                },*/
                {
                    width:40,
                    tooltip:(file) => {
                        let fileDescription = "";
                        if(file?.description) {
                            fileDescription = file.description;
                        }
                        return fileDescription
                    },
                    template:(obj) => {
                        let fileDescription = "";
                        let cssClass = "opacity-0-2 ";
                        if(obj?.description) {
                            fileDescription = obj.description;
                            cssClass = "";
                        }
                        return `<span class='webix_icon cursor-pointer fas fa-comment-dots ${cssClass}'></span>`;
                    }
                },
                {
                    width:40,
                    tooltip:false,
                    template:() => {
                        return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-file-download'></span>`;
                    }
                },
                {
                    width:40,
                    tooltip:false,
                    hidden:(this.mode === "read" ? true : false),
                    template:() => {
                        if(this.mode === "write") {
                            return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-trash'></span>`;
                        } else {
                            return `` ;
                        }
                    },
                },
            ],
            data:this.getFiles(this.entityId),
            onClick:{
                "fa-file-download":function(e,row) {
                    const item = this.getItem(row);
                    const fileId = item.file_id;
                    FileHelper.downloadCrFile(fileId);
                },
                "fa-comment-dots":(e,row) => {
                    const table = this.$$(`${this.idPrefix}FilesTable`) as webix.ui.datatable;
                    const item = table.getItem(row);
                    const fileId = item.file_id;
                    const fileDescription = item.description;
                    this.fileNotesModal.openNotesModal("file-note", i18nHelper.getTranslation("File Note"), {} as unknown, fileDescription, "cke5", [],
                        (inputedNote) => {
                            CognovisRestFileService.putCrFile({
                                fileId:fileId,
                                description:inputedNote
                            })
                            .then(() => {
                                const pleaseWaitDialog = webix.$$("cognovis-please-wait-dialog") as webix.ui.window;
                                if(pleaseWaitDialog) {
                                    pleaseWaitDialog.close();
                                }
                                setTimeout(() => {
                                    const currentFolderId = this.getProperId();
                                    this.getFiles(currentFolderId)
                                    .then(files => {
                                        this.setFiles(files);
                                    });
                                },500);
                            });
                        }
                    );
                },
                "fa-trash":(e,row) => {
                    const table = this.$$(`${this.idPrefix}FilesTable`) as webix.ui.datatable;
                    const item = table.getItem(row);
                    const fileId = item.file_id;
                    const confirmWarning = `${item.name} ${i18nHelper.getTranslation("lt_will_be_removed_perma")}`
                    webix.confirm({
                        title: i18nHelper.getTranslation(`any_are_you_sure_message`),
                        type: "confirm-warning",
                        text: confirmWarning,
                        width: 500,
                        css:"cog-remove-tasks-modal"
                    })
                    .then(() => {
                        CognovisRestFileService.deleteCrFile({
                            fileId:fileId
                        })
                        .then(() => {
                            const uploadId = this.getProperId();
                            this.getFiles(uploadId)
                            .then(files => {
                                this.setFiles(files);
                                if(this.customCloseModalAction) {
                                    this.customCloseModalAction(this.files);
                                }
                            });
                        });
                    })
                }
            },
            on:{
                onBeforeLoad:function(){
                    if(this.folderSelectorDisabled) {
                        this.showOverlay(i18nHelper.getTranslation("Loading")); 
                    } else {
                        this.showOverlay(i18nHelper.getTranslation("Please_select_folder"));
                    }
                },
                onAfterLoad:function() {
                    const countResults = (this as webix.ui.datatable).count();
                    if(countResults > 0) {
                        this.hideOverlay();
                    } else {
                        this.showOverlay(i18nHelper.getTranslation("No_files_found")); 
                    }
                    this.$scope.originalFilesIds = this.data.serialize().map(file => file.file_id);
                }
            }
        }
        return datatable as unknown as webix.ui.datatableConfig
    }

}