/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetMaterialType {
    /**
     * \r\n      \r\n
     */
    N00OTHER = 9000,
    /**
     * \r\n
     */
    LEKTORAT = 9002,
    TEXTARBEIT = 9004,
    CONSULTING_ROLLOUT = 9006,
    SOFTWARE_DEVELOPMENT = 9008,
    WEB_SITE_DEVELOPMENT = 9010,
    GENERIC_PM = 9012,
    TRANSLATION = 9014,
    AUTOMATICALLY_CREATED = 9016,
    CUSTOMER_MATERIAL = 9020,
    PROVIDER_MATERIAL = 9022,
}
