import BaseView from "../baseview";
import NewProjectForm from "./new-project-form-fields";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";

export default class NewOfferView extends BaseView {

    config(): webix.ui.scrollviewConfig {
        // const dynfieldsSetup:IDynfieldSetup = {
        //     pages:[
        //         {
        //             objectType:"im_project",
        //             pageUrl:"/#!/new-offer",
        //         }
        //     ],
        //     checkForRequiredFields:true
        // };
        // const newProjectForm = new DynNewOffer(this.app, dynfieldsSetup, []);
        const newProjectForm = new NewProjectForm(this.app);
        return {
            view: "scrollview",
            scroll: "y",
            css:"cog-content",
            
            body: {
                view: "layout",
                padding:12,
                cols: [
                    {
                        view: "layout",
                        rows: [
                            {
                                view: "template",
                                type: "header",
                                template: i18nHelper.getTranslation("new_offer"),
                                css: "cog-box-header",
                                height: 40,
                            },
                            newProjectForm
                        ],
                    },
                ],
            },
            disabled: false,
            borderless: false
        };
    }
}
