
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import { ImTransTaskIds, ITransTask, WebixPortalTranslationService } from "../../openapi/index";
import { container } from "tsyringe";
import TaskErrorModal from './project-task-modals/task-error-popup'
import ReplaceQuoteModal from './project-task-modals/replace-quote-modal'
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import ProjectDetails from "views/project-details";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { FileHelper } from "../../../sources/modules/file-helpers/file-helpers";
import { DatetimeHelper } from "../../../sources/modules/datetime-helpers/datetime-helpers";
import AddTaskToQuote from "views/modals/add-task-to-quote";
import TotalTasksUnitsModal from "./project-task-modals/total-task-units-modal";

export default class ProjectTasks extends ProjectDetails {

    justUploadedFile: string | boolean = false;
    webixHelpers: WebixHelpers;
    projectId: number;
    itemId: string;
    tableName = "tasks-table";
    taskNamesSuggestions = ["one", "two"];
    addTaskToQuoteModal:AddTaskToQuote;
    totalTasksUnitsModal:TotalTasksUnitsModal;
    newTaskDefaults = {
        task: {
            name:"",
            id:-1,
        },
        target_language: {
            id: "all",
            name: "all languages"
        },
        task_type: {
            id: 87,
            name: "Trans Proof"
        },
        task_units: 0,
        billable_units:0,
        task_uom: {
            id: 324,
            name: "S-Word"
        },
        task_billable: true,
        edit: true
    };
    showTaskErrorModal: TaskErrorModal;
    showReplaceQuoteModal: ReplaceQuoteModal;
    isThatNewRow = false;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    uploaderMode:"csv" | "trados";

    init(): void {
        this.showTaskErrorModal = this.ui(TaskErrorModal) as TaskErrorModal;
        this.showReplaceQuoteModal = this.ui(ReplaceQuoteModal) as ReplaceQuoteModal;
        this.on(this.app, "parent_class::project-data-loaded", data => {
            this.injectActionComboOptions();
        });
    }

    urlChange():void {
        const newProjectId = this.getParam("project_id", true);
        if(newProjectId !== this.projectId) {
            this.projectId = newProjectId;
            const table = webix.$$("tasks-table") as webix.ui.datatable;
            if(table) {
                this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                table.clearAll();
                table.refresh();
                WebixPortalTranslationService.getTransTasks({ projectId: this.projectId })
                .then(tasks => {
                    tasks.map(task => {
                        if(task.task_deadline) {
                            task["deadline"] = DatetimeHelper.parseDateForDatepicker(task.task_deadline);
                        } else {
                            task["deadline"] = "";
                        }
                        task["selected"] = false;
                        task["task_billable"] = true;
                        if (task.billable_units == 0) {
                            task["task_billable"] = false;
                        }
                    });
                    table.define("data", tasks);
                    table.refresh();
                    this.cognovisPleaseWaitWindow.hide();
                });
            }
        } 
        super.urlChange();
    }

    config(): webix.ui.scrollviewConfig {
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.projectId = this.getParam("project_id", false);
        const table = {
            view: "datatable",
            select: "row",
            id: 'tasks-table',
            type: "wide",
            tooltip: true,
            padding:13,
            multiselect: true,
            // editaction: "none",
            fixedRowHeight: false,
            borderless: false,
            autoWidth: true,
            columns: [
                {
                    id: "selected",
                    translation_key: "NULL",
                    header: ["#", { content: "masterCheckbox" }],
                    template: "<div cog-checkbox='cog-checkbox'>{common.checkbox()}</div>",
                    fillspace: false,
                    width: 40
                },
                {
                    id: "task_name",
                    view_id: "task_name",
                    header: [i18nHelper.getTranslation("Task_name"), { content: "textFilter" }],
                    sort: "text",
                    map:"#task.name#",
                    fillspace: true,
                    editor: "text",
                    suggest:{
                        localId:"taskNameSuggestBox",
                        url:() => {
                            return this.getTaskNamesSuggestion();
                        }
                    }
                },
                {
                    id: "target_language",
                    view_id: "tlanguage",
                    header: [i18nHelper.getTranslation("Target"), { content: "multiSelectFilter" }],
                    sort: "text",
                    editor: "combo",
                    //suggest:{view:"checksuggest", button:true},
                    options: () => CognovisCategory.getCategory("Intranet Translation Language")
                        .then(res => {
                            // Limit languages to the ones that are within the project
                            const targetLanguagesComma = webix.storage.session.get("current_project_target_languages");
                            //res = res.filter(lang => targetLanguagesComma.indexOf(lang["id"]) > -1)
                            const langs = [{ id: 'all', value: 'all languages' }, ...res];
                            return langs;
                        }),
                    fillspace: true,
                    map: "#target_language.id#"

                },
                {
                    id: "task_type",
                    header: [i18nHelper.getTranslation("Type"), { content: "multiSelectFilter" }],
                    sort: "text",
                    fillspace: true,
                    options: ()  => {
                        return WebixPortalTranslationService.getTransTaskTypes({projectId:this.projectId})
                        .then(taskTypes => {
                            const preparedForCombo = taskTypes.map(type => ({id:type.category_id, value:type.category_translated}));
                            if(preparedForCombo[0]) {
                                this.newTaskDefaults.task_type = {
                                    id:preparedForCombo[0].id,
                                    name:preparedForCombo[0].value
                                }
                            }
                            return preparedForCombo
                        });
                    },
                    editor: "combo",
                    map: "#task_type.id#"
                },
                {
                    id: "task_units",
                    header: [i18nHelper.getTranslation("Units"), { content: "numberFilter" }],
                    sort: "text",
                    fillspace: true,
                    editor: "text",
                },
                {
                    id: "billable_units",
                    header: [i18nHelper.getTranslation("billable_units"), { content: "numberFilter" }],
                    sort: "text",
                    fillspace: true,
                    editor: "text",
                },
                {
                    id: "task_uom",
                    header: [i18nHelper.getTranslation("Uom"), { content: "selectFilter" }],
                    sort: "text",
                    fillspace: true,
                    editor: "combo",
                    options: () => CognovisCategory.getCategory("Intranet UoM"),
                    map: "#task_uom.id#"
                },
                {
                    id: "deadline",
                    view_id: "deadline",
                    header: [i18nHelper.getTranslation("Deadline"), {content:"dateFilter"}],
                    editor: "date",
                    map:"(date)#deadline#",
                    editable: true,
                    suggest: {
                        type: "calendar",
                        body: {
                            timepicker: true,
                            calendarTime: "%H:%i",
                            on: {
                                onBeforeRender: function () {
                                    if (this.config.date.toString() === "Invalid Date") {
                                        this.config.date = new Date();
                                    }
                                }
                            }
                        }
                    },
                    fillspace: true,
                    template: (task) => {
                        if(task.deadline) {
                            const myFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
                            const formattedDate = myFormat(task.deadline);
                            if (formattedDate.indexOf("NaN") > -1 || !task.deadline) {
                                return "";
                            } else {
                                return formattedDate;
                            }
                        } else {
                            return "";
                        }
                    }

                },
                {
                    view: "template",
                    css: "edit",
                    width: 60,
                    align:"right",
                    template: function (obj) {
                        return `<span class='webix_icon wxi-${obj.edit ? "check" : "pencil"}' id='edit-${obj.edit ? "check" : "pencil"}'></span>`;
                    }
                }
            ],

            onClick: {
                "wxi-pencil": (event, row) => {
                    const table = ($$(this.tableName) as webix.ui.datatable);
                    table.define("editable", true);
                    this.editStart(event, row);
                },
                "wxi-check": (event, row) => {
                    const table = ($$(this.tableName) as webix.ui.datatable);
                    table.define("editable", false);
                    this.editEnd(event, row);
                }
            },
            url: () => {
                return WebixPortalTranslationService.getTransTasks({ projectId: this.projectId })
                    .then(tasks => {
                        tasks.map(task => {
                            if(task.task_deadline) {
                                task["deadline"] = DatetimeHelper.parseDateForDatepicker(task.task_deadline);
                            } else {
                                task["deadline"] = "";
                            }
                            task["selected"] = false;
                            task["task_billable"] = true;
                            if (task.billable_units == 0) {
                                task["task_billable"] = false;
                            }
                        });
                        return tasks;
                    });
            },

            rules: {
                "task_name": webix.rules.isNotEmpty,
                "target_language": webix.rules.isNotEmpty,
                "task_type": webix.rules.isNotEmpty,
                "task_units": webix.rules.isNumber,
                "billable_units":webix.rules.isNumber,
                "task_uom": webix.rules.isNotEmpty
            },

        };
        
        const buttonRow = {
            view: "layout",
            paddingY:10,
            css:"cog-content",
            borderless: true,
            cols: [
                {
                    view: "button",
                    value: i18nHelper.getTranslation("Add_single_task"),
                    id: "addSingleTask",
                    css: "cog-button-big",
                    height: 40,
                    width:200,
                    click: () => {
                        this.addNewTask();
                    }
                },
                {
                    view:"spacer",
                    width:10
                },
                {
                    view: "richselect",
                    allowClear:true,
                    id: "action-combo-import",
                    placeholder: i18nHelper.getTranslation("Upload_Import"),
                    heigth: 40,
                    options:[],
                    on: {
                        onChange: (value) => {
                            switch (value) {
                                case "csv":
                                    this.setUploaderMode("csv");
                                    this.openUploader(value);
                                    break;
                                case "trados-new":
                                    this.createTasksFromTrados(false);
                                    break;
                                case "trados-replace":
                                    this.createTasksFromTrados(true);
                                    break;
                                case "download-csv":
                                    this.downloadAsCsv()
                                    break
                                case "trados-upload":
                                    this.setUploaderMode("trados");
                                    this.openUploader(value);
                                    break;
                            }
                            this.clearActionCombo();
                        }
                    }
                },
                {
                    view:"spacer",
                    width:10
                },
                {
                    view: "richselect",
                    allowClear:true,
                    id: "action-combo-quotes",
                    placeholder: i18nHelper.getTranslation("Quote"),
                    options: [
                        { value: i18nHelper.getTranslation("Create_new_quote"), id: "createNewQuote" },
                        { value: i18nHelper.getTranslation("Replace_existing_quote"), id: "replaceExistingQuote" },
                        { value: i18nHelper.getTranslation("Create_new_quote_for_each_language"), id: "createNewQuoteLanguage" },
                        { value:i18nHelper.getTranslation("Add_tasks_to_existing_quote"), id:"addTasksToQuote"}
                    ], on: {
                        onChange: (value) => {
                            switch (value) {
                                case "createNewQuote":
                                    this.createQuote();
                                    break;
                                case "replaceExistingQuote":
                                    this.replaceExistingQuote(this.projectId);
                                    break;
                                case "createNewQuoteLanguage":
                                    this.createQuotePerLanguage();
                                    break;
                                case "addTasksToQuote":
                                    this.addTasksToQuote();
                                    break;
                            }
                            this.clearActionCombo();
                        }
                    },
                },
                {
                    view:"spacer",
                    width:13
                },
                {
                    view:"button",
                    type:"icon",
                    icon:"fas fa-calculator",
                    width:24,
                    tooltip:i18nHelper.getTranslation("Tasks_units_summary"),
                    click:() => {
                        this.openTotalTasksUnitsModal();
                    }
                },
                {
                    view:"spacer",
                    width:13
                },
                {
                    view: "button",
                    type: "icon",
                    width:24,
                    id:"delete-tasks-icon",
                    icon: "fas fa-trash-alt",
                    on: {
                        onItemClick: () => {
                            this.attemptToRemoveTasks();
                        }
                    }
                },
                {
                    view:"spacer",
                    width:0,
                },
            ],
        };
        const CSVUploader = {
            view: "uploader",
            id: "csv-uploader",
            width: 0,
            height: 1,
            upload: this.webixHelpers.getUrlWithApiKey(
                "upload_fs_file"
            ),
            on: {
                onUploadComplete: (
                    file
                ) => {
                    console.log(this.uploaderMode)
                    if(this.uploaderMode === "csv") {
                        this.addTasksFromFiles([file]);
                        this.refreshTable();
                    } 
                    if(this.uploaderMode == "trados") {
                        this.addTasksFromTrados([file]);
                        this.refreshTable();
                    }
                },
            },
        };
        return {
            view: "scrollview",
            scroll: "y",
            css:"cog-content",
            body: {
                view: "layout",
                padding:13,
                rows: [CSVUploader, buttonRow, table],
            },
        };
    }
    
    editEnd(event: Event, row: string): void {
        this.itemId = row["row"];
        const table = (webix.$$(this.tableName) as webix.ui.datatable);
        table.editStop();
        const taskData = table.getItem(this.itemId);
        const newTask = {
            taskId: null,
            //projectId: projectId as number,
            task_name: taskData.task_name,
            task_uom_id: taskData.task_uom,
            task_type_id: taskData.task_type,
            task_units: taskData.task_units,
            billable_units: taskData.billable_units,
            target_language_ids:[]
            
        };
        if(taskData.deadline && DatetimeHelper.isValidDate(taskData.deadline)) {
            newTask["task_deadline"] = this.webixHelpers.formatDate(taskData.deadline);
        } 
        if (taskData.target_language !== "all") {
            newTask["target_language_ids"] = [ taskData.target_language ];
        }
        if (this.isThatNewRow) {
            WebixPortalTranslationService.postTransTasks({ projectId: this.projectId, requestBody: newTask })
            .then(() => {
                //const newTransTasksIds = res.map(transTask => transTask.task.id).join(",");
                this.getTaskNamesSuggestion()
                .then((suggestedNames) => {
                    const taskNameSuggestBox = this.$$("taskNameSuggestBox") as webix.ui.suggest;
                    if(taskNameSuggestBox) {
                        const asList = (taskNameSuggestBox.getList() as webix.ui.list);
                        asList.clearAll();
                        asList.parse(suggestedNames,"json");
                    }
                })
                this.refreshTable();
            });
        } else {
            let deadline = "";
            if(taskData.deadline) {
                deadline = this.webixHelpers.formatDate(taskData.deadline);
            }
            table.editStop();
            const existingTask = {
                taskId: taskData.task.id,
                task_name: taskData.task_name,
                task_uom_id: taskData.task_uom,
                target_language_ids: taskData.target_language,
                task_type_id: taskData.task_type,
                task_units: newTask.task_units,
                billable_units: newTask.billable_units,
                task_deadline:deadline
            };
            WebixPortalTranslationService.putTransTasks({ taskId: existingTask.taskId, requestBody: existingTask })
            .then(() => {
                this.refreshTable();
            })
            .catch(err => {
                webix.alert({
                    title: i18nHelper.getTranslation("Error"),
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal",
                    callback:() => {
                        this.refreshTable();
                    }
                });
                
            }); 
        }
    }

    editStart(event: Event, elementId: string): void {
        const table = ($$(this.tableName) as webix.ui.datatable);

        if (this.itemId && typeof this.itemId !== "undefined") {
            //form.clearValidation();
            if (table.getItem(this.itemId)) {
                table.getItem(this.itemId).edit = false;
                table.refresh(this.itemId);
            }
        }
        this.itemId = elementId["row"];
        this.markAsCurrentlyEdited(this.itemId);
        table.editStop();
        table.editCell(elementId, "task_name");
        this.isThatNewRow = false;
    }

    addNewTask(): void {
        const table = ($$(this.tableName) as webix.ui.datatable);
        const currentProject = super.getProject();
        if(currentProject.project_type) {
            this.newTaskDefaults.task_type = {
                id:currentProject.project_type.id,
                name:currentProject.project_type.name
            }
        }
        const data = this.newTaskDefaults;
        this.clearEditMode();
        const id = table.add(webix.copy(data), 0) as string;
        this.itemId = id;
        //make table it editable
        table.define("editable", true)
        table.editCell(id, "task_name");
        this.isThatNewRow = true;

    }

    markAsCurrentlyEdited(item: string): void {
        const table = ($$(this.tableName) as webix.ui.datatable);
        // clear any current selection
        this.clearEditMode();
        const vals = table.getItem(item);
        vals.edit = true;
    }

    clearEditMode(): void {
        const table = ($$(this.tableName) as webix.ui.datatable);
        table.editStop();
        this.itemId = "";
        if (this.itemId) {
            table.getItem(this.itemId).edit = false;
            table.refresh(this.itemId);
        }
    }

    refreshTable(): void {
        const table = ($$(this.tableName) as webix.ui.datatable);
        table.clearAll();
        WebixPortalTranslationService.getTransTasks({ projectId: this.projectId })
        .then(tasks => {
            tasks.map(task => {
                if(task.task_deadline) {
                    task["deadline"] = DatetimeHelper.parseDateForDatepicker(task.task_deadline);
                } else {
                    task["deadline"] = "";
                }
                task["selected"] = false;
                task["task_billable"] = true;
                if (task.billable_units == 0) {
                    task["task_billable"] = false;
                }
            });
            table.define("data", tasks);
            table.refresh();
        });
    }

    getActionComboOptions(): {value: string, id:string}[] {
        const currentProject = super.getProject();
        let actionComboOptions = [
            {
                value: i18nHelper.getTranslation("Create_tasks_from_CSV"),
                id: 'csv'
            }, 
            {
                value: i18nHelper.getTranslation("Download_as_CSV"),
                id: "download-csv"
            }
        ]
        if (currentProject.trados) {
            actionComboOptions.push({
                value: i18nHelper.getTranslation("Import_and_replace_from_Trados"),
                id: "trados-new"
            })
            actionComboOptions.push({
                value: i18nHelper.getTranslation("Import_only_new_tasks_from_Trados"),
                id: "trados-replace"
            })
            actionComboOptions.push({
                value:i18nHelper.getTranslation("Import tasks from Trados file"),
                id:"trados-upload"
            })
        }
        return actionComboOptions;
    }

    injectActionComboOptions():void {
        const actions = this.getActionComboOptions();
        const actionComboImports = (webix.$$("action-combo-import") as webix.ui.richselect);
        actionComboImports.define("options", actions);
        actionComboImports.refresh();
    }

    getSelectedTasks():ITransTask[] {
        const table = (webix.$$(this.tableName) as webix.ui.datatable);
        const items = table.serialize();
        const selectedItems = items.filter(task => task.selected); 
        if(selectedItems.length > 0) {
            return selectedItems
        }  else {
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text: i18nHelper.getTranslation("Select_at_least_one_task"),
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            }); 
            return []
        }
    }

    attemptToRemoveTasks(): void {
        const selectedItems = this.getSelectedTasks();
        if(selectedItems.length > 0) {
            let confirmWarning = `${i18nHelper.getTranslation(`You_want_to_remove`)}:<br/>`;
            const taskIdsArr = selectedItems.map((task:ITransTask, index: number) => {
                confirmWarning = confirmWarning + `${index + 1}. ${task.task.name}<br/>`;
                return task.task.id;
            });
            webix.confirm({
                title: i18nHelper.getTranslation(`any_are_you_sure_message`),
                type: "confirm-warning",
                text: confirmWarning,
                width: 500,
                css:"cog-remove-tasks-modal"
            })
            .then(() => {
                this.removeTasks(taskIdsArr)
            });
        }
    }

    removeTasks(taskIds: ImTransTaskIds): void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        WebixPortalTranslationService.deleteTransTasks({taskIds:taskIds})
        .then(res => {
            this.cognovisPleaseWaitWindow.hide();
            if (res.length === 0) {
                this.refreshTable();
            } else {
                let problematicTasksHtml = "";
                res.map(err => {
                    problematicTasksHtml = problematicTasksHtml + `<p>${err.parameter}: ${err.err_msg}</p><br/>`
                });
                webix.alert({
                    title: i18nHelper.getTranslation("Some_tasks_could_not_be_removed"),
                    text: problematicTasksHtml,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            }
        });
    }

    createTasksFromTrados(onlyNew: boolean): void {
        WebixPortalTranslationService.postTransTasksFromTrados({
            onlyNew: onlyNew,
            projectId: this.projectId
        })
        .then(() => {
            this.refreshTable();
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    openUploader(fileType: string): void {
        this.justUploadedFile = fileType;
        const csvUploader = ($$("csv-uploader") as webix.ui.uploader);
        csvUploader.fileDialog();
    }

    addTasksFromFiles(files: IUploadedFile[]): void {
        WebixPortalTranslationService.postTransTasksFromFile({
            filename: files[0].filename,
            path: files[0].path,
            projectId: this.projectId
        })
        .then(() => {
            this.refreshTable();
            this.justUploadedFile = false;
        });
    }

    addTasksFromTrados(files: IUploadedFile[]): void {
        WebixPortalTranslationService.postTransTasksFromTradosFile({
            filename: files[0].filename,
            path: files[0].path,
            projectId: this.projectId
        })
        .then(() => {
            this.refreshTable();
            this.justUploadedFile = false;
        });
    }


    clearActionCombo(): void {
        setTimeout(() => {
            const actionComboImports = (webix.$$("action-combo-import") as webix.ui.richselect);
            if(actionComboImports) {
                actionComboImports.setValue(null);
                const actionComboQuotes = (webix.$$("action-combo-quotes") as webix.ui.richselect);
                actionComboQuotes.setValue(null);
            }
        },500);
    }

    downloadAsCsv():void {
        FileHelper.downloadAsCsv(this.projectId);
    }

    createQuote(): void {
        const selectedItems = this.getSelectedTasks();
        if(selectedItems.length > 0) {
            this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Quote_is_being_created")});
            const selectedTaskIds = selectedItems.map(task => {
                return task.task.id
            });
            WebixPortalTranslationService.postTransQuote({
                requestBody: {
                    trans_tasks: selectedTaskIds,
                    quote_per_language_p: false
                }
            })
            .then(res => {
                if (res[0]) {
                    const newInvoiceId = res[0].invoice.id;
                    CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${newInvoiceId}&project_id=${this.projectId}`);
                } else {
                    webix.alert({
                        type:"alert-error",
                        text:res["message"],
                        width:480,
                        css:"cog-import-fp-trados-modal"
                    });
                }
            })
            .catch(err => {
                webix.alert({
                    type:"alert-error",
                    title:i18nHelper.getTranslation("Error"),
                    text:err.body.message,
                    width:480,
                    css:"cog-import-fp-trados-modal"
                });
            })
            .finally(() => {
                this.cognovisPleaseWaitWindow.hide();
            });
        }
    }

    createQuotePerLanguage(): void {
        const selectedItems = this.getSelectedTasks();
        if(selectedItems.length > 0) {
            this.cognovisPleaseWaitWindow.show({message:i18nHelper.getTranslation("Quotes_are_being_created")});
            const selectedTaskIds = selectedItems.map(task => {
                return task.task.id
            });
            WebixPortalTranslationService.postTransQuote({
                requestBody: {
                    trans_tasks: selectedTaskIds,
                    quote_per_language_p: true
                }
            })
            .then((res) => {
                if (res[0]) {
                    const newQuoteId = res[0].invoice.id;
                    CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${newQuoteId}&project_id=${this.projectId}`);
                } else {
                    CognovisNavigator.navigateTo(`/main/project-finances.project-finances`);
                }
            })
            .catch(err => {
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            })
            .finally(() => {
                this.cognovisPleaseWaitWindow.hide();
            });
        }
    }

    replaceExistingQuote(projectId: number): void {
        const selectedItems = this.getSelectedTasks();
        if(selectedItems.length > 0) {
            const selectedTaskIds = selectedItems.map(task => {
                return task.task.id;
            });
            this.showReplaceQuoteModal.showModal(projectId);
            this.showReplaceQuoteModal.selectedTasksIds = selectedTaskIds;
        }
    }

    addTasksToQuote(): void {
        const selectedItems = this.getSelectedTasks();
        if(selectedItems.length > 0) {
            if(!this.addTaskToQuoteModal) {
                this.addTaskToQuoteModal = this.ui(AddTaskToQuote) as AddTaskToQuote;
            }
            this.addTaskToQuoteModal.openModal(this.projectId, selectedItems)
        }
    }

    getTaskNamesSuggestion():Promise<string[]> {
        return WebixPortalTranslationService.getSuggestedTaskNames({
        })
        .then(res => res.map(suggestedTask => suggestedTask.task.name))
    }

    openTotalTasksUnitsModal():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        if(!this.totalTasksUnitsModal) {
            this.totalTasksUnitsModal = this.ui(TotalTasksUnitsModal) as TotalTasksUnitsModal;
        }
        WebixPortalTranslationService.getTransTasks({ projectId: this.projectId })
        .then(tasks => {
            this.cognovisPleaseWaitWindow.hide();
            this.totalTasksUnitsModal.openModal(this.currentProject,tasks);
        });
    }

    setUploaderMode(mode:"csv" | "trados"):void {
        this.uploaderMode = mode;
        // const uploader = webix.$$("csv-uploader") as webix.ui.uploader;
        // if(mode === "csv") {
        //     uploader.config.upload = this.webixHelpers.getUrlWithApiKey(
        //         "upload_fs_file"
        //     );
        // }
        // if(mode === "trados") {
        //     uploader.config.upload = this.webixHelpers.getUrlWithApiKey(
        //         "upload_trados_file"
        //     );
        // }
        // uploader.refresh();
    }

}
