/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetBizObjectRole {
    /**
     * Full Member
     */
    FULL_MEMBER = 1300,
    /**
     * Project Manager
     */
    PROJECT_MANAGER = 1301,
    /**
     * Key Account Manager
     */
    KEY_ACCOUNT = 1302,
    /**
     * Office Manager
     */
    OFFICE_MANAGER = 1303,
    /**
     * Final Customer
     */
    FINAL_CUSTOMER = 1304,
    /**
     * Generic Association
     */
    GENERIC_ASSOCIATION = 1305,
    /**
     * Related Mail
     */
    MAIL_ASSOCIATION = 1306,
    /**
     * Consultant
     */
    CONSULTANT = 1307,
    /**
     * Trainer
     */
    TRAINER = 1308,
    /**
     * Conf Item Manager
     */
    CONF_ITEM_MANAGER = 1309,
    FREELANCER = 1310,
}
