/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Id } from '../models/Id';
import type { IError } from '../models/IError';
import type { ImProfileIds461_463_465_467_471 } from '../models/ImProfileIds461_463_465_467_471';
import type { IProfile } from '../models/IProfile';
import type { IProfilePrivilegeBody } from '../models/IProfilePrivilegeBody';
import type { IUser } from '../models/IUser';
import type { IUserProfile } from '../models/IUserProfile';
import type { IUserProfileBody } from '../models/IUserProfileBody';
import type { IUserProfileBodyPut } from '../models/IUserProfileBodyPut';
import type { IUserWithPrivilege } from '../models/IUserWithPrivilege';
import type { IUserWithRoles } from '../models/IUserWithRoles';
import type { NumberFloat } from '../models/NumberFloat';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestUsersManagerService {

    /**
     * Endpoint returning possible users profiles
     * @returns IProfile All possible profiles with profile privileges
     * @throws ApiError
     */
    public static getProfilePrivileges(): CancelablePromise<Array<IProfile>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/profile_privileges',
        });
    }

    /**
     * @returns IProfile All possible profiles with profile privileges
     * @throws ApiError
     */
    public static postProfilePrivileges({
        requestBody,
    }: {
        /**
         * privlige name and object id
         */
        requestBody: IProfilePrivilegeBody,
    }): CancelablePromise<IProfile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/profile_privileges',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteProfilePrivileges({
        objectId,
        privilegeName,
    }: {
        objectId: Id,
        privilegeName: string,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/profile_privileges',
            query: {
                'object_id': objectId,
                'privilege_name': privilegeName,
            },
        });
    }

    /**
     * Returns an array of members of a specific group
     * @returns IUser User who is a member of the object
     * @throws ApiError
     */
    public static getUserManagerMembers({
        groupIds,
    }: {
        /**
         * IDs of the groups
         */
        groupIds?: ImProfileIds461_463_465_467_471,
    }): CancelablePromise<Array<IUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_manager_members',
            query: {
                'group_ids': groupIds,
            },
        });
    }

    /**
     * Endpoint returning possible users and their privliges (anyting other than 'view', 'read', 'write', 'admin')
     * @returns IUserWithPrivilege users together with priveleges
     * @throws ApiError
     */
    public static getUsersPrivileges(): CancelablePromise<Array<IUserWithPrivilege>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users_privileges',
        });
    }

    /**
     * Endpoint returnig possible user profiles
     * @returns IUserProfile user profile (e.g. Accounting Employee etc)
     * @throws ApiError
     */
    public static getUsersProfiles(): CancelablePromise<Array<IUserProfile>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users_profiles',
        });
    }

    /**
     * Endpoint returning possible users and their roles
     * @returns IUserWithRoles users together with roles
     * @throws ApiError
     */
    public static getUsersRoles(): CancelablePromise<Array<IUserWithRoles>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users_roles',
        });
    }

    /**
     * @returns string Generic description
     * @throws ApiError
     */
    public static postUserProfile({
        requestBody,
    }: {
        /**
         * user and profile ids
         */
        requestBody: IUserProfileBody,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user_profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string Generic description
     * @throws ApiError
     */
    public static deleteUserProfile({
        userId,
        profileId,
    }: {
        /**
         * id of the user
         */
        userId: NumberFloat,
        /**
         * id of the profile
         */
        profileId: NumberFloat,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user_profile',
            query: {
                'user_id': userId,
                'profile_id': profileId,
            },
        });
    }

    /**
     * @returns string Generic description
     * @throws ApiError
     */
    public static putProfile({
        profileId,
        requestBody,
    }: {
        /**
         * id of the modified profile
         */
        profileId: NumberFloat,
        requestBody: IUserProfileBodyPut,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/profile',
            query: {
                'profile_id': profileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
