
import { CognovisNavigator } from "../../sources/modules/cognovis-navigator/cognovis-navigator";
import { i18nHelper } from "../../sources/modules/i18n-helper/i18n-helper";

function objGet(obj, path){
    return new Function('_', 'return _.' + path)(obj);
}


webix.ui.suggest = webix.extend(webix.ui.suggest, {
    $init: function(config){
        if(config.fitMaster === true && config.fitMaster === false) {
            config.fitMaster = config.fitMaster;
        } else {
            config.fitMaster = false
        }
    }
});


webix.ui.baselayout = webix.extend(webix.ui.baselayout, {
    $init: function(){
        //console.log(this.config)
    }
});

webix.extend(webix.ui.datatable, {
    $init: function(){
        this.attachEvent("onAfterRender", function(){
            const cells = this.getNode().querySelectorAll(".webix_column");
            cells.forEach(element => {
                element.setAttribute("data-cell-name","cog-cell");
            });
            this.eachRow(id => {
                const item = this.getItem(id);
                const entityColumn = this.config.entityColumn;
                const eId = objGet(item,entityColumn);
                if(eId) {
                    const node = this.getItemNode(id);
                    if(node) {
                        node.setAttribute("cog_entity_id", eId);
                    }
                }
            });
            this.eachColumn((id,col) => {
                if(col.node.setAttribute) {
                    col.node.setAttribute("cog_column_name",id);
                }
                const cells = col.node.querySelectorAll(".webix_cell");
                cells.forEach(singleCell => {
                    singleCell.setAttribute("cog_column_name",id);
                });
                if(col.header[0]?.text && col.translateHeader) {
                    // figure out translation key
                    let translationKey = id;
                    if(col.translation_key) {
                        translationKey = col.translation_key;
                    }
                    col.header[0].text = i18nHelper.getTranslation(translationKey);
                }
            })
    })
}});

webix.editors.text = webix.extend({
    render:function(){
        return webix.html.create("div", {
            "class":"webix_dt_editor"
        }, "<input type='text' cog-id='"+this.config.id+"'>");
    }
}, webix.editors.text);


webix.protoUI({
    name:"cogObjectLink",
    $init() {
        this.$ready.push(this._Init);
    },
    _Init: function (config) {
        const me = this;
        const html = `<span class='text-link'>${this.config.objectName}</span>`;
        this.setValue(html);
        this.refresh();
        (webix.ui({
            view:"contextmenu", 
            localId:`${this.idPrefix}ProjectTableContextMenu`,
            data: 
            [
                i18nHelper.getTranslation("Open_in_new_tab")
            ],
            on: {
                onMenuItemClick:function() {
                    CognovisNavigator.openInNewTab(me.config.objectId, me.config.objectType);
                }
            }
        }) as webix.ui.contextmenu).attachTo(this.$view);
    },
    on_click:{
        "webix_view":function() {
            CognovisNavigator.goToObjectDetails(this.config.objectId, this.config.objectType);
        }
    },
}, webix.ui.label, webix.MouseEvents, webix.ui.view, webix.EventSystem);


// This is our own extension to standard Webix Datatable
webix.protoUI({
    name:"cognovis-datatable",
    defaults: {
        cols: [],
        editable:true,
        editaction:"dblclick",
    },

    $init() {
        this.$ready.push(this._Init);
    },
    _Init() {
        const modifiedOriginalDatatable = {
            ...this.config,
            view:"datatable",
            id:"cognovis-"+this.config.id,
            on:this.config.events
        };

        // Do the cognovis translations
        modifiedOriginalDatatable.columns.forEach(column => {
            if(column.header[0]) {
                let translation = column.header[0];
                if(column.id && webix.i18n["custom"][column.id]) {
                    translation =  webix.i18n["custom"][column.id];
                }
                if(column.translationKey &&  webix.i18n["custom"][column.translationKey]) {
                    translation =  webix.i18n["custom"][column.translationKey];
                }
                column.header[0] = translation;
            }
        });
        this.addView(modifiedOriginalDatatable);
    }
}, webix.ui.layout);



