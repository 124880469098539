import { CognovisPleaseWaitWindow } from "../../../services/cognovis-please-wait-window";
import CognovisBasicModal from "../../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { CognovisRestInvoiceService, IInvoiceItem } from "../../../../sources/openapi";
import { i18nHelper } from "../../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisCategory } from "../../../../sources/modules/cognovis-category/cognovis-category";
import { stringify } from "querystring";


export default class InvoiceItemDetailsModal extends CognovisBasicModal {

    idPrefix = "lidm";
    invoiceId:number;
    invoiceItemData:IInvoiceItem;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle:string;
    firstFieldFocus = "item_name";
    afterSubmitAction:() => void;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ",800, 412);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(invoiceItemData:IInvoiceItem, afterSubmitAction?:() => void):void {
        this.invoiceId = this.getParam("invoice_id", true);
        this.invoiceItemData = invoiceItemData;
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
        if(this.invoiceItemData.invoice) {
            this.modalTitle = this.invoiceItemData.item.name;
        } else {
            this.modalTitle = i18nHelper.getTranslation("Add new invoice item");
        }
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTitle);
        this.cognovisPleaseWaitWindow.hide();          
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        this.injectValues();
        this.focusOnField();
    }

    getContent():webix.ui.layoutConfig {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        width:720,
                        localId:`${this.idPrefix}DynfieldCreationModalContainer`,
                        rows:[
                            {
                                view:"form",
                                borderless:true,
                                localId:`${this.idPrefix}InvoiceItemModalForm`,
                                elements:this.getFormElements(),
                                rules:this.getFormRules()
                            }
                        ]
                    }                   
                ]
            }
        };
        return layout
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            click:() => {
                                this.hide();
                            }
                        },  
                        {
                            view:"button",
                            localId:`${this.idPrefix}SaveButton`,
                            value:`${i18nHelper.getTranslation(`Save`)}`,
                            click:() => {
                                this.submit();
                            }
                        }
                    ] 
                };
        return buttons;
    }

    getFormElements():webix.ui.layoutConfig[] {
        return [
            this.getSingleFormElement("text", "item_name", i18nHelper.getTranslation("Item_name"), ""),
            this.getCogMaterialPicker(),
            this.getSingleFormElement("text", "item_units", i18nHelper.getTranslation("Units"), ""),
            this.getSingleFormElement("text", "price_per_unit", i18nHelper.getTranslation("Price_per_unit"),"0"),
            //this.getSingleFormElement("text", "description", i18nHelper.getTranslation("Price_per_unit"),"0")
        ]
    }

    getFormRules():{[key:string]:(value:string) => boolean} {
        return {
            "item_name":webix.rules.isNotEmpty,
            "item_material_id":webix.rules.isNotEmpty,
            "item_units":webix.rules.isNotEmpty,
            "price_per_unit":webix.rules.isNotEmpty
        }
    }

    getCogMaterialPicker():webix.ui.layoutConfig {
        let value:{id:number, value:string} = undefined;
        if(this.invoiceItemData.invoice) {
            value = {
                id:this.invoiceItemData?.item_material?.id,
                value:this.invoiceItemData?.item_material?.name,
            };
        }
        return {
            view:"layout",
            borderless:true,
            height:60,
            rows:[
                {
                    view:"cogWebixMaterialPicker",
                    //disabled:true,
                    name:"item_material_id",
                    value:value,
                    innerKey:"id",
                    existingData:(this.invoiceItemData.invoice) ? this.invoiceItemData.item_material : undefined,
                    labelWidth:180,
                    inputWidth:685,
                    options:[],
                    height:40,
                    label:i18nHelper.getTranslation("Material"),
                    modalTitle:`${i18nHelper.getTranslation("Material picker")}`,
                    onChangeEvent:() => {
                            const form = this.$$(`${this.idPrefix}InvoiceItemModalForm`) as webix.ui.form;
                            form.validate();
                            this.toggleErrors();
                    }
                },
                {
                    template:" ",
                    borderless:true,
                    height:20,
                    innerKey:"id",
                    css:"error-message",
                    errorId:`erroritem_material_id`
                }
            ]
        }

    }

    getSingleFormElement(type:string, fieldName:string, label:string, value:string, category?:string):webix.ui.layoutConfig {
        let suggest = {};
        if(category) {
            suggest = {
                body: {
                    url: () => CognovisCategory.getCategory(category),
                }
            };
        }
        return {
            view:"layout",
            borderless:true,
            height:60,
            rows:[
                {
                    view:type,
                    name:fieldName,
                    label:label,
                    placeholder:label,
                    labelWidth:180,
                    borderless:true,
                    suggest,
                    on: {
                        onChange:() => {
                            const form = this.$$(`${this.idPrefix}InvoiceItemModalForm`) as webix.ui.form;
                            form.validate();
                            this.toggleErrors();
                        }
                    }

                },
                {
                    template:" ",
                    borderless:true,
                    height:20,
                    css:"error-message",
                    errorId:`error${fieldName}`
                }
            ]
        }
    }

    focusOnField():void {
        const form = this.$$(`${this.idPrefix}InvoiceItemModalForm`) as webix.ui.form;
        const field = form.queryView({name:this.firstFieldFocus});
        if(field) {
            field.focus();
        }
    }

    injectValues():void {
        if(this.invoiceItemData.invoice) {
            const form = this.$$(`${this.idPrefix}InvoiceItemModalForm`) as webix.ui.form;
            const itemNameField = form.queryView({name:"item_name"}) as webix.ui.text;
            itemNameField.setValue(this.invoiceItemData.item.name);
            const itemUnitsField = form.queryView({name:"item_units"}) as webix.ui.combo;
            itemUnitsField.setValue(this.invoiceItemData.item_units.toString());
            const pricePerUnitField = form.queryView({name:"price_per_unit"}) as webix.ui.combo;
            pricePerUnitField.setValue(this.invoiceItemData.price_per_unit.toString());
        }
    }

    toggleErrors():void {
        const form = this.$$(`${this.idPrefix}InvoiceItemModalForm`) as webix.ui.form;
        const values = form.getValues();
        Object.keys(values).forEach(key => {
            const errorField = form.queryView({errorId:`error${key}`}) as webix.ui.template;
            if(errorField) {
                if(!values[key]) {
                    errorField.setHTML(`${i18nHelper.getTranslation("Field cannot be empty")}`);
                } else {
                    errorField.setHTML("");
                }
            }
        });

    }

    createNewLinteItem(itemName:string, materialId:number, itemUnits:number, pricePerUnit:number):void {
        CognovisRestInvoiceService.postInvoiceItem({
            invoiceId:this.invoiceId,
            requestBody: {
                item_name:itemName,
                item_units:itemUnits,
                price_per_unit:pricePerUnit,
                item_material_id:materialId,
                description:" ",
            }
        })  
        .then(() => {
            if(this.afterSubmitAction) {
                this.afterSubmitAction();
            }
            this.hide();               
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }
    
    saveLineItem(invoiceItem:IInvoiceItem):void {
        CognovisRestInvoiceService.putInvoiceItem({
            itemId:invoiceItem.item.id,
            requestBody:{
               item_name:invoiceItem.item.name,
               item_units:invoiceItem.item_units,
               description:invoiceItem.description,
               price_per_unit:invoiceItem.price_per_unit,
               item_material_id:this.invoiceItemData.item_material.id
               //sort_order:""
            }
        })
        .then(() => {
            if(this.afterSubmitAction) {
                this.afterSubmitAction();
            }
            this.hide();
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        })
    }

    submit():void {
        const form = this.$$(`${this.idPrefix}InvoiceItemModalForm`) as webix.ui.form;
        if(form.validate()) {
            this.toggleErrors();
            const values = form.getValues();
            if(this.invoiceItemData.invoice) {
                this.invoiceItemData.item.name = values.item_name;
                this.invoiceItemData.source_language.id = values.source_language;
                this.invoiceItemData.target_language.id = values.target_language;
                this.invoiceItemData.item_uom.id = values.item_uom;
                this.invoiceItemData.item_units = values.item_units;
                this.invoiceItemData.price_per_unit = values.price_per_unit;
                this.invoiceItemData.item_material.id = values.item_material_id;
                this.saveLineItem(this.invoiceItemData);
            } else {
                this.createNewLinteItem(values.item_name, values.item_material_id, values.item_units, values.price_per_unit);
            }
        } else {
            this.toggleErrors();
        }
    }

}