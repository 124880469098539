import {injectable} from "tsyringe";

@injectable()
export default class DateHelper {

    isSameDay(d1:Date, d2:Date):boolean {
        return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
    }

    formatToYearMonthDay(date:Date):string {
        const d = new Date(date);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
            month = "0" + month;
        }
        if (day.length < 2) {
            day = "0" + day;
        }

        return [year, month, day].join("-");
    }

    getDaysDifference(date1:Date, date2:Date):number {
        const oneDayInMiliseconds = 24*3600*1000;
        const differenceMiliseconds = date1.getTime()-date2.getTime();
        return Math.round(differenceMiliseconds/oneDayInMiliseconds);
    }

    parseDateForDatepicker(date:string):string {
        const dateObj = new Date(date);
        const dateAsProperString = `${dateObj.getFullYear()}-${(dateObj.getMonth()+1)}-${dateObj.getDate()}`;
        return dateAsProperString
    }

}