import { CognovisRestService, ICompany, IMenuItem, ITransProject, IUser } from "../../sources/openapi";
import { JetView } from "webix-jet"
import { plugins} from "webix-jet";
import { CognovisNavigator } from "../../sources/modules/cognovis-navigator/cognovis-navigator";
import { i18nHelper } from "../../sources/modules/i18n-helper/i18n-helper";
import {config} from "../config";
import { UserProfile } from "../../sources/modules/cognovis-profile/profiles-types";
import CognovisProfile from "../../sources/modules/cognovis-profile/cognovis-profile";

/**
Main sidebar
*/

export default class Sidebar extends JetView {

    idPrefix = "sidebar";
    mainMenuName = "webix_main_menu";
    expandableMenuItems:{[key:string]:string} = {
        "project" : "project-overview.projects",
        "company" : "companies-overview.companies",
        "user": "users-overview.user-details",
        "admin": "a"
    };
    currentProjectId:number;
    currentCompanyId:number;
    openedProjects:ITransProject[] = [];
    openedCompanies:ICompany[] = [];
    openedUsers:{id:number, name:string}[] = [];
    allMenuItems:IMenuItem[] = [];
    mainMenuItems: ILeftNavigationItem[] = [];
    projectSubmenuItems:ILeftNavigationItem[] = [];
    companiesSubmenuItems:ILeftNavigationItem[] = [];
    adminSubmenuItems:ILeftNavigationItem[] = [];
    collapsed = false;
    viewConfig:{[key:string]:string | number} = {
        maximumProjectsOpened:3,
        maximumCompaniesOpened:1,
        maximumAdminOpened:100
    };

    config(): webix.ui.sidebarConfig {
        // Check for sidebar settings
        if(config.sidebarSettings) {
            this.viewConfig = config.sidebarSettings;
        }
        const savedCollapsed = webix.storage.local.get("left_navigation_collapsed_state");
        if(savedCollapsed) {
            this.collapsed = savedCollapsed;
        }
        const sidebar = this.getSidebar();
        return sidebar
    }

    init():void {
        const sidebar = this.getSidebarUI();
        this.use(plugins.Menu, "menu");
        const items = this.getMenuItems(true)
        .then(items => {
            if(CognovisProfile.isUserInGivenGroup(UserProfile.EMPLOYEE) && (!CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) && !CognovisProfile.isUserInGivenGroup(UserProfile.ACCOUNTING))) {
                items = items.filter(item => item.id !== "invoices.invoices");
            }
            this.setMenuItems(items);
        });
    }

    getSidebar():webix.ui.sidebarConfig {
        const sidebar = {
            view: "sidebar",
            localId: "menu",
            id: `${this.idPrefix}MainSidebar`,
            gravity: 1.6,
            activeTitle:true,
            multipleOpen:true,
            scroll:"y",
            collapsed:this.collapsed,
            width: 300,
            data: [],
            on: {
                onItemClick:(menuId:string, e:Event) => {
                    if(menuId === this.expandableMenuItems["project"]) {
                        const sidebar = this.getSidebarUI();
                        const current = sidebar.getSelectedId(false);
                        if (menuId !== current) {
                            sidebar.unselectAll();
                            e.preventDefault();
                            CognovisNavigator.navigateTo(`/main/${this.expandableMenuItems["project"]}`);
                            this.closeAllChilds(this.expandableMenuItems["project"]);
                            this.forceSelection(`${this.expandableMenuItems["project"]}`);
                            this.forceRemoveSelection(`${this.expandableMenuItems["company"]}`);
                            return false
                        }
                    }
                    if(menuId === this.expandableMenuItems["company"]) {
                        const sidebar = this.getSidebarUI();
                        const current = sidebar.getSelectedId(false);
                        if (menuId !== current) {
                            sidebar.unselectAll();
                            e.preventDefault();
                            CognovisNavigator.navigateTo(`/main/${this.expandableMenuItems["company"]}`);
                            this.closeAllChilds(this.expandableMenuItems["company"]);
                            this.forceSelection(`${this.expandableMenuItems["company"]}`);
                            this.forceRemoveSelection(`${this.expandableMenuItems["project"]}`);
                            return false
                        }
                    }
                    // if(menuId === this.expandableMenuItems["admin"]) {
                    //     const sidebar = this.getSidebarUI();
                    //     const current = sidebar.getSelectedId(false);
                    //
                    //
                    // }
                }
            }
        };
        return sidebar;
    }

    collapse():void {
        const sidebar = this.getSidebarUI();
        sidebar.toggle();
        this.collapsed = !this.collapsed;
    }

    getSidebarUI():webix.ui.sidebar {
        const sidebar: webix.ui.sidebar = webix.$$(`${this.idPrefix}MainSidebar`) as webix.ui.sidebar;
        return sidebar;
    }

    openProject(project:ITransProject):void {
        if(project.project.id !== this.currentProjectId) {
            this.currentProjectId = project.project.id;
            this.insertProjectMenuItem(project);
            this.updateProjectsMenu();
            this.markItemAsSelected(this.getCurrentMenuFromUrl());
        }
    }

    openCompany(company:ICompany, forceUser?:boolean, forcedUser?:IUser) {
        this.currentCompanyId = company.company.id;
        this.insertCompanyMenuItem(company);
        if(forceUser && forcedUser) {
            this.insertUserMenuItem(forcedUser.user.id, forcedUser.user.name);
            this.updateCompaniesMenu(forcedUser);
        } else {
            if(company.primary_contact?.id) {
                this.insertUserMenuItem(company.primary_contact.id, company.primary_contact.name);
            }
            this.updateCompaniesMenu();
        }
        this.markItemAsSelected(this.getCurrentMenuFromUrl());
    }

    insertProjectMenuItem(project:ITransProject) {
        if(this.openedProjects.map(project => (project.project.id)).indexOf(project.project.id) === -1) {
            this.openedProjects.push(project);
        }
    }

    insertCompanyMenuItem(company:ICompany) {
        if(this.openedCompanies.map(company => (company.company.id)).indexOf(company.company.id) === -1) {
            this.openedCompanies.push(company);
        }
    }

    insertUserMenuItem(userId:number, userName:string) {
        if(this.openedUsers.map(user => (user.id)).indexOf(userId) === -1) {
            this.openedUsers.push({id:userId, name:userName});
        }
    }



    updateProjectsMenu():void {
        const sidebar = this.getSidebarUI();
        const properProject = this.openedProjects.find(project => project.project.id === this.currentProjectId);
        const alreadyExists = sidebar.getItem(properProject.project.id);
        if(!alreadyExists) {
            this.addItem({ id: properProject.project.id, icon:"fas fa-project-diagram", value: properProject.project_nr}, 0, this.expandableMenuItems["project"]);
            // We need to reverse array to keep proper order
            this.projectSubmenuItems.map(item => {
                this.addItem({ id:`${item.id}?project_id=${this.currentProjectId}`, icon:item.icon, value: item.value}, 0, properProject.project.id.toString());
            });
            if(this.openedProjects.length > this.viewConfig["maximumProjectsOpened"]) {
                this.removeItem(this.openedProjects[0].project.id);
                this.openedProjects.shift();
            }
            sidebar.open(this.expandableMenuItems["project"]);
            sidebar.open(properProject.project.id);
        } else {
            sidebar.open(this.expandableMenuItems["project"]);
            sidebar.open(alreadyExists.id);
        }
    }

    updateCompaniesMenu(forcedUser?:IUser):void {
        const sidebar = this.getSidebarUI();
        const properCompany = this.openedCompanies.find(company => company.company.id === this.currentCompanyId);
        const alreadyExistingCompany = sidebar.getItem(properCompany.company.id);
        if(!alreadyExistingCompany) {
            this.addItem({ id: properCompany.company.id, icon:"fa fa-id-card", value: properCompany.company.name}, 0, this.expandableMenuItems["company"]);
            // We need to reverse array to keep proper order
            this.companiesSubmenuItems.map(item => {
                if(item.label === "webix_user_details") {
                    if(forcedUser?.user) {
                        this.addItem({ id:`${item.id}?user_id=${forcedUser.user.id}&company_id=${properCompany.company.id.toString()}`, icon:item.icon, value:forcedUser.user.name}, 0, properCompany.company.id.toString());
                    } else {
                        if(properCompany.primary_contact?.id) {
                            this.addItem({ id:`${item.id}?user_id=${properCompany.primary_contact.id}&company_id=${properCompany.company.id.toString()}`, icon:item.icon, value: properCompany.primary_contact.name}, 0, properCompany.company.id.toString());
                        }
                    }
                } else {
                    this.addItem({ id:`${item.id}?company_id=${this.currentCompanyId}`, icon:item.icon, value: item.value}, 0, properCompany.company.id.toString());
                }
            });

            if(this.openedCompanies.length > this.viewConfig["maximumCompaniesOpened"]) {
                this.removeItem(this.openedCompanies[0].company.id);
                this.openedCompanies.shift();
            }
            sidebar.open(this.expandableMenuItems["company"]);
            sidebar.open(properCompany.company.id);

        } else {
            this.addItem({id:`${this.expandableMenuItems["user"]}?user_id=${this.openedUsers[this.openedUsers.length-1].id}&company_id=${properCompany.company.id.toString()}`, icon:"fa fa-user", value:this.openedUsers[this.openedUsers.length-1].name}, 1, properCompany.company.id.toString())
            sidebar.open(this.expandableMenuItems["company"]);
            sidebar.open(alreadyExistingCompany.id);
        }
    }

    addItem(itemObj:{id:number | string, icon:string, value:string}, index:number, parentId:string) {
        const sidebar = this.getSidebarUI();
        if(!sidebar.exists(itemObj.id)) {
            (sidebar as unknown as webix.ui.tree).add(itemObj, index, parentId);
            this.updateSidebar();
        }
    }

    removeItem(itemId:number | string) {
        const sidebar = this.getSidebarUI();
        if(sidebar.exists(itemId)) {
            sidebar.remove(itemId);
            this.updateSidebar();
        }
    }

    markItemAsSelected(menuItemId:string):void {
        if(menuItemId) {
            const sidebar = this.getSidebarUI();
            if(sidebar.exists(menuItemId)) {
                sidebar.select(menuItemId);
            } else {
                const strippedFromParams = this.findMenuWithoutParams(menuItemId);
                if(sidebar.exists(strippedFromParams)) {
                    sidebar.select(strippedFromParams);
                }
            }
        }
    }

    getAllItems():ILeftNavigationItem[] {
        const allItems = [];
        const sidebar = this.getSidebarUI();
        const items = sidebar.serialize();
        items.map(item => {
            allItems.push(item);
            if(item.data && item.data.length > 0) {
                item.data.map(itemSecondLevel => {
                    allItems.push(itemSecondLevel);
                    if(itemSecondLevel.data && itemSecondLevel.data.length > 0) {
                        itemSecondLevel.data.map(itemThirdLevel => {
                            allItems.push(itemThirdLevel);
                        });
                    }
                });
            };
        });
        return allItems
    }

    findMenuWithoutParams(menuItemId:string):string {
        let found = "";
        const splitted = menuItemId.split("?");
        const params = splitted[1];
        if(menuItemId) {
            const allItems = this.getAllItems();
            allItems.map(element => {
                if(element.id && element.id.split) {
                    if(splitted[0] === element.id.split("?")[0]) {
                        found = element.id
                    }
                }
            });
        }
        return found;
    }

    getCurrentMenuFromUrl():string {
        const sidebar = this.getSidebarUI();
        const path = sidebar.$scope.app["_subSegment"]["route"]["path"];
        return path.split("/main/")[1];
    }

    addCompanyToUrl(company:ICompany):void {
        if(window.location.href.indexOf("company_id=") === -1) {
            setTimeout(() => {
                const currentUrl = window.location.href;
                let newUrl = `${currentUrl}&company_id=${company.company.id}`;
                if(currentUrl.indexOf("?") === -1) {
                    newUrl = `${currentUrl}?company_id=${company.company.id}`;
                }
                window.history.pushState("", "", newUrl);
            },100);
        }
    }

    closeAllChilds(parentMenuId:string) {
        const sidebar = this.getSidebarUI();
        sidebar.getItem(parentMenuId);
        const items = sidebar.getOpenItems();
        items.map(item => {
            const sidebarItem = sidebar.getItem(item);
            if(sidebarItem.$level === 2) {
                sidebar.close(sidebarItem.id);
            }
        });
    }

    getMenuItems(onlyMainItems?:boolean):Promise<ILeftNavigationItem[]> {
        this.projectSubmenuItems = [];
        return CognovisRestService.getMenuItems({
            packageName: 'webix-portal',
            parentMenuLabel: 'webix_main_menu'
        })
        .then(menuItems => {
            this.allMenuItems = menuItems;
            const menuItemsArr = [];
            menuItems.map(menuItem => {
                const menuObj = {
                    value: i18nHelper.getTranslation(menuItem.name),
                    original:menuItem.name,
                    menu_id: menuItem.menu_id,
                    id: menuItem.url,
                    url:menuItem.url,
                    icon: menuItem.icon,
                    label: menuItem.label,
                    parent_menu_id: menuItem.parent_menu.id,
                    data: [],
                    menu:[],
                    sort_order:menuItem.sort_order
                };
                menuItemsArr.push(menuObj);
                if(menuItem.parent_menu.name === "webix_projects") {
                    this.projectSubmenuItems.push(menuObj);
                    this.projectSubmenuItems = this.projectSubmenuItems.sort((a,b) => (a["sort_order"] > b["sort_order"]) ? 1 : ((b["sort_order"] > a["sort_order"]) ? -1 : 0));
                    this.projectSubmenuItems.reverse();
                }
                if(menuItem.parent_menu.name === "webix_companies_db") {
                    this.companiesSubmenuItems.push(menuObj);
                    this.companiesSubmenuItems = this.companiesSubmenuItems.sort((a,b) => (a["sort_order"] > b["sort_order"]) ? 1 : ((b["sort_order"] > a["sort_order"]) ? -1 : 0));
                    this.companiesSubmenuItems.reverse();
                }
                if(menuItem.parent_menu.name === "webix_admin") {
                    this.adminSubmenuItems.push(menuObj);
                    this.adminSubmenuItems = this.adminSubmenuItems.sort((a,b) => (a["sort_order"] > b["sort_order"]) ? 1 : ((b["sort_order"] > a["sort_order"]) ? -1 : 0));
                    this.adminSubmenuItems.reverse();
                }
                if(menuObj.url === "a") {
                    menuObj.data = this.adminSubmenuItems;
                    menuObj.menu = this.adminSubmenuItems;
                }
                if(menuItem.parent_menu.name  === this.mainMenuName) {
                    this.mainMenuItems.push(menuObj);
                }
            });
            return this.mainMenuItems
        });
    }

    setMenuItems(items:ILeftNavigationItem[]):void {
        const sidebar = this.getSidebarUI();
        sidebar.define("data", items);
        sidebar.refresh();
    }

    updateSidebar():void {
        const sidebar = this.getSidebarUI();
        const items = sidebar.serialize();
        sidebar.clearAll();
        sidebar.parse(items, "");
        sidebar.refresh();
    }

    forceSelection(menuId:string):void {
        const item = document.querySelectorAll(`[webix_tm_id="${menuId}"]`);
        if(item[0]) {
            webix.html.addCss(item[0] as HTMLElement, "webix_selected")
        }
    }

    forceRemoveSelection(menuId:string):void {
        const item = document.querySelectorAll(`[webix_tm_id="${menuId}"]`);
        if(item[0]) {
            webix.html.removeCss(item[0] as HTMLElement, "webix_selected")
        }
    }

}