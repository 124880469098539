/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetTransTaskType {
    TRANS = 4210,
    PROOF = 4211,
    EDIT = 4212,
    OTHER = 4213,
    COPY = 4214,
    CERT = 4218,
    PM = 4219,
}
