
import { i18nHelper } from "../../../../sources/modules/i18n-helper/i18n-helper";
import CognovisBasicModal from "../../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { CognovisRestAbsencesService, CognovisRestService, CognovisRestTimesheetService, ICategory, IDayOff, IntranetProjectStatus, ITimesheetEntry, ITimesheetTask, ITransProject, IUserAbsence, WebixPortalProjectService } from "../../../../sources/openapi";
import { container } from "tsyringe";
import { WebixHelpers } from "../../../../sources/modules/webix-helpers/webix-helpers";
import ObjectNotesModal from "views/modals/object-notes-modal";
import { IJetApp } from "webix-jet";
import { UserProfile } from "../../../../sources/modules/cognovis-profile/profiles-types";
import { CognovisNavigator } from "../../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { CognovisPleaseWaitWindow } from "../../../../sources/services/cognovis-please-wait-window";
import { DatetimeHelper } from "../../../../sources/modules/datetime-helpers/datetime-helpers";
import { UserStateManager } from "../../../../sources/services/user-state-manager";

export default class LoggedHoursModal extends CognovisBasicModal {

    idPrefix = "lhm";
    itemId: string;
    projectId:number;
    tableName = "LoggedHoursDatatable";
    webixHelpers: WebixHelpers;
    fullTableId = `${this.idPrefix}${this.tableName}`;
    currentUser:{id:number, value:string, name:string};
    isThatNewRow = false;
    timesheetTasks:ITimesheetTask[];
    notesModal:ObjectNotesModal;
    afterSaveAction:() => void;
    editionButtonsHidden = true;
    possibleProjects:{id:string, value:string}[];
    dateSelectorEnabled = false;
    filterWithCurrentUser = false;
    currentUserTimesheetEntries:ITimesheetEntry[] = [];
    absenceTypes:ICategory[];
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 1200, 640);
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }
    
    openModal(projectId:number, dateSelectorEnabled?:boolean, filterWithCurrentUser?:boolean, afterSaveAction?:() => void):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        let projectStatusId = 0;
        this.dateSelectorEnabled = dateSelectorEnabled;
        this.filterWithCurrentUser = filterWithCurrentUser;
        if((this.app as IJetApp & {currentProject:ITransProject}).currentProject) {
            projectStatusId = (this.app as IJetApp & {currentProject:ITransProject}).currentProject.project_status.id;
        }
        const deliveredStatusIds = [IntranetProjectStatus.CLOSED, IntranetProjectStatus.DELIVERED, IntranetProjectStatus.INVOICED, IntranetProjectStatus.DECLINED];
        if(deliveredStatusIds.indexOf(projectStatusId) === -1) {
            this.editionButtonsHidden = false;
        }
        const loggedUserData = webix.storage.session.get("logged_user_data");
        const currentUserId = UserStateManager.getCurrentlyLoggedUser().id;
        this.currentUser =   {
            id:currentUserId,
            value:`${loggedUserData.first_names} ${loggedUserData.last_name}`,
            name:`${loggedUserData.first_names} ${loggedUserData.last_name}`
        };
        if(afterSaveAction) {
            this.afterSaveAction = afterSaveAction;
        }
        this.projectId = projectId;
        WebixPortalProjectService.getUserProjects({
        })
        .then(projects => {
            this.possibleProjects = projects.map(project => ({id:project.project.id.toString(), value:`${project.project_nr} - ${project.project.name}`}));
            if(this.projectId > 0) {
                CognovisRestTimesheetService.getTimesheetTask({projectId:this.projectId})
                .then(timesheetTasks => {
                    const modal = (this.getRoot() as webix.ui.window);
                    modal.show(); 
                    this.timesheetTasks = timesheetTasks;
                    const modalContent = this.getContent(this.possibleProjects, timesheetTasks);
                    const modalTitle = i18nHelper.getTranslation("Logged_hours")
                    const actionButtons = this.getActionButtons();
                    this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
                    this.cognovisPleaseWaitWindow.hide();
                });
            } else {
                const modal = (this.getRoot() as webix.ui.window);
                modal.show(); 
                const modalContent = this.getContent(this.possibleProjects, []);
                const modalTitle = i18nHelper.getTranslation("Logged_hours")
                const actionButtons = this.getActionButtons();
                this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
                this.cognovisPleaseWaitWindow.hide();          
            }
        });
    }

    closeModal():void {
        this.hide();
    }

    getContent(possibleProjects:{id:string, value:string}[], timesheetTasks:ITimesheetTask[]):webix.ui.layoutConfig {
        const rows:webix.ui.baseviewConfig[] = [
            this.getToolbar(possibleProjects)
        ];
        if(timesheetTasks.length > 0) {
            rows.push(this.getHoursTable(timesheetTasks));
        }
        const layout = {
            view:"scrollview",
            body:{
                width:1200,
                height:640,
                padding:30,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}LoggedHoursModal`,
                        rows:rows
                    },                    
                ]
            }
        };
        return layout
    }

    getToolbar(possibleProjects:{id:string, value:string}[]):webix.ui.toolbarConfig {
        const toolbar = {
            view:"toolbar",
            tooltip:true,
            hidden:this.editionButtonsHidden,
            cols:[
                {
                    view:"combo",
                    gravity:50,
                    placeholder:i18nHelper.getTranslation("Select_project"),
                    localId:`${this.idPrefix}ProjectSelector`,
                    options:possibleProjects,
                    value:this.projectId,
                    on:{
                        onChange:(value) => {
                            this.projectId = value;
                            this.recreateTable(this.projectId);
                            //this.refreshTable(this.projectId);
                        }
                    }
                },
                {
                    view: "datepicker",
                    format:"%d.%m.%Y %H:%i",
                    disabled:!this.dateSelectorEnabled,
                    hidden:!this.dateSelectorEnabled,
                    localId:`${this.idPrefix}DateSelector`,
                    value: "",
                    timepicker: true,
                    gravity:30,
                    suggest:{
                        type:"calendar", 
                        body:{
                            dayTemplate:(date, sec) => {
                                const dayId = this.getCalendarDayDivId(date.getDate(), (date.getMonth()+1), date.getFullYear());
                                let isThatDayInTheFuture = false;
                                const dayInCalnedarTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                const todayTime = new Date();
                                if(dayInCalnedarTime.getTime() >= todayTime.getTime()) {
                                    isThatDayInTheFuture = true;
                                }
                                const fullDate = this.getFullDate(date.getDate(), (date.getMonth()+1), date.getFullYear());
                                const html =  `<div id='${dayId}' data-cog-full-date='${fullDate}' data-cog-is-date-in-future='${isThatDayInTheFuture}' webix_tooltip='0' data-acch='0' data-dayofweek='${date.getDay()}' class='day cog-calendar-day-marker'>${date.getDate()}</div>`;
                                return html;
                            },
                            on:{
                                onAfterMonthChange:(values) => {
                                    const days = document.querySelectorAll(".cog-calendar-day-marker");
                                    const startingDay = days[0];
                                    const extractedStartingDay = startingDay["dataset"]["cogFullDate"];
                                    const endingDate = days[days.length-1];
                                    const extractedEndingDay = endingDate["dataset"]["cogFullDate"];
                                    this.markDaysInCalendar(extractedStartingDay, extractedEndingDay);
                                },
                            }
                        },
                    },
                    on: {
                        onChange:() => {
                            this.recreateTable(this.projectId);
                        },
                        onFocus:() => {
                            setTimeout(() => {
                                const days = document.querySelectorAll(".cog-calendar-day-marker");
                                const startingDay = days[0];
                                const extractedStartingDay = startingDay["dataset"]["cogFullDate"];
                                const endingDate = days[days.length-1];
                                const extractedEndingDay = endingDate["dataset"]["cogFullDate"];
                                this.markDaysInCalendar(extractedStartingDay, extractedEndingDay);
                            },100);
                        }   
                    }
                },
                {
                    view:"spacer",
                    gravity:10
                },
                { 
                    view:"button", 
                    type:"icon", 
                    css:"cog-icon-dark",
                    localId:`${this.idPrefix}AddNewEntryButton`,
                    gravity:9,
                    width:20, 
                    icon:"fal fa-plus", 
                    align:"right",
                    click:() => this.addNewEntry()
                }
            ]
        };
        return toolbar
    }

    markDaysInCalendar(startindDate:string, endingDate:string):void {
        if(this.currentUserTimesheetEntries.length > 0) {
            setTimeout(() => {
                this.addClassesToCalendar(this.currentUserTimesheetEntries, [], []);
            },100);
        } else {
            this.getTimesheetEntries(this.projectId, this.currentUser.id)
            .then(entries => {
                CognovisRestAbsencesService.getDaysOff({
                    startDate:startindDate,
                    endDate:endingDate
                })
                .then(daysOff => {
                    CognovisRestAbsencesService.getUserAbsence({ownerId:this.currentUser.id, groupId:UserProfile.EMPLOYEE})
                    .then(absences => {
                        CognovisRestService.getImCategory({categoryType:"Intranet Absence Type"})
                        .then(absenceTypes => {
                            this.absenceTypes = absenceTypes;
                            this.addClassesToCalendar(entries, daysOff, absences);
                        });
                    });
                });
            });
        }
    }
    
    addClassesToCalendar(entries:ITimesheetEntry[], daysOff:IDayOff[], absences:IUserAbsence[]):void {
        const days = document.querySelectorAll(".cog-calendar-day-marker");
        days.forEach(dayDiv => {
            webix.TooltipControl.addTooltip(dayDiv.id as string, this.webixHelpers.convertHoursDecimalToDuration("0"));
            if(dayDiv["dataset"]["dayofweek"] !== "0" && dayDiv["dataset"]["dayofweek"] !== "6" && dayDiv["dataset"]["cogIsDateInFuture"] !== "true") {
                dayDiv.classList.add('noentries');
            }
        });
        const myFormat = webix.Date.dateToStr("%d-%m-%Y", false);
        entries.map(entry => {
            const parsedDate = myFormat(new Date(entry.day));
            const dayId = `${this.idPrefix}-day-${parsedDate}`;
            const node = document.getElementById(dayId);
            if(node) {
                webix.html.removeCss(node, "noentries");
                // acch stands for accumulated hours
                let accumulatedHours = entry.hours;
                if(node.dataset["acch"]) {
                    accumulatedHours = accumulatedHours + parseFloat(node.dataset["acch"]);
                }
                node.dataset["acch"] = accumulatedHours.toString();
                const convertedHours = this.webixHelpers.convertHoursDecimalToDuration(accumulatedHours.toString());
                webix.TooltipControl.addTooltip(node.id as string, convertedHours);
                if(accumulatedHours <= 6) {
                    webix.html.addCss(node, "notalotofentries");
                }
            }
        });
        daysOff.map(dayOff => {
            const parsedDate = myFormat(new Date(dayOff.day));
            const dayId = `${this.idPrefix}-day-${parsedDate}`;
            const node = document.getElementById(dayId);
            if(node) {
                node.style.backgroundColor = `#${dayOff.color}`;
                webix.TooltipControl.addTooltip(node.id as string, dayOff.absence_type.name);
            }
        });
        absences.map(absence => {
            const absenceStartDate = new Date(absence.start_date);
            const absenceEndDate = new Date(absence.end_date);
            let loopDate = absenceStartDate;
            while(loopDate <= absenceEndDate) {
                const parsedDate = myFormat(loopDate);
                const dayId = `${this.idPrefix}-day-${parsedDate}`;
                const node = document.getElementById(dayId);
                if(node) {
                    //webix.html.addCss(node, "absence");
                    const absenceTypeColor = this.absenceTypes.find(cat => cat.category_id === absence.absence_type.id).aux_string2;
                    if(absenceTypeColor) {
                        node.style.backgroundColor = `#${absenceTypeColor}`;
                    }
                    webix.TooltipControl.addTooltip(node.id as string, absence.absence.name);
                }
                const newLoopDate = loopDate.setDate(loopDate.getDate() + 1);
                loopDate = new Date(newLoopDate);
            }
        });
    }

    getCalendarDayDivId(day:number, month:number, year:number):string {
        let correctedDay = `${day}`;
        if(day <= 9) {
            correctedDay = `0${day}`
        }
        let correctedMonth = `${month}`;
        if(month <=9) {
            correctedMonth = `0${month}`;
        }
        const dayId = `${this.idPrefix}-day-${correctedDay}-${correctedMonth}-${year}`;
        return dayId;
    }

    getFullDate(day:number, month:number, year:number):string {
        let correctedDay = `${day}`;
        if(day <= 9) {
            correctedDay = `0${day}`
        }
        let correctedMonth = `${month}`;
        if(month <=9) {
            correctedMonth = `0${month}`;
        }
        const dayId = `${year}-${correctedMonth}-${correctedDay}`;
        return dayId;
    }


    getHoursTable(timesheetTasks:ITimesheetTask[]):webix.ui.datatableConfig {
        const timesheetTasksParsed = timesheetTasks.map(task => ({id:task.task.id, value:task.task.name}));
        const datatable = {
            view:"datatable",
            localId:`${this.idPrefix}LoggedHoursDatatable`,
            columns:[
                {
                    id:"hour_id",
                    hidden:true
                },
                {
                    id:"task_name",
                    map:"#task.name#",
                    hidden:true,
                },
                {
                    id:"task",
                    header:[i18nHelper.getTranslation("Task")],
                    map:"#task.id#",
                    fillspace:true,
                    editor:"richselect",
                    sort:"text",
                    options:() => timesheetTasksParsed
                },
                {
                    id:"user",
                    editor:"text",
                    header:[i18nHelper.getTranslation("User")],
                    map:"#user.id#",
                    width:160,
                    editable:false,
                    sort:"text",
                    options:() => {
                        return WebixPortalProjectService.getProjectMembers({
                            projectId:this.projectId
                        })
                        .then(projectMembers => {
                            return projectMembers.map(member => ({id:member.member.user.id, value:member.member.user.name}));
                        });
                    },
                    value:this.currentUser.id
                },
                {
                    id:"hours",
                    header:[i18nHelper.getTranslation("Hours")],
                    hidden:true
                },
                {
                    id:"duration",
                    editor:"text",
                    inputConfig:{ placeholder: "text here" },
                    header:[i18nHelper.getTranslation("Duration")],
                    editable:true,
                    width:80,
                    sort:"text"
                },
                {
                    id:"day",
                    width:160,
                    header:[i18nHelper.getTranslation("Day")],
                    editor:"date",
                    sort:"date",
                    suggest: {
                        type: "calendar",
                        editable: true,
                        body: {
                            timepicker: true,
                            calendarTime: "%H:%i",
                            on: {
                                onBeforeRender: function () {
                                    if (this.config.date.toString() === "Invalid Date") {
                                        this.config.date = new Date();
                                    }
                                }
                            }
                        },

                    },
                    template: (task) => {
                        const myFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
                        const formattedDate = myFormat(new Date(task.day));
                        if (formattedDate.indexOf("NaN") > -1) {
                            return "";
                        } else {
                            return formattedDate;
                        }
                    }
                },
                {
                    view: "template",
                    hidden:this.editionButtonsHidden,
                    css: "edit",
                    width: 40,
                    template: function (obj) {
                        return `<span class='webix_icon wxi-${obj.edit ? "check" : "pencil"}' id='edit-${obj.edit ? "check" : "pencil"}'></span>`;
                    }
                },
                {
                    view: "template",
                    hidden:this.editionButtonsHidden,
                    css:"edit",
                    width: 40,
                    template: function () {
                        return `<span style='color:grey' class='webix_icon mdi mdi-comment-processing-outline'></span>`;
                    }
                },
                {
                    view: "template",
                    hidden:this.editionButtonsHidden,
                    css: "edit",
                    width: 40,
                    template: function () {
                        return `<span class='webix_icon wxi-trash'></span>`;
                    }
                },
            ],
            url:() => this.getTimesheetEntries(this.projectId),
            onClick: {
                "wxi-pencil": (event:Event, element:{column:string, row:number}) => {
                    const table = (this.$$(this.fullTableId) as webix.ui.datatable);
                    table.define("editable", true);
                    this.editStart(event, element);
                },
                "wxi-check": (event:Event, element:{column:string, row:number}) => {
                    const table = (this.$$(this.fullTableId) as webix.ui.datatable);
                    table.define("editable", false);
                    this.editEnd(event, element);
                },
                "wxi-trash": (event:Event, element:{column:string, row:number})=> {
                    const table = (this.$$(this.fullTableId) as webix.ui.datatable);
                    const item = table.getItem(element.row);
                    const parsedDate = this.webixHelpers.formatDate(new Date(item.day));
                    const confirmWarning = `${i18nHelper.getTranslation(`You_want_to_remove`)} ${item.task_name} ${parsedDate}?`;
                    webix.confirm({
                        title: i18nHelper.getTranslation(`any_are_you_sure_message`),
                        type: "confirm-warning",
                        text: confirmWarning,
                        width: 480
                    })
                    .then(() => {
                        this.deleteTimesheetEntry(item.hour_id)
                    });
                },
                "mdi-comment-processing-outline":(event:Event, element:{column:string, row:number}) => {
                    const table = (this.$$(this.fullTableId) as webix.ui.datatable);
                    const item:ITimesheetEntry = table.getItem(element.row);
                    if(!this.notesModal) {
                        this.notesModal = this.ui(ObjectNotesModal) as ObjectNotesModal;
                    }
                    this.notesModal.openNotesModal("write", i18nHelper.getTranslation("Logged hours info"), item.hour_id, item.note, "cke5", [], (newComment:string) => {
                        CognovisRestTimesheetService.putTimesheetEntry({
                            hourId:item.hour_id,
                            requestBody:{
                                note:newComment,
                                hours: item.hours         
                            }
                        })
                        .then(() => {
                            this.refreshTable(this.projectId);
                        });
                    });
                }
            }
        };
        return datatable;
    }

    editStart(event: Event, element:{column:string, row:number}):void {
        const table = this.$$(this.fullTableId) as webix.ui.datatable;
        if (this.itemId && typeof this.itemId !== "undefined") {
            //form.clearValidation();
            if (table.getItem(this.itemId)) {
                table.getItem(this.itemId).edit = false;
                table.refresh(this.itemId);
            }
        }
        this.itemId = element.row.toString();
        this.markAsCurrentlyEdited(this.itemId);
        table.editStop();
        table.editCell(element.row, "task");
    }

    editEnd(event: Event, element:{column:string, row:number}):void {
        this.itemId = element.row.toString();
        const table = this.$$(this.fullTableId) as webix.ui.datatable;
        const item = table.getItem(this.itemId);
        let suchDayIsNotYetLogged = false;
        const currentEntries = table.serialize();
        const searchForSameDayEntries = currentEntries.filter(entry => Number(entry.user) === Number(item.user) && (Number(entry.task) === Number(item.task)) && new Date(entry.day).toISOString().split('T')[0] === new Date(item.day).toISOString().split('T')[0]);
        if(searchForSameDayEntries.length < 2 ) {
            suchDayIsNotYetLogged = true;
        }
        this.clearEditMode();
        let parsedDeadline = "";
        if(item.day && DatetimeHelper.isValidDate(item.day)) {
            parsedDeadline = this.webixHelpers.formatDate(item.day);
        }
        const decimalHours = this.webixHelpers.convertDurationToHoursDecimal(item.duration);
        CognovisRestTimesheetService.postTimesheetEntry({
            projectId:item.task,
            requestBody:{
                day:parsedDeadline,
                hours:decimalHours,
                user_id:this.currentUser.id
            }
        })
        .then(() => {
            this.clearEditMode();
            this.refreshTable(this.projectId);
            this.isThatNewRow = false;
            if(this.afterSaveAction) {
                this.afterSaveAction();
            }
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal",
            });
        });
    }

    markAsCurrentlyEdited(item: string): void {
        const table = (this.$$(this.fullTableId) as webix.ui.datatable);
        // clear any current selection
        this.clearEditMode();
        const vals = table.getItem(item);
        vals.edit = true;
        table.refresh(this.itemId);
    }

    clearEditMode(): void {
        const table = (this.$$(this.fullTableId) as webix.ui.datatable);
        table.editStop();
        //this.itemId = "";
        if (this.itemId) {
            table.getItem(this.itemId).edit = false;
            table.refresh(this.itemId);
        }
    }

    getTimesheetEntries(projectId:number, userId?:number):Promise<ITimesheetEntry[]> {
        let params:{projectId?:number, userId?:number} = {projectId:projectId};
        if(projectId === 0 && userId) {
            params = {userId:userId}
        }
        return CognovisRestTimesheetService.getTimesheetEntry(params)
        .then(entries => {
            let withHoursDecimal = entries.map(entry => {
                entry["day"] = DatetimeHelper.parseDateForDatepicker(entry.day);
                entry["duration"] = this.webixHelpers.convertHoursDecimalToDuration(entry.hours.toString());
                return entry
            });
            // User filtering
            if(this.filterWithCurrentUser) {
                withHoursDecimal = withHoursDecimal.filter(entry => entry.user.id === this.currentUser.id);
            }
            let selectedDate = "";
            const dateSelector = this.$$(`${this.idPrefix}DateSelector`) as webix.ui.datepicker;
            if(dateSelector) {
                selectedDate = dateSelector.getValue() as string;
            }
            if(this.dateSelectorEnabled && selectedDate !== null) {
                const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
                const dateFormatted = myFormat(selectedDate);
                const hoursWithDecimalFilteredWithDay = withHoursDecimal.filter(entry => {
                    const entryDayFormatted = myFormat(new Date(entry.day));
                    const isDayCorrect = dateFormatted === entryDayFormatted;
                    if(isDayCorrect) {
                        return true
                    }
                });
                return hoursWithDecimalFilteredWithDay;
            } else {
                return withHoursDecimal
            }
        });
    }

    addNewEntry(): void {
        if(this.timesheetTasks.length > 0) {
            const table = (this.$$(this.fullTableId) as webix.ui.datatable);
            const data = {
                task:{
                    id:this.timesheetTasks[0].task.id,
                    name:this.timesheetTasks[0].task.name
                },
                user: this.currentUser,
                hours:1.75,
                duration:"1h 20m",
                day:new Date(),
                edit:true
            }        
            const id = table.add(webix.copy(data), 0) as string;
            table.define("editable", true);
            table.editCell(id, "task");
            this.isThatNewRow = true;
        } else {
            const translatedError = i18nHelper.getTranslation("Project_has_no_timesheet_tasks");
            const yesGoToProjectInfo = i18nHelper.getTranslation("Do_you_want_to_open_project_info");
            webix.confirm({
                title: "Error",
                width:400,
                text: `${translatedError}<br/>${yesGoToProjectInfo}` ,
                type:"confirm-warning",
                callback:(confirmed) => {
                    if(confirmed) {
                        CognovisNavigator.goToObjectDetails(this.projectId, "im_project");
                    }
                }
            });
        }
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
 
                    ] 
                };
        return buttons;
    }

    refreshTable(projectId:number):void {
        const table = (this.$$(this.fullTableId) as webix.ui.datatable);
        table.clearAll();
        CognovisRestTimesheetService.getTimesheetEntry({projectId:projectId})
        .then(entries => {
            const withHoursDecimal = entries.map(entry => {
                entry["day"] = DatetimeHelper.parseDateForDatepicker(entry.day);
                entry["duration"] = this.webixHelpers.convertHoursDecimalToDuration(entry.hours.toString());
                return entry
            });
            const withHoursDecimalUser = withHoursDecimal.filter(entry => entry.user.id === this.currentUser.id);
            table.define("data", withHoursDecimalUser);
            table.refresh();
        });
    }

    recreateTable(projectId:number):void {
        const modal = this.$$(`${this.idPrefix}LoggedHoursModal`) as webix.ui.layout;
        const dtable = modal.queryView({view:"datatable"}) as webix.ui.datatable;
        if(dtable) {
            modal.removeView(dtable.config.id);
        }
        CognovisRestTimesheetService.getTimesheetTask({projectId:projectId})
        .then(timesheetTasks => {
            this.timesheetTasks = timesheetTasks;
            this.getTimesheetEntries(projectId)
            .then(() => {
                const newTable = this.getHoursTable(timesheetTasks);
                modal.addView(newTable);
            });
        });
    }

    deleteTimesheetEntry(entryId:number):void {
        CognovisRestTimesheetService.deleteTimesheetEntry({
            hourId:entryId
        })
        .then(deletedTasks => {
            if(deletedTasks.length > 0) {
                let allErrorsText = "";
                deletedTasks.map((error, index) => {
                    const temp = `${index+1}. ${error.err_msg}</br>`; 
                    allErrorsText = allErrorsText + temp;
                });
                webix.alert({
                    title: "Error",
                    width:400,
                    text: allErrorsText,
                    type:"alert-error"
                });
            } else {
                this.refreshTable(this.projectId);
            }
        });
    }

}