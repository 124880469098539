/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ICompany } from '../models/ICompany';
import type { ICompanyBody } from '../models/ICompanyBody';
import type { ICompanyContact } from '../models/ICompanyContact';
import type { ICompanyContactBody } from '../models/ICompanyContactBody';
import type { ICountry } from '../models/ICountry';
import type { IError } from '../models/IError';
import type { IInternalCompany } from '../models/IInternalCompany';
import type { ImCompanyId } from '../models/ImCompanyId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IntranetBizObjectRoleIds } from '../models/IntranetBizObjectRoleIds';
import type { IntranetCompanyStatusIds } from '../models/IntranetCompanyStatusIds';
import type { IntranetCompanyTypeIds } from '../models/IntranetCompanyTypeIds';
import type { IntranetOfficeStatus } from '../models/IntranetOfficeStatus';
import type { IntranetOfficeType } from '../models/IntranetOfficeType';
import type { IOffice } from '../models/IOffice';
import type { PersonId } from '../models/PersonId';
import type { UserIds } from '../models/UserIds';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestCompanyService {

    /**
     * Endpoint return list of companies with some additional data
     * Handler for GET rest calls on companies.<br>    It returns array of companies filtered by company_type or company_status<br>    It is also possible to get single company by passing company_id
     * @returns ICompany Array with company information
     * @throws ApiError
     */
    public static getCompanies({
        companyId,
        primaryContactId,
        companyTypeIds,
        companyStatusIds,
        autocompleteQuery,
    }: {
        /**
         * If provided limit to only one company_id
         */
        companyId?: IntegerInt32,
        /**
         * Primary contact for whom we like the company info(s)
         */
        primaryContactId?: IntegerInt32,
        /**
         * Limit to only companies of this company_type
         */
        companyTypeIds?: IntranetCompanyTypeIds,
        /**
         * Limit to only documents of this company status
         */
        companyStatusIds?: IntranetCompanyStatusIds,
        /**
         * Query string for auto completion of companies. Used in lookup of companies for projects
         */
        autocompleteQuery?: string,
    }): CancelablePromise<Array<ICompany>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/companies',
            query: {
                'company_id': companyId,
                'primary_contact_id': primaryContactId,
                'company_type_ids': companyTypeIds,
                'company_status_ids': companyStatusIds,
                'autocomplete_query': autocompleteQuery,
            },
        });
    }

    /**
     * Handler for GET rest calls to get company contacts (needed in Customer Portal App)
     * @returns ICompanyContact Array of company contacts based found
     * @throws ApiError
     */
    public static getCompanyContacts({
        companyId,
        email,
        contactIds,
        roleIds,
    }: {
        /**
         * ID of the company for which to get the contacts. Defaults to main company of logged in user
         */
        companyId?: ImCompanyId,
        /**
         * email address of the user we want to look for
         */
        email?: string,
        /**
         * Company contacts to return - ignored if company_id is provided - user_id of the company contact (equals company_contact_id)
         */
        contactIds?: UserIds,
        /**
         * Biz object role the user has (like member or key account) - only useful if you provide company_id
         */
        roleIds?: IntranetBizObjectRoleIds,
    }): CancelablePromise<Array<ICompanyContact>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/company_contacts',
            query: {
                'company_id': companyId,
                'email': email,
                'contact_ids': contactIds,
                'role_ids': roleIds,
            },
        });
    }

    /**
     * Get offices for a company
     * @returns IOffice Array of offices
     * @throws ApiError
     */
    public static getCompanyOffices({
        companyId,
        officeTypeId,
        officeStatusId,
    }: {
        /**
         * company for which we want the offices
         */
        companyId: ImCompanyId,
        /**
         * Type of the office, defaults to main office
         */
        officeTypeId?: IntranetOfficeType,
        /**
         * Status of the office, defaults to active
         */
        officeStatusId?: IntranetOfficeStatus,
    }): CancelablePromise<Array<IOffice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/company_offices',
            query: {
                'company_id': companyId,
                'office_type_id': officeTypeId,
                'office_status_id': officeStatusId,
            },
        });
    }

    /**
     * Return the list of configured countries and country codes.
     * @returns ICountry Array of countries
     * @throws ApiError
     */
    public static getCountries(): CancelablePromise<Array<ICountry>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/countries',
        });
    }

    /**
     * Return data about the internal company
     * @returns IInternalCompany Company information for the internal company
     * @throws ApiError
     */
    public static getInternalCompany(): CancelablePromise<IInternalCompany> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_company',
        });
    }

    /**
     * Handler for POST calls on companies.<br>    Can either create new company (with user) and edit existing one
     * @returns ICompany Object with the newly created company
     * @throws ApiError
     */
    public static postCompany({
        requestBody,
    }: {
        /**
         * Company Information
         */
        requestBody: ICompanyBody,
    }): CancelablePromise<ICompany> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/company',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Try to delete a company (as in purge from the database). Will fail if the company has any associated information though. In this case use PUT to<br>    update the company status to deleted instead
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteCompany({
        companyId,
    }: {
        /**
         * Company we want to purge
         */
        companyId: ImCompanyId,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/company',
            query: {
                'company_id': companyId,
            },
        });
    }

    /**
     * Handler for PUT calls on companies.
     * @returns ICompany Object with the newly created company
     * @throws ApiError
     */
    public static putCompany({
        companyId,
        requestBody,
    }: {
        /**
         * Company we want to update the information for
         */
        companyId: ImCompanyId,
        /**
         * Company Information
         */
        requestBody: ICompanyBody,
    }): CancelablePromise<ICompany> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/company',
            query: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a contact for a company
     * @returns ICompanyContact Contact object
     * @throws ApiError
     */
    public static postCompanyContact({
        companyId,
        requestBody,
        password,
    }: {
        /**
         * Company for which we want to add a contact
         */
        companyId: ImCompanyId,
        /**
         * Contact information for the contact we want to add
         */
        requestBody: ICompanyContactBody,
        /**
         * Password we use by default for the user - this will make sure the contact actually can login (if empty user won't be able to login)
         */
        password?: string,
    }): CancelablePromise<ICompanyContact> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/company_contact',
            query: {
                'company_id': companyId,
                'password': password,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Nuke a company_contact instead of setting the status to deleted<br><br>    Will completely remove the contact if possible.
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteCompanyContact({
        contactId,
        companyId,
    }: {
        /**
         * Person we want to remove
         */
        contactId: PersonId,
        /**
         * Person we want to remove
         */
        companyId?: ImCompanyId,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/company_contact',
            query: {
                'contact_id': contactId,
                'company_id': companyId,
            },
        });
    }

    /**
     * Change a contact for a company. Note that password isn't supported
     * @returns ICompanyContact Contact object
     * @throws ApiError
     */
    public static putCompanyContact({
        contactId,
        requestBody,
    }: {
        /**
         * Contact we want to edit
         */
        contactId: PersonId,
        /**
         * Contact information for the contact we want to add
         */
        requestBody: ICompanyContactBody,
    }): CancelablePromise<ICompanyContact> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/company_contact',
            query: {
                'contact_id': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
