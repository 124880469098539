import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, CognovisRestService, IDynfieldLayoutPageBody} from "../../../sources/openapi";

export default class DynfieldsLayoutEditModal extends CognovisBasicModal {

    pageUrl:string;
    idPrefix = "dynflem";
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle: string;
    afterSubmitAction: () => void;

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 600, 340);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(pageUrl:string, afterSubmitAction?: () => void): void {
        this.modalTitle = i18nHelper.getTranslation("Create new dynfield layout");
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTitle);
        this.cognovisPleaseWaitWindow.hide();
        const modal = (this.getRoot() as webix.ui.window);
        modal.show();
        if(pageUrl) {
            this.pageUrl = pageUrl;
            this.setFormValues();
        }
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
    }

    getContent(): webix.ui.layoutConfig {

        const layout = {
            view: "scrollview",
            body: {
                padding: 13,
                rows: [
                    {
                        view: "form",
                        id: `${this.idPrefix}DynfieldLayoutEditViewForm`,
                        elements: [
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Page url"),
                                labelWidth: 160,
                                name: "page_url"
                            },
                            {
                                view: "combo",
                                label: i18nHelper.getTranslation("Object type"),
                                labelWidth: 160,
                                name: "object_type",
                                suggest: {
                                    body: {
                                        url: () => {
                                            return CognovisRestService.getValidObjectTypes()
                                                .then(types => {
                                                    return types.map(type => ({id:type.object_type, value:type.object_type}))
                                            });
                                        }
                                    }
                                }
                            },
                            {
                                view:"combo",
                                label: i18nHelper.getTranslation("Layout type"),
                                labelWidth: 160,
                                name: "layout_type",
                                options:[
                                    { id: "table", value: "Table" },
                                    { id: "div_absolute", value: "DIV Absolute" },
                                    { id: "div_relative", value: "DIV Relative" },
                                    { id: "adp", value: "ADP Page" }
                                ]
                            },
                            {
                                view: "checkbox",
                                label: i18nHelper.getTranslation("Default"),
                                labelWidth: 160,
                                name: "default_p"
                            }
                            ]
                    }
                ]
            }
        };
        return layout;
    }

    setFormValues():void {
        const form = webix.$$(`${this.idPrefix}DynfieldLayoutEditViewForm`) as webix.ui.form;
        CognovisRestDynamicService.getDynfieldLayoutPages({pageUrl: this.pageUrl})
            .then(dynfieldLayouts => {
                if(dynfieldLayouts[0]) {
                    const dynfieldLayout = dynfieldLayouts[0];
                    this.setTitle(`${dynfieldLayout.object_type} - ${dynfieldLayout.page_url}`);
                    Object.entries(dynfieldLayout).forEach(([key, value]) => {
                        const element = form.queryView({name:key});
                        if(element) {
                            element.setValue(value);
                        }
                    });
                }
            });
    }

    getActionButtons(): webix.ui.layoutConfig {
        return {
            view: "layout",
            padding: 0,
            cols: [
                {
                    view: "button",
                    value: `${i18nHelper.getTranslation('Close')}`,
                    click: () => {
                        this.hide();
                    }
                },
                {
                    view: "button",
                    localId: `${this.idPrefix}SaveButton`,
                    value: `${i18nHelper.getTranslation('Save')}`,
                    click: () => {
                        this.submit();
                    }
                }
            ]
        };
    }

    submit(): void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait") });
        const form = webix.$$(`${this.idPrefix}DynfieldLayoutEditViewForm`) as webix.ui.form;
        const values = form.getValues();
        if(this.pageUrl) {
            CognovisRestDynamicService.putDynfieldLayoutPages({
                requestBody: values as IDynfieldLayoutPageBody
            })
                .then(result => {
                    this.afterSubmitAction();
                    this.cognovisPleaseWaitWindow.hide();
                    this.hide();
                });
        } else {
            CognovisRestDynamicService.postDynfieldLayoutPages({requestBody:values as IDynfieldLayoutPageBody})
                .then(result => {
                    this.afterSubmitAction();
                    this.cognovisPleaseWaitWindow.hide()
                    this.hide();
                });
        }
    }
}
