import { IJetApp} from "webix-jet";
import ObjectNotesModal from "../modals/object-notes-modal";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { } from "../../../sources/openapi/services/CognovisRestNotesService";
import { IntranetNotesType, INote,  CognovisRestNotesService, IntranetNotesStatus} from "../../../sources/openapi";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import { PermissionsHelper } from "../../../sources/modules/permissions-helper";

export default class ObjectNotesPortlet extends CognovisBasicPortlet {

    idPrefix = "onp";
    objectId:number;
    objectNotesModal:ObjectNotesModal;
    objectTitle = "";
    portletId:number;

    constructor(app:IJetApp, objectId:number, portletId?:number, objectTitle?:string) {
        super(app, {});
        this.objectId = objectId;
        this.portletId = portletId;
        this.objectTitle = objectTitle;
    }

    config():webix.ui.layoutConfig {
        return this.getCoreLayout();
    }

    init():void {
        super.init();
        this.loadObjectNotes();
        this.objectNotesModal = this.ui(ObjectNotesModal) as ObjectNotesModal;
    }

    loadObjectNotes():void {
        CognovisRestNotesService.getNote({
            objectId:this.objectId
        })
        .then(objectNotes => {
            const notes = {
                view: "layout",
                gravity:1,
                id:`${this.idPrefix}NotesList`,
                rows:[
                    {
                        view:"scrollview",
                        autoheight:true,
                        minHeight:400,
                        autowidth:true,
                        gravity:1,
                        body:{
                            rows:[
                                this.getObjectNotes(objectNotes)
                            ]
                        }
                    }
                ]
            };
            this.components = [notes];
            this.insertComponents();
        });
    }

    refreshNotes():void {
        const notesContainer = webix.$$(`${this.idPrefix}NotesContainer`) as webix.ui.layout;
        notesContainer.define("rows", []);
        notesContainer.reconstruct();
        CognovisRestNotesService.getNote({
            objectId:this.objectId
        })
        .then(objectNotes => {
            if(objectNotes.length > 0) {
                const objectNotesLayout:webix.ui.layoutConfig[] = [];
                objectNotes.map(objectNote => {
                    objectNotesLayout.push(this.getSingleNoteLayout(objectNote));
                });
                notesContainer.define("rows", objectNotesLayout);
                notesContainer.reconstruct();
            } else {
                const objectNotesLayout:webix.ui.layoutConfig[] = [];
                objectNotesLayout.push(this.getNoDataLayout());
                notesContainer.define("rows", objectNotesLayout);
                notesContainer.reconstruct();          
            }
        });
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            borderless:true,
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: this.objectTitle, 
                    borderless: true 
                },
                { 
                    view: "button", 
                    type: "icon", 
                    icon: "fal fa-plus", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true ,
                    click:() => {
                        this.objectNotesModal.openNotesModal("write", this.objectTitle, {} as unknown, "", "cke5", [{
                            name:"note_type",
                            hidden:false,
                            editable:true
                        },{
                            name:"note_status",
                            hidden:false,
                            editable:false,
                            value:IntranetNotesStatus.ACTIVE                
                        }], 
                        (inputedNote, noteType, noteStatus) => {
                            CognovisRestNotesService.postNote({
                                objectId:this.objectId,
                                requestBody:{
                                    note_type_id:noteType,
                                    note_status_id:noteStatus,
                                    note:inputedNote
                                }
                            })
                            .then(() => {
                                this.refreshNotes();
                            })
                            .catch(err => {
                                webix.alert({
                                    title: err.message,
                                    text: err.body.message,
                                    type: "alert-error",
                                    width: 500,
                                    css:"cog-remove-tasks-modal"
                                });
                            });
                        });
                    }
                }
            ]
        };
        return header;
    }

    getObjectNotes(objectNotes:INote[]):webix.ui.layoutConfig {
        const objectNotesLayout:webix.ui.layoutConfig[] = [];
        if(objectNotes.length > 0) {
            objectNotes.map(objectNote => {
                objectNotesLayout.push(this.getSingleNoteLayout(objectNote));
            });
        } else {
            objectNotesLayout.push(this.getNoDataLayout());
        }
        const layout:webix.ui.layoutConfig = {
            view:"layout",
            padding:20,
            paddingY:44,
            borderless:true,  
            id:`${this.idPrefix}NotesContainer`,
            rows:objectNotesLayout
        }
        return layout
    }

    getNoDataLayout():webix.ui.layoutConfig {
        const layout = {
            view:"layout",    
            borderless:true,     
            css:{
                "text-align":"center"
            },
            gravity:1, 
            rows:[
                {
                    template:`<span style='width:100%; text-align:center'>${i18nHelper.getTranslation("No data")}</span>`
                }
            ] 
        };
        return layout
    }

    getAvatarHelper
    
    getSingleNoteLayout(objectNote:INote):webix.ui.layoutConfig {
        const properFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
        const noteCreationDateFormatted = properFormat(new Date(objectNote.creation_date));
        //const indicatorClass = "project-team-activity-indicator";
        const layout = {
            view:"layout",    
            borderless:true,      
            cols:[
                {
                    view:"layout",
                    width:40,
                    cols:[
                        {
                            template: `<span style='color:${objectNote.note.status_color} !important;' class='webix_icon ${objectNote.note.type_icon}'></span>`,
                            borderless:true,
                            height:60,
                            width:60
                        },
                    ]
                },
                {
                    rows:[
                        {
                            view:"layout",
                            cols:[
                                {
                                    height:80,
                                    width:80,
                                    borderless:true,
                                    template:`<div class='anon-avatar-bg'><img style='width:60px; height:60px; border-radius:50%; margin-top:-3px;' src='${objectNote.creator.portrait_url}'/></div>`
                                },
                                {
                                    view:"layout",
                                    rows:[
                                        {
                                            template: i18nHelper.getTranslation("Added_by") + ` ${objectNote.creator?.user?.name}`,
                                            height:22,
                                            css:{
                                                "font-size":"0.9em"
                                            },
                                            borderless:true
                                        },
                                        {
                                            template:`on ${noteCreationDateFormatted}`,
                                            height:22,
                                            css:{
                                                "font-style":"italic",
                                                "font-size":"0.8em",
                                            },
                                            borderless:true
                                        },
                                        {
                                            template:`${objectNote.note_content}`,
                                            borderless:true,
                                            autoheight:true,
                                        },
                                        {
                                            view:"spacer",
                                            height:10
                                        }
                                    ]
                                },
                                { 
                                    view:"layout",
                                    width:44,
                                    height:60,
                                    rows:[
                                        {
                                            view:"layout",
                                            cols:[
                                                {
                                                    view: "button",
                                                    hidden:(PermissionsHelper.userHasWritePermission(objectNote.note["privileges"]) ? false : true), 
                                                    type: "icon", 
                                                    icon: "wxi-pencil", 
                                                    align: "right", 
                                                    css:"action-icon-grey",
                                                    width: 22,
                                                    height:25, 
                                                    borderless: true,
                                                    click:() => {
                                                        this.objectNotesModal.openNotesModal("write", this.objectTitle, objectNote as any, objectNote.note_content, "cke5", [{
                                                            name:"note_type",
                                                            hidden:false,
                                                            editable:true,
                                                            value:objectNote.note.type.id
                                                        },
                                                        {
                                                            name:"note_status",
                                                            hidden:false,
                                                            editable:true,
                                                            value:objectNote.note.status.id                   
                                                        }], 
                                                        (inputedNote, noteType:IntranetNotesType, noteStatus:IntranetNotesStatus) => {
                                                            CognovisRestNotesService.putNote({
                                                                noteId:objectNote.note.id,
                                                                requestBody:{
                                                                    note:inputedNote,
                                                                    note_status_id:noteStatus,
                                                                    note_type_id:noteType
                                                                }
                                                            })
                                                            .then(() => {
                                                                this.refreshNotes();
                                                            })
                                                            .catch(err => {
                                                                webix.alert({
                                                                    title: err.message,
                                                                    text: err.body.message,
                                                                    type: "alert-error",
                                                                    width: 500,
                                                                    css:"cog-remove-tasks-modal"
                                                                });
                                                            });
                                                        });
                                                    }            
                                                },
                                                {
                                                    view: "button", 
                                                    hidden:(PermissionsHelper.userHasWritePermission(objectNote.note["privileges"]) ? false : true),
                                                    type: "icon", 
                                                    icon: "wxi-trash", 
                                                    align: "right", 
                                                    width: 22,
                                                    height:25, 
                                                    css:"action-icon-grey",
                                                    borderless: true,
                                                    click:(e) => {
                                                        const confirmWarning = i18nHelper.getTranslation("lt_You_want_to_remove_th") + `<br/>`;
                                                        webix.confirm({
                                                            title: i18nHelper.getTranslation("any_are_you_sure_message"),
                                                            type: "confirm-warning",
                                                            text: confirmWarning,
                                                            width: 480
                                                        })
                                                        .then(() => {
                                                            CognovisRestNotesService.deleteNote({
                                                                noteId:objectNote.note.id
                                                            })
                                                            .then(() => {
                                                                this.refreshNotes();
                                                            })
                                                            .catch(err => {
                                                                webix.alert({
                                                                    title: err.message,
                                                                    text: err.body.message,
                                                                    type: "alert-error",
                                                                    width: 500,
                                                                    css:"cog-remove-tasks-modal"
                                                                });
                                                            });
                                                        });
                                                    }            
                                                },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                }
            ]
        };
        return layout
    }
    
}