import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import DynfieldCreationModal from "../../../sources/modules/cog-dynfields/dynfield-creation-modal";
import {CognovisRestCompanyService, ICompany, IntranetCompanyType} from "../../../sources/openapi";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CustomValidator } from "../../../sources/modules/custom-validators";


export default class DynCompanyCreationModal extends DynfieldCreationModal implements IBuildsDynfieldCreationModal {
    
    customRules = [
        {
            field:"company_name",
            rule:CustomValidator.atLeastThreeLetters
        }
    ];
    firstFocusFieldName = "company_name";
    customEvents:{[key: string]: string | number | Function}[] = [
        {
            field:"company_type_id",
            type:"onChange",
            action:(newCompanyTypeId:IntranetCompanyType) => {
                this.dynfieldsConfig.pages[0].objectSubtypeId = newCompanyTypeId;
                this.rebuildForms(this.dynfieldsConfig);
            }
        },
        {
            field:"company_name",
            type:"onBlur",
            action:function() {
                const companyName = this.getValue();
                if(companyName && companyName.length >= 3) {
                    CognovisRestCompanyService.getCompanies({
                        autocompleteQuery: companyName
                    })
                    .then(companies => {   
                        if(companies[0]) {
                            const extractedValues = this.$scope.extractValuesFromObject(companies[0]);
                            this.$scope.setFormValues(extractedValues);
                            this.$scope.makeReadonly();
                            this.$scope.updateButtonLabel("SaveButton",i18nHelper.getTranslation("Use"));
                        }
                    });
                }
            }
        }
    ];

    afterShow():void {
        this.setFormValues(this.defaultValues);
        this.bindCustomEvents();
    }

    submit():void {
        const root = this.getRoot() as webix.ui.layout;
        const form = root.queryView({view:"form"}) as webix.ui.form;
        if(!this.dynfieldsConfig.checkForRequiredFields || (form.validate({hidden:false,disabled:false}) && this.dynfieldsConfig.checkForRequiredFields)) {
            const values = form.getValues();
            this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
            console.log(values)
            CognovisRestCompanyService.postCompany({
                requestBody:{
                    company_name:values.company_name,
                    company_type_id:values.company_type_id,
                    company_status_id:values.company_status_id,
                    vat_type_id:values.vat_type_id,
                    vat_number:values.vat_number,
                    address_country_code:values.address_country_code,
                    address_city:values.address_city,
                    address_postal_code:values.address_postal_code,
                    address_line1:values.address_line1,
                    address_line2:values.address_line2,
                    phone:values.phone,
                    url:values.website,
                    payment_term_id:values.payment_term,
                    default_invoice_template_id:values.default_invoice_template,
                    default_quote_template_id:values.default_quote_template
                }
            })
            .then(newCompanyObj => {
                this.cognovisPleaseWaitWindow.hide();
                this.closeModal();
                if(this.customAfterSubmitAction) {
                    this.customAfterSubmitAction(newCompanyObj);
                } else {
                    this.afterSubmit(newCompanyObj);
                } 
            });
        }
    }

    afterSubmit(newCompany:ICompany):void { 
        if(newCompany) {
            CognovisNavigator.goToObjectDetails(newCompany.company.id, "im_company");
        }
    }

    setFormValues(values:{[key: string]: string | number}):void {
        const root = this.getRoot() as webix.ui.layout;
        const form = root.queryView({view:"form"}) as webix.ui.form;
        if(this.dynfieldsConfig.pages[0]?.objectSubtypeId) {
            values.company_type_id = this.dynfieldsConfig.pages[0]?.objectSubtypeId;
        }
        form.setValues(values);
    }
}