/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IError } from '../models/IError';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IRelationship } from '../models/IRelationship';
import type { IRelationshipBody } from '../models/IRelationshipBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestRelationshipService {

    /**
     * Return a list of relationships. If no filter provided will get all the relationships of the current user
     * @returns IRelationship Generic Response description for cog_rest::get::relationships
     * @throws ApiError
     */
    public static getRelationships({
        objectIdOne,
        objectIdTwo,
        relType,
        relId,
    }: {
        /**
         * object_id of the primary object in the relationship
         */
        objectIdOne?: IntegerInt32,
        /**
         * object_id of the secondary object in the relationship
         */
        objectIdTwo?: IntegerInt32,
        /**
         * Type of the relationship
         */
        relType?: string,
        /**
         * In case we look for a specific relationship
         */
        relId?: IntegerInt32,
    }): CancelablePromise<Array<IRelationship>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/relationships',
            query: {
                'object_id_one': objectIdOne,
                'object_id_two': objectIdTwo,
                'rel_type': relType,
                'rel_id': relId,
            },
        });
    }

    /**
     * Relationship
     * Adds a new relationship
     * @returns IRelationship Generic Response description for cog_rest::post::relationship
     * @throws ApiError
     */
    public static postRelationship({
        requestBody,
    }: {
        /**
         * Infomration about the relationship we want to add.
         */
        requestBody: IRelationshipBody,
    }): CancelablePromise<IRelationship> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/relationship',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Relationship
     * Remove a relationship
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteRelationship({
        relId,
    }: {
        /**
         * relationship we want to remove
         */
        relId: IntegerInt32,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/relationship',
            query: {
                'rel_id': relId,
            },
        });
    }

}
