import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { singleton } from "tsyringe";
import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import { IntranetProjectStatus, ITransProject, WebixPortalTranslationService, ICategory} from "../../../sources/openapi";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";

@singleton()
export class ProjectsTableBuilder  {

    idPrefix = "po";
    additionalModal:unknown;
    usePmFilterByDefault = true;

    buildProjectsTable(tableId:string,visibleColumns:string[], projectStatusesIds:IntranetProjectStatus[], filtersOff = false, excludedProjectsStatuses?:IntranetProjectStatus[], callbackAction?:(number)=>void, additionalParams?:{[key: string]: number}[]):webix.ui.datatableConfig {
        let params = {
            projectStatusId: projectStatusesIds
        };
        if(additionalParams) {
            additionalParams.map(param => {
                params[Object.keys(param)[0]] = Object.values(param)[0];
            });
        }
        const table = {
            view: "datatable",
            css:"cog-clickable-table",
            id:`${this.idPrefix}${tableId}`,
            entityColumn:"project.id",
            hover:"cog-datatable-hover",
            tooltip:true,
            columns:this.getProjectTableColumns(visibleColumns, filtersOff),
            ready() {
                (webix.ui({
                    view:"contextmenu", 
                    localId:`${this.idPrefix}ProjectTableContextMenu`,
                    data: 
                    [
                        i18nHelper.getTranslation("Open_in_new_tab")
                    ],
                    on: {
                        onMenuItemClick:function() {
                            const mainContext = this.getContext();
                            const project = mainContext.obj.getItem(mainContext.id.row);
                            const projectId = project.project.id;
                            const url = `#!/project-info?project_id=${projectId}`;
                            // eslint-disable-next-line security/detect-non-literal-fs-filename
                            window.open(url, '_blank').focus();
                        }
                    }
                }) as webix.ui.contextmenu).attachTo(this);
            },
            url: () => {
                return WebixPortalTranslationService.getTransProjects(params)
                .then(response => {
                    response.map((project:ITransProject & {formatted_end_date:string, formatted_contact_again_date:string}) => {
                        const endDateObj = new Date(project.end_date);
                        const endDateAsString = `${endDateObj.getFullYear()}-${(endDateObj.getMonth()+1)}-${endDateObj.getDate()}`;
                        project.formatted_end_date = endDateAsString;
                        const contactAgainObj = new Date(project.contact_again_date);
                        const contactAgainDateAsString = `${contactAgainObj.getFullYear()}-${(contactAgainObj.getMonth()+1)}-${contactAgainObj.getDate()}`;
                        project.formatted_contact_again_date = contactAgainDateAsString;
                    });
                    if(callbackAction) {
                        callbackAction(response.length);
                    }
                    // Little bit hacky way to filter projects in case we have ONLY "Potential status"
                    // That is because we don't want to display "Inquring" in two seperate tabels
                    // (Second tab for PM)
                    if(excludedProjectsStatuses && excludedProjectsStatuses.length > 0) {
                        response = response.filter(project => excludedProjectsStatuses.indexOf(project.project_status.id) === -1);
                    } 
                    const currentUserScreenName = UserStateManager.getCurrentUserData().screen_name;
                    if(currentUserScreenName) {
                        const totalForThisPm = response.filter(project => project.project_lead.screen_name === currentUserScreenName).length;
                        if(totalForThisPm > 0) {
                            this.usePmFilterByDefault = true;
                        } else {
                            this.usePmFilterByDefault = false;
                        }
                    }
                    return response;
                });
            },
            on:{
                onAfterLoad:() => {      
                    const table = webix.$$(`${this.idPrefix}${tableId}`) as webix.ui.datatable; 
                    const currentUserScreenName = UserStateManager.getCurrentUserData().screen_name;
                    if(currentUserScreenName) {
                        const pmFilter = table.getFilter("project_manager");
                        if(pmFilter && this.usePmFilterByDefault) {
                            table.getFilter("project_manager").value = currentUserScreenName
                            table.filter('project_manager', currentUserScreenName , true);    
                        }
                    };
                    if(table.serialize().length === 0) {
                        (table as webix.ui.datatable & {showOverlay:(message:string) => void}).showOverlay(i18nHelper.getTranslation("No data"));
                    } else {
                        (table as webix.ui.datatable & {showOverlay:(message:string) => void}).hideOverlay();
                    }         
                },
                onAfterFilter:() => {
                    const table = webix.$$(`${this.idPrefix}${tableId}`) as webix.ui.datatable; 
                    if(table.serialize().length === 0) {
                        (table as webix.ui.datatable & {showOverlay:(message:string) => void}).showOverlay(i18nHelper.getTranslation("No data"));
                    } else {
                        (table as webix.ui.datatable & {showOverlay:(message:string) => void}).hideOverlay();
                    }  
                },
                onItemClick:(row) => {
                    const table = webix.$$(`${this.idPrefix}${tableId}`) as webix.ui.datatable;
                    const item = table.getItem(row);
                    const projectId = item.project.id;
                    switch(row.column) {
                        case "action-details":
                            this.openProjectDetails(projectId);
                            break;
                        case "action-reject":
                            if(item.cost_quotes_cache > 0) {
                                this.attemptToUpdateProjectStatus(item, IntranetProjectStatus.DECLINED);
                            }
                            break;
                        case "action-accept":
                            if(item.cost_quotes_cache > 0) {
                                this.attemptToUpdateProjectStatus(item, IntranetProjectStatus.OPEN);
                            }
                            break;
                        case "action-financial-overview":
                            //this.leftNavigationService.toggleProjectDetails(projectId, `main/financial-overview.financial-overview?project_id=${projectId}`);
                            break;
                        case "action-contact-again":
                            webix["contactAgainModal"].openModal(item,() => {
                                this.refreshTable();
                            });
                            break;
                        default:
                            this.openProjectDetails(projectId);
                            break;
                    }
                }
            }
        };
        return table;
    }

    getProjectTableColumns(visibleColumns:string[], filtersOff = false):webix.ui.layoutConfig[] {
        const columns = [                    
            { 
                id: "type", 
                map: "#project_type.name#", 
                sort:"text",
                width:80,
                header:[ i18nHelper.getTranslation("Type"), { content:"richSelectFilter"}],
                tooltip:(obj) => {
                    return obj.project_type.name
                },
                template: (obj) => {
                    const projectTypes:ICategory[] = webix["app"]["categories"]["projectTypes"];
                    let icon = "";
                    let iconColor = "#59a8c2";
                    if(projectTypes) {
                        const projectType = projectTypes.find(category => category.category_id === obj.project_type.id);
                        if(projectType) {
                            icon = projectType["aux_html2"];
                            const projectStatuses:ICategory[] = webix["app"]["categories"]["projectStatuses"];
                            if(projectStatuses.length > 0) {
                                const projectStatus = projectStatuses.find(category => category.category_id === obj.project_status.id);
                                if(projectStatus) {
                                    iconColor = projectStatus["aux_html2"];
                                }
                            }
                        }
                    }
                    return `<span style='color:${iconColor}' class='webix_icon table-action-icon ${icon}'></span>`;
                }
            },
            {
                id: "project_status",
                header: [i18nHelper.getTranslation("Project_status"), { content:"selectFilter"}], 
                map: "#project_status.name#",
                sort: "text",
                maxwidth: 110
            },
            { 
                id: "project_nr", 
                header: [i18nHelper.getTranslation("Project_nr"), { content:"textFilter"}], 
                fillspace: true,
                sort:"text",
                maxWidth:110
            },
            { 
                id: "project_name", 
                header: [i18nHelper.getTranslation("Project_name"), { content:"textFilter"}], 
                fillspace: true,
                map:"#project.name#",
                sort:"text",
            },
            { 
                id: "dt_source_language", 
                map: "#source_language.name#", 
                sort:"text",
                header: [i18nHelper.getTranslation("Source_language"),  { content:"selectFilter"}],
                maxWidth:120
            },
            {
                id: "target_language",
                header:[ 
                    i18nHelper.getTranslation("Target_language"), 
                    { 
                        content:"multiSelectFilter",
                        options:() => {
                            return CognovisCategory.getCategory("Intranet Translation Language");
                        },
                        compare:function(item, value) {  
                            let result = false;
                            const onlyIdsArr = item.map(item => item.id);
                            const arr = Object.keys(value);
                            const countSelectedLanguages = arr.length;
                            let countMatchedLangs = 0;
                            arr.map(selectedLanguage => {
                                if(onlyIdsArr.indexOf(Number(selectedLanguage)) > -1) {
                                    countMatchedLangs ++;
                                }
                            });
                            if(countSelectedLanguages === countMatchedLangs) {
                                result = true;
                            }
                            return result
                        }
                    }
                ],
                sort:"text",
                width:80,
                tooltip:(obj) => {
                    if (obj && obj.target_language) {
                        const targetLanguages = obj.target_language;
                        const langsArr = [];
                        targetLanguages.map((targetLanguage) => langsArr.push(targetLanguage.name));
                        return langsArr.join("</br>");
                    } else {
                        return "";
                    }
                },
                template: (obj) => {
                    if (obj && obj.target_language) {
                        const targetLanguages = obj.target_language;
                        const langsArr = [];
                        targetLanguages.map((targetLanguage) => langsArr.push(targetLanguage.name));
                        if(langsArr.length > 1) {
                            return `${langsArr[0]}...`;
                        } else {
                            return langsArr[0];
                        }
                        
                    } else {
                        return "";
                    }
                },
            },
            { 
                id: "customer", 
                map: "#company.name#", 
                sort:"text",
                fillspace:true,
                header: [i18nHelper.getTranslation("Customer"),  { content:"selectFilter"}]
            },
            { 
                id: "project_manager", 
                map: "#project_lead?.screen_name#", 
                header: [i18nHelper.getTranslation("Manager"),  { content:"selectFilter"}],
                sort:"text",
                tooltip:(obj) => {
                    return obj.project_lead.user.name
                }
            },
            { 
                id: "company_contact", 
                map: "#company_contact?.name#", 
                sort:"text",
                fillspace: true,
                header: [i18nHelper.getTranslation("Requested_by"),  { content:"selectFilter"}],
            },
            // { 
            //     id: "project_status", 
            //     map:"#project_status?.name#",
            //     sort:"text",
            //     header: [i18nHelper.getTranslation("Status"),  { content:"selectFilter"}],
            // },
            { 
                id: "cost_quotes_cache", 
                map:"#price#",
                sort:PriceHelper.sortDatatableByFormattedPrice,
                width:140,
                css:"price-display",
                header: [{text:i18nHelper.getTranslation("Price")},{ content:"numberFilter"}],
                template:function(obj) {
                    return PriceHelper.formatPrice(obj.price);
                }
            },
            { 
                id: "cost_invoices_cache", 
                map:"#invoice_amount#",
                sort:PriceHelper.sortDatatableByFormattedPrice,
                width:140,
                css:"price-display",
                header: [{text:i18nHelper.getTranslation("Price")},{ content:"numberFilter"}],
                template:function(obj) {
                    return PriceHelper.formatPrice(obj.invoice_amount);
                }
            },
            { 
                id: "deadline", 
                map:"(date)#formatted_end_date#",
                header: [{text:i18nHelper.getTranslation("Deadline")}, {content:"dateFilter"}],
                width:140,
                sort:"date",
                template:function(obj) {
                    if(obj.end_date) {
                        const myFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
                        const formattedDate = myFormat(new Date(obj.end_date));
                        return formattedDate
                    } else {
                        return ""
                    }
                }
            },
            { 
                id: "last_contacted", 
                map:"(date)#formatted_contact_again_date",
                css:"price-display",
                sort:"date",
                header: [{text:i18nHelper.getTranslation("contact_again")}, {content:"dateFilter"}],
                width:140,
                template:function(obj) {
                    if(obj.contact_again_date) {
                        const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
                        const formattedDate = myFormat(new Date(obj.contact_again_date));
                        return formattedDate
                    } else {
                        return ""
                    }
                }
            },
            {
                width:42,
                id:"action-details",
                header:" ",
                tooltip:i18nHelper.getTranslation("Go_to_project_details"),
                template:() => {
                    return `<span style='color:#59a8c2' class='webix_icon table-action-icon far fa-arrow-alt-circle-right'></span>`;
                }
            },
            {
                width:42,
                id:"action-accept",
                header:" ",
                tooltip:i18nHelper.getTranslation("Accept_project"),
                template:(obj) => {
                    // const status = obj.project_status.id;
                    // if(status !== IntranetProjectStatus.INQUIRING) {
                    //     return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-check-circle accept'></span>`;
                    // } else {
                    //     return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-check-circle disabled'></span>`;
                    // }
                    const price = Number(obj.cost_quotes_cache);
                    if(price > 0) {
                        return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-check-circle accept'></span>`;
                    } else {
                        return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-check-circle disabled'></span>`;
                    }
                }
            },
            {
                width:42,
                id:"action-reject",
                header:" ",
                tooltip:i18nHelper.getTranslation("Reject_project"),
                template:(obj) => {
                    // const status = obj.project_status.id;
                    // if(status !== IntranetProjectStatus.INQUIRING) {
                    //     return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-close-circle reject'></span>`;
                    // } else {
                    //     return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-close-circle disabled'></span>`;
                    // }
                    const price = Number(obj.cost_quotes_cache);
                    if(price > 0) {
                        return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-close-circle reject'></span>`;
                    } else {
                        return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-close-circle disabled'></span>`;
                    }
                }
            },
            {
                width:42,
                id:"action-contact-again",
                header:" ",
                tooltip:i18nHelper.getTranslation("Update_contact_again_date"),
                template:() => {
                    return `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-calendar-edit'></span>`;
                }
            },
            {
                width:42,
                id:"action-financial-overview",
                tooltip:i18nHelper.getTranslation("Go_to_financial_overview"),
                header:" ",
                template:() => {
                    return `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-file-document'></span>`;

                }
            },
        ];
        const filteredColumns = columns.filter(column => visibleColumns.indexOf(column.id) > -1);
        const sortedAndFilteredColumns = [];
        visibleColumns.map(column => {
            const properCol = filteredColumns.find(col => col.id === column);
            if(properCol) {
                sortedAndFilteredColumns.push(properCol);
            }
        });
        if(filtersOff) {
            sortedAndFilteredColumns.map(column => {
                column.header = column.header[0];
            });
        }
        return sortedAndFilteredColumns
    }

    openProjectDetails(projectId:number):void {
        const currentUrl = window.location.href;
        if(currentUrl.indexOf("project-info?project_id") > -1) {
            const currentUrlSplitted = currentUrl.split("project_id=");
            const newProjectUrl = `${currentUrlSplitted[0]}project_id=${projectId}`;
            window.location.href = newProjectUrl
            window.location.reload();
        } else {
            CognovisNavigator.goToObjectDetails(projectId, "im_project");
        }
    }

    attemptToUpdateProjectStatus(project:ITransProject, newProjectStatus:IntranetProjectStatus):void {
        let declineOrAccept = "";
        if(newProjectStatus === IntranetProjectStatus.OPEN) {
            declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_accept_project?");
        } 
        if(newProjectStatus === IntranetProjectStatus.DECLINED) {
            declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_decline_project?");
        }
        webix.confirm({
            title: project.project.name,
            type: "confirm-warning",
            text: declineOrAccept,
            width: 480
        })
        .then(() => {
            this.updateProjectStatus(project, newProjectStatus)
        }); 
    }

    updateProjectStatus(project:ITransProject, newProjectStatus:IntranetProjectStatus):void {
        WebixPortalTranslationService.putTransProject({
            projectId:project.project.id,
            requestBody:{
                project_status_id:newProjectStatus as number,
                target_language_ids:project.target_language.map(tl => tl.id),
                source_language_id:project.source_language.id,
                company_id:project.company.id,
                complexity_type_id:project.complexity_type.id
            }
        })
        .then(() => {
            this.refreshTable();
        });
    }

    refreshTable():void {
        window.location.reload();
    }
}