import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisRestMailService, IMail } from "../../../sources/openapi";
import { IJetApp, JetView } from "webix-jet";
import SendMailModal from '../../../sources/views/modals/send-mail-modal'
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";
import { DatetimeHelper } from "../../../sources/modules/datetime-helpers/datetime-helpers";


export default class MessagesPortlet extends CognovisBasicPortlet {

    idPrefix = "mp";
    params:{[key: string]: number}
    title:string;
    sendEmailModal:SendMailModal;
    messages:IMail[];
    portletId:number;

    constructor(app:IJetApp, params:{[key: string]: number}, portletId?:number, title?:string) {
        super(app, {});
        this.params = params;
        this.portletId = portletId;
        if(title) {
            this.title = title;
        } else {
            this.title = i18nHelper.getTranslation("Mail Messages");
        }
    }

    config():webix.ui.layoutConfig {
        return this.getCoreLayout();
    }

    init():void {
        super.init();
        this.getMessages()
        .then(messages => {
            this.messages = messages;
            const layout = {
                view:"layout",
                rows:[
                    {
                        view:"layout",
                        autoheight:true,
                        minHeight:400,
                        autowidth:true,
                        gravity:1,
                        rows:[this.getMailsDatatable(messages)]
                    }
                ]
            };
            this.components = [layout];
            this.insertComponents();
        });
    }


    getMessages():Promise<IMail[]> {
        let params = {
            limit:50,
            ...this.params
        }
        return CognovisRestMailService.getMessage(params)
        .then(messages => {
            messages.map(message => {
                const sentDateObj = new Date(message.sent_date);
                message["formatted_sent_date"] = `${sentDateObj.getFullYear()}-${(sentDateObj.getMonth()+1)}-${sentDateObj.getDate()} ${sentDateObj.getHours()}:${sentDateObj.getMinutes()}`;
            });
            return messages
        }); 
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            height:44,
            css: "cog-box-header", 
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: this.title, 
                    borderless: true 
                }
            ]
        };
        return header;
    }

    getMailsDatatable(messages:IMail[]):webix.ui.datatable {
        const datatable = {
            localId:`${this.idPrefix}MessagesTable`,
            view:"datatable",
            tooltip:true,
            borderless:true,
            columns:[
                {
                    id:"sender",
                    sort: "text",
                    header:[ i18nHelper.getTranslation("Sender"),  { content: "multiSelectFilter" }],
                    map:"#sender.person.name#",
                    tooltip: "",
                    fillspace: true,
                    editor: "text"
                },
                {
                    id: "recipients",
                    sort: "text",
                    header:[ 
                        i18nHelper.getTranslation("Recipients"), 
                        { 
                            content:"multiSelectFilter",
                            options:function() {
                                const recipientsArr = [];
                                messages.map(message => {
                                    if(message.recipients != null) {
                                        message.recipients.map(recipient => {
                                            if(recipientsArr.indexOf(recipient.person.name) === -1) {
                                                recipientsArr.push(recipient.person.name)
                                            }
                                        });
                                    };
                                });
                                return recipientsArr
                            },
                            compare:function(items, value) {  
                                let result = false;
                                const arr = Object.keys(value);
                                arr.map(selectedName => {
                                    items.map(item => {
                                        if(selectedName === item.person.name) {
                                            result = true;
                                        }
                                    });
                                });
                                return result
                            }
                        }
                    ],
                    fillspace: true,
                    template: (obj:IMail) => {
                        const recipientsArr = [];
                        if(obj.recipients != null) {
                            obj.recipients.map(recipient => recipientsArr.push(`${recipient.person.name}`));
                        }
                        return recipientsArr.join(",");
                    },
                    tooltip:(obj:IMail) => {
                        const recipientsArr = [];
                        obj.recipients.map(recipient => recipientsArr.push(recipient.person.name));
                        return recipientsArr.join("<br/>");                   
                    },
                },
                {
                    id:"subject",
                    sort: "text",
                    header:[ i18nHelper.getTranslation("Subject"), { content: "textFilter" }],
                    map:"#subject#",
                    fillspace: true,
                    editor: "text"
                },
                {
                    id:"context",
                    // map:"#context.name#",
                    sort: "text",
                    header:[ i18nHelper.getTranslation("Context"), { content: "multiSelectFilter" }],
                    fillspace: true,
                    template: (obj:IMail) => {
                        if (obj.context != null) {
                            return obj.context.name                            
                        } else {
                            return ""
                        }
                    }
                },
                {
                    id:"sent_date",
                    fillspace: true,
                    map:"(date)#formatted_sent_date#",
                    sort: "date",
                    header:[ i18nHelper.getTranslation("Sent"), {content:"dateFilter"}],
                    template:function(obj) {
                        const date = new Date(obj.sent_date);
                        const properFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
                        return properFormat(date)
                    }
                },
                {
                    view: "template",
                    width: 40,
                    tooltip:false,
                    template: function (obj:IMail) {
                        return `<i style='margin-left:-4px;' class='fas fa-eye'></i>`
                    }
                },
                {  
                    name:"attachment-indicator",
                    width:40,
                    tooltip:(obj:IMail) => {
                        if(obj.cr_files) {
                            const tooltipHeader = i18nHelper.getTranslation("Attachments available");
                            const attachmentsNames = obj.cr_files.map(mail => mail.name).join("<br/>");
                            const tooltip = `<b>${tooltipHeader}:</b><br/>${attachmentsNames}`
                            return tooltip
                        } else {
                            return i18nHelper.getTranslation("No attachments available");
                        }
                    },
                    template:(obj:IMail) => {
                        if(obj.cr_files) {
                            return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-paperclip'></span>`; 
                        } else {
                            return `<span style='color:grey' class='webix_icon fas fa-paperclip greyedout'></span>`;
                        }
                    }
                }
            ],
            data:messages,
            on:{
                onAfterRender:function() {
                    if(this.serialize().length > 0) {
                        this.markSorting("sent_date", "asc");
                    } else {
                        (this as webix.ui.datatable & {showOverlay:(message:string) => void}).showOverlay(i18nHelper.getTranslation("No data"));
                    }
                }
            },
            onClick:{
                "fa-eye":(event,row) => {
                    this.displayMail(row);
                }
                // "fa-paperclip":(event,row) => {
                //     this.displayMail(row);
                // }
            }
        };
        return datatable as unknown as webix.ui.datatable
    }

    displayMail(row:string):void {
        if(!this.sendEmailModal) {
            this.sendEmailModal = this.ui(SendMailModal) as SendMailModal;
        }
        const table = this.$$(`${this.idPrefix}MessagesTable`) as webix.ui.datatable;
        const item:IMail = table.getItem(row);
        const messageId = item.message_id;
        const messageObj = this.messages.find(message => message.message_id === messageId);
        const recipientsOptions = [];
        messageObj.recipients.map(recipient => {
            recipientsOptions.push({
                id:recipient.person.id,
                value:recipient.person.name
            });
        });
        const recipientIds = messageObj.recipients.map(recipient => recipient.person.id);
        const fields = [
            {
                name:"sender_email",
                editable:false,
                defaultValue:messageObj.sender.person.id,
                visible:true,
                options:[{
                    id:messageObj.sender.person.id,
                    value:messageObj.sender.person.name
                }]
            },
            {
                name:"cc_email",
                visible:false
            },
            {
                name:"subject",
                visible:true,
                editable:false,
                autofocus:true,
                defaultValue:messageObj.subject
            },
            {
                name:"recipient_email",
                editable:false,
                defaultValue:recipientIds,
                visible:true,
                options:recipientsOptions             
            },
            {
                name:"message_body",
                defaultValue:messageObj.body,
                visible:true,
                editable:true
            }
        ];
        this.sendEmailModal.openModal(false,Number(item.message_id), fields as [], true, item.cr_files);
    }

}