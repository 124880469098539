import * as usermanager from "@xbs/usermanager";
import { CognovisRestDynamicService } from "../../../../sources/openapi/services/CognovisRestDynamicService";

export default class UmCustomUsersForm extends usermanager.views["users/form"] {

    idPrefix = "umcuf";

    config() {
        const ui = super.config();
        const deleteButton = ui?.rows[0]?.cols[1];
        const saveButton = ui?.rows[0]?.cols[2];
        deleteButton.disabled = true;
        saveButton.disabled = true;
        ui.rows[1].rows.forEach(existingField => {
            existingField.disabled = true;
        });
        return ui
    }

    config_tried() {
        const ui = super.config();
        return CognovisRestDynamicService.getDynamicAttributes({
            pageUrl: "account-info",
            objectType: "person"
        })
        .then(res => {
            //ui.rows[1].rows = [];
            ui.rows[1].rows.forEach(existingField => {
                existingField.hidden = true;
            });
            //ui.rows[1].rows = [...ui.rows[1].rows[3], ...ui.rows[1].rows[6]];
            res.map(dynfield => {
                ui.rows[1].rows.push({
                    view: "text",
                    localId: `${this.idPrefix}${dynfield.attribute.id}`,
                    inputWidth: 615,
                    labelWidth: 220,
                    name: dynfield.attribute.name,
                    label: dynfield.display_name,
                });
            });
            return ui;
        })

    }
    init($view) {
        // call default logic
        super.init();
        // custom logic below
        const form = $view.queryView('form');
        console.log(form)
        this.hideOriginalComponents()
    }

    hideOriginalComponents() {
    
    }
}