import BaseView from "../baseview";
import { IntranetProjectStatus } from "../../openapi/index";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { container } from "tsyringe";
import { ProjectsOverviewTopTabs } from "./top-tabs";
import { UserProfile } from "../../../sources/modules/cognovis-profile/profiles-types";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { ProjectsTableBuilder } from "./projects-table-builder";
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";

export default class Request extends BaseView {

    projectsTableBuilder:ProjectsTableBuilder;
    projectOverviewTobTabs: ProjectsOverviewTopTabs;
    idPrefix = "po";

    config(): webix.ui.scrollviewConfig {
        this.projectOverviewTobTabs = container.resolve(ProjectsOverviewTopTabs);
        this.projectsTableBuilder = container.resolve(ProjectsTableBuilder);
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                padding:13,
                css:"cog-content",
                rows: [
                    this.projectOverviewTobTabs.getTopTabs("requested-projects"),
                    {
                        view: "layout",
                        rows:[
                            {
                                view:"template",
                                type:"header",
                                css: "cog-box-header-no-border", 
                                template:i18nHelper.getTranslation("New_requestes_for_quote"),
                            },
                            this.getRequestedProjectsTable()
                        ]
                    },
                ],
            },
        };
    }

    getRequestedProjectsTable():webix.ui.datatableConfig  {
        if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
            return this.getRequestedProjectsTableForPm();
        }
        if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
            return this.getRequestedProjectsTableForCustomer();
        }
    }

    getRequestedProjectsTableForPm():webix.ui.datatableConfig {
        const requestedProjectsTableColumns = [
            "type", "project_nr", "customer", "project_name", "source_language", "target_language", "project_manager", "company_contact", "deadline", "cost_quotes_cache", "action-accept", "action-reject"
        ];
        const requestedProjectsStatusesIds = [IntranetProjectStatus.INQUIRING];
        const requestedProjectsTable = this.projectsTableBuilder.buildProjectsTable("RequestedProjects", requestedProjectsTableColumns, requestedProjectsStatusesIds, false, [], (numberOfResults:number) => {
            this.projectOverviewTobTabs.updateRfqBadge(numberOfResults);
        });
        return requestedProjectsTable
    }

    getRequestedProjectsTableForCustomer():webix.ui.datatableConfig {
        const requestedProjectsTableColumns = [
            "type", "source_language", "target_language",  "company_contact", "deadline",  "cost_quotes_cache", "action-accept", "action-reject"
        ];
        const requestedProjectsStatusesIds = [IntranetProjectStatus.POTENTIAL];
        const requestedProjectsTable = this.projectsTableBuilder.buildProjectsTable("RequestedProjects", requestedProjectsTableColumns, requestedProjectsStatusesIds, true, [], (numberOfResults:number) => {
            this.projectOverviewTobTabs.updateRfqBadge(numberOfResults);
        });
        return requestedProjectsTable
    }

    init():void {
        if(CognovisProfile.isUserInGivenGroup(UserProfile.FREELANCER)) {
            CognovisNavigator.navigateTo("/main/assignments.my-assignments");
        }
       
    }
    
}
