import { container } from "tsyringe";
import { UserProfile } from "../modules/cognovis-profile/profiles-types";
import CognovisProfile from "../modules/cognovis-profile/cognovis-profile";
import HtmlTemplateHelper from "../modules/html-helpers/html-template-helper";
import { UrlHelper } from "../modules/url-helpers/url-helper";
import BaseView from "./baseview";
import { IJetApp } from "webix-jet";
import { IJetConfig } from "webix-jet/dist/types/interfaces";
import {
    CognovisRestCompanyService,
    CognovisRestRelationshipService,
    CognovisRestReportService,
    CognovisRestService,
    CognovisRestTimesheetService,
    ICategoryObject,
    ICompanyContact,
    IntranetReportType,
    IWebixNotification,
    WebixPortalAssignmentService,
    WebixPortalNotificationService,
    WebixPortalProjectService,
} from "../openapi/index";
import { WebixHelpers } from "../../sources/modules/webix-helpers/webix-helpers";
import { CognovisNavigator } from "../../sources/modules/cognovis-navigator/cognovis-navigator";
import CognovisFileUploaderModal from "../../sources/modules/file-helpers/cognovis-file-uploader-modal";
import { i18nHelper } from "../../sources/modules/i18n-helper/i18n-helper";
import SearchComponent from "./search/search-component";
import SendMailModal from "../../sources/views/modals/send-mail-modal"
import LoggedHoursModal from "./project-info/record-time/logged-hours-modal";
import { UserStateManager } from "../services/user-state-manager";
import i18TranslationModal from "./i18n-translation-modal";
import NotificationsModal from "./modals/notifications-modal";
import Sidebar from "./sidebar";
import { config } from "../config";

export default class MainView extends BaseView {

    idPrefix = "ml";
    urlHelper: UrlHelper;
    htmlTemplateHelper: HtmlTemplateHelper;
    leftNavigation: ILeftNavigationItem[] = [];
    webixHelpers: WebixHelpers;
    sidebarLogo:string;
    favicon:string;
    fileUploaderModal:CognovisFileUploaderModal;
    sendMailModal:SendMailModal;
    loggedHoursModal:LoggedHoursModal;
    i18nTranslationModal:i18TranslationModal;
    notificationsModal:NotificationsModal;

    constructor(app: IJetApp, name: string, config: IJetConfig) {
        super(app, name, config);
        this.htmlTemplateHelper = container.resolve(HtmlTemplateHelper);
        this.urlHelper = container.resolve(UrlHelper);
        this.webixHelpers = container.resolve(WebixHelpers);
    }


    config(): Promise<webix.ui.layoutConfig> {
        // creating left navigation by calling endpoint 'menu_items'
        return (
                CognovisRestService.getImCategory({categoryType:"Intranet Project Type"})
                .then(response => {
                    this.app["projectTypes"] = response;
                    webix["app"] = {
                        categories:{
                            projectTypes:response
                        }
                    };
                    CognovisRestService.getImCategory({categoryType:"Intranet Project Status"})
                    .then(response => {
                        this.app["projectStatuses"] = response;
                        webix["app"]["categories"]["projectStatuses"] = response;
                    });
                })
                // generating user switcher (this works only if user is an admin)
                .then(() => {
                    return this.getUserSwitcher()
                    .then(generatedUserSwitcherCombo => generatedUserSwitcherCombo);
                })
                .then(generatedUserSwitcherCombo => {
                    return CognovisRestCompanyService.getInternalCompany()
                    .then(internalCompany => {
                        this.favicon = internalCompany.favicon_url;
                        this.sidebarLogo = internalCompany.logo_url;
                        let currentUserId = webix.storage.local.get("wjet_user").user_id;
                        if(webix.storage.session.get("cognovis_faked_user_data")) {
                            currentUserId = webix.storage.session.get("cognovis_faked_user_data").user_id;
                        }
                        return CognovisRestCompanyService.getCompanyContacts({
                            contactIds:[currentUserId]
                        })
                        .then(companyContacts => {
                            this.setBrowserIcon(this.favicon);
                            if(companyContacts[0]) {
                                const loggedUserObject = companyContacts[0];
                                const loggedUserCompanyId = companyContacts[0].company.id;
                                webix.storage.session.put("logged_user_data", loggedUserObject);
                                webix.storage.local.put(
                                    "logged_user_company_id",
                                    loggedUserCompanyId
                                );        
                                const logout = this.getLogoutButton();
                                return CognovisRestService.getMenuItems({
                                    packageName: 'webix-portal',
                                    parentMenuLabel: 'webix_main_menu'
                                })
                                .then(leftNavigationItemsRes => {
                                    const sidebar = new Sidebar(this.app,leftNavigationItemsRes as ILeftNavigationItem[]);
                                    const swaSwitcher = generatedUserSwitcherCombo;
                                    const header = this.getHeader(logout, swaSwitcher);
                                    // main ui
                                    const ui = {
                                        view:"layout",
                                        id:"mainbody",
                                        rows: [
                                            header,
                                            {
                                                view:"layout",
                                                css:"cog-mainbody",
                                                cols: [
                                                    sidebar,
                                                    {
                                                        $subview: true,
                                                    },
                                                ],
                                            },
                                        ],
                                    };
                                    return ui;
                                })
                            }
                        })

                    })
                })
        );
    }

    getSidebar(): webix.ui.sidebarConfig {
        let collapsed = false;
        const savedCollapsed = webix.storage.local.get("left_navigation_collapsed_state");
        if(savedCollapsed) {
            collapsed = savedCollapsed;
        }
        const sidebar = {
            localId: "menu",
            id: "mainSidebar",
            gravity: 1.6,
            view: "sidebar",
            collapsed:collapsed,
            width: 250,
            data: this.leftNavigation,
            on: {
                onItemClick:(id:string, e:Event) => {
                    if(id.indexOf("http") > -1) {
                        e.preventDefault();
                        window.open(id, '_blank');
                        return false;
                    }
                }
            }
        };
        return sidebar;
    }

    getAvatarUploader():webix.ui.uploaderConfig {
        const uploader = {
            view: "uploader",
            localId: `AvatarUploader`,
            name: "files",
            autosend: false,
            width:1,
            upload: this.webixHelpers.getUrlWithApiKey("upload_cr_file"),
            value:i18nHelper.getTranslation("Add file"),
            formData: null,
            datatype: "json",
            on: {
                onAfterFileAdd:() => {
                    // First we check for existing avatar->user relationship
                    CognovisRestRelationshipService.getRelationships({
                        objectIdOne:UserStateManager.getCurrentlyLoggedUser().id,
                        relType:"user_portrait_rel"
                    })
                    .then(portraitsRels => {
                        const fileUploader = (this.$$(`AvatarUploader`)) as webix.ui.uploader;
                        if(portraitsRels[0]) {
                            fileUploader.define("formData",{
                                context_id:portraitsRels[0].object_two.id,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(filesRes => {
                                CognovisRestRelationshipService.deleteRelationship({relId:portraitsRels[0].rel.id})
                                .then(() => {
                                    CognovisRestRelationshipService.postRelationship(
                                        {
                                            requestBody:{
                                                object_id_one:UserStateManager.getCurrentlyLoggedUser().id,
                                                object_id_two:filesRes.file_id,
                                                rel_type:"user_portrait_rel"
                                            }
                                        }
                                    )
                                });
                            });
                        } else {
                            fileUploader.define("formData",{
                                context_id:UserStateManager.getCurrentlyLoggedUser().id,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(filesRes => {
                                CognovisRestRelationshipService.postRelationship(
                                    {
                                        requestBody:{
                                            object_id_one:UserStateManager.getCurrentlyLoggedUser().id,
                                            object_id_two:filesRes.file_id,
                                            rel_type:"user_portrait_rel"
                                        }
                                    }
                                )
                            });
                        }
                    });
                }
            }
        }
        return uploader
    }


    attemptToUploadAvatar():void {
        const avatarUploader = (this.$$(`AvatarUploader`) as webix.ui.uploader);
        avatarUploader.fileDialog();
    }

    getLogoutButton(): webix.ui.buttonConfig {
        const logout = {
            view: "button",
            label: "Logout",
            align: "right",
            inputHeight: 40,
            width: 120,
            css: "cog-button-big",
            click: () => this.show("/logout"),
        };
        return logout;
    }

    getUserAvatar(): webix.ui.templateConfig {
        const avatar = {
            view: "template",
            css: "avatar-template",
            template: "<img src='assets/images/user.png' alt='' class='avatar'/>",
            width: 70,
            height: 40,
            borderless: true,
            align: "right"
        }
        return avatar;
    }

    getHeader(
        logoutButton: webix.ui.buttonConfig,
        swaSwitcher: webix.ui.richselectConfig

    ): webix.ui.toolbarConfig {
        const searchComponent = SearchComponent;
        const header = {
            view: "toolbar",
            css:"cog-main-toolbar",
            borderless:true,
            padding: { left: 5 },
            height: 50,
            elements: [
                {
                    view:"button",
                    type:"icon",
                    width:35,
                    icon:"mdi mdi-menu",
                    click:() => {
                        const menu = webix.$$("sidebarMainSidebar") as webix.ui.sidebar;
                        menu.$scope.collapse();
                        webix.storage.local.put("left_navigation_collapsed_state", menu.config.collapsed);
                    }
                },
                {
                    view:"template",
                    borderless:true,
                    css:"cog-logo-container",
                    template: "<img src='"+ config.logoUrl + "' alt='' />",
                    width:283,
                    height:25
                },
                {
                    view:"spacer",
                    gravity:33
                },
                // {
                //     view: "label",
                //     label: this.app.config.name,
                //     css: "logo",
                //     align: "left",
                // },
                this.getSubscribeButton(),
                swaSwitcher,
                searchComponent,
                this.getToolbar(),
                logoutButton,
            ],
        };
        return header;
    }

    getSubscribeButton():webix.ui.layoutConfig {
        if (CognovisProfile.isUserInGivenGroup(UserProfile.FREELANCER)) {
            return {
                view:"layout",
                rows:[
                    {
                        view:"button",
                        width:210,
                        height:40,
                        css:"newsletter-button",
                        value:i18nHelper.getTranslation("Subscribe to our newsletter"),
                        click:() => {
                            const url = config.newsletterUrls.find(newsletterSetup => newsletterSetup.language === UserStateManager.currentlyLoggedUser.userData.locale).url;
                            if(url) {
                                window.open(url, '_blank').focus();
                            }
                        }
                    }
                ]
            }
        } else {
            return {}
        }
    }

    getNotificationsLayout():webix.ui.layoutConfig {
        const toolbar = this.getToolbar();
        const layout = {
            view:"layout",
            width:100,
            borderless:true,
            height:20,
            rows:[
                toolbar,
            ]
        };
        return layout
    }

    getToolbar():webix.ui.toolbarConfig {
        let langIcon = "cog-button-icon";
        const translatorMode = webix.storage.session.get("cognovis_translator_mode");
        if(!translatorMode) {
            langIcon = "cog-button-icon disabled";
        }
        let langIconTooltip = "Translator mode is now on. Click it to turn it off";
        if(!translatorMode) {
            langIconTooltip = "Translator mode is now off. Click it to turn it on";
        }      
        let translatorModeButtonHidden = true;
        if (CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || UserStateManager.fakingMode) {
            translatorModeButtonHidden = false;
        }
        let timeTrackingButtonHidden = true;
        if (CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
            timeTrackingButtonHidden = false;
        }
        const toolbar = {
            view:"toolbar",
            borderless:true,
            width:180,
            height:100,
            id:`${this.idPrefix}PmToolbar`,
            cols:[
                this.getAvatarUploader(),
                {
                    view:"button",
                    type:"icon",
                    icon:"fas fa-chart-line",
                    id:`${this.idPrefix}Reports`,
                    label:"",
                    click:(element, event) => {
                        this.createReportsContextMenu();
                        const menu = webix.$$(`${this.idPrefix}ReportsContextMenu`) as webix.ui.menu;
                        if(menu) {
                            menu.show(event.target)
                        }
                    },
                },
                { 
                    view:"button", 
                    badge:false, 
                    type:"icon", 
                    icon:"fas fa-bell", 
                    css:"cog-button-icon",
                    id:`${this.idPrefix}NotificationsBell`,
                    label:"",
                    click:() => {
                        if(!this.notificationsModal) {
                            this.notificationsModal = this.ui(NotificationsModal) as NotificationsModal;
                        }
                        this.notificationsModal.openModal();
                    } 
                },
                {
                    view:"button",
                    type:"icon",
                    css:"cog-button-icon",
                    icon:"mdi mdi-clock",
                    hidden:timeTrackingButtonHidden,
                    click:() => {
                        if(!this.loggedHoursModal) {
                            this.loggedHoursModal = this.ui(LoggedHoursModal) as LoggedHoursModal;
                        }
                        this.loggedHoursModal.openModal(0, true, true, () => {
                            const currentPages = this.getUrl();
                            const accountInfoPageActive = currentPages.find(page => page.page === "account-info.account-info");
                            if(accountInfoPageActive) {
                                const timesheetTable = webix.$$(`aiTimesheetEntriesTable`) as webix.ui.datatable;
                                if(timesheetTable) {
                                    CognovisRestTimesheetService.getTimesheetEntry({userId:UserStateManager.getCurrentlyLoggedUser().id})
                                    .then(entries => {
                                        entries.map(entry => {
                                            entry["duration"] = this.webixHelpers.convertHoursDecimalToDuration(entry.hours.toString());
                                        });
                                        timesheetTable.clearAll();
                                        timesheetTable.define("data", entries);
                                        timesheetTable.refresh();
                                    });
                                }
                            }
                        });
                    }
                },
                {
                    view:"button",
                    type:"icon",
                    css:"cog-button-icon",
                    icon:"mdi mdi-account",
                    tooltip:() => {
                        return `<div style="padding-top:4px;padding-bottom:1px;"><img src="${UserStateManager.currentlyLoggedUser.userData.portrait_url}"></div>`;
                    },
                    click:() => {
                        this.attemptToUploadAvatar();
                    }
                },
                {
                    view:"button",
                    type:"icon",
                    css:langIcon,
                    hidden:translatorModeButtonHidden,
                    icon:"fas fa-language",
                    tooltip:langIconTooltip,
                    click:() => {
                        //this.openI18nTranslationModal();
                        this.toggleTranslatorMode();
                    }
                },
            ]
        };
        return toolbar
    }

    createReportsContextMenu():void {
        let contextmenu = webix.$$(`${this.idPrefix}ReportsContextMenu`) as webix.ui.menu;
        if(!contextmenu) {
            contextmenu = webix.ui({
                view:"contextmenu",
                width:220,
                height:0,
                autoheight:true,
                submenuConfig:{
                    width:300
                },
                scroll:"y",
                id:`${this.idPrefix}ReportsContextMenu`,
                template: (obj) => {return `<span class='webix_icon fas fa-chart-line'></span> <span style='margin-left:8px'>${obj.value}</span>`},
                data:[],
                click:(id) => {
                    const menuItem = contextmenu.getMenuItem(id);
                    if(!menuItem.submenu) {
                        if(menuItem.type?.id === IntranetReportType.WEBIX_PIVOT_REPORT) {
                            this.app.show(`main/reports.report-pivot-view?report_id=${menuItem.id}`);
                            //window.location.href = `#!/main/reports.report-pivot-view?report_id=${menuItem.id}`;
                        } else {
                            this.app.show(`main/reports.report-base-view?report_id=${menuItem.id}`);
                        }
                    } else {
                        this.app.show(`main/reports.report-list?report_menu_id=${menuItem.id}`);
                    }
                }
            }) as unknown as webix.ui.menu
        }
        CognovisRestReportService.getReports({reportTypeId:IntranetReportType.WEBIX_REPORT} as any)
        .then(reports => {
            const menuData = [];
            const reportGroups = [];
            reports.map(report => {
                const rep = reportGroups.find(reportG => reportG?.id === report.parent_menu.id);
                if(!rep) {
                    reportGroups.push({...report.parent_menu, submenu:[]});
                }
            });
            reportGroups.map(reportGroup => {
                reports.map(report => {
                    if(reportGroup.id === report.parent_menu.id) {
                        reportGroup.submenu.push({
                            id:report.report.id,
                            value:report.report.name,    
                            type:report.report.type                    
                        });
                    }
                });
            });
            reportGroups.map(rGroup => {
                menuData.push({
                    id:rGroup.id,
                    value:rGroup.name,
                    submenu:rGroup.submenu
                })
            });            
            contextmenu.define("data", menuData);
            contextmenu.refresh();
        });
    }

    refreshNotificationsTable():void {
        const table = webix.$$(`${this.idPrefix}NotificationsTable`) as webix.ui.datatable;
        table.clearAll();
        this.getNotifications()
        .then(messages => {
            table.define("data", messages.filter(message => message.viewed_date === null));
            table.refresh();
        });
    }

    getUserSwitcher():Promise<webix.ui.richselectConfig> {
        if (CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || UserStateManager.fakingMode) {
            return WebixPortalProjectService.getUserSwitches({
                realUserId: UserStateManager.realUserId,
                limit: 60
            })
            .then((usersForSelector) => {
                const usersForSelectorForWebixCombo = usersForSelector.map(user => {
                    const displayedValue = `${user.user.name} (${user.profiles[0].group_name}, ${user.locale})`;
                    return {id:user.user.id, value:displayedValue }
                });
                const webixHelpersAlias = this.webixHelpers;
                const swaSwitcher = {
                    //view: "richselect",
                    view: "combo",
                    id: "swa_user_id",
                    css: "user__switcher",
                    height: 40,
                    inputHeight: 40,
                    width: 400,
                    align: "center",
                    value: UserStateManager.getCurrentlyLoggedUser().id,
                    options: usersForSelectorForWebixCombo,
                    on: {
                        onChange(value) {
                            // get the token
                            // Use the manual way with authentication as otherwise we get the switched user....
                            webix
                                .ajax()
                                .get(
                                    webixHelpersAlias.getUrl(
                                        "/switch_user",
                                        { switch_user_id: value },
                                        true
                                    )
                                )
                                .then(function (data) {
                                    data = data.json();
                                    webix.storage.session.put(
                                        "cognovis_faked_user_data",
                                        data
                                    );
                                    webix.storage.local.remove("cognovis_translations");
                                    webix.storage.session.remove("cached_cognovis_categories");
                                    window.location.href = "/";
                                });
                        },
                    },
                };

                return swaSwitcher;
            });
        } else {
            return Promise.resolve().
            then(() => {
                return {}
            });
        }
    }

    buildAccountInfoFromTemplate(keyAccountName:string,keyAccountEmail:string, keyAccountPhone:string, logoUrl:string): string {
        const sidebarAccountInfoTemplate = `
            <div class='account-info-container'>
            <div class='account-info-row' id='name'>{{accountInfoName}}</div>
            <div class='account-info-row email'><a id='email' href='mailto:{{accountInfoEmail}}'>{{accountInfoEmail}}</a></div>
            <div class='account-info-row phone'><a id='phone' href='tel:{{accountInfoPhone}}'>{{accountInfoPhone}}</a></div>
            <img class='logo-img' src={{logoUrl}}>
            </div>`;
        const paramsToReplace: unknown = {
            accountInfoName: keyAccountName,
            accountInfoEmail: keyAccountEmail,
            accountInfoPhone: keyAccountPhone,
            logoUrl,
        };
        const sidebarAccountInfoReplaced = this.htmlTemplateHelper.replaceParams(
            sidebarAccountInfoTemplate,
            paramsToReplace
        );
        return sidebarAccountInfoReplaced;
    }

    init(): void {
        //this.use(plugins.Menu, "menu");
        const projectId = this.urlHelper.getUrlParamByName(
            "project_id"
        );
        this.initNotificationsCalls();
        if(!this.i18nTranslationModal) {
            this.i18nTranslationModal = this.ui(i18TranslationModal) as i18TranslationModal;
        }
        webix["openI18nTranslationModal"] = this.i18nTranslationModal;
    }

    openI18nTranslationModal():void {
        if(!this.i18nTranslationModal) {
            this.i18nTranslationModal = this.ui(i18TranslationModal) as i18TranslationModal;
        }
        webix["openI18nTranslationModal"] = this.i18nTranslationModal;
        //this.i18nTranslationModal.openModal();
    }

    toggleTranslatorMode():void {
        let translatorMode = webix.storage.session.get("cognovis_translator_mode");
        translatorMode = !translatorMode;
        webix.storage.session.put("cognovis_translator_mode", translatorMode);
        window.location.reload();
    }

    setBrowserIcon(browserIconUrl: string): void {
        const getBrowserIcon = document.querySelector(
            "#iconLink"
        ) as HTMLLinkElement;
        getBrowserIcon.href = browserIconUrl;
    }


    initNotificationsCalls():void {
        this.getNotifications();
        setInterval(() => {
            this.getNotifications();
        }, 60*2000);
    }

    getNotifications():Promise<IWebixNotification[]> {
        return WebixPortalNotificationService.getWebixNotification({
                            
        })
        .then(messages => {  
            this.updateNotificationsBadge(messages);
            return messages;
        });
    }

    updateNotificationsBadge(messages:IWebixNotification[]):void {
        const bellButton = webix.$$(`${this.idPrefix}NotificationsBell`) as webix.ui.button;
        const totalUnreadNotifications = messages.filter(message => message.viewed_date === null).length;
        bellButton.config.badge = totalUnreadNotifications;
        bellButton.refresh();
    }


    executeNotificationAction(
        actionType:number, 
        notification:IWebixNotification,
        actionData:{
            action_type?: ICategoryObject,
            action_text?: string,
            action_help?: string,
            action_icon?: string,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action_object?: any,
        }
    ):void {
        switch(actionType) {
            case 4300:
                this.markAsRead(notification.notification.id);
                break;
            case 4301:
                this.openFileModal(actionData.action_object.id, "write");
                break;
            case 4302:
                this.openSendMailModal(actionData.action_object.id, actionData.action_text, actionData.action_object.id); 
                break;
            case 4304:
                CognovisNavigator.goToObjectDetails(actionData.action_object.id, "im_company");
                break;
            case 4305:
                CognovisNavigator.goToObjectDetails(actionData.action_object.id, "user");
                break;
            case 4306:
                WebixPortalAssignmentService.getFreelancerAssignments({
                    assignmentId:notification.context.id
                })
                .then(assignment => {
                    CognovisNavigator.navigateTo(`main/assignments.freelancer-selection?project_id=${assignment[0].project.id}&auto_focus_id=${actionData.action_object.id}-${assignment[0].assignment_type.id}-0`);
                });
                break;
            case 4307:  
                this.openFileModal(actionData.action_object.id, "read");
                break;
        }
        const popup = webix.$$(`${this.idPrefix}NotificationsPopup`) as webix.ui.popup;
        popup.hide();
    }

    markAsRead(notificationId:number):void {
        WebixPortalNotificationService.postWebixNotificationRead({
            notificationId:notificationId
        })
        .then(() => {
            this.refreshNotificationsTable();
        });
    }

    openFileModal(objectId:number, mode:string):void {
        if(!this.fileUploaderModal) {
            this.fileUploaderModal = this.ui(CognovisFileUploaderModal) as CognovisFileUploaderModal;
        }
        const filesTranslation = i18nHelper.getTranslation("Files");
        const modalTitle = `${filesTranslation}`;
        this.fileUploaderModal.openModal(objectId, mode, {allowFolderSelection:true}, modalTitle);
    }

    openSendMailModal(recipientId:number, defaultBody:string, contextId?:number):void {
        if(!this.sendMailModal) {
            this.sendMailModal = this.ui(SendMailModal) as SendMailModal;
        }
        const valueUserId = UserStateManager.getCurrentlyLoggedUser().id;
        
        CognovisRestCompanyService.getCompanyContacts({
            contactIds:[valueUserId, recipientId]
        })
        .then(senderAndRecipients => {
            let sender:ICompanyContact;
            let recipient:ICompanyContact;
            senderAndRecipients.map(sendOrRecipient => {
                if(sendOrRecipient.contact.id === valueUserId) {
                    sender = sendOrRecipient
                } else {
                    recipient = sendOrRecipient;
                }
            });
            this.sendMailModal.openModal(false,contextId, [
                {
                    name:"sender_email",
                    editable:false,
                    defaultValue:valueUserId.toString(),
                    visible:true,
                    options:[{
                        id:valueUserId,
                        value:`${sender.first_names} ${sender.last_name}`
                    }]
                },
                {
                    name:"cc_email",
                    visible:false
                },
                {
                    name:"subject",
                    visible:true,
                    editable:true,
                    autofocus:true
                },
                {
                    name:"recipient_email",
                    editable:false,
                    defaultValue:recipientId.toString(),
                    visible:true,
                    options:[{
                        id:recipientId,
                        value:`${recipient.first_names} ${recipient.last_name}`
                    }]              
                },
                {
                    name:"message_body",
                    defaultValue:defaultBody,
                    visible:true,
                    editable:true
                }
            ]);
        });
    }

}
