import session from "models/session";
import "reflect-metadata";
import { JetApp, plugins } from "webix-jet";
import DateHelper from "./modules/date-helpers/date-helper";
import CognovisI18n from "./stores/i18n";
import { CognovisRestCompanyService, CognovisRestService, OpenAPI } from "./openapi";
import CognovisProfile from "./modules/cognovis-profile/cognovis-profile";
import { UserProfile } from "./modules/cognovis-profile/profiles-types";
import { config as envConfig } from "./config";

// Our own webix extensions imports
// That should be done here in order to be able to use them anywhere from our code
import "./styles/app.css";
import "./webix-extensions/cognovis-datatable";
import "./webix-extensions/cognovis-datetimepicker";
import "./webix-extensions/cognovis-material-picker";
import { UserStateManager } from "./services/user-state-manager";

declare const APPNAME;
declare const VERSION;
declare const PRODUCTION;
declare const BUILD_AS_MODULE;

export default class MyApp extends JetApp {
    constructor(config = {}) {
        console.log(`Webix version: ${webix.version}`);
        let startPage = "main/project-overview.projects";
        const userGroups = UserStateManager.getCurrentUserGroups();
        if(userGroups[0]?.group_id === UserProfile.FREELANCER) {
            startPage = "main/assignments.my-assignments";
        }
        const defaults = {
            id: APPNAME,
            version: VERSION,
            debug: !PRODUCTION,
            start: startPage,
            baseUrl: envConfig.serverUrl,
            views: {
                "account-info": "main/account-info.account-info",
                //"companies-db": "main/companies-db.companies-db",
                "invoices": "main/invoices.invoices",
                "new-offer": "main/new-offer.new-offer",
                "projects": "main/projects-overview.projects",
                "project-info": "main/project-info.project-info",
                "project":"main/project-info.customer-project-view",
                "project-file": "main/project-file.project-file",
                "project-message": "main/project-message.project-message",
                "project-task": "main/project-tasks.project-tasks",
                "financial-overview": "main/financial-overview.financial-overview",
                "invoice-detail": "main/invoice-detail.invoice-detail",
                "project-finances": "main/project-finances.project-finances",
                "edit-batches": "assignments.modals.edit-batches.edit-batches",
                "freelancer-selection": "main/assignments.freelancer-selection",
                "freelancer-rating-overview": "main/freelancers.freelancer-rating-overview",
                "assignments": "main/assignments.overview",
                "my-assignments": "main/assignments.my-assignments",
                "my-closed-assignments": "main/assignments.my-closed-assignments",
                "assignment-details": "main/assignments.assignment-details",
                "update-fees": "main/assignments.modals.update-fees.update-fees",
                "request-overview": "main/project-overview.request",
                "projects-overview": "main/project-overview.projects",
                "potential-projects-overeview":"main/project-overview.potential",
                "closed-projects-overview": "main/project-overview.closed",
                "delivered-projects-overview": "main/delivered-overview.delivered",
                "user-details":"main/users-overview.user-details",
                "companies":"main/companies-overview.companies",
                "company":"main/companies-overview.company-details",
                "dynview":"main/project-overview.dynview",
                "dyn-projects":"main/project-overview.dyn-projects",
                "dynviews-settings":"main/dynviews-settings.dynviews-settings",
                "dynfields-settings":"main/dynfields-settings.dynfields-settings",
                "dynfields-widgets":"main/dynfields-settings.widgets-settings",
                "dynfields-layouts-settings":"main/dynfields-settings.dynfields-layouts-settings",
                "dynfields-attribute-permissions":"main/dynfields-settings.dynfields-attribute-permissions",
                "reports": "main/reports.reports",
                "report-list": "main/reports.report-list",
                "report":"main/report.report-base-view",
                "pivot-report":"main/reports.report-pivot-view",
                "reports-manager":"main/report.report-manager",
                "users-manager":"main/users-manager.users-manager",
                "general-chat":"main/chat.general-chat",
                "project-chat":"main/chat.project-chat",
                "server-error": "server-error.server-error",
                "page-not-found": "page-not-found.page-not-found",
                "forgot-password":"forgot-password"
            },
            routes: {
                "/account-info": "/main/account-info.account-info",
                //"/companies-db": "/main/companies-db.companies-db",
                "/invoices": "/main/invoices.invoices",
                "/new-offer": "/main/new-offer.new-offer",
                "/projects": "/main/project-overview.projects",
                "/project-info": "/main/project-info.project-info",
                "/project":"/main/project-info.customer-project-view",
                "/project-file": "/main/project-file.project-file",
                "/project-message": "/main/project-message.project-message",
                "/project-task": "/main/project-tasks.project-tasks",
                "/financial-overview": "/main/financial-overview.financial-overview",
                "/invoice-detail": "/main/invoice-detail.invoice-detail",
                "/project-finances": "/main/project-finances.project-finances",
                "/edit-batches": "/assignments.modals.edit-batches.edit-batches",
                "/freelancer-selection": "/main/assignments.freelancer-selection",
                "/freelancer-rating-overview": "/main/freelancers.freelancer-rating-overview",
                "/assignments": "/main/assignments.overview",
                "/my-assignments": "/main/assignments.my-assignments",
                "/my-closed-assignments": "/main/assignments.my-closed-assignments",
                "/assignment-details": "/main/assignments.assignment-details",
                "/update-fees": "/assignments.modals.update-fees.update-fees",
                "/request-overview": "/main/project-overview.request",
                "/projects-overview": "/main/project-overview.projects",
                "/potential-projects-overeview":"/main/project-overview.potential",
                "/closed-projects-overview": "/main/project-overview.closed",
                "/delivered-projects-overview": "/main/project-overview.delivered",
                "/reports": "/main/reports.reports",
                "/pivot-report":"/main/reports.reports.report-pivot-view",
                "/report-list": "/main/reports.report-list",
                "/report":"/main/report.report-base-view",
                "/reports-manager":"/main/report.report-manager",
                "/user-details":"/main/users-overview.user-details",
                "/companies":"/main/companies-overview.companies",
                "/company":"/main/companies-overview.company-details",
                "/dynview":"/main/project-overview.dynview",
                "/dynfields-settings":"main/dynfields-settings.dynfields-settings",
                "/dyn-projects":"/main/project-overview.dyn-projects",
                "/dynviews-settings":"main/dynviews-settings.dynviews-settings",
                "/dynfields-widgets":"main/dynfields-settings.widgets-settings",
                "/dynfields-layouts-settings":"main/dynfields-settings.dynfields-layouts-settings",
                "/dynfields-attribute-permissions":"main/dynfields-settings.dynfields-attribute-permissions",
                "/users-manager":"/main/users-manager.users-manager",
                "/general-chat":"/main/chat.general-chat",
                "/project-chat":"/main/chat.project-chat",
                "/server-error": "/server-error.server-error",
                "/page-not-found": "/page-not-found.page-not-found",
                "/forgot-password":"/forgot-password"
            },
        };
        super({ ...defaults, ...config });
        this.use(plugins.User, { 
            model: session,
            public:(path) => path.indexOf("forgot-password") > -1
        });

        //This get executed if user enters a wrong directory
        this.attachEvent("app:error:resolve", function () {
            webix.delay(() => {
                this.show("/page-not-found");
                const errorModal: HTMLElement = document.querySelector(".webix_error");
                errorModal.style.display = "none";
            });
        });

        webix.TooltipControl.delay = 1;
        //Check server availiability if success false,programatically redirect to server-unavailable view
        // CognovisRestService.getServerUp()
        //     .then((res) => {
        //         !res.success && this.show("/server-error");
        //     })
        //     .catch(() => {
        //         this.show("/server-error");
        //     });
    }
}

if (!BUILD_AS_MODULE) {
    // First we check if user is logged in, we do this to prevent app from searching for translations
    const isUserLogged = session.status();
    isUserLogged.then(
        () => {
            // Set Openapi token
            const barerToken = webix.storage.local.get("cognovis_bearer_token");
            // Next we check if secondBearerToken exists. It is used ONLY in case side wide admin decided to use user switcher
            // That means he is simply faking other user
            const fakedUserBearerToken = webix.storage.session.get("cognovis_faked_user_data")?.bearer_token;
            if(fakedUserBearerToken) {
                OpenAPI.TOKEN = fakedUserBearerToken;
            } else {
                OpenAPI.TOKEN = barerToken;
            }            
            CognovisRestService.getUserGroups({})
            .then(groups => {
                UserStateManager.setCurrentUserGroups(groups);
            });
            CognovisRestService.getUser({userIds:[UserStateManager.getCurrentlyLoggedUser().id]})
            .then(users => {
                if(users[0]) {
                    UserStateManager.setCurrentUserData(users[0]);
                }
                webix["auto_open_company"] = false;
                if(CognovisProfile.isUserAnEmployee() && users[0].company) {
                    CognovisRestCompanyService.getCompanies({
                        companyId:users[0].company.id
                    })
                    .then(object => {
                        webix["auto_open_company"] = object[0];
                    });
                }
                
                // We init dateHelper obj as it will come in handy
                const dateHelper = new DateHelper();
                // Check if translations are already saved to browser local storage
                const localTranslations = webix.storage.local.get("cognovis_translations");
                const lastUpdateTime = webix.storage.local.get("cognovis_translations_last_update");
                // We calculate number of days since last translations
                const isUserPoAdmin = CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN);
                const dateDifference = dateHelper.getDaysDifference(new Date(), new Date(lastUpdateTime));

                console.log(`User locale: ${UserStateManager.currentlyLoggedUser.userData.locale}`);
                // We load translations from local memory only if we have them and date difference since last update is not bigger than 1
                //if (localTranslations && dateDifference < 1 && !isUserPoAdmin)  {
                if (localTranslations && dateDifference < 1 && !isUserPoAdmin) {
                    // Translations are loaded from local browser memory
                    // Admins always load from the backend
                    webix.i18n["custom"] = localTranslations;
                    console.log(`i18n fetched from local memory. Last update: ${dateDifference} days ago. `);
                    webix.ready(() => new MyApp().render());
                } else {
                    console.log("i18n fetched from server");
                    // Translations should load from server and then saved to local browser memory
                    const cognovisI18n = new CognovisI18n();
                    cognovisI18n.getTranslations()
                    .then(
                        (translations) => {
                            const customTranslations = {};
                            translations.filter(trans => trans.locale === UserStateManager.currentlyLoggedUser.userData.locale)
                            .forEach((translationWithKey) => {
                                customTranslations[translationWithKey.message_key] = translationWithKey.message;
                                customTranslations[`${translationWithKey.key_of_package}.${translationWithKey.message_key}`] = translationWithKey.message;
                            });
                            webix.storage.local.put("cognovis_translations", customTranslations);
                            webix.storage.local.put("cognovis_all_translations", translations);
                            // we need to store last translations update time in local memory (YYYY-MM-DD format)
                            webix.storage.local.put("cognovis_translations_last_update", new Date().toISOString());
                            webix.i18n["custom"] = customTranslations;
                            webix.ready(() => new MyApp().render());
                        },
                        () => {
                            webix.ready(() => new MyApp().render());
                        }
                    );
                }
            })
        },
        () => {
            // If user is not logged in, we just launch the app so he can get his token
            webix.ready(() => new MyApp().render());
        }
    );
}
