/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean0 } from '../models/Boolean0';
import type { ICategory } from '../models/ICategory';
import type { Ids } from '../models/Ids';
import type { IError } from '../models/IError';
import type { IGetSuggestedTaskNames } from '../models/IGetSuggestedTaskNames';
import type { IInvoice } from '../models/IInvoice';
import type { ImCompanyId } from '../models/ImCompanyId';
import type { ImCostId } from '../models/ImCostId';
import type { ImProjectId } from '../models/ImProjectId';
import type { ImTransTaskId } from '../models/ImTransTaskId';
import type { ImTransTaskIds } from '../models/ImTransTaskIds';
import type { IntranetProjectStatusIds } from '../models/IntranetProjectStatusIds';
import type { IntranetProjectTypeIds } from '../models/IntranetProjectTypeIds';
import type { IntranetTmTool } from '../models/IntranetTmTool';
import type { ITmTool } from '../models/ITmTool';
import type { ITransCloneProjectBody } from '../models/ITransCloneProjectBody';
import type { ITransProject } from '../models/ITransProject';
import type { ITransProjectBody } from '../models/ITransProjectBody';
import type { ITransProjectBodyPut } from '../models/ITransProjectBodyPut';
import type { ITransQuote } from '../models/ITransQuote';
import type { ITransTask } from '../models/ITransTask';
import type { ITransTaskBody } from '../models/ITransTaskBody';
import type { Pagination } from '../models/Pagination';
import type { PersonId } from '../models/PersonId';
import type { StringCsv } from '../models/StringCsv';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebixPortalTranslationService {

    /**
     * Return not removable tasks for given project_id.<br>        Task can be removed if has not yet been assigned
     * @returns ITransTask Translation task that are not removable
     * @throws ApiError
     */
    public static getNotRemovableTransTasks({
        transTasks,
    }: {
        /**
         * Object ID of translation tasks which we check if they are removable
         */
        transTasks?: Ids,
    }): CancelablePromise<Array<ITransTask>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/not_removable_trans_tasks',
            query: {
                'trans_tasks': transTasks,
            },
        });
    }

    /**
     * Handler for GET rest calls which return list of previously used task_names
     * @returns IGetSuggestedTaskNames of task_names
     * @throws ApiError
     */
    public static getSuggestedTaskNames({
        taskNameStub,
    }: {
        /**
         * first three letters of task_name
         */
        taskNameStub?: string,
    }): CancelablePromise<Array<IGetSuggestedTaskNames>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/suggested_task_names',
            query: {
                'task_name_stub': taskNameStub,
            },
        });
    }

    /**
     * Returns the URL to open the project in the TM Tool - Might just provide the download of a project folder for this project
     * @returns ITmTool Information about the TM Tool
     * @throws ApiError
     */
    public static getTmToolUrl({
        projectId,
        tmToolId,
    }: {
        /**
         * Project for which we want the TM Tool link
         */
        projectId: ImProjectId,
        /**
         * TM Tool for which we want to get the link
         */
        tmToolId: IntranetTmTool,
    }): CancelablePromise<ITmTool> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tm_tool_url',
            query: {
                'project_id': projectId,
                'tm_tool_id': tmToolId,
            },
        });
    }

    /**
     * Return Translation projects
     * @returns ITransProject Generic Response description for cog_rest::get::trans_projects
     * @throws ApiError
     */
    public static getTransProjects({
        projectId,
        parentId,
        projectNr,
        companyId,
        memberId,
        projectStatusId,
        projectTypeId,
        pagination,
    }: {
        /**
         * Project for which to return the tasks.
         */
        projectId?: ImProjectId,
        /**
         * parent project. Works only if project_id is empty
         */
        parentId?: ImProjectId,
        /**
         * Project Nr we are looking for
         */
        projectNr?: string,
        /**
         * Company whos projects we look for. Also returns projects where the company employees are involved in.
         */
        companyId?: ImCompanyId,
        /**
         * Looking for members of this project
         */
        memberId?: PersonId,
        /**
         * Limit to projects of a certain status. Exclude deleted status by default.
         */
        projectStatusId?: IntranetProjectStatusIds,
        /**
         * Limit to projects of a certain type. We will by default exclude Task/Ticket (100/101)
         */
        projectTypeId?: IntranetProjectTypeIds,
        /**
         * Pagination information
         */
        pagination?: Pagination,
    }): CancelablePromise<Array<ITransProject>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/trans_projects',
            query: {
                'project_id': projectId,
                'parent_id': parentId,
                'project_nr': projectNr,
                'company_id': companyId,
                'member_id': memberId,
                'project_status_id': projectStatusId,
                'project_type_id': projectTypeId,
                'pagination': pagination,
            },
        });
    }

    /**
     * Returns an icategory array of possible trans_task_types in a project
     * @returns ICategory Task types allowed. You can read the workflow steps involved based of the aux_int1 of the category
     * @throws ApiError
     */
    public static getTransTaskTypes({
        projectId,
    }: {
        /**
         * Project in which to look for possible task Types
         */
        projectId: ImProjectId,
    }): CancelablePromise<Array<ICategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/trans_task_types',
            query: {
                'project_id': projectId,
            },
        });
    }

    /**
     * Provide the trans tasks from project-open for a specific project
     * @returns ITransTask Translation task to be returned
     * @throws ApiError
     */
    public static getTransTasks({
        projectId,
        taskId,
        taskName,
    }: {
        /**
         * Project for which to return the tasks.
         */
        projectId?: ImProjectId,
        /**
         * Task which we want to get
         */
        taskId?: ImTransTaskId,
        /**
         * name of the task to delete in the project
         */
        taskName?: string,
    }): CancelablePromise<Array<ITransTask>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/trans_tasks',
            query: {
                'project_id': projectId,
                'task_id': taskId,
                'task_name': taskName,
            },
        });
    }

    /**
     * Create a new trans task for the provided project_id
     * @returns ITransTask Task which were created
     * @throws ApiError
     */
    public static postTransTasks({
        projectId,
        requestBody,
    }: {
        /**
         * Project in which to create the task
         */
        projectId: ImProjectId,
        /**
         * Single trans task which can be created
         */
        requestBody: ITransTaskBody,
    }): CancelablePromise<Array<ITransTask>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/trans_tasks',
            query: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the provided task ids if it is possible to remove them<br><br>    Will only remove all or none. If there are no errors, then the removal was successful.
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteTransTasks({
        taskIds,
    }: {
        /**
         * Trans Task we are trying to delete
         */
        taskIds: ImTransTaskIds,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/trans_tasks',
            query: {
                'task_ids': taskIds,
            },
        });
    }

    /**
     * Create a new trans task for the provided project_id
     * @returns ITransTask Task which was updated
     * @throws ApiError
     */
    public static putTransTasks({
        taskId,
        requestBody,
    }: {
        /**
         * TaskID we want to overwrite (if provided)
         */
        taskId: ImTransTaskId,
        /**
         * Single trans task which can be created
         */
        requestBody: ITransTaskBody,
    }): CancelablePromise<ITransTask> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/trans_tasks',
            query: {
                'task_id': taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates a file for importing trans tasks into a different project
     * @returns string Generic description
     * @throws ApiError
     */
    public static getTransTasksAsFile({
        projectId,
        fileType,
    }: {
        /**
         * Project in which to create the task - needs write permission
         */
        projectId: ImProjectId,
        /**
         * which filetype do we want. defaults to CSV, supports some others
         */
        fileType?: StringCsv,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/trans_tasks_as_file',
            query: {
                'project_id': projectId,
                'file_type': fileType,
            },
        });
    }

    /**
     * Clone an existing translation project and make the cloner the PM.
     * @returns ITransProject Translation Project we just created
     * @throws ApiError
     */
    public static postCloneTransProject({
        projectId,
        requestBody,
    }: {
        /**
         * Project for which we want to create a copy_trans_project
         */
        projectId: ImProjectId,
        /**
         * Updated information for the cloned project and cloning instructions
         */
        requestBody: ITransCloneProjectBody,
    }): CancelablePromise<ITransProject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/clone_trans_project',
            query: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a translation project. In comparison to normal project creation this supports source and target languages as well as skills.<br>    Might be used for \"normal\" project creation as well though.<br><br>    Handler for POST calls on the project.<br><br>    For variables starting with \"skill_\", these will be added as freelancer skills to the project. So are source_language_id, target_language_ids, subject_area_id and<br>    language_experience_level_id (the level for the skills in languages).
     * @returns ITransProject Translation Project we just created
     * @throws ApiError
     */
    public static postTransProject({
        requestBody,
    }: {
        /**
         * Single project which can be created
         */
        requestBody: ITransProjectBody,
    }): CancelablePromise<ITransProject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/trans_project',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a project from the Database. Can only be done by admins
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteTransProject({
        projectId,
    }: {
        /**
         * Project we want to delete.
         */
        projectId: ImProjectId,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/trans_project',
            query: {
                'project_id': projectId,
            },
        });
    }

    /**
     * Modify a translation project. In comparison to normal project creation this supports source and target languages as well as skills.<br>    Might be used for \"normal\" project creation as well though.<br><br>    Handler for PUT calls on the project.<br><br>    For variables starting with \"skill_\", these will be added as freelancer skills to the project. So are source_language_id, target_language_ids, subject_area_id and<br>    language_experience_level_id (the level for the skills in languages).
     * @returns ITransProject Translation Project we just created
     * @throws ApiError
     */
    public static putTransProject({
        projectId,
        requestBody,
    }: {
        /**
         * Project which we want to update
         */
        projectId: ImProjectId,
        /**
         * Single project which can be created
         */
        requestBody: ITransProjectBodyPut,
    }): CancelablePromise<ITransProject> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/trans_project',
            query: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IInvoice Array of newly created quotes
     * @throws ApiError
     */
    public static postTransQuote({
        requestBody,
    }: {
        /**
         * Task information for creating or updating the quote
         */
        requestBody: ITransQuote,
    }): CancelablePromise<Array<IInvoice>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/trans_quote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Overwrite an existing quote with the tasks
     * @returns IInvoice Array with a single object with the newly created quote
     * @throws ApiError
     */
    public static putTransQuote({
        quoteId,
        requestBody,
    }: {
        /**
         * Quote for which we will update the line items
         */
        quoteId: ImCostId,
        /**
         * Task information for creating or updating the quote
         */
        requestBody: ITransQuote,
    }): CancelablePromise<Array<IInvoice>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/trans_quote',
            query: {
                'quote_id': quoteId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates tasks from single uploaded CSV/XLS file
     * @returns ITransTask Task which were created
     * @throws ApiError
     */
    public static postTransTasksFromFile({
        filename,
        path,
        projectId,
    }: {
        /**
         * Name of the file (original filename)
         */
        filename: string,
        /**
         * Path to the file as returned by upload_fs_file endpoint
         */
        path: string,
        /**
         * Project in which to create the task - needs write permission
         */
        projectId: ImProjectId,
    }): CancelablePromise<Array<ITransTask>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/trans_tasks_from_file',
            query: {
                'filename': filename,
                'path': path,
                'project_id': projectId,
            },
        });
    }

    /**
     * Uploads the files from the analysis in the \"Trados/Reports\" folder of the project
     * @returns ITransTask Tasks which were created
     * @throws ApiError
     */
    public static postTransTasksFromTrados({
        projectId,
        onlyNew,
    }: {
        /**
         * Project in which to create the task - needs write permission
         */
        projectId: ImProjectId,
        /**
         * Include only new tasks, do not overwrite existing ones
         */
        onlyNew?: Boolean0,
    }): CancelablePromise<Array<ITransTask>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/trans_tasks_from_trados',
            query: {
                'project_id': projectId,
                'only_new': onlyNew,
            },
        });
    }

    /**
     * Creates trans tasks from uploaded trados analysis
     * @returns ITransTask Tasks which were created
     * @throws ApiError
     */
    public static postTransTasksFromTradosFile({
        projectId,
        filename,
        path,
        onlyNew,
    }: {
        /**
         * Project in which to create the task - needs write permission
         */
        projectId: ImProjectId,
        /**
         * Name of the file (original filename)
         */
        filename: string,
        /**
         * Path to the file as returned by upload_fs_file endpoint
         */
        path: string,
        /**
         * Include only new tasks, do not overwrite existing ones
         */
        onlyNew?: Boolean0,
    }): CancelablePromise<Array<ITransTask>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/trans_tasks_from_trados_file',
            query: {
                'project_id': projectId,
                'filename': filename,
                'path': path,
                'only_new': onlyNew,
            },
        });
    }

}
