/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetFreelanceAssignmentStatus {
    CREATED = 4220,
    REQUESTED = 4221,
    ACCEPTED = 4222,
    DENIED = 4223,
    IN_PROGRESS = 4224,
    WORK_DELIVERED = 4225,
    DELIVERY_ACCEPTED = 4226,
    DELIVERY_REJECTED = 4227,
    ASSIGNED_OTHER = 4228,
    PACKAGE_AVAILABLE = 4229,
    ASSIGNMENT_DELETED = 4230,
    ASSIGNMENT_CLOSED = 4231,
}
