import { JetView } from "webix-jet";

export default class UserLocale extends JetView {
  config(): webix.ui.panelConfig {
    const ui = {
      view: "panel",
      x: 0,
      y: 1,
      dx: 1,
      dy: 1,
      header: "User Locale",
      body: {
        template: "User will manage his locale and timezone here",
      },
    };
    return ui as webix.ui.baseviewConfig;
  }
}
