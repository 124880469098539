import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisOkButtonWindow } from "../../../sources/services/cognovis-ok-button-window";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { CognovisRestInvoiceService, CognovisRestMailService, CognovisRestService, ICrFile, IMail } from "../../../sources/openapi";
import { FileHelper } from "../../../sources/modules/file-helpers/file-helpers";
import { WebixAlertConfig } from "@xbs/webix-pro";

export default class SendMailModal extends CognovisBasicModal {

    // SendMailModal can work in two modes.
    // First one is activated by passing invoiceId (numeric value). Modal will automaticaly get whole needed data from server.
    // Second one is more flexieble. We pass 'false' as invoiceId and then we use second param 'fields' to configure modal.

    idPrefix = "smm";
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    cognovisOkWindow: CognovisOkButtonWindow;
    webixHelpers: WebixHelpers;
    contextId:number;
    customActionAfterSend:() => void;
    forcedReadmeMode:boolean;
    
    init():void {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisOkWindow = container.resolve(CognovisOkButtonWindow);
    }

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Send E-Mail`), 880,770);
        return mainLayout;
    }

    openModal(invoiceId:number | boolean, contextId?:number, fields?:{name:string, autofocus?:boolean, options?:{id:number, value:string}[], visible?:boolean, editable?:boolean, defaultValue?:string}[], forcedReadmeMode?:boolean, crFiles?:ICrFile[]):void {
        this.forcedReadmeMode = forcedReadmeMode;
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig);
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const fileUploader = this.$$(`${this.idPrefix}SendMailModalFileUploader`) as webix.ui.uploader;
        const fileUploaderList = this.$$(`${this.idPrefix}SendMailModalFileUploaderList`) as webix.ui.list;
        fileUploader.define("link",fileUploaderList.config.id);
        fileUploader.refresh();
        // If invoiceId is not 'false', then we activate 'Invoice mode'
        if(invoiceId) {
            let emailData;
            let potentialSenders;
            let potentialRecipients;
            this.getDataForEmail(invoiceId as number)
            .then(resEmailData => {
                emailData = resEmailData;
                this.getPotentialSenders(invoiceId as number)
                .then(resPotentialSenders => {
                    potentialSenders = resPotentialSenders;
                });
                this.getPotentialRecipients(invoiceId as number)
                .then(resPotentialRecipients => {
                    potentialRecipients = resPotentialRecipients;
                    const fieldsValues = this.createFieldDefaults(emailData,potentialSenders,potentialRecipients);
                    this.setFieldsDefaults(fieldsValues);
                    const uploadedField = (this.$$(`${this.idPrefix}SendMailModalFileUploader`) as webix.ui.uploader);
                    uploadedField.files.parse(emailData.cr_files[0]);
                    const fileUploader = this.$$(`${this.idPrefix}SendMailModalFileUploader`) as webix.ui.uploader;
                    fileUploader.define("formData",{
                        context_id: invoiceId
                    });
                    this.cognovisPleaseWaitWindow.hide();
                });
            });
            const sendMailForm = this.$$(`${this.idPrefix}SendMailModalForm`) as webix.ui.form;
            sendMailForm.setValues({
                context_id: invoiceId
            });

        } else {
            this.setFieldsDefaults(fields);
            this.cognovisPleaseWaitWindow.hide();
            if(contextId) {
                this.contextId = contextId;
                fileUploader.define("formData",{
                    context_id: contextId
                });
            }
            if(crFiles) {
                const uploadedField = (this.$$(`${this.idPrefix}SendMailModalFileUploader`) as webix.ui.uploader);
                uploadedField.files.parse(crFiles);              
            }
        }

    }

    setCustomAfterSendAction(customActionAfterSend?:() => void):void {
        this.customActionAfterSend = customActionAfterSend;
    }


    closeModal():void {
        this.hide();
    }

    getContent():webix.ui.scrollviewConfig {
        const modal = {
            view: "scrollview",
            localId:`${this.idPrefix}MainContainer`,
            header: i18nHelper.getTranslation("Send Invoice by E-Mail"),
            css:"send-invoice-by-email",
            hidden: false,
            minWidth: 779,
            minHeight: 600,
            autoheight:true,
            autowidth:true,
            body: {
                view: "form",
                localId:`${this.idPrefix}SendMailModalForm`,
                elements: [
                    { 
                        view: "richselect", 
                        required: true, 
                        name: "sender_email", 
                        localId:`${this.idPrefix}-SenderEmail`,
                        label: i18nHelper.getTranslation("Sender")
                    },
                    { 
                        view: "multiselect", 
                        name: "recipient_email", 
                        localId:`${this.idPrefix}-Recipient-Email`,
                        label: i18nHelper.getTranslation("Recipient") 
                    },
                    { 
                        view: "text", 
                        type: "email", 
                        name: "cc_email", 
                        localId:`${this.idPrefix}-CC-Email`,
                        label: i18nHelper.getTranslation("CC"), 
                        placeholder: "CC" 
                    },
                    { 
                        view: "text", 
                        name: "subject", 
                        localId:`${this.idPrefix}-Subject`,
                        label: i18nHelper.getTranslation("Subject") 
                    },
                    {
                        view:"ckeditor5",
                        localId:`${this.idPrefix}-MessageBody`,
                        name:"message_body",
                        height:360,
                        mode:"document",
                        value:``,
                    },
                    {
                        view: "uploader",
                        localId: `${this.idPrefix}SendMailModalFileUploader`,
                        name: "files",
                        disabled:this.forcedReadmeMode,
                        css:"cog-button-big",
                        value:i18nHelper.getTranslation("Browse_Files"),
                        //link: `${this.idPrefix}SendMailModalFileUploaderList`,
                        autosend: false,
                        formData: null,
                        upload: this.webixHelpers.getUrlWithApiKey(
                            "upload_cr_file"
                        ),
                        datatype: "json",
                        align: "right",
                        inputWidth: 150,
                        on: {
                            onUploadComplete:(response) => {
                                this.sendMail();
                            }
                        }
                    },
                    {
                        view: "list",
                        localId: `${this.idPrefix}SendMailModalFileUploaderList`,
                        type: "uploader",
                        align: "left",
                        autoheight: true,
                        borderless: true,
                        on: {
                            "onItemClick":function(id, e, trg){ 
                                const item = this.getItem(id);
                                FileHelper.downloadCrFile(item.file_id);
                            }
                        }
                    },
                ]
            }
        };
        return modal;
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Send`)}`,
                            align: "right",
                            disabled:this.forcedReadmeMode,
                            click:() => {
                                this.attemptSendMail();
                            }
                        },    
                    ] 
                };
        return buttons;
    }
    
    // Only for Invoice Mode
    createFieldDefaults(data:IMail, potentialSenders:ICombobox[], potentialRecipients:ICombobox[]):{name:string, autofocus?:boolean, options?:{id:number, value:string}[], visible?:boolean, editable?:boolean, defaultValue?:string}[] {
        const emailRecipientId = data.recipients[0].person.id.toString();
        const parsedBody = data.body.replace(/\\n/g, "")
        const fields = [
            {
                name:"sender_email",
                editable:true,
                defaultValue:potentialSenders[0].id.toString(),
                visible:true,
                options:potentialSenders as {id:number, value:string}[],
                autofocus:false
            },
            {
                name:"cc_email",
                visible:true,
                editable:true,
                autofocus:false
            },
            {
                name:"subject",
                visible:true,
                defaultValue:data.subject,
                editable:true,
                autofocus:true
            },
            {
                name:"recipient_email",
                editable:true,
                defaultValue:emailRecipientId,
                visible:true,
                options:potentialRecipients as {id:number, value:string}[],
                autofocus:false     
            },
            {
                name:"message_body",
                defaultValue:parsedBody,
                visible:true,
                editable:true,
                autofocus:false
            }
        ];
        return fields
    }

    setFieldsDefaults(fields:{name:string, autofocus?:boolean, options?:{id:number, value:string}[], visible?:boolean, editable?:boolean, defaultValue?:string}[]):void {
        const form = this.$$(`${this.idPrefix}SendMailModalForm`) as webix.ui.form;
        const formFields = form.getChildViews();
        fields.map(field => {
            const realFormField = formFields.find(realField => realField.config["name"] === field.name) as webix.ui.text;
            if(realFormField) {
                if(field.options) {
                    realFormField.define("options", field.options);
                    realFormField.refresh();
                }
                realFormField.setValue(field.defaultValue);
                if(field.visible) {
                    realFormField.enable();
                    realFormField.show();
                } else {
                    realFormField.hide();
                    realFormField.disable();
                }
                if(field.editable) {
                    realFormField.enable();
                } else {
                    realFormField.disable();
                }
                if(field.autofocus) {
                    realFormField.focus();
                }
            }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const editor = (this.$$(`${this.idPrefix}-MessageBody`) as any);
        editor.setValue(fields.find(field => field.name === "message_body").defaultValue);
    }

    attemptSendMail():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Sending e-mail...")});
        (this.$$(`${this.idPrefix}SendMailModalFileUploader`) as webix.ui.uploader)
        .send(uploadedFiles => {
            //console.log("attachments are ready");
        });
    }

    sendMail():void {
        // Access the files form the file uploader
        const form = this.$$(`${this.idPrefix}SendMailModalForm`) as webix.ui.form;
        const formValues = form.getValues({
            hidden: false,
            disabled: true
        });
        let ccReplacedCommasAndSemiColons = "";
        if(formValues.cc_email) {
            ccReplacedCommasAndSemiColons = formValues.cc_email.replace(/;/g, ' ');
            ccReplacedCommasAndSemiColons = ccReplacedCommasAndSemiColons.replace(/,/g, ' ');
        }
        const requestBody = {
            from_party_id: formValues.sender_email,
            to_party_ids: formValues.recipient_email,
            subject:  formValues.subject,
            body: formValues.message_body,
            cc_addr: ccReplacedCommasAndSemiColons,
            context_id: formValues.context_id,
            files: formValues.files
        };
        if(this.contextId) {
            requestBody.context_id = this.contextId;
        }
        CognovisRestMailService.postSendMail({
            requestBody: requestBody
        })
        .then(() => {
            this.closeModal();
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                type:"info", 
                text:i18nHelper.getTranslation("message_sent_message"),
                callback:() => {
                    if(this.customActionAfterSend) {
                        this.customActionAfterSend();
                    }
                }
            } as unknown as WebixAlertConfig);
        })
        .catch(err => {
            this.closeModal();
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            })
            .finally(() => {
                this.cognovisPleaseWaitWindow.hide();
            });
        });
    }

    getDataForEmail(invoiceId:number):Promise<IMail> {
        return CognovisRestInvoiceService.getInvoiceMail({
            invoiceId:invoiceId
        })
        .then(res => {
            return res
        });
    }

    getPotentialSenders(invoiceId:number):Promise<ICombobox[]> {
        return CognovisRestInvoiceService.getInvoiceMailSender({
            invoiceId: invoiceId
        })
        .then(res => {
            const newDatasetForCombo = [];
            res.forEach(sender => {
                newDatasetForCombo.push({
                    value: sender.person.name,
                    id: sender.person.id
                });
            })
            return newDatasetForCombo;
        })
    }

    getPotentialRecipients(invoiceId:number):Promise<ICombobox[]> {
        return CognovisRestInvoiceService.getInvoiceMailRecipient({
            invoiceId: invoiceId
        })
        .then(res => {
            const newDatasetForCombo = [];
            res.forEach(recipient => {
                newDatasetForCombo.push({
                    value: recipient.person.name,
                    id: recipient.person.id
                });
            });
            return newDatasetForCombo;
        });
    }
}