/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ICrFile } from '../models/ICrFile';
import type { ICrFolder } from '../models/ICrFolder';
import type { Id } from '../models/Id';
import type { Ids } from '../models/Ids';
import type { ImProjectId } from '../models/ImProjectId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { StringUpload } from '../models/StringUpload';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestFileService {

    /**
     * Returns a list of files within a folder or a project
     * @returns ICrFile Array of files and folders within the folder.
     * @throws ApiError
     */
    public static getCrFile({
        parentId,
        fileId,
        fileItemName,
    }: {
        /**
         * object_id for which we are looking for files. might also be a folder
         */
        parentId?: IntegerInt32,
        /**
         * ItemID of the file we look for
         */
        fileId?: Id,
        /**
         * name of the file we look for in a parent
         */
        fileItemName?: string,
    }): CancelablePromise<Array<ICrFile>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cr_file',
            query: {
                'parent_id': parentId,
                'file_id': fileId,
                'file_item_name': fileItemName,
            },
        });
    }

    /**
     * Unpublishes a file (effectively \"deleting\" it)
     * @returns ICrFile File definition in case the unpublishing was not successful
     * @throws ApiError
     */
    public static deleteCrFile({
        fileId,
    }: {
        /**
         * FileId which we want to update.
         */
        fileId: Id,
    }): CancelablePromise<ICrFile> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cr_file',
            query: {
                'file_id': fileId,
            },
        });
    }

    /**
     * Updates information of a file. Can rename the file, update the parent or change the description
     * @returns ICrFile File definition
     * @throws ApiError
     */
    public static putCrFile({
        fileId,
        parentId,
        revisionId,
        fileItemName,
        liveRevision,
        description,
    }: {
        /**
         * FileId which we want to update.
         */
        fileId: Id,
        /**
         * Object_id in which we locate the file (context)
         */
        parentId?: IntegerInt32,
        /**
         * Revision we want to ammend of the file
         */
        revisionId?: Id,
        /**
         * Name of the file, in case we want to rename
         */
        fileItemName?: string,
        /**
         * New live revision for this file. If live_revision is 0 then unpublish the file
         */
        liveRevision?: Id,
        /**
         * Description / comment for the file
         */
        description?: string,
    }): CancelablePromise<ICrFile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cr_file',
            query: {
                'parent_id': parentId,
                'file_id': fileId,
                'revision_id': revisionId,
                'file_item_name': fileItemName,
                'live_revision': liveRevision,
                'description': description,
            },
        });
    }

    /**
     * Returns a list of files within a folder or a project
     * @returns ICrFolder Array of folders within the folder.
     * @throws ApiError
     */
    public static getCrFolders({
        folderId,
        projectId,
    }: {
        /**
         * ID of the folder we looking at
         */
        folderId?: Id,
        /**
         * Project for which to return the tasks.
         */
        projectId?: ImProjectId,
    }): CancelablePromise<Array<ICrFolder>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cr_folders',
            query: {
                'folder_id': folderId,
                'project_id': projectId,
            },
        });
    }

    /**
     * Serves a file from the content repository back to the user
     * @returns string Generic description
     * @throws ApiError
     */
    public static getDownloadCrFile({
        fileId,
        revisionId,
    }: {
        /**
         * File which we want to read
         */
        fileId?: IntegerInt32,
        /**
         * If provided, download the specific revision for the file
         */
        revisionId?: IntegerInt32,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/download_cr_file',
            query: {
                'file_id': fileId,
                'revision_id': revisionId,
            },
        });
    }

    /**
     * @returns string Generic description
     * @throws ApiError
     */
    public static getDownloadFsFile({
        objectId,
        filePath,
    }: {
        /**
         * ID of the object in which path the file exists
         */
        objectId: IntegerInt32,
        /**
         * relative path (to the filestorage path of the object_id) for the file
         */
        filePath: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/download_fs_file',
            query: {
                'object_id': objectId,
                'file_path': filePath,
            },
        });
    }

    /**
     * @returns string Generic description
     * @throws ApiError
     */
    public static getDownloadFsFolder({
        objectId,
        folderPath,
    }: {
        /**
         * ID of the object in which path the file exists
         */
        objectId: IntegerInt32,
        /**
         * relative path (to the filestorage path of the object_id) for the folder
         */
        folderPath: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/download_fs_folder',
            query: {
                'object_id': objectId,
                'folder_path': folderPath,
            },
        });
    }

    /**
     * Uploads a file into the content repository. Assumes the uploader to be called \"upload\"
     * @returns ICrFile File definition
     * @throws ApiError
     */
    public static postUploadCrFile({
        contextId,
        uploaderName,
        fileItemId,
        folder,
        description,
    }: {
        /**
         * Object_id in which we upload the file (context) - might be a file_item_id, then we upload a new revision
         */
        contextId?: IntegerInt32,
        /**
         * Name used for the upload. Defaults to uploader
         */
        uploaderName?: StringUpload,
        /**
         * If we just want to upload a new revision to an existing file, provide the file_item_id. Will also try detect an existing file_item_id from filename and context_id.
         */
        fileItemId?: Id,
        /**
         * Folder in which we want to store the file. Relative to the root folder of the context_id. If empty, attach to the context_id without folders.
         */
        folder?: string,
        /**
         * Description / comment for the file
         */
        description?: string,
    }): CancelablePromise<ICrFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/upload_cr_file',
            query: {
                'context_id': contextId,
                'uploader_name': uploaderName,
                'file_item_id': fileItemId,
                'folder': folder,
                'description': description,
            },
        });
    }

    /**
     * @returns string Generic description
     * @throws ApiError
     */
    public static postUploadFsFile(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/upload_fs_file',
        });
    }

    /**
     * Endpoint to call when an upload to a folder has been completed
     * @returns ICrFile Array of files and folders within the folder.
     * @throws ApiError
     */
    public static postUploadToFolderCompleted({
        folderId,
        fileItemIds,
        description,
    }: {
        /**
         * ID of the folder we finished uploading to
         */
        folderId: Id,
        /**
         * FileIds which we succesfully uploaded
         */
        fileItemIds?: Ids,
        /**
         * Description / comment for the file
         */
        description?: string,
    }): CancelablePromise<Array<ICrFile>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/upload_to_folder_completed',
            query: {
                'folder_id': folderId,
                'file_item_ids': fileItemIds,
                'description': description,
            },
        });
    }

}
