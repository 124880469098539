import { CognovisRestReportService, IReport } from "../../../sources/openapi";
import { JetView } from "webix-jet"
import ReportTableBuilder from "./report-table-builder";



export default class ReportList extends JetView {

    idPrefix = "rl";
    reportParentId:number;
    reportId = 0;
    reportName:string;
    reportTableBuilder:ReportTableBuilder;

    config():webix.ui.layoutConfig {
        this.reportParentId = this.getParam("report_parent_id", true);
        return {
            view:"layout",
            type:"clean",
            css:"cog-content",
            padding:13,
            gravity:1,
            rows:[
                this.getLayout()
            ]
        }
    }

    init():void {
        this.injectTabbarOptions();
    }

    getLayout():webix.ui.layoutConfig {
        this.reportTableBuilder = new ReportTableBuilder(this.app, `${this.idPrefix}ReportDatatable`, {reportId:this.reportId, reportName:this.reportName}, this.reportName, [], {
            adjust:true
        }, {});
        return {
            view:"layout",
            rows:[
                this.getTabbar(),
                this.reportTableBuilder.getDatatableWithContainer()
            ]
        }
    }

    selectTab(newReportId:number) {
        this.reportId = newReportId;
        this.reportName = "";
        if(this.reportTableBuilder){
            this.reportTableBuilder.recreateTable(this.reportId);
        } else {
            this.buildTable();
        }
    }

    buildTable():void {
        this.reportTableBuilder = new ReportTableBuilder(this.app, `${this.idPrefix}ReportDatatable`, {reportId:this.reportId, reportName:this.reportName}, this.reportName, [], {
            adjust:true
        }, {});
        const table = webix.$$(`${this.idPrefix}ReportDatatable`) as webix.ui.datatable;
        const items = table.serialize();
        this.reportTableBuilder.buildReportTable();
    }

    getTabbar():webix.ui.tabbarConfig {
        return {
            view:"tabbar", 
            optionWidth:300,
            id:`${this.idPrefix}ReportsListTabbar`, 
            //value:"listView", // the initially selected tab
            options: [
  
            ],
            on:{
                onAfterTabClick:(id, e) => {
                    this.selectTab(id);
                }
            }
        }
    }    

    getReports():Promise<IReport[]> {
        return CognovisRestReportService.getReports({reportParentId:this.reportParentId})
        .then(reports => {
            return reports
        });
    }

    injectTabbarOptions():void {
        this.getReports()
        .then(reports => {
            const reportsAsTabs = [];
            reports.map(report => {
                reportsAsTabs.push({
                    id:report.report.id,
                    value:report.report.name
                });
            });
            const tabbar = webix.$$(`${this.idPrefix}ReportsListTabbar`) as webix.ui.tabbar;
            tabbar.define("options", reportsAsTabs);
            tabbar.refresh();
        });
    }

    getTabView():webix.ui.tabviewConfig {
        return {
            view: "tabview",
            cells: [
                {
                    header: "List",
                    body: {
                        id: "listView",
                        view: "list", 
                        // list config
                    }
                },
                {
                    header: "Form",
                    body: {
                        id: "formView",
                        view: "form",
                        // form config
                    }
                }
            ]
        }
    }

    // getTabs():webix.ui.layoutConfig {

    // }
}