import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { IJetApp } from "webix-jet";
import {CognovisRestService, IBizObjectMember, IntranetBizObjectRole, IUser, WebixPortalProjectService} from "../../../sources/openapi";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";

export default class ProjectTeamPortlet extends CognovisBasicPortlet {
    
    projectId:number;
    idPrefix = "pot";
    projectMembers:IBizObjectMember[] = [];
    portletId:number;
    title:string;

    constructor(app:IJetApp, projectId:number, portletId?:number, title?:string) {
        super(app, {});
        this.projectId = projectId;
        this.portletId = portletId;
        if(this.title) {
            this.title = title;
        }
    }

    init():void {
        super.init();
        WebixPortalProjectService.getProjectMembers({
            projectId:this.projectId
        })
        .then(projectMembers => {
            // Removing duplicates !
            // 1st May of 2021: we had some backend problem with duplicates, but we can easily (for now) fix that in frontend
            const uniqueProjectMembers = projectMembers .reduce((unique, o) => {
                if(!unique.some(obj => obj.member.user.id === o.member.user.id)) {
                  unique.push(o);
                }
                return unique;
            },[]);
            this.projectMembers = uniqueProjectMembers;
            const rows = [
                {
                    view:"spacer",
                    css:"cog-box-content",
                    height:20
                },
                {
                    view:"layout",
                    minHeight:600,
                    css:"cog-box-content",
                    cols:[
                        {
                            view:"spacer",
                            css:"cog-box-content",
                            gravity:5
                        },
                        {
                            view:"layout",
                            gravity:80,
                            css:"cog-box-content",
                            rows:this.getMembersRows(uniqueProjectMembers),
                        },
                        {
                            view:"spacer",
                            css:"cog-box-content",
                            gravity:5
                        },
                    ]
                    
                }
            ];
            const portlet = {
                view: "layout",
                gravity:1,
                id:`${this.idPrefix}MembersList`,
                rows:rows,
            };
            this.components = [portlet];
            this.insertComponents();
        });
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: i18nHelper.getTranslation("Project_team"), 
                    borderless: true 
                },
                { 
                    view: "button", 
                    type: "icon", 
                    icon: "fal fa-ellipsis-v", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true ,
                    click:(element, event) => {
                        CognovisRestService.getGroupMembers({
                            groupId:463
                        })
                        .then(internalCompanyMembers => {
                            this.createContextMenu(internalCompanyMembers);
                            const menu = webix.$$(`${this.idPrefix}TeamMemberContextMenu`) as webix.ui.contextmenu;
                            menu.show(event.target);
                        });
                    }
                },
            ]
        };
        return header;
    }

    getMembersRows(projectMembers:IBizObjectMember[]):webix.ui.layoutConfig[] {
        const projectMembersLayout:webix.ui.layoutConfig[] = [];
        for(let i = 0; i <= projectMembers.length; i = i + 2) {
            projectMembersLayout.push({
                view:"layout",
                cols:[
                    this.getSingleMember(projectMembers[Number(i)]),
                    this.getSingleMember(projectMembers[i+1])
                ]
            });
        }
        return projectMembersLayout
    }

    refreshTeamMembers():void {
        window.location.reload();
    }
    
    getSingleMember(projectMember:IBizObjectMember):webix.ui.layoutConfig {
        if(projectMember) {
            let indicatorClass = "project-team-activity-indicator";
            const twentyMinutes = 1200*20;
            if(projectMember?.member?.seconds_since_last_request && projectMember?.member?.seconds_since_last_request <= twentyMinutes) {
                indicatorClass = "project-team-activity-indicator active";
            }
            let formattedUserName = `<div style='margin-top:18px;'>${projectMember?.member?.user?.name}<br/>(${projectMember.role.name})</div>`;
            if(projectMember.role.id === IntranetBizObjectRole.FREELANCER) {
                if(projectMember.member.company != null ) {
                    formattedUserName  = `<a class='general-link' href='javascript:void(0)'><div style='margin-top:18px;'>${projectMember?.member?.user?.name}<br/>(${projectMember.role.name})</div></a>`
                } else {
                    formattedUserName  = `<div style='margin-top:18px;'>${projectMember?.member?.user?.name}<br/>(${projectMember.role.name})</div>`
                }
            }
            const layout = {
                view:"layout",
                height:120,
                padding:20,
                cols:[
                    {
                        template:`<div class='anon-avatar-bg'><div class='${indicatorClass}'></div><img alt="" style='border-radius:50%; border:2px solid #ffffff; width:60px; height:60px;' src='${projectMember?.member.portrait_url}'/></div>`,
                        borderless:true,
                        width:80
                    },
                    {
                        view:"button",
                        template:formattedUserName,
                        borderless:true,
                        click:() => {
                            if(projectMember.member.company.id) {
                                CognovisNavigator.goToObjectDetails(projectMember.member.user.id, "user");
                            }
                        }
                    }
                ]
            };
            return layout
        } else {
            return {}
        }
    }

    createContextMenu(companyMembers:IUser[]):void {
        // Let's first filter list for only those one who are not members of project yet
        // First we need to extract ids of curret project members
        const currentProjectMembersIds = this.projectMembers.map(projectMember => projectMember.member.user.id);
        // Now we filter company members with previously extracted ids of project members
        const filteredCompanyMembers = companyMembers.filter(companyMember => currentProjectMembersIds.indexOf(companyMember.user.id) === -1);
        const parsedInternalCompanyMembers = filteredCompanyMembers.map(member => (
            {
                userId:member.user.id, 
                id:`${this.idPrefix}AddAnotherMember-${member.user.id}`,
                value:member.user.name, 
                action:"add-member"
            }
        ));
        const parsedCurrentMembers = this.projectMembers.map(member => (
            {
                userId:member.member.user.id, 
                id:`${this.idPrefix}AddAnotherMember-${member.member.user.id}`,
                value:member.member.user.name, 
                action:"remove-member"
            }
        ));
        let contextmenu = webix.$$(`${this.idPrefix}TeamMemberContextMenu`) as webix.ui.contextmenu;
        if(!contextmenu) {
            contextmenu = webix.ui({
                view:"contextmenu",
                width:220,
                heihgt:200,
                id:`${this.idPrefix}TeamMemberContextMenu`,
                template: (obj) => {return `<span class='webix_icon ${obj.icon}'></span> <span style='margin-left:8px'>${obj.value}</span>`},
                data:[
                    {
                        value: i18nHelper.getTranslation("add_me_to_project"),
                        action:"add-me",
                        icon:"fas fa-user-plus"
                    },
                    {
                        value: i18nHelper.getTranslation("add_another_member"),
                        icon:"fas fa-user-plus",
                        submenu:parsedInternalCompanyMembers,
                    },
                    {
                        value: i18nHelper.getTranslation("delete_member"),
                        icon:"fas fa-user-times",
                        submenu:parsedCurrentMembers
                    },
                ],
                click:(id) => {
                    const userId = UserStateManager.getCurrentlyLoggedUser().id;
                    const me = webix.$$(`${this.idPrefix}TeamMemberContextMenu`) as webix.ui.menu;
                    const menuItem = me.getMenuItem(id);
                    switch(menuItem.action) {
                        case "add-me":
                            this.addMember(userId);
                            break;
                        case "add-member":
                            this.addMember(menuItem.userId);
                            break;
                        case "remove-member":
                            this.removeMember(menuItem.userId);
                            break;
                        default:
                            // nothing
                            break;
                    }
                }
            }) as webix.ui.contextmenu;
        }

    }

    addMember(userId:number):void {
        CognovisRestService.postBizObjectMember({
            requestBody:{
                object_id:this.projectId,
                member_id:userId,
                role_id:1300
            }
        })
        .then(() => {
            this.refreshTeamMembers();
        });
    }

    removeMember(userId:number):void {
        CognovisRestService.deleteBizObjectMember({
            objectId:this.projectId,
            memberId:userId
        })
        .then(() => {
            this.refreshTeamMembers();
        });
    }


}