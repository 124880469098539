import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import {
    CognovisRestDynamicService,
    IDynfieldLayoutAttribute,
    IDynfieldLayoutPage,
    IDynfieldLayoutPageAttributesBody
} from "../../../sources/openapi";

export default class DynfieldsLayoutSingleAttributeEditModal extends CognovisBasicModal {

    attributeId:number;
    pageUrl:string;
    objectType:string;
    idPrefix = "dynflsaem";
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle: string;
    afterSubmitAction: () => void;

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 740, 520);  // adjust size if needed
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(pageUrl:string, objectType:string, dynfieldLayoutAttr:IDynfieldLayoutAttribute, afterSubmitAction?: () => void): void {
        this.modalTitle = (pageUrl && objectType) ? i18nHelper.getTranslation("Edit layout page attributes") : i18nHelper.getTranslation("Create new layout page attribute");
        if(pageUrl) {
            this.pageUrl = pageUrl;
        }
        if(objectType) {
            this.objectType = objectType;
        }
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTitle);
        this.cognovisPleaseWaitWindow.hide();
        const modal = (this.getRoot() as webix.ui.window);
        modal.show();
        if(dynfieldLayoutAttr?.attribute_id) {
            this.attributeId = dynfieldLayoutAttr.attribute_id;
            this.setFormValues();
        }
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
    }

    getContent(): webix.ui.layoutConfig {
        // Define the layout configuration
        const layout = {
            view: "scrollview",
            body: {
                padding: 13,
                rows: [
                    {
                        view: "form",
                        id: `${this.idPrefix}DynfieldLayoutSingleAttributeEditViewForm`,
                        elements: [
                            {
                                view:"combo",
                                //hidden:!this.attributeId,
                                label: i18nHelper.getTranslation("Attribute"),
                                labelWidth: 160,
                                name:'attribute_id',
                                suggest: {
                                    body: {
                                        url: () => {
                                            return CognovisRestDynamicService.getDynfieldAttributes({objectType:this.objectType})
                                                .then(dynfields => {
                                                    return dynfields.map(dynfield => ({id:dynfield.dynfield_id, value:dynfield.dynfield_name}))
                                                });
                                        }

                                    }
                                }
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Position x"),
                                labelWidth: 160,
                                name: "pos_x"
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Position y"),
                                labelWidth: 160,
                                name: "pos_y"
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Size x"),
                                labelWidth: 160,
                                name: "size_x"
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Size y"),
                                labelWidth: 160,
                                name: "size_y"
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Sort key"),
                                labelWidth: 160,
                                name: "sortk_key"
                            },
                            {
                                view:"combo",
                                label: i18nHelper.getTranslation("Label style"),
                                value:"plain",
                                labelWidth: 160,
                                name:"label_style",
                                options:[
                                    {id:"plain", value:"Plain"},
                                    {id:"no_label", value:"No label"}
                                ]
                            },
                            {
                                view:"text",
                                labelWidth: 160,
                                label:i18nHelper.getTranslation("DIV class"),
                                name:"div_class"
                            }
                        ]
                    }
                ]
            }
        };
        return layout;
    }

    setFormValues(): void {
        const form = webix.$$(`${this.idPrefix}DynfieldLayoutSingleAttributeEditViewForm`) as webix.ui.form;
        CognovisRestDynamicService.getDynfieldLayoutPageAttributes({attributeId: this.attributeId, pageUrl: this.pageUrl, objectType: this.objectType})
            .then(attributes => {
                if(attributes[0]) {
                    const attribute = attributes[0];
                    this.setTitle(attribute.pretty_name);
                    Object.entries(attribute).forEach(([key, value]) => {
                        const element = form.queryView({name:key});
                        if(element) {
                            element.setValue(value);
                        }
                    });
                }
            });
    }

    getActionButtons(): webix.ui.layoutConfig {
        const buttons = {
            view: "layout",
            padding: 0,
            cols: [
                {
                    view: "button",
                    value: `${i18nHelper.getTranslation('Close')}`,
                    click: () => {
                        this.hide();
                    }
                },
                {
                    view: "button",
                    localId: `${this.idPrefix}SaveButton`,
                    value: `${i18nHelper.getTranslation('Save')}`,
                    click: () => {
                        this.submit();
                    }
                }
            ]
        };
        return buttons;
    }

    submit(): void {
        const form = webix.$$(`${this.idPrefix}DynfieldLayoutSingleAttributeEditViewForm`) as webix.ui.form;
        const values = form.getValues();
        values["page_url"] = this.pageUrl;
        values["object_type"] = this.objectType;
            // CognovisRestDynamicService.putDynfieldLayoutPageAttributes(values as IDynfieldLayoutPageAttributesBody)
            //     .then(() => {
            //         this.hide();
            //         if(this.afterSubmitAction) {
            //             this.afterSubmitAction();
            //         }
            //     });
            CognovisRestDynamicService.postDynfieldLayoutPageAttributes({requestBody:values as IDynfieldLayoutPageAttributesBody})
                .then(() => {
                    this.hide();
                    if (this.afterSubmitAction) {
                        this.afterSubmitAction();
                    }
                });

    }
}
