import { IntranetCompanyStatus, IntranetCompanyType, IntranetOfficeType } from "../../../sources/openapi";
import { container } from "tsyringe";
import BaseView from "views/baseview"
import { CompaniesTableBuilder } from "./companies-table-builder";
import { CompaniesTableTopTabs } from "./companies-table-top-tabs";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import DynCompanyCreationModal from "./dyn-company-creation-modal";


export default class Companies extends BaseView {
    
    idPrefix = "co";
    companiesTableBuilder:CompaniesTableBuilder;
    companiesTopTabs:CompaniesTableTopTabs;
    currentView = "customers";
    companyCreationModal:DynCompanyCreationModal;

    config():webix.ui.layoutConfig {
        if(this.getParam("tab", true)) {
            this.currentView = this.getParam("tab", true);
        } 
        this.companiesTableBuilder = container.resolve(CompaniesTableBuilder);
        this.companiesTopTabs = container.resolve(CompaniesTableTopTabs);
        const visibleColumns = ["company_name", "primary_contact_name", "company_type", "company_status", "last_contact"]
        const companiesTable = this.companiesTableBuilder.buildCompaniesTable(this.currentView, visibleColumns, [IntranetCompanyStatus.ACTIVE], this.getCompanyTypes(this.currentView))
        const view = {
            view: "scrollview",
            scroll: "y",
            id:`${this.idPrefix}CompaniesTable`,
            gravity:1,
            autowidth:true,
            body: {
                view:"layout",
                type:"clean",
                css:"cog-content",
                autoWidth: true,
                padding:13,
                gravity:1,
                rows: [
                    this.companiesTopTabs.getCompaniesTableTopTabs(this.currentView, () => {
                        let newCompanyType:IntranetCompanyType = IntranetCompanyType.CUSTOMER;
                        switch(this.getParam("tab", true)) {
                            case "customers":
                                newCompanyType = IntranetCompanyType.CUSTOMER;
                                break;
                            case "providers":
                                newCompanyType = IntranetCompanyType.PROVIDER;
                                break;
                            case "final-companies":
                                newCompanyType = IntranetCompanyType.FINAL_COMPANY;
                                break;
                        }
                        const dynfieldsSetup:IDynfieldSetup = {
                            pages:[
                                {
                                    objectType:"im_company",
                                    pageUrl:"/#!/companies",
                                    objectSubtypeId:newCompanyType
                                },
                                {
                                    objectType:"im_office",
                                    pageUrl:"/#!/companies",
                                    objectSubtypeId:IntranetOfficeType.MAIN_OFFICE
                                }
                            ],
                            checkForRequiredFields:true
                        };
                        const defaultValues = {"company_type_id":newCompanyType};
                        if(!this.companyCreationModal) {
                            this.companyCreationModal = this.ui(DynCompanyCreationModal) as DynCompanyCreationModal;
                        }
                        this.companyCreationModal.openModal(dynfieldsSetup, [], i18nHelper.getTranslation("New company"), defaultValues);
                    }),
                    companiesTable
                ]
            }
        };
        return view
    }

    getCompanyTypes(currentView:string):IntranetCompanyType[] {
        switch(currentView) {
            case "customers":
                return [IntranetCompanyType.CUSTOMER];
            case "providers":
                return [IntranetCompanyType.PROVIDER];
            case "final-companies":
            case "final_companies":
                return [IntranetCompanyType.FINAL_COMPANY];
        }
    }

    getCompanyTypeName(companyType:IntranetCompanyType):string {
        switch(companyType) {
            case IntranetCompanyType.CUSTOMER:
                return "customers";
            case IntranetCompanyType.PROVIDER:
                return "providers";
            case IntranetCompanyType.FINAL_COMPANY:
                return "final-companies"
        }
    }

}