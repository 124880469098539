
import { IJetApp} from "webix-jet";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { UsersTableBuilder } from "./users-table-builder";
import { CognovisRestCompanyService, ICompanyContact, IntranetBizObjectRole } from "../../../sources/openapi";
import UserPickerModal from "views/users-overview/user-picker-modal";
import UserDataModal from "views/modals/user-data-modal";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";
import DynCompanyContactModal from "views/companies-overview/dyn-company-contact-modal";
export default class UsersListPortlet extends CognovisBasicPortlet {
    
    companyId:number;
    idPrefix = "ulp";
    tableId:string;
    usersTableBuilder:UsersTableBuilder;
    userPickerModal: UserPickerModal;
    userCreationModal:DynCompanyContactModal;
    title:string;
    portletId:number;

    constructor(app:IJetApp, companyId:number, portletId?:number, title?:string) {
        super(app, {});
        this.companyId = companyId;
        this.tableId = `${this.idPrefix}UsersTable`;
        this.usersTableBuilder = container.resolve(UsersTableBuilder);
        this.portletId = portletId;
        if(title) {
            this.title = title;
        }
    }

    init():void {
        super.init();
        const portlet = {
            view: "layout",
            gravity:1,
            css:"cog-box-content",
            minHeight:400,
            borderless:true,
            localId:`${this.idPrefix}UsersListContainer`,
            rows:[
                this.getUsersTable()
            ],
        };
        this.components = [portlet];
        this.insertComponents();
    }

    getUsersTable():webix.ui.datatableConfig {
        const usersTable = this.usersTableBuilder.buildUsersTable(
            this.tableId, 
            ["firstNames"],
            false,
            <T>() => {
                return CognovisRestCompanyService.getCompanyContacts({
                    companyId:this.companyId,
                    roleIds: [IntranetBizObjectRole.FULL_MEMBER]
                })
                .then(companyContacts => {
                    return companyContacts as T[];
                });
            }
        );
        return usersTable
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: i18nHelper.getTranslation("Company contacts"), 
                    borderless: true
                },
                { 
                    view: "button", 
                    type: "icon", 
                    icon: "fal fa-plus", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true ,
                    click:() => {
                        this.addNewContact();
                    }
                }
            ]
        };
        return header;
    }

    addNewContact():void {        
        if(!this.userCreationModal) {
            this.userCreationModal = this.ui(DynCompanyContactModal) as DynCompanyContactModal;
        }
        const dynfieldsSetup:IDynfieldSetup = {
            pages:[
                {
                    objectType:"person",
                    pageUrl:"/#!/company-details",
                },
            ],
            checkForRequiredFields:true
        }
        this.userCreationModal.openModal(dynfieldsSetup, [], i18nHelper.getTranslation("New company contact"), {});
    }

}