/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetCompanyStatus {
    POTENTIAL = 41,
    INQUIRIES = 42,
    QUALIFYING = 43,
    QUOTING = 44,
    QUOTE_OUT = 45,
    ACTIVE = 46,
    DECLINED = 47,
    INACTIVE = 48,
    DELETED = 49,
    BANNED = 50,
    TO_CONTACT = 4800,
}
