/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetCostTemplate {
    ANGEBOTFREMDSPRACHENLEKTORAT_DE_FODT = 10000695,
    ANGEBOTLEKTORAT_DE_FODT = 10000697,
    ANGEBOTREDAKTION_DE_FODT = 10000699,
    ANGEBOTUEBERSETZUNG_DE_FODT = 10000701,
    ANGEBOTUEBERSETZUNG_MIT_KORREKTUR_DE_FODT = 10000703,
    KUNDENRECHNUNGCONTENT_DE_FODT = 10000705,
    KUNDENRECHNUNGUEB_DE_FODT = 10000707,
    LIEFERANTENAUFTRAG_DE_FODT = 10000709,
    LIEFERANTENGUTSCHRIFT_DE_FODT = 10000711,
    ANGEBOTFREMDSPRACHENLEKTORAT_EN_FODT = 10000722,
    ANGEBOTREDAKTION_EN_FODT = 10000724,
    ANGEBOTUEBERSETZUNG_EN_FODT = 10000726,
    ANGEBOTUEBERSETZUNG_MIT_KORREKTUR_EN_FODT = 10000728,
    KUNDENRECHNUNGCONTENT_EN_FODT = 10000730,
    KUNDENRECHNUNGUEB_EN_FODT = 10000732,
    LIEFERANTENAUFTRAG_EN_FODT = 10000734,
    LIEFERANTENGUTSCHRIFT_EN_FODT = 10000736,
    LIEFERANTENGUTSCHRIFTKORREKTUR_DE_FODT = 10000747,
    KUNDENRECHNUNGCONTENTSTORNO_DE_FODT = 10000749,
    KUNDENRECHNUNGUEBSTORNO_DE_FODT = 10000751,
    KUNDENRECHNUNGCONTENTSTORNO_EN_FODT = 10000753,
    KUNDENRECHNUNGUEBSTORNO_EN_FODT = 10000755,
    ANGEBOTLEKTORAT_EN_FODT = 10000759,
    LIEFERANTENGUTSCHRIFTKORREKTUR_EN_FODT = 10000761,
    LIEFERANTENGUTSCHRIFT_NEU_DE_FODT = 10000794,
    LHIND_ANGEBOT_UEBERSETZUNG_MIT_KORREKTUR_DE_FODT = 10000801,
    LHIND_ANGEBOT_REDAKTION_DE_FODT = 10000803,
    ANGEBOTSEOREDAKTION_DE_FODT = 10000827,
    ANGEBOT_LHIND_CONTENT_DE_FODT = 10000835,
    ANGEBOT_LHIND_UEB_DE_FODT = 10000837,
    DE_ANGEBOT_CONTENT_SEOCHECKUNDAUDIT_DE_FODT = 10000839,
    DE_ANGEBOT_CONTENT_SEOREDAKTION_DE_FODT = 10000841,
    DE_ANGEBOT_KORREKTORAT_DE_FODT = 10000843,
    DE_ANGEBOT_LEKTORAT_DE_FODT = 10000845,
    DE_ANGEBOT_SEOSCHULUNGALLGEMEIN_DE_FODT = 10000847,
    DE_ANGEBOT_UEB_2AUGEN_DE_FODT = 10000849,
    DE_ANGEBOT_UEB_4AUGEN_DE_FODT = 10000851,
    DE_ANGEBOT_UEB_SEAUEBERSETZUNG_DE_FODT = 10000853,
    DE_ANGEBOT_UEB_SEOUEBERSETZUNG_DE_FODT = 10000855,
    DE_ANGEBOT_UEB_SEOWEBSITEUEBERSETZUNG_DE_FODT = 10000857,
    DE_ANGEBOT_UEB_TRANSKREATION_DE_FODT = 10000859,
    DE_ANGEBOT_UEB_WEBSITEUEBERSETZUNG_DE_FODT = 10000861,
    DE_RECHNUNG_CONTENT_DE_FODT = 10000863,
    DE_RECHNUNG_UEB_DE_FODT = 10000865,
}
