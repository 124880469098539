import { IJetApp, JetView } from "webix-jet";
import { i18nHelper } from "../i18n-helper/i18n-helper";
import InformationBoxEditModal from "./information-box-edit-modal";

export class InformationBox extends JetView {

    infoboxConfig:ICognovisInformationBoxConfig;
    editionModal:InformationBoxEditModal;
    invoiceEditable:boolean;

    constructor(app: IJetApp, infoboxConfig:ICognovisInformationBoxConfig, invoiceEditable?:boolean) {
        super(app,{});
        this.infoboxConfig = infoboxConfig;
        this.invoiceEditable = invoiceEditable;
    }

    config():webix.ui.layoutConfig {
        return this.build();
    }

    build(): webix.ui.layoutConfig {
        const formElements = [];
        this.infoboxConfig.fields.forEach(field => {
            // Figure out value
            let valueDisplay = "";
            if(field.value) {
                valueDisplay = field.value;
            }
            if(field.value && field.value.name) {
                valueDisplay = field.value.name;
            } 
            if(field.name === "created" || field.name === "effectiveDate" || field.name === "deliveryDate") {
                valueDisplay = `${valueDisplay.split("-")[2]}.${valueDisplay.split("-")[1]}.${valueDisplay.split("-")[0]}`;
            }
            formElements.push(
                { 
                    view: "text", 
                    labelWidth: 150, 
                    label: this.getTranlatedLabel(field), 
                    name: field.name, 
                    value:valueDisplay,
                    readonly: true
                }
            );
        });
        const config:ICognovisInformationBoxConfig = {
            entityId:this.infoboxConfig.entityId,
            webixId:this.infoboxConfig.webixId,
            title:this.infoboxConfig.title,
            fields:formElements,
            saveAction:this.infoboxConfig.saveAction
        };
        this.editionModal = this.ui(InformationBoxEditModal) as InformationBoxEditModal;
        this.editionModal.windowId = `${this.infoboxConfig.title}-id`;
        const layout = this.createBasicLayout(config);
        return layout;
    }

    createBasicLayout(config:ICognovisInformationBoxConfig):webix.ui.layoutConfig {
        const ui = {
            view: "layout",
            type: "clean",
            css:"cog-box-content",
            autoHeight: true,
            rows: [
                {
                    view: "layout",
                    height:44,
                    css: "cog-box-header",
                    cols: [
                        { 
                            view: "template", 
                            type: "header",
                            css: "cog-box-header",  
                            template: config.title,
                            borderless: true 
                        },
                        { 
                            view: "button", 
                            type: "icon", 
                            icon: "far fa-edit", 
                            align: "right", 
                            css:"action-icon-grey",
                            width: 28, 
                            hidden:!this.invoiceEditable,
                            borderless: true,
                            click:() => {
                                this.openEditModal()
                            }
                        }
                    ]
                },
                {
                    view: "layout",
                    padding:13,
                    //css: "cog-box-content",
                    id: config.webixId,
                    rows:config.fields
                },
            ]
        }
        return ui;
    }

    getTranlatedLabel(field:ICognovisInformationBoxField):string {
        let label = "";
        if(field.translationKey) {
            label = i18nHelper.getTranslation(field.translationKey);
        }
        if(field.name && label == "") {
            label = i18nHelper.getTranslation(field.name);
        }
        if(field.label) {
            label = field.label;
        }
        return label;
    }

    openEditModal():void {
        // Figure out which fields are editable
        const editableFields = this.getEditableFields(this.infoboxConfig.fields);
        const window = (this.editionModal.getRoot() as webix.ui.window);
        this.editionModal.entityId = this.infoboxConfig.entityId;
        this.editionModal.setElements(editableFields);
        this.editionModal.setTitle(this.infoboxConfig.editModalTitle);
        this.editionModal.saveAction = this.infoboxConfig.saveAction;
        
        window.show();
    }

    closeEditModal():void {
        this.editionModal.hideModal();
    }

    getEditableFields(fields:ICognovisInformationBoxField[]):ICognovisInformationBoxField[] {
        const fieldsWithLabels = fields.map(field => {
            field.label = this.getTranlatedLabel(field);
            return field;
        });
        return fieldsWithLabels.filter(field => field.editConfig)
    }
    
    

}
