import * as usermanager from "@xbs/usermanager";
import { CognovisRestService, CognovisRestUsersManagerService, IUser } from "../../../sources/openapi";
import { promise } from "@xbs/webix-pro";
import { User } from "webix-jet/dist/types/plugins/User";
import { url } from "inspector";

export default class UsersManagerBackend extends usermanager.services.Backend {

    static rulesAndRoles = [];
    static usersAndRoles = [];
    static rulesOnly = [];
    static rolesOnly = [];
    static users:IUser[] = [];
    static metaLoaded:boolean = false;
    rolesColors = [
        "#e14982",
        "#37bf57",
        "#c983c7",
        "#f0ad1c",
        "#9a9cab",
        "#96856a",
        "#7383ae",
        "#f5c78a",
        "#f84bbe",
        "#44f0ef",
        "#db6a6",
        "#31fba1"
    ];

    users():Promise<IChatUser[]> {
        return CognovisRestUsersManagerService.getUserManagerMembers({
        })
        .then(internalCompanyMembers => {
            const employees = internalCompanyMembers.map(employee => {
                if(employee.user) {
                    return {
                        avatar: employee.portrait_url,
                        email: employee["email"],
                        id: employee.user.id,
                        name:employee.user.name,
                        status: this.getUserActivityStatus(employee)             
                    }
                }
            });
            // Sort the users, so that 'active' ones will be displayed before 'not active'
            const sorted = [
                ...employees.filter(emp => emp.status === 1),
                ...employees.filter(emp => emp.status === 0)
            ];
            (UsersManagerBackend as any).users = sorted;
            return promise.resolve(sorted);
        });
    }

    updateUser(idUser:number, userData:any, event):any {
        if(userData.rules) {
            const newRule = this.getRuleAndActionType(idUser, userData.rules);
            if(newRule.action === "add" && newRule.rule) {
                UsersManagerBackend.rulesAndRoles.push([idUser,newRule.idRule]);
                CognovisRestUsersManagerService.postProfilePrivileges({
                    requestBody:{
                        object_id:idUser,
                        privilege_name:newRule.rule
                    }
                });
            }
            if(newRule.action === "remove" && newRule.rule) {
                CognovisRestUsersManagerService.deleteProfilePrivileges({
                    objectId:idUser,
                    privilegeName:newRule.rule
                })
                .then(res => {
                    let index = -1;
                    UsersManagerBackend.rulesAndRoles.forEach((cur,i) => {
                        if(cur[0] === idUser && cur[1] === newRule.idRule) {
                            index = i;
                        }
                    });
                    delete UsersManagerBackend.rulesAndRoles[index];
                });
            } 
        }
        if(userData.roles) {
            if(idUser["row"]) {
                idUser = idUser["row"];
            }
            const newRole = this.getRoleAndActionType(idUser, userData.roles);
            if(newRole.action === "add" && newRole.role) {
                CognovisRestUsersManagerService.postUserProfile({requestBody:{
                    user_id:idUser,
                    profile_id:newRole.role
                }})
                UsersManagerBackend.usersAndRoles.push([idUser,newRole.role]);
            }
            if(newRole.action === "remove" && newRole.role) {
                CognovisRestUsersManagerService.deleteUserProfile({
                    userId:idUser,
                    profileId:newRole.role
                })
                .then(() => {
                    let index = -1;
                    UsersManagerBackend.usersAndRoles.forEach((cur,i) => {
                        if(cur[0] === idUser && cur[1] === newRole.role) {
                            index = i;
                        }
                    });
                    delete UsersManagerBackend.usersAndRoles[index];
                });
            }
        }
    }

    updateRole(idRole:number, data:{rules:string[]}):void {
        const newRule = this.getRuleAndActionType(idRole, data.rules);
        if(newRule.action === "add") {
            UsersManagerBackend.rulesAndRoles.push([idRole,newRule.idRule]);
            CognovisRestUsersManagerService.postProfilePrivileges({
                requestBody:{
                    object_id:idRole,
                    privilege_name:newRule.rule
                }
            });
        }
        if(newRule.action === "remove") {
            CognovisRestUsersManagerService.deleteProfilePrivileges({
                objectId:idRole,
                privilegeName:newRule.rule
            })
            .then(res => {
                let index = -1;
                UsersManagerBackend.rulesAndRoles.forEach((cur,i) => {
                    if(cur[0] === idRole && cur[1] === newRule.idRule) {
                        index = i;
                    }
                });
                delete UsersManagerBackend.rulesAndRoles[index];
            });
        }
        if(data["color"] || data["icon"]) {
            CognovisRestUsersManagerService.putProfile({
                profileId:idRole,
                requestBody:{
                    color:data["color"],
                    icon:data["icon"]
                }
            })
            .then(res => {

            });
        }
    }

    roles():Promise<any[]> {
        return CognovisRestUsersManagerService.getUsersProfiles()
        .then(usersRoles => {
            const mapped = usersRoles.map((ur, index) => {
                return {
                    id:ur.profile.id,
                    name:ur.profile.name,
                    short:"",
                    long:"",
                    icon:ur.icon,
                    color:ur.color
                }
            });
            return promise.resolve(mapped);
        });
    }

    rules():Promise<any[]> {
        return CognovisRestUsersManagerService.getProfilePrivileges()
        .then(profilesWithPriviliges => {
            const privliges = [];
            const privilegesOnly = [];
            const mapped = profilesWithPriviliges.map(pp => {
                pp.profile_privileges.map((privlige,index) => {
                    const toBePushed = {
                        id:privlige["privilege_id"],
                        short:privlige.privilege_name,
                        long:privlige.privilege_name
                    };
                    privliges.push(toBePushed);
                    if(privilegesOnly.filter(p => p.short === privlige.privilege_name).length === 0) {
                        privilegesOnly.push(toBePushed);
                    }
                });
            });
            UsersManagerBackend.rulesOnly = privilegesOnly;
            return promise.resolve(privilegesOnly);
        });
    }

    getUserActivityStatus(user:IUser):number {
        const twentyMinutes = 1200*20;
        if(user.seconds_since_last_request && Number(user.seconds_since_last_request) <= twentyMinutes) {
            return 1
        } else {
            return 0
        }
    }

    meta():Promise<any[]> {
        const rulesAndRoles = [];
        rulesAndRoles["RoleRule"] = [];
        rulesAndRoles["UserRole"] = [];
        rulesAndRoles["RuleRole"] = [];
        rulesAndRoles["UserRule"] = [];
        return CognovisRestUsersManagerService.getUsersPrivileges()
        .then(usersWithPriviliges => {
            usersWithPriviliges.map(up => {
                //UsersManagerBackend.users.push(up["user"]);
                up.user_privileges.map(privilege => {
                    if(privilege.permitted_p) {
                        rulesAndRoles["UserRule"].push([up.user["id"], privilege["privilege_id"]]);
                    }
                });
            });
            UsersManagerBackend.rulesAndRoles = rulesAndRoles["UserRule"];
            return CognovisRestUsersManagerService.getUsersRoles()
            .then(users => {
                console.log("users roles")
                users.map(user => {
                    user.user_roles.map(userRoles => {
                        rulesAndRoles["UserRole"].push([user.user.user.id, userRoles.group_id]);
                    });
                });
                UsersManagerBackend.usersAndRoles = rulesAndRoles["UserRole"];
                return CognovisRestUsersManagerService.getProfilePrivileges()
                .then(profilesWithPriviliges => {
                    profilesWithPriviliges.map(pp => {
                        pp.profile_privileges.map((privlige,index) => {
                            if(privlige.permitted_p) {
                                rulesAndRoles["RoleRule"].push([pp.profile.id,privlige["privilege_id"]]);
                            }
                        });
                    });
                    UsersManagerBackend.rulesAndRoles = rulesAndRoles["RoleRule"];
                    UsersManagerBackend.metaLoaded = true;
                    return promise.resolve(rulesAndRoles)
                })
            });
        })

    }

    getRuleAndActionType(roleId:number, newRules:string[]):{action:string, rule:string, idRule:number} {
        let rule = "";
        let action;
        let idRule;
        const currentRoleRules = [];
        UsersManagerBackend.rulesAndRoles.forEach(arrs => {
            if(arrs[0] === roleId) {
                currentRoleRules.push(arrs[1]);
            }
        });
        newRules.forEach(newRule => {
            if(currentRoleRules.indexOf(newRule) === -1) {
                idRule = newRule;
                rule = this.getRuleNameFromId(newRule);
                action = "add";
            }
        });
        if(!rule) {
            currentRoleRules.forEach(cr => {
                if(newRules.indexOf(cr) === -1) {
                    idRule = cr;
                    rule = this.getRuleNameFromId(cr);
                    action = "remove";
                }
            });
        } 
        return {action:action, rule:rule, idRule:idRule}
    }

    getRoleAndActionType(userId:number, newRoles:string[]):{action:string, role:number} {
        let role;
        let action;
        const currentUserRoles = [];
        UsersManagerBackend.usersAndRoles.forEach(arrs => {
            if(arrs[0] === userId) {
                currentUserRoles.push(arrs[1]);
            }
        });
        newRoles.forEach(nr => {
            if(currentUserRoles.indexOf(nr) === -1) {
                role = nr;
                action = "add";
            }
        });
        if(!role) {
            currentUserRoles.forEach(cr => {
                if(newRoles.indexOf(cr) === -1) {
                    role = cr;
                    action = "remove";
                }
            });
        } 
        return {action:action, role:role}
    }

    getRuleNameFromId(id:string):string {
        let name:string;
        UsersManagerBackend.rulesOnly.map(ro => {
            if(ro.id === id) {
                name = ro.short
            }
        });
        return name
    }

}