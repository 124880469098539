import BaseView from "../baseview";
import { IntranetProjectStatus, IntranetProjectStatusIds, WebixPortalTranslationService} from "../../openapi/index";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { ProjectsOverviewTopTabs } from "./top-tabs";
import { UserProfile } from "../../modules/cognovis-profile/profiles-types";
import { ProjectsTableBuilder } from "./projects-table-builder";
import CognovisProfile from "../../modules/cognovis-profile/cognovis-profile";
import ContactAgainModal from "./contact-again-modal";
export default class PotentialProjects extends BaseView {

    projectOverviewTobTabs: ProjectsOverviewTopTabs;
    projectsTableBuilder:ProjectsTableBuilder;
    idPrefix = "po";
    contactAgainModal:ContactAgainModal;
    
    config(): webix.ui.scrollviewConfig {
        this.projectOverviewTobTabs = container.resolve(ProjectsOverviewTopTabs);
        this.projectsTableBuilder = container.resolve(ProjectsTableBuilder);
        const rows = this.getPotentialProjectsTable();
        const projectTables = {
            view: "scrollview",
            scroll: "y",
            gravity:1,
            autowidth:true,
            body: {
                view:"layout",
                type:"clean",
                css:"cog-content",
                autoWidth: true,
                padding:13,
                gravity:1,
                rows: [
                    this.projectOverviewTobTabs.getTopTabs("potential-projects"),
                    {
                        view: "layout",
                        rows: rows
                    },
                ],
            }
        } as webix.ui.scrollviewConfig;
        return projectTables;
    }

    init():void {
        if(CognovisProfile.isUserInGivenGroup(UserProfile.FREELANCER) && !CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
            setTimeout(() => {
                //CognovisNavigator.navigateTo("/main/assignments.my-assignments");
            },1);
        }
        const projectStatusIds: IntranetProjectStatusIds = [];
        if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
            projectStatusIds.push(IntranetProjectStatus.INQUIRING);   
        } else {
            projectStatusIds.push(IntranetProjectStatus.POTENTIAL);
        }
        WebixPortalTranslationService.getTransProjects({
            projectStatusId: projectStatusIds,
        })
        .then((requstedProjects) => {
            const numberOfRequests = requstedProjects.length;
            this.projectOverviewTobTabs.updateRfqBadge(numberOfRequests);
            if(!this.contactAgainModal) {
                this.contactAgainModal = this.ui(ContactAgainModal) as ContactAgainModal;
            }
            webix["contactAgainModal"] = this.contactAgainModal;
        });
    }


    getPotentialProjectsTable():webix.ui.layoutConfig[] {
            // Potential Projects
            const potentialProjectsTableColumns = [
                "type", "project_nr",  "customer", "project_name", "source_language", "target_language", "project_manager", "cost_quotes_cache", "last_contacted", "project_status", "action-accept", "action-reject", "action-contact-again"
            ]
            const potentialProjectsStatusesIds = [IntranetProjectStatus.POTENTIAL];
            const potentialProjectsTable = this.projectsTableBuilder.buildProjectsTable("PotentialProjects", potentialProjectsTableColumns, potentialProjectsStatusesIds, false, [IntranetProjectStatus.INQUIRING]);
            const rows = [
                {
                    view:"template",
                    type:"header",
                    css: "cog-box-header-no-border", 
                    template:i18nHelper.getTranslation("Potential_Projects"),
                },
                potentialProjectsTable,
                { view: "spacer", height: 20 },
            ];
            return rows as webix.ui.layoutConfig[]
    }
}
