import { IJetApp } from "webix-jet";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";
import { container } from "tsyringe";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import ReportTableBuilder from "./report-table-builder";

export default class ReportPortlet extends CognovisBasicPortlet  {

    idPrefix = "rp";
    objectId:number | string;
    objectName:string;
    reportTableBuilder:ReportTableBuilder;
    title = "";
    portletId:number;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    additionalEvents:{[key:string]:Function};

    constructor(app:IJetApp, queryParams:{reportId:number, reportName:string}, objectName:string, portletId:number, title:string, additionalColumns:{id:string, template:Function | string}[] = [], additionalEvents:{[key:string]:Function}) {
        super(app, {});
        this.objectId = queryParams.reportId;
        this.objectName = queryParams.reportName;
        this.portletId = portletId;
        if(title) {
            this.title = title;
        } else {
            this.title = this.objectName;
        }   
        if(additionalEvents) {
            this.additionalEvents = additionalEvents;
        }
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.reportTableBuilder = new ReportTableBuilder(this.app, `${this.idPrefix}ReportDatatable`, {reportId:this.objectId, reportName:this.objectName}, this.objectName, additionalColumns, {
            adjust:false
        }, additionalEvents);
    }

    init():void {
        super.init();
        const layout = {
            view:"layout",
            rows:[
                {
                    view:"layout",
                    autoheight:true,
                    minHeight:600,
                    autowidth:true,
                    gravity:1,
                    rows:[
                        this.reportTableBuilder.getDatatable()
                    ]
                }
            ]
        }
        this.components = [layout];
        this.insertComponents();
        this.reportTableBuilder.buildReportTable();
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            height:44,
            css: "cog-box-header", 
            cols: [
                { 
                    view: "template", 
                    id:`${this.idPrefix}ReportDatatableHeader`,
                    type: "header",
                    css: "cog-box-header",  
                    template: this.title, 
                    borderless: true 
                }
            ]
        };
        return header;
    }
}

