import { JetView } from "webix-jet";
import "./success-error-message.style.css";

export default class ErrorMessagePopup extends JetView {
  config(): webix.ui.popupConfig {
    return {
      view: "popup",
      width: 500,
      height: 120,
      position: "top",
      css: "popup__wrapper",
      body: ({
        view: "layout",
        type: "clean",
        rows: [
          {
            view: "template",
            css: "error",
            template: `
            <h4>Error!</h4>
            <p>Your form wasn't submitted</p>`,
          },
        ],
      } as unknown) as string,
    };
  }
}
