/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetTranslationQualityType {
    SPELLING = 7002,
    GRAMMAR = 7004,
    INTERPUNCTUATION = 7006,
    LOCALIZATION = 7008,
    STYLE = 7010,
    FREELANCER_RATED = 7012,
    COLLABORATION = 7014,
    INHALT = 7015,
    LESBARKEIT_STIL = 7016,
    FACHWISSEN_TERMINOLOGIE = 7017,
    BEACHTUNG_DES_BRIEFINGS = 7018,
    RECHTSCHREIBUNG_GRAMMATIK = 7019,
    ERFLLUNG_DER_SEOANFORDERUNGEN = 7020,
}
