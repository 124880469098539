import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { CognovisRestCompanyService, CognovisRestInvoiceService, ICrFile, IInvoice, IntranetCostType, IntranetProjectStatus, ITransProject, IUser, WebixNotificationStatus, WebixNotificationType, WebixPortalNotificationService, WebixPortalTranslationService } from "../../../sources/openapi";
import { JetView } from "webix-jet";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";
import { config } from "../../config";
import CognovisFileUploaderModal from "../../../sources/modules/file-helpers/cognovis-file-uploader-modal";

export default class CustomerProjectView extends JetView {

    currentProject:ITransProject;
    idPrefix = "cspv";
    currentFeeAndTimingsMode = "quote";
    quotes:IInvoice[];
    invoices:IInvoice[];
    fileUploaderModal:CognovisFileUploaderModal;
    currentUserId:number;
    sidebar:webix.ui.sidebar;

    init():void {
        this.fileUploaderModal = this.ui(CognovisFileUploaderModal) as CognovisFileUploaderModal;
        this.sidebar = webix.$$("sidebarMainSidebar") as webix.ui.sidebar;
        const loginInfo = webix.storage.local.get("wjet_user");
        const fakedUserObj = webix.storage.session.get("cognovis_faked_user_data");
        let valueUserId = loginInfo.user_id;
        if (fakedUserObj) {
            valueUserId = fakedUserObj.user_id;
        }
        this.currentUserId = valueUserId;
    }

    config():Promise<webix.ui.layoutConfig> {
        const projectId = Number(this.getParam("project_id", false));
        return WebixPortalTranslationService.getTransProjects({
            projectId:projectId
        })
        .then(projects => {
            if(projects[0]) {
                this.currentProject = projects[0];
                const costTypeIds = [IntranetCostType.CUSTOMER_INVOICE, IntranetCostType.QUOTE];
                return CognovisRestInvoiceService.getInvoice({
                    costTypeIds:costTypeIds,
                    projectId:this.currentProject.project.id
                })
                .then(financialDocuments => {
                    const quotes = financialDocuments.filter(invoice => invoice.cost_type.id === IntranetCostType.QUOTE);
                    this.quotes = quotes;
                    const invoices = financialDocuments.filter(invoice => invoice.cost_type.id === IntranetCostType.CUSTOMER_INVOICE);
                    this.invoices = invoices;
                    const mainLayout = this.getMainLayout(this.currentProject, financialDocuments);
                    return mainLayout
                })
                

            }
        });
    }

    getMainLayout(project:ITransProject, financialDocuments:IInvoice[]):webix.ui.layoutConfig {
        const displayedUser = project.project_lead;
        const displayedUserRoleInAssignment = "Project Manager";
        const layout = {
            view:"scrollview",
            gravity:1,
            autowidth:true,
            id:`${this.idPrefix}MainContainer`,
            body:{
                padding:13,
                css:"cog-content",
                rows:[
                    {
                        view:"toolbar",
                        css:"cog-content",
                        cols:[
                            {
                                view:"button",
                                type:"icon",
                                align:"left",
                                icon: "mdi mdi-arrow-left",
                                width: 30,
                                click:() => {
                                    CognovisNavigator.back("main/project-overview.request");
                                }
                            }
                        ]
                    },
                    this.getHeader(project.project_nr + ': ' + project.project.name),
                    {
                        view:"spacer",
                        height:13
                    },
                    {
                        view:"layout",
                        gravity:1,
                        cols:[
                            this.getProjectManagerInfoPortlet(displayedUser, displayedUserRoleInAssignment),
                            {
                                view:"spacer",
                                width:8
                            },
                            {
                                gravity:70,
                                view:"layout",
                                rows:[
                                    {
                                        view:"layout",
                                        cols:[
                                            this.getProjectInfoPortlet(project),
                                            { view:"spacer", width:8},
                                            this.getFeeAndTimingsPortlet(project, financialDocuments)
                                        ]
                                    },
                                    { view:"spacer", height:10},
                                    {
                                        view:"layout",
                                        cols:[
                                            this.getNotesPortlet(project["description"]),
                                            { view:"spacer", width:8},
                                            this.getActionButtonsPortlet(project, financialDocuments)
                                        ]
                                    }
                                ]
                            }                            
                        ]
                    },
                ]
            }
        };
        return layout;
    }

    
    getHeader(projectName:string):webix.ui.layoutConfig {
        const header = 
        {
            type:"header",
            autowidth:true,
            gravity:1,
            css:"cog-header",
            template:`${projectName}`,
            borderless: true 
        };
        return header;
    }

    getProjectManagerInfoPortlet(user:IUser, roleInAssignment:string):webix.ui.layoutConfig {
        const portlet = {
            view:"layout",
            gravity:20,
            autowidth:true,
            margin:5,
            css:"cog-box-content",
            rows:[
                {
                    type:"header",
                    css:"cog-header",
                    template:roleInAssignment
                },
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                {
                    view:"spacer",
                    height:20,
                    borderless:true
                },
                {
                    template:`<div style='text-align:center;' class='anon-avatar-bg'><img alt="" style='margin:0 auto; border-radius:50%; border:2px solid #ffffff; width:120px; height:120px;' src='${config.serverUrl}/intranet/download/user/${user.user.id}/portrait.jpg'/></div>`,
                    borderless:true,
                    height:160
                },
                {
                    borderless:true,
                    height:25,
                    template:`<span style='font-size:1.1em'>${user.user.name}</span>`
                },
                {
                    borderless:true,
                    height:25,
                    template:`<span style='font-size:1.1em'>${roleInAssignment}</span>`
                },
                {
                    view:"spacer",
                    height:6,
                    borderless:true
                },
                {
                    view:"template",
                    id:`${this.idPrefix}ProjectManagerWorkPhone`,
                    borderless:true,
                    height:25,
                    template:""
                },
                {
                    view:"template",
                    id:`${this.idPrefix}ProjectManagerEmail`,
                    borderless:true,
                    height:25,
                    template:""
                },
                {
                    view:"spacer",
                    height:20,
                    borderless:true
                },
            ]
        };
        this.updateProjectManagerPhoneAndEmail(user.user.id);
        return portlet;
    }

    getProjectInfoPortlet(project:ITransProject):webix.ui.layoutConfig {
        const portlet = {
            view:"layout",
            gravity:50,
            autowidth:true,
            css:"cog-box-content",
            height:200,
            rows:[
                {
                    type:"header",
                    css:"cog-header",
                    template:"Project Information"
                },
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                {
                    view:"layout",
                    rows:this.getProjectInfoFields(project)
                }
            ]
        };
        return portlet;
    }

    getProjectInfoFields(project:ITransProject):webix.ui.textConfig[] {
        const commaSeperatedTargetLanguages = project.target_language.map(tl => tl.name).join(",");
        const projectInfoFields:webix.ui.layoutConfig[] = [
            this.createField(i18nHelper.getTranslation("project_type"), project.project_type?.name),
            this.createField(i18nHelper.getTranslation("source_language"), project.source_language?.name),
            this.createField(i18nHelper.getTranslation("target_languages"), commaSeperatedTargetLanguages),
            this.createField(i18nHelper.getTranslation("project_status"), project.project_status?.name),
        ];  
        return projectInfoFields
    }

    getFeeAndTimingsPortlet(project:ITransProject, financialDocuments:IInvoice[]):webix.ui.layoutConfig {
        const portlet = {
            gravity:50,
            view:"layout",
            localId:`${this.idPrefix}FeeAndTimingsPortlet`,
            css:"cog-box-content",
            rows:[
                {
                    type:"header",
                    css:"cog-header",
                    template:"Fee and Timings"
                },
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                {
                    view:"layout",
                    rows:this.getFeeAndTimingsFields(project, financialDocuments)
                },
                {
                    view:"spacer",
                    height:20
                },
                {
                    view:"layout",
                    gravity:1,
                    cols:[
                        {
                            view:"spacer",
                            gravity:3,
                        },
                        {
                            view:"button",
                            gravity:46,
                            localId:`${this.idPrefix}QuoteInvoiceToggler-quote`,
                            css:((this.currentFeeAndTimingsMode === "quote") ? 'quote-invoice-toggler' : 'quote-invoice-toggler-disabled'),
                            value:i18nHelper.getTranslation("Quote"),
                            click:() => {
                                this.toggleFeeAndTimingsMode("quote");
                            }
                        },
                        {
                            view:"button",
                            gravity:46,
                            localId:`${this.idPrefix}QuoteInvoiceToggler-invoice`,
                            css:((this.currentFeeAndTimingsMode === "invoice") ? 'quote-invoice-toggler' : 'quote-invoice-toggler-disabled'),
                            value:i18nHelper.getTranslation("Invoice"),
                            click:() => {
                                this.toggleFeeAndTimingsMode("invoice");
                            }
                        },
                        {
                            view:"spacer",
                            gravity:3,
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:20
                }
            ]
        };
        return portlet;
    }


    getFeeAndTimingsFields(project:ITransProject, financialDocuments:IInvoice[]):webix.ui.textConfig[] {
        // Check if we have single or multiple quotes
        const quotes = financialDocuments.filter(invoice => invoice.cost_type.id === IntranetCostType.QUOTE);
        const invoices = financialDocuments.filter(invoice => invoice.cost_type.id === IntranetCostType.CUSTOMER_INVOICE);
        let quoteNameDisplay = "";
        let quotesTotalPriceFormatted = "0";
        if(quotes.length > 0) {
            quoteNameDisplay = `<span style='cursor:pointer; color:#59a8c2;'>${quotes[0].invoice.name}</span>`;
            if(quotes.length > 1) {
                quoteNameDisplay = `<span style='cursor:pointer; color:#59a8c2;'>${quotes.length} ${quotes[0].cost_type.name}</span>`
            }
            const quotesTotalPrice = quotes.reduce((accum:number,invoice:IInvoice) => accum +  Number(invoice.amount),0);
            quotesTotalPriceFormatted = PriceHelper.formatPrice(quotesTotalPrice);
        }
        let invoiceNameDisplay = "";
        let invoicesTotalPriceFormatted = "";
        if(invoices.length > 0) {
            invoiceNameDisplay = `<span style='cursor:pointer; color:#59a8c2;'>${invoices[0].invoice.name}</span>`;
            if(invoices.length > 1) {
                invoiceNameDisplay = `<span style='cursor:pointer; color:#59a8c2;'>${invoices.length} ${invoices[0].cost_type.name}</span>`;
            }   
            const invoicesTotalPrice = invoices.reduce((accum:number,invoice:IInvoice) => accum +  Number(invoice.amount),0);
            invoicesTotalPriceFormatted = PriceHelper.formatPrice(invoicesTotalPrice);
        }
        const feeAndTimingFields:webix.ui.layoutConfig[] = [
            this.createFieldForFeeAndTimings(i18nHelper.getTranslation("Quote_number"), quoteNameDisplay, "quote"),
            this.createFieldForFeeAndTimings(i18nHelper.getTranslation("Net_amount"),quotesTotalPriceFormatted , "quote"),
            this.createFieldForFeeAndTimings(i18nHelper.getTranslation("Processing_time"), project.processing_time.toString(), "quote"),
            this.createFieldForFeeAndTimings(i18nHelper.getTranslation("Invoice_number"), invoiceNameDisplay, "invoice"),
            this.createFieldForFeeAndTimings(i18nHelper.getTranslation("Net_amount"), invoicesTotalPriceFormatted, "invoice"),
            this.createFieldForFeeAndTimings(i18nHelper.getTranslation("Processing_time"), project.processing_time.toString(), "invoice")
        ];  
        return feeAndTimingFields
    }

    toggleFeeAndTimingsMode(newMode:string):void {
        if((newMode === "quote" && this.quotes.length > 0) || newMode === "invoice" && this.invoices.length > 0) {
            this.currentFeeAndTimingsMode = newMode;
            const feeAndTimingsPortlet = this.$$(`${this.idPrefix}FeeAndTimingsPortlet`) as webix.ui.layout;
            const childs = feeAndTimingsPortlet.queryView({view:"layout"}, "all");
            childs.map((field:webix.ui.layout) => {
                if(typeof field.config["visibleInMode"] !== 'undefined') {
                    if(field.config["visibleInMode"] === this.currentFeeAndTimingsMode) {
                        field.show();
                    } else {
                        field.hide();
                    }
                }
            });
            const buttonQuote = this.$$(`${this.idPrefix}QuoteInvoiceToggler-quote`) as webix.ui.button;
            const buttonInoice = this.$$(`${this.idPrefix}QuoteInvoiceToggler-invoice`) as webix.ui.button;
            webix.html.removeCss(buttonQuote.getNode(), "quote-invoice-toggler-disabled");
            webix.html.removeCss(buttonInoice.getNode(), "quote-invoice-toggler-disabled");
            webix.html.removeCss(buttonQuote.getNode(), "quote-invoice-toggler");
            webix.html.removeCss(buttonInoice.getNode(), "quote-invoice-toggler");
            const button = this.$$(`${this.idPrefix}QuoteInvoiceToggler-${newMode}`) as webix.ui.button;
            webix.html.addCss(button.getNode(), "quote-invoice-toggler");
        }
    }

    createField(label:string, value:string):webix.ui.layoutConfig {
        const labelAndValuePair = {
            css:"single-assignment-infobox",
            view:"layout",
            cols:[
                {
                    view:"spacer",
                    width:20,
                },
                {
                    view:"label",
                    label:label,
                },
                {
                    view:"label",
                    gravity:2.5,
                    label:value,
                }
            ]
        };
        return labelAndValuePair;
    }

    createFieldForFeeAndTimings(label:string, value:string, visibleInMode?:string):webix.ui.layoutConfig {
        let hidden = false;
        if(visibleInMode) {
            if(visibleInMode !== this.currentFeeAndTimingsMode) {
                hidden = true;
            }
        }
        const labelAndValuePair = {
            css:"single-assignment-infobox",
            view:"layout",
            hidden:hidden,
            cols:[
                {
                    view:"spacer",
                    width:20,
                },
                {
                    view:"label",
                    label:label,
                },
                {
                    view:"label",
                    gravity:2.5,
                    label:value,
                    on: {
                        onItemClick:() => {
                            if(visibleInMode === "quote") {
                                if(this.quotes.length === 1) {
                                    CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${this.quotes[0].invoice.id}`);
                                } else {
                                    CognovisNavigator.navigateTo(`main/financial-overview.financial-overview?project_id=${this.currentProject.project.id}`);
                                }
                            }
                            if(visibleInMode === "invoice") {
                                if(this.invoices.length === 1) {
                                    CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${this.invoices[0].invoice.id}`);
                                } else {
                                    CognovisNavigator.navigateTo(`main/financial-overview.financial-overview?project_id=${this.currentProject.project.id}`);
                                }
                            }
                        }
                    }
                }
            ]
        };
        if(visibleInMode) {
            labelAndValuePair["visibleInMode"] = visibleInMode;
        }
        return labelAndValuePair
    }

    getNotesPortlet(projectNote:string):webix.ui.layoutConfig {
        const portlet = {
            gravity:60,
            view:"layout",
            css:"cog-box-content",
            rows:[
                {
                    type:"header",
                    css:"cog-header",
                    template:"Additional comment"
                },
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                {
                    view:"scrollview",
                    body:{
                        template:projectNote,
                        borderless:true,
                        padding:20,
                    }
                }
            ]
        };
        return portlet;
    }

    getActionButtonsPortlet(project:ITransProject, financialDocuments:IInvoice[]):webix.ui.layoutConfig {
        const potentialStatusesIds = [IntranetProjectStatus.POTENTIAL, IntranetProjectStatus.QUOTE_OUT, IntranetProjectStatus.QUOTING];
        const requestedStatusesIds = [
            IntranetProjectStatus.POTENTIAL,
            IntranetProjectStatus.QUOTE_OUT, IntranetProjectStatus.QUOTING, 
            IntranetProjectStatus.INQUIRING, IntranetProjectStatus.QUALIFYING
        ];
        const deliveredStatusIds = [IntranetProjectStatus.CLOSED, IntranetProjectStatus.DELIVERED, IntranetProjectStatus.INVOICED];
        const quotes = financialDocuments.filter(invoice => invoice.cost_type.id === IntranetCostType.QUOTE);
        const quotesTotalPrice = quotes.reduce((accum:number,invoice:IInvoice) => accum +  Number(invoice.amount),0);
        const portlet = {
            gravity:40,
            view:"layout",
            css:{
                "background-color":"#ffffff"
            },
            rows:[
                {
                    type:"header",
                    css:"cog-header",
                    template:"Actions"
                },
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                {
                    view:"layout",
                    padding:20,
                    rows:[
                        {
                            view:"button",
                            value:i18nHelper.getTranslation("Accept_quote"),
                            id:`${this.idPrefix}ActionsAcceptQuote`,
                            height:30,
                            hidden:quotesTotalPrice === 0 || potentialStatusesIds.indexOf(project.project_status.id) === -1,
                            css:"assignment-actions-accept",
                            click:() => {
                                this.attemptToUpdateProjectStatus(this.currentProject, IntranetProjectStatus.OPEN);
                            },
                        },
                        {
                            view:"button",
                            value:(requestedStatusesIds.indexOf(project.project_status.id) === -1) ? i18nHelper.getTranslation("Download_original_files") : i18nHelper.getTranslation("Update_original_files"),
                            id:`${this.idPrefix}ActionsUpdateOriginalFiles`,
                            height:30,
                            css:"assignment-actions-normal",
                            click:() => {
                                const projectId = project.project.id;
                                const filesTranslation = i18nHelper.getTranslation("Original_files");
                                const modalTitle = `${project.project.name} ${filesTranslation}`;
                                if(requestedStatusesIds.indexOf(project.project_status.id) === -1) {
                                    this.fileUploaderModal.openModal(projectId, "read", {allowFolderSelection:false, entityFolderId:project.project_folder.id, defaultFolderName:"Original"}, modalTitle);
                                } else {
                                    this.fileUploaderModal.openModal(projectId, "write", {allowFolderSelection:false, entityFolderId:project.project_folder.id, defaultFolderName:"Original"}, modalTitle, "", (files:ICrFile[]) => {
                                        if(files.length > 0 && this.currentUserId !== project.project_lead.user.id) {
                                            WebixPortalNotificationService.postWebixNotification({    
                                                requestBody: { 
                                                    context_id: project.project.id,
                                                    recipient_id: project.project_lead.user.id,
                                                    notification_type_id: WebixNotificationType.REQUEST_QUOTE_CHANGE,
                                                    notification_status_id:WebixNotificationStatus.IMPORTANT,
                                                    message:`Customer ${project.company.name} uploaded new original files to ${project.project.name}`
                                                }      
                                            });     
                                        }
                                    });
                                }
                            },
                        },
                        {
                            view:"button",
                            value:(requestedStatusesIds.indexOf(project.project_status.id) === -1) ? i18nHelper.getTranslation("Download_reference_files") : i18nHelper.getTranslation("Update_reference_files"),
                            id:`${this.idPrefix}ActionsUpdateReferenceFiles`,
                            height:30,
                            css:"assignment-actions-normal",
                            click:() => {
                                const projectId = project.project.id;
                                const filesTranslation = i18nHelper.getTranslation("Reference_files");
                                const modalTitle = `${project.project.name} ${filesTranslation}`;
                                if(requestedStatusesIds.indexOf(project.project_status.id) === -1) {
                                    this.fileUploaderModal.openModal(projectId, "read", {allowFolderSelection:false, entityFolderId:project.project_folder.id, defaultFolderName:"Projektinfos extern"}, modalTitle);
                                } else {
                                    this.fileUploaderModal.openModal(projectId, "write", {allowFolderSelection:false, entityFolderId:project.project_folder.id, defaultFolderName:"Projektinfos extern"}, modalTitle, "", (files:ICrFile[]) => {
                                        if(files.length > 0 && this.currentUserId !== project.project_lead.user.id) {
                                            WebixPortalNotificationService.postWebixNotification({    
                                                requestBody: { 
                                                    context_id: project.project.id,
                                                    recipient_id: project.project_lead.user.id,
                                                    notification_type_id: WebixNotificationType.REQUEST_QUOTE_CHANGE,
                                                    notification_status_id:WebixNotificationStatus.IMPORTANT,
                                                    message:`Customer ${project.company.name} uploaded new reference files to ${project.project.name}`
                                                }      
                                            });     
                                        }
                                    });
                                }
                            },
                        },
                        {
                            view:"button",
                            value:i18nHelper.getTranslation("Request_quote_changes"),
                            id:`${this.idPrefix}ActionsRequestQuoteChanges`,
                            height:30,
                            hidden:((quotes.length === 0 || quotes.length > 1) || potentialStatusesIds.indexOf(project.project_status.id) === -1),
                            css:"assignment-actions-normal",
                            click:() => {
                                WebixPortalNotificationService.postWebixNotification({    
                                    requestBody: { 
                                        context_id: project.project.id,
                                        recipient_id: project.project_lead.user.id,
                                        notification_type_id: WebixNotificationType.REQUEST_QUOTE_CHANGE,
                                        notification_status_id:WebixNotificationStatus.IMPORTANT,
                                        message:`Customer ${project.company.name} requested a quote change to ${project.project.name}`
                                    }      
                                });
                                webix.alert({
                                    title:i18nHelper.getTranslation("Info"),
                                    ok:"Ok",
                                    text:i18nHelper.getTranslation("Please_contact_the_PM_directly_to_request_a_quote_change"),
                                });
                            },
                        },
                        {
                            view:"button",
                            value:i18nHelper.getTranslation("Download_delivery_files"),
                            id:`${this.idPrefix}ActionsDownloadDeliveryFiles`,
                            height:30,
                            hidden:(deliveredStatusIds.indexOf(project.project_status.id) === -1),
                            css:"assignment-actions-normal",
                            click:() => {
                                const projectId = project.project.id;
                                const filesTranslation = i18nHelper.getTranslation("Delivered_files");
                                const modalTitle = `${project.project.name} ${filesTranslation}`;
                                this.fileUploaderModal.openModal(projectId, "read", {allowFolderSelection:false, entityFolderId:project.project_folder.id, defaultFolderName:"Final"}, modalTitle);
                            },
                        },
                        {
                            view:"button",
                            value:i18nHelper.getTranslation("Deny_quote"),
                            id:`${this.idPrefix}ActionsDenyQuote`,
                            height:30,
                            hidden:quotesTotalPrice === 0 || potentialStatusesIds.indexOf(project.project_status.id) === -1,
                            css:"assignment-actions-reject",
                            click:() => {
                                this.attemptToUpdateProjectStatus(this.currentProject, IntranetProjectStatus.DECLINED);
                            },
                        },
                        {
                            view:"spacer",
                            height:10,
                            borderless:true
                        }
                    ]
                }
            ],
        };
        return portlet;
    }

    updateProjectManagerPhoneAndEmail(projectManagerId:number):void {
        CognovisRestCompanyService.getCompanyContacts({
            contactIds:[projectManagerId]
        })
        .then(persons => {
            const phone = persons[0]?.work_phone;
            const email = persons[0]?.email;
            const phoneContainer = (webix.$$(`${this.idPrefix}ProjectManagerWorkPhone`)) as webix.ui.template;
            if(phoneContainer) {
                phoneContainer.setHTML(`<span class='telephone-link'><a href='tel:${phone}'>${phone}</a></span>`);
            }   
            const emailContainer = (webix.$$(`${this.idPrefix}ProjectManagerEmail`)) as webix.ui.template;
            if(emailContainer) {
                emailContainer.setHTML(`<span class='email-link'><a href='mailto:${email}'>${email}</a></span>`);
            }
        });
    }

    attemptToUpdateProjectStatus(project:ITransProject, newProjectStatus:IntranetProjectStatus):void {
        let declineOrAccept = "";
        if(newProjectStatus === IntranetProjectStatus.OPEN) {
            declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_accept_project?");
        } 
        if(newProjectStatus === IntranetProjectStatus.DECLINED) {
            declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_decline_project?");
        }
        webix.confirm({
            title: project.project.name,
            type: "confirm-warning",
            text: declineOrAccept,
            width: 480
        })
        .then(() => {
            this.updateProjectStatus(project, newProjectStatus)
        }); 
    }

    updateProjectStatus(project:ITransProject, newProjectStatus:IntranetProjectStatus):void {
        WebixPortalTranslationService.putTransProject({
            projectId:project.project.id,
            requestBody:{
                project_status_id:newProjectStatus as number,
                target_language_ids:project.target_language.map(tl => tl.id),
                source_language_id:project.source_language.id,
                company_id:project.company.id
            }
        })
        .then(() => {
            if(newProjectStatus === IntranetProjectStatus.OPEN) {
                window.location.reload();
            }
            if(newProjectStatus === IntranetProjectStatus.DECLINED) {
                CognovisNavigator.navigateTo("main/project-overview.request");
            }
        });
    }

}