/* eslint-disable @typescript-eslint/no-explicit-any */
import { singleton } from "tsyringe";

@singleton()
export class CognovisOkButtonWindow {

    lastDialogUsedId:string;

    show(params:ICognovisWindowOkButtonParams):void {
        const pleaseWaitDialogId = "cognovis-ok-button-window";
        this.lastDialogUsedId = pleaseWaitDialogId;
        const pleaseWaitDialog = webix.ui({
            view:"window",
            id:pleaseWaitDialogId,
            height:params.height,
            width:params.width,
            modal:true,
            css: {
                padding:10,
                backgroundColor:'red'
            },
            borderless:true,
            position:"center",
            headHeight:0,
            body:{
                view:"layout",
                rows:[
                    {
                        view:"template",
                        type:"header",
                        height:5,
                        css:{"background-color":"#0083A3"}
                    },
                    {
                        view:"label",
                        label:params.message,
                        align:"center",
                        autoheight:true,
                        height:60
                    },
                    {
                        view:"button",
                        value:params.okButtonText,
                        on: {
                            onItemClick:() => {
                                this.hide();
                            }
                        }
                    }
                ]
            }
        });
        pleaseWaitDialog.show();
    }

    hide():void {
        const window = (webix.$$(this.lastDialogUsedId) as webix.ui.window);
        if(window) {
            window.close();
        }
    }

}