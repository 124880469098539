import { singleton } from "tsyringe";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import { UserProfile } from "../../../sources/modules/cognovis-profile/profiles-types";


@singleton()
export class ProjectsOverviewTopTabs {

    idPrefix = "po";
    currentView = "active-projects";

    getTopTabs(currentView:string):webix.ui.layoutConfig {
        this.currentView = currentView;
        const tabs = {
            view:"layout",
            css: {
                "border-bottom":"1px solid #DADEE0 !important"
            },
            cols: [
                {
                    view:"layout",
                    id:`${this.idPrefix}TopTabsContainer`,
                    gravity:50,
                    cols:[
                        { 
                            view: "button", 
                            value: i18nHelper.getTranslation("Projects"),
                            id:`${this.idPrefix}TopTabsProjects`,
                            width:260,
                            css:(this.currentView === "active-projects" ? "toptab active" : "toptab"),
                            click: () => {
                                this.currentView = "active-projects";
                                CognovisNavigator.navigateTo("main/project-overview.projects");
                            },
                        },
                        { 
                            view: "button", 
                            hidden:(!CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER) && !CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN)),
                            value: i18nHelper.getTranslation("Pontential_Projects"),
                            id:`${this.idPrefix}TopTabsPontentialProjects`,
                            width:260,
                            css:(this.currentView === "potential-projects" ? "toptab active" : "toptab"),
                            click: () => {
                                this.currentView = "potential-projects";
                                CognovisNavigator.navigateTo("main/project-overview.potential");
                            },
                        },
                        { 
                            view: "button", 
                            value: i18nHelper.getTranslation("Requests"),
                            width:260,
                            css:(this.currentView === "requested-projects" ? "toptab active" : "toptab"),
                            id:`${this.idPrefix}TopTabsRequests`,
                            click: () => {
                                this.currentView = "requested-projects";
                                CognovisNavigator.navigateTo("main/project-overview.request");
                            },
                        }
                    ],
                },
                {
                    view:"spacer",
                    gravity:50
                }
            ],
        };
        if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
            tabs.cols[0].cols.push({
                view: "button", 
                value: i18nHelper.getTranslation("Closed"),
                width:260,
                css:(this.currentView === "closed-projects" ? "toptab active" : "tobtab"),
                id:`${this.idPrefix}TopTabsClosed`,
                click: () => {
                    this.currentView = "closed-projects";
                    CognovisNavigator.navigateTo("main/project-overview.closed");
                },
            });
        }
        if(CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER) || CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) ) {
            tabs.cols[0].cols.push({
                view: "button", 
                value: i18nHelper.getTranslation("Delivered"),
                width:260,
                css:(this.currentView === "delivered-projects" ? "toptab active" : "toptab"),
                id:`${this.idPrefix}TopTabsDelivered`,
                click: () => {
                    this.currentView = "delivered-projects";
                    CognovisNavigator.navigateTo("main/project-overview.delivered");
                },
            });
        }
        return tabs;
    }

    updateTabsCss(url:string):void {
        const tabsContainer = webix.$$(`${this.idPrefix}TopTabsContainer`) as webix.ui.layout;
        const tabs = tabsContainer.queryView({view:"button"}, "all") as webix.ui.button[];
        tabs.map(tab => {
            webix.html.removeCss(tab.getNode(), "toptab active");
        });
        tabs.map(tab => {
            const viewUrl = tab.config["viewUrl"];
            console.log(viewUrl)
            if(viewUrl && viewUrl === url) {
                webix.html.addCss(tab.getNode(), "toptab active");
            } 
        });
       
    }

    updateRfqBadge(numberOfRequests:number):void {
        const button = (webix.$$(`${this.idPrefix}TopTabsRequests`) as webix.ui.button);
        button.config.badge = numberOfRequests;
        button.refresh();
    }
}