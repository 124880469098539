/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetSkillRole {
    TRANSLATOR = 10000433,
    PROOFREADER = 10000434,
    CONTENT_WRITER = 10000531,
    GRAPHIC_DESIGNER_DTP = 10000532,
    COPY_EDITOR = 10000533,
    SWORN_TRANSLATOR = 10000548,
    SUBTITLER = 10000549,
    VOICE_OVER_TALENT = 10000551,
    INTERPRETER = 10000558,
    BLOGGER = 10000762,
    MIT_SEOKENNTNISSEN = 10000812,
    SEA_EXPERT = 10000820,
    AMAZON_EXPERT = 10000821,
    CLAIMS_EXPERT = 10000822,
    SOCIAL_MEDIA_EXPERT = 10000823,
    MIT_AMAZONKENNTNISSEN = 10000832,
    MIT_SEAKENNTNISSEN = 10000833,
}
