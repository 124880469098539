import * as usermanager from "@xbs/usermanager";

export default class UmCustomRolesGrid extends usermanager.views["roles/grid"] {

    config() {
        const ui = super.config();
        const colorColumn = ui.columns[0];
        if(colorColumn) {
            colorColumn.template = (roleObj) => {
                return `<div class='um-role-icon'><i style="color:${roleObj.color};" class="${roleObj.icon}"></i></div>` 
            };
        }
        return ui
    }
}