import { IAssignment, IFreelancerAssignment, INamedId, IntranetUom, WebixPortalAssignmentService } from "../../../../openapi";
import CognovisBasicModal from "../../../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../../modules/i18n-helper/i18n-helper";
import { WebixHelpers } from "../../../../modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { CognovisCategory } from "../../../../../sources/modules/cognovis-category/cognovis-category";
import { CognovisPleaseWaitWindow } from "../../../../../sources/services/cognovis-please-wait-window";


export default class DeleteAssignmentModal extends CognovisBasicModal {

    webixHelpers:WebixHelpers;
    currentAssignment:IAssignment;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

    init():void {
        this.webixHelpers = container.resolve(WebixHelpers);
    }

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Delete_assignment`), 560, 800);
        return mainLayout;
    }

    openModal(additionalData:ICognovisModalAdditionlData):void {
        const cognovisBasicModal = this.$$("deleteAssignmentModal") as webix.ui.window;
        if(cognovisBasicModal) {
            cognovisBasicModal.show();
        } else {
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
        }
        
        const assignment = additionalData as IAssignment;
        const actionButtons = this.getActionButtons(assignment);
        const modalContent = this.getContent(assignment);
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig);
    }

    closeModal():void {
        this.close();
    }

    getContent(assignment:IFreelancerAssignment):webix.ui.layoutConfig {
        const modal = {
                view:"form",
                localId:"deleteAssignmentModalForm",
                elements:[
                    {
                        view:"textarea",
                        localId:"commentsCkEditor5",
                        name:"reason",
                        height:300,
                        css:{},
                        mode:"document",
                        placeholder:"Reason to delete...",
                    },
                    {
                        view:"checkbox",
                        value:0,
                        name:"deletePo",
                        labelWidth:180,
                        width:560,
                        label:i18nHelper.getTranslation("Delete_PO"),
                        on: {
                            onAfterRender:function() {
                                if(
                                    assignment.purchase_order?.id
                                ) {
                                    this.show();
                                } else {
                                    this.hide();
                                }
                            }
                        }
                    }
                ]
        }
        return modal;
    }

    getActionButtons(assignment:IFreelancerAssignment):webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Cancel`)}`, 
                            align: "left",
                            click:() => {
                                this.close();
                            }
                        },
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`save`)}`,
                            align: "right",
                            click:() => {
                                this.deleteAssignment(assignment);
                            }
                        },    
                    ] 
                };
        return buttons;
    }

    deleteAssignment(assignment:IAssignment):void {
        const form = this.$$(`deleteAssignmentModalForm`) as webix.ui.form;
        const values = form.getValues();
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.cognovisPleaseWaitWindow.show({message:i18nHelper.getTranslation("Please_wait")});
        WebixPortalAssignmentService.deleteTranslationAssignments({
            assignmentIds:[assignment.assignment.id],
            deletePoP: values.deletePo,
            reason: values.reason
        })
        .then(() => {
            const titleTranslate = i18nHelper.getTranslation("Assignment_deleted");
            const textTranslated = i18nHelper.getTranslation("The_assignment_was_deleted");
            webix.alert({
                width:320,
                title:titleTranslate,
                ok:"Ok",
                text:textTranslated
            })
            .then(() => {
                this.cognovisPleaseWaitWindow.hide();
                window.location.reload();
            })
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }
}