import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { IJetApp } from "webix-jet";
import DynfieldsBasicLayout from "../../modules/cog-dynfields/dynfields-basic-layout";
import { CognovisRestCompanyService, CognovisRestDynamicService, CognovisRestRelationshipService, ICompanyContact, IdValue, IDynamicAttribute, IntranetCollmexRestService } from "../../../sources/openapi";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";

interface IDynfieldBasicLayout {
    getPredefinedFields?:() => webix.ui.baseviewConfig[];
    getObjectData:(objectId:number) => Promise<{[key: string]: string | number}>;
}

export default class CogCompanyBasicInfoPortlet extends DynfieldsBasicLayout implements IDynfieldBasicLayout {

    userId:number;
    companyId:number;
    currentObject:any;
    currentContact:ICompanyContact;
    webixHelpers:WebixHelpers;
    portletId:number;
    title:string;
    mainOfficeId = -1;
    predefinedFields = [];
    cognovisPleaseWaitWindow:CognovisPleaseWaitWindow;

    constructor(app:IJetApp, userId:number, portletId?:number, title?:string) {
        super(app, userId, "ccbip", { objectType: "im_company", pageUrl: "/#!/company", objectId:userId, portletId:portletId, customTemplates:[
            {
                field:"collmex_id",
                template:(id:number, value:string) => {
                    if(!id) {
                        return `<span class='create-collmex text-link'>Create collmex ID</span>`;
                    } else {
                        return `${id}`;
                    }                
                }
            }
        ]}, i18nHelper.getTranslation("Basic information"), i18nHelper.getTranslation("Edit my data"), (data:unknown, callback?:() => void) => {
            this.submit(userId, data as IdValue);
            if(this.mainOfficeId) {
                this.submit(this.mainOfficeId, data as IdValue, true);
            }
            callback();
        });
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.objectId = userId;
        this.portletId = portletId;
        if(this.title) {
            this.title = title;
        }
        this.webixHelpers = container.resolve(WebixHelpers);
    }

    config():webix.ui.layoutConfig {
        return {
            view:"layout",
            rows:[
                this.getCoreLayout()
            ]
        }
    }

    init():void {
        this.showLoader();
        this.getObjectData(this.objectId)
        .then(data => {
            // Specific type of page, as we need dynfields both from 'im_company' and 'im_offices'
            this.getDynfields(this.dynfieldsConfig.objectType, this.dynfieldsConfig.pageUrl)
            .then(companyDynfields => {
                this.getDynfields("im_office", this.dynfieldsConfig.pageUrl, this.mainOfficeId)
                .then(officeDynfields => {
                    this.dynfields = [...companyDynfields, ...officeDynfields]
                    this.setData(data);
                    this.setTopAdditionalLayoutElements(this.getAvatarContainer());
                    this.setBottomAdditionalLayoutElements(this.getLogoUploader());
                    this.insertComponents();
                });
            });
        });
    }

    getObjectData(objectId:number):Promise<{[key: string]: string | number}> {
        return CognovisRestCompanyService.getCompanies({
            companyId:objectId
        })
        .then(object => {
            this.currentObject = object[0];
            if(this.currentObject["main_office"]["id"]) {
                this.mainOfficeId = this.currentObject["main_office"]["id"];
            }
            const sidebar = webix.$$(`sidebarMainSidebar`) as webix.ui.sidebar;
            sidebar.$scope.openCompany(this.currentObject);
            return CognovisRestDynamicService.getDynamicObject({
                objectId:objectId,
                pageUrl:this.dynfieldsConfig.pageUrl
            })
            .then(companyData => {
                return CognovisRestDynamicService.getDynamicObject({
                    objectId:this.mainOfficeId,
                    pageUrl:this.dynfieldsConfig.pageUrl
                })
                .then(officeData => {
                    const keyPairConverted = Object.entries(this.currentObject).map(([id, value]) => ({id, value}));
                    const mergedDataObject = [...companyData, ...keyPairConverted, ...officeData]
                    return this.prepareData(mergedDataObject as unknown as IdValue);
                });
            });
        })
    }
    
    buildDynfield(field:IDynamicAttribute):webix.ui.layoutConfig & {sortOrder:number} {
        return {
            view:"layout",
            sortOrder:field.sort_order,
            rows:[
                {
                    view:"spacer",
                    height:4
                },
                {
                    view:"layout",
                    cols:[
                        {
                            height:30,
                            gravity:0.4,
                            borderless:true,
                            template:function() {
                                return i18nHelper.getTranslationWithKey(field.display_name, field.i18n_key);
                            }
                        },
                        {
                            view:"template",
                            gravity:0.6,
                            id:`${this.idPrefix}Dynfield${field.attribute.id}`,
                            dynfieldValue:true,
                            dynfieldKey:field.attribute.name,
                            borderless:true,
                            template:" ",
                            onClick:{
                                "create-collmex":() => {
                                    this.createCollmexId();
                                }
                            }
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:4
                }
            ]
        }
    }

    getAvatarContainer():webix.ui.baseviewConfig {
        return {
            view:"button",
            id:`${this.idPrefix}UserPortrait`,
            template:`<div style='text-align:center;' class='anon-avatar-comppany-bg'><img alt="" style='margin:0 auto; border-radius:50%; border:2px solid #ffffff; width:150px; height:150px;' src='${this.currentObject.logo_url}'/></div>`,
            borderless:true,
            height:160,
            click:() => this.attemptToUploadLogo()
        } as unknown as webix.ui.baseviewConfig
    }

    attemptToUploadLogo():void {
        const companyLogoUploader = (this.$$(`CompanyLogoUploader`) as webix.ui.uploader);
        companyLogoUploader.fileDialog();
    }

    getLogoUploader():webix.ui.uploaderConfig {
        const uploader = {
            view: "uploader",
            localId: `CompanyLogoUploader`,
            name: "files",
            autosend: false,
            css:{
                "opacity":"0"
            },
            upload: this.webixHelpers.getUrlWithApiKey("upload_cr_file"),
            value:i18nHelper.getTranslation("Add file"),
            formData: null,
            datatype: "json",
            on: {
                onAfterFileAdd:() => {
                    // First we check for existing avatar->user relationship
                    CognovisRestRelationshipService.getRelationships({
                        objectIdOne:this.companyId,
                        relType:"im_company_logo_rel"
                    })
                    .then(portraitsRels => {
                        const fileUploader = (this.$$(`CompanyLogoUploader`)) as webix.ui.uploader;
                        if(portraitsRels[portraitsRels.length - 1]) {
                            fileUploader.define("formData",{
                                file_item_id:portraitsRels[portraitsRels.length -1].object_two.id,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(fileRes => {
                                //
                            });

                        } else {
                            fileUploader.define("formData",{
                                context_id:this.companyId,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(filesRes => {
                                CognovisRestRelationshipService.postRelationship(
                                    {
                                        requestBody:{
                                            object_id_one:this.companyId,
                                            object_id_two:filesRes.file_id,
                                            rel_type:"im_company_logo_rel"
                                        }
                                    }
                                )
                            });
                        }
                        window.location.reload();
                        //CognovisNavigator.navigateTo(`/main/companies-overview.company-details?company_id=${this.companyId}`);
                    });
                }
            }
        }
        return uploader
    }
    submit(objectId:number, data:IdValue, runCallbacks?:boolean):void {
        console.log(data)
        CognovisRestDynamicService.putDynamicObject({
            requestBody:{
                object_id:objectId,
                id_values:data
            }
        })
        .then(res => {
            if(runCallbacks) {
                this.setData(this.prepareData(res));
                this.injectData();
            }
        });
    }
    
    createCollmexId():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        IntranetCollmexRestService.postImportCompanyToCollmex({companyId:this.objectId})
        .then(res => {
            window.location.reload();
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        })
        .finally(() => {
            this.cognovisPleaseWaitWindow.hide();
        });
    }

}