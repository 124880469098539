import BaseView from "../baseview";
import {i18nHelper} from "../../modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, IDynfieldWidget} from "../../openapi";
import WidgetsEditModal from "views/dynfields-settings/widgets-edit-modal";
import DynviewsEditModal from "views/dynviews-settings/dynviews-edit-modal";


export default class WidgetsSettings extends BaseView {

    idPrefix = "dynfsw";
    title:string;
    widgetEditModal:WidgetsEditModal;
    config():webix.ui.layoutConfig {
        this.title = i18nHelper.getTranslation("Dynfields Widget Settings");
        return this.getCoreLayout();
    }

    init():void {
        const container = webix.$$(`${this.idPrefix}WidgetsSettingsContainer`) as webix.ui.layout;
        container.addView(this.getDatatable());
    }

    getCoreLayout():webix.ui.scrollviewConfig {
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                padding:13,
                css:"cog-content",
                width:0,
                height:0,
                autoWidth:true,
                rows: [
                    this.getTopButtons(),
                    this.getHeader(),
                    {
                        view:"layout",
                        id:`${this.idPrefix}MainContainer`,
                        height:0,
                        gravity:1,
                        minHeight:300,
                        css:"cog-box-content",
                        rows:[
                            {
                                view:"layout",
                                id:`${this.idPrefix}WidgetsSettingsContainer`,
                                rows:[]
                            }
                        ]
                    }
                ]
            }
        }
    }

    getHeader():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:44,
            cols: [
                {
                    view: "template",
                    type: "header",
                    css: "cog-box-header-no-border",
                    template: this.title,
                    borderless: true
                },
            ]
        }
    }

    getTopButtons():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:40,
            gravity:1,
            css:{
                "margin":"2px 0"
            },
            cols:[
                {
                    view:"spacer",
                    width:0,
                },
                {
                    view:"button",
                    width:100,
                    inputWidth:100,
                    css:"cog-button-big",
                    label:i18nHelper.getTranslation("New widget"),
                    click:() => {
                        this.openWidgetsDetailsModal()
                    }
                }
            ]
        }
    }
    getDatatable():webix.ui.datatableConfig {
        this.showLoader();
        return {
            view: "datatable",
            id: `${this.idPrefix}WidgetsListTable`,
            resizeRow:false,
            hover:"cog-datatable-hover",
            tooltip:true,
            columns:[
                {
                    id:"widget_name",
                    fillspace:true,
                    sort:"text",
                    header: [i18nHelper.getTranslation("Widget name"),  { content:"textFilter"}],
                },
                {
                    width:42,
                    id:"action-edit",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Edit widget"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-magnifying-glass'></span>`;
                    }
                },
                {
                    width:42,
                    id:"action-delete",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Delete widget"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-trash'></span>`;
                    }
                }
            ],
            url:() => {
                return CognovisRestDynamicService.getDynfieldWidgets({})
                .then(widgets => {
                    this.hideLoader();
                    return widgets
                });
            },
            on: {
                onItemClick:(row) => {
                    const table = webix.$$(`${this.idPrefix}WidgetsListTable`) as webix.ui.datatable;
                    const item:IDynfieldWidget = table.getItem(row);
                    switch(row.column) {
                        case "action-edit":
                            this.openWidgetsDetailsModal(item.widget_id);
                            break;
                        case "action-delete":
                            this.attemptToDeleteWidget(item)
                            break;
                    }
                }
            }
        }
    }

    openWidgetsDetailsModal(widgetId?:number):void {
        if(!this.widgetEditModal) {
            this.widgetEditModal = this.ui(WidgetsEditModal) as WidgetsEditModal;
        }
        if(widgetId) {
            this.widgetEditModal.openModal(widgetId, () => {
                this.refreshTable();
            });
        } else {
            this.widgetEditModal.openModal(0, () => {
                this.refreshTable();
            });
        }
    }

    attemptToDeleteWidget(widget:IDynfieldWidget):void {
        const declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_delete_widget?");
        webix.confirm({
            title: widget.widget_name,
            type: "confirm-warning",
            text: declineOrAccept,
            width: 480
        })
            .then(() => {
                this.deleteWidget(widget.widget_id)
            });
    }

    deleteWidget(widgetId:number):void {
        CognovisRestDynamicService.deleteDynfieldWidget({
            widgetId: widgetId
        }).then(() => {
            this.refreshTable();
        });
    }

    refreshTable():void {
        const table = webix.$$(`${this.idPrefix}WidgetsListTable`) as webix.ui.datatable;
        if(table) {
            this.showLoader();
            table.clearAll();
            CognovisRestDynamicService.getDynfieldWidgets({})
                .then(widgets => {
                    this.hideLoader();
                    table.define("data", widgets);
                    table.refresh()
                });
        }
    }

    showLoader():void {
        const container = webix.$$(`${this.idPrefix}WidgetsSettingsContainer`) as webix.ui.layout & {showProgress?:(text:string) => void}
        webix.extend(container, webix.ProgressBar);
        container.showProgress(i18nHelper.getTranslation("Please_wait"));
        if(container.showProgress) {
            container.showProgress(i18nHelper.getTranslation("Please_wait"));
        }
    }

    hideLoader():void {
        const container = webix.$$(`${this.idPrefix}WidgetsSettingsContainer`) as webix.ui.layout & {hideProgress?:() => void}
        container.hideProgress();
    }

}
