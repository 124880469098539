import { IInvoiceItem } from "../../../../sources/openapi";
import { JetView } from "webix-jet";

export default class LineItemsDescription extends JetView {

    currentLineItem:IInvoiceItem;
    saveAction:(param1:string)=>void;

    config(): webix.ui.windowConfig {
        return {
            view: "window",
            position: "top",
            id: "desciption-window",
            width: 300,
            modal: true,
            head: "",
            padding:10,
            body: ({
                rows: [
                    {
                        view: "textarea",
                        id: "line-item-desc",
                        css: "description-textarea"
                    },
                    {
                        view:"layout",
                        type:"clean",
                        height:90,
                        cols:[
                            {
                                view: "button",
                                value: "Close",
                                inputHeight:40,
                                on: {
                                    onItemClick:function() {
                                        ($$("desciption-window") as webix.ui.window).close();
                                    }
                                }
                            },
                            {
                                view: "button",
                                value: "Save Changes",
                                hidden:true,
                                inputHeight:40,
                                id: "saveDesc",
                                on: {
                                    onItemClick:() => {
                                        const descEl = $$("line-item-desc") as webix.ui.textarea;
                                        const descValue = descEl.getValue();
                                        this.saveAction(descValue);
                                        ($$("desciption-window") as webix.ui.window).close();
                                    }
                                }
                            },
                        ]
                    }

                ],
            } as unknown) as webix.ui.baseview,
        };
    }

    showModal(lineItem:IInvoiceItem, isInvoiceEditable = false, saveAction:(desc:string)=>void): void {
        this.currentLineItem = lineItem;
        const saveButton = webix.$$("saveDesc") as webix.ui.button;
        if(isInvoiceEditable) {
            saveButton.show();
            this.saveAction = saveAction;
        } else {
            saveButton.hide();
        }
        const modal = this.getRoot() as webix.ui.window;
        this.setModalDescription(lineItem.description);
        this.setTitle(lineItem.invoice.name);
        modal.show();
    }

    setModalDescription(description: string): void {
        const desc = $$("line-item-desc") as webix.ui.textarea;
        let descriptionToDisplay = description;
        if(!descriptionToDisplay) {
            descriptionToDisplay = "";
        }
        setTimeout(() => {
            desc.focus();
            desc.setValue(descriptionToDisplay);
        },100);
    }

    setTitle(title:string):void {
        const win = ($$("desciption-window") as webix.ui.window);
        win.define("head",{view:"label", label:title, css:"line-item-desc-modal-header"});
        win.resize();
    }
}
