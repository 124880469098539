/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetReportType {
    SIMPLE_SQL_REPORT = 15100,
    INDICATOR = 15110,
    WEBIX_REPORT = 15120,
    WEBIX_PIVOT_REPORT = 15130,
}
