import { IJetApp, JetView } from "webix-jet";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { CognovisRestCompanyService, CognovisRestRelationshipService, CognovisRestService, ICompanyContact, INamedId, IUser } from "../../../sources/openapi";
import UserDataModal from "views/modals/user-data-modal";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";

export default class UserBasicInfoPortlet extends CognovisBasicPortlet {

    idPrefix = "ubip";
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    userDataModal:UserDataModal;
    webixHelpers:WebixHelpers;
    currentUserId:number;
    companyId:number;
    currentContact:ICompanyContact;
    currentUser:IUser;
    portletId:number;

    constructor(app:IJetApp, userId:number, currentUser:IUser, currentContact:ICompanyContact, portletId?:number, title?:string) {
        super(app, {});
        this.currentUserId = userId;
        this.currentUser = currentUser;
        this.currentContact = currentContact;
        this.portletId = portletId;
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.title = title;
    }

    init():void {
        super.init();
        if(!this.title) {
            this.setHeaderTitle(`${i18nHelper.getTranslation("User")}: ${this.currentUser.user.name}`);
        }
        this.components = [
            {
                view:"layout",
                minHeight:500,
                cols:[
                    this.getPortraitUploader(),
                    this.getUserBasicInfoLayout()
                ]
            }

        ];
        this.insertComponents();
    }

    refreshUserData():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        this.getUserData(this.currentUserId)
        .then(res => {
            // Set header
            this.setHeaderTitle(`${this.currentContact.first_names} ${this.currentContact.last_name}`);
            // Set fields
            const nameField = this.$$(`${this.idPrefix}UsernameField`) as webix.ui.template;
            nameField.setHTML(`${this.currentContact.first_names} ${this.currentContact.last_name}`);
            const comapnyNameField = this.$$(`${this.idPrefix}CompanyNameField`) as webix.ui.template;
            comapnyNameField.setHTML(this.currentUser.company.name);
            const emailField = this.$$(`${this.idPrefix}EmailField`) as webix.ui.template;
            emailField.setHTML(`<a class='text-link' href='mailto:${this.currentContact.email}'>${this.currentContact.email}</a>`);
            const workPhoneField = this.$$(`${this.idPrefix}WorkPhoneField`) as webix.ui.template;
            workPhoneField.setHTML(`<a class='text-link' href='tel:${this.currentContact.work_phone}'>${this.currentContact.work_phone}</a>`);
            const cellPhoneField = this.$$(`${this.idPrefix}CellPhoneField`) as webix.ui.template;
            cellPhoneField.setHTML(`<a class='text-link' href='tel:${this.currentContact.cell_phone}'>${this.currentContact.cell_phone}</a>`);
            const localeField = this.$$(`${this.idPrefix}LocaleField`) as webix.ui.template;
            localeField.setHTML(this.currentContact.locale);
            // Update left navigation in case company contact is visible in there
            const sidebar = webix.$$("sidebarMainSidebar") as webix.ui.sidebar;
            const openedCompanyContactItem = sidebar.getItem(`users-overview.user-details?user_id=${this.currentContact.contact.id}`);
            if(openedCompanyContactItem) {
                openedCompanyContactItem.value = `${this.currentContact.first_names} ${this.currentContact.last_name}`;
                sidebar.refresh();
            }
            this.cognovisPleaseWaitWindow.hide();
        });
    }

    getUserData(userId:number):Promise<{user:IUser, companyContact:ICompanyContact}> {
        return CognovisRestService.getUser({
            userIds:[this.currentUserId]
        })
        .then(user => {
            this.currentUser = user[0];
            return CognovisRestCompanyService.getCompanyContacts({
                contactIds:[user[0].user.id]
            })
            .then(companyContact => {
                this.currentContact = companyContact[0];
                return {user:user[0], companyContact:companyContact[0]}
            });
        });
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    localId:`${this.idPrefix}UserInfoHeader`,
                    template:this.title,
                    borderless: true 
                },
                { 
                    view: "button", 
                    type: "icon", 
                    icon: "far fa-edit", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true ,
                    click:(element, event) => {
                        if(!this.userDataModal) {
                            this.userDataModal = this.ui(UserDataModal) as UserDataModal;
                        }
                        this.userDataModal.openModal(["first_names", "last_name", "email", "salutation", "work_phone", "cell_phone"],this.currentUser.company.id, this.currentContact, () => {
                            this.refreshUserData();
                        });
                    }
                }
            ]
        };
        return header;
    }

    attemptToUploadPortrait():void {
        const userPortraitUploader = (this.$$(`UserPortraitUploader`) as webix.ui.uploader);
        userPortraitUploader.fileDialog();
    }

    getPortraitUploader():webix.ui.uploaderConfig {
        const uploader = {
            view: "uploader",
            localId: `UserPortraitUploader`,
            name: "files",
            autosend: false,
            width:1,
            height:1,
            upload: this.webixHelpers.getUrlWithApiKey("upload_cr_file"),
            value:i18nHelper.getTranslation("Add file"),
            formData: null,
            datatype: "json",
            on: {
                onAfterFileAdd:() => {
                    // First we check for existing avatar->user relationship
                    CognovisRestRelationshipService.getRelationships({
                        objectIdOne:this.currentUserId,
                        relType:"user_portrait_rel"
                    })
                    .then(portraitsRels => {
                        const fileUploader = (this.$$(`UserPortraitUploader`)) as webix.ui.uploader;
                        if(portraitsRels[portraitsRels.length - 1]) {
                            fileUploader.define("formData",{
                                file_item_id:portraitsRels[portraitsRels.length -1].object_two.id,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(fileRes => {
                                //
                            });

                        } else {
                            fileUploader.define("formData",{
                                context_id:this.companyId,
                                description:""
                            });
                            fileUploader.refresh();
                            fileUploader.send(filesRes => {
                                CognovisRestRelationshipService.postRelationship(
                                    {
                                        requestBody:{
                                            object_id_one:this.currentUserId,
                                            object_id_two:filesRes.file_id,
                                            rel_type:"user_portrait_rel"
                                        }
                                    }
                                )
                                .then(res => {
                                    window.location.reload();
                                })
                                .catch(() => {
                                    window.location.reload();
                                });
                            });
                        }
                        
                    });
                }
            }
        }
        return uploader
    }

    getUserBasicInfoLayout():webix.ui.layoutConfig {
        const userBasicInfoLayout = {
                view:"layout",
                css:"cog-box-content",
                rows:[
                    {
                        view:"spacer",
                        height:3,
                        borderless:true
                    },
                    {
                        view:"spacer",
                        height:20,
                        borderless:true
                    },
                    {
                        view:"button",
                        id:`${this.idPrefix}UserPortrait`,
                        template:`<div style='text-align:center;' class='anon-avatar-bg'><img alt="" style='margin:0 auto; border-radius:50%; border:2px solid #ffffff; width:150px; height:150px;' src='${this.currentUser.portrait_url}'/></div>`,
                        borderless:true,
                        height:160,
                        click:() => this.attemptToUploadPortrait()
                    },
                    this.getIconLabelTemplate(`${this.idPrefix}UsernameField`, "fas fa-user-cog", `${this.currentContact.first_names} ${this.currentContact.last_name}`),
                    this.getIconLabelTemplate(`${this.idPrefix}CompanyNameField`, "fas fa-id-card", `${this.currentUser.company.name}`),
                    //this.getIconLabelTemplate("fas fa-id-card", `${companyContact.salutation}`),
                    this.getIconLabelTemplate(`${this.idPrefix}EmailField`, "fas fa-envelope",this.currentContact.email , "email"),
                    this.getIconLabelTemplate(`${this.idPrefix}WorkPhoneField`, "fas fa-phone-office", this.currentContact.work_phone, "phone"),
                    this.getIconLabelTemplate(`${this.idPrefix}CellPhoneField`, "fas fa-mobile", this.currentContact.cell_phone, "phone"),
                    this.getIconLabelTemplate(`${this.idPrefix}LocaleField`, "fas fa-language", this.currentContact.locale),
                    {
                        view:"spacer",
                        height:6,
                        borderless:true
                    },
                    {
                        view:"template",
                        id:`${this.idPrefix}ProjectManagerWorkPhone`,
                        borderless:true,
                        height:25,
                        template:""
                    },
                    {
                        view:"template",
                        id:`${this.idPrefix}ProjectManagerEmail`,
                        borderless:true,
                        height:25,
                        template:""
                    },
                    {
                        view:"spacer",
                        height:20,
                        borderless:true
                    },
                    

                ]
        } as webix.ui.layoutConfig;
        return userBasicInfoLayout
    }

    getIconLabelTemplate(id:string, icon:string,label:string, link?:string):webix.ui.layoutConfig {
        switch(link) {
            case "email":
                label = `<a class='text-link' href='mailto:${label}'>${label}</a>`;
                break;
            case "phone":
                label = `<a class='text-link' href='tel:${label}'>${label}</a>`;
                break;
            default:
                label = label;
        }
        const layout = {
            view:"layout",
            height:40,
            paddingY:10,
            borderless:true,
            cols:[
                {
                    view:"spacer",
                    width:13
                },
                {
                    template:`<i class="cog-icon-dark ${icon}"/>`,
                    borderless:true,
                    width:40
                },
                {
                    template:`${label}`,
                    localId:id,
                    borderless:true,
                },
                {
                    view:"spacer",
                    width:13
                },
            ]
        };
        return layout
    }

}