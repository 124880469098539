/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Pagination = {
    /**
     * Start for the limit of the pagination
     */
    start?: number;
    /**
     * Appends pagination information to a SQL statement depending on URL parameters
     */
    limit?: number;
    /**
     * Should be the column we sort by
     */
    property?: string;
    /**
     * Either ASC or DESC
     */
    direction?: Pagination.direction;
};

export namespace Pagination {

    /**
     * Either ASC or DESC
     */
    export enum direction {
        ASC = 'ASC',
        DESC = 'DESC',
    }


}

