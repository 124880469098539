/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetTranslationLanguage {
    /**
     * Spanisch (Spanien)
     */
    ESES = 251,
    /**
     * Spanisch (Lateinamerika)
     */
    SPA_LA = 252,
    /**
     * Spanisch (US)
     */
    ESUS = 253,
    /**
     * Spanisch (Mexico)
     */
    ESMX = 254,
    /**
     * Spanisch (Venezuela)
     */
    ESVE = 255,
    /**
     * Spanisch (Peru)
     */
    ESPE = 256,
    /**
     * Spanisch (Argentinien)
     */
    ESAR = 257,
    /**
     * Spanisch (Uruguay)
     */
    ESUY = 258,
    /**
     * Englisch (US)
     */
    ENUS = 262,
    /**
     * Englisch (UK)
     */
    ENGB = 263,
    /**
     * Englisch (Kanada)
     */
    ENCA = 264,
    /**
     * Englisch (Irland)
     */
    ENIE = 265,
    /**
     * Englisch (Australien)
     */
    ENAU = 266,
    /**
     * Italienisch (Italien)
     */
    ITIT = 269,
    /**
     * Französisch (Frankreich)
     */
    FRFR = 272,
    /**
     * Französisch (Belgien)
     */
    FRBE = 273,
    /**
     * Französisch (Schweiz)
     */
    FRCH = 274,
    /**
     * Portugiesisch (Portugal)
     */
    PTPT = 277,
    /**
     * Portugiesisch (Brasilien)
     */
    PTBR = 278,
    /**
     * Deutsch (Deutschland)
     */
    DEDE = 282,
    /**
     * Deutsch (Schweiz)
     */
    DECH = 283,
    /**
     * Russisch
     */
    RURU = 286,
    /**
     * Russisch (Ukraine)
     */
    RU_UA = 287,
    /**
     * Dänisch
     */
    DADK = 288,
    /**
     * Niederländisch
     */
    NLNL = 291,
    /**
     * Flämisch (Belgien)
     */
    NLBE = 292,
    /**
     * Katalanisch (Spanien)
     */
    CAES = 294,
    /**
     * Galegisch
     */
    GLG = 296,
    /**
     * Baskisch (Spanien)
     */
    EUES = 297,
    /**
     * Griechisch
     */
    ELGR = 10000002,
    /**
     * Japanisch
     */
    JAJP = 10000003,
    /**
     * Chinesisch
     */
    ZHCN = 10000004,
    /**
     * Polnisch
     */
    PLPL = 10000017,
    /**
     * Tschechisch
     */
    CSCZ = 10000032,
    /**
     * Ungarisch
     */
    HUHU = 10000033,
    /**
     * Rumänisch
     */
    RORO = 10000034,
    /**
     * Slowakisch
     */
    SKSK = 10000035,
    /**
     * Taiwanesisch
     */
    ZHTW = 10000037,
    /**
     * Finnisch
     */
    FIFI = 10000038,
    /**
     * Schwedisch
     */
    SVSE = 10000039,
    /**
     * Ukrainisch
     */
    UKUA = 10000040,
    /**
     * Arabisch (Ägypten)
     */
    AREG = 10000041,
    /**
     * Armenisch
     */
    HYAM = 10000042,
    /**
     * Aserbaidschanisch - Kyrillisch
     */
    AZCYRLAZ = 10000043,
    /**
     * Bahasa
     */
    IDID = 10000044,
    /**
     * Bulgarisch
     */
    BGBG = 10000046,
    /**
     * Hindi
     */
    HIIN = 10000047,
    /**
     * Hebräisch
     */
    HEIL = 10000048,
    /**
     * Jiddisch
     */
    YID = 10000049,
    /**
     * Koreanisch
     */
    KOKR = 10000050,
    /**
     * Kroatisch
     */
    HR = 10000051,
    /**
     * Lettisch
     */
    LVLV = 10000052,
    /**
     * Mazedonisch
     */
    MKMK = 10000053,
    /**
     * Bahasa
     */
    MSMY = 10000054,
    /**
     * Mongolisch
     */
    MNMN = 10000055,
    /**
     * Norwegisch
     */
    NBNO = 10000056,
    /**
     * Farsi
     */
    FAIR = 10000057,
    /**
     * Filipino
     */
    FIL = 10000058,
    /**
     * Slowenisch
     */
    SLSI = 10000059,
    /**
     * Tagalog (Philippinen)
     */
    TGL = 10000060,
    /**
     * Thai
     */
    THTH = 10000061,
    /**
     * Türkisch
     */
    TRTR = 10000062,
    /**
     * Vietnamesisch
     */
    VIVN = 10000064,
    /**
     * Deutsch (Österreich)
     */
    DEAT = 10000087,
    /**
     * Italienisch (Schweiz)
     */
    ITCH = 10000096,
    /**
     * Litauisch
     */
    LTLT = 10000109,
    /**
     * Maltesisch
     */
    MTMT = 10000110,
    /**
     * Serbisch
     */
    SR = 10000129,
    /**
     * Estnisch
     */
    ETEE = 10000289,
    /**
     * Paschtu
     */
    PUS = 10000295,
    /**
     * Albanisch
     */
    SQAL = 10000296,
    /**
     * Bosnisch (Bosnien) - Lateinisch
     */
    BSLATNBA = 10000297,
    /**
     * Luxemburgisch
     */
    LBLU = 10000298,
    /**
     * Norwegisch (Nynorsk)
     */
    NN_NO = 10000352,
    /**
     * Afrikaans
     */
    AF = 10000354,
    /**
     * Kantonesisch
     */
    ZHHK = 10000355,
    /**
     * Goidelisch
     */
    GAIE = 10000356,
    /**
     * Spanisch (Kolumbien)
     */
    ESCO = 10000357,
    /**
     * Moldauisch
     */
    MOL = 10000376,
    /**
     * Isländisch
     */
    ISIS = 10000383,
    /**
     * Arabisch (Vereinigte Arabische Emirate)
     */
    ARAE = 10000406,
    /**
     * Französisch (Kanada)
     */
    FRCA = 10000407,
    /**
     * Serbisch (Bosnien und Herzegowina) - Lateinisches Alphabet
     */
    SRLATNBA = 10000408,
    /**
     * Serbisch (Serbien) - Lateinisches Alphabet
     */
    SRLATNRS = 10000409,
    /**
     * Aserbeidschanisch (Aserbeidschan) - Lateinisches Alphabet
     */
    AZLATNAZ = 10000410,
    /**
     * Kroatisch (Bosnien und Herzegowina)
     */
    HRBA = 10000411,
    /**
     * Kroatisch (Kroatien)
     */
    HRHR = 10000412,
    /**
     * Bosnisch (Bosnien und Herzegowina) - Kyrillisches Alphabet
     */
    BSCYRLBA = 10000413,
    /**
     * Afrikaans (Südafrika)
     */
    AFZA = 10000414,
    /**
     * Serbisch (Bosnien und Herzegowina) - Kyrillisches Alphabet
     */
    SRCYRLBA = 10000415,
    /**
     * Serbisch (Serbien) - Kyrillisches Alphabet
     */
    SRCYRLCS = 10000416,
    /**
     * Englisch (Philippinen)
     */
    ENPH = 10000417,
    /**
     * Schwedisch (Samen)
     */
    SESE = 10000428,
    /**
     * Japanisch
     */
    JA = 10000522,
    /**
     * Rumänisch
     */
    RO = 10000562,
    /**
     * Albanisch
     */
    SQ = 10000568,
    /**
     * Arabisch
     */
    AR = 10000569,
    /**
     * Armenisch
     */
    AM = 10000570,
    /**
     * Aserbaidschanisch
     */
    AZ = 10000571,
    /**
     * Bengalisch
     */
    BN = 10000572,
    /**
     * Baskisch
     */
    EU = 10000573,
    /**
     * Bosnisch
     */
    SHB2 = 10000574,
    /**
     * Bulgarisch
     */
    BG = 10000575,
    /**
     * Katalanisch
     */
    CA = 10000576,
    /**
     * Kroatisch
     */
    SH = 10000577,
    /**
     * Tschechisch
     */
    CS = 10000578,
    /**
     * Dari
     */
    PRS = 10000579,
    /**
     * Estnisch
     */
    ET = 10000580,
    /**
     * Tagalog
     */
    TL = 10000581,
    /**
     * Griechisch
     */
    EL = 10000582,
    /**
     * Hebräisch (veraltet)
     */
    IW = 10000583,
    /**
     * Hindi
     */
    HI = 10000584,
    /**
     * Ungarisch
     */
    HU = 10000585,
    /**
     * Isländisch
     */
    IS = 10000586,
    /**
     * Indonesisch
     */
    ID = 10000587,
    /**
     * Goidelisch
     */
    GA = 10000588,
    /**
     * Koreanisch
     */
    KO = 10000589,
    /**
     * Lettisch
     */
    LV = 10000590,
    /**
     * Litauisch
     */
    LT = 10000591,
    /**
     * Mazedonisch
     */
    MK = 10000592,
    /**
     * Malaysisch
     */
    MS = 10000593,
    /**
     * Maltesisch
     */
    MT = 10000594,
    /**
     * Mongolisch
     */
    MN = 10000595,
    /**
     * Paschtu
     */
    PS = 10000596,
    /**
     * Persisch
     */
    FA = 10000597,
    /**
     * Serbisch - Kyrillisches Alphabet
     */
    SHYU = 10000598,
    /**
     * Serbisch - Lateinisches Alphabet
     */
    SHSR = 10000599,
    /**
     * Slowakisch
     */
    SK = 10000600,
    /**
     * Slowenisch
     */
    SL = 10000601,
    /**
     * Thai
     */
    TH = 10000602,
    /**
     * Türkisch
     */
    TR = 10000603,
    /**
     * Urdu
     */
    URPK = 10000605,
    /**
     * Vietnamesisch
     */
    VI = 10000606,
    /**
     * Jiddish
     */
    YI = 10000607,
    /**
     * Nigerian Standard English
     */
    ENNG = 10000612,
    /**
     * Bengali
     */
    BNBD = 10000613,
    /**
     * Marathi
     */
    MRIN = 10000614,
    /**
     * Englisch (Neuseeland)
     */
    ENNZ = 10000689,
    /**
     * Georgisch
     */
    KAGE = 10000692,
    /**
     * Kasachisch
     */
    KKKZ = 10000693,
    /**
     * Englisch (Singapur)
     */
    ENSG = 10000717,
    /**
     * Englisch (Südafrika)
     */
    ENZA = 10000718,
    /**
     * Belorussisch
     */
    BE = 10000737,
    /**
     * Färöisch
     */
    FO = 10000738,
    /**
     * Georgisch
     */
    KA = 10000739,
    /**
     * Traditionelles Chinesisch
     */
    ZHHANT = 10000740,
    /**
     * Tigrinja
     */
    TIER = 10000742,
    /**
     * Burmesisch
     */
    MYMM = 10000743,
    /**
     * Urdu
     */
    UR = 10000744,
    /**
     * Spanisch (Chile)
     */
    ESCL = 10000745,
    /**
     * Kurdisch (arabisch)
     */
    DA = 10000764,
    FI = 10000765,
    GL = 10000766,
    LB = 10000767,
    PL = 10000768,
    SV = 10000769,
    HE = 10000770,
    KUARABIQ = 10000772,
    ZHHANS = 10000805,
    SHHR = 10000806,
    ESEM = 10000807,
    SWKE = 10000811,
}
