import { PriceHelper} from "../../../sources/modules/price-helpers/price-helpers";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisRestReportService, IReport, IReportRow } from "../../../sources/openapi";
import { IReportColumn } from "../../../sources/openapi";
import { IJetApp } from "webix-jet";

export default class ReportTableBuilder {

    app:IJetApp;
    tableId:string;
    reportId:number | string;
    reportName:string;
    reportColumns:IReportColumn[] = [];
    reportRows:IReportRow[] = [];
    rows:{[key:string]:string}[];
    headerId:string;
    customDatatableOptions:{[key:string]:string | boolean | number} = {
        "adjust":true
    };
    customColumnsOptions:{[key:string]:string | boolean | number}[] = [];    
    additionalColumns:{id:string, template:Function | string}[];
    additionalEvents:{[key:string]:Function};

    constructor(app:IJetApp, tableId:string, queryParam:{reportId:number, reportName?:string}, reportName:string,  additionalColumns?:{id:string, template:Function | string}[], customDatatableOptions?:{[key:string]:string | boolean | number}, additionalEvents?:{[key:string]:Function}) {
        this.app = app;
        this.tableId = tableId;
        this.reportId = queryParam.reportId;
        if(queryParam.reportName) {
            this.reportName = reportName;
        }   
        if(additionalColumns) {
            this.additionalColumns = additionalColumns;
        }
        if(customDatatableOptions) {
            this.customDatatableOptions = customDatatableOptions;
        }
        if(additionalEvents) {
            this.additionalEvents = additionalEvents;
        }
    }

    buildReportTable():void {
        const table = webix.$$(`${this.tableId}`) as webix.ui.datatable;
        webix.extend(table, webix.ProgressBar);
        (table as unknown as{showProgress:(text:string)=>void}).showProgress(i18nHelper.getTranslation("Creating report..."));
        this.createReport();
    }

    getReportData():Promise<IReport> {
        let params:{reportId?:number,reportName?:string} = {
            reportName:this.reportName
        };
        if(this.reportId) {
            params = {
                reportId:this.reportId as number
            }
        }
        return CognovisRestReportService.getReport(params)
        .then(report => {
            return report
        })
        .catch(err => {
            webix.alert({
                text:err.body.message,
                width:420,
                type: "alert-error",
                css:"cog-import-fp-trados-modal"
            });     
            const table = webix.$$(`${this.tableId}`) as webix.ui.datatable;
            webix.extend(table, webix.ProgressBar);
            (table as unknown as webix.ProgressBar).hideProgress();
            (table as webix.ui.datatable & {showOverlay:(message:string) => void}).showOverlay(i18nHelper.getTranslation("Corrupted data"));

            return null
        });
        
    }

    createReport():void {
        this.getReportData()
        .then(report => {
            if(report) {
                this.reportName = report.report.name;
                this.reportColumns = report.report_columns;
                this.reportRows = report.report_rows;
                const header = webix.$$(`${this.tableId}Header`) as webix.ui.template;
                if(header) {
                    const currentTitle = header.getNode().textContent;
                    // Update only if title is empty
                    header.setHTML(this.reportName);
                }
                const datatable = this.getDatatableView();
                datatable.define("columns", this.getDatatableColumns());
                this.rows = this.getDatatableRows();
                datatable.define("data", this.rows);
                datatable.refresh();
                this.app.attachEvent("action::reload-report-table", () => {
                    this.refreshTable();
                }); 
            }
        });
    }

    recreateTable(newReportId:number):void {
        this.reportId = newReportId;
        const container = webix.$$(`${this.tableId}Container`) as webix.ui.layout;
        if(container) {
            container.removeView(this.tableId);
        }
        webix.extend(container, webix.ProgressBar);
        (container as unknown as{showProgress:(text:string)=>void}).showProgress(i18nHelper.getTranslation("Creating report..."));
        const datatable = this.getDatatable();
        container.addView(datatable);
        (container as unknown as webix.ProgressBar).hideProgress();
        this.buildReportTable();
    }

    refreshTable():void {
        this.reportColumns = [];
        this.reportRows = [];
        this.rows = [];
        const datatable = this.getDatatableView();
        datatable.clearAll();
        (datatable as unknown as {showProgress:(text:string)=>void}).showProgress(i18nHelper.getTranslation("Creating report..."));
        this.getReportData()
        .then(report => {
            this.reportName = report.report.name;
            this.reportColumns = report.report_columns;
            this.reportRows = report.report_rows;
            this.setTitle(this.reportName);
            this.rows = this.getDatatableRows();
            datatable.define("data", this.rows);
            datatable.refresh();   
        });
    }

    getDatatableView():webix.ui.datatable {
        const datatable = webix.$$(`${this.tableId}`) as webix.ui.datatable;
        return datatable
    }

    setTitle(newTitle:string):void {
        const header = webix.$$(`${this.tableId}Header`) as webix.ui.template;
        if(header) {
            header.setHTML(newTitle);
        }
    }

    setReportId(newReportId:number):void {
        this.reportId = newReportId;
    }

    bindWebixDatatypes(report:IReport):IReport {
        report.report_columns.map(column => {
            report.report_rows.map(row => {
                row.cells.map(cell => {
                    if(column.column_name === cell.id) {
                        const webixDatatype = this.matchProperWebixDatatatype(cell.display_value);
                        column["webixDatatype"] = webixDatatype;
                    }
                });
                
            });
        });
        return report;
    }

    matchProperWebixDatatatype(value:string) {
        let result = "string";
        if(value.match(/^-?\d+$/) || value.match(/^\d+\.\d+$/)) {
            result = "int";
        }
        return result
    }

    getDatatableColumns():{id:string, header:{}, sort:string, adjust:boolean, tooltip:boolean, fillspace:boolean}[] {
        const columns = [];
        this.reportColumns.map(reportColumn => {
            const colObj = {
                id:`${reportColumn["variable_name"]}`,
                header:this.getProperHeader(reportColumn),
                sort:this.getProperSortingMethod(reportColumn),
                adjust:this.customDatatableOptions["adjust"],
                tooltip:true,
                fillspace:!this.customDatatableOptions["adjust"],
                maxWidth:1000
            };
            
            if(this.getProperFormat(reportColumn)) {
                colObj["format"] = this.getProperFormat(reportColumn);
            }
            if(this.getProperColumnMap(reportColumn)) {
                colObj["map"] = this.getProperColumnMap(reportColumn);
            }
            if(this.getProperTemplate(reportColumn)) {
                colObj["template"] = this.getProperTemplate(reportColumn);
            }
            columns.push(colObj);
        });
        if(this.additionalColumns) {
            return [...columns, ...this.additionalColumns]
        } else {
            return columns
        }
        
    }

    getDatatableRows():{[key:string]:string}[] {
        const rows = [];
        if(this.reportRows !== null) {
            this.reportRows.map(reportRow => {
                const obj = {};
                reportRow.cells.map(cell => {
                    try {
                        obj[cell.id] = JSON.parse(cell.display_value);
                        obj[`fullObj_${cell.id}`] = JSON.parse(cell.display_value);
                    } catch(e) {
                        if(cell.display_value.indexOf("[") > -1 && cell.display_value.indexOf("]") > -1) {
                            obj[cell.id] = cell.display_value.slice(1, -1).split(",");
                            obj[`fullObj_${cell.id}`] = {id:cell.value, name:cell.display_value.slice(1, -1).split(",")};
                        } else {
                            obj[`${cell.id}`] = cell.display_value;
                            obj[`fullObj_${cell.id}`] =  {id:cell.value, name:cell.display_value};
                        }
                    }
                    const date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
                    if ((date_regex.test(obj[cell["id"]]))) {
                        obj[cell["id"]] = new Date(obj[cell["id"]])
                    }
                });
                rows.push(obj);
            });
        }
        (this.rows as {[key:string]:string}[]) = rows;
        return rows
    }

    getDatatableWithContainer():webix.ui.layoutConfig {
        return {
            view:"layout",
            id:`${this.tableId}Container`,
            rows:[
                this.getDatatable()
            ]
        }
    }

    getDatatable():webix.ui.datatableConfig {
        const coreEvents = {
        };
        return {
            view:"datatable",
            tooltip:true,
            css:"report-table",
            leftSplit:1,
            id:`${this.tableId}`,
            on : {...coreEvents, ...this.additionalEvents},
            onClick: {
                "webix_cell": (e, obj) => {
                    const datatable = this.getDatatableView();
                    const object = datatable.getItem(obj.row);
                    const objectType = this.figureOutObjectType(object);
                }
            }
        } as unknown as webix.ui.datatableConfig
    }

    getProperFormat(column:IReportColumn):string {
        let properFormat = undefined;
        switch(column["column_type"].name.toLowerCase()) {
            case "date":
            case "datum":
                properFormat = webix.Date.dateToStr("%d.%m.%Y", true);
                break;
        };
        return properFormat   
    }

    getProperColumnMap(column:IReportColumn):string {
        let properColumnMap = undefined;
        switch(column["column_type"].name) {
            case "Date":
                properColumnMap = `(date)${column.column_name}`;
                break;
            case "CogObject":
                properColumnMap = `#${column["variable_name"]}.name#`;
                break;
            case "CogObjectArray":
                properColumnMap = `#${column["variable_name"]}[0].name#`;
                break;

        }
        return properColumnMap
    }

    getProperHeader(column:IReportColumn):unknown {
        let header;
        let headerText = column["column_header"];
        if(!headerText) {
            headerText = column.column_name;
        }
        switch(column["column_type"].name) {
            case "String":
                case "im_name_from_id":
                header = [{text:headerText}, {content:"textFilter"}];
                break;
            case "Price":
                header = [{text:headerText}, {content:"numberFilter"}];
                break;
            case "Date":
            case "Datum":
                header = [{text:headerText}, {content:"dateRangeFilter"}];
                break;
            case "Category":
                header = [{text:headerText}, {content:"multiComboFilter", inputConfig:{tagMode:true,fitMaster:false, minWidth:200, maxWidth:1000}}];
                break;          
            case "CogObject":
                header = [{text:headerText}, {
                    content:"textFilter",
                    compare:(item,value) => {
                        if(item.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                            return true
                        } else {
                            return false
                        }
                    }
                }];
                break;          
            case "NamedIDArray":
            case "CogObjectArray":
                header = [{text:headerText}, {
                    content:"multiComboFilter", 
                    inputConfig:{tagMode:true,fitMaster:false, minWidth:200, maxWidth:1000},
                    options:(val) => {
                        const catArr = [];
                        this.rows.map(row => {
                            if(row[column["variable_name"]] && Array.isArray(row[column["variable_name"]])) {
                                (row[column["variable_name"]] as unknown as []).map(splittedCat => {
                                    if(catArr.indexOf(splittedCat) === -1) {
                                        if(splittedCat["name"]) {
                                            catArr.push(splittedCat["name"]);
                                        } else {
                                            catArr.push(splittedCat);
                                        }
                                    }
                                });
                            }
                        });
                        catArr.push({id:"none", value:i18nHelper.getTranslation("_none")})
                        return catArr
                    },
                    compare:(item,value) => {
                        let result = false;
                        let countMatched = 0;
                        const keys = Object.keys(value);
                        const total = keys.length;
                        keys.map(key => {
                            if(item[0] && item[0]["name"]) {
                                if(item[0]["name"].indexOf(key) > -1) {
                                    countMatched++
                                }
                            } else {
                                if(item.indexOf(key) > -1) {
                                    countMatched++
                                }
                            }
                        });
                            console.log(value.none, item)
                        if(countMatched === total || (value.none && (item.length === 0 ||item === ""))) {
                            result = true;
                        }
                        return result
                    }
                }];
                break;
            default:
                header = [{text:headerText}, {content:"dateFilter"}];
                break;
        }
        return header         
    }

    getProperTemplate(column:IReportColumn):Function {
        let properType = undefined;
        //First we check if datatype is an array
        // if(column["column_type"]?.name.toLowerCase() && column["column_type"]?.name.toLowerCase().indexOf("array") > -1) {
        //     properType = this.getArrayDerefFunction(this, column);
        //     return properType;
        // }
        switch(column["column_type"]?.name) {
            case "String":
                properType = (obj) => {
                    return `${obj[column["variable_name"]]}`;
                };
                break;
            case "Price":
                properType = this.priceFormatFunction(this, column);
                break;
            case "CogObject":
                properType = (obj) => {
                    const objectType = this.figureOutObjectType(obj);
                    if(objectType) {
                        if(obj[`fullObj_${column["variable_name"]}`]["id"]) {
                            return this.getUrlBasedOnObjectType(obj[`fullObj_${column["variable_name"]}`]["id"], obj[`fullObj_${column["variable_name"]}`]["name"], objectType);
                        } else {
                            return this.getUrlBasedOnObjectType(obj[column["variable_name"]]["id"], obj[column["variable_name"]]["name"], objectType);
                        }
                    } else {
                        return `${obj[column["variable_name"]]["name"]}`
                    }
                    
                };
                break;
            case "CogObjectArray":
                properType = (obj) => {
                    const onlyNames = [];
                    obj[column["variable_name"]].forEach(element => {
                        onlyNames.push(element.name);
                    });
                    return onlyNames.join(", ");
                }
                break;
            case "Email":
                properType = (obj) => {
                    return `<a class='text-link' href='mailto:${obj[column["variable_name"]]}'>${obj[column["variable_name"]]}</a>`;
                };
        }
        return properType     
    }

    getUrlBasedOnObjectType(variableId:string, variableName:string, objectType:string):string {
        let url;
        switch(objectType) {
            case "user":
                url = `<a class='text-link' href='/#!/user-details?user_id=${variableId}'>${variableName}</a>`;
                break;
            case "im_project":
                url = `<a class='text-link' href='/#!/project-info?project_id=${variableId}'>${variableName}</a>`;
                break;
            case "im_company":
                url = `<a class='text-link' href='/#!/company?company_id=${variableId}'>${variableName}</a>`;
                break;
            default:
                url = `<a class='text-link' href='/#!/project-info?project_id=${variableId}'>${variableId}</a>`;
                break;
        }
        return url;
        
    }
    
    getArrayDerefFunction(obj:{}, column:IReportColumn):Function {
        if(obj[column.column_name]) {
            const func = (obj) => {
                return obj[column.column_name].join(",");
            };
            return func
        } else {
            return () => {
                return []
            }
        }
    }

    priceFormatFunction(obj:{}, column:IReportColumn):Function {
        const func = (obj) => {
            const price = obj[`fullObj_${column.variable_name}`].id.replace(/,/g, '.');
            //return price
            return PriceHelper.formatPrice(Number(price));
        };
        return func
    }

    getProperSortingMethod(column:IReportColumn):string | Function {
        let properType;
        switch(column["column_type"].name.toLowerCase()) {
            case "string":
            case "im_name_from_id":
                properType = "int";
                ///properType = properType();
                break;
            case "Price":
                properType = "int"
                break;
            case "date":
            case "Date":
            case "Datum":
            case "datum":
                properType = "date";
                break;
            default:
                properType = "string";
                break;
        }
        return properType
    }

    getSortingFunc(obj:{}, column:IReportColumn):Function {
        let type;
        const func = function(obj) {
            if(obj[column.column_name].match(/^-?\d+$/) || obj[column.column_name].match(/^\d+\.\d+$/)) {
                type = "int";
            } else {
                type = "string";
            }
            return type
        };
        return func
    }

    figureOutObjectType(fullObject:unknown):string {
        let clickedObjType = "";
        if(fullObject) {
            let objectFl;
            let objectFlSec;
            Object.keys(fullObject).map(keyFirst => {
                Object.keys(fullObject[keyFirst]).map(keySec => {
                    if(keySec === "object_type") {
                        objectFl = keyFirst;
                        objectFlSec = keySec;
                        if(objectFl && objectFlSec) {
                            clickedObjType = fullObject[objectFl][objectFlSec];
                        }
                    }
                });
            });
            return clickedObjType;
        }
    }

}