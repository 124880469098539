/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean0 } from '../models/Boolean0';
import type { BooleanT } from '../models/BooleanT';
import type { IMaterial } from '../models/IMaterial';
import type { IMaterialBody } from '../models/IMaterialBody';
import type { ImCompanyId } from '../models/ImCompanyId';
import type { ImMaterialId } from '../models/ImMaterialId';
import type { ImPriceId } from '../models/ImPriceId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IntranetMaterialStatus } from '../models/IntranetMaterialStatus';
import type { IntranetMaterialType } from '../models/IntranetMaterialType';
import type { IntranetPriceStatus } from '../models/IntranetPriceStatus';
import type { IntranetTranslationFileType } from '../models/IntranetTranslationFileType';
import type { IntranetTranslationLanguage } from '../models/IntranetTranslationLanguage';
import type { IntranetTranslationSubjectArea } from '../models/IntranetTranslationSubjectArea';
import type { IntranetUom } from '../models/IntranetUom';
import type { IPrice } from '../models/IPrice';
import type { IPriceBody } from '../models/IPriceBody';
import type { StringDate } from '../models/StringDate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestPricingService {

    /**
     * Handler for GET rest calls which returns materials for given type_id (multiple comma seperated ids possible)
     * @returns IMaterial Array of material objects of the type
     * @throws ApiError
     */
    public static getMaterial({
        materialTypeId,
        materialId,
        materialNamePart,
        sourceLanguageId,
        targetLanguageId,
        subjectAreaId,
        taskTypeId,
        materialUomId,
        materialStatusId,
        fileTypeId,
        materialBillableP,
    }: {
        /**
         * IDs of required material type for which to return material information
         */
        materialTypeId?: IntranetMaterialType,
        /**
         * Material_id of the material we want to retrieve
         */
        materialId?: ImMaterialId,
        /**
         * Part of the material name we look for
         */
        materialNamePart?: string,
        /**
         * Source Language for the project. Typically the language the source material is in
         */
        sourceLanguageId?: IntranetTranslationLanguage,
        /**
         * Language into which this task is translated to. Leaving it empty will use the projects target languages
         */
        targetLanguageId?: IntranetTranslationLanguage,
        /**
         * Subject Area of the project (determines pricing among other things)
         */
        subjectAreaId?: IntranetTranslationSubjectArea,
        /**
         * category of the task type we look for
         */
        taskTypeId?: IntegerInt32,
        /**
         * Units of measure of this material
         */
        materialUomId?: IntranetUom,
        /**
         * Status of the material
         */
        materialStatusId?: IntranetMaterialStatus,
        /**
         * File type we work on
         */
        fileTypeId?: IntranetTranslationFileType,
        /**
         * Is the material billable
         */
        materialBillableP?: BooleanT,
    }): CancelablePromise<Array<IMaterial>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material',
            query: {
                'material_type_id': materialTypeId,
                'material_id': materialId,
                'material_name_part': materialNamePart,
                'source_language_id': sourceLanguageId,
                'target_language_id': targetLanguageId,
                'subject_area_id': subjectAreaId,
                'task_type_id': taskTypeId,
                'material_uom_id': materialUomId,
                'material_status_id': materialStatusId,
                'file_type_id': fileTypeId,
                'material_billable_p': materialBillableP,
            },
        });
    }

    /**
     * Creates a new material so new pricing can be added.
     * @returns IMaterial Array of material objects of the type
     * @throws ApiError
     */
    public static postMaterial({
        requestBody,
    }: {
        /**
         * Material Information
         */
        requestBody: IMaterialBody,
    }): CancelablePromise<IMaterial> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/material',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns an array of prices for a company (or a specific price)
     * @returns IPrice Array of prices
     * @throws ApiError
     */
    public static getPrice({
        priceId,
        companyId,
        materialId,
        validFrom,
        validThrough,
        priceStatusId,
    }: {
        /**
         * Identifier for the price
         */
        priceId?: ImPriceId,
        /**
         * Company for which we want to retrieve prices
         */
        companyId?: ImCompanyId,
        /**
         * Material for which we want to retrieve prices
         */
        materialId?: ImMaterialId,
        /**
         * display only prices that are valid_from...
         */
        validFrom?: StringDate,
        /**
         * display only dates that are valid_through...
         */
        validThrough?: StringDate,
        /**
         * Only search for prices of this status (ypically set to active)
         */
        priceStatusId?: IntranetPriceStatus,
    }): CancelablePromise<Array<IPrice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/price',
            query: {
                'price_id': priceId,
                'company_id': companyId,
                'material_id': materialId,
                'valid_from': validFrom,
                'valid_through': validThrough,
                'price_status_id': priceStatusId,
            },
        });
    }

    /**
     * Creates a new price entry for a company. Will return existing price if price already exists.
     * @returns IPrice Price we just created
     * @throws ApiError
     */
    public static postPrice({
        requestBody,
    }: {
        /**
         * Price information (with material extension)
         */
        requestBody: IPriceBody,
    }): CancelablePromise<IPrice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/price',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Nuke or inactivate a price
     * @returns IPrice Price we just deleted
     * @throws ApiError
     */
    public static deletePrice({
        priceId,
        nukeP,
    }: {
        /**
         * Price we want to delete
         */
        priceId: ImPriceId,
        /**
         * define if we want to nuke instead of inactivate the price. Nuking only for admins.
         */
        nukeP?: Boolean0,
    }): CancelablePromise<IPrice> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/price',
            query: {
                'price_id': priceId,
                'nuke_p': nukeP,
            },
        });
    }

    /**
     * Creates a new price entry for a company. Will default to update if price already exists
     * @returns IPrice Price we just created
     * @throws ApiError
     */
    public static putPrice({
        priceId,
        requestBody,
    }: {
        /**
         * Price which we want to update
         */
        priceId: ImPriceId,
        /**
         * Price information (with material extension)
         */
        requestBody: IPriceBody,
    }): CancelablePromise<IPrice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/price',
            query: {
                'price_id': priceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Return a file with the price list of a company as CSV
     * @returns string Generic description
     * @throws ApiError
     */
    public static getPriceList({
        companyId,
    }: {
        /**
         * Company whos pricelist we want to download
         */
        companyId: ImCompanyId,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/price_list',
            query: {
                'company_id': companyId,
            },
        });
    }

    /**
     * Upload a file to a company using mass import
     * @returns IPrice Array of prices
     * @throws ApiError
     */
    public static postPriceListFromFile({
        companyId,
        path,
        filename,
    }: {
        /**
         * Company whos pricelist we Upload
         */
        companyId: ImCompanyId,
        /**
         * location of the file on the harddisk which we import the CSV from
         */
        path: string,
        /**
         * Name of the file (original filename) - used to identify XLS
         */
        filename: string,
    }): CancelablePromise<Array<IPrice>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/price_list_from_file',
            query: {
                'company_id': companyId,
                'path': path,
                'filename': filename,
            },
        });
    }

}
