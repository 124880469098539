import {  IReportColumn, IReportRow } from "../../../sources/openapi";
import { JetView } from "webix-jet";
import { DatetimeHelper } from "../../../sources/modules/datetime-helpers/datetime-helpers";
import ReportTableBuilder from "./report-table-builder";

export default class ReportBaseView extends JetView {

    idPrefix = "rbw";
    reportTableBuilder:ReportTableBuilder;
    reportId:number 
    reportName = "";
    reportColumns:IReportColumn[] = [];
    reportRows:IReportRow[] = [];
    rows:{[key:string]:string}[] = [];
    
    config():webix.ui.layoutConfig {
        this.reportId = this.getParam("report_id", true);
        this.reportTableBuilder = new ReportTableBuilder(this.app, `${this.idPrefix}ReportDatatable`, {reportId:this.reportId}, this.reportName);
        return this.getLayout();
    }

    init():void {
        this.reportTableBuilder.buildReportTable();
    }

    urlChange():void {
        const newReportId = this.getParam("report_id", true);
        if(this.reportId !== newReportId) {
            this.reportId = newReportId;
            this.reportTableBuilder.recreateTable(this.reportId);
        }
    }

    getLayout():webix.ui.scrollviewConfig {
        return {
            view: "scrollview",
            scroll: "y",
            gravity:1,
            body: {
                view:"layout",
                type:"clean",
                css:"cog-content",
                autoWidth: true,
                padding:13,
                gravity:1,
                rows:[
                    this.getHeader(),
                    {
                        view:"layout",
                        rows:[
                            this.reportTableBuilder.getDatatableWithContainer()
                        ]
                    }
                ]
            }
        };
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            height:44,
            css: "cog-box-header", 
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    id:`${this.idPrefix}ReportDatatableHeader`,
                    css: "cog-box-header",  
                    template: `${this.reportName}`,
                    borderless: true
                },
                {
                    view:"button",
                    type:"icon",
                    width:28,
                    align: "right", 
                    icon: "far fa-file-excel",
                    click:() => {
                        const datetime = DatetimeHelper.parseDateWithHour(new Date());
                        const name = `${this.reportName}_${datetime}`;
                        const table = webix.$$(`${this.idPrefix}ReportDatatable`) as webix.ui.datatable;
                        webix.toExcel(table, {
                            filename:name,
                            name:name,
                            filterHTML:true
                        });
                    }
                },
                {
                    view:"button",
                    type:"icon",
                    width:28,
                    align: "right", 
                    icon: "far fa-file-csv",
                    click:() => {
                        const datetime = DatetimeHelper.parseDateWithHour(new Date());
                        const name = `${this.reportName}_${datetime}`;
                        const table = webix.$$(`${this.idPrefix}ReportDatatable`) as webix.ui.datatable;
                        webix.toCSV(table, {
                            filename:name,
                            name:name
                        });
                    }
                }
            ]
        };
        return header;
    }


}