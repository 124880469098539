import CognovisBasicModal from "../../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../../sources/modules/i18n-helper/i18n-helper";
import { ICategoryObject, ITransProject, ITransTask } from "../../../../sources/openapi";

type TaskUnitsByLanguage = {
    hashKey:string,
    targetLanguage:ICategoryObject,
    unitOfMeasure:ICategoryObject,
    totalUnits:number,
    totalBillableUnits:number,
    tasksNames:string      
};

export default class TotalTasksUnitsModal extends CognovisBasicModal {

    idPrefix = "ttum";
    projectTasks:ITransTask[];
    currentProject:ITransProject;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ",620,480);
        return mainLayout;
    }

    openModal(project:ITransProject,projectTasks:ITransTask[]):void {
        this.currentProject = project;
        const taskUnitsPerLanguageData = this.prepareDataForTable(projectTasks);
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const modalContent = this.getContent(taskUnitsPerLanguageData);
        const translatedTitle = i18nHelper.getTranslation("Task_units_per_language");
        const modalTitle = `${project.project_nr}: ${translatedTitle}`;
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle); 
    }

    closeModal():void {
        this.hide();
    }

    getContent(taskUnitsPerLanguageData:TaskUnitsByLanguage[]):webix.ui.layoutConfig {
        const layout = {
            view:"layout",
            rows:[
                this.getToolbar(),
                this.getDatatable(taskUnitsPerLanguageData)
            ]
        };
        return layout;
    }

    getToolbar():webix.ui.toolbarConfig {
        const toolbar = {
            view:"toolbar",
            gravity:1,
            rows:[
                {
                    view:"button",
                    type:"icon",
                    icon: "far fa-file-excel",
                    align: "left",
                    inputWidth:28,
                    click:() => {
                        const tableByLocalId = this.$$(`${this.idPrefix}TasksUnitsByLanguageDatatable`) as webix.ui.datatable;
                        const tableByUniqueId = webix.$$(`${tableByLocalId.config.id}`);
                        webix.toExcel(tableByUniqueId, {
                            filename:this.currentProject.project_nr,
                            name:this.currentProject.project_nr,
                        });
                    }
                }
            ]
        };
        return toolbar
    }

    getDatatable(taskUnitsPerLanguageData:TaskUnitsByLanguage[]):webix.ui.datatableConfig {
        const datatable = {
            view:"datatable",
            localId:`${this.idPrefix}TasksUnitsByLanguageDatatable`,
            tooltip:true,
            columns:[
                {
                    id:"targetLanguageId",
                    map:"#targetLanguage.id#",
                    hidden:true
                },
                {
                    id:"unitOfMeasureId",
                    map:"#unitOfMeasure.id#",
                    hidden:true
                },
                {
                    id:"targetLanguageName",
                    fillspace:true,
                    header: [i18nHelper.getTranslation("Target"), { content: "multiSelectFilter" }],
                    map:"#targetLanguage.name#",
                    sort:"text",
                    tooltip:(task:TaskUnitsByLanguage) => {
                        return task.tasksNames
                    }
                },
                {
                    id:"unitOfMeasureName",
                    header: [i18nHelper.getTranslation("Uom"), { content: "selectFilter" }],
                    map:"#unitOfMeasure.name#",
                    sort:"text",
                    tooltip:false
                },
                {
                    id:"totalUnits",
                    header: [i18nHelper.getTranslation("Units"), { content: "numberFilter" }],
                    map:"#totalUnits#",
                    sort:"int",
                    tooltip:false
                },
                {
                    id:"totalBillableUnits",
                    header: [i18nHelper.getTranslation("billable_units"), { content: "numberFilter" }],
                    map:"#totalBillableUnits#",
                    sort:"int",
                    tooltip:false
                }
            ],
            data:taskUnitsPerLanguageData
        };
        return datatable
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
 
                    ] 
                };
        return buttons;
    }

    prepareDataForTable(projectTasks:ITransTask[]):TaskUnitsByLanguage[] {
        // We need to create a hash key with combined target_languge and unit of measure ids
        projectTasks.map(task => {
            task["hashKey"] = `${task.target_language.id}|${task.task_uom.id}`; 
        });
        const arrayUniqueByKey = [...new Map(projectTasks.map(item =>
            [item["hashKey"], item])).values()];
        const arr:TaskUnitsByLanguage[] = [];
        arrayUniqueByKey.map(item => {
            const totalUnits = projectTasks.filter(task => task["hashKey"] === item["hashKey"]).reduce((a, b) => +a + +b.task_units, 0);
            const totalBillableUnits = projectTasks.filter(task => task["hashKey"] === item["hashKey"]).reduce((a, b) => +a + +b.billable_units, 0);
            const taskNames = projectTasks.filter(task => task["hashKey"] === item["hashKey"]).map(task => task.task.name).join(", ");
            arr.push({
                hashKey:item["hashKey"],
                targetLanguage:projectTasks.find(task => task["hashKey"] === item["hashKey"]).target_language,
                unitOfMeasure:projectTasks.find(task => task["hashKey"] === item["hashKey"]).task_uom,
                totalUnits:totalUnits,
                totalBillableUnits:totalBillableUnits,
                tasksNames:taskNames
            });
        });
        return arr;
    }

    uniqueObjectByTwoProps(arr, keyProps) {
        const kvArray = arr.map(entry => {
            const key = keyProps.map(k => entry[k]).join('|');
                return [key, entry];
        });
        const map = new Map(kvArray);
        return Array.from(map.values());
    }

    onlyUniqueLanguages() {

    }

}