import * as usermanager from "@xbs/usermanager";

export default class UmCustomSidebar extends usermanager.views["sidebar"] {

    config() {
        const ui = super.config();
        if(ui.rows[0].rows[0]) {
            const newButton = ui.rows[0].rows[0];
            newButton.hidden = true;
        }
        ///ui.width = 52;
        return ui
    }
}