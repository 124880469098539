import FreelancerView from './freelancer-view'
import CustomerView from './customer-view'
import { CognovisRestInvoiceService, IGroup, IInvoice, IntranetBizObjectRole,  IntranetCostStatus, IntranetCostType, WebixPortalTranslationService } from '../../../sources/openapi';

import ProjectDetails from 'views/project-details';
import { UserProfile } from '../../../sources/modules/cognovis-profile/profiles-types';
import CognovisProfile from '../../../sources/modules/cognovis-profile/cognovis-profile';
import { i18nHelper } from '../../../sources/modules/i18n-helper/i18n-helper';
import { PriceHelper } from '../../../sources/modules/price-helpers/price-helpers';
import MultipleDocumentsCreator from 'views/modals/multiple-documents-creator';
import { container } from 'tsyringe';
import { CognovisPleaseWaitWindow } from '../../../sources/services/cognovis-please-wait-window';
import { CognovisNavigator } from '../../../sources/modules/cognovis-navigator/cognovis-navigator';
import QuickPicker from '../../../sources/modules/quick-picker';


export default class FinancialOverView extends ProjectDetails  {

    idPrefix = "fo";
    projectId:number;
    freelancerTableView:FreelancerView;
    customerTableView:CustomerView;
    allProjectRelatedDocuments:IInvoice[];
    projectRelatedQuotes:IInvoice[];
    projectRelatedInvoices:IInvoice[];
    projectRelatedPurchaseOrders:IInvoice[];
    projectRelatedCreditNotes:IInvoice[];
    projectRelatedTimesheetCosts:IInvoice[];
    projectRelatedTimesheetBudget:IInvoice[];
    projectRelatedExpenseBudget:IInvoice[];
    projectRelatedExpenses:IInvoice[];
    emptyCost:IInvoice;
    userRole:IGroup;
    userRoles:IGroup[];
    currency:string;
    multipleDocumentsGeneratorModal:MultipleDocumentsCreator;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    providerSelectionModal:QuickPicker;

    config():Promise<webix.ui.scrollviewConfig> {
        // below we have proper version, but will change that in future, for now using project_id from session
        this.projectId = this.getParam("project_id", false);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return this.getProjectFinanceDocuments(this.projectId)
        .then(financialDocuments => {
            if(financialDocuments[0]?.currency_name) {
                this.currency = financialDocuments[0].currency_name;
            }
            this.linkFinancialDocuments(financialDocuments);
            return this.getLayout();
        });
    }

    linkFinancialDocuments(financialDocuments:IInvoice[]):void {
        this.allProjectRelatedDocuments = financialDocuments;
        // Map linked documents first
        this.allProjectRelatedDocuments = this.mapLinkedInvoices(this.allProjectRelatedDocuments);
        this.projectRelatedQuotes = this.extractProjectRelatedQuotes(this.allProjectRelatedDocuments);
        this.projectRelatedInvoices = this.extractProjectRelatedInvoices(this.allProjectRelatedDocuments);
        // Po's
        this.projectRelatedPurchaseOrders = this.extractProjectRelatedPurchaseOrders(this.allProjectRelatedDocuments);
        // Credit Notes
        this.projectRelatedCreditNotes = this.extractProjectRelatedCreditNotes(this.allProjectRelatedDocuments);
        // Timesheet Costs
        this.projectRelatedTimesheetCosts = this.extractProjectRelatedTimesheetCosts(this.allProjectRelatedDocuments);
        // Timesheet Budget
        this.projectRelatedTimesheetBudget = this.extractProjectRelatedTimesheetBudget(this.allProjectRelatedDocuments);
        // Expense Budget
        this.projectRelatedExpenseBudget = this.extractProjectRelatedExpenseBudget(this.allProjectRelatedDocuments);
        // Expenses
        this.projectRelatedExpenses = this.extractProjectRelatedExpenses(this.allProjectRelatedDocuments);
        // Freelancer table, we display Purchase Orders with linked Credit Notes\
        this.freelancerTableView = new FreelancerView(this.app,this.projectId, () => {
            this.refreshData();
        });
        this.freelancerTableView.freelancerFiancialDocuments = this.createRowsFreelancerTable(this.allProjectRelatedDocuments);
        // Customer table, we display Quotes with linked Invoices in there
        this.customerTableView = new CustomerView(this.app, this.projectId, () => {
            this.refreshData();
        });
    }

    init():void {
        // Insert data to customer table
        setTimeout(() => {
            this.updateTables(this.allProjectRelatedDocuments);
        },1);
    }

    urlChange():void {
        const newProjectId = this.getParam("project_id", true);
        if(newProjectId !== this.projectId) {
            this.projectId = newProjectId;
            this.refreshData();
        }
        super.urlChange();
    }

    updateTables(financialDocuments:IInvoice[]):void {
        const customerTable = webix.$$("customer-costs-table") as webix.ui.datatable;
        if(customerTable) {
            const customerFinancialDocuments = this.createRowsCustomerTable(financialDocuments);
            customerTable.clearAll();
            customerTable.refresh();
            customerTable.define("data", customerFinancialDocuments);
            customerTable.refresh();
        }
        const freelancerTable = webix.$$("freelancer-costs-table") as webix.ui.datatable;
        if(freelancerTable) {
            const freelancerFinancialDocuments = this.createRowsFreelancerTable(financialDocuments);
            freelancerTable.clearAll();
            customerTable.refresh();
            freelancerTable.define("data", freelancerFinancialDocuments);
            freelancerTable.refresh();
        }

        const state = this.buildDataForTables();
        const preliminaryCostsTable = webix.$$(`${this.idPrefix}PreliminaryCostsTable`) as webix.ui.datatable;
        const actualCostsTable = webix.$$(`${this.idPrefix}ActualCostsTable`) as webix.ui.datatable;
        const deltaCostsTable = webix.$$(`${this.idPrefix}DeltaCostsTable`) as webix.ui.datatable;

        if(preliminaryCostsTable && actualCostsTable && deltaCostsTable) {
            preliminaryCostsTable.clearAll();
            preliminaryCostsTable.define("data", state.preliminaryCosts);
            preliminaryCostsTable.refresh();
            
            actualCostsTable.clearAll();
            actualCostsTable.define("data", state.actualCosts);
            actualCostsTable.refresh();

            deltaCostsTable.clearAll();
            deltaCostsTable.define("data", state.deltaCosts);
            deltaCostsTable.refresh();
        }
    }

    refreshData():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        this.getProjectFinanceDocuments(this.projectId)
        .then(financialDocuments => {
            if(financialDocuments[0]?.currency_name) {
                this.currency = financialDocuments[0].currency_name;
            }
            this.linkFinancialDocuments(financialDocuments);
            this.updateTables(this.allProjectRelatedDocuments);
            this.cognovisPleaseWaitWindow.hide();
        });
    }

    getLayout():webix.ui.scrollviewConfig {
        const state:IFinancialOverviewData = this.buildDataForTables();
        if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
            return {
                view: "scrollview",
                scroll: "y",
                body: {
                    id:`${this.idPrefix}SummariesContainer`,
                    css:"cog-content",
                    padding:13,
                    rows: [
                        { view: "spacer", height: 4, },
                        this.getActionCombo(),
                        { view: "spacer", height: 4, },
                        {
                            cols: [
                                //preliminary costs datatable
                                {
                                    rows: [
                                        {
                                            view:"layout",
                                            rows:[
                                                { view: "template", css:"cog-box-header", type: "header", template: i18nHelper.getTranslation("Preliminary_costs"),},
                                                {
                                                    view: "datatable", header: false, height: 72, summaryTable:true,
                                                    id:`${this.idPrefix}PreliminaryCostsTable`,
                                                    columns: [
                                                        { 
                                                            id: "cost_type", 
                                                            footer: i18nHelper.getTranslation("Total_amount"),
                                                            fillspace:true 
                                                        },
                                                        { 
                                                            id: "cost_number", 
                                                            footer: state.preliminaryCostsTotalNumber, 
                                                            maxWidth:40,  
                                                        },
                                                        { 
                                                            id: "cost_amount", 
                                                            footer:{
                                                                text:`${state.preliminaryCostsTotalAmount}`,
                                                                css:"price-display"
                                                            }, 
                                                            css:'price-display' 
                                                        },
                                                    ],
                                                    data: state.preliminaryCosts
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    view:"spacer",
                                    width:13
                                },
                                //actual costs datatable
                                {
                                    rows: [
                                        {
                                            view:"layout",
                                            rows:[
                                                { view: "template", type: "header", css:"cog-box-header", template: i18nHelper.getTranslation("Actual_costs"), },
                                                {
                                                    view: "datatable", header: false, height: 72, summaryTable:true,
                                                    id:`${this.idPrefix}ActualCostsTable`,
                                                    columns: [
                                                        { 
                                                            id: "cost_type", 
                                                            footer: i18nHelper.getTranslation("Total_amount"), 
                                                            fillspace:true
                                                        },
                                                        { 
                                                            id: "cost_number", 
                                                            footer: state.actualCostsTotalNumber, 
                                                            maxWidth:40, 
                                                        },
                                                        { 
                                                            id: "cost_amount",
                                                            footer:{
                                                                text:`${state.actualCostsTotalAmount}`,
                                                                css:"price-display"
                                                            }, 
                                                            css:'price-display' 
                                                        },
                                                    ],
                                                    data: state.actualCosts
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    view:"spacer",
                                    width:13
                                },
                                //actual costs datatable
                                {
                                    rows: [
                                        {
                                            view:"layout",
                                            rows:[
                                                { view: "template", type: "header", css:"cog-box-header", template: i18nHelper.getTranslation("Delta_costs"), },
                                                {
                                                    view: "datatable", header: false, height: 72, summaryTable:true,
                                                    id:`${this.idPrefix}DeltaCostsTable`,
                                                    columns: [
                                                        { 
                                                            id: "cost_type", 
                                                            footer: i18nHelper.getTranslation("Total_amount"), 
                                                            fillspace:true
                                                        },
                                                        { 
                                                            id: "cost_number", 
                                                            footer: state.deltaCostsTotalNumber, 
                                                            maxWidth:40, 
                                                        },
                                                        { 
                                                            id: "cost_amount",
                                                            footer:{
                                                                text:`${state.actualCostsTotalAmount}`,
                                                                css:"price-display"
                                                            }, 
                                                            css:'price-display' 
                                                        },
                                                    ],
                                                    data: state.deltaCosts
                                                }
                                            ]
                                        }
                                    ]
                                },
                            ],
                        },
                        { view: "spacer", height: 13, },
                        this.customerTableView,
                        { view: "spacer", height: 13, },
                        this.freelancerTableView,
                        { view: "spacer", height: 13, },
                    ]
                }
            } as webix.ui.scrollviewConfig
        } else {
            return {
                view: "scrollview",
                scroll: "y",
                body: {
                    responsive:true,
                    padding:20,
                    rows: [
                        this.customerTableView,
                        { view: "spacer", height: 10, },        
                    ]
                }
            } as webix.ui.scrollviewConfig         
        }
    }

    buildDataForTables():IFinancialOverviewData {
        const premilinaryData = this.getPreliminarySummaryData(this.projectRelatedQuotes,this.projectRelatedPurchaseOrders,this.projectRelatedTimesheetBudget,this.projectRelatedExpenseBudget);
        const actualCosts = this.getActualCosts(this.projectRelatedInvoices,this.projectRelatedCreditNotes,this.projectRelatedTimesheetCosts,this.projectRelatedExpenses);
        const deltaCosts = this.getDeltaCosts(premilinaryData,actualCosts);
        const state = {
            preliminaryCosts: [
                { cost_type: `${i18nHelper.getTranslation("Quotes")}`, cost_number: premilinaryData.quotes.totalNumber, cost_amount: `  ${PriceHelper.formatPrice(premilinaryData.quotes.totalAmount, this.currency)}`},
                { cost_type: `${i18nHelper.getTranslation("Purchase_Orders")}`, cost_number:  premilinaryData.purchaseOrders.totalNumber, cost_amount: ` - ${PriceHelper.formatPrice(premilinaryData.purchaseOrders.totalAmount, this.currency)}` }
            ],
            preliminaryCostsTotalNumber: premilinaryData.totalNumberSummary,
            preliminaryTotalCosts:premilinaryData.totalCosts,
            preliminaryCostsTotalAmount: `${PriceHelper.formatPrice(premilinaryData.totalAmountSummary, this.currency)}`, 
            preliminaryCostsPercentage:this.calculatePercentage(premilinaryData.totalAmountSummary,premilinaryData.quotes.totalAmount).toFixed(2),
            preliminaryPieChartData:[
                    { margin:premilinaryData.totalAmountSummary, label:`${i18nHelper.getTranslation("Total")} <br/>${PriceHelper.formatPrice(premilinaryData.totalAmountSummary, this.currency)}`, color: "#90CC8E" },
                    { margin:premilinaryData.totalCosts, label:`${i18nHelper.getTranslation("Costs")} <br/>${PriceHelper.formatPrice(premilinaryData.totalCosts, this.currency)}`, color: "#EEB389" },
            ],
            actualCosts: [
                { cost_type: `${i18nHelper.getTranslation("Invoices")}`, cost_number: `${actualCosts.invoices.totalNumber}`, cost_amount: `  ${PriceHelper.formatPrice(actualCosts.invoices.totalAmount,this.currency)}` },
                { cost_type: `${i18nHelper.getTranslation("Credit_notes")}`, cost_number: `${actualCosts.creditNotes.totalNumber}`, cost_amount: ` - ${PriceHelper.formatPrice(actualCosts.creditNotes.totalAmount,this.currency)}` }
            ],
            actualCostsTotalNumber: actualCosts.totalNumberSummary,
            actuaolCostsTotalCosts:actualCosts.totalCosts,
            actualCostsTotalAmount: `${PriceHelper.formatPrice(actualCosts.totalAmountSummary, this.currency)}`,
            actualCostsPercentage:this.calculatePercentage(actualCosts.totalAmountSummary,actualCosts.invoices.totalAmount).toFixed(2),
            actualCostsPieChartData: [
                { margin:actualCosts.totalAmountSummary, label:`${i18nHelper.getTranslation("Total")} <br/>${PriceHelper.formatPrice(actualCosts.totalAmountSummary,this.currency)}`, color: "#90CC8E" },
                { margin:actualCosts.totalCosts,  label:`${i18nHelper.getTranslation("Costs")} <br/>${PriceHelper.formatPrice(actualCosts.totalCosts, this.currency)}`, color: "#EEB389" },
            ],
            deltaCosts: [
                { cost_type: `${i18nHelper.getTranslation("Customer")}`, cost_number: `${deltaCosts.invoices.totalNumber}`, cost_amount: `  ${PriceHelper.formatPrice(deltaCosts.invoices.totalAmount,this.currency)}` },
                { cost_type: `${i18nHelper.getTranslation("Provider")}`, cost_number: `${deltaCosts.creditNotes.totalNumber}`, cost_amount: ` - ${PriceHelper.formatPrice(deltaCosts.creditNotes.totalAmount,this.currency)}` }
            ],
            deltaCostsTotalNumber: deltaCosts.totalNumberSummary,
            deltaCostsTotalCosts:deltaCosts.totalCosts,
            deltaCostsTotalAmount: `${PriceHelper.formatPrice(deltaCosts.totalAmountSummary, this.currency)}`,
        }    
        return state  
    }

    getActionCombo():webix.ui.richselectConfig {
        const actionCombo = {
            view:"richselect",
            id: "documents-combo-actions",
            inputWidth:300,
            css:{
                "margin-left":"10px !important"
            },
            placeholder: i18nHelper.getTranslation("Create_financial_documents"),
            heigth: 40,
            options: [
                {
                    value: i18nHelper.getTranslation("Create_invoice"),
                    id: "create_invoice"
                }, 
                {
                    value: i18nHelper.getTranslation("Create_credit_note"),
                    id: "create_credit_note"
                },
                {
                    value:i18nHelper.getTranslation("Create_new_quote_from_scratch"),
                    id:"new_quote_from_scratch"
                },
                {
                    value:i18nHelper.getTranslation("Create_new_invoice_from_scratch"),
                    id:"new_invoice_from_scratch"
                },
                {
                    value:i18nHelper.getTranslation("Create_new_purchase_order_from_scratch"),
                    id:"new_po_from_scratch"
                },
                {
                    value:i18nHelper.getTranslation("Create_new_credit_note_from_scratch"),
                    id:"new_credit_note_from_scratch"
                }
            ],
            on: {
                onChange: (value) => {
                    switch (value) {
                        case "create_invoice":
                            this.openMultipleDocumentsGeneratorModal(this.projectId, IntranetCostType.QUOTE);
                            break;
                        case "create_credit_note":
                            this.openMultipleDocumentsGeneratorModal(this.projectId, IntranetCostType.PURCHASE_ORDER);
                            break;
                        case "new_quote_from_scratch":
                            this.createQuoteOrInvoiceFromScratch(IntranetCostType.QUOTE);
                            break;
                        case "new_invoice_from_scratch":
                            this.createQuoteOrInvoiceFromScratch(IntranetCostType.CUSTOMER_INVOICE);
                            break;
                        case "new_po_from_scratch":
                            if(!this.providerSelectionModal) {
                                this.providerSelectionModal = this.ui(QuickPicker) as QuickPicker;
                            }
                            this.providerSelectionModal.openModal("project-members", IntranetBizObjectRole.FREELANCER, i18nHelper.getTranslation("Select provider"), this.projectId, (selectedId:number) => {
                                this.createPoOrPbFromScratch(IntranetCostType.PURCHASE_ORDER, selectedId);
                            });
                            
                            break;
                        case "new_credit_note_from_scratch":
                            if(!this.providerSelectionModal) {
                                this.providerSelectionModal = this.ui(QuickPicker) as QuickPicker;
                            }
                            this.providerSelectionModal.openModal("project-members", IntranetBizObjectRole.FREELANCER, i18nHelper.getTranslation("Select provider"), this.projectId, (selectedId:number) => {
                                this.createPoOrPbFromScratch(IntranetCostType.PROVIDER_BILL, selectedId);
                            });
                            break;
                    }
                    this.clearActionCombo();
                }
            }
        }
        return actionCombo
    }

    createQuoteOrInvoiceFromScratch(typeId:IntranetCostType) {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Financial_document_is_being_created")});
        WebixPortalTranslationService.getTransProjects({
            projectId:this.projectId
        })
        .then(project => {
            CognovisRestInvoiceService.postInvoice({
                requestBody:{
                    company_id:project[0].company.id,
                    project_id:project[0].project.id,
                    cost_type_id:typeId,
                    company_contact_id:project[0].company_contact.id
                }
            })
            .then(res => {
                CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${res.invoice.id}&project_id=${this.projectId}`);
            })
            .catch(err => {
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            })
            .finally(() => {
                this.cognovisPleaseWaitWindow.hide();
            });
        });
    }

    createPoOrPbFromScratch(typeId:IntranetCostType, selectedId:number) {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Financial_document_is_being_created")});
            CognovisRestInvoiceService.postInvoice({
                requestBody:{
                    project_id:this.projectId,
                    cost_type_id:typeId,
                    company_contact_id: this.currentProject.project_lead.user.id,
                    provider_contact_id: selectedId
                }
            })
            .then(res => {
                CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${res.invoice.id}&project_id=${this.projectId}`);
            })
            .catch(err => {
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            })
            .finally(() => {
                this.cognovisPleaseWaitWindow.hide();
            });            
    }

    getProjectFinanceDocuments(projectId:number):Promise<IInvoice[]> {
        return CognovisRestInvoiceService.getInvoice({
            projectId:projectId
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
            return []
        });
    }

    extractProjectRelatedQuotes(costs:IInvoice[]):IInvoice[] {
        return costs.filter(cost => cost.cost_type.id === IntranetCostType.QUOTE);
    }

    extractProjectRelatedInvoices(costs:IInvoice[]):IInvoice[] {
        return costs.filter(cost => cost.cost_type.id === IntranetCostType.CUSTOMER_INVOICE || cost.cost_type.id === IntranetCostType.CANCELLATION_INVOICE || cost.cost_type.id === IntranetCostType.CUSTOMER_INVOICE_CORRECTION);
    }

    extractProjectRelatedPurchaseOrders(costs:IInvoice[]):IInvoice[] {
        return costs.filter(cost => cost.cost_type.id === IntranetCostType.PURCHASE_ORDER);
    }

    extractProjectRelatedCreditNotes(costs:IInvoice[]):IInvoice[] {
        return costs.filter(cost => cost.cost_type.id === IntranetCostType.PROVIDER_BILL || cost.cost_type.id === IntranetCostType.PROVIDE_BILL_CORRECTION || cost.cost_type.id === IntranetCostType.CANCELLATION_BILL);
    }

    extractProjectRelatedTimesheetCosts(costs:IInvoice[]):IInvoice[] {
        return costs.filter(cost => cost.cost_type.id === IntranetCostType.TIMESHEET_COST);
    }

    extractProjectRelatedTimesheetBudget(costs:IInvoice[]):IInvoice[] {
        return costs.filter(cost => cost.cost_type.id === IntranetCostType.TIMESHEET_BUDGET);
    }

    extractProjectRelatedExpenses(costs:IInvoice[]):IInvoice[] {
        return costs.filter(cost => cost.cost_type.id === IntranetCostType.EXPENSE_BUNDLE);
    }

    extractProjectRelatedExpenseBudget(costs:IInvoice[]):IInvoice[] {
        return costs.filter(cost => cost.cost_type.id === IntranetCostType.EXPENSE_PLANNED_COST);
    }

    mapLinkedInvoices(costs:IInvoice[]):IInvoice[] {
        costs.map(doc_one => {
            costs.map(doc_two => {
                if(doc_one.linked_invoice && doc_one.linked_invoice.id === doc_two.invoice.id) {
                    doc_one["linked_invoice_obj"] = doc_two;
                }
            });
        });
        return costs;
    }

    getPreliminarySummaryData(
        quotes:IInvoice[], 
        purchaseOrders:IInvoice[], 
        timesheetBudget:IInvoice[], 
        expensesBudget:IInvoice[])
        :ICognovisPreliminaryCostsSummaryData {
        const quotesDataSummary = {
            totalNumber:this.countDocs(quotes, [IntranetCostStatus.ACCEPTED, IntranetCostStatus.OUTSTANDING, IntranetCostStatus.PAID, IntranetCostStatus.SEND, IntranetCostStatus.FILED]),
            totalAmount:this.sumTotals(quotes, [IntranetCostStatus.ACCEPTED, IntranetCostStatus.OUTSTANDING, IntranetCostStatus.PAID, IntranetCostStatus.SEND, IntranetCostStatus.FILED])
        }
        const purchaseOrdersSummary = {
            totalNumber:this.countDocs(purchaseOrders),
            totalAmount:this.sumTotals(purchaseOrders)
        }
        const timeTrackingSummary = {
            totalNumber:this.countDocs(timesheetBudget),
            totalAmount:this.sumTotals(timesheetBudget)
        }
        const otherBudgetSummary = {
            totalNumber:this.countDocs(expensesBudget),
            totalAmount:this.sumTotals(expensesBudget)
        }
        const totalNumberOf = quotesDataSummary.totalNumber + purchaseOrdersSummary.totalNumber + timeTrackingSummary.totalNumber + otherBudgetSummary.totalNumber;
        const totalCosts = purchaseOrdersSummary.totalAmount + timeTrackingSummary.totalAmount + otherBudgetSummary.totalAmount;
        const totalSum = quotesDataSummary.totalAmount - totalCosts;
        const data = {
            quotes:quotesDataSummary,
            purchaseOrders:purchaseOrdersSummary,
            timeTracking:timeTrackingSummary,
            otherBudget:otherBudgetSummary,
            totalNumberSummary:totalNumberOf,
            totalCosts:totalCosts,
            totalAmountSummary:totalSum
        }

        return data;
    }

    getActualCosts(
        invoices:IInvoice[], 
        creditNotes:IInvoice[],
        timesheetCosts:IInvoice[],
        expenses:IInvoice[] 
        ):ICognovisActualCostsSummaryData {
        const invoicesDataSummary = {
            totalNumber:this.countDocs(invoices),
            totalAmount:this.sumTotals(invoices)
        }
        const creditNotesSummary = {
            totalNumber:this.countDocs(creditNotes),
            totalAmount:this.sumTotals(creditNotes)
        }
        const timeTrackingSummary = {
            totalNumber:this.countDocs(timesheetCosts),
            totalAmount:this.sumTotals(timesheetCosts)
        }
        const otherCostsSummary = {
            totalNumber:this.countDocs(expenses),
            totalAmount:this.sumTotals(timesheetCosts)
        }
        const totalNumberOf = invoicesDataSummary.totalNumber + creditNotesSummary.totalNumber + timeTrackingSummary.totalNumber + otherCostsSummary.totalNumber;
        const totalCosts = creditNotesSummary.totalAmount + timeTrackingSummary.totalAmount + otherCostsSummary.totalAmount;
        const totalSum = invoicesDataSummary.totalAmount  - totalCosts;
        const data = {
            invoices:invoicesDataSummary,
            creditNotes:creditNotesSummary,
            timeTracking:timeTrackingSummary,
            otherCosts:otherCostsSummary,
            totalNumberSummary:totalNumberOf,
            totalCosts:totalCosts,
            totalAmountSummary:totalSum
        }
        return data;
    }

    getDeltaCosts(
        preliminary:ICognovisPreliminaryCostsSummaryData
        ,actual:ICognovisActualCostsSummaryData
    ):ICognovisActualCostsSummaryData {
        const invoicesDataSummary = {
            totalNumber: preliminary.quotes.totalNumber - actual.invoices.totalNumber,
            totalAmount: preliminary.quotes.totalAmount - actual.invoices.totalAmount
        }
        const creditNotesSummary = {
            totalNumber: preliminary.purchaseOrders.totalNumber - actual.creditNotes.totalNumber,
            totalAmount: preliminary.purchaseOrders.totalAmount - actual.creditNotes.totalAmount
        }
        const timeTrackingSummary = {
            totalNumber: preliminary.timeTracking.totalNumber - actual.timeTracking.totalNumber,
            totalAmount: preliminary.timeTracking.totalAmount - actual.timeTracking.totalAmount
        }
        const otherCostsSummary = {
            totalNumber: preliminary.otherBudget.totalNumber - actual.otherCosts.totalNumber,
            totalAmount: preliminary.otherBudget.totalAmount - actual.otherCosts.totalAmount
        }
        const totalNumberOf = invoicesDataSummary.totalNumber + creditNotesSummary.totalNumber + timeTrackingSummary.totalNumber + otherCostsSummary.totalNumber;
        const totalCosts = creditNotesSummary.totalAmount + timeTrackingSummary.totalAmount + otherCostsSummary.totalAmount;
        const totalSum = invoicesDataSummary.totalAmount  - totalCosts;
        const data = {
            invoices:invoicesDataSummary,
            creditNotes:creditNotesSummary,
            timeTracking:timeTrackingSummary,
            otherCosts:otherCostsSummary,
            totalNumberSummary:totalNumberOf,
            totalCosts:totalCosts,
            totalAmountSummary:totalSum
        }
        return data;
    }

    countDocs(costs:IInvoice[], statusesIds?:IntranetCostStatus[]):number {
        if(statusesIds && statusesIds.length > 0) {
            return costs.filter(cost => statusesIds.indexOf(cost.cost_status.id) > -1).filter(cost => cost.amount > -1).length;
        } else {
            const notActiveStatusesIds = [IntranetCostStatus.DELETED, IntranetCostStatus.REJECTED, IntranetCostStatus.CANCELLED, IntranetCostStatus.REPLACED];
            return costs.filter(cost => notActiveStatusesIds.indexOf(cost.cost_status.id) === -1).filter(cost => cost.amount > -1).length;
        }
    }

    sumTotals(costs:IInvoice[], statusesIds?:IntranetCostStatus[]):number {
        let totals = 0;
        if(statusesIds && statusesIds.length > 0) {
            costs.filter(cost => statusesIds.indexOf(cost.cost_status.id) > -1).map(cost => {
                if(cost.amount > -1) {
                    totals = totals + cost.amount;
                }
            });
        } else {
            const notActiveStatusesIds = [IntranetCostStatus.DELETED, IntranetCostStatus.REJECTED, IntranetCostStatus.CANCELLED, IntranetCostStatus.REPLACED];
            costs.filter(cost => notActiveStatusesIds.indexOf(cost.cost_status.id) === -1).map(cost => {
                if(cost.amount > -1) {
                    totals = totals + cost.amount;
                }
            });
        }
        return totals;
    }

    calculatePercentage(totalAmountSummary:number,totalAmount:number):number {
        if(totalAmount > 0) {
            return totalAmountSummary/totalAmount*100;
        } else {
            return 0;
        }
    }

    // We need to have array of objects which looks like:
    // const arr = [purchaseOrder, creditNote]
    createRowsFreelancerTable(costs:IInvoice[]):{purchaseOrder:IInvoice,creditNote:IInvoice}[] {
        const purchaseOrdersOnly = costs.filter(cost => cost.cost_type.id === IntranetCostType.PURCHASE_ORDER);
        const creditNotesOnly = costs.filter(cost => cost.cost_type.id === IntranetCostType.PROVIDER_BILL || cost.cost_type.id === IntranetCostType.PROVIDE_BILL_CORRECTION || cost.cost_type.id === IntranetCostType.CANCELLATION_BILL)
        const dtRows = [];
        const alreadyUsedIds = [];
        purchaseOrdersOnly.map(cost => {
            dtRows.push({
                purchaseOrder:cost,
                creditNote:cost["linked_invoice_obj"]
            });
            alreadyUsedIds.push(cost["linked_invoice_obj"]?.invoice?.id);
        });
        creditNotesOnly.map(cost => {
            // In case Credit Note was alredy displayed as a child of Purchase Order, we ommit that row
            if(alreadyUsedIds.indexOf(cost.invoice.id) === -1) {
                dtRows.push({
                    purchaseOrder:{},
                    creditNote:cost
                });
            }
        });
        return dtRows;
    }

    // We need to have array of objects which looks like:
    // const arr = [quote, invoice]
    createRowsCustomerTable(costs:IInvoice[]):{quote:IInvoice,invoice:IInvoice}[] {
        const quotesOnly = costs.filter(cost => cost.cost_type.id === IntranetCostType.QUOTE);
        const invoicesOnly = costs.filter(cost => cost.cost_type.id === IntranetCostType.CUSTOMER_INVOICE || cost.cost_type.id === IntranetCostType.CANCELLATION_INVOICE || cost.cost_type.id === IntranetCostType.CUSTOMER_INVOICE_CORRECTION);
        const dtRows = [];
        const alreadyUsedIds = [];
        quotesOnly.map(cost => {
            dtRows.push({
                quote:cost,
                invoice:cost["linked_invoice_obj"]
            });
            alreadyUsedIds.push(cost["linked_invoice_obj"]?.invoice.id);
        });
        
        invoicesOnly.map(cost => {
            // In case Credit Note was alredy displayed as a child of Purchase Order, we ommit that row
            if(alreadyUsedIds.indexOf(cost.invoice.id) === -1) {
                dtRows.push({
                    quote:{},
                    invoice:cost
                });
            }
        });
        return dtRows;
    }

    openMultipleDocumentsGeneratorModal(projectId:number, baseDocumentType:IntranetCostType):void {
        if(!this.multipleDocumentsGeneratorModal) {
            this.multipleDocumentsGeneratorModal = this.ui(MultipleDocumentsCreator) as MultipleDocumentsCreator;
        }
        this.multipleDocumentsGeneratorModal.openModal(projectId, baseDocumentType);
    }

    clearActionCombo(): void {
        setTimeout(() => {
            const actionComboImports = (webix.$$("documents-combo-actions") as webix.ui.richselect);
            actionComboImports.setValue(null);
        },500);
    }

}