import { JetView } from "webix-jet";

export default class ProjectMessage extends JetView {
  config(): webix.ui.layoutConfig {
    return {
      view: "layout",
      type: "clean",

      cols: [
        { view: "spacer", width: 20 },
        {
          view: "layout",
          type: "clean",
          responsive: true,
          rows: [
            { view: "template", type: "header", template: "Inbox", height: 40, css: "inbox__header" },
            { view: "button", value: "New message", inputWidth: 150, id: "new_message_button", css: "new__message__button", click: () => this.show("new-message") },
            {
              view: "datatable",
              select: "row",
              columns: [
                { id: "read", header: ["READ", { content: "" }], sort: "string", width: 200 },
                { id: "communication_nr", header: ["COMMUNICATION NR", { content: "" }], sort: "int", width: 200 },
                { id: "project_name", header: ["PROJECT NAME", { content: "" }], sort: "string", width: 200 },
                { id: "subject", header: ["SUBJECT", { content: "" }], sort: "string", width: 200 },
                { id: "message", header: ["MESSAGE", { content: "" }], sort: "text", width: 200 },
                { id: "sent_date", header: ["SENT DATE", { content: "" }], sort: "date", width: 200 },
              ],
            },
          ],
        },
      ],
    };
  }
}
