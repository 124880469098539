/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetAbsenceType {
    VACATION = 5000,
    SICK = 5002,
    BANK_HOLIDAY = 5005,
    OVERTIME = 5006,
}
