import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import { CognovisRestCompanyService, INamedId, IntranetCompanyType, IntranetCostType, IntranetPaymentTerm,  ICompany } from "../../../sources/openapi";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { container } from "tsyringe";
export default class CompanyCreationModal extends CognovisBasicModal {

    windowId = "companyCreationModal";
    elementsPrefix = "ccm";
    afterSubmitAction:(newCompanyId:INamedId) => void;
    autoFocusOnFieldId:string;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    companyType = IntranetCompanyType.CUSTOMER;
    existingData:ICompany;

    config():webix.ui.windowConfig {
        // Here is the small trick.
        // We need to send whitespace (" ") or any other string as modal title
        // Otherwise we won't be able later to change it
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        const mainLayout = super.getMainLayout(" ", 800,600);
        this.autoFocusOnFieldId = `${this.elementsPrefix}companyName`
        return mainLayout;
    }

    openModal(modalTitle:string, width?:number, height?:number, visibleFieldsNames?:string[], companyType?:IntranetCompanyType, afterSubmitAction?:(newCompanyId) => void,existingData?:ICompany):void {
        this.afterSubmitAction = afterSubmitAction;
        if(existingData) {
            this.existingData = existingData;
        }
        let cognovisBasicModal = this.$$(this.windowId) as webix.ui.window;
        if(cognovisBasicModal) {
            cognovisBasicModal.show();
        } else {
            cognovisBasicModal = (this.getRoot() as webix.ui.window);
            cognovisBasicModal.show(); 
        }
        if(companyType) {
            this.companyType = companyType;
        }
        const actionButtons = this.getActionButtons();
        const modalContent = this.getContent();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
        this.toggleInputs(visibleFieldsNames);
        cognovisBasicModal.config.width = width;
        cognovisBasicModal.config.height = height;
        cognovisBasicModal.resize();
        webix.delay(() => {
            const autofocusField = webix.$$(this.autoFocusOnFieldId) as webix.ui.text;
            if(autofocusField) {
                autofocusField.focus();
            }
        },{}, [], 1000);
    }

    closeModal():void {
        this.hide();
    }

    toggleInputs(inputNames?:string[]):void {
        if(inputNames.length > 0) {
            const form = webix.$$(`${this.elementsPrefix}Form`) as webix.ui.form;
            if(form["_collection"]) {
                const formFields = form["_collection"];
                // First we hide all input fields
                formFields.map(field => {
                    const element = webix.$$(field.id);
                    element.hide();
                });
                // Now we decide which ones should be visible
                formFields.map(field => {
                    if(!inputNames || inputNames.indexOf(field.name) > -1) {
                        const element = webix.$$(field.id);
                        element.show();
                    }
                });
            }
        }
    }

    getContent():webix.ui.layoutConfig {
        const modal = {
            view:"scrollview",
            maxHeight:800,
            body:{
                view:"form",
                id:`${this.elementsPrefix}Form`,
                elements:[
                    {
                        view:"combo",
                        id:`${this.elementsPrefix}companyType`,
                        name:"company_type",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Company type"),
                        placeholder:i18nHelper.getTranslation("Company type"),
                        value:this.companyType,
                        suggest: {
                            body: {
                                url: () =>
                                    CognovisCategory.getCategory(
                                        "Intranet Company Type"
                                    ),
                            },
                        },
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}CompanyName`,
                        name:"company_name",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Company name"),
                        placeholder:i18nHelper.getTranslation("Company name"),
                        on: {
                            onAfterRender:function() {
                                setTimeout(() => {
                                    this.setValue("");
                                    this.focus();
                                    this.setValue(this.$scope.existingData?.company.name);
                                },1);
                            }
                        }
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}ShortCompanyName`,
                        value:this.existingData?.company.name,
                        name:"short_company_name",
                        labelWidth:200,
                        hidden:true,
                        label:i18nHelper.getTranslation("Short company name"),
                        placeholder:i18nHelper.getTranslation("Short company name")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}Street`,
                        value:this.existingData?.address_line1,
                        name:"address_line1",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Street"),
                        placeholder:i18nHelper.getTranslation("Street")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}Zip`,
                        value:this.existingData?.address_postal_code,
                        name:"address_postal_code",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("zip"),
                        placeholder:i18nHelper.getTranslation("zip")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}City`,
                        value:this.existingData?.address_city,
                        name:"address_city",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("city"),
                        placeholder:i18nHelper.getTranslation("city")
                    },
                    {
                        view:"combo",
                        id:`${this.elementsPrefix}Country`,
                        name:"country",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("country"),
                        placeholder:i18nHelper.getTranslation("country"),
                        on: {
                            onAfterRender:() => {
                                if(this.existingData) {
                                setTimeout(() => {
                                        const me = webix.$$(`${this.elementsPrefix}Country`) as webix.ui.combo;
                                        const items = (me.getList() as webix.ui.list).data.pull;
                                        const langCode = (Object.values(items) as {id:number, value:string}[]).find(items => items.value === this.existingData.address_country_full).id;
                                        me.setValue(langCode.toString())
                                    },500);
                                }
                            }
                        },
                        suggest: {
                            body: {
                                url: () => {
                                    return CognovisRestCompanyService.getCountries()
                                    .then(counries => {
                                        const parsedCountries = counries.map(country => ({
                                            id:country.iso,
                                            value:country.country_name
                                        }));
                                        return parsedCountries;
                                    });
                                }
                            },
                        },
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}Phone`,
                        value:this.existingData?.phone,
                        name:"phone",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("phone"),
                        placeholder:i18nHelper.getTranslation("phone")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}Website`,
                        value:"",
                        name:"website",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("website"),
                        placeholder:i18nHelper.getTranslation("website")
                    },
                    // {
                    //     view:"combo",
                    //     id:`${this.elementsPrefix}Source`,
                    //     value:this.existingData?.referral_source?.id,
                    //     name:"referal_source_id",
                    //     labelWidth:200,
                    //     label:i18nHelper.getTranslation("Referral source"),
                    //     placeholder:i18nHelper.getTranslation("Referral source"),
                    //     suggest: {
                    //         body: {
                    //             url: () =>
                    //                 CognovisCategory.getCategory(
                    //                     "Referral source"
                    //                 ),
                    //         },
                    //     },
                    // },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}VatId`,
                        value:this.existingData?.vat_number,
                        name:"vat_number",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("VAT ID"),
                        placeholder:i18nHelper.getTranslation("VAT ID")
                    },
                    {
                        view:"combo",
                        id:`${this.elementsPrefix}TaxClassification`,
                        value:this.existingData?.vat_type?.id,
                        name:"vat_type_id",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Tax classification"),
                        placeholder:i18nHelper.getTranslation("Tax classification"),
                        suggest: {
                            body: {
                                url: () =>
                                    CognovisCategory.getCategory(
                                        "Intranet VAT Type"
                                    ),
                            },
                        },
                    },
                    {
                        view:"combo",
                        id:`${this.elementsPrefix}PaymentTerm`,
                        value:(this.existingData?.payment_term?.id ? this.existingData?.payment_term?.id :IntranetPaymentTerm.N14_DAYS),
                        name:"payment_term",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Payment term"),
                        placeholder:i18nHelper.getTranslation("Payment term"),
                        suggest: {
                            body: {
                                url: () =>
                                    CognovisCategory.getCategory(
                                        "Intranet Payment Term"
                                    ),
                            },
                        },
                    },
                    {
                        view:"combo",
                        id:`${this.elementsPrefix}PrimaryContact`,
                        value:(this.existingData?.primary_contact?.id ? this.existingData?.primary_contact?.id : ''),
                        name:"primary_contact",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Primary Contact"),
                        placeholder:i18nHelper.getTranslation("Primary Contact"),
                        suggest: {
                            body: {
                                url: () =>
                                    CognovisRestCompanyService.getCompanyContacts({
                                        companyId: this.existingData?.company?.id
                                    })
                                    .then((possibleContacts) => {
                                        return  possibleContacts.map(contact => ({id:contact.contact.id, value:contact.contact.name}));
                                    })
                             },
                        },
                        on: {
                            onAfterRender:function() {
                                if(this.$scope.existingData?.company != undefined) {
                                    this.show();
                                } else {
                                    this.hide();
                                }
                            }
                        }
                    },
                    {
                        view:"combo",
                        id:`${this.elementsPrefix}AccountingContact`,
                        value:(this.existingData?.accounting_contact?.id ? this.existingData?.accounting_contact?.id : ''),
                        name:"accounting_contact",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Accounting Contact"),
                        placeholder:i18nHelper.getTranslation("Accounting Contact"),
                        suggest: {
                            body: {
                                url: () =>
                                    CognovisRestCompanyService.getCompanyContacts({
                                        companyId: this.existingData?.company?.id
                                    })
                                    .then((possibleContacts) => {
                                        return  possibleContacts.map(contact => ({id:contact.contact.id, value:contact.contact.name}));
                                    })
                             },
                        },
                        on: {
                            onAfterRender:function() {
                                if(this.$scope.existingData?.company != undefined) {
                                    this.show();
                                } else {
                                    this.hide();
                                }
                            }
                        }
                    },
                    {
                        view:"combo",
                        id:`${this.elementsPrefix}DefaultQuoteTemplate`,
                        value:this.existingData?.default_quote_template?.id,
                        name:"default_quote_template",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Default quote template"),
                        placeholder:i18nHelper.getTranslation("Default quote template"),
                        suggest: {
                            body: {
                                url: () => {
                                    return CognovisCategory.getCategory(
                                        "Intranet Cost Template"
                                    )
                                    .then(templates => {
                                        const onlyQuoteTemplates = templates.filter(category => category.aux_int1 === IntranetCostType.QUOTE);
                                        return onlyQuoteTemplates;
                                    });
                                }
                            },
                        },
                    },
                    {
                        view:"combo",
                        id:`${this.elementsPrefix}DefaultInvoiceTemplate`,
                        value:this.existingData?.default_invoice_template?.id,
                        name:"default_invoice_template",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Default invoice template"),
                        placeholder:i18nHelper.getTranslation("Default invoice template"),
                        suggest: {
                            body: {
                                url: () => {
                                    return CognovisCategory.getCategory(
                                        "Intranet Cost Template"
                                    )
                                    .then(templates => {
                                        return templates.filter(category => category.aux_int1 === IntranetCostType.CUSTOMER_INVOICE);
                                    });
                                }
                            },
                        },
                    }
                ],
                rules: {
                    company_name: webix.rules.isNotEmpty,
                    short_company_name:webix.rules.isNotEmpty
                },
            }
        };
        return modal;
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Cancel`)}`, 
                            align: "left",
                            click:() => {
                                this.hide();
                            }
                        },    
                        { 
                            view: "button", 
                            id:`${this.elementsPrefix}SubmitNewCompanyButton`,
                            value: `${i18nHelper.getTranslation(`save`)}`,
                            align: "right",
                            click:() => {
                                const form = webix.$$(`${this.elementsPrefix}Form`) as webix.ui.form;
                                if (form.validate()) {
                                    this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                                    const values = form.getValues();
                                    let companyTypeId = values.company_type_id;
                                    if(!companyTypeId) {
                                        companyTypeId = this.companyType;
                                    }
                                    if(this.existingData) {
                                        CognovisRestCompanyService.putCompany({
                                            companyId:this.existingData.company.id,
                                            requestBody:{
                                                company_name:values.company_name,
                                                company_type_id:companyTypeId,
                                                company_status_id:values.company_status_id,
                                                vat_type_id:values.vat_type_id,
                                                vat_number:values.vat_number,
                                                address_country_code:values.country,
                                                address_city:values.address_city,
                                                address_postal_code:values.address_postal_code,
                                                address_line1:values.address_line1,
                                                address_line2:values.address_line2,
                                                phone:values.phone,
                                                //referral_source_id:values.referal_source_id,
                                                url:values.website,
                                                payment_term_id:values.payment_term,
                                                primary_contact_id:values.primary_contact,
                                                accounting_contact_id:values.accounting_contact,
                                                default_invoice_template_id:values.default_invoice_template,
                                                default_quote_template_id:values.default_quote_template
                                            }
                                        })
                                        .then(newCompanyObj => {
                                            this.cognovisPleaseWaitWindow.hide();
                                            this.hide();
                                            if(this.afterSubmitAction) {
                                                this.afterSubmitAction(newCompanyObj.company);
                                            }
                                        });
                                    } else {
                                        CognovisRestCompanyService.postCompany({
                                            requestBody:{
                                                company_name:values.company_name,
                                                company_type_id:companyTypeId,
                                                company_status_id:values.company_status_id,
                                                vat_type_id:values.vat_type_id,
                                                vat_number:values.vat_number,
                                                address_country_code:values.country,
                                                address_city:values.address_city,
                                                address_postal_code:values.address_postal_code,
                                                address_line1:values.address_line1,
                                                address_line2:values.address_line2,
                                                phone:values.phone,
                                                url:values.website,
                                                payment_term_id:values.payment_term,
                                                default_invoice_template_id:values.default_invoice_template,
                                                default_quote_template_id:values.default_quote_template
                                            }
                                        })
                                        .then(newCompanyObj => {
                                            this.cognovisPleaseWaitWindow.hide();
                                            this.hide();
                                            if(this.afterSubmitAction) {
                                                this.afterSubmitAction(newCompanyObj.company);
                                            }
                                        });
                                    }

                                }
                            }
                        },    
                    ] 
                };
        return buttons;
    }

}