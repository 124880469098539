import BaseView from "../baseview";
import "./server-error.style.css";

export default class ServerError extends BaseView {

    config(): webix.ui.scrollviewConfig {
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                view: "layout",
                rows: [
                    {
                        view: "template",
                        template: `
                <div class="server-error">
                    <img src="/assets/images/error-404.png" alt="" />
                    <h1>Server Unavailable</h1>
                      <a route="/main/projects.projects">Back</a>
              </div>
        `,
                    },
                ],
            },
        };
    }
    
}
