import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { JetView } from "webix-jet"
import { container } from "tsyringe";
import * as usermanager from "@xbs/usermanager";
import UsersManagerBackend from "./users-manager-backend";
import UmCustomUsersGrid from "./custom-views/users-grid";
import UmCustomSidebar from "./custom-views/users-manager-sidebar";
import UmCustomUserDetails from "./custom-views/users-manager-user-details";
import UmCustomUsersForm from "./custom-views/users-manager-profile";
import UmCustomRolesGrid from "./custom-views/roles-grid";
import UmCustomRolesForm from "./roles-form";
export default class UsersManager extends JetView {

    idPrefix = "um";
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

    config():webix.ui.layoutConfig {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return {
            view:"layout",
            localId:`${this.idPrefix}UsersManagerContainer`,
            rows:[
                this.getUsersManagerUI()
            ]
        } as unknown as webix.ui.layoutConfig
    }

    getUsersManagerUI():webix.ui.usermanagerConfig {
        const mappedComponents = new Map([
            [usermanager.services.Backend, UsersManagerBackend],
            [usermanager.views["users/grid"], UmCustomUsersGrid as usermanager.services.Backend],
            [usermanager.views["sidebar"],UmCustomSidebar as usermanager.services.Backend],
            [usermanager.views["users/audit"], {}],
            [usermanager.views["users/editor"], UmCustomUserDetails],
            [usermanager.views["users/form"], usermanager.views["users/roles"]],
            [usermanager.views["roles/grid"], UmCustomRolesGrid],
            [usermanager.views["roles/form"], UmCustomRolesForm]
        ]);
        return  {
            view:"usermanager",
            id:`${this.idPrefix}UsersManager`,
            compact: false,
            //url: "https://docs.webix.com/usermanager-backend/",
            override: mappedComponents
        }  as unknown as webix.ui.usermanagerConfig
    }

}