import EditBatches from "views/assignments/modals/edit-batches/edit-batches";
import { IJetApp, JetView } from "webix-jet";

export default class InformationBoxEditModal extends JetView {

    windowId = "";
    entityId:number;
    defaultSaveButtonText = "Save";
    defaultCancelButtonText = "Cancel";
    saveAction;

    constructor(app: IJetApp) {
        super(app,{});
    }

    config():webix.ui.windowConfig {
        return {
            view: "window",
            position: "top",
            head:" ",
            width: 900,
            minHeight:600,
            modal: true,
            css: {
                "margin-top":"40px"
            },
            //css: "import-discount-modal",
            body: ({
                rows:[
                    {
                        view: "form",
                        elements:[]
                    },
                    {
                        view:"layout",
                        cols:[
                            {
                                view:"button",
                                label:this.defaultCancelButtonText,
                                on: {
                                    onItemClick:() => {
                                        const window = this.getRoot() as webix.ui.window;
                                        window.hide();
                                    }
                                }
                            },
                            {
                                view:"button",
                                label:this.defaultSaveButtonText,
                                on: {
                                    onItemClick:() => {
                                        const form = this.getForm() as webix.ui.form;
                                        const values = form.getValues();
                                        this.saveAction(values);
                                    }
                                }
                            }
                        ]
                    }
                ]
            } as unknown) as webix.ui.baseview,
        }
    }

    getEditableFields(fields:ICognovisInformationBoxField[]):ICognovisInformationBoxField[] {
        return fields.filter(field => field.editConfig)
    }


    getForm():webix.ui.layout {
        const window = this.getRoot() as webix.ui.window;
        const body = (window.getBody() as webix.ui.layout);
        const childs = body.getChildViews();
        const form = (childs[0] as webix.ui.layout);
        return form;
    }

    setElements(editableElements:ICognovisInformationBoxField[]):void {
        const elements = this.createElements(editableElements);
        const form = this.getForm();
        form.define("elements", elements);
        form.reconstruct();
    }

    setTitle(title:string):void {
        const window = this.getRoot() as webix.ui.window;
        window.getHead().define("template", title);
    }

    createElements(editableElements:ICognovisInformationBoxField[]):ICognovisInformationBoxField[] {
        const elementsToDisplay = [];
        editableElements.forEach(element => {
            const on = {};
            if (typeof element.editConfig["onAfterRender"] === "function") {
                on["onAfterRender"] = element.editConfig["onAfterRender"];
            }
            if (typeof element.editConfig["onChange"] === "function") { 
                on["onChange"] = element.editConfig["onChange"];
            }
            if(element.editConfig["type"] === "datepicker") {
                elementsToDisplay.push({
                    id:element.id,
                    view:element.editConfig["type"],
                    format:"%d.%m.%Y",
                    label:element.label,
                    labelWidth: 150,
                    name:element.name,
                    value:element.value,
                    on: on
                });
            } else {
                const obj = {
                    id:element.id,
                    view:element.editConfig["type"],
                    suggest: {
                        body: {
                            url:element.editConfig["url"]
                        },
                        width:710,
                        fitMaster:false
                    },
                    label:element.label,
                    labelWidth: 150,
                    name:element.name,
                    value:element.value?.id,
                    on: on
                }
                elementsToDisplay.push(obj);
                if(element.editConfig["type"] === "combo") {
                    console.log(obj)
                }
            }
        });
        return elementsToDisplay;
    }   

    showModal(): void {
        const window = this.getRoot() as webix.ui.window;
        window.show();
    }


    hideModal(): void {
        const window = this.getRoot() as webix.ui.window;
        window.hide();
    }
}