/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetProjectStatus {
    /**
     * \r\n
     */
    POTENTIAL = 71,
    /**
     * \r\n
     */
    INQUIRING = 72,
    /**
     * All potential outcomes of projectes once the \"open\" phase is over\r\n
     */
    QUALIFYING = 73,
    /**
     * \r\n
     */
    QUOTING = 74,
    /**
     * \r\n
     */
    QUOTE_OUT = 75,
    OPEN = 76,
    DECLINED = 77,
    DELIVERED = 78,
    INVOICED = 79,
    CLOSED = 81,
    DELETED = 82,
    CANCELED = 83,
    QUOTE_ACCEPTED = 380,
}
