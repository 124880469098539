import BaseView from "../baseview";
import {i18nHelper} from "../../modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, IDynfieldAttr} from "../../openapi";
import DynfieldsEditModal from "views/dynfields-settings/dynfields-edit-modal";


export default class DynfieldsSettings extends BaseView {

    idPrefix = "dynfsett";
    title:string;
    DynfieldEditModal:DynfieldsEditModal;
    config():webix.ui.layoutConfig {
        this.title = i18nHelper.getTranslation("Dynfields Settings");
        return this.getCoreLayout();
    }

    init():void {
        const container = webix.$$(`${this.idPrefix}DynfieldsSettingsContainer`) as webix.ui.layout;
        container.addView(this.getDatatable());
    }

    getCoreLayout():webix.ui.scrollviewConfig {
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                padding:13,
                css:"cog-content",
                width:0,
                height:0,
                autoWidth:true,
                rows: [
                    this.getTopButtons(),
                    this.getHeader(),
                    {
                        view:"layout",
                        id:`${this.idPrefix}MainContainer`,
                        height:0,
                        gravity:1,
                        minHeight:300,
                        css:"cog-box-content",
                        rows:[
                            {
                                view:"layout",
                                id:`${this.idPrefix}DynfieldsSettingsContainer`,
                                rows:[]
                            }
                        ]
                    }
                ]
            }
        }
    }

    getHeader():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:44,
            cols: [
                {
                    view: "template",
                    type: "header",
                    css: "cog-box-header-no-border",
                    template: this.title,
                    borderless: true
                },
            ]
        }
    }

    getTopButtons():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:40,
            gravity:1,
            css:{
                "margin":"2px 0"
            },
            cols:[
                {
                    view:"spacer",
                    width:0,
                },
                {
                    view:"button",
                    width:180,
                    inputWidth:180,
                    css:"cog-button-big",
                    label:i18nHelper.getTranslation("New dynfield attribute"),
                    click:() => {
                        this.openDynfieldsDetailsModal(0)
                    }
                }
            ]
        }
    }
    getDatatable():webix.ui.datatableConfig {
        this.showLoader();
        return {
            view: "datatable",
            id: `${this.idPrefix}DynfieldsListTable`,
            resizeRow:false,
            hover:"cog-datatable-hover",
            tooltip:true,
            columns:[
                {
                    id:"dynfield_id",
                    fillspace:true,
                    sort:"text",
                    hidden:true,
                    header: [i18nHelper.getTranslation("Attribute name"),  { content:"textFilter"}],
                },
                {
                    id:"dynfield_name",
                    fillspace:true,
                    sort:"text",
                    header: [i18nHelper.getTranslation("Attribute name"),  { content:"textFilter"}],
                },
                {
                    id:"object_type",
                    fillspace:true,
                    sort:"text",
                    header: [i18nHelper.getTranslation("Object type"),  { content:"richSelectFilter"}],
                },
                {
                    width:42,
                    id:"action-edit",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Edit attribute"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-magnifying-glass'></span>`;
                    }
                },
                {
                    width:42,
                    id:"action-delete",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Delete attribute"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-trash'></span>`;
                    }
                }
            ],
            url:() => {
                return CognovisRestDynamicService.getDynfieldAttributes({})
                    .then((dynfields:IDynfieldAttr[]) => {
                        this.hideLoader();
                        return dynfields
                    });
            },
            on: {
                onItemClick:(row) => {
                    const table = webix.$$(`${this.idPrefix}DynfieldsListTable`) as webix.ui.datatable;
                    const item:IDynfieldAttr = table.getItem(row);
                    switch(row.column) {
                        case "action-edit":
                            this.openDynfieldsDetailsModal(item.dynfield_id)
                            break;
                        case "action-delete":
                            this.attemptToDeleteDynfield(item)
                            break;
                    }
                }
            }
        }
    }

    openDynfieldsDetailsModal(dynfieldId:number):void {
        if(!this.DynfieldEditModal) {
            this.DynfieldEditModal = this.ui(DynfieldsEditModal) as DynfieldsEditModal;
        }
        if(dynfieldId) {
            this.DynfieldEditModal.openModal(dynfieldId, () => {
                this.refreshTable();
            });
        } else {
            this.DynfieldEditModal.openModal(0, () => {
                this.refreshTable();
            });
        }
    }

    attemptToDeleteDynfield(dynfield:IDynfieldAttr):void {
        const declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_delete_dynfield?");
        webix.confirm({
            title: dynfield.dynfield_name,
            type: "confirm-warning",
            text: declineOrAccept,
            width: 480
        })
            .then(() => {
                this.deleteDynfield(dynfield.dynfield_id)
            });
    }

    deleteDynfield(dynfieldId:number):void {
        CognovisRestDynamicService.deleteDynfieldAttribute({
            attributeId: dynfieldId
        }).then(() => {
            this.refreshTable();
        });
    }

    refreshTable():void {
        const table = webix.$$(`${this.idPrefix}DynfieldsListTable`) as webix.ui.datatable;
        if(table) {
            this.showLoader();
            table.clearAll();
            CognovisRestDynamicService.getDynfieldAttributes({})
                .then(Dynfields => {
                    this.hideLoader();
                    table.define("data", Dynfields);
                    table.refresh()
                });
        }
    }

    showLoader():void {
        const container = webix.$$(`${this.idPrefix}DynfieldsSettingsContainer`) as webix.ui.layout & {showProgress?:(text:string) => void}
        webix.extend(container, webix.ProgressBar);
        container.showProgress(i18nHelper.getTranslation("Please_wait"));
        if(container.showProgress) {
            container.showProgress(i18nHelper.getTranslation("Please_wait"));
        }
    }

    hideLoader():void {
        const container = webix.$$(`${this.idPrefix}DynfieldsSettingsContainer`) as webix.ui.layout & {hideProgress?:() => void}
        container.hideProgress();
    }

}
