import BaseView from "../baseview";
import { IntranetProjectStatus, IntranetProjectStatusIds, WebixPortalTranslationService} from "../../openapi/index";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { ProjectsOverviewTopTabs } from "./top-tabs";
import { UserProfile } from "../../../sources/modules/cognovis-profile/profiles-types";
import { ProjectsTableBuilder } from "./projects-table-builder";
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import ContactAgainModal from "./contact-again-modal";
export default class Projects extends BaseView {

    projectOverviewTobTabs: ProjectsOverviewTopTabs;
    projectsTableBuilder:ProjectsTableBuilder;
    idPrefix = "po";
    contactAgainModal:ContactAgainModal;
    
    config(): webix.ui.scrollviewConfig {
            this.projectOverviewTobTabs = container.resolve(ProjectsOverviewTopTabs);
            this.projectsTableBuilder = container.resolve(ProjectsTableBuilder);
            let rows = [];
            if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
                rows = this.getTablesForPm();
            } 
            if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
                rows = this.getTablesForCustomer();
            }
            const projectTables = {
                view: "scrollview",
                scroll: "y",
                gravity:1,
                autowidth:true,
                body: {
                    view:"layout",
                    type:"clean",
                    css:"cog-content",
                    autoWidth: true,
                    padding:13,
                    gravity:1,
                    rows: [
                        this.projectOverviewTobTabs.getTopTabs("active-projects"),
                        {
                            view: "layout",
                            rows: rows
                        },
                    ],
                }
            } as webix.ui.scrollviewConfig;
            const userGroups = UserStateManager.getCurrentUserGroups();
            if(userGroups[0].group_id === UserProfile.FREELANCER) {
                CognovisNavigator.navigateTo("/main/assignments.my-assignments");
            } else {
                return projectTables;
            }
    }

    init():void {
        const projectStatusIds: IntranetProjectStatusIds = [];
        if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
            projectStatusIds.push(IntranetProjectStatus.INQUIRING);   
        } else {
            projectStatusIds.push(IntranetProjectStatus.POTENTIAL);
        }
        WebixPortalTranslationService.getTransProjects({
            projectStatusId: projectStatusIds,
        })
        .then((requstedProjects) => {
            const numberOfRequests = requstedProjects.length;
            this.projectOverviewTobTabs.updateRfqBadge(numberOfRequests);
            if(!this.contactAgainModal) {
                this.contactAgainModal = this.ui(ContactAgainModal) as ContactAgainModal;
            }
            webix["contactAgainModal"] = this.contactAgainModal;
        });
    }


    getTablesForPm():webix.ui.layoutConfig[] {
            // Current Projects
            const currentProjectsTableColumns = [
                "type","project_nr", "customer",  "project_name", "source_language", "target_language", "project_manager", "cost_quotes_cache", "deadline", "last_contacted", "action-contact-again"
            ];
            const currentProjectsStatusesIds = [IntranetProjectStatus.OPEN];
            const currentProjectsTable = this.projectsTableBuilder.buildProjectsTable("CurrentProjects", currentProjectsTableColumns, currentProjectsStatusesIds);
            const rows = [
                {
                    view:"layout",
                    rows:[
                        {
                            view:"template",
                            type:"header",
                            css: "cog-box-header-no-border", 
                            template:i18nHelper.getTranslation("Current_Projects"),
                        },
                        currentProjectsTable
                    ]
                },
                { view: "spacer", height: 20 },
            ];
            return rows;
    }

    getTablesForCustomer():webix.ui.layoutConfig[] {
            // Current Projects
            const currentProjectsTableColumns = [
                "type", "project_nr", "customer", "project_name", "source_language", "target_language", "company_contact", "cost_quotes_cache", "deadline",
            ];
            const currentProjectsStatusesIds = [IntranetProjectStatus.OPEN];
            const currentProjectsTable = this.projectsTableBuilder.buildProjectsTable("CurrentProjects", currentProjectsTableColumns, currentProjectsStatusesIds, true);
            const rows = [
                {
                    view:"layout",
                    rows:[
                        {
                            view:"template",
                            type:"header",
                            css: "cog-box-header-no-border", 
                            template:i18nHelper.getTranslation("Current_Projects"),
                        },
                        currentProjectsTable
                    ]
                },
            { view: "spacer", height: 20 },
        ];
        return rows;
    }

}
