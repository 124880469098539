import { JetView } from "webix-jet";
import "../../modals/error-success-message-popup/success-error-message.style.css"

export default class TaskErrorModal extends JetView {
    config(): webix.ui.popupConfig {
        return {
            view: "popup",
            width: 500,
            height: 120,
            position: "top",
            css: "popup__wrapper task_error_modal",
            body: ({
                view: "layout",
                type: "clean",
                rows: [
                    {
                        view: "template",
                        css: "error",
                        template: `
            <h4>Error!</h4>
            <p>Make sure all field is properly filled</p>`,
                    },
                ],
            } as unknown) as string,
        };
    }
}
