import { injectable } from "tsyringe";

@injectable()
export class UrlHelper {

    getUrlParamByName(paramName: string, url?: string): string {
        if (!url) {
            url = window.location.href;
        }
        const param = paramName.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
        const regexS = "[\\?&]" + param + "=([^&#]*)";
        // eslint-disable-next-line security/detect-non-literal-regexp
        const regex = new RegExp(regexS);
        const results = regex.exec(url);
        return results == null ? null : results[1];
    }

}
