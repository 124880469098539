import { JetView } from "webix-jet";

export default class ProjectFiles extends JetView {
  config(): webix.ui.layoutConfig {
    return {
      view: "layout",
      type: "clean",
      cols: [],
    };
  }
}
