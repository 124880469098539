import { ProjectsTableBuilder } from "views/project-overview/projects-table-builder";
import { IJetApp, JetView } from "webix-jet";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";


export default class ProjectSubProjects extends CognovisBasicPortlet {
    
    projectId:number;
    idPrefix = "psp";
    tableId:string;
    projectsTableBuilder:ProjectsTableBuilder;
    portletId:number;
    title:string;

    constructor(app:IJetApp, projectId:number, portletId?:number, title?:string) {
        super(app, {});
        this.projectId = projectId;
        this.portletId = portletId;
        if(this.title) {
            this.title = title;
        }
        this.tableId = `${this.idPrefix}-SubProjectsTable-${this.projectId}`;
        this.projectsTableBuilder = container.resolve(ProjectsTableBuilder);
    }

    init():void {
        super.init();
        const portlet = {
            view:"layout",
            rows:[
                {
                    view: "layout",
                    gravity:1,
                    css:"cog-box-content",
                    hidden:true,
                    localId:`${this.idPrefix}SubProjects`,
                    rows:[
                        this.getSideProjectsTable()
                    ],
                }
            ]
        };
        this.components = [portlet];
        this.insertComponents();
    }


    getSideProjectsTable():webix.ui.datatableConfig {
        const sideProjectsTable = this.projectsTableBuilder.buildProjectsTable(
            this.tableId, 
            ["project_nr", "project_name", "cost_invoices_cache", "deadline"],
            [],
            true,
            [],
            (total:number) => {
                const container = this.$$(`${this.idPrefix}SubProjects`) as webix.ui.layout;
                container.show();
            },
            [
                {"parentId":this.projectId}
            ]
        );
        return sideProjectsTable
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: i18nHelper.getTranslation("Subprojects"), 
                    borderless: true
                }
            ]
        };
        return header;
    }

}