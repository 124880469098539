import { ICompany, IntranetCompanyType, ITransProject } from "../../sources/openapi";
import { IJetApp, JetView } from "webix-jet"
import { plugins} from "webix-jet";
import { CognovisNavigator } from "../../sources/modules/cognovis-navigator/cognovis-navigator";
import { i18nHelper } from "../../sources/modules/i18n-helper/i18n-helper";
import { UserStateManager } from "../../sources/services/user-state-manager";


export default class Sidebar extends JetView {

    idPrefix = "sidebar";
    collapsed = false;
    injectedMenuItems: ILeftNavigationItem[] = [];
    allMenuItems: ILeftNavigationItem[] = [];
    menuItems: ILeftNavigationItem[] = [];
    onlyParentMenuItems:ILeftNavigationItem[] = [];
    openedProject = false;
    openedCompany = false;
    expandableMenuItems:{[key:string]:string} = {
        "project" : "project-overview.projects",
        "company" : "companies-overview.companies"
    };
    currentProject:ITransProject;
    currentCompany:ICompany;

    constructor(app:IJetApp, menuItems:ILeftNavigationItem[]) {
        super(app, {});
        this.injectedMenuItems = menuItems;
    }
    
    config(): webix.ui.sidebarConfig {
        const savedCollapsed = webix.storage.local.get("left_navigation_collapsed_state");
        if(savedCollapsed) {
            this.collapsed = savedCollapsed;
        }
        return this.getSidebar();
    }

    init():void {
        const sidebar = this.getSidebarUI();
        this.use(plugins.Menu, "menu");
        const items = this.getMenuItems()
        this.setMenuItems(items);
        if(webix["auto_open_company"]) {
            this.openCompanyDetails(webix["auto_open_company"], true);
        }
    }


    getSidebar():webix.ui.sidebarConfig {
        const sidebar = {
            localId: "menu",
            id: `${this.idPrefix}MainSidebar`,
            gravity: 1.6,
            view: "sidebar",
            collapsed:this.collapsed,
            width: 250,
            data: [],
            on: {
                onItemClick:(id:string, e:Event) => {
                    if(id.indexOf("http") > -1) {
                        e.preventDefault();
                        window.open(id, '_blank');
                        return false;
                    }
                    if(id.indexOf("freelancer_id") > -1 && this.openedCompany) {
                        return false
                    }
                }
            }
        };
        return sidebar;     
    }

    collapse():void {
        const sidebar = this.getSidebarUI();
        sidebar.toggle();
        if(this.openedProject) {
            this.openedProject = false;
            this.openProjectDetails(this.currentProject);
        }
        if(this.openedCompany) {
            this.openedCompany = false;
            this.openCompanyDetails(this.currentCompany, true);
        }
    }

    getMenuItems():ILeftNavigationItem[] {
        const navItems = [];
        this.injectedMenuItems.map(menuItem => {
                const menuObj = {
                    value: i18nHelper.getTranslation(menuItem.name),
                    original:menuItem.name,
                    menu_id: menuItem.menu_id,
                    id: menuItem.url,
                    url:menuItem.url,
                    icon: menuItem.icon,
                    label: menuItem.label,
                    parent_menu_id: menuItem.parent_menu_id,
                    data: [],
                };
                this.allMenuItems.push(menuObj);
                if (menuItem.parent_menu_id === 2001000) {
                    navItems.push(menuObj);
                    this.onlyParentMenuItems.push(menuObj);
                }
            }
        );
        this.menuItems = navItems;
        return this.menuItems
    }

    setMenuChilds(): void {
        this.menuItems.map(parentItem => {
            parentItem.data = [];
            this.allMenuItems.forEach((childItem) => {
                if (parentItem.id === this.expandableMenuItems["project"] && parentItem.menu_id === childItem.parent_menu_id) {
                    parentItem.data.push(childItem);
                }
            });
        });
    }

    setCompanyMenuChild(company:ICompany):void {
        const sidebar = this.getSidebarUI();
        this.menuItems.map(parentItem => {
            parentItem.data = [];
            this.allMenuItems.forEach((childItem) => {
                if (parentItem.id === this.expandableMenuItems["company"] && parentItem.menu_id === childItem.parent_menu_id) {
                    parentItem.data.push(childItem);
                }
            });
            if(parentItem.menu_id === 1618892) {
                if(!this.openedCompany && company.primary_contact) {
                    if(window.location.hash.indexOf("user_id") === -1) {
                        parentItem.data.push({
                            id:`users-overview.user-details`,
                            icon:"fas fa-user",
                            value:company.primary_contact.name,
                            urlParam:company.primary_contact.id,
                        });
                    }
                    if(webix["auto_open_company"]) {
                        parentItem.data.push({
                            id:`users-overview.user-details`,
                            icon:"fas fa-user",
                            value:UserStateManager.getCurrentUserData().user.name,
                            urlParam:UserStateManager.getCurrentUserData().user.id,
                        });
                    }
                }
                if(!this.openedCompany && company.primary_contact?.id && (company?.company_type?.id === IntranetCompanyType.PROVIDER || company?.company_type?.id === IntranetCompanyType.SMALL_BUSINESS) && !webix["auto_open_company"]) {
                    parentItem.data.push({
                        id:  `assignments.my-assignments?freelancer_id=${company.primary_contact.id}`,
                        icon:"fas fa-tasks",
                        value: i18nHelper.getTranslation("Assignments")
                    });
                }
            }
        });
    }

    updateUsersLabel(id:number, name:string):void {
        const sidebar = this.getSidebarUI();
        const item = sidebar.getItem("users-overview.user-details");
        item.value = name;
        item.urlParam = id
        sidebar.refresh();
    }

    rebuildMenu():void {
        const sidebar = this.getSidebarUI();
        sidebar.closeAll();
        sidebar.clearAll();
        this.setMenuItems(this.menuItems);
    }

    setMenuItems(items:ILeftNavigationItem[]):void {
        const sidebar = this.getSidebarUI();
        sidebar.define("data", items);
        sidebar.refresh();
    }

    getSidebarUI():webix.ui.sidebar {
        const sidebar: webix.ui.sidebar = webix.$$(`${this.idPrefix}MainSidebar`) as webix.ui.sidebar;
        return sidebar;
    }

    attachMenuEvents(objectId:number):void {
        const sidebar = this.getSidebarUI();
        sidebar.attachEvent("onItemClick", (id, e) => {
            if(id.indexOf("http") > -1) {
                e.preventDefault();
                window.open(id, '_blank');
                return false;
            } else {
                const item = sidebar.getItem(id);
                e.preventDefault();
                if (id === this.expandableMenuItems["project"]) {
                    this.setMenuItems(this.onlyParentMenuItems);
                    this.rebuildMenu();
                    CognovisNavigator.navigateTo("/main/project-overview.projects");
                    this.setExpandabaleMenuLabel(item.original, "project");
                    const companyMenuItem = sidebar.getItem(this.expandableMenuItems["company"]);
                    this.setExpandabaleMenuLabel(companyMenuItem.original, "company");
                    this.openedProject = false;
                    return false; 
                } else if(id === this.expandableMenuItems["company"]) {
                    this.setMenuItems(this.onlyParentMenuItems);
                    this.rebuildMenu();
                    CognovisNavigator.navigateTo(`/main/${id}`);
                    this.setExpandabaleMenuLabel(item.original, "company");
                    const projectMenuItem = sidebar.getItem(this.expandableMenuItems["project"]);
                    this.setExpandabaleMenuLabel(projectMenuItem.original, "project");
                    this.openedCompany = false;
                    return false;
                } else if(id === "users-overview.user-details") {
                    if(item["urlParam"]) {
                        this.setParam("user_id", item["urlParam"]);
                        CognovisNavigator.navigateTo(`/main/${id}?user_id=${item["urlParam"]}&company_id=${objectId}`);
                        return false;
                    }
                } else {
                    if(item.parent_menu_id !== 2001000 || item.parent_menu_id !== 1618892 ) {
                        if(window.location.hash.indexOf("project_id") > -1) {
                            CognovisNavigator.navigateTo(`/main/${id}?project_id=${objectId}`);
                        } else if(window.location.hash.indexOf("company_id") > -1) {
                            CognovisNavigator.navigateTo(`/main/${id}?company_id=${objectId}`);
                        } else {
                            CognovisNavigator.navigateTo(`/main/${id}`);
                        }
                    } else {
                        CognovisNavigator.navigateTo(`/main/${id}`);
                    }
                    setTimeout(() => {
                        sidebar.blockEvent();
                        const clickedItem = sidebar.locate(e);
                        const i = sidebar.getItem(clickedItem);
                        sidebar.select(clickedItem.toString());
                        sidebar.unblockEvent();
                    },250);
                    return false;   
                }
            }
        });
    }
    

    openCompanyDetails2(company:ICompany, openPrimaryContact = false) {
        if(!this.openedCompany) {
            this.currentCompany = company;
            const sidebar = this.getSidebarUI();
            if(this.openedCompany) {
                this.openedCompany = false;
            }
            this.setCompanyMenuChild(company);
            this.rebuildMenu();
            this.attachMenuEvents(company.company.id);
            sidebar.open(this.expandableMenuItems["company"]);
            this.setExpandabaleMenuLabel(company.company.name, "company");
            this.openedProject = true;
            if(this.app.getUrl()[1].page) {
                sidebar.blockEvent();
                sidebar.open(this.expandableMenuItems["company"]);
                const sidebarItem = sidebar.getItem(this.app.getUrl()[1].page);
                if(sidebarItem) {
                    sidebar.select(this.app.getUrl()[1].page);
                }
                sidebar.unblockEvent();
                const item = document.querySelectorAll('[webix_tm_id="companies-overview.company-details"]');
                if(item[0]) {
                    webix.html.addCss(item[0] as HTMLElement, "webix_selected")
                }   
            }
            if(window.location.href.indexOf("company_id=") === -1) {
                setTimeout(() => {
                    const currentUrl = window.location.href;
                    let newUrl = `${currentUrl}&company_id=${company.company.id}`;
                    if(currentUrl.indexOf("?") === -1) {
                        newUrl = `${currentUrl}?company_id=${company.company.id}`;
                    }                    
                    window.history.pushState("", "", newUrl);
                },100);
            }
        }
    }

    openCompanyDetails(company:ICompany, openPrimaryContact = false) {
        if(!this.openedCompany) {
            this.currentCompany = company;
            const sidebar = this.getSidebarUI();
            this.setCompanyMenuChild(company);
            this.rebuildMenu();
            this.attachMenuEvents(company.company.id);
            sidebar.open(this.expandableMenuItems["company"]);
            this.setExpandabaleMenuLabel(company.company.name, "company");
            this.openedCompany = true;
            if(this.app.getUrl()[1].page) {
                sidebar.blockEvent();
                sidebar.open(this.expandableMenuItems["company"]);
                const sidebarItem = sidebar.getItem(this.app.getUrl()[1].page);
                if(sidebarItem) {
                    sidebar.select(this.app.getUrl()[1].page);
                }
                sidebar.unblockEvent();
            }
            if(window.location.href.indexOf("company_id=") === -1) {
                setTimeout(() => {
                    const currentUrl = window.location.href;
                    let newUrl = `${currentUrl}&company_id=${company.company.id}`;
                    if(currentUrl.indexOf("?") === -1) {
                        newUrl = `${currentUrl}?company_id=${company.company.id}`;
                    }                    
                    window.history.pushState("", "", newUrl);
                },100);
            }
        }
    }


    openProjectDetails(project:ITransProject) {
        if(!this.openedProject) {
            this.currentProject = project;
            const sidebar = this.getSidebarUI();
            this.setMenuChilds();
            this.rebuildMenu();
            this.attachMenuEvents(project.project.id);
            sidebar.open(this.expandableMenuItems["project"]);
            this.setExpandabaleMenuLabel(project.project_nr, "project");
            this.openedProject = true;
            if(this.app.getUrl()[1].page) {
                sidebar.blockEvent();
                sidebar.open(this.expandableMenuItems["project"]);
                const sidebarItem = sidebar.getItem(this.app.getUrl()[1].page);
                if(sidebarItem) {
                    sidebar.select(this.app.getUrl()[1].page);
                }
                sidebar.unblockEvent();
                const item = document.querySelectorAll('[webix_tm_id="project-overview.projects"]');
                if(item[0]) {
                    webix.html.addCss(item[0] as HTMLElement, "webix_selected")
                }   
            }
        }
    }
    
    setExpandabaleMenuLabel(name:string, item:string):void {
        const sidebar = this.getSidebarUI();
        const companyMenuItem = sidebar.getItem(this.expandableMenuItems[item]);
        companyMenuItem.value = name;
        sidebar.refresh();
    }


}