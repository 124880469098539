/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean1 } from '../models/Boolean1';
import type { IBizObjectMember } from '../models/IBizObjectMember';
import type { IBizObjectMemberBody } from '../models/IBizObjectMemberBody';
import type { ICategory } from '../models/ICategory';
import type { Id } from '../models/Id';
import type { IGetApiKey } from '../models/IGetApiKey';
import type { IGroup } from '../models/IGroup';
import type { IMenuItem } from '../models/IMenuItem';
import type { ImProfileId } from '../models/ImProfileId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IntegerInt320 } from '../models/IntegerInt320';
import type { IObjectType } from '../models/IObjectType';
import type { IPrivilege } from '../models/IPrivilege';
import type { ITranslation } from '../models/ITranslation';
import type { IUser } from '../models/IUser';
import type { Pagination } from '../models/Pagination';
import type { PersonId } from '../models/PersonId';
import type { PersonIds } from '../models/PersonIds';
import type { UserId } from '../models/UserId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestService {

    /**
     * Returns the api_key
     * @returns IGetApiKey info
     * @throws ApiError
     */
    public static getApiKey({
        userId,
    }: {
        /**
         * User for whom we want to get the api_key. Defaults back to current user for non admins
         */
        userId: UserId,
    }): CancelablePromise<IGetApiKey> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_key',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * Returns an array of members for a business object (like project or company)
     * @returns IBizObjectMember Array of Members for this object
     * @throws ApiError
     */
    public static getBizObjectMembers({
        objectId,
        memberId,
    }: {
        /**
         * Object for which we look at the members
         */
        objectId?: Id,
        /**
         * Member for whom we are looking for memberships
         */
        memberId?: PersonId,
    }): CancelablePromise<Array<IBizObjectMember>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/biz_object_members',
            query: {
                'object_id': objectId,
                'member_id': memberId,
            },
        });
    }

    /**
     * Returns an array of members of a specific group
     * @returns IUser User who is a member of the object
     * @throws ApiError
     */
    public static getGroupMembers({
        groupId,
    }: {
        /**
         * group_id which we are looking for
         */
        groupId: ImProfileId,
    }): CancelablePromise<Array<IUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/group_members',
            query: {
                'group_id': groupId,
            },
        });
    }

    /**
     * Endpoint returning i18n translations for given package_keys in the locale
     * Returns translations json array of translations containing messsage_key, message
     * @returns ITranslation Array of translation objects
     * @throws ApiError
     */
    public static getI18N({
        packageKey,
        messageKey,
        locale,
        restUserId,
    }: {
        /**
         * Package key to get. Can also be comma seperated set of package_names
         */
        packageKey: string,
        /**
         * Message to get
         */
        messageKey?: string,
        /**
         * locale (e.g. \"de-DE\"), if nothing is provided, we use currently logged user locale
         */
        locale?: string,
        /**
         * user_id in case no locale is set
         */
        restUserId?: IntegerInt320,
    }): CancelablePromise<Array<ITranslation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/i18n',
            query: {
                'package_key': packageKey,
                'message_key': messageKey,
                'locale': locale,
                'rest_user_id': restUserId,
            },
        });
    }

    /**
     * PO Category
     * Handler for GET rest calls on categories
     * @returns ICategory Array of categories in the system
     * @throws ApiError
     */
    public static getImCategory({
        categoryType,
        categoryId,
        enabledP,
        parentCategoryId,
        pagination,
    }: {
        /**
         * Type of category we are returning
         */
        categoryType?: string,
        /**
         * Category id to return a single category
         */
        categoryId?: IntegerInt32,
        /**
         * Should we only return enabled categories
         */
        enabledP?: Boolean1,
        /**
         * Parent Category for which we want to retrieve the children
         */
        parentCategoryId?: IntegerInt32,
        /**
         * Limit the array to only a certain number of entries and sort
         */
        pagination?: Pagination,
    }): CancelablePromise<Array<ICategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/im_category',
            query: {
                'category_type': categoryType,
                'category_id': categoryId,
                'enabled_p': enabledP,
                'parent_category_id': parentCategoryId,
                'pagination': pagination,
            },
        });
    }

    /**
     * Return the data structure for the menu for the specific user viewing the parent.
     * @returns IMenuItem Array of menu items
     * @throws ApiError
     */
    public static getMenuItems({
        parentMenuId,
        parentMenuLabel,
        packageName,
        objectId,
    }: {
        /**
         * menu_id of the parent menu
         */
        parentMenuId?: IntegerInt32,
        /**
         * Label for which we lookup the parent_menu_id
         */
        parentMenuLabel?: string,
        /**
         * Package Name for which we lookup the parent_menu_id
         */
        packageName?: string,
        /**
         * Object_id for which we want to show the menu
         */
        objectId?: IntegerInt32,
    }): CancelablePromise<Array<IMenuItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/menu_items',
            query: {
                'parent_menu_id': parentMenuId,
                'parent_menu_label': parentMenuLabel,
                'package_name': packageName,
                'object_id': objectId,
            },
        });
    }

    /**
     * Endpoint which allows to check what kind of permissions user have for give object_id
     * @returns IPrivilege Array of privileges
     * @throws ApiError
     */
    public static getObjectPermission({
        objectId,
        permissionUserId,
    }: {
        /**
         * id of object for which we need to check permissions types
         */
        objectId: IntegerInt32,
        /**
         * user_id for which to check the permission
         */
        permissionUserId?: IntegerInt32,
    }): CancelablePromise<IPrivilege> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/object_permission',
            query: {
                'object_id': objectId,
                'permission_user_id': permissionUserId,
            },
        });
    }

    /**
     * User
     * Get information about users
     * @returns IUser Array with information of the users
     * @throws ApiError
     */
    public static getUser({
        userIds,
        profileId,
    }: {
        /**
         * IDs of the users we want to look at
         */
        userIds?: PersonIds,
        /**
         * Profile we look for
         */
        profileId?: ImProfileId,
    }): CancelablePromise<Array<IUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
            query: {
                'user_ids': userIds,
                'profile_id': profileId,
            },
        });
    }

    /**
     * Returns a JSON array of group_ids and names the user is a member off
     * @returns IGroup array of groups the user is a member of.
     * @throws ApiError
     */
    public static getUserGroups({
        userId,
    }: {
        /**
         * User for whom we want to get the groups.
         */
        userId?: UserId,
    }): CancelablePromise<Array<IGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_groups',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * Retrieve a list of valid object types that have associated dynamic attributes.
     * @returns IObjectType Array of valid object types
     * @throws ApiError
     */
    public static getValidObjectTypes(): CancelablePromise<Array<IObjectType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/valid_object_types',
        });
    }

    /**
     * Add a member to an biz object
     * @returns IBizObjectMember Array of Members for this object after the user was added
     * @throws ApiError
     */
    public static postBizObjectMember({
        requestBody,
    }: {
        /**
         * Member and object info
         */
        requestBody: IBizObjectMemberBody,
    }): CancelablePromise<Array<IBizObjectMember>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/biz_object_member',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove a member from the object
     * @returns IBizObjectMember Array of Members for this object after the user was removed
     * @throws ApiError
     */
    public static deleteBizObjectMember({
        objectId,
        memberId,
    }: {
        /**
         * object we want to remove the user from
         */
        objectId: IntegerInt32,
        /**
         * member we want to remove
         */
        memberId: UserId,
    }): CancelablePromise<Array<IBizObjectMember>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/biz_object_member',
            query: {
                'object_id': objectId,
                'member_id': memberId,
            },
        });
    }

}
