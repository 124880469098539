import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { CognovisRestCompanyService, ICategoryObject, ICompanyContact, IWebixNotification, WebixPortalAssignmentService, WebixPortalNotificationService } from "../../../sources/openapi";
import SendMailModal from "./send-mail-modal";
import CognovisFileUploaderModal from "../../../sources/modules/file-helpers/cognovis-file-uploader-modal";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import { FileHelper } from "../../../sources/modules/file-helpers/file-helpers";
import ObjectNotesModal from "./object-notes-modal";



export default class NotificationsModal extends CognovisBasicModal {

    idPrefix = "nm";
    fileUploaderModal:CognovisFileUploaderModal;
    sendMailModal:SendMailModal;
    notesModal:ObjectNotesModal;

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Notifications`), 880,520);
        return mainLayout;
    }

    openModal():void {
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig);
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
            this.loadDataIntoTable();
    }

    closeModal():void {
        this.hide();
    }

    getContent():webix.ui.layoutConfig {
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}NotificationsTableContainer`,
                        rows:[
                            {
                                view:"layout",
                                paddingY:13,
                                paddingX:10,
                                rows:[
                                    {
                                        view:"datatable",
                                        localId:`${this.idPrefix}NotificationsDatatable`,
                                        header:false,
                                        tooltip:true,
                                        columns:[
                                            {
                                                id:"notification_id",
                                                map:"#notification.id#",
                                                hidden:true
                                            },
                                            {
                                                id:"context_icon",
                                                tooltip:false,
                                                width:35,
                                                template:((obj:IWebixNotification) => {
                                                    if(obj.context["type_icon"]) {
                                                        return `<span style='color:${obj.context["status_color"]} !important;' class='cursor-pointer webix_icon ${obj.context["type_icon"]}'></span>`;
                                                    } else {
                                                        return ``;
                                                    }
                                                })
                                            },
                                            {
                                                id:"project_nr",
                                                map:"#project_nr#",
                                                tooltip:"#project.name#"
                                            },
                                            {
                                                id:"project_icon",
                                                tooltip:false,
                                                width:35,
                                                template:((obj:IWebixNotification) => {
                                                    if(obj.project) {
                                                        return `<span style='color:${obj.project["status_color"]} !important;' class='webix_icon cursor-pointer ${obj.project["type_icon"]}'></span>`;
                                                    } else {
                                                        return ``;
                                                    }
                                                })
                                            },
                                            {
                                                id:"message",
                                                fillspace:true,
                                                template:((obj:IWebixNotification) => {
                                                    return `<span style='color:${obj.notification_status.icon_or_color};' class='clickable cursor-pointer'>${obj.message}</span>`;
                                                })
                                            },
                                            {
                                                id:"actions",
                                                align:"right",
                                                width:200,
                                                css: {
                                                    "text-align":"right"
                                                },
                                                tooltip:() => {
                                                    return ""
                                                },
                                                template:((obj:IWebixNotification) => {
                                                    const actions = obj.actions;
                                                    if(actions) {
                                                        const status = obj.notification_status.id;
                                                        let color = "";
                                                        switch(status) {
                                                            case 4240:
                                                                color = "blue";
                                                                break;
                                                            case 4241:
                                                                color = "orange";
                                                                break;
                                                            case 4242:
                                                                color = "red";
                                                                break;
                                                        }
                                                        let html = "";
                                                        actions.map(action => {
                                                            let disabled = "";
                                                            let actionHelp = action.action_help;
                                                            let cursor = "cursor-pointer";
                                                            if(action.action_type.id === 14310 && action.action_text.length === 0) {
                                                                disabled = "disabled";
                                                                actionHelp = i18nHelper.getTranslation("No_comment_provided");
                                                                cursor = "";
                                                            }
                                                            html = html + `     <span webix_tooltip='${actionHelp}' data-action-type='${action.action_type.id}' class='webix_icon notification-icon ${action.action_icon} color-${color} ${cursor} ${disabled}'></span>`;
                                                        });
                                                        //html = html + `<span class='webix_icon fa fa-check color-${color}'></span>`;
                                                        return html
                                                    } else {
                                                        return ""
                                                    }
                                                })
                                            }
                                        ],
                                        on: {
                                            onItemClick:(row, event) => {
                                                const rowId = row.row;
                                                const notificationsTable = this.getDatatableView();
                                                const item = notificationsTable.getItem(row.row) as IWebixNotification;
                                                if(row.column === "context_icon") {
                                                    this.markAsRead(item.notification.id, rowId);
                                                    this.closeModal();
                                                    CognovisNavigator.goToObjectDetails(item.context.id, item.context["object_type"]);
                                                }
                                                if(row.column === "project_icon" || row.column === "project_nr") {
                                                    this.markAsRead(item.notification.id, rowId);
                                                    this.closeModal();
                                                    CognovisNavigator.goToObjectDetails(item.project.id, "im_project");
                                                }
                                                if(row.column === "actions") {
                                                    const actionType = Number(event.target.dataset.actionType);
                                                    if(item.actions) {
                                                        const actionData = item.actions.find(action => action.action_type.id === actionType)
                                                        this.executeNotificationAction(actionType, item, actionData, rowId);   
                                                    }
                                                }
                                            },
                                            onAfterLoad:function(){
                                                this.sort("notification_id", "desc");
                                            }
                                        },
                                    }
                                ]
                            }
                        ]
                    },                    
                ]
            }
        };
        return layout
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        }  
                    ] 
                };
        return buttons;
    }

    getDatatableView():webix.ui.datatable {
        const notificationsTable =  this.$$(`${this.idPrefix}NotificationsDatatable`) as webix.ui.datatable;
        return notificationsTable;
    }
    

    getNotifications():Promise<IWebixNotification[]> {
        return WebixPortalNotificationService.getWebixNotification({})
        .then(messages => {  
            return messages;
        });
    }

    loadDataIntoTable():void {
        const notificationsTable = this.getDatatableView();
        notificationsTable.clearAll();
        webix.extend(this.$$(`${this.idPrefix}NotificationsDatatable`), webix.ProgressBar);
        (notificationsTable as any).showProgress(i18nHelper.getTranslation("Please_wait"));
        this.getNotifications()
        .then(notifications => {
            notificationsTable.define("data", notifications);
            notificationsTable.refresh();
            notificationsTable.hideOverlay();
        });
    }

    executeNotificationAction(
        actionType:number, 
        notification:IWebixNotification,
        actionData:{
            action_type?: ICategoryObject,
            action_text?: string,
            action_help?: string,
            action_icon?: string,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action_object?: any,
        },
        rowId:number
    ):void {
        switch(actionType) {
            case 14300:
                this.markAsRead(notification.notification.id, rowId);
                break;
            case 14301:
                this.openFileModal(actionData.action_object.id, "write");
                this.closeModal();
                break;
            case 14302:
                this.openSendMailModal(actionData.action_object.id, actionData.action_text, actionData.action_object.id); 
                this.closeModal();
                break;
            case 14304:
                CognovisNavigator.goToObjectDetails(actionData.action_object.id, "im_company");
                this.closeModal();
                break;
            case 14305:
                CognovisNavigator.goToObjectDetails(actionData.action_object.id, "user");
                this.closeModal();
                break;
            case 14306:
                WebixPortalAssignmentService.getFreelancerAssignments({
                    assignmentId:notification.context.id
                })
                .then(assignment => {
                    this.closeModal();
                    CognovisNavigator.navigateTo(`main/assignments.freelancer-selection?project_id=${assignment[0].project.id}&auto_focus_id=${actionData.action_object.id}-${assignment[0].assignment_type.id}-0`);
                });
                break;
            case 14307:  
                this.openFileModal(actionData.action_object.id, "read");
                this.closeModal();
                break;
            case 14308:
                FileHelper.downloadCrFile(actionData.action_object.id);
                break;
            case 14309:
                this.closeModal();
                if(actionData.action_object.object_type === "im_freelance_assignment") {
                    // If action object type is im_assignment, we first make backend call to retrieve langauge
                    // and then we redirect to overview with pre-opened language tab
                    WebixPortalAssignmentService.getFreelancerAssignments({assignmentId:actionData.action_object.id})
                    .then(assignment => {
                        if(assignment[0]) {
                            CognovisNavigator.navigateTo(`main/assignments.overview?project_id=${assignment[0].project.id}&languages=${assignment[0].target_language.name}`);   
                        }
                    });
                } else {
                    // If action object type is im_project, we redirect to overview without pre-opened language tab
                    CognovisNavigator.navigateTo(`main/assignments.overview?project_id=${actionData.action_object.id}`);
                }
            break;
            case 14310:
                if(actionData.action_text.length > 0) {
                    if(!this.notesModal) {
                        this.notesModal = this.ui(ObjectNotesModal) as ObjectNotesModal;
                    }
                    this.notesModal.openNotesModal("read", i18nHelper.getTranslation("File Note"), actionData.action_object.id, actionData.action_text, 'cke5', [], () => {

                    });
                }
                break;

        }
        //this.loadDataIntoTable();
        
    }

    markAsRead(notificationId:number, rowId:number):void {
        WebixPortalNotificationService.postWebixNotificationRead({
            notificationId:notificationId
        })
        .then(() => {
            const notificationsTable = this.getDatatableView();
            notificationsTable.remove(rowId);
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    openFileModal(objectId:number, mode:string):void {
        if(!this.fileUploaderModal) {
            this.fileUploaderModal = this.ui(CognovisFileUploaderModal) as CognovisFileUploaderModal;
        }
        const filesTranslation = i18nHelper.getTranslation("Files");
        const modalTitle = `${filesTranslation}`;
        this.fileUploaderModal.openModal(objectId, mode, {allowFolderSelection:true}, modalTitle);
    }

    openSendMailModal(recipientId:number, defaultBody:string, contextId?:number):void {
        if(!this.sendMailModal) {
            this.sendMailModal = this.ui(SendMailModal) as SendMailModal;
        }
        const valueUserId = UserStateManager.getCurrentlyLoggedUser().id;
        
        CognovisRestCompanyService.getCompanyContacts({
            contactIds:[valueUserId, recipientId]
        })
        .then(senderAndRecipients => {
            let sender:ICompanyContact;
            let recipient:ICompanyContact;
            senderAndRecipients.map(sendOrRecipient => {
                if(sendOrRecipient.contact.id === valueUserId) {
                    sender = sendOrRecipient
                } else {
                    recipient = sendOrRecipient;
                }
            });
            this.sendMailModal.openModal(false,contextId, [
                {
                    name:"sender_email",
                    editable:false,
                    defaultValue:valueUserId.toString(),
                    visible:true,
                    options:[{
                        id:valueUserId,
                        value:`${sender.first_names} ${sender.last_name}`
                    }]
                },
                {
                    name:"cc_email",
                    visible:false
                },
                {
                    name:"subject",
                    visible:true,
                    editable:true,
                    autofocus:true
                },
                {
                    name:"recipient_email",
                    editable:false,
                    defaultValue:recipientId.toString(),
                    visible:true,
                    options:[{
                        id:recipientId,
                        value:`${recipient.first_names} ${recipient.last_name}`
                    }]              
                },
                {
                    name:"message_body",
                    defaultValue:defaultBody,
                    visible:true,
                    editable:true
                }
            ]);
        });
    }

}
