/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetSkillType {
    /**
     * Intranet Translation Language
     */
    SOURCE_LANGUAGE = 2000,
    /**
     * Intranet Translation Language
     */
    TARGET_LANGUAGE = 2002,
    /**
     * Intranet Translation Language
     */
    SWORN_LANGUAGE = 2004,
    /**
     * Intranet TM Tool
     */
    TM_TOOLS = 2006,
    /**
     * Intranet Translation Subject Area
     */
    SUBJECTS = 2014,
    BUSINESS_SECTOR = 2024,
    LANGUAGE_EXPERIENCE_LEVEL = 2028,
    ROLE = 10000432,
}
