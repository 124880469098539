import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { CognovisRestAbsencesService, CognovisRestService, IUserAbsenceBody } from "../../openapi";
import { WebixHelpers } from "../../modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { CognovisCategory } from "../../modules/cognovis-category/cognovis-category";
import CognovisProfile from "../../modules/cognovis-profile/cognovis-profile";
import { UserProfile } from "../../modules/cognovis-profile/profiles-types";


export default class VacationsCreationModal extends CognovisBasicModal {

    idPrefix = "acm";
    webixHelpers: WebixHelpers;
    currentUser:{id:number, name:string};
    afterSaveAction:() => void;
    allowGroupAbsences = false;
    
    init():void {
        this.webixHelpers = container.resolve(WebixHelpers);
    }

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 700, 590);
        return mainLayout;
    }

    openModal(currentUser:{id:number, name:string}, afterSaveAction?:() => void):void {
        if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.HR_MANAGER)) {
            this.allowGroupAbsences = true; 
        }
        this.currentUser = currentUser;
        if(afterSaveAction) {
            this.afterSaveAction = afterSaveAction;
        }
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const modalContent = this.getContent();
        const modalTitle = i18nHelper.getTranslation("Create_new_absence")
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
    }

    getContent():webix.ui.layoutConfig {
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}LoggedHoursModal`,
                        rows:[
                            this.getMainForm()
                        ]
                    },                    
                ]
            }
        };
        return layout
    }

    getMainForm():webix.ui.formConfig {
        const modal = {
            view:"form",
            localId:`${this.idPrefix}AbsencesCreationMainForm`,
            elements:[
                {  
                    id:`${this.idPrefix}Owner`,
                    view:"combo",
                    name:"owner_id",
                    label:i18nHelper.getTranslation("Owner_name"),
                    labelWidth:120,
                    hidden:!this.allowGroupAbsences,
                    suggest: {
                        body: {
                            url: () => {
                                return CognovisRestService.getGroupMembers({
                                    groupId:UserProfile.EMPLOYEE
                                })
                                .then(internalCompanyMembers => {
                                    const preparedForCombo = [];
                                    preparedForCombo.push({
                                        // Small trick here, as we set group_id as user_id
                                        id:UserProfile.EMPLOYEE,
                                        value:i18nHelper.getTranslation("All_employees")
                                    });
                                    const filteredOutOfNoNames = internalCompanyMembers.filter(member => member.user.name !== "");
                                    filteredOutOfNoNames.map(member => {
                                        preparedForCombo.push({
                                            id:member.user.id,
                                            value:` - ${member.user.name}`
                                        });
                                    });
                                    return preparedForCombo;
                                });
                            },
                        },
                    },
                },
                {
                    id:`${this.idPrefix}AbsenceName`,
                    view:"text",
                    name:"absence_name",
                    label:i18nHelper.getTranslation("Absence_name"),
                    labelWidth:120
                },
                {
                    id:`${this.idPrefix}AbsenceDate`,
                    view: "daterangepicker", 
                    localId:`${this.idPrefix}AbsencesDaterangePicker`,
                    name: "date_range", 
                    label: i18nHelper.getTranslation(`Date_range`),
                    labelWidth:120,
                    format:"%d.%m.%Y",
                    timepicker: true,
                    calendarTime: "%H:%i",
                },
                {
                    id:`${this.idPrefix}AbsenceType`,
                    view: "combo",
                    name: "absence_type_id",
                    label:i18nHelper.getTranslation("Absence_type"),
                    labelWidth:120,
                    suggest: {
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet Absence Type"),
                        }
                    },
                },
                {
                    id:`${this.idPrefix}AbsenceDescription`,
                    view:"ckeditor5",
                    name:"description",
                    localId:"absenceDescCkEditor5",
                    height:250,
                    mode:"document",
                    value:" "
                }
            ]
        }   
        return modal;
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        {
                            id:`${this.idPrefix}CancelVacationsButton`,
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                super.close();
                            }
                        },
                        { 
                            id:`${this.idPrefix}SaveVacationsButton`,
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`,
                            align: "right",
                            click:() => {
                                const form = (this.$$(`${this.idPrefix}AbsencesCreationMainForm`) as webix.ui.form);
                                const values = form.getValues();
                                const formattedStartDate = this.webixHelpers.formatDate(values.date_range.start);
                                const formattedEndDate = this.webixHelpers.formatDate(values.date_range.end);
                                const requestBody:IUserAbsenceBody = {
                                    absence_name:values.absence_name,
                                    description:values.description,
                                    start_date:formattedStartDate,
                                    end_date:formattedEndDate,
                                    absence_type_id:values.absence_type_id
                                };
                                if(!values.owner_id) {
                                    values.owner_id = this.currentUser.id;
                                }
                                if(Number(values.owner_id) === UserProfile.EMPLOYEE) {
                                    requestBody.group_id = values.owner_id
                                } else {
                                    requestBody.owner_id = values.owner_id
                                }
                                CognovisRestAbsencesService.postUserAbsence({
                                    requestBody:requestBody
                                })
                                .then(() => {
                                    if(this.afterSaveAction) {
                                        this.afterSaveAction();
                                    }
                                    super.hide();
                                });
                            }
                        },  
 
                    ] 
                };
        return buttons;
    }

}