/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestOpenapiService {

    /**
     * Display  OpenAPI Information of all documented API endpoints
     * @returns string Generic description
     * @throws ApiError
     */
    public static getOpenapi({
        packageKey,
        includeDefaultP,
    }: {
        /**
         * Limit the OpenAPI to only those endpoints as defined in this package
         */
        packageKey?: string,
        /**
         * 1
         */
        includeDefaultP?: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/openapi',
            query: {
                'package_key': packageKey,
                'include_default_p': includeDefaultP,
            },
        });
    }

}
