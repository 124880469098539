
import { ProjectsTableBuilder } from "views/project-overview/projects-table-builder";
import { IJetApp, JetView } from "webix-jet";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { IntranetProjectStatus } from "../../../sources/openapi";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";


export default class UserProjects extends CognovisBasicPortlet {
    
    memberId:number;
    idPrefix = "psp";
    title:string;
    defaultTitle = i18nHelper.getTranslation("User_projects");
    tableId:string;
    projectsTableBuilder:ProjectsTableBuilder;
    portletId:number;

    constructor(app:IJetApp, memberId:number, portletId?:number, title?:string) {
        super(app, {});
        this.memberId = memberId;
        this.portletId = portletId;
        this.tableId = `${this.idPrefix}-UserProjectsTable-${this.memberId}`;
        this.projectsTableBuilder = container.resolve(ProjectsTableBuilder);
        if(title) {
            this.title = title;
        } else {
            this.title = this.defaultTitle;
        }
    }

    init():void {
        super.init();
        const portlet = {
            view: "layout",
            gravity:1,
            css:"cog-box-content",
            localId:`${this.idPrefix}UserProjects`,
            rows:[
                this.getUserProjectsTable()
            ],
        };
        this.components = [portlet];
        this.insertComponents();
    }

    getUserProjectsTable():webix.ui.datatableConfig {
        const UserProjectsTable = this.projectsTableBuilder.buildProjectsTable(
            this.tableId, 
            ["project_nr", "project_name", "cost_quotes_cache", "deadline"],
            [IntranetProjectStatus.OPEN, IntranetProjectStatus.POTENTIAL],
            false,
            [],
            (total:number) => {
                const container = this.$$(`${this.idPrefix}UserProjects`) as webix.ui.layout;
                if(total > 0) {
                    container.show();
                } else {
                    //container.hide();
                }
            },
            [
                {"memberId":this.memberId}
            ]
        );
        return UserProjectsTable
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: this.title, 
                    borderless: true
                }
            ]
        };
        return header;
    }

}