import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisRestService, IntranetMaterialType, IMaterial, CognovisRestPricingService, ICompany, IPrice, IntranetPriceStatus} from "../../../sources/openapi";
import { DatetimeHelper } from "../../../sources/modules/datetime-helpers/datetime-helpers";

export default class PriceDetailsModal extends CognovisBasicModal {

    idPrefix = "pdm";
    companyData:ICompany;
    priceData:IPrice;

    afterSubmitAction:() => void;

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Price`), 740,360);
        return mainLayout;
    }

    openModal(companyData:ICompany, afterSubmitAction?:() => void, price?:IPrice):void {
        this.companyData = companyData;
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
        if(price) {
            this.priceData = price;
        }
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig);
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        if(!price) {
            this.clearFields();
        }
    }

    closeModal():void {
        this.hide();
    }

    getContent():webix.ui.layoutConfig {
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}PricesTableContainer`,
                        rows:[
                            {
                                view:"layout",
                                rows:[
                                    this.getForm()
                                ]
                            }
                        ]
                    },                    
                ]
            }
        };
        return layout
    }

    getForm():webix.ui.formConfig {
        return {
            view:"form",
            elements:[
                {
                    view:"cogWebixMaterialPicker",
                    //disabled:true,
                    name:"materialId",
                    value:{
                        id:this.priceData?.material?.material?.id,
                        value:this.priceData?.material?.material?.name,
                    },
                    existingData:(this.priceData) ? this.priceData : undefined,
                    labelWidth:200,
                    width:474,
                    inputWidth:475,
                    options:[],
                    height:40,
                    label:i18nHelper.getTranslation("Material"),
                    modalTitle:`${i18nHelper.getTranslation("Material picker")}`
                },
                {
                    view:"combo",
                    label:i18nHelper.getTranslation("Complexity"),
                    value:this.priceData?.complexity_type?.id,
                    labelWidth:200,
                    name:"complexityId",
                    suggest: {
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet Price Complexity"),
                        },
                    },
                },
                {
                    view:"text",
                    label:i18nHelper.getTranslation("Price per unit"),
                    value:this.priceData?.price,
                    labelWidth:200,
                    name:"price",
                },
                {
                    view:"text",
                    label:i18nHelper.getTranslation("Min price"),
                    value:this.priceData?.min_price,
                    labelWidth:200,
                    name:"minPrice",
                }
            ]
        }
    }

    

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`,
                            align: "right",
                            click:() => {
                                this.submitForm()
                            }
                        }  
                    ] 
                };
        return buttons;
    }

    submitForm():void {
        if(this.priceData) {
            this.updateExistingPrice();
        } else {
            this.createNewPrice();
        }
    }

    updateExistingPrice():void {
        const container = this.$$(`${this.idPrefix}PricesTableContainer`) as webix.ui.layout;
        const form = container.queryView({view:"form"});
        const values = form.getValues();
        CognovisRestPricingService.putPrice({
            priceId:this.priceData.price_id,
            requestBody:{
                company_id:this.companyData.company.id,
                price:values.price,
                material_id:values.materialId,
                min_price:values.minPrice,
                complexity_type_id:values.complexityId
            }
        })
        .then(res => {
            if(this.afterSubmitAction) {
                this.afterSubmitAction();
            }
            this.closeModal();
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    createNewPrice():void {
        const container = this.$$(`${this.idPrefix}PricesTableContainer`) as webix.ui.layout;
        const form = container.queryView({view:"form"});
        const values = form.getValues();
        CognovisRestPricingService.postPrice({
            requestBody:{
                company_id:this.companyData.company.id,
                complexity_type_id:values.complexityId,
                material_id:values.materialId,
                price:values.price,
                min_price:values.minPrice,
                price_status_id:IntranetPriceStatus.ACTIVE
            }
        })
        .then(res => {
            if(this.afterSubmitAction) {
                this.afterSubmitAction();
            }
            this.closeModal();
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    clearFields():void {
        const container = this.$$(`${this.idPrefix}PricesTableContainer`) as webix.ui.layout;
        const form = container.queryView({view:"form"}) as webix.ui.form;
        form.clear();  
    }
}
