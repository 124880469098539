/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetAbsenceStatus {
    ACTIVE = 16000,
    REQUESTED_OR_ACTIVE = 16001,
    DELETED = 16002,
    REQUESTED = 16004,
    CANCELLED = 16005,
    REJECTED = 16006,
    PLANNED = 16009,
    STORNO_REQUESTEED = 16010,
}
