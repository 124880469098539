import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import BaseView from "views/baseview";
import { CognovisRestCompanyService, CognovisRestService, IAssignment, IFreelancerAssignment, INamedId, IntranetFreelanceAssignmentStatus, ITransProject, WebixPortalAssignmentService, WebixPortalTranslationService } from "../../../sources/openapi";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { PriceHelper } from '../../../sources/modules/price-helpers/price-helpers';
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import CognovisFileUploaderModal from "../../../sources/modules/file-helpers/cognovis-file-uploader-modal";
import { FileHelper } from "../../../sources/modules/file-helpers/file-helpers";
import DeliveryRating from "./modals/delivery-rating/delivery-rating";
import { UserProfile } from "../../../sources/modules/cognovis-profile/profiles-types";
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import DeleteAssignmentModal from "./modals/delete-assignment/delete-assignment";
import { DatetimeHelper } from "../../../sources/modules/datetime-helpers/datetime-helpers";
import ObjectNotesPortlet from "views/portlets/object-notes-portlet";

export default class AssignmentDetails extends BaseView {

    assignmentId:number;
    deliveryRatingModal:DeliveryRating;
    freelancePackageId:number;
    assignmentAndProject:{assignment:IFreelancerAssignment, project:ITransProject};
    projectManagerId:number;
    currentAssignment:IFreelancerAssignment;
    currentProject:ITransProject;
    currentUserId:number;
    idPrefix = "sad";
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    fileUploaderModal:CognovisFileUploaderModal;
    pricesVisible = true;
    deleteAssignmentModal: DeleteAssignmentModal;

    init():void {
        this.fileUploaderModal = this.ui(CognovisFileUploaderModal) as CognovisFileUploaderModal;
        this.deleteAssignmentModal = this.ui(DeleteAssignmentModal) as DeleteAssignmentModal;
    }

    getDataForLayout():Promise<IFreelancerAssignment> {
        const assignmentId = Number(this.getParam("assignment_id", false));
        this.assignmentId = assignmentId;
        this.currentUserId = UserStateManager.getCurrentlyLoggedUser().id;
        return this.getAssignmentData(assignmentId)
        .then(assignment => {
            this.currentAssignment = assignment;
            // We also need to check if assignee id is not an employee
            return CognovisRestService.getUserGroups({
                userId:this.currentAssignment.assignee.id
            })
            .then(groups => {
                if(groups.find(group => group.group_id === UserProfile.EMPLOYEE)) {
                    this.pricesVisible = false;
                } 
                return assignment
            });
        });
    }
    
    config():Promise<webix.ui.scrollviewConfig> {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.cognovisPleaseWaitWindow.show({message:i18nHelper.getTranslation("Please_wait")});
        return this.getDataForLayout()
        .then(assignment => {
            this.projectManagerId = assignment.project_lead.id;
            // By default displayed user is project PM
            let displayedUser = assignment.project_lead;
            let displayedUserRoleInAssignment = i18nHelper.getTranslation("Manager");
            // But in case we view assignment as PM
            if(assignment.assignee.id !== this.currentUserId) {
                displayedUser = assignment.assignee;
                displayedUserRoleInAssignment = i18nHelper.getTranslation("Assignee");
            }
            const layout = {
                view:"scrollview",
                gravity:1,
                css: "cog-content",
                autowidth:true,
                id:`${this.idPrefix}MainContainer`,
                body:{
                    padding:13,
                    rows:[
                        {
                            view:"toolbar",
                            css: "cog-content",
                            cols:[
                                {
                                    view:"button",
                                    type:"icon",
                                    align:"left",
                                    icon: "mdi mdi-arrow-left",
                                    width: 30,
                                    css: "cog-content",
                                    click:() => {
                                        CognovisNavigator.back("/main/assignments.my-assignments");
                                    }
                                }
                            ]
                        },
                        this.getHeader(`${assignment.assignment.name} (${assignment.assignment_status.name})`),
                        {
                            view:"spacer",
                            height:20
                        },
                        {
                            view:"layout",
                            css:"assigment-details-layout",
                            gravity:1,
                            cols:[
                                this.getProjectManagerInfoPortlet(displayedUser, displayedUserRoleInAssignment),
                                {
                                    view:"spacer",
                                    width:8
                                },
                                {
                                    gravity:70,
                                    view:"layout",
                                    rows:[
                                        {
                                            view:"layout",
                                            cols:[
                                                this.getProjectInfoPortlet(assignment),
                                                { view:"spacer", width:8},
                                                this.getFeeAndTimingsPortlet(assignment)
                                            ]
                                        },
                                        { view:"spacer", height:10},
                                        {
                                            view:"layout",
                                            cols:[
                                                this.getNotesPortlet(),
                                                { view:"spacer", width:8},
                                                this.getActionButtonsPortlet()
                                            ]
                                        }
                                    ]
                                }                            
                            ]
                        }
                    ]
                }
            };
            this.cognovisPleaseWaitWindow.hide();
            return layout;
        });
    }

    getHeader(projectName:string):webix.ui.layoutConfig {
        const header = 
        {
            type:"header",
            view:"template",
            css:"cog-header",
            autowidth:true,
            gravity:1,
            template:`${projectName}`,
            borderless: true 
        };
        return header;
    }

    getProjectManagerInfoPortlet(user:INamedId, roleInAssignment:string):webix.ui.layoutConfig {
        const portlet = {
            view:"layout",
            gravity:20,
            autowidth:true,
            margin:5,
            css:"cog-box-content",
            rows:[
                {
                    view:"template",
                    css:"cog-box-header",
                    type:"header",
                    template:roleInAssignment
                },
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                {
                    view:"spacer",
                    height:20,
                    borderless:true
                },
                {
                    view:"template",
                    id:`${this.idPrefix}ProjectManagerPortrait`,
                    template:"",
                    borderless:true,
                    height:160
                },
/*                {
                    view:"cogObjectLink",
                    objectId:user.id,
                    objectName:user.name,
                    objectType:"person",
                    gravity:2.5,
                    borderless:true,
                    height:25,
                },*/
                {
                    borderless:true,
                    height:25,
                    template:`<span style='font-size:1.1em'>${user.name}</span>`
                },
                {
                    borderless:true,
                    height:25,
                    template:`<span style='font-size:1.1em'>${roleInAssignment}</span>`
                },
                {
                    view:"spacer",
                    height:6,
                    borderless:true
                },
                {
                    view:"template",
                    id:`${this.idPrefix}ProjectManagerWorkPhone`,
                    borderless:true,
                    height:25,
                    template:""
                },
                {
                    view:"template",
                    id:`${this.idPrefix}ProjectManagerEmail`,
                    borderless:true,
                    height:25,
                    template:""
                },
                {
                    view:"spacer",
                    height:20,
                    borderless:true
                },
            ]
        };
        this.updateProjectManagerPhoneAndEmail(user.id);
        return portlet;
    }

    updateProjectManagerPhoneAndEmail(projectManagerId:number):void {
        CognovisRestCompanyService.getCompanyContacts({
            contactIds:[projectManagerId]
        })
        .then(persons => {
            const phone = persons[0]?.work_phone;
            const email = persons[0]?.email;
            const portraitUrl = persons[0]?.portrait_url;
            const phoneContainer = (webix.$$(`${this.idPrefix}ProjectManagerWorkPhone`)) as webix.ui.template;
            if(phoneContainer) {
                phoneContainer.setHTML(`<span class='telephone-link'><a href='tel:${phone}'>${phone}</a></span>`);
            }   
            const emailContainer = (webix.$$(`${this.idPrefix}ProjectManagerEmail`)) as webix.ui.template;
            if(emailContainer) {
                emailContainer.setHTML(`<span class='email-link'><a href='mailto:${email}'>${email}</a></span>`);
            }
            const portraitContainer = (webix.$$(`${this.idPrefix}ProjectManagerPortrait`)) as webix.ui.template;
            if(portraitContainer) {
                if(portraitUrl !== "") {
                    portraitContainer.setHTML(`<div style='text-align:center;' class='anon-avatar-bg'><img alt="" style='margin:0 auto; border-radius:50%; border:2px solid #ffffff; width:120px; height:120px;' src='${portraitUrl}'/></div>`)                    
                } else {
                    portraitContainer.setHTML(`<div style='text-align:center;' class='anon-avatar-bg'><img alt="" style='margin:0 auto; border-radius:50%; border:2px solid #ffffff; width:120px; height:120px;' src='/assets/images/user.png'/></div>`)
                }
            }
        });
    }

    getProjectInfoPortlet(assignment:IFreelancerAssignment):webix.ui.layoutConfig {
        const portlet = {
            view:"layout",
            gravity:50,
            autowidth:true,
           css:"cog-box-content",
            height:200,
            rows:[
                {
                    view:"template",
                    type:"header",
                    css:"cog-box-header",
                    template:i18nHelper.getTranslation("Project_information")
                },
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                {
                    view:"layout",
                    rows:this.getProjectInfoFields(assignment)
                }
            ]
        };
        return portlet;
    }

    getProjectInfoFields(assignment:IFreelancerAssignment):webix.ui.textConfig[] {
        let finalCompany = "<i> - </i>";
        if(assignment?.final_company?.name) {
            finalCompany = assignment?.final_company?.name;
        }
       let skillBusinessSector = "<i> - </i>";
        if(assignment?.skill_business_sector) {
            skillBusinessSector = assignment?.skill_business_sector[0]["name"];
        } 
        let subjectArea = "";
        if(assignment.subject_area?.name) {
            subjectArea = assignment.subject_area?.name;
        }
        let projectInfoFields:webix.ui.layoutConfig[]
        if(assignment.assignee.id !== this.currentUserId) {
            projectInfoFields = [
                this.createLinkedField(i18nHelper.getTranslation("project_nr"), assignment.project.id, assignment.project_nr, "im_project"),
                this.createField(i18nHelper.getTranslation("Batch_name"), assignment.freelance_package?.name),
                this.createField(i18nHelper.getTranslation("project_type"), assignment.assignment_type?.name),
                this.createField(i18nHelper.getTranslation("source_language"), assignment.source_language?.name),
                this.createField(i18nHelper.getTranslation("target_languages"), assignment.target_language?.name),
                this.createLinkedField(i18nHelper.getTranslation("end_customer"), assignment.final_company.id, assignment.final_company.name, "im_company"),
                this.createField(i18nHelper.getTranslation("subject_area"), subjectArea),
                this.createField(i18nHelper.getTranslation("specific_area"), skillBusinessSector)
            ];      
        } else {
            projectInfoFields = [
                this.createField(i18nHelper.getTranslation("project_nr"), assignment.project_nr),
                this.createField(i18nHelper.getTranslation("Batch_name"), assignment.freelance_package?.name),
                this.createField(i18nHelper.getTranslation("project_type"), assignment.assignment_type?.name),
                this.createField(i18nHelper.getTranslation("source_language"), assignment.source_language?.name),
                this.createField(i18nHelper.getTranslation("target_languages"), assignment.target_language?.name),
                this.createField(i18nHelper.getTranslation("end_customer"),assignment.final_company?.name),
                this.createField(i18nHelper.getTranslation("subject_area"), subjectArea),
                this.createField(i18nHelper.getTranslation("specific_area"), skillBusinessSector)
            ];      
        }

        return projectInfoFields
    }

    getFeeAndTimingsPortlet(assignment:IFreelancerAssignment):webix.ui.layoutConfig {
        const portlet = {
            gravity:50,
            view:"layout",
            css:"cog-box-content",
            rows:[
                {
                    view:"template",
                    type:"header",
                    css:"cog-box-header",
                    template:i18nHelper.getTranslation("Fee_and_timings")
                },
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                {
                    view:"layout",
                    rows:this.getFeeAndTimingsFields(assignment)
                }
            ]
        };
        return portlet;
    }

    getFeeAndTimingsFields(assignment:IFreelancerAssignment):webix.ui.textConfig[] {
        // Formatting prices
        const orderVolume = `${assignment.assignment_units} ${assignment.uom.name}`;
        const formattedPrice = PriceHelper.formatAssignmentRate(assignment.rate);
        const totalFee = (assignment.rate * assignment.assignment_units);
        const totalFeeFormatted = PriceHelper.formatPrice(totalFee);
        // Formatting start_date and end_date (deadline)
        const properFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
        let startDate = "";
        if(assignment.start_date) {
            const date = new Date(DatetimeHelper.parseDateForDatepicker(assignment.start_date));
            startDate = `${properFormat(date)}`;
        }
        let endDate = "";
        if(assignment.assignment_deadline) {
            const date = new Date(DatetimeHelper.parseDateForDatepicker(assignment.assignment_deadline));
            endDate = `${properFormat(date)}`;
        }
        const feeAndTimingFields:webix.ui.layoutConfig[] = [
            this.createField(i18nHelper.getTranslation("order_volume"), orderVolume),
            this.createField(i18nHelper.getTranslation("fee_unit"), `${formattedPrice}`),
            this.createField(i18nHelper.getTranslation("total_fee"), `${totalFeeFormatted}`),
            this.createField(i18nHelper.getTranslation("start_date"), `${startDate} CE(S)T`),
            this.createField(i18nHelper.getTranslation("deadline"), `${endDate} CE(S)T`),
            {
                view:"spacer",
                height:50
            }
        ];  
        return feeAndTimingFields
    }

    createField(label:string, value:string):webix.ui.layoutConfig {
        const labelAndValuePair = {
            css:"single-assignment-infobox",
            view:"layout",
            cols:[
                {
                    view:"spacer",
                    width:20,
                },
                {
                    view:"label",
                    label:label,
                },
                {
                    view:"label",
                    gravity:2.5,
                    label:value,
                }
            ]
        };
        return labelAndValuePair
    }

    createLinkedField(label:string, objectId:number, objectName:string, objectType:string):webix.ui.layoutConfig {
        const labelAndValuePair = {
            css:"single-assignment-infobox",
            view:"layout",
            cols:[
                {
                    view:"spacer",
                    width:20,
                },
                {
                    view:"label",
                    label:label,
                },
                {
                    view:"cogObjectLink",
                    objectId:objectId,
                    objectName:objectName,
                    objectType:objectType,
                    gravity:2.5,
                    css:{
                        "margin-top":"7px !important"
                    }
                }
            ]
        };
        return labelAndValuePair
    }

    getNotesPortlet():webix.ui.layoutConfig {
        const notesPortlet = new ObjectNotesPortlet(this.app, this.assignmentId, 0, i18nHelper.getTranslation("Additional_comment"));
        const portlet = {
            gravity:60,
            view:"layout",
            css:"cog-box-content",
            rows:[
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                notesPortlet
            ]
        };
        
       
       return portlet 
    }

    getActionButtonsPortlet():webix.ui.layoutConfig {
        const originalAndReferenceFilesStatusesIds = [
            IntranetFreelanceAssignmentStatus.CREATED,
            IntranetFreelanceAssignmentStatus.ACCEPTED,
            IntranetFreelanceAssignmentStatus.REQUESTED,
            IntranetFreelanceAssignmentStatus.IN_PROGRESS
        ];
        const portlet = {
            gravity:40,
            view:"layout",
            css:"cog-box-content",
            rows:[
                {
                    view:"template",
                    css: "cog-box-header",
                    type:"header",
                    template:i18nHelper.getTranslation("Actions")
                },
                {
                    view:"spacer",
                    height:3,
                    borderless:true
                },
                {
                    view:"layout",
                    padding:20,
                    margin:20,
                    rows:[
                        {
                            view:"button",
                            value:i18nHelper.getTranslation("Browse_project_files"),
                            id:`${this.idPrefix}ActionsDownloadOriginalFiles`,
                            height:30,
                            hidden:originalAndReferenceFilesStatusesIds.indexOf(this.currentAssignment.assignment_status.id) === -1,
                            css:"assignment-actions-normal",
                            click:() => {
                                const projectId = this.currentAssignment.project.id;
                                const modalTitle = `${this.currentAssignment.project_nr}`;
                                this.fileUploaderModal.openModal(projectId, "read", {allowFolderSelection:true, useEntityId:true, defaultFolderName:"Original", entityFolderId:this.currentAssignment.project_folder.id}, modalTitle);
                            }
                        },
                        {
                            view:"button",
                            id:`${this.idPrefix}ActionsDownloadBatchFiles`,
                            value:i18nHelper.getTranslation("Download_batch_files"),
                            height:30,
                            css:"assignment-actions-normal",
                            click:() => {
                                const freelancePackageId = this.currentAssignment.freelance_package.id;
                                const modalTitle = `${this.currentAssignment.project_nr}`;
                                if(this.currentAssignment.assignment_status.id === IntranetFreelanceAssignmentStatus.ACCEPTED) {
                                    WebixPortalAssignmentService.putTranslationAssignments({
                                        assignmentId: this.currentAssignment.assignment.id,
                                        requestBody: {
                                            assignment_status_id: IntranetFreelanceAssignmentStatus.IN_PROGRESS
                                        }
                                    })
                                }
                                this.fileUploaderModal.openModal(freelancePackageId, "read", {allowFolderSelection:false, useEntityId:true, entityFolderId:0}, modalTitle);
                            },
                            on: {
                                onAfterRender:function() {
                                    const statusesIds = [
                                        IntranetFreelanceAssignmentStatus.ACCEPTED,
                                        IntranetFreelanceAssignmentStatus.IN_PROGRESS,
                                        IntranetFreelanceAssignmentStatus.WORK_DELIVERED
                                    ]
                                    if(statusesIds.indexOf(this.$scope.currentAssignment.assignment_status.id) > -1 && this.$scope.currentAssignment.package_files?.length > 0) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }
                                }
                            }
                        },
                        {
                            view:"button",
                            id:`${this.idPrefix}ActionsUploadReturnFiles`,
                            value:i18nHelper.getTranslation("Upload_return_files"),
                            height:30,
                            css:"assignment-actions-normal",
                            click:() => {
                                const assignmentId = this.currentAssignment.assignment.id;
                                const modalTitle = `${this.currentAssignment.project_nr}`;
                                const flUploadSuccessMessage =  i18nHelper.getTranslation("lt_Thank_you_Your_files_");
                                this.fileUploaderModal.openModal(assignmentId, "write", {allowFolderSelection:false, uploadSuccessMessage:flUploadSuccessMessage}, modalTitle);
                            },
                            on: {
                                onAfterRender:function() {
                                    const statusesIds = [
                                        IntranetFreelanceAssignmentStatus.ACCEPTED,
                                        IntranetFreelanceAssignmentStatus.IN_PROGRESS
                                    ]
                                    if(statusesIds.indexOf(this.$scope.currentAssignment.assignment_status.id) > -1) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }
                                }
                            }
                        },
                        {
                            view:"button",
                            id:`${this.idPrefix}ActionsAcceptProject`,
                            value:i18nHelper.getTranslation("Accept_project"),
                            height:50,
                            css:"assignment-actions-accept",
                            click:() => {
                                this.acceptAssignment(this.assignmentId)
                            },
                            on: {
                                onAfterRender:function() {
                                    const statusesIds = [
                                        IntranetFreelanceAssignmentStatus.REQUESTED
                                    ]
                                    if(statusesIds.indexOf(this.$scope.currentAssignment.assignment_status.id) > -1) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }
                                }
                            }
                        },
                        {
                            view:"button",
                            id:`${this.idPrefix}ActionsRejectProject`,
                            value:i18nHelper.getTranslation("Reject_project"),
                            height:40,
                            css:"assignment-actions-reject",
                            click:() => {
                                this.rejectAssignment(this.assignmentId)
                            },
                            on: {
                                onAfterRender:function() {
                                    const statusesIds = [
                                        IntranetFreelanceAssignmentStatus.REQUESTED
                                    ]
                                    if(statusesIds.indexOf(this.$scope.currentAssignment.assignment_status.id) > -1) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }
                                }
                            }
                        },
                        {
                            view:"button",
                            id:`${this.idPrefix}ActionsDeleteAssignment`,
                            value:i18nHelper.getTranslation("Delete_assignment"),
                            height:40,
                            css:"assignment-actions-reject",
                            click:() => {
                                this.deleteAssignmentModal.openModal(this.currentAssignment);
                            },
                            on: {
                                onAfterRender:function() {
                                    if(originalAndReferenceFilesStatusesIds.indexOf(this.$scope.currentAssignment.assignment_status.id) > -1 
                                        && this.$scope.currentAssignment.project_lead.id ==  UserStateManager.getCurrentlyLoggedUser().id
                                        && !this.$scope.currentAssignment.provider_bill?.id) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }
                                }
                            }
                        },
                        {
                            view:"button",
                            id:`${this.idPrefix}ActionsSetToInProgress`,
                            value:i18nHelper.getTranslation("Set_to_in_progress"),
                            height:50,
                            css:"assignment-actions-accept",
                            click:() => {
                                this.setToInProgress(this.assignmentId);
                            },
                            on: {
                                onAfterRender:function() {
                                    const statusesIds = [
                                        IntranetFreelanceAssignmentStatus.ACCEPTED
                                    ]
                                    if(statusesIds.indexOf(this.$scope.currentAssignment.assignment_status.id) > -1) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }
                                }
                            }
                        },
                        {
                            view:"button",
                            id:`${this.idPrefix}ActionsSetToWorkDelivered`,
                            value:i18nHelper.getTranslation("Set_to_work_finished"),
                            height:50,
                            css:"assignment-actions-accept",
                            click:() => {
                                this.setToWorkDelivered(this.currentAssignment);
                            },
                            on: {
                                onAfterRender:function() {
                                    const statusesIds = [
                                        IntranetFreelanceAssignmentStatus.IN_PROGRESS
                                    ]
                                    if(statusesIds.indexOf(this.$scope.currentAssignment.assignment_status.id) > -1) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }
                                }
                            }
                        },
                        {
                            view:"button",
                            id:`${this.idPrefix}ActionsDownloadPurchaseOrder`,
                            value:i18nHelper.getTranslation("Download_purchase_order"),
                            height:30,
                            hidden:!this.pricesVisible,
                            css:"assignment-actions-normal",
                            click:() => {
                                if(this.currentAssignment.purchase_order?.id) {
                                    FileHelper.downloadFinanceDocument(this.currentAssignment.purchase_order?.id);
                                }
                            },
                            on: {
                                onAfterRender:function() {
                                    if(
                                        this.$scope.currentAssignment.purchase_order?.id
                                    ) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }
                                }
                            }
                        },
                        {
                            view:"button",
                            id:`${this.idPrefix}ActionsDownloadCreditNote`,
                            value:i18nHelper.getTranslation("Download_credit_note"),
                            height:30,
                            hidden:!this.pricesVisible,
                            css:"assignment-actions-normal",
                            click:() => {
                                if(this.currentAssignment.provider_bill?.id) {
                                    FileHelper.downloadFinanceDocument(this.currentAssignment.provider_bill?.id);
                                }
                            },
                            on: {
                                onAfterRender:function() {
                                    const statusesIds = [
                                        IntranetFreelanceAssignmentStatus.DELIVERY_ACCEPTED,
                                        IntranetFreelanceAssignmentStatus.ASSIGNMENT_CLOSED
                                    ]
                                    if(
                                        statusesIds.indexOf(this.$scope.currentAssignment.assignment_status.id) > -1
                                        &&
                                        this.$scope.currentAssignment.provider_bill?.id
                                    ) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }
                                }
                            }
                        },
                    ]
                }
            ]
        };
        return portlet;
    }

    getAssignmentData(assignmentId:number):Promise<IFreelancerAssignment> {
        return WebixPortalAssignmentService.getFreelancerAssignments({
            assignmentId:assignmentId
        })
        .then(assignments => {
            const ourNeededAssignment = assignments.find(assignment => assignment.assignment.id === assignmentId);
            this.freelancePackageId = ourNeededAssignment.freelance_package.id;
            return ourNeededAssignment;
        });
    }

    getProjectData(projectId:number):Promise<ITransProject> {
        return WebixPortalTranslationService.getTransProjects({
            projectId:projectId
        })
        .then(projectBasicInfo => {     
            return projectBasicInfo[0];
        });
    }

    acceptAssignment(assignmentId:number):void {
        this.cognovisPleaseWaitWindow.show({message:i18nHelper.getTranslation("Please_wait")});
        WebixPortalAssignmentService.putTranslationAssignments({
            assignmentId:assignmentId,
            requestBody: {
                assignment_status_id:IntranetFreelanceAssignmentStatus.ACCEPTED
            }
        })
        .then(() => {
            this.cognovisPleaseWaitWindow.hide();
            CognovisNavigator.navigateTo(`main/assignments.my-assignments`);
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
            window.location.reload();
        });
    }

    rejectAssignment(assignmentId:number):void {
        this.cognovisPleaseWaitWindow.show({message:i18nHelper.getTranslation("Please_wait")});
        WebixPortalAssignmentService.putTranslationAssignments({
            assignmentId:assignmentId,
            requestBody: {
                assignment_status_id:IntranetFreelanceAssignmentStatus.DENIED
            }
        })
        .then(() => {
            const titleTranslate = i18nHelper.getTranslation("Assignment_denied");
            const textTranslated = i18nHelper.getTranslation("You_have_denied_that_assignment");
            webix.alert({
                width:320,
                title:titleTranslate,
                ok:"Ok",
                text:textTranslated
            })
            .then(() => {
                this.cognovisPleaseWaitWindow.hide();
                CognovisNavigator.navigateTo(`main/assignments.my-assignments`);
            })
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    setToInProgress(assignmentId:number):void {
        this.cognovisPleaseWaitWindow.show({message:i18nHelper.getTranslation("Please_wait")});
        WebixPortalAssignmentService.putTranslationAssignments({
            assignmentId:assignmentId,
            requestBody: {
                assignment_status_id:IntranetFreelanceAssignmentStatus.IN_PROGRESS
            }
        })
        .then(() => {
            this.cognovisPleaseWaitWindow.hide();
            window.location.reload();
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    setToWorkDelivered(assignment:IAssignment):void {
        this.cognovisPleaseWaitWindow.show({message:i18nHelper.getTranslation("Please_wait")});
        if(!this.deliveryRatingModal) {
            this.deliveryRatingModal = this.ui(DeliveryRating) as DeliveryRating;
        }
        this.deliveryRatingModal.openModal(assignment, () => {
            WebixPortalAssignmentService.putTranslationAssignments({
                assignmentId:assignment.assignment.id,
                requestBody: {
                    assignment_status_id:IntranetFreelanceAssignmentStatus.WORK_DELIVERED
                }
            })
            .then(() => {
                this.cognovisPleaseWaitWindow.hide();
                window.location.reload();
            })
            .catch(err => {
                console.log(err)
                this.cognovisPleaseWaitWindow.hide();
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            });
        });
    }

}
