import { CognovisPleaseWaitWindow } from "../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import CognovisBasicModal from "./cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "./i18n-helper/i18n-helper";
import { CognovisCategory } from "./cognovis-category/cognovis-category";
import { CognovisRestCompanyService, WebixPortalProjectService } from "../../sources/openapi";



export default class QuickPicker extends CognovisBasicModal {

    idPrefix = "qp";
    objectType:string;
    type:number | string | number[];
    objectId:number;
    modelTitle:string;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle:string;
    firstFieldFocus = "item_name";
    afterSubmitAction:(selectedId:number) => void;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ",600,180);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(objectType:string, type:number | string | number[], title:string, objectId?:number, afterSubmitAction?:(selectedId:number) => void):void {
        this.objectType = objectType;
        this.type = type;
        if(objectId) {
            this.objectId = objectId;
        }
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
        this.modalTitle = title;
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTitle);
        this.cognovisPleaseWaitWindow.hide();          
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
    }

    getContent():webix.ui.layoutConfig {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}QuickPickerContainer`,
                        rows:[
                            {
                                view:"form",
                                localId:`${this.idPrefix}QuickPickerForm`,
                                elements:[
                                    {
                                        view:"combo",
                                        labelWidth:160,
                                        label:this.modalTitle,
                                        name:"selected_id",
                                        suggest:this.getProperSuggestData(this.objectType, this.type as number)

                                    }
                                ]
                            }
                        ]
                    }                   
                ]
            }
        };
        return layout
    }

    getProperSuggestData(objectType:string, objectId:string | number):{} {
        let suggest;
        switch(objectType) {
            case "im_category":
               suggest = {
                    body: {
                        url: () => CognovisCategory.getCategory(objectId as string),
                    }
                };
            break;
            case "im_company":
                suggest = {
                    body: {
                        url: () => {
                            return CognovisRestCompanyService.getCompanies({
                                companyTypeIds:this.type as number[]
                            })
                            .then(finalCompanies => {
                                const companies = finalCompanies.map(company => ({id:company.company.id, value:company.company.name}))
                                return companies;
                            });
                        }
                    }
                };
            break;
            case "person":
                // tba
                break;
            case "project-members":
                suggest = {
                    body: {
                        url: () => {
                            return WebixPortalProjectService.getProjectMembers({
                                projectId:this.objectId
                            })
                            .then(projectMembers => {
                                const onlyFreelancers = projectMembers.filter(member => member.role.id === this.type);
                                return onlyFreelancers.map(projectMember => ({id:projectMember.member.user.id, value:projectMember.member.user.name, companyId:projectMember.member.company.id}))
                            });
                        }
                    }
                };
        }
        return suggest
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            click:() => {
                                this.hide();
                            }
                        },  
                        {
                            view:"button",
                            localId:`${this.idPrefix}SaveButton`,
                            value:`${i18nHelper.getTranslation(`Save`)}`,
                            click:() => {
                                this.submit();
                            }
                        }
                    ] 
                };
        return buttons;
    }

    getFormUI():webix.ui.form {
        const form = this.$$(`${this.idPrefix}QuickPickerForm`) as webix.ui.form;
        return form;
    }

    submit():void {
        const form = this.getFormUI();
        const values = form.getValues();
        const selectedId = values.selected_id;
        this.afterSubmitAction(selectedId);
    }


}