import BaseView from "../baseview";
import { CognovisRestService, IntranetProjectStatus, IntranetProjectStatusIds, WebixPortalTranslationService } from "../../openapi/index";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { ProjectsOverviewTopTabs } from "./top-tabs";
import { UserProfile } from "../../../sources/modules/cognovis-profile/profiles-types";
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import { ProjectsTableBuilder } from "./projects-table-builder";

export default class ClosedProjects extends BaseView {

    projectOverviewTobTabs: ProjectsOverviewTopTabs;
    projectsTableBuilder:ProjectsTableBuilder;
    idPrefix = "po";
    
    config():webix.ui.scrollviewConfig {
        this.projectOverviewTobTabs = container.resolve(ProjectsOverviewTopTabs);
        this.projectsTableBuilder = container.resolve(ProjectsTableBuilder);
        const closedProjectStatusesIds: IntranetProjectStatusIds = [
            IntranetProjectStatus.CLOSED, IntranetProjectStatus.DECLINED, IntranetProjectStatus.DELETED,
            IntranetProjectStatus.CANCELED, IntranetProjectStatus.DELIVERED, IntranetProjectStatus.INVOICED
        ];
        const closedProjectsTableColumns = [
            "type", "project_nr", "project_name", "source_language", "target_language", "company_contact", "cost_invoices_cache", "end_date", "action-financial-overview"
        ]
        const closedProjectsTable =  this.projectsTableBuilder.buildProjectsTable("PotentialProjects", closedProjectsTableColumns, closedProjectStatusesIds, true);
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                padding:13,
                css:"cog-content",
                rows: [
                    this.projectOverviewTobTabs.getTopTabs("closed-projects"),
                    {
                        view: "layout",
                        rows:[
                            {
                                view:"template",
                                type:"header",
                                css: "cog-box-header-no-border", 
                                template:i18nHelper.getTranslation("Closed_Projects"),
                            },
                            closedProjectsTable
                        ]
                    },
                ],
            },
        };
    }

    init():void {
        CognovisRestService.getUserGroups({})
		.then(userGroups => {
            const firstFoundedGroup = userGroups[0];
            if(firstFoundedGroup) {
                if(firstFoundedGroup.group_id === UserProfile.FREELANCER) {
                    CognovisNavigator.navigateTo("/main/assignments.my-assignments");
                }
            }
        });
        const projectStatusIds: IntranetProjectStatusIds = [];
        if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
            projectStatusIds.push(IntranetProjectStatus.INQUIRING);   
        } else {
            projectStatusIds.push(IntranetProjectStatus.POTENTIAL);
        }
        WebixPortalTranslationService.getTransProjects({
            projectStatusId: projectStatusIds,
        })
        .then((requstedProjects) => {
            const numberOfRequests = requstedProjects.length;
            this.projectOverviewTobTabs.updateRfqBadge(numberOfRequests);
        });
        
    }

}
