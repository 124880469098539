import { IJetApp } from "webix-jet";
import { config } from "../../config";
export class CognovisNavigator {
    
    static app:IJetApp;
    static visitedPages:string[] = [];
    static maximumLastVisitedPagesSize = 5;

    static setApp(app:IJetApp):void {
        this.app = app;
    }

    static getApp():IJetApp {
        return this.app
    }

    static navigateTo(route:string):void {
        const mainSidebar = webix.$$(`sidebarMainSidebar`) as webix.ui.sidebar;
        // Figure out current route
        const currentRoute = mainSidebar.$scope.app["_subSegment"]["route"]["path"];
        this.updateLastVisitedPages(currentRoute);
        mainSidebar.$scope.app.show(route);
    }

    static back(defaultRedirectUrl?:string):void {
        if(this.visitedPages[0]) {
            this.navigateTo(this.visitedPages[0]);
        } else if(defaultRedirectUrl){ 
            this.navigateTo(defaultRedirectUrl);
        } else {
            history.back();
        }
    }

    static updateLastVisitedPages(route:string):void {
        this.visitedPages.splice(0, 0, route);
        if(this.visitedPages.length > 5) {
            this.visitedPages.pop();
        }
    }

    static openInNewTab(objectId:number, objectType:string) {
        const path = config.paths.find(path => path.objectType === objectType);
        if(path) {
            const pathWithReplacedParam = path.url.replace("=:objectId", `=${objectId.toString()}`);
            console.log(`${config.serverUrl}${pathWithReplacedParam}`)
            window.open(`/#!/${pathWithReplacedParam}`, '_blank').focus();
        }
    }

    static goToObjectDetails(objectId:number, objectType:string, linkedObjectId?:number):void {
        // Figure out url (which are configured in config.ts)
        const path = config.paths.find(path => path.objectType === objectType);
        if(path) {
            const pathWithReplacedParam = path.url.replace("=:objectId", `=${objectId.toString()}`);
            if(path.external) {
                // eslint-disable-next-line security/detect-non-literal-fs-filename
                window.open(`${config.serverUrl}${pathWithReplacedParam}`, '_blank').focus();
            } else {
                if(objectType === "im_project") {
                    // We now need to check if other project is not currently opened
                    const currentUrl = window.location.href;
                    if(currentUrl.indexOf("?project_id") > -1) {
                        const currentUrlSplitted = currentUrl.split("project_id=");
                        const newProjectUrl = `${currentUrlSplitted[0]}project_id=${objectId}`;
                        window.location.href = newProjectUrl
                        window.location.reload();
                    } else {
                        this.navigateTo(pathWithReplacedParam);
                    }
                } else if(objectType === "user") {
                    const currentUrl = window.location.href;
                    if(currentUrl.indexOf("?user_id") > -1) {
                        const currentUrlSplitted = currentUrl.split("user_id=");
                        const newUserUrl = `${currentUrlSplitted[0]}user_id=${objectId}`;
                        window.location.href = newUserUrl
                        window.location.reload();
                    } else {
                        if(linkedObjectId) {
                            this.navigateTo(`/main/users-overview.user-details?user_id=${objectId}&company_id=${linkedObjectId}`);
                        } else {
                            this.navigateTo(`/main/users-overview.user-details?user_id=${objectId}`);
                        }
                    }
                } else if(objectType === "im_invoice" || objectType === "im_trans_invoice") {
                    // We now need to check if other project is not currently opened
                    const currentUrl = window.location.href;
                    if(currentUrl.indexOf("?invoice_id") > -1) {
                        const currentUrlSplitted = currentUrl.split("invoice_id=");
                        const newInvoiceUrl = `${currentUrlSplitted[0]}invoice_id=${objectId}&project_id=${linkedObjectId}`;
                        window.location.href = newInvoiceUrl
                        window.location.reload();
                    } else {
                        this.navigateTo(`main/invoice-detail.invoice-detail?project_id=${linkedObjectId}&invoice_id=${objectId}`);
                    }
                } else if(objectType === "im_company") {
                    const currentUrl = window.location.href;
                    if(currentUrl.indexOf("company?company_id") > -1) {
                        const currentUrlSplitted = currentUrl.split("company_id=");
                        const newCompanyUrl = `${currentUrlSplitted[0]}company_id=${objectId}`;
                        window.location.href = newCompanyUrl;
                        window.location.reload();
                    } else {
                        this.navigateTo(pathWithReplacedParam);
                    }
                } else {
                    this.navigateTo(pathWithReplacedParam);
                }
            }
        }
    }

    static getBaseUrl():string {
        const getUrl = window.location;
        const baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        return  baseUrl;
    }

}