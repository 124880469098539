/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean0 } from '../models/Boolean0';
import type { IAssignment } from '../models/IAssignment';
import type { IAssignmentBody } from '../models/IAssignmentBody';
import type { IAssignmentQualityReport } from '../models/IAssignmentQualityReport';
import type { IAssignmentQualityReportBody } from '../models/IAssignmentQualityReportBody';
import type { IAssignmentRatingBody } from '../models/IAssignmentRatingBody';
import type { ICategory } from '../models/ICategory';
import type { Id } from '../models/Id';
import type { IError } from '../models/IError';
import type { IFreelancePackage } from '../models/IFreelancePackage';
import type { IFreelancePackageBody } from '../models/IFreelancePackageBody';
import type { IFreelancePackageBodyPut } from '../models/IFreelancePackageBodyPut';
import type { IFreelancer } from '../models/IFreelancer';
import type { IFreelancerAssignment } from '../models/IFreelancerAssignment';
import type { IFreelancerFee } from '../models/IFreelancerFee';
import type { IFreelancerTaskUnits } from '../models/IFreelancerTaskUnits';
import type { IGetAssignmentQualityRatingTypes } from '../models/IGetAssignmentQualityRatingTypes';
import type { ImAssignmentQualityReportId } from '../models/ImAssignmentQualityReportId';
import type { ImFreelanceAssignmentId } from '../models/ImFreelanceAssignmentId';
import type { ImFreelanceAssignmentIds } from '../models/ImFreelanceAssignmentIds';
import type { ImFreelancePackageId } from '../models/ImFreelancePackageId';
import type { ImFreelancePackageIds } from '../models/ImFreelancePackageIds';
import type { ImProjectId } from '../models/ImProjectId';
import type { ImTransTaskId } from '../models/ImTransTaskId';
import type { IntranetFreelanceAssignmentStatus } from '../models/IntranetFreelanceAssignmentStatus';
import type { IntranetFreelanceAssignmentStatusIds } from '../models/IntranetFreelanceAssignmentStatusIds';
import type { IntranetProjectType } from '../models/IntranetProjectType';
import type { IntranetSkillBusinessSectorIds } from '../models/IntranetSkillBusinessSectorIds';
import type { IntranetSkillRoleIds } from '../models/IntranetSkillRoleIds';
import type { IntranetTmToolIds } from '../models/IntranetTmToolIds';
import type { IntranetTranslationLanguage } from '../models/IntranetTranslationLanguage';
import type { IntranetTranslationLanguageIds } from '../models/IntranetTranslationLanguageIds';
import type { IntranetTranslationQualityType } from '../models/IntranetTranslationQualityType';
import type { IntranetTranslationSubjectArea } from '../models/IntranetTranslationSubjectArea';
import type { IntranetTranslationSubjectAreaIds } from '../models/IntranetTranslationSubjectAreaIds';
import type { IntranetTransTaskType } from '../models/IntranetTransTaskType';
import type { IntranetUom } from '../models/IntranetUom';
import type { IPostAssignmentRemindFreelancer } from '../models/IPostAssignmentRemindFreelancer';
import type { ITransProjectFilter } from '../models/ITransProjectFilter';
import type { ITransTask } from '../models/ITransTask';
import type { ITransWorkflowStep } from '../models/ITransWorkflowStep';
import type { NumberFloat } from '../models/NumberFloat';
import type { PersonId } from '../models/PersonId';
import type { PersonIds } from '../models/PersonIds';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebixPortalAssignmentService {

    /**
     * Provide the quality report rating types which can be used for rating an assignment
     * @returns IGetAssignmentQualityRatingTypes with the two arrays
     * @throws ApiError
     */
    public static getAssignmentQualityRatingTypes({
        assignmentIds,
    }: {
        /**
         * Assignments we would like to rate
         */
        assignmentIds: ImFreelanceAssignmentIds,
    }): CancelablePromise<IGetAssignmentQualityRatingTypes> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/assignment_quality_rating_types',
            query: {
                'assignment_ids': assignmentIds,
            },
        });
    }

    /**
     * Returns the rating information
     * @returns IAssignmentQualityReport Generic Response description for cog_rest::get::assignment_quality_report
     * @throws ApiError
     */
    public static getAssignmentQualityReport({
        assigneeId,
        assignmentId,
    }: {
        /**
         * Assignee for whom we want to get assignments
         */
        assigneeId?: PersonId,
        /**
         * Assignment we would like get the report for
         */
        assignmentId?: ImFreelanceAssignmentIds,
    }): CancelablePromise<Array<IAssignmentQualityReport>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/assignment_quality_report',
            query: {
                'assignee_id': assigneeId,
                'assignment_id': assignmentId,
            },
        });
    }

    /**
     * Create a new assignment_quality_report
     * @returns IAssignmentQualityReport Generic Response description for cog_rest::post::assignment_quality_report
     * @throws ApiError
     */
    public static postAssignmentQualityReport({
        assignmentId,
        requestBody,
    }: {
        /**
         * Assignment where we want to add the report to
         */
        assignmentId: ImFreelanceAssignmentId,
        /**
         * Report information
         */
        requestBody: IAssignmentQualityReportBody,
    }): CancelablePromise<IAssignmentQualityReport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/assignment_quality_report',
            query: {
                'assignment_id': assignmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a quality report
     * @returns IAssignmentQualityReport Any remaining report for this assignment, probably empty
     * @throws ApiError
     */
    public static deleteAssignmentQualityReport({
        reportId,
    }: {
        /**
         * Report we want to update
         */
        reportId: ImAssignmentQualityReportId,
    }): CancelablePromise<IAssignmentQualityReport> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/assignment_quality_report',
            query: {
                'report_id': reportId,
            },
        });
    }

    /**
     * Update a quality report
     * @returns IAssignmentQualityReport Generic Response description for cog_rest::put::assignment_quality_report
     * @throws ApiError
     */
    public static putAssignmentQualityReport({
        reportId,
        requestBody,
        assignmentId,
    }: {
        /**
         * Report we want to update
         */
        reportId: ImAssignmentQualityReportId,
        /**
         * Report information
         */
        requestBody: IAssignmentQualityReportBody,
        /**
         * Assignment where we want to add the report to
         */
        assignmentId?: ImFreelanceAssignmentId,
    }): CancelablePromise<IAssignmentQualityReport> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/assignment_quality_report',
            query: {
                'report_id': reportId,
                'assignment_id': assignmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the best UoM for a freelance_package_id or a project_id
     * @returns ICategory Unit of measure
     * @throws ApiError
     */
    public static getBestUom({
        freelancePackageId,
        projectId,
    }: {
        /**
         * Package for which we want to get the best UoM
         */
        freelancePackageId?: ImFreelancePackageId,
        /**
         * Project for which we want to determine the best UoM ID
         */
        projectId?: ImProjectId,
    }): CancelablePromise<ICategory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/best_uom',
            query: {
                'freelance_package_id': freelancePackageId,
                'project_id': projectId,
            },
        });
    }

    /**
     * Returns the package (or task) units.
     * @returns IFreelancerTaskUnits array of task_units
     * @throws ApiError
     */
    public static getFreelancePackageUnits({
        freelancePackageId,
        freelancerId,
    }: {
        /**
         * id from im_trans_tasks
         */
        freelancePackageId: NumberFloat,
        /**
         * if of the freelander
         */
        freelancerId: NumberFloat,
    }): CancelablePromise<Array<IFreelancerTaskUnits>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/freelance_package_units',
            query: {
                'freelance_package_id': freelancePackageId,
                'freelancer_id': freelancerId,
            },
        });
    }

    /**
     * Returns the list of assignments of a freelancer used in the freelancer list view or the single assignment view.<br><br>        Contains more information about the project than the normal translation_assignment endpoint.
     * @returns IFreelancerAssignment Assignments of the freelancer
     * @throws ApiError
     */
    public static getFreelancerAssignments({
        freelancerId,
        assignmentId,
        assignmentStatusIds,
    }: {
        /**
         * Freelancer for whom we want to get the list of assignments
         */
        freelancerId?: Id,
        /**
         * Assignment we want to return (in case we need a single one)
         */
        assignmentId?: ImFreelanceAssignmentId,
        /**
         * Status of the assignment
         */
        assignmentStatusIds?: IntranetFreelanceAssignmentStatusIds,
    }): CancelablePromise<Array<IFreelancerAssignment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/freelancer_assignments',
            query: {
                'freelancer_id': freelancerId,
                'assignment_id': assignmentId,
                'assignment_status_ids': assignmentStatusIds,
            },
        });
    }

    /**
     * Returns the fee for each freelancer for the given uom_id and task_type_id
     * @returns IFreelancerFee array of fees
     * @throws ApiError
     */
    public static getFreelancerFee({
        freelancerIds,
        uomId,
        taskTypeId,
        sourceLanguageId,
        targetLanguageId,
        subjectAreaId,
    }: {
        /**
         * Freelancer for whom to get the fees
         */
        freelancerIds: PersonIds,
        /**
         * Unit of measure for the assignment. If empty, use the latest uom_id from the tasks (we should not have mixed uom_ids in packages)
         */
        uomId: IntranetUom,
        /**
         * Trans Task Type for which we want to view the fee
         */
        taskTypeId: IntranetTransTaskType,
        /**
         * Source_language_ids to make this more specific
         */
        sourceLanguageId: IntranetTranslationLanguage,
        /**
         * Target languages to specify the fee better
         */
        targetLanguageId: IntranetTranslationLanguage,
        /**
         * Subject area we look for
         */
        subjectAreaId?: IntranetTranslationSubjectArea,
    }): CancelablePromise<Array<IFreelancerFee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/freelancer_fee',
            query: {
                'freelancer_ids': freelancerIds,
                'uom_id': uomId,
                'task_type_id': taskTypeId,
                'source_language_id': sourceLanguageId,
                'target_language_id': targetLanguageId,
                'subject_area_id': subjectAreaId,
            },
        });
    }

    /**
     * Returns a list of freelancers who could work in a specific package.
     * @returns IFreelancer Array of freelancers for display in the freelancers selection screen
     * @throws ApiError
     */
    public static getFreelancersForPackage({
        freelancePackageId,
        excludeFeeP,
    }: {
        /**
         * Package for which we look for freelancers
         */
        freelancePackageId: ImFreelancePackageId,
        /**
         * Exclude the fee calculation
         */
        excludeFeeP?: Boolean0,
    }): CancelablePromise<Array<IFreelancer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/freelancers_for_package',
            query: {
                'freelance_package_id': freelancePackageId,
                'exclude_fee_p': excludeFeeP,
            },
        });
    }

    /**
     * Returns a list of freelancers who could work in a specific project.<br><br>        All filters (everything except the project_id) include sub_categories
     * @returns IFreelancer Array of freelancers for display in the freelancers selection screen
     * @throws ApiError
     */
    public static getFreelancersForProject({
        projectId,
        sourceLanguageIds,
        targetLanguageIds,
        subjectAreaIds,
        skillRoleIds,
        skillBusinessSectorIds,
        skillTmToolIds,
        uomId,
    }: {
        /**
         * Project for which we search freelancers to work on.
         */
        projectId: ImProjectId,
        /**
         * List of source_language_ids to filter, will not default to anything (so any source language is possible)
         */
        sourceLanguageIds?: IntranetTranslationLanguageIds,
        /**
         * List of target languages to filter (will limit to valid languages of the project. Filter out any invalid target_lang)
         */
        targetLanguageIds?: IntranetTranslationLanguageIds,
        /**
         * List of subject areas to filter for
         */
        subjectAreaIds?: IntranetTranslationSubjectAreaIds,
        /**
         * List of Roles the freelancer has experience in.
         */
        skillRoleIds?: IntranetSkillRoleIds,
        /**
         * List of Business Sectors (in addition to subject_area) the freelancer has experience.
         */
        skillBusinessSectorIds?: IntranetSkillBusinessSectorIds,
        /**
         * List of TM Tools the freelancer can work with.
         */
        skillTmToolIds?: IntranetTmToolIds,
        /**
         * Unit of measure for which we want to show the price. Defaults to S-Word
         */
        uomId?: IntranetUom,
    }): CancelablePromise<Array<IFreelancer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/freelancers_for_project',
            query: {
                'project_id': projectId,
                'source_language_ids': sourceLanguageIds,
                'target_language_ids': targetLanguageIds,
                'subject_area_ids': subjectAreaIds,
                'skill_role_ids': skillRoleIds,
                'skill_business_sector_ids': skillBusinessSectorIds,
                'skill_tm_tool_ids': skillTmToolIds,
                'uom_id': uomId,
            },
        });
    }

    /**
     * Returns a list of packages along with their tasks.<br><br>    Appends unassigned tasks with an empty freelance_package object..
     * @returns IFreelancePackage Array of the packages
     * @throws ApiError
     */
    public static getPackages({
        projectId,
        targetLanguageId,
        packageTypeId,
        transTaskId,
        freelancerIds,
        freelancePackageIds,
    }: {
        /**
         * Project for which we want to return the assignments
         */
        projectId?: ImProjectId,
        /**
         * Target language for which we want to return packages.
         */
        targetLanguageId?: IntranetTranslationLanguage,
        /**
         * Trans Task Type for which we want to view the packages
         */
        packageTypeId?: IntranetTransTaskType,
        /**
         * Task which we want to get the packages for.
         */
        transTaskId?: ImTransTaskId,
        /**
         * Freelancer whom we want to get possible packages for.
         */
        freelancerIds?: PersonIds,
        /**
         * Packages we want to return (by ID)
         */
        freelancePackageIds?: ImFreelancePackageIds,
    }): CancelablePromise<Array<IFreelancePackage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/packages',
            query: {
                'project_id': projectId,
                'target_language_id': targetLanguageId,
                'package_type_id': packageTypeId,
                'trans_task_id': transTaskId,
                'freelancer_ids': freelancerIds,
                'freelance_package_ids': freelancePackageIds,
            },
        });
    }

    /**
     * Returns a list of newly packages along with their tasks.<br><br>    Appends unassigned tasks with an empty freelance_package object..
     * @returns IFreelancePackage Array of the packages
     * @throws ApiError
     */
    public static postPackages({
        requestBody,
        projectId,
        overwriteP,
    }: {
        /**
         * Package Info for the creation of the pacakge
         */
        requestBody: IFreelancePackageBody,
        /**
         * Project for which we want to generate packages (all yet unassigned tasks into one package)
         */
        projectId?: ImProjectId,
        /**
         * if set, we will overwrite existing package assignments (if possible). Otherwise we only generate a package for the not already assigned tasks.
         */
        overwriteP?: Boolean0,
    }): CancelablePromise<Array<IFreelancePackage>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/packages',
            query: {
                'project_id': projectId,
                'overwrite_p': overwriteP,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the provided package ids<br><br>    Will remove as many as it can. For others we have the errors
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deletePackages({
        packageIds,
    }: {
        /**
         * Package we are trying to delete
         */
        packageIds: ImFreelancePackageIds,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/packages',
            query: {
                'package_ids': packageIds,
            },
        });
    }

    /**
     * Returns a list of newly packages along with their tasks.<br><br>    Appends unassigned tasks with an empty freelance_package object..
     * @returns IFreelancePackage Package we just updated
     * @throws ApiError
     */
    public static putPackages({
        freelancePackageId,
        requestBody,
    }: {
        /**
         * Package we want to update
         */
        freelancePackageId: ImFreelancePackageId,
        /**
         * Package Info for the creation of the pacakge
         */
        requestBody: IFreelancePackageBodyPut,
    }): CancelablePromise<IFreelancePackage> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/packages',
            query: {
                'freelance_package_id': freelancePackageId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns the list of previous assignments which have the same tasks in them. Used for rating freelancers
     * @returns IAssignment Array of assignments
     * @throws ApiError
     */
    public static getPreviousAssignments({
        assignmentId,
        assignmentStatusIds,
    }: {
        /**
         * Assignment we want to return the previous assignments for
         */
        assignmentId: ImFreelanceAssignmentId,
        /**
         * Status of the previous assignments we look for
         */
        assignmentStatusIds?: IntranetFreelanceAssignmentStatusIds,
    }): CancelablePromise<Array<IAssignment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/previous_assignments',
            query: {
                'assignment_id': assignmentId,
                'assignment_status_ids': assignmentStatusIds,
            },
        });
    }

    /**
     * Returns a list of default values for filtering feelancer
     * @returns ITransProjectFilter Set of filters for this project
     * @throws ApiError
     */
    public static getProjectDefaultFilters({
        projectId,
        freelancePackageId,
    }: {
        /**
         * project for which we need the filters
         */
        projectId: ImProjectId,
        /**
         * PackageId for which we want to set the filters
         */
        freelancePackageId?: ImFreelancePackageId,
    }): CancelablePromise<ITransProjectFilter> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project_default_filters',
            query: {
                'project_id': projectId,
                'freelance_package_id': freelancePackageId,
            },
        });
    }

    /**
     * Provide the list of workflow steps necessary to deliver the tasks in this project<br><br>        Works based of im_trans_tasks task_type_id. Not all tasks necessarily have to go through each of the steps, so<br>        this is only useful for e.g. displaying the columns for the steps in a project.
     * @returns ITransWorkflowStep Workflow steps of this project
     * @throws ApiError
     */
    public static getProjectWorkflowSteps({
        projectId,
    }: {
        /**
         * Project for which we need to retrieve the steps
         */
        projectId: ImProjectId,
    }): CancelablePromise<Array<ITransWorkflowStep>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project_workflow_steps',
            query: {
                'project_id': projectId,
            },
        });
    }

    /**
     * Returns a list of tasks which are not in a package
     * @returns ITransTask Array of the tasks which don't have a package
     * @throws ApiError
     */
    public static getTasksWithoutPackage({
        projectId,
        targetLanguageId,
        packageTypeId,
    }: {
        /**
         * Project for which we want to return the assignments
         */
        projectId: ImProjectId,
        /**
         * Target language for which we want to get the tasks
         */
        targetLanguageId?: IntranetTranslationLanguage,
        /**
         * package_type we need to query for.
         */
        packageTypeId?: IntranetTransTaskType,
    }): CancelablePromise<Array<ITransTask>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks_without_package',
            query: {
                'project_id': projectId,
                'target_language_id': targetLanguageId,
                'package_type_id': packageTypeId,
            },
        });
    }

    /**
     * Returns an array of the current assignments of a project
     * @returns IAssignment Array of assignments
     * @throws ApiError
     */
    public static getTranslationAssignments({
        assignmentId,
        projectId,
        freelancePackageId,
        assignmentStatusId,
        assignmentTypeId,
        projectTypeId,
    }: {
        /**
         * Assignment we want to return (in case we need a single one)
         */
        assignmentId?: ImFreelanceAssignmentId,
        /**
         * Project for which we want to return the assignments
         */
        projectId?: ImProjectId,
        /**
         * Freelance package we want to view
         */
        freelancePackageId?: ImFreelancePackageId,
        /**
         * Status of the assignment (useful for tests)
         */
        assignmentStatusId?: IntranetFreelanceAssignmentStatus,
        /**
         * Type of assignment we look for
         */
        assignmentTypeId?: IntranetTransTaskType,
        /**
         * Type of project, needed for testing.
         */
        projectTypeId?: IntranetProjectType,
    }): CancelablePromise<Array<IAssignment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/translation_assignments',
            query: {
                'assignment_id': assignmentId,
                'project_id': projectId,
                'freelance_package_id': freelancePackageId,
                'assignment_status_id': assignmentStatusId,
                'assignment_type_id': assignmentTypeId,
                'project_type_id': projectTypeId,
            },
        });
    }

    /**
     * Generate one (or multiple) assignments for a *single* package.
     * @returns IAssignment Array of assignments
     * @throws ApiError
     */
    public static postTranslationAssignments({
        freelancerIds,
        freelancePackageId,
        requestBody,
    }: {
        /**
         * Freelancer for whom to create an assignment
         */
        freelancerIds: PersonIds,
        /**
         * Package which we want to assign. Only one package at a time.
         */
        freelancePackageId: ImFreelancePackageId,
        /**
         * detailed information of the assignment we want to update
         */
        requestBody: IAssignmentBody,
    }): CancelablePromise<Array<IAssignment>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/translation_assignments',
            query: {
                'freelancer_ids': freelancerIds,
                'freelance_package_id': freelancePackageId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Set an assignment to deleted
     * @returns IAssignment Array of assignments which were not purged. If we purge (successfully) nothing will be returned.
     * @throws ApiError
     */
    public static deleteTranslationAssignments({
        assignmentIds,
        purgeP,
        reason,
        deletePoP,
    }: {
        /**
         * Assignment which we want to delete
         */
        assignmentIds: ImFreelanceAssignmentIds,
        /**
         * Are we trying to purge the assignment. Successful if the assignment does not have a purchase order
         */
        purgeP?: Boolean0,
        /**
         * What is the reason provided for the deleting of the assignment
         */
        reason?: string,
        /**
         * Should we set the Purchase Order (if it exists) to rejected / deleted.
         */
        deletePoP?: Boolean0,
    }): CancelablePromise<Array<IAssignment>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/translation_assignments',
            query: {
                'assignment_ids': assignmentIds,
                'purge_p': purgeP,
                'reason': reason,
                'delete_po_p': deletePoP,
            },
        });
    }

    /**
     * Update an assignment
     * @returns IAssignment The newly updated assignment
     * @throws ApiError
     */
    public static putTranslationAssignments({
        assignmentId,
        requestBody,
    }: {
        /**
         * Assignment which we want to update
         */
        assignmentId: ImFreelanceAssignmentId,
        /**
         * detailed information of the assignment we want to update
         */
        requestBody: IAssignmentBody,
    }): CancelablePromise<IAssignment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/translation_assignments',
            query: {
                'assignment_id': assignmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a new rating within a report
     * @returns IAssignmentQualityReport Generic Response description for cog_rest::post::assignment_quality_rating
     * @throws ApiError
     */
    public static postAssignmentQualityRating({
        requestBody,
    }: {
        /**
         * Invididual Rating
         */
        requestBody: IAssignmentRatingBody,
    }): CancelablePromise<IAssignmentQualityReport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/assignment_quality_rating',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update a new rating within a report
     * @returns IAssignmentQualityReport Generic Response description for cog_rest::delete::assignment_quality_rating
     * @throws ApiError
     */
    public static deleteAssignmentQualityRating({
        reportId,
        qualityTypeId,
    }: {
        /**
         * Report from which we want to delete the rating
         */
        reportId: ImAssignmentQualityReportId,
        /**
         * Type of rating
         */
        qualityTypeId: IntranetTranslationQualityType,
    }): CancelablePromise<IAssignmentQualityReport> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/assignment_quality_rating',
            query: {
                'report_id': reportId,
                'quality_type_id': qualityTypeId,
            },
        });
    }

    /**
     * Update a new rating within a report
     * @returns IAssignmentQualityReport Generic Response description for cog_rest::put::assignment_quality_rating
     * @throws ApiError
     */
    public static putAssignmentQualityRating({
        requestBody,
    }: {
        /**
         * Invididual Rating
         */
        requestBody: IAssignmentRatingBody,
    }): CancelablePromise<IAssignmentQualityReport> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/assignment_quality_rating',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remind the freelancer about the assignment so he / she should accept it. Only works on requested assignments
     * @returns IPostAssignmentRemindFreelancer send
     * @throws ApiError
     */
    public static postAssignmentRemindFreelancer({
        assignmentId,
    }: {
        /**
         * Assignment for which we want to send the reminder
         */
        assignmentId: ImFreelanceAssignmentId,
    }): CancelablePromise<IPostAssignmentRemindFreelancer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/assignment_remind_freelancer',
            query: {
                'assignment_id': assignmentId,
            },
        });
    }

}
