/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetCostStatus {
    CREATED = 3802,
    OUTSTANDING = 3804,
    PAST_DUE = 3806,
    PARTIALLY_PAID = 3808,
    PAYMENT_IN_PROCESS = 3809,
    PAID = 3810,
    DELETED = 3812,
    REPLACED = 3813,
    FILED = 3814,
    SEND = 3815,
    REQUESTED = 3816,
    REJECTED = 3818,
    CANCELLED = 3819,
    ACCEPTED = 3820,
}
