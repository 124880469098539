import { CognovisRestService, ICategory, Pagination } from "../../../sources/openapi/index";

export class CognovisCategory {

	static storageKey = "cached_cognovis_categories";

	static getCategory(categoryType: string, forceXhr?:boolean): Promise<ICategory[]> {
		if (this.isCategoryAvailableInStorage(categoryType) && !forceXhr) {
            console.log("cached for: "+categoryType)
			return Promise.resolve().then(() => {
				const cachedCategories = webix.storage.session.get(
					this.storageKey
				);
				let concreteCategory = {};
				cachedCategories.forEach((category) => {
					if (category.category_type === categoryType) {
						concreteCategory = category.data;
					}
				});
				return concreteCategory as ICategory[];
			});
		} else {
            console.log("external for: "+categoryType)
			return CognovisRestService.getImCategory({
				categoryType:categoryType,
			})
			.then((categories) => {
				const categoriesWithProperKeys = this.prepareCategoriesForCombobox(
					categories
				);
				this.saveCategoryToStorage(
					categoryType,
					categoriesWithProperKeys
				);
				return categoriesWithProperKeys as ICategory[];
			});
		}
	}

	static matchCategory(catName:string):ICategory {
		const cachedCategories = webix.storage.session.get(
			this.storageKey
		);	
		let trackedCategory:ICategory;
		cachedCategories.forEach((category) => {
			if (category.category_translated === catName) {
				trackedCategory = category;
			}
		});
		return trackedCategory;
	}

	static isCategoryAvailableInStorage(categoryType: string): boolean {
		const cachedCategory = webix.storage.session.get(this.storageKey);
		let result = false;
		if (cachedCategory) {
			cachedCategory.forEach((category) => {
				if (category.category_type === categoryType) {
					result = true;
				}
			});
		}
		return result;
	}

	static saveCategoryToStorage(categoryType: string, data: unknown[]): void {
		const cachedCategories = webix.storage.session.get(this.storageKey);
		if (cachedCategories) {
			cachedCategories.push({
				category_type: categoryType,
				data,
			});
			webix.storage.session.put(this.storageKey, cachedCategories);
		} else {
			const categoriesArr = [];
			categoriesArr.push({
				category_type: categoryType,
				data,
			});
			webix.storage.session.put(this.storageKey, categoriesArr);
		}
	}

	static prepareCategoriesForCombobox(categories: ICategory[]): ICombobox[] {
		const newCategoryObj: ICombobox[] = [];
		categories.forEach((category: ICategory) => {
			newCategoryObj.push({
				id: category.category_id,
				value: category.category_translated,
				aux_int1:category.aux_int1,
				aux_string1:category.aux_string1
			});
		});
		return newCategoryObj;
	}
}
