import { CognovisCategory } from "../../modules/cognovis-category/cognovis-category";
import { CognovisRestCompanyService, CognovisRestFileService, ICompanyContact, CognovisRestNotesService, ICrFile, INamedId, IntranetCompanyStatus, IntranetCompanyType, ITransProject,  WebixPortalFreelancerService,  WebixPortalProjectService, WebixPortalTranslationService, IntranetNotesType, IntranetProjectType, IntranetProjectStatus, IntranetOfficeType, CognovisRestDynamicService, IDynamicAttribute, ITransProjectBody } from "../../openapi";
import { WebixHelpers } from "../../modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { IJetApp, JetView } from "webix-jet";
import { UserProfile } from "../../modules/cognovis-profile/profiles-types";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { config } from "../../config";
import CognovisFileUploaderModal from "../../../sources/modules/file-helpers/cognovis-file-uploader-modal";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import DynCompanyCreationModal from "views/companies-overview/dyn-company-creation-modal";
import DynCompanyContactModal from "views/companies-overview/dyn-company-contact-modal";
import { WebixAlertConfig } from "@xbs/webix-pro";

export default class NewProjectForm extends JetView {

    formId = "npNewOfferForm";
    dynfields:IDynamicAttribute[];
    webixHelpers: WebixHelpers;
    userRole:UserProfile;
    uploadedFiles: IUploadedFile[];
    companyCreationModal:DynCompanyCreationModal;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    fileUploaderModal:CognovisFileUploaderModal;
    alreadyUploadedFiles:ICrFile[] = [];
    originalFiles:ICrFile[] = [];
    referenceFiles:ICrFile[] = [];
    currentUserId:number;
    commentModified = false;
    sidebar:webix.ui.sidebar;
    userCreationModal:DynCompanyContactModal;
    visibleDynfields:string[];
    currentSubtype:IntranetProjectType = IntranetProjectType.TRANS_PROOF;
    sessionValues:{[key: string]: string | number}[] = [];
    
    constructor(app:IJetApp) {
        super(app, {});
        this.webixHelpers = container.resolve(WebixHelpers);
        this.currentUserId = UserStateManager.getCurrentlyLoggedUser().id;
        this.sidebar = webix.$$("sidebarMainSidebar") as webix.ui.sidebar;
    }

    config(): Promise<webix.ui.layoutConfig> {
        return this.getDynfields("im_project", "/#!/new-offer", IntranetProjectType.TRANS_PROOF)
        .then(dynfields => {
            this.dynfields = dynfields;
            this.visibleDynfields = dynfields.map(dynfield => dynfield.attribute.name);
            const formFieldsFirstColumn = this.getFormFieldsFirstColumn();
            const formFieldsSecondColumn = this.getFormFieldsSecondColumn();
            const properLayout = this.getProperLayout(formFieldsFirstColumn, formFieldsSecondColumn);
            setTimeout(() => {
                this.setRequiredOrNotClasses();
                //this.attachInputLabels();
            },100);
            return {
                view: "layout",
                type: "clean",
                gravity:1,
                rows: [
                    {
                        view: "form",
                        id:this.formId,
                        autoWidth: true,
                        gravity:1,
                        elements: [
                            {
                                view: "layout",
                                type: "clean",
                                css:"new-project-form",
                                ...properLayout
                            }
                        ],
                        rules: this.getFormRules()
                    }
                ],
            }
        });
    }


    rebuildForms(subtypeId:number):void {
        this.saveSessionValues();
        const form = webix.$$(this.formId) as webix.ui.form;
        this.getDynfields("im_project", "/#!/new-offer", subtypeId)
        .then(dynfields => {
            this.visibleDynfields = dynfields.map(dynfield => dynfield.attribute.name);
            const formFieldsFirstColumn = this.getFormFieldsFirstColumn();
            const formFieldsSecondColumn = this.getFormFieldsSecondColumn();
            const properLayout = this.getProperLayout(formFieldsFirstColumn, formFieldsSecondColumn);
            console.log(properLayout);
            const layout = {
                view: "layout",
                type: "clean",
                css:"new-project-form",
                ...properLayout
            };
            form.define("elements", [layout]);
            form.reconstruct();
            this.setSessionValues();
            this.setRequiredOrNotClasses();
            //this.attachInputLabels();
        });  
    }

    init():void {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);    
        (this.$$("commentsCkEditor5") as any).getEditor(true)
        .then(editor => {
            editor.ui.focusTracker.on( 'change:isFocused', (evt, data, isFocused) => {
                if(isFocused && !this.commentModified) {
                    editor.data.set("");
                    this.commentModified = true;
                }
            });
        });
    }

    getDynfields(objectType:string, pageUrl:string, objectSubtypeId?:number):Promise<IDynamicAttribute[]> {
        let params = {
            objectType:objectType,
            pageUrl:pageUrl
        }
        if(objectSubtypeId) {
            params["objectSubtypeId"] = objectSubtypeId;
        }
        return CognovisRestDynamicService.getDynamicAttributes(params)    
        .then(dynfields => {
            const sortedBySortOrder = dynfields.sort((a,b) => (a["sort_order"] > b["sort_order"]) ? 1 : ((b["sort_order"] > a["sort_order"]) ? -1 : 0));
            return dynfields
        });
    }

    getFormRules():{[key:string]:() => boolean} {
        const rules = {};
        this.dynfields.map(dynfield => {
            let fieldIsRequired = false;
            dynfield.dynamic_subtypes.map(subtype => {
                if(subtype.required_p) {
                    fieldIsRequired = true;
                }
            });
            if(fieldIsRequired && this.fieldExists(dynfield.attribute.name)) {
                rules[dynfield.attribute.name] = webix.rules.isNotEmpty;
            }
        });
        return rules;
    }

    fieldExists(fieldName:string):boolean {
        const fields = [...this.getFormFieldsFirstColumn(), ...this.getFormFieldsSecondColumn()];
        if(fields.find(field => field["name"] === fieldName)) {
            return true
        } else {
            return false
        }
    }

    attachInputLabels():void {
        const fields = this.collectAllFields();
        const form: webix.ui.form = $$(this.formId) as webix.ui.form;
        fields.map(field => {
            const fieldObj = form.queryView({name:"field"}, "all");
            if(fieldObj[0]) {
                this.dynfields.map(dynfield => {
                    if(field === dynfield.attribute.name) {
                        fieldObj[0].define("label", i18nHelper.getTranslationWithKey(dynfield.display_name, dynfield.i18n_key));
                        fieldObj[0].refresh();
                    }
                });
            }
        });
    }

    getFormFieldsFirstColumn(): Record<string, unknown>[] {
        const fields: Record<string, unknown>[] = 
            [
                {
                    view: "text",
                    height: 35,
                    label:i18nHelper.getTranslation("project_name"),
                    placeholder:i18nHelper.getTranslation("project_name"),
                    labelWidth:140,
                    gravity:95,
                    inputWidth:590,
                    name: "project_name",
                    id: "npProjectName"
                },
                {
                    view:"spacer",
                    height:20,
                },
                {
                    view:"layout",
                    name: "company_id",
                    gravity:1,
                    cols:[
                            {
                            view: "combo",
                            gravity:96,
                            label:i18nHelper.getTranslation("customer"),
                            placeholder:i18nHelper.getTranslation("customer"),
                            labelPosition:"left",
                            height: 35,
                            labelWidth:140,
                            inputWidth:590,
                            name: "company_id",
                            id: "npCustomer",
                            suggest: {
                                body: {
                                    url: () => {
                                        return CognovisRestCompanyService.getCompanies({
                                            companyStatusIds: [IntranetCompanyStatus.ACTIVE],
                                            companyTypeIds: [IntranetCompanyType.CUSTOMER]
                                        })
                                        .then(companyInfo => {
                                            const customerOptionsForCombo = companyInfo.map(company => ({id:company.company.id, value:company.company.name}))
                                            return customerOptionsForCombo;
                                        });
                                    }
                                },
                            },
                            on: {
                                onChange: (companyId) => {
                                    CognovisRestCompanyService.getCompanyContacts({
                                        companyId: companyId
                                    })
                                    .then((possibleContacts) => {
                                        //const possibleContactsForCombo = this.webixHelpers.prepareDataForCombo(possibleContacts,"user_id","full_name",["email"]);
                                        const possibleContactsForCombo = possibleContacts.map(contact => ({id:contact.contact.id, value:contact.contact.name, email:contact.email}));
                                        const customerContactCombo = webix.$$("npCustomerContact") as webix.ui.combo;
                                        const customerCombo = webix.$$("npCustomer") as webix.ui.combo;
                                        customerCombo["additionalData"] = possibleContactsForCombo;
                                        customerContactCombo.define("options", possibleContactsForCombo);
                                        if(possibleContacts[0]) {
                                            customerContactCombo.setValue(possibleContactsForCombo[0].id.toString());
                                            const customerEmailCombo = webix.$$("npCustomerEmail") as webix.ui.combo;
                                            if(customerEmailCombo) {
                                                customerEmailCombo.setValue(possibleContactsForCombo[0]["email"]);
                                            }
                                        }
                                        this.setNewProjectCompanyDefaults(companyId);
                                    });
                                },
                            } 
                        },
                        { 
                            view:"button",
                            gravity:4,
                            id:"npAddNewCustomerButton",
                            type:"icon",
                            css:"cog-icon",
                            icon:"wxi-plus",
                            click:() => {
                                const dynfieldsSetup:IDynfieldSetup = {
                                    pages:[
                                        {
                                            objectType:"im_company",
                                            pageUrl:"/#!/new-offer",
                                            objectSubtypeId:IntranetCompanyType.CUSTOMER
                                        },
                                        {
                                            objectType:"im_office",
                                            pageUrl:"/#!/new-offer",
                                            objectSubtypeId:IntranetOfficeType.MAIN_OFFICE
                                        }
                                    ],
                                    checkForRequiredFields:true
                                }
                                const defaultValues = {"company_type_id":IntranetCompanyType.CUSTOMER};
                                if(!this.companyCreationModal) {
                                    this.companyCreationModal = this.ui(DynCompanyCreationModal) as DynCompanyCreationModal;
                                }
                                this.companyCreationModal.openModal(dynfieldsSetup, [], i18nHelper.getTranslation("New company"), defaultValues, (newCompanyInfo) => {
                                    CognovisRestCompanyService.getCompanies({
                                        companyStatusIds: [IntranetCompanyStatus.ACTIVE],
                                        companyTypeIds: [IntranetCompanyType.CUSTOMER]
                                    })
                                    .then(companyInfo => {
                                        const companyField = webix.$$("npCustomer") as webix.ui.combo;
                                        const customerOptionsForCombo = companyInfo.map(company => ({id:company.company.id, value:company.company.name}))
                                        companyField.define("options", customerOptionsForCombo);
                                        companyField.setValue(newCompanyInfo["company"]["id"].toString());
                                        this.cognovisPleaseWaitWindow.hide();
                                    });
                                });
                            }
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:20,
                },
                {
                    view: "combo",
                    name: "project_type_id",
                    label:i18nHelper.getTranslation("project_type"),
                    placeholder:i18nHelper.getTranslation("project_type"),
                    id: "npProjectType",
                    labelWidth:140,
                    inputWidth:590,
                    labelPosition:"left",
                    value:IntranetProjectType.TRANS_PROOF,
                    height: 35,
                    suggest: {
                        id:"npProjectTypeSuggest",
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet Project Type"),
                        }
                    },
                    on: {
                        onChange:(value:number) => {
                            this.currentSubtype = value;
                            this.rebuildForms(value);
                        },
                        onAfterRender:() => {
                            if(this.currentSubtype) {
                                this.setProjectTypeField(this.currentSubtype);
                            } 
                            const me = webix.$$("npProjectType") as webix.ui.combo;
                            if(me) {
                                me.focus();
                            }
                        }
                    }
                },
                {
                    view:"spacer",
                    height:20,
                },
                {
                    view:"layout",
                    name: "company_contact_id",
                    gravity:1,
                    cols:[
                        {
                            view: "combo",
                            label: i18nHelper.getTranslation("customer_contact"),
                            placeholder: i18nHelper.getTranslation("customer_contact"),
                            labelPosition:"left",
                            height: 35,
                            labelWidth:140,
                            gravity:96,
                            inputWidth:590,
                            name: "company_contact_id",
                            id: "npCustomerContact",
                            options: [],
                            on: {
                                onChange: (e) => {
                                    // Changing customer email ones the contact value changes
                                    const emailCombo = (webix.$$("npCustomerEmail") as webix.ui.combo);
                                    if(emailCombo) {
                                        const customerCombo = (webix.$$("npCustomer") as webix.ui.combo);
                                        const customerComboAdditionalData = customerCombo["additionalData"];
                                        if (typeof customerComboAdditionalData !== "undefined") {
                                            customerComboAdditionalData.map(contact => {
                                                if (contact.id == e) {
                                                        emailCombo.setValue(contact.email);
                                                }
                                            });
                                        }
                                    }
                                },
                            }
                        },
                        { 
                            view:"button",
                            id:"npAddNewUserButton",
                            gravity:4,
                            type:"icon",
                            css:"cog-icon",
                            icon:"wxi-plus",
                            click:() => {
                                const companyField = webix.$$("npCustomer") as webix.ui.combo;
                                const companyId = companyField.getValue();
                                if(companyId) {
                                    const companyName = companyField.getText();
                                    const modalTitle = `${companyName}`;
                                    if(!this.userCreationModal) {
                                        this.userCreationModal = this.ui(DynCompanyContactModal) as DynCompanyContactModal;
                                    }
                                    const dynfieldsSetup:IDynfieldSetup = {
                                        pages:[
                                            {
                                                objectType:"person",
                                                pageUrl:"/#!/new-offer",
                                            }
                                        ],
                                        checkForRequiredFields:true
                                    };
                                    this.userCreationModal.openModal(dynfieldsSetup, [], i18nHelper.getTranslation("New company contact"), {}, (companyContact:ICompanyContact) => {
                                        CognovisRestCompanyService.getCompanyContacts({
                                            companyId: companyContact.company.id
                                        })
                                        .then((possibleContacts) => {
                                            //const possibleContactsForCombo = this.webixHelpers.prepareDataForCombo(possibleContacts,"user_id","full_name",["email"]);
                                            const possibleContactsForCombo = possibleContacts.map(contact => ({id:contact.contact.id, value:contact.contact.name, email:contact.email}));
                                            const justCreatedContact = possibleContactsForCombo.find(contact => contact.id === companyContact.contact.id);
                                            if(justCreatedContact) {
                                                const customerContactCombo = webix.$$("npCustomerContact") as webix.ui.combo;
                                                customerContactCombo.define("options", possibleContactsForCombo);
                                                customerContactCombo.setValue(justCreatedContact.id.toString())
                                                const customerEmailCombo = webix.$$("npCustomerEmail") as webix.ui.combo;
                                                if(customerEmailCombo) {
                                                    customerEmailCombo.setValue(justCreatedContact.email);
                                                }
                                            }
                                        });
                                    });
                                } else {
                                    const pleaseSelectCompanyFirstTranslation = i18nHelper.getTranslation("Please_select_company_first");
                                    webix.alert({
                                        text:pleaseSelectCompanyFirstTranslation,
                                        expire: 10000,
                                        title:"Error",
                                        type:"error"
                                    } as unknown as WebixAlertConfig);
                                }
                            }
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:20,
                },
                {
                    view:"spacer",
                    height:20,
                },
                {
                    view: "combo",
                    name: "parent_id",
                    label:i18nHelper.getTranslation("Parent_project"),
                    placeholder:i18nHelper.getTranslation("Parent_project"),
                    id: "npParentProject",
                    labelWidth:140,
                    inputWidth:590,
                    labelPosition:"left",
                    height: 35,
                    suggest: {
                        body: {
                            url: () => {
                                return WebixPortalProjectService.getUserProjects({
                                    userId:UserStateManager.getCurrentlyLoggedUser().id
                                })
                                .then(potentialParentProjects => {
                                    const parentProjectIdField = webix.$$("npParentProject") as webix.ui.combo;
                                    if(parentProjectIdField) {
                                        const potentialParentProjectsParsedForCombo = potentialParentProjects.map(project => ({id:project.project.id, value:`${project.project_nr} - ${project.project.name}`}));
                                        parentProjectIdField.define("options", potentialParentProjectsParsedForCombo);
                                        parentProjectIdField.refresh();
                                    }
                                });
                            },
                        },
                    },
                    visibleForSubtypes:"all"
                },
                {
                    view:"spacer",
                    height:20,
                },
                {
                    view:"layout",
                    name: "final_company_id",
                    cols:[
                        {
                            view: "combo",
                            name: "final_company_id",
                            id: "npFinalCompany",
                            label: i18nHelper.getTranslation("final_company"),
                            placeholder: i18nHelper.getTranslation("final_company"),
                            labelPosition:"left",
                            height: 35,
                            gravity:96,
                            labelWidth:140,
                            inputWidth:590,
                            suggest: {
                                body: {
                                    url: () => {
                                        return CognovisRestCompanyService.getCompanies({
                                            companyTypeIds:[IntranetCompanyType.FINAL_COMPANY]
                                        })
                                        .then(finalCompanies => {
                                            const finalComapniesForCombo = finalCompanies.map(company => ({id:company.company.id, value:company.company.name}))
                                            return finalComapniesForCombo;
                                        });
                                    },
                                },
                            },
                        },
                        { 
                            view:"button",
                            id:"npAddNewFinalCompanyButton",
                            type:"icon",
                            icon:"wxi-plus",
                            css:"cog-icon",
                            gravity:4,
                            click:() => {
                                const dynfieldsSetup:IDynfieldSetup = {
                                    pages:[
                                        {
                                            objectType:"im_company",
                                            pageUrl:"/#!/new-offer",
                                            objectSubtypeId:IntranetCompanyType.FINAL_COMPANY
                                        },
                                        {
                                            objectType:"im_office",
                                            pageUrl:"/#!/new-offer",
                                            objectSubtypeId:IntranetOfficeType.MAIN_OFFICE
                                        }
                                    ],
                                    checkForRequiredFields:true
                                }
                                const defaultValues = {"company_type_id":IntranetCompanyType.FINAL_COMPANY};
                                if(!this.companyCreationModal) {
                                    this.companyCreationModal = this.ui(DynCompanyCreationModal) as DynCompanyCreationModal;
                                }
                                this.companyCreationModal.openModal(dynfieldsSetup, [], i18nHelper.getTranslation("New company"), defaultValues, (newCompanyInfo) => {
                                    CognovisRestCompanyService.getCompanies({
                                        companyStatusIds: [IntranetCompanyStatus.ACTIVE],
                                        companyTypeIds: [IntranetCompanyType.FINAL_COMPANY]
                                    })
                                    .then(companyInfo => {
                                        const companyField = webix.$$("npFinalCompany") as webix.ui.combo;
                                        const finalCompaniesForCombo = companyInfo.map(company => ({id:company.company.id, value:company.company.name}))
                                        companyField.define("options", finalCompaniesForCombo);
                                        companyField.setValue(newCompanyInfo["company"]["id"].toString());
                                        this.cognovisPleaseWaitWindow.hide();
                                    });
                                });
                            }
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:20,
                },
                // {
                //     view: "combo",
                //     name: "invoice_address",
                //     id: "npInvoiceAddress",
                //     css: "invoice__address",
                //     label: i18nHelper.getTranslation("invoice_address"),
                //     placeholder: i18nHelper.getTranslation("invoice_address"),
                //     labelPosition:"left",
                //     height: 35,
                //     labelWidth:140,
                //     inputWidth:590,
                // },
                {
                    view:"text",
                    name:"additional_billing_info",
                    label: i18nHelper.getTranslation("Billing_info"),
                    placeholder: i18nHelper.getTranslation("Eg_company_address"),
                    labelPosition:"left",
                    height: 35,
                    labelWidth:140,
                    inputWidth:590,
                    visibleForSubtypes:"all"
                },
                {
                    view:"spacer",
                    height:20,
                },
                {
                    view: "text",
                    type: "number",
                    name: "processing_time",
                    id: "npProcessingTime",
                    css: "processing__time",
                    label: i18nHelper.getTranslation("processing_time"),
                    placeholder: i18nHelper.getTranslation("processing_time"),
                    labelPosition:"left",
                    height: 35,
                    labelWidth:140,
                    inputWidth:590,
                    attributes:{ min:1 }
                },
                {
                    view:"spacer",
                    height:20,
                },
            ]
            .filter(field => {
                let currentRole:UserProfile;
                if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
                    currentRole = UserProfile.CUSTOMER;
                } else {
                    currentRole = UserProfile.PROJECT_MANAGER;
                }
                if((config.newOfferFieldsSettings.find(role => role.role === currentRole).allFieldsVisible) || config.newOfferFieldsSettings.find(role => role.role === currentRole).visibleFields.indexOf(field.name) > -1) {
                    return true
                } else {
                    return false
                }
            });
            fields.map(field => {
                this.dynfields.map(dynfield => {
                    if(dynfield.attribute.name === field["name"]) {
                        field["sortOrder"] = dynfield.sort_order;
                    }
                });
            });
            return fields.filter(field => (this.visibleDynfields.indexOf((field["name"]) as any) > -1 || this.matchHardcodedSubtype(field)));
    }

    getFormFieldsSecondColumn(): Record<string, unknown>[] {
        const fields = 
        [
            {
                view: "combo",
                name: "source_language_id",
                id: "npSourceLanguage",
                label: i18nHelper.getTranslation("source_language"),
                placeholder: i18nHelper.getTranslation("source_language"),
                labelPosition:"left",
                height: 35,
                labelWidth:140,
                inputWidth:590,
                suggest: {
                    body: {
                        url: () =>
                            CognovisCategory.getCategory(
                                "Intranet Translation Language"
                            ),
                    },
                },
            },
            {
                view:"spacer",
                height:20,
            },
            {
                view: "multicombo",
                name: "target_language_ids",
                id: "npTargetLanguages",
                label: i18nHelper.getTranslation("target_languages"),
                placeholder: i18nHelper.getTranslation("target_languages"),
                labelPosition:"left",
                height: 35,
                labelWidth:140,
                inputWidth:590,
                visibleFor:[],
                suggest: {
                    body: {
                        url: () =>
                            CognovisCategory.getCategory(
                                "Intranet Translation Language"
                            ),
                    },
                },
                visibleForSubtypes:"all"
            },
            {
                view:"spacer",
                height:20,
            },
            {
                view: "datepicker",
                format:"%d.%m.%Y %H:%i",
                value: " ",
                timepicker: true,
                name: "start_date",
                id: "npStartDate",
                css: "start__date",
                label: i18nHelper.getTranslation("start_date"),
                placeholder: i18nHelper.getTranslation("start_date"),
                labelPosition:"left",
                height: 35,
                labelWidth:140,
                inputWidth:590,
            },
            {
                view:"spacer",
                height:20,
            },
            {
                view: "datepicker",
                value: " ",
                format:"%d.%m.%Y %H:%i",
                timepicker: true,
                name: "end_date",
                id: "npEndDate",
                css: "end__date",
                label: i18nHelper.getTranslation("end_date"),
                placeholder: i18nHelper.getTranslation("end_date"),
                labelPosition:"left",
                height: 35,
                labelWidth:140,
                inputWidth:590
            },
            {
                view:"spacer",
                height:20,
            },
            {
                view: "combo",
                name: "subject_area_id",
                id: "npSubjectArea",
                css: "subject__area",
                label: i18nHelper.getTranslation("subject_area"),
                placeholder: i18nHelper.getTranslation("subject_area"),
                labelPosition:"left",
                height: 35,
                labelWidth:140,
                inputWidth:590,
                suggest: {
                    body: {
                        url: () =>
                            CognovisCategory.getCategory(
                                "Intranet Translation Subject Area"
                            ),
                    },
                },
            },
            {
                view:"spacer",
                height:20,
            },
            {
                view: "combo",
                name: "skill",
                id: "npSkill",
                label: i18nHelper.getTranslation("skill"),
                placeholder: i18nHelper.getTranslation("skill"),
                labelPosition:"left",
                height: 35,
                labelWidth:140,
                inputWidth:590,
                suggest: {
                    body: {
                        url: () => 
                            CognovisCategory.getCategory(
                                "Intranet Skill Business Sector"
                            ),
                    },
                },
                visibleForSubtypes:"all"
            },
            {
                view:"spacer",
                height:20,
            },
            {
                view: "text",
                name: "company_project_nr",
                id: "npRefNr",
                css: "ref__nr",
                label: i18nHelper.getTranslation("company_project_nr"),
                placeholder: i18nHelper.getTranslation("company_project_nr"),
                labelPosition:"left",
                height: 35,
                labelWidth:140,
                inputWidth:590,
            },
            {
                view:"spacer",
                height:20,
            },
            {
                view: "combo",
                name: "complexity_type_id",
                id: "npComplexityType",
                css: "ref__nr",
                label: i18nHelper.getTranslation("Complexity_type"),
                placeholder: i18nHelper.getTranslation("Complexity_type"),
                labelPosition:"left",
                height: 35,
                labelWidth:140,
                inputWidth:590,
                value:4290,
                suggest: {
                    body: {
                        url: () => 
                            CognovisCategory.getCategory(
                                "Intranet Price Complexity"
                            ),
                    },
                },
            },
            {
                view:"spacer",
                height:20,
            },
            {
                view:"layout",
                name:"add_original_file",
                rows:[
                    {
                        view:"spacer",
                        height:15
                    },
                    {
                        view: "button",
                        margin:40,
                        width:591,
                        css:"cog-button-big",
                        name:"add_original_file",
                        hidden:!CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER),
                        value:i18nHelper.getTranslation("add_original_file"),
                        id: "npAddOriginalFile",
                        height: 35,
                        click: () => {
                            this.openFileModal(this.currentUserId);
                        },
                    }
                ]
            },
            {
                view:"spacer",
                height:5,
            }
        ]
        .filter(field => {
            let currentRole:UserProfile;
            if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
                currentRole = UserProfile.CUSTOMER;
            } else {
                currentRole = UserProfile.PROJECT_MANAGER;
            }
            if((config.newOfferFieldsSettings.find(role => role.role === currentRole).allFieldsVisible) || config.newOfferFieldsSettings.find(role => role.role === currentRole).visibleFields.indexOf(field.name) > -1) {
                return true
            } else {
                return false
            }
        });
        fields.map(field => {
            this.dynfields.map(dynfield => {
                if(dynfield.attribute.name === field["name"]) {
                    field["sortOrder"] = dynfield.sort_order;
                }
            });
        });
        return fields.filter(field => (this.visibleDynfields.indexOf((field["name"]) as any) > -1 || this.matchHardcodedSubtype(field)));
    }

    matchHardcodedSubtype(field) {
        if(this.currentSubtype) {
            if(field["visibleForSubtypes"] === "all" || (field["visibleForSubtypes"] && field["visibleForSubtypes"].length > 0 && field["visibleForSubtypes"].indexOf(Number(this.currentSubtype)) > -1)) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    setProjectTypeField(subtypeId:number):void {
        const me = webix.$$("npProjectType") as webix.ui.combo;
        me.blockEvent();
        me.setValue(subtypeId.toString());
        me.unblockEvent();
    }

    setNewProjectCompanyDefaults(companyId:string):void {
        //Autofill for project_type, source_language,target_language,subject_area,final_company,subject_area
        WebixPortalProjectService.getCompanyNewProjectDefaults({
            customerId: parseInt(companyId),
        })
        .then(data => {
            const projectTypeField = (webix.$$("npProjectType") as webix.ui.combo);
            // Only set default project_type_id when value is empty
            if(!projectTypeField.getValue()) {
                this.setProjectTypeField(data.project_type_id);
            }
            const sourceLanguageField = webix.$$("npSourceLanguage") as webix.ui.combo;
            if(sourceLanguageField && sourceLanguageField.isEnabled()) {
                sourceLanguageField.setValueHere(data.source_language_id?.toString());
            }
            const targetLanguageField = webix.$$("npTargetLanguages") as webix.ui.combo;
            if(targetLanguageField && targetLanguageField.isEnabled()) {
                targetLanguageField.setValueHere(data.target_language_id?.toString());
            }
            const subjectAreaField = webix.$$("npSubjectArea") as webix.ui.combo;
            if(subjectAreaField && subjectAreaField.isEnabled()) {
                subjectAreaField.setValueHere(data.subject_area_id?.toString());
            }
            const finalCompanyField = webix.$$("npFinalCompany") as webix.ui.combo;
            if(finalCompanyField && finalCompanyField.isEnabled()) {
                finalCompanyField.setValueHere(data.final_company_id?.toString());
            }
            const complexityTypeField = webix.$$("npComplexityType") as webix.ui.combo;
            if(complexityTypeField && complexityTypeField.isEnabled()) {
                complexityTypeField.setValueHere(data.complexity_type_id?.toString());
            }
        });
    }

    saveSessionValues():void {
        const form: webix.ui.form = $$(this.formId) as webix.ui.form;
        const values = form.getValues();
        this.sessionValues = values as {[key: string]: string | number}[];
    }

    setSessionValues():void {
        if(this.sessionValues) {
            const form: webix.ui.form = $$(this.formId) as webix.ui.form;
            const allFields = this.collectAllFields();
            allFields.map(fieldName => {
                const field = form.queryView({name:fieldName}) as webix.ui.text;
                if(field && this.sessionValues[fieldName]) {
                    field.setValue(this.sessionValues[fieldName])
                }
            });
        }
    }

    setRequiredOrNotClasses():void {
        const fields = this.collectAllFields();
        const rules = this.getFormRules();
        const form: webix.ui.form = $$(this.formId) as webix.ui.form;
        fields.map(fieldName => {
            const field = form.queryView({name:fieldName}) as webix.ui.text;
            if(field) {
                webix.html.removeCss(field.getNode(), "mandatory");
            }
        });
        fields.map(fieldName => {
            if(rules[fieldName]) {
                const field = form.queryView({name:fieldName}) as webix.ui.text;
                if(field) {
                    webix.html.addCss(field.getNode(), "mandatory");
                }
            }
        });
    }

    collectAllFields():string[] {
        const fieldsArr = [];
        const form: webix.ui.form = $$(this.formId) as webix.ui.form;
        const values = form.getValues();
        Object.keys(values).forEach(field => {
            fieldsArr.push(field);
        });
        return fieldsArr
    }


    submitProjectAsPm(): void {
        const form: webix.ui.form = $$(this.formId) as webix.ui.form;
        if (form.validate({hidden:false, disabled:false})) {
            this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
            const formValues = form.getValues({
                hidden: false,
                disabled: false,
            });
            const valuesToSubmit = {
                ...formValues
            };
            if(!valuesToSubmit.start_date) {
                valuesToSubmit.start_date = "";
            }
            if(!valuesToSubmit.end_date) {
                valuesToSubmit.end_date = "";
            }
            valuesToSubmit.customer_contact_id = valuesToSubmit.company_contact_id;
            if(!this.commentModified) {
                valuesToSubmit.description = "";
            }
            const additionBillingInfo = formValues.additional_billing_info;
            WebixPortalTranslationService.postTransProject({
                requestBody:valuesToSubmit as unknown as ITransProjectBody
            })
            .then((newProject:ITransProject ) => {
                const skillCombo = webix.$$("npSkill") as webix.ui.combo;
                const skillIds = skillCombo.getValue();
                if(skillIds) {
                    const skillIdsArr = skillIds.split(",");
                    const skilldsIdsArrFixed = skillIdsArr.map(skill => Number(skill));
                    WebixPortalFreelancerService.postObjectSkill({
                        objectId:newProject.project.id,
                        skillIds:skilldsIdsArrFixed
                    })
                    .then(() => {
                        if (additionBillingInfo) {
                            CognovisRestNotesService.postNote({
                                objectId:newProject.project.id,
                                requestBody:{
                                    note_type_id:IntranetNotesType.FINANCIAL_INFORMATION,
                                    note:additionBillingInfo
                                }
                            })
                            .then(() => {
                                this.cognovisPleaseWaitWindow.hide();
                                CognovisNavigator.goToObjectDetails(newProject.project.id, "im_project");
                            });
                        } else {
                            this.cognovisPleaseWaitWindow.hide();
                            CognovisNavigator.goToObjectDetails(newProject.project.id, "im_project"); 
                        }
                    });
                } else {
                    if (additionBillingInfo) {
                        CognovisRestNotesService.postNote({
                            objectId:newProject.project.id,
                            requestBody:{
                                note_type_id:IntranetNotesType.FINANCIAL_INFORMATION,
                                note:additionBillingInfo
                            }
                        })
                        .then(() => {
                            this.cognovisPleaseWaitWindow.hide();
                            CognovisNavigator.goToObjectDetails(newProject.project.id, "im_project"); 
                        });
                    } else {
                        this.cognovisPleaseWaitWindow.hide();
                        CognovisNavigator.goToObjectDetails(newProject.project.id, "im_project");  
                    }
                }
            })
            .catch(() => {
                this.cognovisPleaseWaitWindow.hide();
                //CognovisNavigator.navigateTo(`/main/project-overview.projects`);
                
            });
        } else {
            //(this.showErrorMessagePopup.getRoot() as webix.ui.popup).show();
        }
    }

    submitProjectAsCustomer(): void {
        const form: webix.ui.form = $$(this.formId) as webix.ui.form;
        if (form.validate({hidden:false, disabled:false})) {
            this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
            const formValues = form.getValues({
                hidden: false,
                disabled: false
            });
            const additionBillingInfo = formValues.additional_billing_info;
            const keyAccountId = webix.storage.session.get("key_account_id");
            const valuesToSubmit = {
                ...formValues,
                company_id: localStorage.getItem("logged_user_company_id") as unknown as number,
                company_contact_id:this.currentUserId,
                project_lead_id:keyAccountId
            };
            WebixPortalTranslationService.postTransProject({
                requestBody:valuesToSubmit
            })
            .then((newProject:ITransProject) => {
                //const newProjectId = newProject.project.id;
                if(this.alreadyUploadedFiles.length > 0) {
                    const newProjectFolderId = newProject.project_folder.id;
                    CognovisRestFileService.getCrFolders({
                        folderId:newProjectFolderId
                    })
                    .then(folders => {
                        const originalFolderId = folders.find(folder => folder.folder.name === "Original")?.folder.id;
                        this.alreadyUploadedFiles.map((file, index) => {
                            CognovisRestFileService.putCrFile({
                                fileId:file.file_id,
                                parentId:originalFolderId
                            })
                            .then(() => {
                                if (additionBillingInfo) {
                                    CognovisRestNotesService.postNote({
                                        objectId:newProject.project.id,
                                        requestBody:{
                                            note_type_id:IntranetNotesType.FINANCIAL_INFORMATION,
                                            note:additionBillingInfo
                                        }
                                    })
                                    .then(() => {
                                        if(index === (this.alreadyUploadedFiles.length-1)) {
                                            this.cognovisPleaseWaitWindow.hide();
                                            webix.alert({
                                                title:i18nHelper.getTranslation("Success!"),
                                                ok:"Ok",
                                                text:i18nHelper.getTranslation("Thank_you_for_your_enquiry"),
                                            })
                                            .then(function() {
                                                CognovisNavigator.navigateTo(`main/project-overview.request`);
                                            });
                                        }
                                    })
                                } else {
                                    if(index === (this.alreadyUploadedFiles.length-1)) {
                                        this.cognovisPleaseWaitWindow.hide();
                                        webix.alert({
                                            title:i18nHelper.getTranslation("Success!"),
                                            ok:"Ok",
                                            text:i18nHelper.getTranslation("Thank_you_for_your_enquiry"),
                                        })
                                        .then(function() {
                                            CognovisNavigator.navigateTo(`main/project-overview.request`);
                                        });
                                    }
                                }
                            });
                        });
                    });
                } else {
                    this.cognovisPleaseWaitWindow.hide();
                    this.alreadyUploadedFiles = [];
                    if (additionBillingInfo) {
                        CognovisRestNotesService.postNote({
                            objectId:newProject.project.id,
                            requestBody:{
                                note_type_id:IntranetNotesType.FINANCIAL_INFORMATION,
                                note:additionBillingInfo
                            }
                        })
                        .then(() => {
                            webix.alert({
                                title:i18nHelper.getTranslation("Success!"),
                                ok:"Ok",
                                text:i18nHelper.getTranslation("Thank_you_for_your_enquiry"),
                            })
                            .then(function() {
                                CognovisNavigator.navigateTo(`main/project-overview.request`);
                            }); 
                        });
                    } else {
                        webix.alert({
                            title:i18nHelper.getTranslation("Success!"),
                            ok:"Ok",
                            text:i18nHelper.getTranslation("Thank_you_for_your_enquiry"),
                        })
                        .then(function() {
                            CognovisNavigator.navigateTo(`main/project-overview.request`);
                        }); 
                    }
                }
            })
            .catch(() => {
                this.cognovisPleaseWaitWindow.hide();
                //CognovisNavigator.navigateTo(`/main/project-overview.projects`);
            });
        } else {
            //(this.showErrorMessagePopup.getRoot() as webix.ui.popup).show();
        }
    }

    getProperLayout(formFieldsFirstColumn:webix.ui.layoutConfig[], formFieldsSecondColumn:webix.ui.layoutConfig[]):webix.ui.layoutConfig {
        const defaultComment = i18nHelper.getTranslation("new_offer_default_comment");
        let layout;
        const mergedFields = [...formFieldsFirstColumn,...formFieldsSecondColumn];
        const sortedMergedFields = mergedFields.sort((a,b) => (a["sortOrder"] > b["sortOrder"]) ? 1 : ((b["sortOrder"] > a["sortOrder"]) ? -1 : 0));
        if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
            layout = 
                {
                    rows:[
                        {
                        view:"layout",
                        cols:
                        [
                            {
                                view: "layout",
                                gravity:45,
                                type: "clean",
                                rows: sortedMergedFields
                            },
                            {
                                view:"spacer",
                                gravity:5
                            },
                            {
                                view: "layout",
                                gravity:45,
                                borderless:false,
                                rows: [
                                    {
                                        view:"ckeditor5",
                                        id:"npComment",
                                        name:"description",
                                        localId:"commentsCkEditor5",
                                        height:240,
                                        mode:"document",
                                        css:{
                                            "overflow-y":"scroll !important"
                                        },
                                        borderless:false,
                                        value:defaultComment
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        view:"layout",
                        rows:[
                            {
                                view:"spacer",
                                height:55
                            },
                            {
                                view: "button",
                                css:"cog-button-big",
                                name:"submit_button",
                                id: "npAddProject",
                                value:function() {
                                    if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
                                        return i18nHelper.getTranslation("Request_for_quote");
                                    } else {
                                        return i18nHelper.getTranslation("add_project");
                                    }
                                }(),
                                height: 35,
                                click: () => {
                                    if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
                                        this.submitProjectAsCustomer();
                                    } else {
                                        this.submitProjectAsPm();
                                    }
                                }
                            }
                        ]
                    }
                ]
            }  
        } else {
            const middleIndex = Math.ceil(sortedMergedFields.length / 2);
            const formFieldsFirstColumn  = sortedMergedFields.splice(0, middleIndex);   
            const formFieldsSecondColumn = sortedMergedFields.splice(-middleIndex);
            layout = 
                {
                    rows:[
                        {
                        view:"layout",
                        cols:
                        [
                            {
                                view: "layout",
                                gravity:40,
                                type: "clean",
                                rows: formFieldsFirstColumn 
                            },
                            {
                                view:"spacer",
                                gravity:10
                            },
                            {
                                view: "layout",
                                type: "clean",
                                gravity:40,
                                rows: formFieldsSecondColumn
                            },
                        ]
                    },
                    {
                        view:"layout",
                        rows:[
                            {
                                view:"spacer",
                                height:15
                            },
                            {
                                view:"ckeditor5",
                                id:"npComment",
                                name:"description",
                                localId:"commentsCkEditor5",
                                height:190,
                                css:{
                                    "overflow-y":"scroll !important"
                                },
                                mode:"document",
                                value:defaultComment
                            },
                            {
                                view:"spacer",
                                height:15
                            },
                            {
                                view: "button",
                                css:"cog-button-big",
                                name:"submit_button",
                                id: "npAddProject",
                                value:function() {
                                    if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
                                        return i18nHelper.getTranslation("Request_for_quote");
                                    } else {
                                        return i18nHelper.getTranslation("add_project");
                                    }
                                }(),
                                height: 35,
                                click: () => {
                                    if(CognovisProfile.isUserInGivenGroup(UserProfile.CUSTOMER)) {
                                        this.submitProjectAsCustomer();
                                    } else {
                                        this.submitProjectAsPm();
                                    }
                                }
                            },
                            {
                                view:"spacer",
                                height:20
                            },
                        ]
                    }
                ]
            }   
        }
        // Otherwise we decide on two column layout
        return layout;
    }

    openFileModal(objectId:number):void {
        if(!this.fileUploaderModal) {
            this.fileUploaderModal = this.ui(CognovisFileUploaderModal) as CognovisFileUploaderModal;
        }
        const filesTranslation = i18nHelper.getTranslation("Files");
        const modalTitle = `${filesTranslation}`;
        this.fileUploaderModal.openModal(objectId, "write", {allowFolderSelection:false}, modalTitle, "", (uploadedFilesArr) => {
            this.alreadyUploadedFiles = uploadedFilesArr;
        });
    }

}
