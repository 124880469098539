import { IGroup, IUser } from "../openapi";

export class UserStateManager {
    static currentlyLoggedUser : {
        id:number,
        userData?:IUser,
        userGroups?:IGroup[]
    } = {
        id:-1,
        userGroups:[],
        userData:{}
    };
    static realUserId:number;
    static fakingMode = false;

    static getCurrentlyLoggedUser():{id:number} {
        if(UserStateManager.currentlyLoggedUser.id === -1) {
            const loginInfo = webix.storage.local.get("wjet_user");
            const fakedUserObj = webix.storage.session.get("cognovis_faked_user_data");
            let valueUserId = loginInfo.user_id;
            UserStateManager.realUserId = valueUserId;
            if (fakedUserObj) {
                if(UserStateManager.realUserId !== fakedUserObj.user_id) {
                    UserStateManager.fakingMode = true;
                }
                valueUserId = fakedUserObj.user_id;
            }
            UserStateManager.setCurrentUserId(valueUserId);
        }
        // Output full user object to browser console
        return UserStateManager.currentlyLoggedUser;
    }

    static setCurrentUserId(userId:number):void {
        UserStateManager.currentlyLoggedUser.id = userId;
    }

    static getCurrentUserGroups():IGroup[] {
        return UserStateManager.currentlyLoggedUser.userGroups;
    }

    static setCurrentUserGroups(groups:IGroup[]):void {
        UserStateManager.currentlyLoggedUser.userGroups = groups;
    }

    static setCurrentUserData(userData:IUser):void {
        // userData.portrait_url = FileHelper.getImageUrl(userData.portrait_url);
        UserStateManager.currentlyLoggedUser.userData = userData;
    }

    static getCurrentUserData():IUser {
        return UserStateManager.currentlyLoggedUser.userData;
    }
    
}