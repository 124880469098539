import { WebixPortalFreelancerService, WebixPortalTranslationService } from "../../../sources/openapi";
import { IMainTranslator } from "../../../sources/openapi";
import { IJetApp, JetView } from "webix-jet";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import MainTranslatorDetailsModal from "./main-translator-details-modal";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { CognovisNavigator } from "../../modules/cognovis-navigator/cognovis-navigator";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";


export default class MainTranslatorsPortlet extends CognovisBasicPortlet  {

    idPrefix = "mtp";
    mode:IMainTranslatorsPortletMode;
    title:string;
    mainTranslators:IMainTranslator[];
    objectId:number;
    objectName:string;
    params:{[key: string]: number};
    mainTranslatorDetailsModal:MainTranslatorDetailsModal;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    portletId:number;

    constructor(app:IJetApp, objectId:number, objectName:string, mode:IMainTranslatorsPortletMode, portletId?:number, title?:string) {
        super(app, {});
        this.mode = mode;
        this.objectId = objectId;
        this.objectName = objectName;
        this.portletId = portletId;
        if(title) {
            this.title = title;
        } else {
            this.title = i18nHelper.getTranslation("Main translator");
        }
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
    }


    init():void {
        super.init();
        let params;
        if(this.mode === "im_company") {
            params = {
                customerId:this.objectId
            }
        }
        if(this.mode === "user") {
            params = {
                freelancerId:this.objectId
            }
        }
        this.params = params;
        this.getMainTranslators()
        .then(res => {
            this.mainTranslators = res;
            const layout = {
                view:"layout",
                rows:[
                    {
                        view:"layout",
                        autoheight:true,
                        minHeight:600,
                        autowidth:true,
                        gravity:1,
                        rows:[this.getMainTranslatorsTable(this.mainTranslators)]
                    }
                ]
            }
            this.components = [layout];
            this.insertComponents();
        });

    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            height:44,
            css: "cog-box-header", 
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: this.title, 
                    borderless: true 
                },
                { 
                    view:"button", 
                    type:"icon", 
                    css:"cog-icon-dark",
                    localId:`${this.idPrefix}AddMainTranslatorButton`,
                    width:24, 
                    icon:"fal fa-plus", 
                    align:"right",
                    click:() => {
                        if(!this.mainTranslatorDetailsModal) {
                            this.mainTranslatorDetailsModal = this.ui(MainTranslatorDetailsModal) as MainTranslatorDetailsModal;
                        }
                        this.mainTranslatorDetailsModal.openModal(this.objectId, this.objectName, this.mode, () => {
                            this.refreshMainTranslatorsData();
                        });                 
                    }
                }
            ]
        };
        return header;
    }

    getMainTranslatorsTable(mainTranslators:IMainTranslator[]):webix.ui.datatableConfig {
        return {
            view:"datatable",
            id:`${this.idPrefix}MainTranslatorsTable`,
            data:mainTranslators,
            columns:([
                {
                    id:"mappingId",
                    map:"#mapping_id#",
                    hidden:true
                },
                {
                    id:"mainTranslatorName",
                    fillspace:true,
                    header: [i18nHelper.getTranslation("Freelancer"),  { content:"multiSelectFilter"}],
                    hidden:this.mode === "user",
                    map:"#freelancer.name#",
                    sort:"text"
                },
                {
                    id:"customerName",
                    fillspace:true,
                    header: [i18nHelper.getTranslation("Customer"),  { content:"multiSelectFilter"}],
                    hidden:this.mode === "im_company",
                    map:"#customer.name#",
                    sort:"text"
                },
                {
                    id:"sourceLanguag",
                    header: [i18nHelper.getTranslation("Source_language"),  { content:"multiSelectFilter"}],
                    map:"#source_language.name#",
                    sort:"text"
                },
                {
                    id:"targetLanguage",
                    header: [i18nHelper.getTranslation("Target_language"),  { content:"multiSelectFilter"}],
                    map:"#target_language.name#",
                    sort:"text"
                },
                {  
                    name:"actions-edit",
                    width:32,
                    tooltip:false,
                    template:() => {
                        return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-edit'></span>`;
                    }
                },
                {  
                    name:"actions-delete",
                    width:32,
                    tooltip:false,
                    template:() => {
                        return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-trash'></span>`;
                    }
                }
            ]) as unknown as webix.WebixDatatableColumn[],
            onClick:{
                "fa-trash":(event, row) => {
                    const table = webix.$$(`${this.idPrefix}MainTranslatorsTable`) as webix.ui.datatable;
                    const item = table.getItem(row);
                    const confirmWarning = i18nHelper.getTranslation("lt_sure_remove_translator") + `<br/>`;
                    webix.confirm({
                        title: i18nHelper.getTranslation("any_are_you_sure_message"),
                        type: "confirm-warning",
                        text: confirmWarning,
                        width: 480
                    })
                    .then(() => {
                        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                        WebixPortalFreelancerService.deleteMainTranslator({
                            mappingId:item.mappingId
                        })
                        .then(() => {
                            this.refreshMainTranslatorsData();
                        })
                        .catch(err => {
                            webix.alert({
                                title: err.message,
                                text: err.body.message,
                                type: "alert-error",
                                width: 500,
                                css:"cog-remove-tasks-modal"
                            });
                        })
                        .finally(() => {
                            this.cognovisPleaseWaitWindow.hide();
                        });
                    });
                }
            },
            on: {
                onItemClick:function(row, event)  {
                    const item = this.getItem(row);
                    if(row.column === "customerName") {
                        if(item.customer?.id) {
                            CognovisNavigator.goToObjectDetails(item.customer?.id, "im_company");
                        }
                    }
                    if(row.column === "mainTranslatorName") {
                        if(item.freelancer?.id) {
                            CognovisNavigator.goToObjectDetails(item.freelancer?.id, "user");
                        }
                    }
                },
                onAfterLoad:() => {
                    const table = webix.$$(`${this.idPrefix}MainTranslatorsTable`) as webix.ui.datatable;
                    if(table.serialize().length === 0) {
                        table.showOverlay(i18nHelper.getTranslation("No data"));
                    }
                }
            }
        }
    }

    refreshMainTranslatorsData():void {
        const table = webix.$$(`${this.idPrefix}MainTranslatorsTable`) as webix.ui.datatable;
        if(table) {
            table.clearAll();
            webix.extend(table, webix.ProgressBar);
            (table as webix.ui.datatable & {showProgress:(info:string) => void}).showProgress(i18nHelper.getTranslation("Please_wait"));
            this.getMainTranslators()
            .then(result => {
                table.define("data", result);
                table.refresh();
                table.hideOverlay();
            });
        }
    }

    getMainTranslators():Promise<IMainTranslator[]> {
        return WebixPortalFreelancerService.getMainTranslators(this.params)
        .then(mainTranslators => {
            return mainTranslators
        });
    }

}