/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetTmTool {
    /**
     * Trados 9.0
     */
    TRADOS_STUDIO_2009 = 10000068,
    /**
     * Trados Studio 2011
     */
    TRADOS_STUDIO_2011 = 10000069,
    /**
     * memoQ
     */
    MEMOQ = 10000308,
    /**
     * Trados Studio 2014
     */
    TRADOS_STUDIO_2014 = 10000379,
    /**
     * Trados Studio 2015
     */
    TRADOS_STUDIO_2015 = 10000534,
    /**
     * Trados Studio 2017
     */
    TRADOS_STUDIO_2017 = 10000535,
    /**
     * Bilingual Word Files
     */
    BILINGUAL_WORD_FILES = 10000536,
    /**
     * Trados Studio 2019
     */
    MEMSOURCE = 10000639,
    TRADOS_STUDIO_2019 = 10000763,
    TRADOS_STUDIO_2021 = 10000795,
    CAFETRAN_ESPRESSO = 10000804,
}
