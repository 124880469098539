import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import { CognovisRestCompanyService, IBizObjectMember, IntranetCompanyStatus, IntranetCompanyType } from "../../openapi";
import { container } from "tsyringe";


export default class UserPickerModal extends CognovisBasicModal {

    idPrefix = "upm";
    objectId:number;
    modalTittle:string;
    defaultModalTitle = i18nHelper.getTranslation("Add user");
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    getUsersAction:<T>(objectId:number) => Promise<T[]>;
    afterSaveAction:(member:{id:number,value:string}) => void

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 470, 200);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(objectId:number, modalTittle?:string, getUsersAction?:<T>(objectId:number) => Promise<T[]>, afterSaveAction?:(member:{id:number,value:string}) => void):void {
        if(modalTittle) {
            this.modalTittle = modalTittle;
        } else {
            this.modalTittle = this.defaultModalTitle;
        }
        if(getUsersAction) {
            this.getUsersAction = getUsersAction;
        }
        if(afterSaveAction) {
            this.afterSaveAction = afterSaveAction;
        }
        this.objectId = objectId;
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const modalContent = this.getContent();
        const modalTitle = i18nHelper.getTranslation("")
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTittle);
        this.cognovisPleaseWaitWindow.hide();          
    }

    getContent():webix.ui.layoutConfig {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}LoggedHoursModal`,
                        rows:[
                            {
                                view: "combo",
                                id: `${this.idPrefix}CompanySelector`,
                                label:i18nHelper.getTranslation("customer"),
                                labelWidth:200,
                                placeholder:i18nHelper.getTranslation("customer"),
                                name: "company_id",
                                disabled:false,
                                suggest: {
                                    body: {
                                        url: () => {
                                            return CognovisRestCompanyService.getCompanies({
                                                companyStatusIds: [IntranetCompanyStatus.ACTIVE],
                                                companyTypeIds: [IntranetCompanyType.CUSTOMER]
                                            })
                                            .then(companyInfo => {
                                                const customerOptionsForCombo = companyInfo.map(company => ({id:company.company.id, value:company.company.name}))
                                                return customerOptionsForCombo;
                                            });
                                        }
                                    },
                                },
                                on: {
                                    onChange: (companyId) => {
                                        this.getUsersAction<IBizObjectMember>(companyId)
                                        .then((possibleContacts) => {
                                            //const possibleContactsForCombo = this.webixHelpers.prepareDataForCombo(possibleContacts,"user_id","full_name",["email"]);
                                            const possibleContactsForCombo = possibleContacts.map(contact => ({id:contact.member.user.id, value:contact.member.user.name}));
                                            const customerContactCombo = webix.$$(`${this.idPrefix}UserSelector`) as webix.ui.combo;
                                            customerContactCombo.define("options", possibleContactsForCombo);
                                            console.log(possibleContactsForCombo[0]);
                                            if(possibleContactsForCombo[0]) {
                                                customerContactCombo.setValue(possibleContactsForCombo[0].id.toString());
                                            }
                                        });
                                    },
                                    onAfterRender:() => {
                                        if(this.objectId) {
                                            const customerCombo = webix.$$(`${this.idPrefix}CompanySelector`) as webix.ui.combo;
                                            customerCombo.setValue(this.objectId.toString());
                                        }
                                    }
                                } 
                            },
                            {
                                view: "combo",
                                id: `${this.idPrefix}UserSelector`,
                                label: i18nHelper.getTranslation("customer_contact"),
                                labelWidth:200,
                                placeholder: i18nHelper.getTranslation("customer_contact"),
                                name: "customer_contact_id",
                                options: [],
                                on: {
                                    onChange: (e) => {
                                        // Changing customer email ones the contact value changes
                                        const emailCombo = (webix.$$("npCustomerEmail") as webix.ui.combo);
                                        const customerCombo = (webix.$$(`${this.idPrefix}CompanySelector`) as webix.ui.combo);
                                        const customerComboAdditionalData = customerCombo["additionalData"];
                                        if (typeof customerComboAdditionalData !== "undefined") {
                                            customerComboAdditionalData.map(contact => {
                                                if (contact.id == e) {
                                                        emailCombo.setValue(contact.email);
                                                }
                                            });
                                        }
                                    },
                                }
                            },
                        ]
                    },                    
                ]
            }
        };
        return layout
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        {
                            view:"button",
                            value:`${i18nHelper.getTranslation(`Save contact`)}`,
                            click:() => {
                                if(this.afterSaveAction) {
                                    const contactField = webix.$$(`${this.idPrefix}UserSelector`) as webix.ui.combo;
                                    const newContact = {
                                        id:Number(contactField.getValue()),
                                        value:contactField.getText()
                                    };
                                    this.afterSaveAction(newContact);
                                }
                            }
                        }
                    ] 
                };
        return buttons;
    }

    closeModal():void {
        this.hide();
    }
    
}