import { IAssignment } from "../../../../openapi";
import CognovisBasicModal from "../../../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../../modules/i18n-helper/i18n-helper";

export default class AssignmentRatingDetails extends CognovisBasicModal {

    currentAssignment:IAssignment;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Rating Details`), 800, 620);
        return mainLayout;
    }

    openModal(additionalData:ICognovisModalAdditionlData):void {
        const cognovisBasicModal = this.$$("assignmentRatingDetailsModal") as webix.ui.window;
        if(cognovisBasicModal) {
            cognovisBasicModal.show();
        } else {
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
        }
        const actionButtons = this.getActionButtons();
        const assignment = additionalData.assignment as IAssignment;
        this.currentAssignment = assignment;  
        const modalContent = this.getContent(this.currentAssignment);
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, this.currentAssignment.assignment.name);
    }


    closeModal():void {
        this.close();
    }

    getContent(assignment:IAssignment):webix.ui.layoutConfig {
        const ratingTotal = assignment["rating_value"];
        const ratingObj = assignment["rating"];
        const ratingComment = assignment["rating_comment"];
        const ratingTable = [];
        ratingObj.map(rating => {
            ratingTable.push({
                cols:[
                {
                    view:"label",
                    label:rating.quality_type.name,
                    width:163,
                    height:30,
                    borderless:true,
                },
                {
                    view:"label",
                    label:rating.quality_level.name,
                    width:163,
                    height:30,
                    borderless:true,
                },  
                {
                    view:"label",
                    label:rating.quality_value,
                    width:163,
                    height:30,
                    borderless:true
                },   
                ]        
            });
        });
        const modal = {
                view:"layout",
                padding:20,
                localId:"assignmentRatingDetailsModal",
                rows:[
                    {
                        borderless:true,
                        height:40,
                        template:() => {
                            return `<p><b>Total</b>: ${ratingTotal.toFixed(2)}`
                        }
                    },
                    {
                        borderless:true,
                        height:120,
                        template:() => {
                            return `<p><b>Comment</b>:<br/> ${ratingComment}`
                        }
                    },
                    {
                        height:190,
                        width:500,
                        borderless:true,
                        view:"layout",
                        rows:ratingTable
                    },
                    {
                        view:"layout",
                        cols:[
                            {
                                view:"button",
                                css:"cog-button-big",
                                inputWidth:120,
                                value:i18nHelper.getTranslation("Edit ratings"),
                                click:() => {
                                    const freelancerId = this.currentAssignment.assignee.id;
                                    const url = `/#!/freelancer-rating-overview?freelancer_id=${freelancerId}`;
                                    window.open(url, '_blank').focus();
                                }
                            }
                        ]
                    },
                ]
        }
        return modal;
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    rows:[

                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`, 
                            align: "left",
                            click:() => {
                                this.close();
                            }
                        }
                    ] 
                };
        return buttons;
    }

}