import { config } from "../../../sources/config";
import { IJetApp, JetView } from "webix-jet";
import { CognovisRestDynamicService, IDynamicAttribute, IIdValue } from "../../openapi";
import CognovisProfile from "../cognovis-profile/cognovis-profile";
import { UserProfile } from "../cognovis-profile/profiles-types";
import { i18nHelper } from "../i18n-helper/i18n-helper";
import DynfieldEditionModal from "./dynfield-edition-modal";
import { DatetimeHelper } from "../datetime-helpers/datetime-helpers";


export default class DynfieldsBasicLayout extends JetView {

    idPrefix = "dbl";
    
    dynfieldsConfig:{objectType:string, pageUrl:string, objectId?:number, groupDynfields?:boolean, customTemplates?:{field:string, template:string | ((id:number, value:string) => string)}[]};
    additionalTopLayoutElements:webix.ui.baseviewConfig = {}
    additionalBottomLayoutElements:webix.ui.baseviewConfig = {};
    title:string;
    sectionHeaders:string[];
    editModalTitle:string;
    dynfields:IDynamicAttribute[];
    objectId:number;
    objectData:unknown;
    predefinedFields:webix.ui.baseviewConfig[] = [];
    editable = false;
    dynfieldEditionModal:DynfieldEditionModal;
    submitAction:(data:unknown) => void;
    afterSubmitAction:() => void;
    portletId:number;
    customTemplates:{field:string, template:string | ((id:number, value:string) => string)}[];
    preventDisplayValue = [
        "address_postal_code",
        "collmex_id"
    ];

    constructor(app:IJetApp, objectId:number, portletPrefix:string, dynfieldsConfig:{objectType:string, pageUrl:string, objectId?:number, groupDynfields?:boolean, portletId?:number, customTemplates?:{field:string, template:string | ((id:number, value:string) => string)}[]}, title?:string, editModalTitle?:string, submitAction?:(data:unknown) => void, afterSubmitAction?:() => void) {
        super(app, {});
        this.objectId = objectId;
        this.dynfieldsConfig = dynfieldsConfig;
        this.idPrefix = `${this.idPrefix}${portletPrefix}`;
        if(title) {
            this.title = title;
        }
        if(dynfieldsConfig.portletId) {
            this.portletId = dynfieldsConfig.portletId;
        }
        if(editModalTitle) {
            this.editModalTitle = editModalTitle;
        }
        if(submitAction) {
            this.submitAction = submitAction;
        }
        if(this.afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
    }
    

    showLoader():void {
        const portlet = webix.$$(`${this.idPrefix}DynfieldContainer`) as webix.ui.layout;
        if(portlet) {
            webix.extend(portlet,webix.ProgressBar);
            (portlet as any).showProgress(i18nHelper.getTranslation("Please_wait"));
        }
       
    }

    hideLoader():void {
        const portlet = webix.$$(`${this.idPrefix}DynfieldContainer`) as webix.ui.layout;
        if(portlet) {
            webix.extend(portlet,webix.ProgressBar);
            (portlet as any).hideProgress();
        }
    }

    getAdminHeaderIcons(portletId:number):webix.ui.buttonConfig {
        if(portletId && CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN)) {
            return {
                view: "button", 
                type: "icon", 
                icon: "far fa-gear", 
                align: "right", 
                css:"action-icon-grey",
                width: 28, 
                borderless:true,
                click:(element, event) => {
                    const url = `${config.webixUrl}/portlet?portlet_id=${portletId}`;
                    window.open(url,'_blank');
                }
            } as unknown as webix.ui.buttonConfig
        } else {
            return {
                view:"spacer",
                width:1
            }
        }
    }

    prepareData(values:IIdValue[]):{} {
        const parsedValues = {};
        values.map(value => {
            parsedValues[value["id"]] = value["value"];
            parsedValues[`${value["id"]}_display`] = "";
            if((value["display_value"] && value["display_value"].length > 0) && this.preventDisplayValue.indexOf(value.id) === -1 ) {
                parsedValues[`${value["id"]}_display`] = value["display_value"];
            } 
        });
        return parsedValues
    }

    addIcons():void {
        const icons = this.getAdminHeaderIcons(this.portletId);
        const rootView = this.getRoot() as webix.ui.layout;
        if(rootView) {
            const headerCmp:webix.ui.baseview = rootView.queryView({type:"header"});
            const parentHeaderCmp = headerCmp.getParentView();
            (parentHeaderCmp as webix.ui.headerlayout).addView(icons);
        }
    }

    getDynfields(objectType:string, pageUrl:string, objectId?:number):Promise<IDynamicAttribute[]> {
        let params = {
            objectType:objectType,
            pageUrl:pageUrl
        }
        if(this.dynfieldsConfig.objectId) {
            params["objectId"] = this.dynfieldsConfig.objectId;
        }
        if(objectId) {
            params["objectId"] = objectId;
        }
        return CognovisRestDynamicService.getDynamicAttributes(params)    
        .then(dynfields => {
            const sortedBySortOrder = dynfields.sort((a,b) => (a["sort_order"] > b["sort_order"]) ? 1 : ((b["sort_order"] > a["sort_order"]) ? -1 : 0))
            this.dynfields = sortedBySortOrder;
            // Useful
            const fakeSectionHeadings = ["Personal Data", "Address", "Other"];
            this.dynfields = this.dynfields.map(field => {
                const random = Math.floor(Math.random() * 3);
                field.dynamic_subtypes.map(subtpye => {
                    subtpye.section_heading = fakeSectionHeadings[random];
                });
                return field;
            });
            if(this.dynfieldsConfig.groupDynfields) {
                this.dynfields = this.groupFields(this.dynfields);
            }
            return this.dynfields
        });
    }

    getCoreLayout():webix.ui.layoutConfig {
        return {
            view:"layout",
            rows:[
                this.getHeader(), 
                {
                    view:"layout",
                    id:`${this.idPrefix}DynfieldContainer`,
                    css:"cog-box-content",
                    minheight:400,
                    padding:13,
                    rows:[
                    ]
                }
            ],
            on: {
                onAfterShow:() => {
                    setTimeout(() => {
                        this.showLoader();
                    },100);
                }
            }
        } as unknown as webix.ui.layoutConfig
    }

    insertComponents():void {
        this.hideLoader();
        let fields = this.buildFields();
        if(this.dynfieldsConfig.groupDynfields) {
            fields = this.buildGrouppedFields();
        }
        const container = webix.$$(`${this.idPrefix}DynfieldContainer`) as webix.ui.layout;
        if(container) {
            container.config.minHeight = 0;
            const rows = [
                this.getTopAdditionalLayoutElements(),
                ...fields,
                this.getBottomAdditionalLayoutElements()   
            ];
            const layout = {
                view:"layout",
                id:`${this.idPrefix}Dynfields`,
                rows:rows
            }
            container.addView(layout);
            this.injectData();
            this.setEditMode();   
            this.addIcons();
        }
    }

    getTopAdditionalLayoutElements():webix.ui.baseviewConfig {
        if(this.additionalTopLayoutElements) {
            return this.additionalTopLayoutElements;
        }
    }

    setTopAdditionalLayoutElements(additionaToplLayoutElemements:webix.ui.baseviewConfig):void {
        this.additionalTopLayoutElements = additionaToplLayoutElemements;
    }

    getBottomAdditionalLayoutElements():webix.ui.layoutConfig {
        if(this.additionalBottomLayoutElements) {
            return this.additionalBottomLayoutElements;
        }
    }

    setBottomAdditionalLayoutElements(additionalBottomLayoutElemements:webix.ui.layoutConfig):void {
        this.additionalBottomLayoutElements = additionalBottomLayoutElemements;
    }

    getHeader():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:44,
            css: "cog-box-header", 
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    id:`${this.idPrefix}Header`,
                    template: this.title, 
                    borderless: true 
                },
                { 
                    view: "button", 
                    id:`${this.idPrefix}EditButton`,
                    hidden:!this.editable,
                    type: "icon", 
                    icon: "far fa-edit", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true ,
                    click:() => {
                        if(!this.dynfieldEditionModal) {
                            this.dynfieldEditionModal = this.ui(DynfieldEditionModal) as DynfieldEditionModal;
                        }
                        this.dynfieldEditionModal.openModal(this.objectId, this.dynfields, this.predefinedFields, this.dynfieldsConfig, this.objectData, this.editModalTitle, this.submitAction, () => {
                            this.insertComponents();
                        });
                    }
                }
            ]
        };
    }

    executeSubtypesFiltering(dynfields:IDynamicAttribute[], neededDisplayMode:string):IDynamicAttribute[] {
        // We need to loop thru all subtypes of all dynfields to decide wheter such type of object should have such field.
        let filteredDynfields:IDynamicAttribute[] = [];
        switch(this.dynfieldsConfig.objectType) {
            default:
                dynfields.map(dynfield => {
                    let addFlag = false;
                    dynfield.dynamic_subtypes.map(subtype => {
                        if(this.hasProperDisplayMode(neededDisplayMode,subtype.display_mode)) {
                            addFlag = true;
                        }
                    });
                    if(addFlag) {
                        filteredDynfields.push(dynfield);
                    }
                });
                break;
        }
        return filteredDynfields
    }

    getObjectData(objectId:number):Promise<{[key: string]: string | number}> {
        return CognovisRestDynamicService.getDynamicObject({
            objectId:objectId,
            pageUrl:this.dynfieldsConfig.pageUrl
        })
        .then(data => {
            return this.prepareData(data);
        });
    }
    
    // This one is not used at the moment, but I'm not removing it, maybe will come in handy one day :)
    roleGroupMap(key:number):UserProfile {
        const map = {
            22060:UserProfile.PROJECT_MANAGER,
            22050:UserProfile.FREELANCER,
            22040:UserProfile.EMPLOYEE,
            22030:UserProfile.CUSTOMER,
            22020:UserProfile.PO_ADMIN,
            22110:UserProfile.FREELANCER_MANAGER,
            22100:UserProfile.HR_MANAGER,
            22090:UserProfile.SALES,
            22080:UserProfile.ACCOUNTING,
            22070:UserProfile.SENIOR_MANAGER,
            29059:UserProfile.HELPDESK
        }
        return map[key];
    }

    hasProperDisplayMode(neededDisplayMode:string, subtypeDisplayMode:string):boolean {
        let final = false;
        switch(neededDisplayMode) {
            case "none":
                break;
            case "display":
                if(subtypeDisplayMode === "display" || subtypeDisplayMode === "edit") {
                    final = true;
                }
                break;
            case "edit":
                if(subtypeDisplayMode === "edit") {
                    final = true;
                }
                break;
            default:
                break;
        }
        return final
    }

    buildFields():webix.ui.baseviewConfig[] {
        const fieldsArr = [];
        // First we execute subtypes filtering
        this.dynfields = this.executeSubtypesFiltering(this.dynfields, "display");
        this.dynfields.map(field => {
            if(field.attribute.name && field.attribute.name.length > 0) {
                const newField = this.buildDynfield(field);
                if(newField) {
                    fieldsArr.push(newField);
                }
            }
        });
        this.predefinedFields.map(predefinedField => {
            const newPredefinedField = this.getSingleFieldContainer(predefinedField);
            if(newPredefinedField) {
                fieldsArr.push(newPredefinedField);
            }
        });
        const sorted = fieldsArr.sort((a,b) => (a["sortOrder"] > b["sortOrder"]) ? 1 : ((b["sortOrder"] > a["sortOrder"]) ? -1 : 0))
        return sorted
    }

    buildGrouppedFields():webix.ui.baseviewConfig[] {
        const fieldsArr = [];
        // First we execute subtypes filtering
        this.dynfields = this.executeSubtypesFiltering(this.dynfields, "display");
        this.sectionHeaders.map(sectionName => {
            const newField = this.buildGrouppedDynfield(sectionName);
            if(newField) {
                fieldsArr.push(newField);
            }
        });
        return fieldsArr
    }

    groupFields(fields:IDynamicAttribute[]):IDynamicAttribute[] {
        // First we extract all section headings
        const sectionHeadingsArr = [""];
        fields.map(field => {
            field.dynamic_subtypes.map(subtype => {
                if(sectionHeadingsArr.indexOf(subtype.section_heading) === -1) {
                    sectionHeadingsArr.push(subtype.section_heading);
                }
            });
        });
        // Move first element to last position
        sectionHeadingsArr.push(sectionHeadingsArr.shift());
        this.sectionHeaders = sectionHeadingsArr;
        return fields;
    }

    injectData():void {
        const container = webix.$$(`${this.idPrefix}DynfieldContainer`) as webix.ui.layout;
        const fields = container.queryView({dynfieldValue:true}, "all");
        fields.map(field => {
            let isDatetime = false;
            if(field.config.widgetType === 'datetimepicker') {
                isDatetime = true;
            }
            const fieldAsComponent = field as webix.ui.template;
            if(this.objectData[field.config.dynfieldKey]) {
                if(this.objectData[`${field.config.dynfieldKey}_display`] && this.objectData[`${field.config.dynfieldKey}_display`].length > 0) {
                    if(isDatetime) {
                        this.objectData[`${field.config.dynfieldKey}_display`] = DatetimeHelper.removeTimezone(this.objectData[`${field.config.dynfieldKey}_display`], true);
                    }
                    fieldAsComponent.setHTML(this.objectData[`${field.config.dynfieldKey}_display`]);
                    if(this.dynfieldsConfig.customTemplates && this.dynfieldsConfig.customTemplates.find(cField => cField.field === field.config.dynfieldKey)) {
                        const funcRes = ((this.dynfieldsConfig.customTemplates?.find(cField => cField.field === field.config.dynfieldKey)?.template) as (id:number, value:string) => string);
                        if(funcRes) {
                            const res = funcRes(this.objectData[`${field.config.dynfieldKey}`], this.objectData[`${field.config.dynfieldKey}_display`]);
                            fieldAsComponent.setHTML(res);
                        }
                    }
                } else {
                    if(isDatetime) {
                        this.objectData[`${field.config.dynfieldKey}_display`] = DatetimeHelper.removeTimezone(this.objectData[`${field.config.dynfieldKey}_display`]);
                    }
                    fieldAsComponent.setHTML(this.objectData[field.config.dynfieldKey]);
                    if(this.dynfieldsConfig.customTemplates && this.dynfieldsConfig.customTemplates.find(cField => cField.field === field.config.dynfieldKey)) {
                        const funcRes = ((this.dynfieldsConfig.customTemplates?.find(cField => cField.field === field.config.dynfieldKey)?.template) as (id:number, value:string) => string);
                        if(funcRes) {
                            const res = funcRes(this.objectData[`${field.config.dynfieldKey}`], this.objectData[`${field.config.dynfieldKey}_display`]);
                            fieldAsComponent.setHTML(res);
                        }
                    }
                }  
            } else {
                fieldAsComponent.setHTML("");
                if(this.dynfieldsConfig.customTemplates && this.dynfieldsConfig.customTemplates.length > 0) {
                    const funcRes = ((this.dynfieldsConfig.customTemplates?.find(cField => cField.field === field.config.dynfieldKey)?.template) as (id:number, value:string) => string);
                    if(funcRes) {
                        const res = funcRes(this.objectData[`${field.config.dynfieldKey}`], this.objectData[`${field.config.dynfieldKey}_display`]);
                        fieldAsComponent.setHTML(res);
                    }
                }
            }
        });
    }

    checkForEditableValues():boolean {
        let editable = false;
        let countEditableFields = 0;
        this.dynfields.map(dynfield => {
            dynfield.dynamic_subtypes.map(subtype => {
                if(subtype.display_mode === "edit") {
                    countEditableFields++;
                }
            });
        });
        if(countEditableFields > 0) {
            editable = true;
        }
        return editable
    }

    setEditMode():void {
        const button = webix.$$(`${this.idPrefix}EditButton`) as webix.ui.button;
        if(this.checkForEditableValues()) {
            this.editable = true;
            button.show();
        } else {
            this.editable = false;
            button.hide();
        }
    }

    setData(data:unknown):void {
        this.objectData = data;
    }

    buildDynfield(field:IDynamicAttribute):webix.ui.layoutConfig & {sortOrder:number} {
        return {
            view:"layout",
            sortOrder:field.sort_order,
            rows:[
                {
                    view:"spacer",
                    height:4
                },
                {
                    view:"layout",
                    cols:[
                        {
                            height:40,
                            gravity:0.4,
                            borderless:true,
                            template:function() {
                                return i18nHelper.getTranslationWithKey(field.display_name, field.i18n_key);
                            }
                        },
                        {
                            view:"template",
                            widgetType:field.widget_type,
                            gravity:0.6,
                            id:`${this.idPrefix}Dynfield${field.attribute.id}`,
                            dynfieldValue:true,
                            dynfieldKey:field.attribute.name,
                            borderless:true,
                            template:" ",
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:4
                }
            ]
        } as unknown as webix.ui.layoutConfig & {sortOrder:number}
    }

    getBasicTemplateFunction(field:IDynamicAttribute):() => string {
        const func = function() {
            if(field.i18n_key) {
                return i18nHelper.getTranslation(field.display_name)
            } else {
                return field.display_name
            }         
        };
        return func
    }
    
    buildGrouppedDynfield(sectionName:string):webix.ui.layoutConfig {
        const sectionFieldsArr = [];
        const alreadyUsedDynfieldsArr = [];
        const rows = [];
        this.dynfields.map(dynfield => {
            dynfield.dynamic_subtypes.map(subtype => {
                if(sectionName === subtype.section_heading && alreadyUsedDynfieldsArr.indexOf(dynfield.attribute.id) === -1) {
                    sectionFieldsArr.push(dynfield);
                    alreadyUsedDynfieldsArr.push(dynfield.attribute.id);
                }
            });
        });
        sectionFieldsArr.map(dynfield => {
            rows.push(this.buildDynfield(dynfield));
        });
        const grouppedLayout = {
            view:"layout",
            rows:[
                {
                    view:"spacer",
                    height:4
                }, 
                {
                    view:"layout",
                    rows:[
                        {
                            template:((sectionName !== "") ? sectionName : i18nHelper.getTranslation("Other")),
                            height:40,
                            css: {
                                "margin-left":"-10px !important",
                                "font-weight":"bold"
                            },
                            borderless:true
                            
                        },
                        {
                            view:"layout",
                            rows:rows
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:4
                }, 
            ]
        };
        return grouppedLayout
    }

    getSingleFieldContainer(field:webix.ui.baseviewConfig):webix.ui.layoutConfig {
        return {
            sortOrder:field["sortOrder"],
            view:"layout",
            rows:[
                {
                    view:"spacer",
                    height:4
                },
                {
                    view:"layout",
                    cols:[
                        {
                            height:40,
                            gravity:0.4,
                            borderless:true,
                            template:function() {
                                return field["label"]
                            }
                        },
                        {
                            view:"template",
                            gravity:0.6,
                            id:`${this.idPrefix}Dynfield${field["name"]}`,
                            dynfieldValue:true,
                            dynfieldKey:field["name"],
                            borderless:true,
                            template:""
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:4
                }
            ]
        } as unknown as webix.ui.layoutConfig
    }


}