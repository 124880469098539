import BaseView from "views/baseview"


export default class Users extends BaseView {
    
    idPrefix = "uo";

    config():webix.ui.layoutConfig {
        return {
            
        }
    }

}