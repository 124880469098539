import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import * as pivot from "@xbs/pivot";
import ReportsPivotBackend from "./report-pivot-backend";
import { JetView } from "webix-jet";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";

export default class PivotReport extends JetView {

    idPrefix:"ipr";
    reportId:number;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

    config():Promise<webix.ui.layoutConfig> {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const reportId = Number(this.getParam("report_id", true));
        return ReportsPivotBackend.fetchData(reportId)
        .then(dataset => {
            const layout = {
                view:"layout",
                rows:[
                    this.getButtonsContainer(),
                    this.getGrid()
                ]
            }
            return layout;
        })
    }

    getButtonsContainer():webix.ui.layoutConfig {
        const buttonsContainer = {
            view:"layout",
            paddingY:13,
            paddingX:4,
            cols:[
                {
                    view:"layout",
                    gravity:0.25,
                    cols:this.getButtons()
                },
                {
                    view:"spacer",
                    gravity:0.75
                }
            ]
        }
        return buttonsContainer
    }

    getButtons():webix.ui.buttonConfig[] {
        const buttons = [
            {
                view: "button",
                inputWidth:160,
                css:"cog-button-big",
                label: i18nHelper.getTranslation("Export_to_PDF"),
                click: () =>
                webix.toPDF(`${this.idPrefix}InvoicesPoPivotGrid`, {
                  autowidth: true,
                  styles: true,
                }),
              },
              {
                view: "button",
                inputWidth:160,
                css:"cog-button-big",
                label: i18nHelper.getTranslation("Export_to_Excel"),
                click: () => webix.toExcel(`${this.idPrefix}InvoicesPoPivotGrid`, { styles: true, spans: true }),
              }
        ]
        return buttons
    }

    getGrid():webix.ui.pivotConfig {
        const pivotGird = {
            view:"pivot",
            override: new Map([[pivot.services.Backend, ReportsPivotBackend]]),
            id:`${this.idPrefix}InvoicesPoPivotGrid`,
            structure: ReportsPivotBackend.getStructure()
        };
        this.hideLoader();
        return pivotGird as unknown as webix.ui.pivotConfig
    }

    hideLoader():void {
        setTimeout(() => {
            if(ReportsPivotBackend.dataLoaded) {
                this.cognovisPleaseWaitWindow.hide();
            } else {
                this.hideLoader();
            }
        },1000);
    }

}