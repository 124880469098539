/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IWebixPortlet } from '../models/IWebixPortlet';
import type { WebixPortletStatus } from '../models/WebixPortletStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebixPortalPortletService {

    /**
     * Returns webix portlets the user can see
     * @returns IWebixPortlet Array of portlets
     * @throws ApiError
     */
    public static getWebixPortlets({
        objectId,
        objectType,
        portletStatusId,
        pageUrl,
    }: {
        /**
         * Object we are looking at to get the portlets back
         */
        objectId?: IntegerInt32,
        /**
         * Object type to look at (in case the object_id is missing). Would display all of the portlets regardless of subtype configuration
         */
        objectType?: string,
        /**
         * Should usually be provided with 28550 to only display enabled portlets
         */
        portletStatusId?: WebixPortletStatus,
        /**
         * On which page are we displaying the portlet.
         */
        pageUrl?: string,
    }): CancelablePromise<Array<IWebixPortlet>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/webix_portlets',
            query: {
                'object_id': objectId,
                'object_type': objectType,
                'portlet_status_id': portletStatusId,
                'page_url': pageUrl,
            },
        });
    }

}
