/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean0 } from '../models/Boolean0';
import type { Boolean1 } from '../models/Boolean1';
import type { IGetPlugin } from '../models/IGetPlugin';
import type { IGetServerUp } from '../models/IGetServerUp';
import type { IGetSwitchUser } from '../models/IGetSwitchUser';
import type { IGetUpdateSystem } from '../models/IGetUpdateSystem';
import type { II18nBody } from '../models/II18nBody';
import type { ImProjectId } from '../models/ImProjectId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IParameter } from '../models/IParameter';
import type { IPostRegisterParty } from '../models/IPostRegisterParty';
import type { IPostUpdateSystem } from '../models/IPostUpdateSystem';
import type { ITranslation } from '../models/ITranslation';
import type { IUser } from '../models/IUser';
import type { IUserToken } from '../models/IUserToken';
import type { NumberFloat } from '../models/NumberFloat';
import type { PackageParameterPackageKeys } from '../models/PackageParameterPackageKeys';
import type { PersonId } from '../models/PersonId';
import type { String1 } from '../models/String1';
import type { StringTypescript } from '../models/StringTypescript';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestSystemService {

    /**
     * Return the token information for the user upon login
     * @returns string Generic description
     * @throws ApiError
     */
    public static getAuthToken({
        username,
        password,
    }: {
        /**
         * Username of the user.
         */
        username: string,
        /**
         * Password for the user
         */
        password: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth_token',
            query: {
                'username': username,
                'password': password,
            },
        });
    }

    /**
     * Return the enums for inclusion
     * @returns string Generic description
     * @throws ApiError
     */
    public static getEnums({
        enumType,
        language,
    }: {
        /**
         * Type of enums we want to return. Typically categories (only supported one)
         */
        enumType?: string,
        /**
         * (Programming) Language for which to generate the enums. currently defaults to typescript (which is the only one suppported)
         */
        language?: StringTypescript,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/enums',
            query: {
                'enum_type': enumType,
                'language': language,
            },
        });
    }

    /**
     * Endpoint returning i18n translations for given package_keys in the locale
     * Registers a message key
     * @returns ITranslation Generic Response description for cog_rest::post::i18n
     * @throws ApiError
     */
    public static postI18N({
        requestBody,
    }: {
        /**
         * Message information we want to store
         */
        requestBody: II18nBody,
    }): CancelablePromise<ITranslation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/i18n',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Handler for GET rest calls to get package parameters<br>        It is possible to query with package_id or package_key<br>        If neither of these will be provided, endpoint will return all parameters from system
     * @returns IParameter Array of package parameters
     * @throws ApiError
     */
    public static getPackageParameter({
        packageId,
        packageKeys,
        parameterName,
    }: {
        /**
         * id of package of needed package parameters
         */
        packageId?: IntegerInt32,
        /**
         * array of package keys of needed package parameters
         */
        packageKeys?: PackageParameterPackageKeys,
        /**
         * Name of the parameter we look for
         */
        parameterName?: string,
    }): CancelablePromise<Array<IParameter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/package_parameter',
            query: {
                'package_id': packageId,
                'package_keys': packageKeys,
                'parameter_name': parameterName,
            },
        });
    }

    /**
     * Provide HTML of a component plugin Call this with /cognovis-rest/plugin?plugin_id=23882&company_id=8711 So you can pass on all arguments as URL values
     * @returns IGetPlugin data with plugins
     * @throws ApiError
     */
    public static getPlugin({
        pluginIds,
        returnUrl,
        projectId,
        pageUrl,
    }: {
        /**
         * String with comma separated plugin_ids
         */
        pluginIds?: string,
        /**
         * URL to retun, used in plugins
         */
        returnUrl?: string,
        /**
         * Project_id which to look for the plugins
         */
        projectId?: ImProjectId,
        /**
         * URL to use for plugins instead of plugin_ids
         */
        pageUrl?: string,
    }): CancelablePromise<IGetPlugin> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/plugin',
            query: {
                'plugin_ids': pluginIds,
                'return_url': returnUrl,
                'project_id': projectId,
                'page_url': pageUrl,
            },
        });
    }

    /**
     * @returns IGetServerUp Generic Response description for cog_rest::get::server_up
     * @throws ApiError
     */
    public static getServerUp(): CancelablePromise<IGetServerUp> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/server_up',
        });
    }

    /**
     * Return the user_id and token for a user to switch into<br><br>        Only for site wide admins though.
     * @returns IGetSwitchUser single json object with user and token info.
     * @throws ApiError
     */
    public static getSwitchUser({
        switchUserId,
    }: {
        /**
         * UserID to switch into
         */
        switchUserId?: string,
    }): CancelablePromise<Array<IGetSwitchUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/switch_user',
            query: {
                'switch_user_id': switchUserId,
            },
        });
    }

    /**
     * Returns a list of package_keys which can be updated. Only valid for site wide admins
     * @returns IGetUpdateSystem Array of package keys to update
     * @throws ApiError
     */
    public static getUpdateSystem(): CancelablePromise<Array<IGetUpdateSystem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/update_system',
        });
    }

    /**
     * Update the packages of the system
     * @returns IPostUpdateSystem Array of package keys to update
     * @throws ApiError
     */
    public static postUpdateSystem({
        packageKey,
        updateP,
        restartP,
    }: {
        /**
         * Key of the package to update. If not provided, update all.
         */
        packageKey?: string,
        /**
         * Boolean Should we update (default yes). Otherwise just return packages
         */
        updateP?: String1,
        /**
         * Boolean Should we restart the server after updating? Defaults to yes, ignored if update_p is false
         */
        restartP?: String1,
    }): CancelablePromise<Array<IPostUpdateSystem>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/update_system',
            query: {
                'package_key': packageKey,
                'update_p': updateP,
                'restart_p': restartP,
            },
        });
    }

    /**
     * Return the user_id and token for a user<br><br>        Only for site wide admins though.
     * @returns IUserToken Object with user info and token
     * @throws ApiError
     */
    public static getUserToken({
        userId,
    }: {
        /**
         * UserID to switch into
         */
        userId: NumberFloat,
    }): CancelablePromise<IUserToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_token',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * Upgrades the system e.g. from a production database
     * @returns string Generic description
     * @throws ApiError
     */
    public static postMigrateSystem({
        serverUrl,
        serverPath,
        systemOwner,
        developP,
        logoUrl,
        logoLinkUrl,
        portalUrl,
        reduceDataP,
        anonymizeP,
        recreateFoldersP,
    }: {
        /**
         * URL of the server
         */
        serverUrl: string,
        /**
         * PATH of the filesystem for the server
         */
        serverPath?: string,
        /**
         * E-Mail of the system owner
         */
        systemOwner?: string,
        /**
         * Is this a develop system - This is probably the case most of the time
         */
        developP?: Boolean1,
        /**
         * Where do we get the logo from
         */
        logoUrl?: string,
        /**
         * Where will clicking on the logo lead to
         */
        logoLinkUrl?: string,
        /**
         * What is the URL for the portal
         */
        portalUrl?: string,
        /**
         * Should we reduce the dataset?
         */
        reduceDataP?: Boolean0,
        /**
         * Should we anonymize the data (ideally it is anonymized before loading).
         */
        anonymizeP?: Boolean0,
        /**
         * Should we recreate the folders?
         */
        recreateFoldersP?: Boolean1,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/migrate_system',
            query: {
                'server_url': serverUrl,
                'server_path': serverPath,
                'system_owner': systemOwner,
                'develop_p': developP,
                'logo_url': logoUrl,
                'logo_link_url': logoLinkUrl,
                'portal_url': portalUrl,
                'reduce_data_p': reduceDataP,
                'anonymize_p': anonymizeP,
                'recreate_folders_p': recreateFoldersP,
            },
        });
    }

    /**
     * Will generate portraits for the users in the group or the user_id (defaults to current user) based of  https://thispersondoesnotexist.com
     * @returns IUser Array of users with their new portrait
     * @throws ApiError
     */
    public static postRandomPortrait({
        groupId,
        userId,
    }: {
        /**
         * Group for which we want to generate portraits
         */
        groupId?: IntegerInt32,
        /**
         * User we want to generate the portrait for
         */
        userId?: PersonId,
    }): CancelablePromise<Array<IUser>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/random_portrait',
            query: {
                'group_id': groupId,
                'user_id': userId,
            },
        });
    }

    /**
     * Registers a party for login. If names are provided, create a person in addition<br>        Allows callbacks to act upon it.
     * @returns IPostRegisterParty Generic Response description for cog_rest::post::register_party
     * @throws ApiError
     */
    public static postRegisterParty({
        email,
        firstNames,
        lastName,
    }: {
        /**
         * E-Mail we want to register
         */
        email: string,
        /**
         * First names of the registered user
         */
        firstNames?: string,
        /**
         * Last Name of the registered user
         */
        lastName?: string,
    }): CancelablePromise<IPostRegisterParty> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/register_party',
            query: {
                'email': email,
                'first_names': firstNames,
                'last_name': lastName,
            },
        });
    }

    /**
     * Restart the server (only for admins)
     * @returns string Generic description
     * @throws ApiError
     */
    public static postServerRestart(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/server_restart',
        });
    }

    /**
     * Changing password for not logged user
     * @returns string Generic description
     * @throws ApiError
     */
    public static postUpdatePassword({
        password,
        passwordConfirm,
    }: {
        /**
         * new password
         */
        password: string,
        /**
         * new password typed second time
         */
        passwordConfirm: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/update_password',
            query: {
                'password': password,
                'password_confirm': passwordConfirm,
            },
        });
    }

}
