import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { IFreelancerSkill, WebixPortalFreelancerService} from "../../../sources/openapi";

export default class SkillDetailsModal extends CognovisBasicModal {

    idPrefix = "sdm";
    userId:number;
    skillData:IFreelancerSkill

    afterSubmitAction:() => void;

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Skill`), 740,360);
        return mainLayout;
    }

    openModal(userId:number, afterSubmitAction?:() => void, skill?:IFreelancerSkill):void {
        this.userId = userId;
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
        if(skill) {
            this.skillData = skill;
        }
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig);
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        if(!skill) {
            this.clearFields();
        }
    }

    closeModal():void {
        this.hide();
    }

    getContent():webix.ui.layoutConfig {
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}SkillsTableContainer`,
                        rows:[
                            {
                                view:"layout",
                                rows:[
                                    this.getForm()
                                ]
                            }
                        ]
                    },                    
                ]
            }
        };
        return layout
    }

    getForm():webix.ui.formConfig {
        return {
            view:"form",
            elements:[
                {
                    view:"combo",
                    localId:`${this.idPrefix}SkillTypeCombo`,
                    //value:this.skillData?.skill_type?.id,
                    label:i18nHelper.getTranslation("Skill_type"),
                    labelWidth:200,
                    name:"skillTypeId",
                    suggest: {
                        body: []
                    },
                    on: {
                        onAfterRender:() => {
                            const me = this.$$(`${this.idPrefix}SkillTypeCombo`) as webix.ui.combo;
                            me.enable();
                            CognovisCategory.getCategory("Intranet Skill Type")
                            .then(types => {
                                (me.getList() as webix.ui.list).clearAll();
                                (me.getList() as webix.ui.list).define("data", types);
                                (me.getList() as webix.ui.list).refresh();
                                if(this.skillData?.skill_type?.id) {
                                    me.setValue(this.skillData.skill_type.id.toString());
                                    me.disable();
                                }
                            });
                            
                        },
                        onChange:(newValue:string) => {
                            const me = this.$$(`${this.idPrefix}SkillTypeCombo`) as webix.ui.combo;
                            const currentOptions = (me.getList() as webix.ui.list).serialize();
                            const currentlySelectedOption:{id:number,value:string, aux_string1:string} = currentOptions.find(option => option.id === Number(newValue));
                            const skillCombo = this.$$(`${this.idPrefix}SkillCombo`) as webix.ui.combo;
                            skillCombo.enable();
                            skillCombo.setValue("");
                            if(currentlySelectedOption?.aux_string1) {
                                (skillCombo.getList() as webix.ui.list).clearAll();
                                CognovisCategory.getCategory(currentlySelectedOption.aux_string1)
                                .then(categories => {
                                    (skillCombo.getList() as webix.ui.list).define("data", categories);
                                    (skillCombo.getList() as webix.ui.list).refresh();
                                    // We also want to select value in case we use modal in edit mode
                                    if(this.skillData.skill?.id) {
                                        skillCombo.setValue(this.skillData?.skill.id.toString());
                                        skillCombo.disable();
                                    } 
                                });
                            }
                        }
                    }
                },
                {
                    view:"combo",
                    localId:`${this.idPrefix}SkillCombo`,
                    label:i18nHelper.getTranslation("Skill"),
                    labelWidth:200,
                    name:"skillId",
                    suggest: {
                        body: []
                    },
                },
                {
                    view:"combo",
                    label:i18nHelper.getTranslation("Experience"),
                    value:this.skillData?.confirmed_experience?.id,
                    labelWidth:200,
                    name:"confirmedExperienceId",
                    suggest: {
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet Experience Level"),
                        },
                    },
                },
            ]
        }
    }

    

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`,
                            align: "right",
                            click:() => {
                                this.submitForm()
                            }
                        }  
                    ] 
                };
        return buttons;
    }

    submitForm():void {
        if(this.skillData?.user) {
            this.updateExistingSkill();
        } else {
            this.createNewSkill();
        }
    }

    updateExistingSkill():void {
        const container = this.$$(`${this.idPrefix}SkillsTableContainer`) as webix.ui.layout;
        const form = container.queryView({view:"form"});
        const values = form.getValues();
        WebixPortalFreelancerService.putFreelancerSkill({
            requestBody:{
                user_id:this.userId,
                skill_id:values.skillId,
                skill_type_id:values.skillTypeId,
                confirmed_experience_id:values.confirmedExperienceId
            }
        })
        .then(res => {
            if(this.afterSubmitAction) {
                this.afterSubmitAction();
            }
            this.closeModal();
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    createNewSkill():void {
        const container = this.$$(`${this.idPrefix}SkillsTableContainer`) as webix.ui.layout;
        const form = container.queryView({view:"form"});
        const values = form.getValues();
        WebixPortalFreelancerService.postFreelancerSkill({
            requestBody:{
                user_id:this.userId,
                skill_id:values.skillId,
                skill_type_id:values.skillTypeId,
                confirmed_experience_id:values.confirmedExperienceId
            }
        })
        .then(res => {
            if(this.afterSubmitAction) {
                this.afterSubmitAction();
            }
            this.closeModal();
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    clearFields():void {
        const container = this.$$(`${this.idPrefix}SkillsTableContainer`) as webix.ui.layout;
        const form = container.queryView({view:"form"}) as webix.ui.form;
        form.clear();  
    }
}
