/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ICognovisObject } from '../models/ICognovisObject';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IObjectType } from '../models/IObjectType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestObjectService {

    /**
     * Provides a cognovis object with additional information
     * @returns ICognovisObject Object data
     * @throws ApiError
     */
    public static getCognovisObject({
        objectId,
    }: {
        /**
         * object we want to look attributes
         */
        objectId: IntegerInt32,
    }): CancelablePromise<ICognovisObject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cognovis_object',
            query: {
                'object_id': objectId,
            },
        });
    }

    /**
     * Returns an array of linked objects
     * @returns ICognovisObject Linked objects for this invoice
     * @throws ApiError
     */
    public static getLinkedObjects({
        objectId,
    }: {
        /**
         * Object for which we want to get the linked objects
         */
        objectId: IntegerInt32,
    }): CancelablePromise<Array<ICognovisObject>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/linked_objects',
            query: {
                'object_id': objectId,
            },
        });
    }

    /**
     * Returns an object type details
     * @returns IObjectType Data describing the object_type
     * @throws ApiError
     */
    public static getObjectTypes({
        objectType,
    }: {
        /**
         * Filter for a specific object type (to just return the values for that)
         */
        objectType: string,
    }): CancelablePromise<Array<IObjectType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/object_types',
            query: {
                'object_type': objectType,
            },
        });
    }

    /**
     * @returns string Generic description
     * @throws ApiError
     */
    public static deleteObject({
        objectId,
    }: {
        /**
         * Object Id to delete
         */
        objectId: IntegerInt32,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/object',
            query: {
                'object_id': objectId,
            },
        });
    }

}
