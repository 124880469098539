/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetNotesType {
    FINANCIAL_INFORMATION = 11511,
    MEETING_NOTE = 11512,
    OTHER = 11514,
    DELIVERY_INFO = 11515,
    PROJECT_INFORMATION = 11516,
    CUSTOMER_COMPLAINT = 10000564,
    PROVIDER_INFORMATION = 10000566,
}
