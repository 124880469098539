/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IKolibriReportFinDoc } from '../models/IKolibriReportFinDoc';
import type { IntranetCostTypeIds } from '../models/IntranetCostTypeIds';
import type { StringDate } from '../models/StringDate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IntranetCustKolibriReportingService {

    /**
     * Returns the financial documents for a period of time
     * @returns IKolibriReportFinDoc Financial documents created in the period
     * @throws ApiError
     */
    public static getKolibriReportFinDocs({
        costTypeIds,
        startDate,
        endDate,
    }: {
        /**
         * List of cost types we want to export
         */
        costTypeIds?: IntranetCostTypeIds,
        /**
         * Which is the first date we look at for the invoice creation - defaults to Beginning of last year
         */
        startDate?: StringDate,
        /**
         * Which is the last date we look at for invoice creation - defaults to now
         */
        endDate?: StringDate,
    }): CancelablePromise<Array<IKolibriReportFinDoc>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/kolibri_report_fin_docs',
            query: {
                'cost_type_ids': costTypeIds,
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }

}
