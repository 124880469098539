import BaseView from "../../baseview";

export default class ProjectMessageNewMessage extends BaseView {
    config(): webix.ui.layoutConfig {
        return {
            view: "layout",
            type: "clean",

            cols: [
                { view: "spacer", width: 20 },
                {
                    view: "layout",
                    type: "clean",
                    responsive: true,
                    rows: [
                        { view: "spacer", height: 20 },
                        {
                            view: "button",
                            type: "icon",
                            icon: "fa fa-chevron-left",
                            inputWidth: 100,
                            id: "back-button",
                            css: "new-message-back-button",
                        },
                        {
                            view: "form",
                            id: "project_newmessage_form",
                            elements: [
                                {
                                    view: "combo",
                                    label: "recipient",
                                    labelWidth: 80,
                                    name: "recipients",
                                    id: "recipient",
                                    options: ["Name1", "Name2", "Name2"],
                                },
                                {
                                    view: "text",
                                    label: "subject",
                                    labelWidth: 80,
                                    name: "subject",
                                    id: "subject",
                                },
                                {
                                    view: "richtext",
                                    name: "new_message_richtext",
                                    id: "new_message_richtext",
                                    height: 200,
                                },
                                {
                                    view: "button",
                                    value: "Send message",
                                    css: "button",
                                    inputWidth: 150,
                                    click: () => {
                                        if (
                                            (<webix.ui.form>(
                                                $$("project_newmessage_form")
                                            )).validate()
                                        ) {
                                            //const form_values: webix.ui.form = ($$("project_newmessage_form") as webix.ui.form).getValues();
                                        } else {
                                            webix.message({
                                                type: "error",
                                                text:
                                                    "Please re-enter the data and try sending the message again",
                                            });
                                        }
                                    },
                                },
                            ],
                            rules: {
                                recipient: webix.rules.isNotEmpty,
                                subject: webix.rules.isNotEmpty,
                                new_message_richtext: webix.rules.isNotEmpty,
                            },
                        },
                    ],
                },
            ],
        };
    }
}
