/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetCostType {
    CUSTOMER_INVOICE = 3700,
    QUOTE = 3702,
    PROVIDER_BILL = 3704,
    PURCHASE_ORDER = 3706,
    CUSTOMER_DOCUMENTS = 3708,
    PROVIDER_DOCUMENTS = 3710,
    EMPLOYEE_SALARY = 3714,
    REPEATING_COST = 3716,
    TIMESHEET_COST = 3718,
    EXPENSE_ITEM = 3720,
    EXPENSE_BUNDLE = 3722,
    DELIVERY_NOTE = 3724,
    CUSTOMER_INVOICE_CORRECTION = 3725,
    TIMESHEET_BUDGET = 3726,
    EXPENSE_PLANNED_COST = 3728,
    INTERCO_INVOICE = 3730,
    INTERCO_QUOTE = 3732,
    PROVIDER_RECEIPT = 3734,
    PROVIDE_BILL_CORRECTION = 3735,
    TIMESHEET_HOURS = 3736,
    CANCELLATION_INVOICE = 3740,
    CANCELLATION_BILL = 3741,
}
