
import { JetView } from "webix-jet";
import { container } from "tsyringe";
import { WebixHelpers } from "../../../../../sources/modules/webix-helpers/webix-helpers";
import { CognovisCategory } from "../../../../../sources/modules/cognovis-category/cognovis-category";
import { IFreelancePackage, WebixPortalAssignmentService,  IFreelancer, ITransWorkflowStep, ImFreelancePackageId, PersonIds, IAssignment, IntranetUomId, ITransProject,  IFreelancerFee, IntranetFreelanceAssignmentStatus, IntranetSkillBusinessSector, IntranetUom} from "../../../../../sources/openapi";
import { CognovisPleaseWaitWindow } from "../../../../../sources/services/cognovis-please-wait-window";
import { i18nHelper } from "../../../../../sources/modules/i18n-helper/i18n-helper";
import  { CognovisTransHelper } from  "../../../../../sources/modules/cognovis-trans-helper/cognovis-trans-helper";
import { CognovisNavigator } from "../../../../../sources/modules/cognovis-navigator/cognovis-navigator";
import ObjectNotes from "../../../modals/object-notes-modal";
import { PriceHelper } from "../../../../../sources/modules/price-helpers/price-helpers";
import { DatetimeHelper } from "../../../../../sources/modules/datetime-helpers/datetime-helpers";

export default class GenerateAssignment extends JetView {

    windowId = "generateAssignmentModal";
    singleSlotPrefix = "pbSlot";
    totalFeeComponentPrefix = "pbTotalFee";
    freelancerComponentPrefix = "pbfreelancerSelector";
    unitsComponentPrefix = "pbUnits";
    uomComponentPrefix = "pbUom";
    rateComponentPrefix = "pbFee";
    deadlineComponentPrefix = "pbDeadline";
    notesButtonComponentPrefix = "pbNotesButton";
    projectData:ITransProject;
    selectedFreelancers: IFreelancer[] = [];
    projectWorkflowSteps:ITransWorkflowStep[] = [];
    projectId:number;
    webixHelpers:WebixHelpers;
    assignmentNotesModal:ObjectNotes;
    freelancerPackages:IFreelancePackage[] = [];
    grouppedFreelancerPackages:ICognovisGrouppedFreelancePackages<IFreelancePackage>[] = [];
    existingAssignments:IAssignment[] = [];
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    cognovisTransHelper:CognovisTransHelper;
    notYetSavedNotes:{freelancePackageId:number,note:string}[] = [];
    generalNote = "";
    autoFocusId = "";
    autoSelectedFreelancerId:boolean | number;
    selectedUomId:number = -1;

    config(): webix.ui.windowConfig {
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisTransHelper = container.resolve(CognovisTransHelper);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.autoSelectedFreelancerId = false;
        const mainLayout = this.getLayout();
        return mainLayout;
    }

    init():void {
        this.assignmentNotesModal = this.ui(ObjectNotes) as ObjectNotes; 
    }
    
    getFreelancePackages(projectId:number, selectedFreelancers: IFreelancer[]):Promise<IFreelancePackage[]> {
        return WebixPortalAssignmentService.getPackages({
            projectId:projectId,
            freelancerIds:selectedFreelancers.map(fl => fl.freelancer.id)
        })
        .then(flPackages => {
            const grouppedFreelancerPackages = this.cognovisTransHelper.groupFreelancePackages(flPackages, this.projectWorkflowSteps);
            this.grouppedFreelancerPackages = grouppedFreelancerPackages;
            return flPackages
        })
    }

    getTranslationAssignments(projectId:number):Promise<IAssignment[]> {
        return WebixPortalAssignmentService.getTranslationAssignments({
            projectId:projectId
        })
        .then(transAssignments => {
            return transAssignments;
        });
    }

    setSelectedFreelancers(selectedFreelancers: IFreelancer[]):void {
        const preparedFreelancersForoCombo = selectedFreelancers.map(flObj => {
            return {
                id:flObj.freelancer.id,
                value:flObj.freelancer.name
            };
        });
        const window = ($$(this.windowId) as webix.ui.window);
        const freelancersMultiselects = window.queryView({view:"multiselect"}, "all");
        freelancersMultiselects.map(freelancerSelector => {
            if(freelancerSelector) {
                const suggestBox = freelancerSelector.getPopup();
                if(suggestBox) {
                    const suggestBoxList = suggestBox.getList();
                    suggestBoxList.clearAll();
                    suggestBoxList.parse(preparedFreelancersForoCombo);
                    //freelancerSelector.define("options", preparedFreelancersForoCombo);
                }
            }
        });
        // Set potentially autoSelectedFreelancer
        if(selectedFreelancers.length === 1) {
            this.autoSelectedFreelancerId = selectedFreelancers[0].freelancer.id;
        }
    }

    getSelectedFreelancersFromSameRowSlots(componentId:string):number[] {
        const currentSlot = this.getCurrentSlot(componentId);
        const taskIds = currentSlot.config["packageData"].tasks.map(task => task.task.id);
        const selectedFreelancersFromSlots = [];
        const allFreelancersSelectionComponents = (this.getRoot() as webix.ui.view).queryView({view:"multiselect"}, "all");
        allFreelancersSelectionComponents.map(component => {
            const pattern = this.extractComponentIdPattern(component.config.id);
            const otherSlot = webix.$$(`${this.singleSlotPrefix}-${pattern}`) as webix.ui.layout;
            const otherSlotTaskIds = otherSlot.config["packageData"].tasks.map(task => task.task.id);
            // Tricky part, we need to figure out if slot is in same row. Best way would be to compare taskIds
            // Sadly, JS/TS does not provide built-in function which allows arr_1 === arr2 to return 'true'
            // The way around it is to use JSON.stringify, we can do that in our case, because our arrays have primitive values
            if((JSON.stringify(taskIds) === JSON.stringify(otherSlotTaskIds)) && currentSlot.config["packageData"].freelance_package.id !== otherSlot.config["packageData"].freelance_package.id) {
                const values = component.getValue();
                const splitted = values.split(",");
                splitted.map(val => {
                    if(val) {
                        selectedFreelancersFromSlots.push(parseInt(val));
                    }
                });
            }
        });
        const unique = selectedFreelancersFromSlots.filter(function(item, pos) {
            return selectedFreelancersFromSlots.indexOf(item) == pos;
        })
        return unique;
    }

    showModal(projectId:number, projectData:ITransProject, selectedFreelancers: IFreelancer[], projectWorkflowSteps:ITransWorkflowStep[], autoFocusId:string, selectedUomId:number = -1):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        this.projectData = projectData;
        this.autoFocusId = autoFocusId;
        this.projectId = projectId;
        this.projectWorkflowSteps = projectWorkflowSteps;
        this.selectedUomId = selectedUomId;
        this.getFreelancePackages(this.projectId, selectedFreelancers)
        .then(() => {
            this.getTranslationAssignments(this.projectId)
            .then(existingAssignments => {
                //this.show("");
                this.insertWorkflowStepsColumn(projectWorkflowSteps);
                this.existingAssignments = existingAssignments;
                this.insertDataRows(this.grouppedFreelancerPackages, projectWorkflowSteps, this.existingAssignments);
                this.cognovisPleaseWaitWindow.hide();
                this.setSelectedFreelancers(selectedFreelancers);
                if(autoFocusId) {
                    this.autoFocusId = autoFocusId;
                    const view = (webix.$$("gaScrollView") as webix.ui.scrollview);
                    const scrollviewbody = view.getBody();
                    const input = (webix.$$(`${this.rateComponentPrefix}-${autoFocusId}`) as webix.ui.text);
                    const flSelector = (webix.$$(`${this.freelancerComponentPrefix}-${autoFocusId}`) as webix.ui.multiselect);
                    if(input) {
                        const scrollValue = (input.getNode().getBoundingClientRect().top) - (scrollviewbody.getNode().getBoundingClientRect().top);
                        view.scrollTo(0,scrollValue-20);
                        setTimeout(() => {
                            if(this.autoSelectedFreelancerId) {
                                flSelector.setValue(this.autoSelectedFreelancerId.toString());
                                input.focus();
                            }
                        },300);                
                    }
                }
            });
        });
    }

    findAssignment(freelancePackageId:number, existingAssignments:IAssignment[]):IAssignment {
        return existingAssignments.find(flPackage => flPackage.freelance_package.id === freelancePackageId);
    }


    findAssignments(freelancePackageId:number, existingAssignments:IAssignment[]):IAssignment[] {
        return existingAssignments.filter(flPackage => flPackage.freelance_package.id === freelancePackageId);
    }

    closeModal():void {
        const window = ($$(this.windowId) as webix.ui.window);
        if(window) {
            window.close();
        }
    }

    getLayout():webix.ui.windowConfig {
        return {
            view: "window",
            id:this.windowId,
            modal: true,
            close:true,
            position:"top",
            width: 1480,
            height:720,
            padding:20,
            css: {
                "margin-top":"50px"
            },
            hidden: false,
            head:i18nHelper.getTranslation("Generate_new_assignments"),
            body: ({
                rows: [
                    {
                        view:"layout",
                        type: "clean",
                        id:"gaColumnsContainer",
                        cols:[]
                    },
                    {
                        view: "scrollview",
                        scroll: "y",  
                        id:"gaScrollView",
                        
                        body:{
                            id:"gaDataRows",
                            width:1480,
                            rows:[]
                        }
                    },
                    {
                        view:"layout",
                        css: {
                            "margin-top":"8px !important"
                        },
                        cols: [
                            {},
                            { 
                                view: "button", 
                                css:"cog-button-big",
                                value: i18nHelper.getTranslation("Cancel"),
                                align: "right", 
                                width:150,
                                inputWidth: 150, 
                                click:() => {
                                    this.closeModal();
                                }
                            },
                            { 
                                view: "button", 
                                css:"cog-button-big",
                                id:"gaGeneralNotesButton",
                                value: i18nHelper.getTranslation("Notes"),
                                align: "right", 
                                width:150,
                                inputWidth: 150,
                                click:() => {
                                    this.openNotesModal("general", "gaGeneralNotesButton", {})
                                },
                            },
                            { 
                                view: "button", 
                                css:"cog-button-big",
                                id:"gaRequestForParticpationButton",
                                value: i18nHelper.getTranslation("Request_for_participation"), 
                                align: "right", 
                                width:250,
                                inputWidth: 250,
                                click:() => {
                                    this.requestForParticipation();
                                }
                            },
                            {
                                view:"spacer",
                                width:13
                            }
                        ],
                    },
                    { view: "spacer", height: 20 },
                ],
            } as unknown) as webix.ui.window,
            on: {
                onHide:() => {
                    this.closeModal();
                }
            }
        }

    }

    openNotesModal(mode:string, buttonId:string, freelancePackage?:IFreelancePackage):void {
        const button = webix.$$(buttonId) as webix.ui.button;
        let currentComment = button.config["comment"];
        if(!currentComment) {
            currentComment = "";
        }
        let modalTitle = `${i18nHelper.getTranslation(`General_Note`)}`;
        if(mode === "specific") {
            modalTitle = `${i18nHelper.getTranslation(`Individual_Note`)}`;
        }
        this.assignmentNotesModal.openNotesModal<IFreelancePackage>(mode, modalTitle, freelancePackage, currentComment, "cke5",[], (inputedNote:string) => {
            if(mode === "general") {
                this.generalNote = inputedNote;
                // Update button colors
                const view = webix.$$(this.windowId) as webix.ui.window;
                const buttons = view.queryView({view:"button"}, "all");
                buttons.map(button => {
                    if(button.config?.notesButton && button.config?.freelancePackageId && !button.config.comment) {
                        button.config.comment = inputedNote;
                        this.saveCommentIntoFreelancePackage(button.config.freelancePackage, this.generalNote);
                    }
                });
            }
            if(mode === "specific") {
                freelancePackage.package_comment = inputedNote;
                button.config["comment"] = inputedNote;
                this.saveCommentIntoFreelancePackage(freelancePackage, inputedNote);
            }
            this.updateAllNotesButtonsCss();    
        });      
    }

    updateAllNotesButtonsCss():void {
        const view = webix.$$(this.windowId) as webix.ui.window;
        const buttons = view.queryView({view:"button"}, "all");
        buttons.map(button => {
            const element = webix.$$(button.config.id) as webix.ui.button;
            if(button.config?.notesButton && button.config?.comment?.length > 0) {
                webix.html.addCss(element.getNode(), "exists");
            } else {
                webix.html.removeCss(element.getNode(), "exists");
            }
        });
    }

    saveCommentIntoFreelancePackage(freelancePackage:IFreelancePackage, comment:string):Promise<IFreelancePackage> {
        return WebixPortalAssignmentService.putPackages({
            freelancePackageId:freelancePackage?.freelance_package.id,
            requestBody:{
                trans_task_ids:freelancePackage.tasks.map(task => task.task.id),
                package_name:freelancePackage.freelance_package.name,
                package_comment:comment
            } 
        })
        .then(flPackage => {
            return flPackage as IFreelancePackage
        });
    }

    getWorkflowStepsColumns(projectWorkflowSteps:ITransWorkflowStep[]):webix.ui.layoutConfig[] {
        const projectWorkflowStepsColumns = [
            { view: "template", type: "header", template: i18nHelper.getTranslation("Batches"), css:{}, width:260},
            { view: "template", type: "header", template: i18nHelper.getTranslation("target_language"), width:100, css:{}},
        ];
        projectWorkflowSteps.map(step => {
            projectWorkflowStepsColumns.push({
                view: "template", css:{"text-align":"center"}, type: "header", template: `${step.task_type.name}`, width:360, 
            });
        });
        return projectWorkflowStepsColumns;
    }

    insertWorkflowStepsColumn(projectWorkflowSteps:ITransWorkflowStep[]):void {
        const workflowStepsColumns = this.getWorkflowStepsColumns(projectWorkflowSteps);
        const columnsContainer = (webix.$$("gaColumnsContainer") as webix.ui.layout);
        workflowStepsColumns.map(column => {
            columnsContainer.addView(column);
        })
    }

    isRowActionPossible(grouppedPackage:ICognovisGrouppedFreelancePackages<IFreelancePackage>, projectWorkflowSteps:ITransWorkflowStep[],existingAssignments:IAssignment[]):boolean {
        let actionPossible = false;
        let totalPossibleActions = 0;
        projectWorkflowSteps.map(step => {
            if(grouppedPackage.grouppedByType[step.task_type.id].packages.length > 0) {
                const freelancePackageObj = grouppedPackage.grouppedByType[step.task_type.id].packages[0].freelancePackage;
                const assignment = this.findAssignment(freelancePackageObj.freelance_package.id, existingAssignments);
                if(!assignment || (assignment.assignment_status.id === IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED || assignment.assignment_status.id === IntranetFreelanceAssignmentStatus.REQUESTED)) {
                    totalPossibleActions++;
                }
            }
        });
        if(totalPossibleActions > 0) {
            actionPossible = true;
        } 
        // If autofocus is available, we need to make additional checks
        if(this.autoFocusId) {
            actionPossible = false;
            projectWorkflowSteps.map(step => {
                if(grouppedPackage.grouppedByType[step.task_type.id].packages.length > 0) {
                    const freelancePackageObj = grouppedPackage.grouppedByType[step.task_type.id].packages[0].freelancePackage;
                    if(freelancePackageObj.freelance_package.id.toString() === this.autoFocusId) {
                        actionPossible = true;
                    }
                }
            });
        }
        return actionPossible
    }

    insertDataRows(grouppedPackages:ICognovisGrouppedFreelancePackages<IFreelancePackage>[], projectWorkflowSteps:ITransWorkflowStep[], existingAssignments:IAssignment[]):void {
        const rowsContainer = (webix.$$("gaDataRows") as webix.ui.layout);
        grouppedPackages.map(grouppedPackage => {
            if(this.isRowActionPossible(grouppedPackage, projectWorkflowSteps, existingAssignments)) {
                const displayedBatchName = `<b>${grouppedPackage.packageName}</b><br/>${grouppedPackage.packageTaskNames}`;
                const batchColumn = this.getBatchColummn(displayedBatchName);
                const targetLanguageColumn = this.getTargetLanguageColumn(grouppedPackage.targetLanguageName);
                const singleBoxesArr = [];
                projectWorkflowSteps.map((step, index) => {
                    if(grouppedPackage.grouppedByType[step.task_type.id].packages.length > 0) {
                        // Find out existing assignments
                        const freelancePackageObj = grouppedPackage.grouppedByType[step.task_type.id].packages[0].freelancePackage;
                        const existingAssignmentsForThatSlot = this.findAssignments(freelancePackageObj.freelance_package.id, existingAssignments);
                        singleBoxesArr.push(this.getSingleAssignmentBox(freelancePackageObj, index, existingAssignmentsForThatSlot));
                    } else {
                        singleBoxesArr.push({});
                    }
                });
                rowsContainer.addView({
                    cols:[
                        batchColumn,
                        targetLanguageColumn,
                        ...singleBoxesArr
                    ]
                });
            }
        });
    }

    getBatchColummn(batchName:string):webix.ui.layoutConfig {
        const batchColumn = {
            type: "clean",
            padding: 10,
            width:260,
            rows: [
                {
                    view: "template",
                    css: {
                        "font-size":"0.8em",
                        "padding-top":"12px"
                    },
                    autoHeight: true,
                    borderless: true,
                    template: `${batchName}`,
                },
            ],
        };
        return batchColumn;
    }

    decideOnSlotStatus(freelancePackage:IFreelancePackage, assignments?:IAssignment[]):boolean {
        let actionPossible = true;
        if(freelancePackage.has_assignments) {
            actionPossible = false;
        }   
        const totalAssignmentsForThatPackage = assignments.length;
        if(assignments.filter(assignment => assignment.assignment_status.id === IntranetFreelanceAssignmentStatus.REQUESTED).length === totalAssignmentsForThatPackage) {
            actionPossible = true;
        }
        if(assignments.filter(assignment => assignment.assignment_status.id === IntranetFreelanceAssignmentStatus.ASSIGNMENT_DELETED || assignment.assignment_status.id === IntranetFreelanceAssignmentStatus.DENIED).length === totalAssignmentsForThatPackage) {
            actionPossible = true;
        }
        return actionPossible
    }

    getTargetLanguageColumn(languageName:string):webix.ui.layoutConfig {
        const targetLanguageColumn = {
            width:100,
            rows: [{ view: "template", template: `${languageName}`,css:{"padding-top":"20px"},  borderless: true }],
        };
        return targetLanguageColumn;
    }

    getSingleAssignmentBox(freelancePackage:IFreelancePackage, index:number, assignments?:IAssignment[]):webix.ui.layoutConfig {
        let freelancerSelectField = {};
        const properFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
        let formattedDeadline = "";
        let calculatedUnits = 0.0;
        // Now we need to figure out if any action is possible for that slot
        const isActionPossible = this.decideOnSlotStatus(freelancePackage, assignments)
        if(!isActionPossible) {
            const assignedFreelancersNames = assignments?.map(assignment => assignment.assignee.name).join(", ");
            freelancerSelectField = {
                view: "text",
                readonly:!isActionPossible,
                disabled:!isActionPossible,
                value: assignedFreelancersNames,
                minWidth:150,
                name:"freelancers",
                id:`${this.freelancerComponentPrefix}-${freelancePackage.freelance_package.id}`,
                placeholder: i18nHelper.getTranslation("Freelancer")
            };
            // We display units only if case of single assignment
            if(assignments.length === 1) {
                calculatedUnits = assignments[0]?.assignment_units;
            }
            // We also set deadline to proper format
            if(assignments[0]?.assignment_deadline) {
                formattedDeadline = new Date(DatetimeHelper.parseDateForDatepicker(assignments[0]?.assignment_deadline)) as unknown as string;
            } else {
                formattedDeadline = properFormat(new Date());
            }
        // In case of empty slot (no assignment), for freelancer field, we need to display multiselect
        } else {
            let existingFreelancersIds = "";
            if(assignments) {
                existingFreelancersIds = assignments
                .filter(assignment => assignment.assignment_status.id !== IntranetFreelanceAssignmentStatus.DENIED)
                .map(assignment => assignment.assignee.id)
                .join(",");
            }
            if(freelancePackage.package_deadline) {
                // That endpoint returns date from intranet-translation package, so we cant use webi properFormat
                formattedDeadline = new Date(DatetimeHelper.parseDateForDatepicker(freelancePackage.package_deadline)) as unknown as string;
            } else {
                formattedDeadline = properFormat(new Date());
            }
            freelancePackage.tasks.map(task => {
                calculatedUnits = freelancePackage.units;
            });
            freelancerSelectField = {
                view: "multiselect",
                minWidth:150,
                name:"freelancers",
                value:existingFreelancersIds,
                id:`${this.freelancerComponentPrefix}-${freelancePackage.freelance_package.id}`,
                placeholder: i18nHelper.getTranslation("Freelancer"),
                suggest:{
                    view:"multisuggest",
                },
                on: {
                    onChange:() => {
                        const componentId = `${this.freelancerComponentPrefix}-${freelancePackage.freelance_package.id}`;
                        this.updatedFreelancerField(componentId, freelancePackage.target_language.id, freelancePackage.package_type.id);
                    },
                    onFocus:function() {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const popup = (this as webix.ui.multiselect).getPopup() as any;
                        const multiselectList = popup.getList();
                        const freelancersInDropdown = multiselectList.serialize();
                        setTimeout(() => {
                            // We must also make sure that already assigned freelancers are also in the list
                            // We need to do this, becuase otherwise value won't be pre-selected (because it doesn't exists)
                            assignments.map(assignment => {
                                freelancersInDropdown.push({
                                    id:assignment.assignee.id,
                                    value:assignment.assignee.name
                                });
                            });
                            multiselectList.clearAll();
                            multiselectList.parse(freelancersInDropdown, "json");
                            const alreadySelectedFreelancers = this.$scope.getSelectedFreelancersFromSameRowSlots(this.config.id.toString());
                            //const alreadySelectedFreelancers = this.$scope.getSelectedFreelancersFromAllSlots();
                            const updatedMultiselectList = (this as webix.ui.multiselect).getList() as webix.ui.list;
                            updatedMultiselectList.serialize().map(value => {
                                if(alreadySelectedFreelancers.indexOf(value.id) > -1) {
                                    updatedMultiselectList.disableItem(value.id);
                                } else {
                                    updatedMultiselectList.enableItem(value.id)
                                }
                                if(existingFreelancersIds.indexOf(value.id) > -1) {
                                    updatedMultiselectList.disableItem(value.id);
                                } 
                            });
                        },100);
                    }
                }
            };
        }
        // Let's decide on notes button css class, so we can indicate if note exsits for that particular freelance_package
        let notesButtonCssClass = "cog-button-big-notes";
        if(freelancePackage.package_comment || this.generalNote.length > 0) {
            notesButtonCssClass = "cog-button-big-notes exists";
        }
        let rate = "";
        if(assignments.length === 1) {
            rate = assignments[0]?.rate?.toString();
        }
        const slotId = `${this.singleSlotPrefix}-${freelancePackage.freelance_package.id}`;
        const singleAssignmentBox = {
            id:slotId,
            borderless:true,
            css:{
                "margin-top":"-14px !important;",
                "margin-left":"5px",
            },
            //hidden:((this.autoFocusId) && slotId.toString() !== `${this.singleSlotPrefix}-${this.autoFocusId}`) ? true : false,
            height:200,
            width:359,
            padding:20,
            rows:[
                {
                    view:"label",
                    height:20,
                    id:`${this.totalFeeComponentPrefix}-${freelancePackage.freelance_package.id}`,
                    label:"",
                },
                {
                    cols:[
                        {
                            view: "text",
                            readonly:!isActionPossible,
                            disabled:!isActionPossible,
                            gravity:1,
                            name:"rate",
                            placeholder: i18nHelper.getTranslation("Fee"),
                            id:`${this.rateComponentPrefix}-${freelancePackage.freelance_package.id}`,
                            width:120,
                            value:rate,
                            required:true,
                            on: {
                                onAfterRender:() => {
                                    this.toggleEmptyIndicator();
                                },
                                onBlur:() => {
                                    this.toggleEmptyIndicator()
                                },
                                onTimedKeyPress:() => {
                                    const componentId = `${this.rateComponentPrefix}-${freelancePackage.freelance_package.id}`;
                                    this.updateTotalFeeField(componentId);
                                }
                            }
                        },
                        {
                            view: "datepicker", 
                            readonly:!isActionPossible,
                            disabled:!isActionPossible,
                            gravity:1,
                            editable:true,
                            minWidth:150,
                            name:"deadline",
                            id:`${this.deadlineComponentPrefix}-${freelancePackage.freelance_package.id}`,
                            format:"%d.%m.%Y %H:%i",
                            placeholder: i18nHelper.getTranslation("deadline"),
                            value:formattedDeadline,
                            suggest: {
                                type: "calendar",
                                body: {
                                    timepicker: true,
                                    calendarTime: "%H:%i",
                                    on: {
                                        onBeforeDateSelect:function() {
                                            this.detachEvent("onAfterDateSelect");
                                            const popup = this.getParentView();
                                            const datepicker = webix.$$(popup.config.master) as webix.ui.datepicker;
                                            this.attachEvent("onAfterDateSelect", function(date) {
                                                datepicker.setValue(date)
                                            });
                                        }
                                    },
                                    icons: [
                                        {
                                            template: function(){
                                                return "<span class='webix_cal_icon_done webix_cal_icon'>"
                                                    +i18nHelper.getTranslation("Done")
                                                    +"</span>";
                                            },
                                            on_click:{
                                                "webix_cal_icon_done": function(){
                                                    this.hide();
                                                }
                                            }
                                        },
                                    ]
                                }
                            },
                        },
                    ]
                },
                {
                    cols:[
                        { 
                            view: "text",
                            readonly:!isActionPossible,
                            disabled:!isActionPossible,
                            width:120,
                            name:"units",
                            id:`${this.unitsComponentPrefix}-${freelancePackage.freelance_package.id}`,
                            placeholder: i18nHelper.getTranslation("units"),
                            value:this.getProperUnits(freelancePackage?.uom?.id, calculatedUnits),
                            on: {
                                onBlur:() => {
                                    this.toggleEmptyIndicator()
                                },
                                onTimedKeyPress:() => {
                                    const componentId = `${this.unitsComponentPrefix}-${freelancePackage.freelance_package.id}`;
                                    this.updateTotalFeeField(componentId);
                                }
                            }
                        },
                        freelancerSelectField
                    ]
                },
                {
                    cols:[
                        {
                            view: "combo",
                            readonly:!isActionPossible,
                            disabled:!isActionPossible,
                            id:`${this.uomComponentPrefix}-${freelancePackage.freelance_package.id}`,
                            name:"uom",
                            placeholder: i18nHelper.getTranslation("uom"),
                            width: 120,
                            suggest: {
                                body: {
                                    url: () =>
                                        CognovisCategory.getCategory(
                                            "Intranet UoM"
                                        ),
                                },
                            },
                            value:this.getDefaultUomId(freelancePackage?.uom?.id),
                            on: {
                                onBlur:() => {
                                    this.toggleEmptyIndicator()
                                },
                                onChange:() => {
                                    const componentId = `${this.freelancerComponentPrefix}-${freelancePackage.freelance_package.id}`;
                                    this.updatedFreelancerField(componentId, freelancePackage.target_language.id, freelancePackage.package_type.id);
                                    this.updateTotalFeeField(componentId);
                                }
                            }
                        },
                        { 
                            view: "button",
                            id:`${this.notesButtonComponentPrefix}-${freelancePackage.freelance_package.id}`,
                            height:24,
                            label: i18nHelper.getTranslation("Notes"), 
                            width:200,
                            notesButton:true,
                            comment:freelancePackage.package_comment,
                            freelancePackage:freelancePackage,
                            freelancePackageId:freelancePackage.freelance_package.id,
                            css: `${notesButtonCssClass}`,
                            click:() => {
                                this.openNotesModal("specific", `${this.notesButtonComponentPrefix}-${freelancePackage.freelance_package.id}`, freelancePackage);
                            }
                        }
                    ]
                }
            ]
        }
        // We add all the required package data to that object
        singleAssignmentBox["isPackageSlot"] = isActionPossible;
        singleAssignmentBox["packageData"] = freelancePackage;
        singleAssignmentBox["preSelectedFreelancerIds"] = assignments?.filter(assignment => assignment.assignment_status.id !== IntranetFreelanceAssignmentStatus.DENIED).map(assignment => assignment.assignee.id);
        return singleAssignmentBox;
    }

    getDefaultUomId(packageUomId:number):number {
        if(this.selectedUomId > -1) {
            return this.selectedUomId
        } else {
            return packageUomId
        }
    }

    getProperUnits(packageUomId:number, calculatedUnits:number):number {
        if(packageUomId === this.selectedUomId) {
            return calculatedUnits
        } else {
            return 1
        }
    }

    requestForParticipation():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const potentialAssignments = this.collectPontentialAssignments();
        if(potentialAssignments.length > 0) {
            potentialAssignments.map(potentialAssignment => {
                let parsedDeadline = "";
                if(potentialAssignment.deadline) {
                    parsedDeadline = this.webixHelpers.formatDate(new Date(potentialAssignment.deadline));
                }
                WebixPortalAssignmentService.postTranslationAssignments({
                    freelancerIds:potentialAssignment.freelancerIds as unknown as PersonIds,
                    freelancePackageId:potentialAssignment.freelancePackageId as ImFreelancePackageId,
                    requestBody:{
                        rate:potentialAssignment.rate,
                        assignment_units:potentialAssignment.units,
                        uom_id:potentialAssignment.uomId,
                        assignment_deadline:parsedDeadline
                    }
                })
                .then(() => {
                    this.closeModal();
                    this.cognovisPleaseWaitWindow.hide();
                    CognovisNavigator.navigateTo(`/main/assignments.overview?project_id=${this.projectId}`);
                })
                .catch(err => {
                    webix.alert({
                        text:err.body.message,
                        width:420,
                        type: "alert-error",
                        css:"cog-import-fp-trados-modal"
                    });         
                });
            });
        } else {
            webix.alert({
                text:i18nHelper.getTranslation("Please_create_at_least_one_new_assignment"),
                width:420,
                type: "alert-error",
                css:"cog-import-fp-trados-modal"
            });
        }

    }

    collectPontentialAssignments():ICognovisPontentialAssignment[] {
        const window = ($$(this.windowId) as webix.ui.window);
        const assignmentSlots = window.queryView({isPackageSlot:true}, "all");
        const potentialAssignmentsArr:ICognovisPontentialAssignment[] = [];
        assignmentSlots.map(assignmentSlot => {
            const preSelectedFreelancerIds = assignmentSlot.config["preSelectedFreelancerIds"];
            const freelancerSelector = assignmentSlot.queryView({name:"freelancers"});
            const freelancerId = freelancerSelector.getValue();
            freelancerId.split(",").map(flId => {         
                // Empty 'freelancerId' means there is no one selected, so we omit that assignmnet slot
                if(flId && !freelancerSelector.config.disabled) {
                    if(preSelectedFreelancerIds.filter(id => Number(id) === Number(flId)).length === 0) {
                        const packageData = assignmentSlot.config["packageData"];
                        const rateSelector = assignmentSlot.queryView({name:"rate"});
                        const rate = rateSelector.getValue();
                        const unitsInput = assignmentSlot.queryView({name:"units"});
                        const units = unitsInput.getValue();
                        const uomSelector = assignmentSlot.queryView({name:"uom"});
                        const uomId = uomSelector.getValue();
                        const deadlineInput = assignmentSlot.queryView({name:"deadline"});
                        const deadline = deadlineInput.getValue();
                        potentialAssignmentsArr.push({
                            freelancePackageId:packageData.freelance_package.id,
                            freelancerIds:flId,
                            rate:rate,
                            deadline:deadline,
                            uomId:uomId,
                            units:units
                        });
                    }
                }
            });
        });
        return potentialAssignmentsArr;
    }

    updateTotalFeeField(componentId:string):void {
        const extractedIdPattern = this.extractComponentIdPattern(componentId);
        // First we check if we selected single freelancer
        const currentFlSelection = this.getCurrentFreelancersSelection(componentId);
        const totalFeeField = webix.$$(`${this.totalFeeComponentPrefix}-${extractedIdPattern}`) as webix.ui.label;
        if(currentFlSelection.length === 1 && currentFlSelection[0]) {
            // Figure out total fee
            // First we check units field
            const unitsField = webix.$$(`${this.unitsComponentPrefix}-${extractedIdPattern}`) as webix.ui.text;
            const units = parseFloat(unitsField.getValue());
            const rateField = webix.$$(`${this.rateComponentPrefix}-${extractedIdPattern}`) as webix.ui.text;
            const rate = parseFloat(rateField.getValue());
            const uomField = webix.$$(`${this.uomComponentPrefix}-${extractedIdPattern}`) as webix.ui.text;
            const uomId = parseFloat(uomField.getValue());
            const uom = IntranetUom[uomId];
            if(!isNaN(units) && !isNaN(rate)) {
                const currentSlot = this.getCurrentSlot(componentId);
                const fee = currentSlot["rateObj"];
                let totalPrice = rate * units;
                let formattedTotalFee = PriceHelper.formatPrice(totalPrice, fee.currency);
                if(fee.min_price > totalPrice) {
                    const uomId = IntranetUom.MINPRICE as unknown as string
                    formattedTotalFee = units + ' ' + uom + ' @ ' + PriceHelper.formatPrice(rate, fee.currency) + ' = ' + PriceHelper.formatPrice(totalPrice, fee.currency) 
                    totalPrice = rate;
                    unitsField.setValue('1');
                    rateField.setValue(fee.min_price)
                    uomField.setValue(IntranetUom.UNIT.toString())
                }
                totalFeeField.setValue(formattedTotalFee);
            }
        } else {
            totalFeeField.setValue("");
        }
    }

    getCurrentFreelancersSelection(componentId:string):string[] {
        const extractedIdPattern = this.extractComponentIdPattern(componentId);
        const flFieldSelector = webix.$$(`${this.freelancerComponentPrefix}-${extractedIdPattern}`) as webix.ui.multiselect;
        const flFieldSelectorValue = flFieldSelector.getValue() as string;
        const selection = flFieldSelectorValue.split(",");  
        return selection    
    }

    updatedFreelancerField(componentId:string, targetLanguageId:number, taskTypeId:number):void {
        // To get sibling elements, we must extract id pattern
        const extractedIdPattern = this.extractComponentIdPattern(componentId);
        const currentFlSelection = this.getCurrentFreelancersSelection(componentId);
        let subjectAreaId = "" as unknown as number
        if(this.projectData?.subject_area?.id) {
            subjectAreaId = this.projectData?.subject_area?.id as unknown as number
        }
        // Only if we selected single freelancer
        if(currentFlSelection.length === 1 && currentFlSelection[0]) {
            //Update Fee
            const params = {
                freelancerIds:currentFlSelection as unknown as PersonIds,
                sourceLanguageId:this.projectData.source_language.id,
                targetLanguageId:targetLanguageId,
                uomId:this.getUomFieldValue(extractedIdPattern),
                taskTypeId:taskTypeId
            }
            if(subjectAreaId) {
                params["subjectAreaId"] = subjectAreaId;
            }
            WebixPortalAssignmentService.getFreelancerFee(params)
            .then(fees => {
                const fee = fees[0];
                if(fee) {
                    // We also need to update slot data, so we can later modify total fee without hitting backend all the time
                    const slot = this.getCurrentSlot(componentId);
                    if(slot) {
                        slot["rateObj"] = fee;
                    } else {
                        // Reset
                        slot["rateObj"] = "";
                    }
                    this.updateFeeField(extractedIdPattern, fee.rate.toString(), fee);
                    this.updateTotalFeeField(componentId);
                }
            })
            .catch(err => {
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            });
        } else {
            // Reset rate field
            this.updateFeeField(extractedIdPattern, "");
            this.updateTotalFeeField(componentId);
        }
    }

    extractComponentIdPattern(componentId:string):string {
        const elements = [];
        const seperatedByDash = componentId.split("-");
        seperatedByDash.map((partOfComponentId, index) => {
            if(index > 0) {
                elements.push(partOfComponentId);
            }
        });
        const elementsWithDashes = elements.join("-");
        return elementsWithDashes;
    }

    getUomFieldValue(componentId:string):IntranetUomId {
        const field = webix.$$(`${this.uomComponentPrefix}-${componentId}`) as webix.ui.text;
        const uomId = field.getValue();
        return uomId as unknown as IntranetUomId
    }

    getCurrentSlot(componentId:string):webix.ui.layout {
        
        const extractedIdPattern = this.extractComponentIdPattern(componentId);
        const slot = webix.$$(`${this.singleSlotPrefix}-${extractedIdPattern}`) as webix.ui.layout;
        return slot;
    }

    updateFeeField(componentId:string, value:string, fullFeeObj?: IFreelancerFee):void {
        const field = webix.$$(`${this.rateComponentPrefix}-${componentId}`) as webix.ui.text;
        if(field) {
            field.setValue("");
            if(value) {
                field.setValue(value);
            }
        }
        this.toggleEmptyIndicator();
    }


    toggleEmptyIndicator():void {
        // Taking care of Rate and Units
        const textFields = $$(this.windowId).queryView({ view:"text" }, "all");
            textFields.map(field => {
                if(!field.config.disabled) {
                    const node = field.getNode();
                    if(field.getValue()) {
                        const inputEl = node?.children[0]?.children[0];
                        if(inputEl) {
                            webix.html.removeCss(inputEl,'required-input');
                        }
                    } else {
                        const inputEl = node?.children[0]?.children[0];
                        if(inputEl) {
                            webix.html.addCss(inputEl,'required-input');  
                        }        
                    }
                }
            });
        // Taking care of datepickers
        const dateFields = $$(this.windowId).queryView({ view:"datepicker" }, "all");
        dateFields .map(field => {
            if(!field.config.disabled) {
                const node = field.getNode();
                if(field.getValue()) {
                    const inputEl = node?.children[0]?.children[0];
                    if(inputEl) {
                        webix.html.removeCss(inputEl,'required-input');
                    }
                } else {
                    const inputEl = node?.children[0]?.children[0];
                    if(inputEl) {
                        webix.html.addCss(inputEl,'required-input');  
                    }        
                }
            }
        });
        // Taking care of multiselect
        const multiselectFields = $$(this.windowId).queryView({ view:"multiselect" }, "all");
        multiselectFields.map(field => {
            if(!field.config.disabled) {
                const node = field.getNode();
                if(field.getValue().length > 0) {
                    const inputEl = node?.children[0]?.children[0];
                    if(inputEl) {
                        webix.html.removeCss(inputEl,'required-input');
                    }
                } else {
                    const inputEl = node?.children[0]?.children[0];
                    if(inputEl) {
                        webix.html.addCss(inputEl,'required-input');  
                    }        
                }
            }
        });
        
    }

}
