import BaseView from "views/baseview";
import {i18nHelper} from "../../modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, IDynfieldPermission} from "../../openapi";


type DynfieldAttributeMapColumn = {
    fillspace: boolean; header: any; id: string, template?:any, hidden?:boolean, width?:number, minWidth?:number, maxWidth?:number
}

export default class DynfieldsAttributePermissions extends BaseView {
    
    idPrefix = "dynfattrp";
    title:string;
    objectType:string;

    config():webix.ui.layoutConfig {
        this.objectType = "im_project";
        this.title = i18nHelper.getTranslation("Dynfield Attributes Permissions Settings");
        return this.getCoreLayout();
    }

    init():void {
        const container = webix.$$(`${this.idPrefix}DynfieldsPermissionsSetingsContainer`) as webix.ui.layout;
        container.addView(this.getDatatable());
        this.loadData();
    }

    getCoreLayout():webix.ui.scrollviewConfig {
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                padding:13,
                css:"cog-content",
                width:0,
                height:0,
                autoWidth:true,
                rows: [
                    this.getHeader(),
                    {
                        view:"layout",
                        id:`${this.idPrefix}MainContainer`,
                        height:0,
                        gravity:1,
                        minHeight:300,
                        css:"cog-box-content",
                        rows:[
                            {
                                view:"layout",
                                id:`${this.idPrefix}DynfieldsPermissionsSetingsContainer`,
                                rows:[]
                            }
                        ]
                    }
                ]
            }
        }
    }

    getHeader():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:44,
            cols: [
                {
                    view: "template",
                    type: "header",
                    css: "cog-box-header-no-border",
                    template: this.title,
                    borderless: true
                },
            ]
        }
    }

    getDatatable():webix.ui.datatableConfig {
        //this.showLoader();
        return {
            view: "datatable",
            leftSplit:2,
            width:2000,
            scroll:"xy",
            id: `${this.idPrefix}DynfieldsPermissionsSetingsListTable`,
            resizeRow: false,
            hover: "cog-datatable-hover",
            //tooltip: true,
            columns: [

            ],
        }
    }

    loadData():void {
        CognovisRestDynamicService.getDynfieldPermissions({objectType: this.objectType})
            .then(dynfieldPermissions => {
                const datatable = ($$(`${this.idPrefix}DynfieldsPermissionsSetingsListTable`) as webix.ui.datatable);
                datatable.refreshColumns(this.generateColumns(dynfieldPermissions));
                datatable.define("data", this.generateRows(dynfieldPermissions));
            });
    }

    generateColumns(dynfieldPermissions:IDynfieldPermission[]):DynfieldAttributeMapColumn[] {
        const groups =  dynfieldPermissions.map(dynfieldPermission => dynfieldPermission.group_id);
        const uniqueGroups = [...new Set(groups)];
        const groupsObj:DynfieldAttributeMapColumn[] = [
            {
                id: 'object_type',
                header: i18nHelper.getTranslation("Object type"),
                fillspace: true,
                minWidth: 100
            },
            {
                id: 'dynfield_name',
                header: i18nHelper.getTranslation("Attribute"),
                fillspace: true,
                minWidth: 200
            }
        ];
        uniqueGroups.forEach(group => {
            groupsObj.push({
                id: `rp-${group.toString()}`,
                header:[ { text: i18nHelper.getTranslation(group.toString()), colspan: 2, css: { "text-align": "center", "border-left": "1px solid red" } }, { text:"R", css: { "text-align": "center" }}],
                fillspace: true,
                maxWidth:50
            })
            groupsObj.push({
                id: `rp-${group.toString()}r`,
                header:[null, {text:"W"}],
                fillspace: true,
                maxWidth:50
            })
            groupsObj.push({
                id: `rp-${group.toString()}w`,
                header:[null, {text:"W"}],
                fillspace: true,
                width:20,
                maxWidth:50
            })

        });
        console.log(groupsObj)
        return groupsObj
    }

    generateRows(dynfieldPermissions:IDynfieldPermission[]):any[] {
        const rows = [];
        const alreadyUsedRows = [];
        const columns = this.generateColumns(dynfieldPermissions);
        dynfieldPermissions.forEach(dynfieldPermission => {
            let row = {};
            columns.forEach(column => {
                if(!alreadyUsedRows.includes(dynfieldPermission.dynfield_id)) {
                    alreadyUsedRows.push(dynfieldPermission.dynfield_id);
                    const properRow = dynfieldPermissions.find(permission => permission.dynfield_id === dynfieldPermission.dynfield_id && permission.group_id === dynfieldPermission.group_id)
                    row['id'] = properRow.dynfield_id;
                    row['object_type'] = properRow.object_type;
                    row['dynfield_name'] = properRow.dynfield_name;
                    //row[`rp-${dynfieldPermission.group_id}`] = properRow.privilege;
                    row[`rp-${dynfieldPermission.group_id}r`] = this.renderIcon((properRow.privilege === "read") ? true : false);
                    row[`rp-${dynfieldPermission.group_id}w`] = this.renderIcon((properRow.privilege === "read") ? false : true);
                    rows.push(row);
                }
            });

        })
        return rows
    }

    renderIcon(value:boolean):string {
        if(value) {
            return `<span style='color:#59a8c2' class='webix_icon table-action-icon fas fa-check'></span>`
        } else {
            return `<span style='color:#59a8c2' class='webix_icon table-action-icon'></span>`
        }
    }


}