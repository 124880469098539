/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Id } from '../models/Id';
import type { Ids } from '../models/Ids';
import type { IFreelancerLanguage } from '../models/IFreelancerLanguage';
import type { IFreelancerSkill } from '../models/IFreelancerSkill';
import type { IFreelancerSkillBody } from '../models/IFreelancerSkillBody';
import type { IMainTranslator } from '../models/IMainTranslator';
import type { IMainTranslatorBody } from '../models/IMainTranslatorBody';
import type { ImProjectId } from '../models/ImProjectId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IntranetSkillType } from '../models/IntranetSkillType';
import type { IObjectSkill } from '../models/IObjectSkill';
import type { PersonId } from '../models/PersonId';
import type { PersonIds } from '../models/PersonIds';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebixPortalFreelancerService {

    /**
     * @returns IFreelancerLanguage Array of languages a freelancer can work on in this project
     * @throws ApiError
     */
    public static getFreelancerProjectLanguages({
        freelancerIds,
        projectId,
    }: {
        /**
         * IDs of freelancers we want to check the project language for
         */
        freelancerIds: PersonIds,
        /**
         * ID of the project for which we look up the freelancer languages
         */
        projectId: ImProjectId,
    }): CancelablePromise<Array<IFreelancerLanguage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/freelancer_project_languages',
            query: {
                'freelancer_ids': freelancerIds,
                'project_id': projectId,
            },
        });
    }

    /**
     * Return a list of skills for the freelancer or project.
     * @returns IFreelancerSkill Generic Response description for cog_rest::get::freelancer_skill
     * @throws ApiError
     */
    public static getFreelancerSkill({
        userId,
        skillTypeId,
    }: {
        /**
         * Freelancer we would like to get the skills for
         */
        userId: PersonId,
        /**
         * Skill type we want to filter for
         */
        skillTypeId?: IntranetSkillType,
    }): CancelablePromise<Array<IFreelancerSkill>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/freelancer_skill',
            query: {
                'user_id': userId,
                'skill_type_id': skillTypeId,
            },
        });
    }

    /**
     * Add skills to an object (typically a project or a freelancer)
     * @returns IFreelancerSkill Generic Response description for cog_rest::post::freelancer_skill
     * @throws ApiError
     */
    public static postFreelancerSkill({
        requestBody,
    }: {
        /**
         * Skills we would like to add for the freelancer
         */
        requestBody: IFreelancerSkillBody,
    }): CancelablePromise<Array<IFreelancerSkill>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/freelancer_skill',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a skill from a user
     * @returns IFreelancerSkill List of remaining skills
     * @throws ApiError
     */
    public static deleteFreelancerSkill({
        userId,
        skillId,
    }: {
        /**
         * User who's skillset we want to remove
         */
        userId: PersonId,
        /**
         * Category for the skill we want to remove
         */
        skillId: IntegerInt32,
    }): CancelablePromise<Array<IFreelancerSkill>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/freelancer_skill',
            query: {
                'user_id': userId,
                'skill_id': skillId,
            },
        });
    }

    /**
     * Add skills to an object (typically a project or a freelancer)
     * @returns IFreelancerSkill Generic Response description for cog_rest::put::freelancer_skill
     * @throws ApiError
     */
    public static putFreelancerSkill({
        requestBody,
    }: {
        /**
         * Skills we would like to add for the freelancer
         */
        requestBody: IFreelancerSkillBody,
    }): CancelablePromise<Array<IFreelancerSkill>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/freelancer_skill',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Return a list of main translators for given user_id or company_id
     * @returns IMainTranslator Generic Response description for cog_rest::get::main_translators
     * @throws ApiError
     */
    public static getMainTranslators({
        freelancerId,
        customerId,
    }: {
        /**
         * Freelancer for whom we want to get list of companies where he works as main translator
         */
        freelancerId?: PersonId,
        /**
         * im_company::read Company for which we want to display main translators
         */
        customerId?: string,
    }): CancelablePromise<Array<IMainTranslator>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/main_translators',
            query: {
                'freelancer_id': freelancerId,
                'customer_id': customerId,
            },
        });
    }

    /**
     * Return a list of required skills for an object
     * @returns IObjectSkill Array of object Skills
     * @throws ApiError
     */
    public static getObjectSkill({
        objectId,
        skillTypeId,
    }: {
        /**
         * Object ID of the object (typically project, RFQ, other) for which we want to see required skills
         */
        objectId: IntegerInt32,
        /**
         * Skill type we want to filter for
         */
        skillTypeId?: IntranetSkillType,
    }): CancelablePromise<Array<IObjectSkill>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/object_skill',
            query: {
                'object_id': objectId,
                'skill_type_id': skillTypeId,
            },
        });
    }

    /**
     * Return a list of required skills for an object
     * @returns IObjectSkill Array of object_skill
     * @throws ApiError
     */
    public static postObjectSkill({
        objectId,
        skillIds,
    }: {
        /**
         * ID of the object (typically project, RFQ, other) for which we want to see required skills
         */
        objectId: Id,
        /**
         * Skills of the skill type we want to filter for
         */
        skillIds: Ids,
    }): CancelablePromise<Array<IObjectSkill>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/object_skill',
            query: {
                'object_id': objectId,
                'skill_ids': skillIds,
            },
        });
    }

    /**
     * Deletes a skill from an object
     * @returns IObjectSkill Array of skills after deletion. Only return those of the skill_type associated with the skill_ids
     * @throws ApiError
     */
    public static deleteObjectSkill({
        objectId,
        skillId,
        skillTypeId,
    }: {
        /**
         * ID of the object (typically project, RFQ, other) from which we want to delete the skill
         */
        objectId: Id,
        /**
         * Skills we want to delete
         */
        skillId?: Id,
        /**
         * Skill type we want delete from the object
         */
        skillTypeId?: IntranetSkillType,
    }): CancelablePromise<Array<IObjectSkill>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/object_skill',
            query: {
                'object_id': objectId,
                'skill_id': skillId,
                'skill_type_id': skillTypeId,
            },
        });
    }

    /**
     * Add a main translator to a company
     * @returns IMainTranslator Generic Response description for cog_rest::post::main_translator
     * @throws ApiError
     */
    public static postMainTranslator({
        requestBody,
    }: {
        /**
         * data we need to create a new mapping (user_id, company_id, source_language_id, target_language_id)
         */
        requestBody: IMainTranslatorBody,
    }): CancelablePromise<Array<IMainTranslator>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/main_translator',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a skill from a user
     * @returns IMainTranslator Generic Response description for cog_rest::delete::main_translator
     * @throws ApiError
     */
    public static deleteMainTranslator({
        mappingId,
    }: {
        /**
         * id of mapping which we want to delete
         */
        mappingId: string,
    }): CancelablePromise<Array<IMainTranslator>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/main_translator',
            query: {
                'mapping_id': mappingId,
            },
        });
    }

}
