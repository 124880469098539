import { CognovisRestInvoiceService, IInvoice, IntranetCostType } from "../../../sources/openapi";
import { container } from "tsyringe";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";


export default class MultipleDocumentsCreator extends CognovisBasicModal {

    idPrefix = "mdc";
    projectId:number;
    neededDocumentsTypeId:IntranetCostType = IntranetCostType.CUSTOMER_INVOICE;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    callbackAction:() => void;

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Send E-Mail`), 640,440);
        return mainLayout;
    }

    init():void {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
    }

    openModal(projectId:number, basicDocumentType:IntranetCostType, callbackAction?:() => void):void {
        this.projectId = projectId;
        if(callbackAction) {
            this.callbackAction = callbackAction;
        }
        let modalTitle = i18nHelper.getTranslation("lt_Create_invoice_from_m");
        if(basicDocumentType === IntranetCostType.PURCHASE_ORDER) {
            this.neededDocumentsTypeId = IntranetCostType.PROVIDER_BILL;
            modalTitle = i18nHelper.getTranslation("lt_Create_credit_note_fr");
        }
        CognovisRestInvoiceService.getInvoice({ projectId:this.projectId, costTypeIds:[basicDocumentType] })
        .then(invoices => {
            invoices.map(invoice => invoice["nameAndGrandTotal"] = `${invoice.invoice.name} - ${invoice.first_names} ${invoice.last_name} ${PriceHelper.formatPrice(invoice.grand_total)}`);
            const unlinkedDocumentsOnly = invoices.filter(invoice => !invoice.linked_invoice);
            const modalContent = this.getContent(unlinkedDocumentsOnly);
            const actionButtons = this.getActionButtons();
            this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
        });
    }

    closeModal():void {
        this.hide();
    }

    getContent(documents:IInvoice[]):webix.ui.layoutConfig {
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}DocumentsContainer`,
                        rows:[
                            {
                                view:"layout",
                                paddingY:13,
                                paddingX:10,
                                rows:[
                                    {
                                        view:"button",
                                        inputWidth:85,
                                        height:36,
                                        css:"cog-button-big",
                                        borderless:true,
                                        value:i18nHelper.getTranslation("Select_all"),
                                        click:() => this.toggleSelectAll(),
                                        on: {
                                            onAfterRender:function() {
                                                if(this.$scope.isListEmpty()) {
                                                    this.hide();
                                                } else {
                                                    this.show();
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                view:"list",
                                localId:`${this.idPrefix}DocumentsList`,
                                borderless:true,
                                type:{
                                    isChecked:function(obj){
                                        let checkmark = "fas fa-square";
                                        if(obj.isChecked) {
                                            checkmark = "fas fa-check-square";
                                        }
                                        return `<span class='check webix_icon ${checkmark}'></span>`;
                                    }
                                },
                                template:`{common.isChecked()} #nameAndGrandTotal#`,
                                scheme: {
                                    $init: function(obj) {
                                      obj.isChecked = 0;
                                    }
                                },
                                data:documents,
                                on: {
                                    onItemClick:(id:number) =>  {
                                        const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
                                        const item = list.getItem(id);
                                        item.isChecked = item.isChecked ? 0 : 1;
                                        list.updateItem(id, item);
                                    },
                                    onAfterRender:() =>{
                                        const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
                                        if(this.isListEmpty()) {
                                            webix.extend(list, webix.OverlayBox);
                                            list["showOverlay"](i18nHelper.getTranslation("Nothing_in_here"));
                                        } else {
                                            list["hideOverlay"];
                                        }
                                    }
                                }
                            }
                        ]
                    },                    
                ]
            }
        };
        return layout
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Cancel_and_go_back`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Create_document`)}`,
                            align: "right",
                            click:() => {
                                const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
                                const items = list.serialize();
                                const totalSelectedItems = items.filter(item => item.isChecked).length;
                                if(totalSelectedItems > 0) {
                                    this.generateDocuments();
                                } else {
                                    webix.alert({
                                        title: i18nHelper.getTranslation("Error"),
                                        text: i18nHelper.getTranslation("lt_Select_at_least_one_d"),
                                        type: "alert-error",
                                        width: 500,
                                        css:"cog-remove-tasks-modal"
                                    });
                                }
                            }
                        },    
                    ] 
                };
        return buttons;
    }

    getCheckedItems():IInvoice[] {
        const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
        const items = list.serialize();
        const onlyCheckedItems = items.filter(item => item["isChecked"]);
        return onlyCheckedItems;
    }

    generateDocuments():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const checkedItems = this.getCheckedItems();
        const onlyIds = checkedItems.map(item => item.invoice.id);
        CognovisRestInvoiceService.postInvoiceCopy({
            sourceInvoiceIds:onlyIds,
            targetCostTypeId:this.neededDocumentsTypeId,
            filedP:true
        })
        .then(newInvoice => {
            this.cognovisPleaseWaitWindow.hide();
            CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${newInvoice.invoice.id}&project_id=${newInvoice.project.id}`);
            
        })
        .catch(err => {
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            })
            .finally(() => {
                this.cognovisPleaseWaitWindow.hide();
            });
        });
    }

    isListEmpty():boolean {
        const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
        if(list) {
            const items = list.serialize();
            if(items.length > 0) {
                return false
            } else {
                return true
            }    
        }
    }

    toggleSelectAll():void {
        const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
        const items = list.serialize();
        const totalSelectedItems = items.filter(item => item.isChecked).length;
        items.forEach(item => {
            if(list.count() === totalSelectedItems) {
                item.isChecked = false;
            } else {
                item.isChecked = true;
            }
        });
        list.refresh();
    }

}