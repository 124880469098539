/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetInvoicePaymentMethod {
    /**
     * Transfer via Paypal
     */
    SEPA = 808,
    PAYPAL = 812,
    FOREIGN_TRANSFER = 10000388,
}
