/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetTranslationSubjectArea {
    /**
     * Sachbücher, Unternehmensbücher, Kochbücher, …
     */
    NON_FICTION_BOOKS = 10000537,
    /**
     * Website, Newsletter, Broschüre, Mailing, PR, Whitepaper, Blogbeiträge, Landingpages, Learning Content, Voice Over, Untertitelungen …
     */
    MARKETINGUNTERLAGEN = 10000538,
    /**
     * Verträge, AGB, Datenschutz, …
     */
    RECHTSTEXTE = 10000541,
    /**
     * Bedienungsanleitungen, Datenblätter, …
     */
    TECHNISCHE_DOKUMENTE = 10000544,
    /**
     * Jahresabschlüsse, Finanzdokumente, Bilanzen, …
     */
    FINANZTEXTE = 10000545,
}
