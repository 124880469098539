/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IInvoice } from '../models/IInvoice';
import type { IKolibriPackage } from '../models/IKolibriPackage';
import type { IKolibriUrl } from '../models/IKolibriUrl';
import type { ImProjectId } from '../models/ImProjectId';
import type { IntranetTmTool } from '../models/IntranetTmTool';
import type { ITmTool } from '../models/ITmTool';
import type { PersonId } from '../models/PersonId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IntranetCustKolibriRestService {

    /**
     * Returns the urls to the T:\\ drive for a project
     * @returns IKolibriUrl URLs for accessing Kolibri systems (T:\\ drive)
     * @throws ApiError
     */
    public static getKolibriTUrls({
        projectId,
    }: {
        /**
         * Project ID for which we want to return the url
         */
        projectId: ImProjectId,
    }): CancelablePromise<Array<IKolibriUrl>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/kolibri_t_urls',
            query: {
                'project_id': projectId,
            },
        });
    }

    /**
     * Returns the URL to open the project in the TM Tool - Might just provide the download of a project folder for this project
     * @returns ITmTool Information about the TM Tool
     * @throws ApiError
     */
    public static getKolibriTmToolUrl({
        projectId,
        tmToolId,
    }: {
        /**
         * Project for which we want the TM Tool link
         */
        projectId: ImProjectId,
        /**
         * TM Tool for which we want to get the link
         */
        tmToolId: IntranetTmTool,
    }): CancelablePromise<ITmTool> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/kolibri_tm_tool_url',
            query: {
                'project_id': projectId,
                'tm_tool_id': tmToolId,
            },
        });
    }

    /**
     * Return the list of freelance packages which could be used to update
     * @returns IKolibriPackage Freelance packages which were updated with files.
     * @throws ApiError
     */
    public static getUpdatePackages({
        projectId,
    }: {
        /**
         * Project ID for which we want to import the packages
         */
        projectId: ImProjectId,
    }): CancelablePromise<Array<IKolibriPackage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/update_packages',
            query: {
                'project_id': projectId,
            },
        });
    }

    /**
     * Import the packages from the T: drive into project-open
     * @returns IKolibriPackage Freelance packages which were updated with files.
     * @throws ApiError
     */
    public static putUpdatePackages({
        projectId,
    }: {
        /**
         * Project ID for which we want to import the packages
         */
        projectId: ImProjectId,
    }): CancelablePromise<Array<IKolibriPackage>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/update_packages',
            query: {
                'project_id': projectId,
            },
        });
    }

    /**
     * sends out the provider bills for created purchase orders as well as those<br>    manually created which are still in status created.
     * @returns IInvoice Array of newly send out bills
     * @throws ApiError
     */
    public static putSendProviderBills({
        projectId,
        senderId,
    }: {
        /**
         * Project ID for which we want to import the packages
         */
        projectId: ImProjectId,
        /**
         * Who is sending the E-Mails
         */
        senderId: PersonId,
    }): CancelablePromise<Array<IInvoice>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/send_provider_bills',
            query: {
                'project_id': projectId,
                'sender_id': senderId,
            },
        });
    }

}
