import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, IWidgetBody} from "../../../sources/openapi";

export default class WidgetsEditModal extends CognovisBasicModal {

    widgetId:number;
    idPrefix = "dynfwem";  // short for EditViewModal
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle: string;
    afterSubmitAction: () => void;


    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 960, 720);  // adjust size if needed
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(widgetId?:number, afterSubmitAction?: () => void): void {
        this.modalTitle = i18nHelper.getTranslation("Create new widget");
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTitle);
        this.cognovisPleaseWaitWindow.hide();
        const modal = (this.getRoot() as webix.ui.window);
        modal.show();
        if(widgetId) {
            this.widgetId = widgetId;
            this.setFormValues();
        }
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
    }

    getContent(): webix.ui.layoutConfig {
        // Define your form's layout
        const layout = {
            view: "scrollview",
            body: {
                padding: 13,
                rows: [
                    {
                        view: "form",
                        id: `${this.idPrefix}WidgetEditViewForm`,
                        elements: [
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Widget name"),
                                labelWidth: 160,
                                name: "widget_name"
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Pretty name"),
                                labelWidth: 160,
                                name: "pretty_name"
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Pretty plural"),
                                labelWidth: 160,
                                name: "pretty_plural"
                            },
                            {
                                view: "combo",
                                label: i18nHelper.getTranslation("Storage type"),
                                labelWidth: 160,
                                name: "storage_type_id",
                                value:10005,
                                options: [
                                    {
                                        id: 10005,
                                        value:"Table Column"
                                    },
                                    {
                                        id: 10007,
                                        value:"Multi-Select Mapping Table"
                                    },
                                ]
                            },
                            {
                                view: "combo",
                                label: i18nHelper.getTranslation("ACS datatype"),
                                labelWidth: 160,
                                name: "acs_datatype",
                                value:"integer",
                                options:[
                                    { id: "boolean", value: "Boolean" },
                                    { id: "checkbox_text", value: "Checkbox Text" },
                                    { id: "currency", value: "Currency" },
                                    { id: "date", value: "Date" },
                                    { id: "email", value: "Email" },
                                    { id: "file", value: "File" },
                                    { id: "filename", value: "Filename" },
                                    { id: "float", value: "Float" },
                                    { id: "integer", value: "Integer" },
                                    { id: "keyword", value: "Keyword" },
                                    { id: "naturalnum", value: "Natural Number" },
                                    { id: "party_search", value: "Party Search" },
                                    { id: "radio_text", value: "Radio Text" },
                                    { id: "richtext", value: "Rich Text" },
                                    { id: "richtext_or_file", value: "Richtext or File" },
                                    { id: "search", value: "Search" },
                                    { id: "select_text", value: "Select Text" },
                                    { id: "string", value: "String" },
                                    { id: "text", value: "Text" },
                                    { id: "url", value: "URL" },
                                    { id: "url_element", value: "URL Element" },
                                    { id: "mobile_number", value: "Mobile Number" },
                                    { id: "recurrence", value: "Recurrence" },
                                    { id: "telecom_number", value: "Telecom Number" }
                                ]
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("SQL datatype"),
                                labelWidth: 160,
                                name: "sql_datatype",
                                value:"text"
                            },
                            {
                                view: "combo",
                                label: i18nHelper.getTranslation("Widget type"),
                                labelWidth: 160,
                                name: "widget",
                                value:"text",
                                options:[
                                    { id: "ampmFragment", value: "ampmFragment" },
                                    { id: "attachment", value: "Attachment" },
                                    { id: "block", value: "Block" },
                                    { id: "button", value: "Button" },
                                    { id: "checkbox", value: "Checkbox" },
                                    { id: "checkbox_text", value: "Checkbox Text" },
                                    { id: "comment", value: "Comment" },
                                    { id: "im_cost_center_tree", value: "Cost Center Tree" },
                                    { id: "im_currencies", value: "Currency" },
                                    { id: "date", value: "Date" },
                                    { id: "dateFragment", value: "Date Fragment" },
                                    { id: "file", value: "File" },
                                    { id: "hidden", value: "Hidden" },
                                    { id: "inform", value: "Inform" },
                                    { id: "input", value: "Input Type" },
                                    { id: "menu", value: "Menu Widget Name" },
                                    { id: "monthFragment", value: "Month Fragment" },
                                    { id: "multiselect", value: "Multiselect" },
                                    { id: "numericrange", value: "Numeric Range" },
                                    { id: "party_search", value: "Party Search" },
                                    { id: "password", value: "Password" },
                                    { id: "radio", value: "Radio" },
                                    { id: "radio_text", value: "Radio" },
                                    { id: "richtext", value: "Rich Text" },
                                    { id: "richtext_or_file", value: "Richtext or File" },
                                    { id: "search", value: "Search" },
                                    { id: "select", value: "Select" },
                                    { id: "select_text", value: "Select Text" },
                                    { id: "skype", value: "Skype" },
                                    { id: "submit", value: "Submit" },
                                    { id: "tab", value: "Tab" },
                                    { id: "text", value: "Text" },
                                    { id: "textarea", value: "Textarea" },
                                    { id: "textdate", value: "Text Date" },
                                    { id: "calculated_sql", value: "Calculated SQL" },
                                    { id: "dynfield_hidden", value: "Dynfield Hidden" },
                                    { id: "generic_sql", value: "Generic SQL" },
                                    { id: "generic_tcl", value: "Generic TCL" },
                                    { id: "mobile_number", value: "Mobile Number" },
                                    { id: "recurrence", value: "Recurrence" },
                                    { id: "telecom_number", value: "Telecom Number" },
                                    { id: "im_category_tree", value: "P/O Category" },
                                    { id: "im_checkbox", value: "]po[ Checkbox" },
                                    { id: "select_locales", value: "Select Locales" },
                                    { id: "currency", value: "::template::widget::currency" },
                                    { id: "simple_survey", value: "::template::widget::simple_survey" },
                                    { id: "category", value: "::template::widget::category" },
                                    { id: "richtext_htmlarea", value: "::template::widget::richtext_htmlarea" },
                                    { id: "jq_datetimepicker", value: "::template::widget::jq_datetimepicker" },
                                    { id: "jq_multiselect", value: "::template::widget::jq_multiselect" },
                                    { id: "textarea_internal", value: "::template::widget::textarea_internal" },
                                    { id: "numericRange", value: "::template::widget::numericRange" },
                                    { id: "time_of_day", value: "::template::widget::time_of_day" },
                                    { id: "timestamp", value: "::template::widget::timestamp" }
                                ]
                            },
                            {
                                view: "combo",
                                label: i18nHelper.getTranslation("Dereferencing function"),
                                labelWidth: 160,
                                name: "deref_plpgsql_function",
                                value:"im_name_from_id",
                                options: [
                                    { id: "im_name_from_id", value: "Generic Default (im_name_from_id) - Please choose this if in doubt." },
                                    { id: "im_category_from_id", value: "Category (im_category_from_id) - Converts a category_id to a translated string." },
                                    { id: "im_integer_from_id", value: "Integer (im_integer_from_id) - Doesn't convert integers or other numbers." },
                                    { id: "im_name_from_user_id", value: "User Name (im_name_from_user_id) - Returns a pretty user name." },
                                    { id: "im_email_from_user_id", value: "User Email (im_email_from_user_id) - Returns the user's email address." },
                                    { id: "im_link_from_id", value: "Object Link (im_link_from_id) - Returns a link to the object's page. Only works with business objects." },
                                    { id: "im_traffic_light_from_id", value: "Traffic Light (im_traffic_light_from_id) - Returns a red, yellow or green GIF for traffic light status of projects." },
                                    { id: "im_cost_center_name_from_id", value: "Cost Center Name (im_cost_center_name_from_id) - Returns the cost center's name." },
                                    { id: "im_numeric_from_id", value: "Numeric (im_numeric_from_id) - Returns a float value." },
                                    { id: "im_boolean_from_id", value: "Boolean (im_boolean_from_id) - Returns a true/false value." }
                                ]
                            },
                            {
                                view: "textarea",
                                label: i18nHelper.getTranslation("Parameters"),
                                labelWidth: 160,
                                name: "parameters"
                            },
                        ]
                    }
                ]
            }
        };
        return layout;
    }


    setFormValues():void {
        const form = webix.$$(`${this.idPrefix}WidgetEditViewForm`) as webix.ui.form;
        CognovisRestDynamicService.getDynfieldWidgets({widgetId:this.widgetId})
            .then(widgets => {
                if(widgets[0]) {
                    const widget = widgets[0];
                    this.setTitle(widget.widget_name);
                    Object.entries(widget).forEach(([key, value]) => {
                        const element = form.queryView({name:key});
                        if(element) {
                            element.setValue(value);
                        }
                    });
                }
            });
    }

    getActionButtons(): webix.ui.layoutConfig {
        return {
            view: "layout",
            padding: 0,
            cols: [
                {
                    view: "button",
                    value: `${i18nHelper.getTranslation('Close')}`,
                    click: () => {
                        this.hide();
                    }
                },
                {
                    view: "button",
                    localId: `${this.idPrefix}SaveButton`,
                    value: `${i18nHelper.getTranslation('Save')}`,
                    click: () => {
                        this.submit();
                    }
                }
            ]
        };
    }

    submit(): void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait") });
        const form = webix.$$(`${this.idPrefix}WidgetEditViewForm`) as webix.ui.form;
        const values = form.getValues();
        if(this.widgetId) {
            CognovisRestDynamicService.putDynfieldWidget({
                widgetId: this.widgetId,
                requestBody: values as IWidgetBody
            })
                .then(result => {
                    this.afterSubmitAction();
                    this.cognovisPleaseWaitWindow.hide();
                    this.hide();
                });
        } else {
            CognovisRestDynamicService.postDynfieldWidget({requestBody:values as IWidgetBody})
                .then(result => {
                    this.afterSubmitAction();
                    this.cognovisPleaseWaitWindow.hide()
                    this.hide();
                });
        }
    }
}

