import DynfieldCreationModal from "../../../sources/modules/cog-dynfields/dynfield-creation-modal";
import {CognovisRestCompanyService, ICompanyContact, ICompanyContactBody} from "../../../sources/openapi";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { finished } from "stream";


export default class DynCompanyContactModal extends DynfieldCreationModal implements IBuildsDynfieldCreationModal {

    firstFocusFieldName = "email";
    afterShow():void {
        this.setFormValues(this.defaultValues);
    }
    companyId:number;
    customEvents:{[key: string]: string | number | Function}[] = [
        {
            field:"email",
            type:"onBlur",
            action:function() {
                const email = this.getValue();
                if(email && email.length >= 3) {
                    CognovisRestCompanyService.getCompanyContacts({
                        email:email
                    })
                    .then(users => {   
                        if(users[0] && this?.$scope?.setFormValues) {
                            const extractedValues = this.$scope.extractValuesFromObject(users[0]);
                            this.$scope.setFormValues(extractedValues);
                            this.$scope.makeReadonly();
                            this.$scope.updateButtonLabel("SaveButton",i18nHelper.getTranslation("Use"));
                        }
                    });
                }
            }
        }
    ];

    submit():void {
        let companyId;
        const npForm = webix.$$("npNewOfferForm") as webix.ui.form;
        if(npForm) {
            companyId = npForm.getValues()["company_id"];
        } else {
            companyId = this.getParam("company_id", true);
        }
        const root = this.getRoot() as webix.ui.layout;
        const form = root.queryView({view:"form"}) as webix.ui.form;
        const values = form.getValues();
        if(!this.dynfieldsConfig.checkForRequiredFields || (form.validate({hidden:false,disabled:false}) && this.dynfieldsConfig.checkForRequiredFields)) {
            this.cognovisPleaseWaitWindow.show({message: i18nHelper.getTranslation("Please_wait")});
            CognovisRestCompanyService.postCompanyContact({
                companyId:companyId,
                requestBody:{
                    first_names:values.first_names,
                    last_name:values.last_name, 
                    email:values.email,
                    password:values.password,
                    password_confirm:values.password_confirm,
                    salutation_id:values.salutation_id,
                    work_phone:values.work_phone,
                    cell_phone:values.cell_phone,
                    position:"",
                    screen_name:values.screen_name
                } as unknown as ICompanyContactBody
            })
            .then((companyContact:ICompanyContact) => {
                CognovisRestCompanyService.getCompanies({companyId:companyId})
                .then(companies => {
                    if(companies[0]?.primary_contact?.id) {
                        this.finish(companyContact);
                    } else {
                        CognovisRestCompanyService.putCompany({companyId:companyId, requestBody:{
                            accounting_contact_id:companyContact.contact.id,
                            primary_contact_id:companyContact.contact.id,
                            company_name:companyContact.company.name
                        }})
                        .then(() => {
                            this.finish(companyContact);
                        });
                    }
                });
            });
        }
    }

    finish(companyContact:ICompanyContact):void {
        const npForm = webix.$$("npNewOfferForm") as webix.ui.form;
        this.cognovisPleaseWaitWindow.hide();
        this.close();
        if(!npForm) {
            window.location.reload()
        } else {
            this.customAfterSubmitAction(companyContact);
        }
    }

    setFormValues(values:{[key: string]: string | number}):void {
        const root = this.getRoot() as webix.ui.layout;
        const form = root.queryView({view:"form"}) as webix.ui.form;
        form.setValues(values);
    }



}