/* 

Page to display the finances of a project

*/

import ProjectDetails from "views/project-details";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";
import {
    CognovisRestInvoiceService,
} from "../../../sources/openapi/index";

import './project-finances.style.css'

export default class Invoices extends ProjectDetails {
    
    projectId: number;

    config(): webix.ui.scrollviewConfig {
        this.projectId = this.getParam("project_id", false);
        return {
            view: "scrollview",
            scroll: "y",
            css: "parent-container",
            body: {
                view: "layout",
                type: "clean",
                responsive: true,

                cols: [
                    {
                        view: "layout",
                        type: "clean",
                        rows: [
                            {
                                view: "template",
                                type: "header",
                                template: "Invoices",
                                css: "invoice__header datatable-title  heading--title",
                                height: 40,
                            },
                            {
                                view: "datatable",
                                css: "datatable invoice__datatable",
                                select: "row",
                                spans: true,
                                tooltip: true,
                                autoHeight: true,
                                columns: [
                                    {
                                        id: "cost_name",
                                        template: "#cost.name#",
                                        header: [
                                            "INVOICE NAME",
                                            {
                                                content: "textFilter",
                                                tooltip:
                                                    "type in an invoice name",
                                            },
                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },
                                    {
                                        id: "cost_type",
                                        template: "#cost_type.name#",
                                        header: [
                                            "PROJECT TYPE",
                                            {
                                                content: "textFilter",
                                                tooltip:
                                                    "type in a project type",
                                            },
                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },
                                    {
                                        id: "target_language",
                                        template:function(obj){
                                            if(obj.target_language) {
                                                return obj.target_language.map(lang => lang.name).join(",")
                                            } else {
                                                return ""
                                            }
                                           
                                        },
                                        header: [
                                            "TARGET LANGUAGE",
                                            {
                                                content: "selectFilter",
                                                tooltip:
                                                    "type in a target language",
                                            },
                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },
                                    {
                                        id: "amount",
                                        header: [
                                            "PRICE",
                                            {
                                                content: "selectFilter",
                                                tooltip: "type in a price",
                                            },
                                        ],
                                        sort: "int",
                                        fillspace: true,
                                        template(obj) {
                                            return PriceHelper.formatPrice(obj.amount, obj.currency_symbol);
                                        }
                                    },
                                    {
                                        id: "cost_status",
                                        template: "#cost_status.name#",
                                        header: [
                                            "INVOICE STATUS",
                                            {
                                                content: "textFilter",
                                                tooltip:
                                                    "type in an invoice status",
                                            },


                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },

                                    { view: "spacer", width: 60 },
                                ],

                                url: () => {
                                    return CognovisRestInvoiceService.getInvoice(
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        { projectId: this.projectId }
                                    )
                                },
                                on: {
                                    onItemClick: function (row) {
                                        const item = this.getItem(row);
                                        CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${item.cost.id}`);
                                    },
                                },
                            },
                            {
                                view: "spacer",
                                height: 50,
                                css: "spacer__invoice__view__last__row",
                            },
                        ],
                    },
                ],
            },
        };
    }

}
