
import { CognovisRestCompanyService,  ICategoryObject, ICompanyContact, ISearchResult, WebixPortalSearchService } from "../../../sources/openapi";
import { IJetApp, JetView } from "webix-jet";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { FileHelper } from "../../../sources/modules/file-helpers/file-helpers";
import SendMailModal from "../../../sources/views/modals/send-mail-modal"
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import { UserProfile } from "../../../sources/modules/cognovis-profile/profiles-types";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";

export default class SearchComponent extends JetView {

    idPrefix = "sc";
    currentlySelectedTab = `${this.idPrefix}-all`;
    webixHelpers:WebixHelpers;
    searchResults:ISearchResult[];
    totals:{
        objectTypeName:string,
        total:number
    }[] = [];
    objectTypes = [
        {
            name:"im_project",
            label:i18nHelper.getTranslation("Projects")
        },
        {
            name:"user",
            label:i18nHelper.getTranslation("Users")
        },
        {
            name:"im_company",
            label:i18nHelper.getTranslation("Companies")
        },
        {
            name:"im_invoice",
            label:i18nHelper.getTranslation("Financial_documents")
        },
    ];
    sendMailModal:SendMailModal;
    lastUsedQuery = "";

    constructor(app:IJetApp) {
        super(app, {});
        this.webixHelpers = container.resolve(WebixHelpers);
    }

    config():webix.ui.layoutConfig {
        let hidden = true;
            if(CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN) || CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER)) {
                hidden = false;
            }
            const container = {
                view: "layout",
                gravity:1,
                animate:true,
                hidden:hidden,
                localId:`${this.idPrefix}SearchComponent`,
                rows:[
                    this.getSearchBar(),
                ],
            };
            return container
    }


    getSearchBar():webix.ui.layoutConfig {
        const searchbar = {
            view:"search", 
            width: 250,
            css:"animate-width",
            on: {
                onKeyPress:(key) => {
                    const search = (this.getRoot() as webix.ui.view).queryView({view:"search"});
                    const value = search.getValue();
                    search.focus();
                    if(value.length >= 3) {
                        if(key === 9 || key === 13) {
                            this.executeSearch(value);
                            this.lastUsedQuery = value;
                        }
                    }
                    // We close searchbar if key pressed is ESC (27)
                    if(key === 27) {
                        this.closeSearch(true);
                    }
                },

                onFocus:() => {
                    const search = (this.getRoot() as webix.ui.view).queryView({view:"search"});
                    search.define("width", 650);
                    search.resize();
                    setTimeout(() => {
                        const value = search.getValue();
                        if(value.length >= 3 && this.lastUsedQuery !== value) {
                            this.executeSearch(value);
                            this.lastUsedQuery = value;
                        }
                        search.focus();
                    },1000) as unknown as NodeJS.Timeout;
                },
                onBlur:() => {
                    this.closeSearch();
                }
            }
        };
        return searchbar;
    }

    getSearchResultsBox():webix.ui.popup {
        let popup = webix.$$(`${this.idPrefix}SearchResultsBox`) as webix.ui.popup;
        const tabs = this. getTopFilteringTabs();
        const rows = [tabs];
        this.objectTypes.map(oType => {
            rows.push(this.getObjectResultTable(oType.name, oType.label));
        });
        if(!popup) {
            const container = (this.$$(`${this.idPrefix}SearchComponent`) as webix.ui.search);
            const node = container.getNode();
            const left = node.getBoundingClientRect().left;
            popup =  webix.ui({
                view:"popup",
                id:`${this.idPrefix}SearchResultsBox`,
                padding:30,
                width:650,
                autoheigth:true,
                left:left,
                top:38,
                body:{
                    rows:rows
                },

            }) as unknown as webix.ui.popup
        } 
        webix.extend(popup, webix.ProgressBar);
        return popup;
    }

    getTopFilteringTabs():webix.ui.layoutConfig {
        const tabs = {
            view:"layout",
            id:`${this.idPrefix}SearchTabsContainer`,
            cols:[
                // {
                //     view:"button",
                //     id:`${this.idPrefix}-all`,
                //     relatedObjectType:"none",
                //     css:(this.currentlySelectedTab === `${this.idPrefix}-all` ? "cog-search-fitler-button selected" : "cog-search-fitler-button"),
                //     width:90,
                //     height:30,
                //     value:i18nHelper.getTranslation("All"),
                //     originalLabel:i18nHelper.getTranslation("All"),
                //     click:(button) => this.switchTab(button)
                // },
                {
                    view:"button",
                    id:`${this.idPrefix}-im_project`,
                    relatedObjectType:"im_project",
                    css:(this.currentlySelectedTab === `${this.idPrefix}-im_project` ? "cog-search-fitler-button selected" : "cog-search-fitler-button"),
                    width:110,
                    height:30,
                    value:i18nHelper.getTranslation("Projects"),
                    originalLabel:i18nHelper.getTranslation("Projects"),
                    click:(button) => this.switchTab(button)
                },
                {
                    view:"button",
                    id:`${this.idPrefix}-user`,
                    relatedObjectType:"user",
                    css:(this.currentlySelectedTab === `${this.idPrefix}-user` ? "cog-search-fitler-button selected" : "cog-search-fitler-button"),
                    width:90,
                    height:30,
                    value:i18nHelper.getTranslation("Users"),
                    originalLabel:i18nHelper.getTranslation("Users"),
                    click:(button) => this.switchTab(button)
                },
                {
                    view:"button",
                    id:`${this.idPrefix}-im_company`,
                    relatedObjectType:"im_company",
                    css:(this.currentlySelectedTab === `${this.idPrefix}-im_company` ? "cog-search-fitler-button selected" : "cog-search-fitler-button"),
                    width:120,
                    height:30,
                    value:i18nHelper.getTranslation("Companies"),
                    originalLabel:i18nHelper.getTranslation("Companies"),
                    click:(button) => this.switchTab(button)
                },
                {
                    view:"button",
                    id:`${this.idPrefix}-im_invoice`,
                    relatedObjectType:"im_invoice",
                    css:(this.currentlySelectedTab === `${this.idPrefix}-im_invoice` ? "cog-search-fitler-button selected" : "cog-search-fitler-button"),
                    width:170,
                    height:30,
                    value:i18nHelper.getTranslation("Financial_documents"),
                    originalLabel:i18nHelper.getTranslation("Financial_documents"),
                    click:(button) => this.switchTab(button)
                }
            ]
        };
        return tabs;
    }

    switchTab(newTabId:string):void {
        this.currentlySelectedTab = newTabId;
        this.updateTabsCss(newTabId);
        this.toggleTables(newTabId);
    }

    clearFilters():void {
        this.switchTab(`${this.idPrefix}-all`);
        this.updateSearchResultsTotals([]);
    }

    toggleTables(newTabId:string):void {
        const tab = (webix.$$(`${newTabId}`) as webix.ui.button);
        const searchResultsBox = (webix.$$(`${this.idPrefix}SearchResultsBox`)) as webix.ui.layout;
        const tables = (searchResultsBox.queryView({view:"layout"}, "all") as webix.ui.layout[]).filter(table => table.config["containerType"] === "tableContainer");
        tables.map(table => {
            const tableItself = table.queryView({view:"datatable"}) as webix.ui.datatable;
            if(newTabId === `${this.idPrefix}-all`) {
                // Determine height for each column.  It depends of number of categories that returned some results
                const numberOfCategoriesWithinResults = this.totals.filter(type => type.total > 0).length;
                const height = Math.ceil(650/numberOfCategoriesWithinResults);
                tableItself.define("maxHeight", height);
            } else {
                tableItself.define("maxHeight", 650);
            }
            tableItself.refresh();
            table.hide();
        });
        tables.map(table => {
            if(newTabId === `${this.idPrefix}-all`) {
                const relatedObjectType = table.config["relatedObjectType"];
                const filteredResults = this.searchResults.filter(result => result.object.object_type === relatedObjectType);
                if(filteredResults.length > 0) {
                    table.show();
                }
            } else {
                if(tab) {
                    if(`${this.idPrefix}-${tab.config["relatedObjectType"]}-tablecontainer` === table.config.id) {
                        table.show();
                    } 
                }
            }
        });
    }

    updateTabsCss(newTabId:string):void {
        const tabsContainer = (webix.$$(`${this.idPrefix}SearchTabsContainer`)) as webix.ui.layout;
        const buttons = tabsContainer.queryView({view:"button"}, "all") as webix.ui.button[];
        buttons.map(button => {
            const buttonId = button.config.id;
            if(buttonId === newTabId) {
                webix.html.addCss(button.getNode(), "selected")
            } else {
                webix.html.removeCss(button.getNode(), "selected");
            }
        });
    }

    updateGlobalTotals(types:ISearchResult[]):void {
        this.totals = [];
        const possibleTypes = ["im_project", "user", "im_company", "im_invoice"];
        possibleTypes.map(possibleType => {
            this.totals.push({
                objectTypeName:possibleType,
                total:types.filter(type => type.object.object_type === possibleType).length
            });
        });
    }

    updateSearchResultsTotals(types:ISearchResult[]):void {
        this.updateGlobalTotals(types);
        const tabsContainer = (webix.$$(`${this.idPrefix}SearchTabsContainer`)) as webix.ui.layout;
        const buttons = tabsContainer.queryView({view:"button"}, "all") as webix.ui.button[];
        // // Calculate and set total value regardless of type
        // const allTab = webix.$$(`${this.idPrefix}-all`) as webix.ui.button & {config:{relatedObjectType:string,originalLabel:string}};
        // const totalResults = types.length;
        // let newValue = `${allTab.config.originalLabel} (${totalResults})`;
        // if(types.length === 0) {
        //     newValue = allTab.config.originalLabel;
        // }
        // allTab.define("value", newValue);
        // allTab.refresh();
        // Calculate and set totals for specific object types
        buttons.map((button:webix.ui.button & {config:{relatedObjectType:string,originalLabel:string}}) => {
            const relatedObjectType = button.config.relatedObjectType;
            const total = types.filter(type => type.object.object_type === relatedObjectType).length;
            const tab = (webix.$$(`${this.idPrefix}-${relatedObjectType}`) as webix.ui.button);
            if(tab) {
                let newValue = `${button.config.originalLabel} (${total})`;
                if(types.length === 0) {
                    newValue = button.config.originalLabel;
                }
                tab.define("value", newValue);
                tab.refresh();
            }
        });
    }

    getObjectResultTable(objectTypeName:string, objectTypeLabel:string):webix.ui.layoutConfig {
        const table = {
            view:"datatable",
            borderless:true,
            id:`${this.idPrefix}-${objectTypeName}-table`,
            header:false,
            width:750,
            css:"search-table",
            autoheigth:true,
            tooltip:true,
            columns:[
                {
                    id:"result_icon",
                    tooltip:false,
                    width:40,
                    template:((result:ISearchResult) => {
                        if(result.object.type_icon) {
                            return `<span style='color:${result.object.status_color} !important;' class='webix_icon ${result.object.type_icon}'></span>`;
                        } else {
                            return ``;
                        }
                    })
                },
                {
                    id:"name",
                    map:"#search_text#",
                    fillspace:true
                },
                {
                    id:"actions",
                    align:"right",
                    css: {
                        "text-align":"right"
                    },
                    tooltip:() => {
                        return ""
                    },
                    template:((obj:ISearchResult) => {
                        let html = "";
                        if(obj.actions) {
                            const actions = obj.actions;
                            actions.map(action => {
                                html = html + `<span webix_tooltip='${action.action_help}' data-action-type='${action.action_type.id}' class='webix_icon ${action.action_icon}'></span>`;
                            });
                        }
                        return html
                    })
                }
            ],
            on: {
                onItemClick:(row, event) => {
                    const table = webix.$$(`${this.idPrefix}-${objectTypeName}-table`) as webix.ui.datatable;
                    const item = table.getItem(row.row) as ISearchResult;
                    if(row.column === "name") {
                        let linkedObjectId:number | boolean = false;
                        if(item.actions?.find(action => action.action_object.object_type === "im_project")) {
                            linkedObjectId = item.actions.find(action => action.action_object.object_type === "im_project").action_object.id;
                        }
                        CognovisNavigator.goToObjectDetails(item.object.id, item.object.object_type, linkedObjectId as number);
                        this.closeSearch(true);
                    }
                    if(row.column === "actions") {
                        const actionType = Number(event.target.dataset.actionType);
                        const actionData = item.actions.find(action => action.action_type.id === actionType)
                        this.executeAction(actionType, item, actionData as unknown as {id:number, action_text:string, object_id:number});   
                        this.closeSearch(true);
                    }
                }
            }
        }
        const tableContainer = {
            view:"layout",
            hidden:true,
            id:`${this.idPrefix}-${objectTypeName}-tablecontainer`,
            relatedObjectType:objectTypeName,
            containerType:"tableContainer",
            rows:[
                {
                    view:"label",
                    type:"header",
                    label:`${objectTypeLabel}`
                },
                table,
                {
                    view:"spacer",
                    height:30
                }
            ]
        };
        return tableContainer
    }

    executeAction(
        actionType:number, 
        searchResult:ISearchResult,
        actionData:{
            action_type?: ICategoryObject,
            action_text?: string,
            action_help?: string,
            action_icon?: string,
            action_object?: {id:number, action_text:string, object_id:number},
        }
    ):void {
        switch(actionType) {
            case 14302:
                // Send mail to the user
                this.openSendMailModal(actionData.action_object.id, actionData.action_text, actionData.action_object.id);
                break;
            case 14304:
                // Open company details (old interface)
                CognovisNavigator.goToObjectDetails(actionData.action_object.id, "im_company");
                this.closeSearch();
                break;
            case 14305:
                // Open user details (old interface)
                CognovisNavigator.goToObjectDetails(actionData.action_object.id, "user");
                this.closeSearch();
                break;
            case 14308:
                // Direct download of file
                FileHelper.downloadCrFile(actionData.action_object.id);
                break;
        }
    }
    
    executeSearch(query:string):void {        
        // Projects
        const popup = this.getSearchResultsBox();
        popup.show();
        (popup as unknown as webix.ProgressBar).showProgress();
        this.objectTypes.map(oType => {            
            const table = webix.$$(`${this.idPrefix}-${oType.name}-table`) as webix.ui.datatable;
            table.clearAll();
        });
        const search = (this.getRoot() as webix.ui.view).queryView({view:"search"});
        search.focus();
        WebixPortalSearchService.getWebixSearch({
            query:query
        })
        .then(searchResults => {
            this.searchResults = searchResults;
            this.clearFilters();
            // We want to find the object_type with highest amount of results
            // First we create temp array which holds only object_type_name
            const onlyObjectTypes = searchResults.map(searchResult => searchResult.object.object_type);
            // Now we are looking for the most common element
            const mostCommonOne = this.webixHelpers.getHighestOccurance(onlyObjectTypes);
            this.updateSearchResultsTotals(searchResults);
            (popup as unknown as webix.ProgressBar).hideProgress();
            this.objectTypes.map(oType => {            
                const container = webix.$$(`${this.idPrefix}-${oType.name}-tablecontainer`) as webix.ui.layout;
                const table = webix.$$(`${this.idPrefix}-${oType.name}-table`) as webix.ui.datatable;
                const filteredResults = searchResults.filter(result => result.object.object_type === oType.name);
                if(filteredResults.length > 0) {
                    table.define("data", filteredResults);
                    container.show();
                } else {
                    table.clearAll();
                    container.hide();
                }
            });
            webix.delay(() => {
                this.switchTab(`${this.idPrefix}-${mostCommonOne}`);
            },{}, [], 400);
        });
    }

    closeSearch(ignorePopup = false):void {
        setTimeout(() => {
            const popup = webix.$$(`${this.idPrefix}SearchResultsBox`) as webix.ui.popup;
            if(popup) {
                if(!ignorePopup) {
                    if(!popup.isVisible()) {
                        const search = (this.getRoot() as webix.ui.view).queryView({view:"search"});
                        search.define("width", 250);
                        search.resize();
                        search.setValue("");
                        popup.hide();
                        search.blur();
                    }
                } else {
                    const search = (this.getRoot() as webix.ui.view).queryView({view:"search"});
                    search.define("width", 250);
                    search.resize();
                    search.setValue("");
                    popup.hide();        
                    search.blur();
                }
            }
        },100);
    }

    openSendMailModal(recipientId:number, defaultBody:string, contextId?:number):void {
        if(!this.sendMailModal) {
            this.sendMailModal = this.ui(SendMailModal) as SendMailModal;
        }
        const loginInfo = webix.storage.local.get("wjet_user");
        const fakedUserObj = webix.storage.session.get("cognovis_faked_user_data");
        let valueUserId = loginInfo.user_id;
        if (fakedUserObj) {
            valueUserId = fakedUserObj.user_id;
        }
        
        CognovisRestCompanyService.getCompanyContacts({
            contactIds:[valueUserId, recipientId]
        })
        .then(senderAndRecipients => {
            let sender:ICompanyContact;
            let recipient:ICompanyContact;
            senderAndRecipients.map(sendOrRecipient => {
                if(sendOrRecipient.contact.id === valueUserId) {
                    sender = sendOrRecipient
                } else {
                    recipient = sendOrRecipient;
                }
            });
            this.sendMailModal.openModal(false, contextId, [
                {
                    name:"sender_email",
                    editable:false,
                    defaultValue:valueUserId,
                    visible:true,
                    options:[{
                        id:valueUserId,
                        value:`${sender.first_names} ${sender.last_name}`
                    }]
                },
                {
                    name:"cc_email",
                    visible:false
                },
                {
                    name:"subject",
                    visible:true,
                    editable:true,
                    autofocus:true
                },
                {
                    name:"recipient_email",
                    editable:false,
                    defaultValue:recipientId,
                    visible:true,
                    options:[{
                        id:recipientId,
                        value:`${recipient.first_names} ${recipient.last_name}`
                    }]              
                },
                {
                    name:"message_body",
                    defaultValue:defaultBody,
                    visible:true,
                    editable:true
                }
            ]);
        });
    }

}