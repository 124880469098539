/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetProjectSource {
    NORMAL = 9820,
    CUSTOMER_PORTAL = 9821,
    PM_PORTAL = 9822,
    WORDPRESS_PLUGIN = 9823,
}
