import BaseView from "views/baseview";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, IDynfieldWidget, IView} from "../../../sources/openapi";
import DynviewsEditModal from "./dynviews-edit-modal";
import DynviewColumnModal from "./dynviews-columns-edit-modal";


export default class DynviewsSettings extends BaseView {

    idPrefix = "dyns";
    title:string;
    dynviewEditModal:DynviewsEditModal;
    dynviewColumnModal:DynviewColumnModal;


    config():webix.ui.layoutConfig {
        this.title = i18nHelper.getTranslation("Dynviews Settings");
        return this.getCoreLayout();
    }

    init():void {
        const container = webix.$$(`${this.idPrefix}DynviewSettingsContainer`) as webix.ui.layout;
        container.addView(this.getDynviewsDatatable());
    }

    getCoreLayout():webix.ui.scrollviewConfig {
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                padding:13,
                css:"cog-content",
                width:0,
                height:0,
                autoWidth:true,
                rows: [
                    this.getTopButtons(),
                    this.getHeader(), 
                    {
                        view:"layout",
                        id:`${this.idPrefix}MainContainer`,
                        height:0,
                        gravity:1,
                        minHeight:300,
                        css:"cog-box-content",
                        rows:[
                            {
                                view:"layout",
                                id:`${this.idPrefix}DynviewSettingsContainer`,
                                rows:[]
                            }
                        ]
                    }
                ]
            }
        } 
    }

    getHeader():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header-no-border", 
                    template: this.title, 
                    borderless: true 
                },
            ]
        } 
    }

    getTopButtons():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:40,
            gravity:1,
            css:{
                "margin":"2px 0"
            },
            cols:[
                {
                    view:"spacer",
                    width:0,
                },
                {
                    view:"button",
                    width:100,
                    inputWidth:100,
                    css:"cog-button-big",
                    label:i18nHelper.getTranslation("New view"),
                    click:() => {
                        this.openViewDetailsModal()
                    }
                }
            ]
        }
    }

    getDynviewsDatatable():webix.ui.datatableConfig {
        this.showLoader();
        return {
            view: "datatable",
            id: `${this.idPrefix}DynviewsListTable`,
            resizeRow:false,
            hover:"cog-datatable-hover",
            tooltip:true,
            columns:[
                {
                    id:"view_name",
                    fillspace:true,
                    sort:"text",
                    header: [i18nHelper.getTranslation("View name"),  { content:"textFilter"}],
                },
                {
                    width:42,
                    id:"view-details",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Edit view"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-solid fa-magnifying-glass'></span>`;
                    }
                },
                {
                    width:42,
                    id:"action-edit-columns",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Edit view columns"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-table-layout'></span>`;
                    }
                },
                {
                    width:42,
                    id:"action-delete",
                    header:" ",
                    tooltip:i18nHelper.getTranslation("Delete widget"),
                    template:() => {
                        return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-trash'></span>`;
                    }
                }
            ],
            url:() => {
                return CognovisRestDynamicService.getViews({})
                .then(views => {
                    this.hideLoader();
                    return views
                });
            },
            on: {
                onItemClick:(row) => {
                    const table = webix.$$(`${this.idPrefix}DynviewsListTable`) as webix.ui.datatable;
                    const item:IView = table.getItem(row);
                    switch(row.column) {
                        case "view-details":
                            this.openViewDetailsModal(item.view_id);
                            break;
                        case "action-edit-columns":
                            this.openColumnsDetailsModal(item.view_id)
                            break;
                        case "action-delete":
                            this.attemptToDeleteView(item)
                            break;
                    }
                }
            },
        }
    }

    attemptToDeleteView(view:IView):void {
        const declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_delete_view?");
        webix.confirm({
            title: view.view_name,
            type: "confirm-warning",
            text: declineOrAccept,
            width: 480
        })
            .then(() => {
                this.deleteView(view.view_id)
            });
    }

    deleteView(viewId:number):void {
        CognovisRestDynamicService.deleteView({
            viewId: viewId
        }).then(() => {
            this.refreshTable();
        });
    }

    refreshTable():void {
        const table = webix.$$(`${this.idPrefix}DynviewsListTable`) as webix.ui.datatable;
        if(table) {
            this.showLoader();
            table.clearAll();
            CognovisRestDynamicService.getViews({})
                .then(views => {
                    this.hideLoader();
                    table.define("data", views);
                    table.refresh()
                });
        }
    }

    showLoader():void {
        const container = webix.$$(`${this.idPrefix}DynviewSettingsContainer`) as webix.ui.layout & {showProgress?:(text:string) => void}
        webix.extend(container, webix.ProgressBar);
        container.showProgress(i18nHelper.getTranslation("Please_wait"));
        if(container.showProgress) {
            container.showProgress(i18nHelper.getTranslation("Please_wait"));
        }
    }

    hideLoader():void {
        const container = webix.$$(`${this.idPrefix}DynviewSettingsContainer`) as webix.ui.layout & {hideProgress?:() => void}
        container.hideProgress();
    }

    openViewDetailsModal(itemId?:number):void {
        if(!this.dynviewEditModal) {
            this.dynviewEditModal = this.ui(DynviewsEditModal) as DynviewsEditModal;
        }
        if(itemId) {
            this.dynviewEditModal.openModal(itemId, () => {
                this.refreshTable();
            });
        } else {
            this.dynviewEditModal.openModal(0, () => {
                this.refreshTable();
            });
        }
    }

    openColumnsDetailsModal(viewId?:number):void {
        if(!this.dynviewColumnModal) {
            this.dynviewColumnModal = this.ui(DynviewColumnModal) as DynviewColumnModal;
        }
        this.dynviewColumnModal.openModal(viewId, () => {
            this.refreshTable();
        });
    }

}