/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetGanttTaskStatus {
    CREATED = 9600,
    CLOSED = 9601,
    INACTIVE = 9602,
    ACTIVE = 9603,
}
