import { config } from "../../sources/config";
import { JetView } from "webix-jet";

import { IBaseView } from "webix-jet/dist/types/interfaces";
import Session from "../models/session";
import { WebixPortalSystemService } from "../../sources/openapi";
import { WebixAlertConfig } from "@xbs/webix-pro";
export default class LoginView extends JetView {

    idPrefix = "l";
    mode = "login";

    config():webix.ui.layoutConfig {
        return {
            view:"layout",
            id:`${this.idPrefix}LoginContainer`,
            rows:[
                {
                    view:"scrollview",
                    css:"login-container",
                    body:{
                        cols:[
                            {
                                view:"spacer",
                                gravity:0.5
                            },
                            {
                                view:"layout",
                                rows:[
                                    {
                                        view:"spacer",
                                        height:40
                                    },
                                    {
                                        view:"layout",
                                        height:500,
                                        rows:[
                                            this.getLoginFormLayout(),
                                            this.getForgotPasswordFormLayout(),
                                        ]
                                    },
                                    {
                                        view:"spacer",
                                        height:40
                                    },
                                    {
                                        template:"<img src='" + config.logoUrl + "'></img>",
                                        borderless:true,
                                        css:"login-form-container-logo slide-in-blurred-bottom",
                                        height:120
                                    },
                                    {
                                        view:"spacer",
                                        height:40
                                    }
                                ]
                            },
                            {
                                view:"spacer",
                                gravity:0.5
                            }
                        ]
                    }
                }
            ]
        }
    }

    init(view: IBaseView): void {
        // Check for session
        const isUserLogged = Session.status();
        isUserLogged
        .then((userLogged) => {
            if (userLogged) {
                window.location.replace("/#!/main/project-overview.projects");
            } 
        })
        .catch(err => {
            this.focusOnField("username");
            webix.UIManager.addHotKey("enter", () => {
                if(this.mode === "login") {
                    this.signIn();
                }
                if(this.mode === "forgot password") {
                    const forgotPasswordForm = this.getForm();
                    const email = forgotPasswordForm.getValues().email;
                    if(email) {
                        this.sendPasswordChangeLink(email);
                    }
                }
            });
        });
    }

    getLoginFormLayout(): webix.ui.formConfig {
        return {
            view:"layout",
            hidden:this.mode !== "login",
            id:`${this.idPrefix}LoginForm`,
            minWidth:360,
            height:0,
            paddingY:20,
            css:"login-form-container slide-in-blurred-top",
            rows:[
                {
                    template:`<h1>Login</h1>`,
                    borderless:true,
                    css:"login-form-container-h1 center",
                    height:60
                },
                {
                    view:"form",
                    borderless:true,
                    padding:40,
                    elements:[
                        this.getLabelInputTemplate("username", "Username", "far fa-user", "Type your username"),
                        this.getLabelInputTemplate("password", "Password", "far fa-lock", "Type your password", "password"),
                        {
                            view:"layout",
                            cols:[
                                {
                                    view:"button",
                                    template:"<a href='#'>Forgot your password?</a>",
                                    css:"login-form-forgot-password",
                                    borderless:true,
                                    padding:0,
                                    align:"right",
                                    click:() => {
                                        this.showView("forgot password");
                                    }
                                }
                            ]
                        },
                        {
                            view:"button",
                            css:"cog-button-big",
                            inputWidth:200,
                            inputHeight:0,
                            align:"center",
                            value:"Login",
                            click:() => {
                                this.signIn();
                            }
                        }
                    ],
                    rules: {
                        username: webix.rules.isNotEmpty,
                        password: webix.rules.isNotEmpty,
                    }
                }
            ]
        }
    }

    getForgotPasswordFormLayout(): webix.ui.formConfig {
        return {
            view:"layout",
            id:`${this.idPrefix}ForgotPasswordForm`,
            minWidth:360,
            hidden:this.mode !== "forgot password",
            height:0,
            paddingY:20,
            css:"login-form-container slide-in-blurred-bottom",
            rows:[
                {
                    template:`<h1>Forgot Password</h1>`,
                    borderless:true,
                    css:"login-form-container-h1 center",
                    height:60
                },
                {
                    view:"form",
                    id:`${this.idPrefix}ForgotPasswordFormInputs`,
                    borderless:true,
                    padding:40,
                    elements:[
                        this.getLabelInputTemplate("email", "E-Mail", "far fa-envelope", "Type your email"),
                        {
                            view:"button",
                            css:"cog-button-big",
                            inputWidth:200,
                            inputHeight:0,
                            align:"center",
                            value:"Reset",
                            click:() => {
                                const forgotPasswordForm = this.getForm();
                                const email = forgotPasswordForm.getValues().email;
                                if(email) {
                                    this.sendPasswordChangeLink(email);
                                }
                            }
                        }
                    ],
                    rules: {
                        email: webix.rules.isNotEmpty,
                    }
                }
            ]
        }
    }

    getLabelInputTemplate(name:string, label:string, icon:string, placeholder:string, type = ""):webix.ui.layoutConfig {
        return {
            view:"layout",
            height:100,
            rows:[
                {
                    view:"label",
                    css:"login-container-input-label",
                    label:label
                },
                {
                    view:"layout",
                    css:"login-container-input-box",
                    cols:[
                        {
                            template:`<i style='font-size:18px;' class="${icon}"></i>`,
                            borderless:true,
                            width:40
                        },
                        {
                            view:"text",
                            type:type,
                            placeholder:placeholder,
                            css:"login-container-input",
                            name:name
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:10
                }
            ]
        }
    }

    signIn(): void {
        const form = this.getForm();
        if (form.validate()) {
            const data = form.getValues();
            Session.login(data.username, data.password)
                .then((res: ICognovisServerResponse) => {
                    const resAsJson = res.json();
                    if (resAsJson.success) {
                        webix.storage.local.put("wjet_user", {
                            user_id: resAsJson.user_id,
                            token: resAsJson.token,
                            site_wide_admin: resAsJson.site_wide_admin,
                            name: resAsJson.name,
                        });
                        webix.storage.local.put(
                            "cognovis_bearer_token",
                            resAsJson.bearer_token
                        );
                        // Reset local translations
                        webix.storage.local.remove("cognovis_translations");
                        location.reload();
                    } else {
                        this.showErrorAlert("Can't authorize", "Username or password is wrong");
                    }
                })
                .catch((err) => {
                    this.showErrorAlert("Can't authorize", "Username or password is wrong");
                });
        } else {
            this.showErrorAlert("Can't authorize", "Username or password is wrong");
        }
    }

    sendPasswordChangeLink(email:string): void {
        WebixPortalSystemService.putForgotPasswordUrl({
            email:email
        })
        .then(() => {
            webix.alert({
                type:"info",
                title:"Email Sent",
                text:"Check your E-Mail and follow instructions",
                position:"center"
            } as unknown as WebixAlertConfig)
        })       
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    showView(newView:string) {
        this.mode = newView;
        const loginForm = webix.$$(`${this.idPrefix}LoginForm`) as webix.ui.form;
        const forgotPasswordForm = webix.$$(`${this.idPrefix}ForgotPasswordForm`) as webix.ui.form;
        switch(this.mode) {
            case "login":
                webix.html.removeCss(forgotPasswordForm.getNode(), "slide-in-blurred-bottom");
                webix.html.addCss(forgotPasswordForm.getNode(), "slide-out-top");
                loginForm.show();
                setTimeout(() => {
                    forgotPasswordForm.hide();
                    webix.html.removeCss(forgotPasswordForm.getNode(), "slide-out-top");
                    loginForm.show();
                    this.focusOnField("username");
                },400);
                break;
            case "forgot password":
                webix.html.removeCss(loginForm.getNode(), "slide-in-blurred-top");
                webix.html.addCss(loginForm.getNode(), "slide-out-top");
                forgotPasswordForm.show();
                setTimeout(() => {
                    loginForm.hide();
                    forgotPasswordForm.show();
                    webix.html.removeCss(loginForm.getNode(), "slide-out-top");
                    this.focusOnField("email");
                },400);
                break;
        }
    }

    showErrorAlert(title:string, error:string):void {
        webix.alert({
            type:"error",
            title:title,
            text:error,
            position:"center"
        } as unknown as  WebixAlertConfig)
        .then(() => {
            this.focusOnField("password");
        });
    }

    getForm():webix.ui.form {
        const rootView = this.getRoot() as webix.ui.view;
        const forms = rootView.queryView({ view: "form" }, "all");
        let formToReturn;
        forms.map(form => {
            if(form.isVisible()) {
                formToReturn = form;
            }
        });
        return formToReturn
    }

    focusOnField(field:string) {
        const form = this.getForm();
        if(form.elements[field]) {
            form.elements[field].focus();
        }
    }

}
