export class PriceHelper {

    static formatPrice(price:number, currency = "EUR"):string {
        if(currency === "Euro") {
            currency = "EUR";
        }
        if(price) {
            return price.toLocaleString("de-DE", {
                style: "currency",
                currency: currency
              });
        } else {
            const zero = 0;
            return zero.toLocaleString("de-DE", {
                style: "currency",
                currency: currency
              });
        }
    }

    static formatAssignmentRate(price:number, currency = "EUR"):string {
        if(currency === "Euro") {
            currency = "EUR";
        }
        return price.toLocaleString("de-DE", {
            style: "currency",
            currency: currency,
            maximumSignificantDigits: 3
          });
    }

    static sortDatatableByFormattedPrice(firstVal:{price:string}, secondVal:{price:string}):number {
        const firstValAsNumber = parseFloat(firstVal.price);
        const secondValAsNumber = parseFloat(secondVal.price);
        return firstValAsNumber > secondValAsNumber  ? 1 : (firstValAsNumber < secondValAsNumber  ? -1 : 0);
    }

    static sortDatatableByFormattedGrandTotal(firstVal:{grand_total:string}, secondVal:{grand_total:string}):number {
        const firstValAsNumber = parseFloat(firstVal.grand_total);
        const secondValAsNumber = parseFloat(secondVal.grand_total);
        return firstValAsNumber > secondValAsNumber  ? 1 : (firstValAsNumber < secondValAsNumber  ? -1 : 0);
    }

}