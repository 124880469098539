import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, IView, IViewColumn, IViewColumnBody} from "../../../sources/openapi";

export default class DynviewsSingleColumnEditModal extends CognovisBasicModal {

    colummId: number;
    viewId:number;
    idPrefix = "dynvscecm";
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle: string;
    afterSubmitAction: () => void;


    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 600, 500);  // adjust size if needed
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(viewId:number, column:IViewColumn, afterSubmitAction?: () => void): void {
        if(viewId) {
            this.viewId = viewId;
        }
        if(column) {
            this.modalTitle = column.column_name;
            this.colummId = column.column_id;
        } else {
            this.modalTitle = i18nHelper.getTranslation("Create new column");
        }
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTitle);
        this.cognovisPleaseWaitWindow.hide();
        const modal = (this.getRoot() as webix.ui.window);
        modal.show();
        if(column) {
            this.setFormValues();
        }
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
    }

    getContent(): webix.ui.layoutConfig {
        // Define your form's layout
        const layout = {
            view: "scrollview",
            body: {
                padding: 13,
                rows: [
                    {
                        view: "form",
                        id: `${this.idPrefix}DynviewSingleColumnEditViewForm`,
                        elements: [
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Column name"),
                                labelWidth: 160,
                                name: "column_name"
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Variable name"),
                                labelWidth: 160,
                                name: "variable_name"
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Visible for"),
                                labelWidth: 160,
                                name: "visible_for"
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Sort order"),
                                labelWidth: 160,
                                name: "sort_order"
                            },
                            {
                                view:"textarea",
                                label: i18nHelper.getTranslation("JSON configuration"),
                                labelWidth: 160,
                                name: "json_configuration"
                            }
                        ]
                    }
                ]
            }
        };
        return layout;
    }

    setFormValues():void {
        const form = webix.$$(`${this.idPrefix}DynviewSingleColumnEditViewForm`) as webix.ui.form;
        CognovisRestDynamicService.getViewColumns({ viewId: this.viewId})
            .then(viewColumns => {
                if(viewColumns.length > 0) {
                    const viewColumn = viewColumns.find(column => column.column_id === this.colummId);
                    if(viewColumn) {
                        Object.entries(viewColumn).forEach(([key, value]) => {
                            const element = form.queryView({name: key});
                            if (element) {
                                element.setValue(value);
                            }
                        });
                    }
                }
            });
    }


    getActionButtons(): webix.ui.layoutConfig {
        const buttons = {
            view: "layout",
            padding: 0,
            cols: [
                {
                    view: "button",
                    value: `${i18nHelper.getTranslation('Close')}`,
                    click: () => {
                        this.hide();
                    }
                },
                {
                    view: "button",
                    localId: `${this.idPrefix}SaveButton`,
                    value: `${i18nHelper.getTranslation('Save')}`,
                    click: () => {
                        this.submit();
                    }
                }
            ]
        };
        return buttons;
    }

    submit(): void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait") });
        const form = webix.$$(`${this.idPrefix}DynviewSingleColumnEditViewForm`) as webix.ui.form;
        const values = form.getValues();
        values["view_id"] = this.viewId;
        if(this.colummId && this.viewId) {
            console.log(values)
            CognovisRestDynamicService.putViewColumn({
                columnId: this.colummId,
                requestBody: values as IViewColumnBody
            })
                .then(result => {
                    this.afterSubmitAction();
                    this.cognovisPleaseWaitWindow.hide();
                    this.hide();
                });
        } else {
            CognovisRestDynamicService.postViewColumn({requestBody: values as IViewColumnBody})
                .then(result => {
                    this.afterSubmitAction();
                    this.cognovisPleaseWaitWindow.hide()
                    this.hide();
                });
        }
    }
}
