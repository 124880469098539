import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import CognovisBasicModal from "../cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../i18n-helper/i18n-helper";
import { IDynamicAttribute } from "../../../sources/openapi";
import { CognovisCategory } from "../cognovis-category/cognovis-category";
import { UserProfile } from "../cognovis-profile/profiles-types";
import { DatetimeHelper } from "../datetime-helpers/datetime-helpers";

export default class DynfieldEditionModal extends CognovisBasicModal {

    idPrefix = "dem";
    objectId:number;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    submitAction:(data:unknown, callback?:() => void) => void;
    afterSubmitAction:() => void;
    objectData:unknown;
    modalTittle = "";
    defaultModalTitle = i18nHelper.getTranslation("Edit");
    dynfields:IDynamicAttribute[];
    dynfieldsConfig:{objectType:string, pageUrl:string, checkForRequiredFields?:boolean};
    predefinedFields:webix.ui.baseviewConfig[];

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 0, 0);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    init():void {
        // Initialize
    }

    openModal(objectId:number, dynfields:IDynamicAttribute[], predefinedFields:webix.ui.baseviewConfig[], dynfieldsConfig:{objectType:string, pageUrl:string, checkForRequiredFields?:boolean}, objectData:unknown, modalTitle:string, submitAction?:(data:unknown) => void, afterSubmitAction?:() => void):void {
        this.objectId = objectId;
        this.dynfields = dynfields;
        this.dynfieldsConfig = dynfieldsConfig;
        this.objectData = objectData;
        this.modalTittle = modalTitle;
        this.predefinedFields = predefinedFields;
        if(submitAction) {
            this.submitAction = submitAction;
        }
        if(this.afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTittle);
        this.cognovisPleaseWaitWindow.hide();          
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        let height = ((this.dynfields.length + this.predefinedFields.length) * 85) + 40;
        if(height >= 770) {
            height = 770;
        }
        if(height <= 220) {
            height = 220;
        }
        const root = (this.getRoot() as webix.ui.window);
        root.config.height = height;
        root.adjust();
    }

    getContent():webix.ui.layoutConfig {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        width:720,
                        localId:`${this.idPrefix}LoggedHoursModal`,
                        rows:[
                            {
                                view:"form",
                                borderless:true,
                                id:`${this.idPrefix}DynfieldEditionModalForm`,
                                elements:this.buildFields(),
                                rules:this.getFormRules()
                            }
                        ]
                    }                   
                ]
            }
        };
        return layout
    }

    getFormRules():{[key:string]:() => boolean} {
        const rules = {};
        this.dynfields.map(dynfield => {
            let fieldIsRequired = false;
            dynfield.dynamic_subtypes.map(subtype => {
                if(subtype.required_p) {
                    fieldIsRequired = true;
                }
            });
            if(fieldIsRequired) {
                rules[dynfield.attribute.name] = webix.rules.isNotEmpty;
            }
        });
        return rules;
    }

    buildFields():webix.ui.baseviewConfig[] {
        const fieldsArr = [];
        this.dynfields = this.executeSubtypesFiltering(this.dynfields, "edit");
        this.dynfields.map(field => {
            //field.widget.name
            const newField = this.buildSpecificType(field);
            if(newField) {
                fieldsArr.push(newField);
            }
        });
        this.predefinedFields.map(predefinedField => {
            const newPredefinedField = this.getSingleFieldContainer(predefinedField);
            if(newPredefinedField && newPredefinedField["editable"]) {
                fieldsArr.push(newPredefinedField);
            }
        });
        // Now we still need to make checks against subtypes

        return fieldsArr
    }

    executeSubtypesFiltering(dynfields:IDynamicAttribute[], neededDisplayMode:string):IDynamicAttribute[] {
        // We need to loop thru all subtypes of all dynfields to decide wheter such type of object should have such field.
        let filteredDynfields:IDynamicAttribute[] = [];
        switch(this.dynfieldsConfig.objectType) {
            default:
                dynfields.map(dynfield => {
                    let addFlag = false;
                    dynfield.dynamic_subtypes.map(subtype => {
                        if(this.hasProperDisplayMode(neededDisplayMode,subtype.display_mode)) {
                            addFlag = true;
                        }
                    });
                    if(addFlag) {
                        filteredDynfields.push(dynfield);
                    }
                });
                break;
        }
        return filteredDynfields
    }

    roleGroupMap(key:number):UserProfile {
        const map = {
            22060:UserProfile.PROJECT_MANAGER,
            22050:UserProfile.FREELANCER,
            22040:UserProfile.EMPLOYEE,
            22030:UserProfile.CUSTOMER,
            22020:UserProfile.PO_ADMIN,
            22110:UserProfile.FREELANCER_MANAGER,
            22100:UserProfile.HR_MANAGER,
            22090:UserProfile.SALES,
            22080:UserProfile.ACCOUNTING,
            22070:UserProfile.SENIOR_MANAGER,
            29059:UserProfile.HELPDESK
        }
        return map[key];
    }

    hasProperDisplayMode(neededDisplayMode:string, subtypeDisplayMode:string):boolean {
        let final = false;
        switch(neededDisplayMode) {
            case "none":
                break;
            case "display":
                if(subtypeDisplayMode === "display" || subtypeDisplayMode === "edit") {
                    final = true;
                }
                break;
            case "edit":
                if(subtypeDisplayMode === "edit") {
                    final = true;
                }
                break;
            default:
                break;
        }
        return final
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        {
                            view:"button",
                            value:`${i18nHelper.getTranslation(`Save`)}`,
                            click:() => {
                                if(this.submitAction) {
                                    this.submitForm();
                                }
                            }
                        }
                    ] 
                };
        return buttons;
    }

    buildSpecificType(field:IDynamicAttribute):webix.ui.layoutConfig {
        // Figure out value
        let value:number | string = "";
        if(this.objectData[field.attribute.name]) {
            value = this.objectData[field.attribute.name];
        }        
        const component = this.getWebixComponent(field, value as string);
        if(component) {
            const container = this.getSingleFieldContainer(component);
            return container
        }
    }
    
    getSingleFieldContainer(field:webix.ui.layoutConfig):webix.ui.layoutConfig {
        return {
            view:"layout",
            rows:[
                {
                    view:"spacer",
                    height:4
                },
                field,
                {
                    view:"spacer",
                    height:4
                }
            ]
        }
    }

    getWebixComponent(field:IDynamicAttribute, value:string):webix.ui.baseviewConfig {
        let webixComponent = {
            view:field.widget_type,
            name:field.attribute.name,
            label:i18nHelper.getTranslationWithKey(field.display_name, field.i18n_key),
            labelWidth:270,
            value:value
        };
        if(field.widget_type === "datepicker") {
            webixComponent["format"] = "%d.%m.%Y";
        }
        if(field.widget_type === "datetimepicker") {
            webixComponent["view"] = "datepicker";
            webixComponent["format"] = "%d.%m.%Y %H:%i";
            webixComponent["timepicker"] = true;
            webixComponent["value"] = DatetimeHelper.removeTimezone(value);
            const suggestBox = {
                type: "calendar",
                body: {
                    timepicker: true,
                    calendarTime: "%H:%i",
                    on: {
                        onBeforeDateSelect:function() {
                            this.detachEvent("onAfterDateSelect");
                            const popup = this.getParentView();
                            const datepicker = webix.$$(popup.config.master) as webix.ui.datepicker;
                            this.attachEvent("onAfterDateSelect", function(date) {
                                datepicker.setValue(date)
                            });
                        }
                    },
                    icons: [
                        {
                            template: function(){
                                return "<span class='webix_cal_icon_clear webix_cal_icon'>"
                                    +i18nHelper.getTranslation("Clear")
                                    +"</span>";
                            },
                            on_click:{
                                "webix_cal_icon_clear": function(){
                                    const popup = this.getParentView();
                                    const datepicker = webix.$$(popup.config.master) as webix.ui.datepicker;
                                    datepicker.setValue("");
                                }
                            }
                        },
                        {
                            template: function(){
                                return "<span class='webix_cal_icon_done webix_cal_icon'>"
                                    +i18nHelper.getTranslation("Done")
                                    +"</span>";
                            },
                            on_click:{
                                "webix_cal_icon_done": function(){
                                    this.hide();
                                }
                            }
                        }
                    ]
                }
            };
            webixComponent["suggest"] = suggestBox;
        }
        if(field.widget_type === "richtext") {
            webixComponent["height"] = 200;
        }
        if(field.widget_data && field.widget_data.length > 0) {
            webixComponent["options"] = field.widget_data;
        }
        if(field.category_type) {
            const suggestBox = {
                body: {
                    url: () => CognovisCategory.getCategory(field.category_type),
                }
            };
            webixComponent["suggest"] = suggestBox;
        }
        if(field.attribute_type === "im_skills") {
            webixComponent["view"] = "multiselect";
        }

        return webixComponent
    }

    closeModal():void {
        this.hide();
    }

    validateIfNeeded(form:webix.ui.form):boolean {
        if(!this.dynfieldsConfig.checkForRequiredFields) {
            return true;
        } else {
            return form.validate();
        }
    }

    excludeNotUpdatedValues(values:{id:string, value:string}[]):{id:string, value:string}[] {
        const onlyModifiedValues = [];
        values.map(value => {
            let changedFromNotEmptyToEmpty = false;
            if( 
                (this.objectData[value.id] !== "" || this.objectData[value.id] !== null) 
                &&
                (value.value === "" || value.value === null)
            ) {
                changedFromNotEmptyToEmpty = true;
            }
            if((value.value !== this.objectData[value.id])) {
                onlyModifiedValues.push(value);
            }
        });
        return onlyModifiedValues
    }
    
    getFieldType(fieldName:string):string {
        const form = webix.$$(`${this.idPrefix}DynfieldEditionModalForm`) as webix.ui.form;
        const field = form.queryView({name:fieldName});
        if(field) {
            return field.config.view;
        } else {
            return ""
        }
    }

    submitForm():void {
        const form = webix.$$(`${this.idPrefix}DynfieldEditionModalForm`) as webix.ui.form;
        if(this.validateIfNeeded(form)) {
            this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")})
            const values = form.getValues();
            const parsedValuesArr = [];
            Object.keys(values).forEach(key => {
                const fieldType = this.getFieldType(key);
                if(values[key] === null) {
                    values[key] = "";
                }
                if(fieldType === "datepicker" && values[key] !== "") {
                    values[key] = DatetimeHelper.formatDateFromDatepicker(values[key]);
                }
                parsedValuesArr.push({
                    id:key,
                    value:values[key]
                });
            });
            // Exclude not modified values
            const onlyModifiedValues = this.excludeNotUpdatedValues(parsedValuesArr);
            this.submitAction(onlyModifiedValues, () => {
                this.cognovisPleaseWaitWindow.hide();
                this.closeModal();
                if(this.afterSubmitAction) {
                    this.afterSubmitAction();
                }
            });
        }
    }

}