import * as usermanager from "@xbs/usermanager";

export default class UmCustomUserDetails extends usermanager.views["users/editor"] {

    config() {
        const ui = super.config();
        ui.rows[0].cols[1].value = "users.roles";
        console.log(ui.rows[0].cols[1]);
        if (ui.rows[0].cols[1].options[3]) {
            ui.rows[0].cols[1].options[0].hidden = true;
            ui.rows[0].cols[1].options[3].hidden = true;
        }
        return ui
    }

}