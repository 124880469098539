import { IntranetProjectType } from "../../../sources/openapi";
import DynfieldBasicForm from "../../../sources/modules/cog-dynfields/dynfield-basic-form";



export default class DynNewOffer extends DynfieldBasicForm {

    dynfieldsConfig:IDynfieldSetup;
    customEvents:{[key: string]: string | number | Function}[] = [
        {
            field:"project_type_id",
            type:"onChange",
            action:(newSubtype:IntranetProjectType) => {
                if(this.dynfieldsConfig.pages[0]) {
                    this.dynfieldsConfig.pages[0].objectSubtypeId = newSubtype;
                    this.rebuildForms(this.dynfieldsConfig);
                }
            }
        }
    ];
    customPlusSignActions:{[key: string]: string | number | Function}[] = [
        {
            field:"company_id",
            action:() => {
                
            }
        }
    ];

}