/* eslint-disable security/detect-object-injection */
import { UserStateManager } from "../../../sources/services/user-state-manager";
import { ITranslation } from "../../../sources/openapi";
import CognovisProfile from "../cognovis-profile/cognovis-profile";
import { UserProfile } from "../cognovis-profile/profiles-types";

export class i18nHelper {
    static getTranslation(translationKey:string):string {
        const storedTranslations = webix.storage.local.get("cognovis_translations");
        const translatorMode = webix.storage.session.get("cognovis_translator_mode");
        if(storedTranslations) {
            const message = translationKey;
            if(translationKey === "_empty_") {
                return "";
            }
            let translatedMessage = storedTranslations[translationKey];
            const allTranslations:ITranslation[] = webix.storage.local.get("cognovis_all_translations");
            const enUSTransltion = allTranslations.find(trans => trans.message_key === translationKey && trans.locale === "en_US");
            if ((UserStateManager.fakingMode || CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN)) && translatorMode) {
                // If we have correct translation, just use it and mark it as green
                let circleColor = "green";
                // If we do not have translation in admin language, we show en_US and mark it as yellow
                if(!translatedMessage) {
                    // Check if translation in en_US exists
                    if(enUSTransltion) {
                        circleColor = "orange";
                        translatedMessage = enUSTransltion.message;
                    } else {
                        circleColor = "red";
                        translatedMessage = `_${translationKey}`;
                    }
                }
                translatedMessage = `_${translationKey}`;
                return `<span class='translation-circle ${circleColor}' cursor-pointer" onclick='webix.openI18nTranslationModal.openModal("${translationKey}", "${circleColor}")'>●</span> ${translatedMessage}`;
                //return `<div style='display:inline;'><div class='translation-circle ${circleColor}'  onclick='webix.openI18nTranslationModal.openModal("${translationKey}", "${circleColor}")'></div><div style='float:left'>${translatedMessage}</div><div class='clear'></div></div>`  
            } else {
                // Proper translation as default
                if(translatedMessage) {
                    return translatedMessage
                } else {
                    // Try to show en_US and if not display key
                    if(enUSTransltion) {
                        return enUSTransltion.message
                    } else {
                        return message;
                    }
                }
            }    
        } else {
            return translationKey
        }
    }

    static getTranslationWithKey(translation:string, translationKey:string):string {
        const storedTranslations = webix.storage.local.get("cognovis_translations");
        const translatorMode = webix.storage.session.get("cognovis_translator_mode");
        const message = translationKey;
        if(translationKey === "_empty_") {
            return "";
        }
        let translatedMessage = storedTranslations[translationKey];
        const allTranslations:ITranslation[] = webix.storage.local.get("cognovis_all_translations");
        const enUSTransltion = allTranslations.find(trans => trans.message_key === translationKey && trans.locale === "en_US");
        if ((UserStateManager.fakingMode || CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN)) && translatorMode) {
            // If we have correct translation, just use it and mark it as green
            let circleColor = "green";
            // If we do not have translation in admin language, we show en_US and mark it as yellow
            if(!translatedMessage) {
                // Check if translation in en_US exists
                if(enUSTransltion) {
                    circleColor = "orange";
                    translatedMessage = enUSTransltion.message;
                } else {
                    circleColor = "red";
                    translatedMessage = `_${translationKey}`;
                }
            }
            translatedMessage = `_${translationKey}`;
            return `<span class='translation-circle ${circleColor}' cursor-pointer" onclick='webix.openI18nTranslationModal.openModal("${translationKey}", "${circleColor}",${true})'>●</span> ${translatedMessage}`;
            //return `<div style='display:inline;'><div class='translation-circle ${circleColor}'  onclick='webix.openI18nTranslationModal.openModal("${translationKey}", "${circleColor}",)'></div><div style='float:left'>${translatedMessage}</div><div class='clear'></div></div>`  
        } else {
            return translation
        }       
    }
}