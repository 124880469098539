import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { singleton } from "tsyringe";
import { CognovisRestCompanyService, ICompany, IntranetCompanyStatus, IntranetCompanyType } from "../../../sources/openapi";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";

@singleton()
export class CompaniesTableBuilder  {

    idPrefix = "co";

    buildCompaniesTable(tableId:string, visibleColumns:string[], companiesStatusesIds:IntranetCompanyStatus[], companiesTypesIds:IntranetCompanyType[]):webix.ui.datatableConfig {
        const table = {
            view: "datatable",
            css:"cog-clickable-table",
            id:`${this.idPrefix}CompaniesDbTable`,
            localId:`${this.idPrefix}CompaniesDbTable`,
            entityColumn:"company_id",
            hover:"cog-datatable-hover",
            tooltip:true,
            columns:this.getCompaniesTableColumns(visibleColumns),
            ready(){
                (webix.ui({
                    view:"contextmenu", 
                    localId:`${this.idPrefix}CompaniesTableContextMenu`,
                    data: 
                    [
                        i18nHelper.getTranslation("Open_in_new_tab")
                    ],
                    on: {
                        onMenuItemClick:function() {
                            const mainContext = this.getContext();
                            const company = mainContext.obj.getItem(mainContext.id.row);
                            const url = `#!/company?company_id=${company.company.id}`;
                            window.open(url, '_blank').focus();
                        }
                    }
                }) as webix.ui.contextmenu).attachTo(this);
            },
            url:() => this.getData(companiesStatusesIds, companiesTypesIds),
            on: {
                onItemClick:(row) => {
                    const table = webix.$$(`${this.idPrefix}CompaniesDbTable`) as webix.ui.datatable;
                    const item = table.getItem(row);
                    CognovisNavigator.navigateTo(`/main/companies-overview.company-details?company_id=${item.company.id}`);
                }
            }
        }
        return table;
    }

    getData(companiesStatusesIds:IntranetCompanyStatus[], companiesTypesIds:IntranetCompanyType[]):Promise<ICompany[]> {
        const table = webix.$$(`${this.idPrefix}CompaniesDbTable`) as webix.ui.datatable;
        table.showOverlay(i18nHelper.getTranslation("Please_wait"));
        return CognovisRestCompanyService.getCompanies({
            companyTypeIds:companiesTypesIds,
            companyStatusIds:companiesStatusesIds
        })
        .then(companies => {
            companies.map((company:ICompany & {formatted_last_contact:string}) => {
                if(company["last_contact"]) {
                    const lastContactObj = new Date(company["last_contact"])
                    const lastContactAsString = `${lastContactObj.getFullYear()}-${(lastContactObj.getMonth()+1)}-${lastContactObj.getDate()}`;
                    company.formatted_last_contact = lastContactAsString;
                } else {
                    company.formatted_last_contact = "";
                }
            });
            table.hideOverlay();
            return companies
        });
    }

    setData(companies:ICompany[]):void {
        const table = webix.$$(`${this.idPrefix}CompaniesDbTable`) as webix.ui.datatable;
        table.define("data", companies);
        table.hideOverlay();
        table.refresh();
    }

    reloadTable(companiesStatusesIds:IntranetCompanyStatus[], companiesTypesIds:IntranetCompanyType[]):void {
        const table = webix.$$(`${this.idPrefix}CompaniesDbTable`) as webix.ui.datatable;
        table.showOverlay(i18nHelper.getTranslation("Please_wait"));
        table.clearAll();
        this.getData(companiesStatusesIds, companiesTypesIds)
        .then(companies => {
            this.setData(companies);
        });
        table.$scope.setParam("tab", table.$scope.getCompanyTypeName(companiesTypesIds[0]), true);
    }

    getCompaniesTableColumns(visibleColumns:string[], filtersOff = false):webix.ui.layoutConfig[] {
        const columns = [
            {
                id:"company_id",
                map:"#company.id#",
                hidden:true
            },
            {
                id:"company_name",
                header: [i18nHelper.getTranslation("Company_name"), { content:"textFilter"}], 
                fillspace: true,
                map:"#company.name#",
                sort:"text",
            },
            {
                id:"primary_contact_name",
                fillspace:true,
                width:400,
                header: [i18nHelper.getTranslation("Primary_contact"), { content:"textFilter"}], 
                sort:"text",
                template:(obj) => {
                    if(obj.primary_contact && obj.primary_contact?.name) {
                        return obj.primary_contact?.name
                    } else {
                        return ""
                    }
                }
            },
            {
                id:"company_status",
                width:160,
                header: [i18nHelper.getTranslation("Status"), { content:"multiComboFilter"}], 
                map:"#company_status.name#",
                sort:"text",
            },
            {
                id:"company_type",
                width:160,
                header: [i18nHelper.getTranslation("Type"), { content:"multiComboFilter"}], 
                map:"#company_type.name#",
                sort:"text",
            },
            {
                id:"last_contact",
                header: [{text:i18nHelper.getTranslation("last_contact")}, {content:"dateFilter"}],
                width:160,
                map:"(date)#formatted_last_contact#",
                sort:"date",
                template:function(obj) {
                    if(obj.formatted_last_contact) {
                        const myFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
                        const formattedDate = myFormat(new Date(obj.formatted_last_contact));
                        return formattedDate
                    } else {
                        return ""
                    }
                }
            }
        ];
        return columns.filter(column => visibleColumns.indexOf(column.id) > -1)
    }

}