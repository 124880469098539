import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, CognovisRestService, IDynfieldAttrBody} from "../../../sources/openapi";

export default class DynfieldsEditModal extends CognovisBasicModal {

    dynfieldId:number;
    idPrefix = "dynfattem";
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle: string;
    afterSubmitAction: () => void;


    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 900,720);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(dynfieldId:number, afterSubmitAction?: () => void): void {
        this.modalTitle = i18nHelper.getTranslation("Create new dynfield attribute");
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTitle);
        this.cognovisPleaseWaitWindow.hide();
        const modal = (this.getRoot() as webix.ui.window);
        modal.show();
        if(dynfieldId) {
            this.dynfieldId = dynfieldId;
            this.setFormValues();
        }
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
    }


getContent(): webix.ui.layoutConfig {
        // Define your form's layout
    const random =  Math.floor(Math.random()*1000 + 1);
        const layout = {
            view: "scrollview",
            body: {
                padding: 13,
                rows: [
                    {
                        view: "form",
                        id: `${this.idPrefix}WidgetEditViewForm`,
                        elements: [
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Attribute name"),
                                labelWidth: 160,
                                name: "dynfield_name",
                                value:`testmichal${random}`
                            },
                            {
                                view: "combo",
                                label: i18nHelper.getTranslation("Object type"),
                                labelWidth: 160,
                                name: "object_type",
                                value:"im_project",
                                suggest: {
                                    body: {
                                        url: () => {
                                            return CognovisRestService.getValidObjectTypes()
                                                .then(types => {
                                                    return types.map(type => ({id:type.object_type, value:type.object_type}))
                                                });
                                        }
                                    }
                                }
                            },
                            {
                                view: "text",
                                label: i18nHelper.getTranslation("Table name"),
                                labelWidth: 160,
                                name: "table_name",
                                value:"im_projects"
                            },
                            {
                                view:"text",
                                label: i18nHelper.getTranslation("Pretty name"),
                                labelWidth: 160,
                                name: "pretty_name",
                                value:`testmichal${random}`

                            },
                            {
                                view: "checkbox",
                                label: i18nHelper.getTranslation("Required"),
                                labelWidth: 160,
                                name: "required",
                                checkValue:"t",
                                uncheckValue:"f"
                            },
                            {
                                view: "checkbox",
                                label: i18nHelper.getTranslation("Also hard coded"),
                                labelWidth: 160,
                                name: "also_hard_coded",
                                checkValue:"t",
                                uncheckValue:"f"
                            },
                            {
                                view: "combo",
                                label: i18nHelper.getTranslation("Widget"),
                                labelWidth: 160,
                                name: "widget_name",
                                value:"textbox_large",
                                suggest: {
                                    body: {
                                        url: () => {
                                            return CognovisRestDynamicService.getDynfieldWidgets({})
                                                .then(widgets => {
                                                    return widgets.map(widget => ({id:widget.widget_name, value:widget.widget_name}))
                                                });
                                        }
                                    }
                                }
                            },
                            {
                                view:"text",
                                label: i18nHelper.getTranslation("Position x"),
                                labelWidth: 160,
                                name: "pos_x",
                                value:10
                            },
                            {
                                view:"text",
                                label: i18nHelper.getTranslation("Position y"),
                                labelWidth: 160,
                                name: "pos_y",
                                value:11
                            },
                            {
                                view:"text",
                                label: i18nHelper.getTranslation("Help text"),
                                labelWidth: 160,
                                name: "help_text",
                                value:"help text"
                            },
                            {
                                view:"text",
                                label: i18nHelper.getTranslation("Help URL"),
                                labelWidth: 160,
                                name: "help_url",
                                value:"help url"
                            },
                            {
                                view:"text",
                                label: i18nHelper.getTranslation("Default value"),
                                labelWidth: 160,
                                name: "default_value",
                                value:'default value'
                            },
                            {
                                view:"text",
                                label: i18nHelper.getTranslation("Section heading"),
                                labelWidth: 160,
                                name: "section_heading",
                                value:'section heading'
                            },
                        ]
                    }
                ]
            }
        };
        return layout;
    }


    setFormValues():void {
        const form = webix.$$(`${this.idPrefix}WidgetEditViewForm`) as webix.ui.form;
        CognovisRestDynamicService.getDynfieldAttributes({attributeId:this.dynfieldId})
            .then(widgets => {
                if(widgets[0]) {
                    const widget = widgets[0];
                    widget["also_hard_coded"] = (widget.also_hard_coded_p) ? "t" : "f";
                    widget["required"] = (widget.required_p) ? "t" : "f";
                    this.setTitle(widget.widget_name);
                    Object.entries(widget).forEach(([key, value]) => {
                        const element = form.queryView({name:key});
                        if(element) {
                            element.setValue(value);
                        }
                    });
                }
            });
    }

    getActionButtons(): webix.ui.layoutConfig {
        return {
            view: "layout",
            padding: 0,
            cols: [
                {
                    view: "button",
                    value: `${i18nHelper.getTranslation('Close')}`,
                    click: () => {
                        this.hide();
                    }
                },
                {
                    view: "button",
                    localId: `${this.idPrefix}SaveButton`,
                    value: `${i18nHelper.getTranslation('Save')}`,
                    click: () => {
                        this.submit();
                    }
                }
            ]
        };
    }

    submit(): void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait") });
        const form = webix.$$(`${this.idPrefix}WidgetEditViewForm`) as webix.ui.form;
        const values = form.getValues();
        values.attribute_name = values.dynfield_name;
        if(this.dynfieldId) {
            CognovisRestDynamicService.putDynfieldAttribute({
                attributeId: this.dynfieldId,
                requestBody: values as IDynfieldAttrBody
            })
                .then(result => {
                    this.afterSubmitAction();
                    this.cognovisPleaseWaitWindow.hide();
                    this.hide();
                });
        } else {
            CognovisRestDynamicService.postDynfieldAttribute({requestBody:values as IDynfieldAttrBody})
                .then(result => {
                    this.afterSubmitAction();
                    this.cognovisPleaseWaitWindow.hide()
                    this.hide();
                });
        }
    }
}

