import { InvoiceItems } from './line-items'
import { ApiError, CognovisRestCompanyService, CognovisRestInvoiceService, CognovisRestService, IInvoice, IMenuItem, IntranetCompanyStatus, IntranetCompanyType, IntranetCostStatus, IntranetCostType, IPrivilege } from '../../../sources/openapi';
import { CognovisCategory } from '../../../sources/modules/cognovis-category/cognovis-category';
import { InformationBox } from '../../../sources/modules/information-box/information-box';
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { container } from 'tsyringe';
import { config } from '../../../sources/config';
import { i18nHelper } from '../../../sources/modules/i18n-helper/i18n-helper';
import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import { CognovisNavigator } from '../../../sources/modules/cognovis-navigator/cognovis-navigator';
import { DatetimeHelper } from '../../../sources/modules/datetime-helpers/datetime-helpers';
import ProjectDetails from 'views/project-details';
import SendMailModal from 'views/modals/send-mail-modal';
import MessagesPortlet from "views/portlets/messages-portlet";
import ObjectNotesPortlet from "views/portlets/object-notes-portlet";
import AddLogggedHoursToQuoteModal from 'views/modals/add-logged-hours-to-quote';
import LinkedObjectsModal from 'views/modals/linked-objects-modal';
import CreateMultipleInvoiceModal from './modals/create-multiple-invoives-modal';

export default class InvoiceDetails extends ProjectDetails {

    invoiceId: number;
    isInvoiceEditable = false;
    isInvoiceDeletable = false;
    isInvoiceCancellable = false;
    currencySymbol: string;
    invoiceData:IInvoice;
    basicDataBox:InformationBox;
    recipientInfoBox:InformationBox;
    webixHelpers: WebixHelpers;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    sendInvoiceByEmailModal:SendMailModal;
    lineItemsView:InvoiceItems;
    menuItems:IMenuItem;
    addLoggedHoursModal:AddLogggedHoursToQuoteModal;
    createMultipleInvoicesModal:CreateMultipleInvoiceModal;

    init():void {
        this.setupButtons(this.invoiceId);
    }

    config():Promise<webix.ui.scrollviewConfig> {
        this.webixHelpers = container.resolve(WebixHelpers);
        this.invoiceId = this.getParam("invoice_id", false);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        const invoiceMessagesPortlet = new MessagesPortlet(this.app, {contextId:this.invoiceId}, null, i18nHelper.getTranslation("Invoice_messages"));
        const invoiceNotesPortlet = new ObjectNotesPortlet(this.app, this.invoiceId, null, i18nHelper.getTranslation("Invoice_notes"));

        return this.getInvoiceData(this.invoiceId)
        .then(invoice => {
            this.invoiceData = invoice;
            this.currencySymbol = invoice.currency_symbol;
        })
        .then(() => {
            return this.getInvoicePermissions()
            .then(permissions => {
                this.isInvoiceEditable = permissions.write;
                this.isInvoiceDeletable = permissions.admin;
                this.isInvoiceCancellable = permissions.admin && ( this.invoiceData.cost_type.id == IntranetCostType.CUSTOMER_INVOICE || this.invoiceData.cost_type.id == IntranetCostType.PROVIDER_BILL);
                this.lineItemsView = new InvoiceItems(this.app, this.invoiceData, this.isInvoiceEditable);
                const invoice = this.invoiceData
                const layout = {
                    view: "scrollview",
                    scroll: "y",
                    css: "parent-container invoice-datail-container",
                    body: {
                        view: "layout",
                        type: "clean",
                        css: "cog-content",
                        padding:13,
                        responsive: true,
                        rows: [
                            {
                                view: "layout", 
                                type: "clean", 
                                css: "cog-box-content invoice-details-buttons",
                                paddingX:0,
                                fillspace:true,
                                cols: [
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Go back"),
                                        type: "icon",
                                        icon: "mdi mdi-arrow-left",
                                        width: 40,
                                        css:"cog-icon",
                                        id: "invoice_go_to_project",
                                        on: {
                                            onItemClick: () => {
                                                const projectId = this.invoiceData.project.id;
                                                CognovisNavigator.back(`/main/financial-overview.financial-overview?project_id=${projectId}`);
                                            }
                                        }
                                    },
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Open linked financial document"),
                                        type: "icon",
                                        css:"cog-icon",
                                        icon: "far fa-link",
                                        width: 50,
                                        hidden:!this.invoiceData.linked_invoice,
                                        id: "go_to_linked_invoice",
                                        on: {
                                            onItemClick: () => {
                                                LinkedObjectsModal.show(this.invoiceData.invoice.id, 200, 70);
/*                                                CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${this.invoiceData.linked_invoice.id}&project_id=${this.invoiceData.project.id}`);
                                                setTimeout(() => {
                                                    window.location.reload();
                                                },100);
*/                                                
                                            }
                                        }
                                    },
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Download PDF"),
                                        type: "icon",
                                        css:"cog-icon",
                                        icon: "fa fa-download blue-icon",
                                        width: 50,
                                        id: "invoice_download_pdf",
                                        on: {
                                            onItemClick: () => {
                                                const bearerToken = webix.storage.local.get("cognovis_bearer_token");
                                                window.location.href = `${config.restUrl}/invoice_document?invoice_id=${this.invoiceId}&preview_p=false&type=pdf&api_key=${bearerToken}`;
                                            }
                                        }
                                    },
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Preview PDF"),
                                        type: "icon",
                                        css:"cog-icon",
                                        icon: "fa fa-eye blue-icon",
                                        width: 50,
                                        id: "invoice_preview_pdf",
                                        on: {
                                            onItemClick: () => {
                                                const bearerToken_1 = webix.storage.local.get("cognovis_bearer_token");
                                                window.location.href = `${config.restUrl}/invoice_document?invoice_id=${this.invoiceId}&preview_p=true&type=pdf&api_key=${bearerToken_1}`;
                                            }
                                        }
                                    },
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Preview LibreOffice"),
                                        type: "icon",
                                        css:"cog-icon",
                                        icon: "fa fa-file-word blue-icon",
                                        width: 50,
                                        id: "invoice_preview_word",
                                        on: {
                                            onItemClick: () => {
                                                const bearerToken_2 = webix.storage.local.get("cognovis_bearer_token");
                                                window.location.href = `${config.restUrl}/invoice_document?invoice_id=${this.invoiceId}&preview_p=true&type=fodt&api_key=${bearerToken_2}`;
                                            }
                                        }
                                    },
                                    // KOL-3049-adding-logged-hours-to-invoice button, should be uncommented later
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Import uninvoiced hours"),
                                        type: "icon",
                                        icon: "fas fa-chess-clock-flip",
                                        width: 50,
                                        hidden:(invoice.cost_type.id !== IntranetCostType.CUSTOMER_INVOICE),
                                        css:"cog-icon",
                                        id: "import_uninvoiced_hours",
                                        on: {
                                            onItemClick: () => {
                                                this.addLoggedHoursModal = this.ui(AddLogggedHoursToQuoteModal) as AddLogggedHoursToQuoteModal;
                                                this.addLoggedHoursModal.openModal(this.invoiceData, () => {
                                                    this.getInvoiceData(invoice.invoice.id)
                                                    .then(() => {
                                                        this.lineItemsView.updateTotalAmountsWithLineItems();
                                                        this.refresh();
                                                    });
                                                });
                                            }
                                        }
                                    },                        
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Import billable tasks"),
                                        type: "icon",
                                        icon: "far fa-clock",
                                        width: 50,
                                        //hidden:!((invoice.cost_status.id === IntranetCostStatus.CREATED) && (invoice.cost_type.id === IntranetCostType.QUOTE)),
                                        css:"cog-icon",
                                        id: "import_billable_tasks",
                                        on: {
                                            onItemClick: () => {
                                                CognovisRestInvoiceService.putImportTimesheetTasks({
                                                    invoiceId:invoice.invoice.id,
                                                    requestBody:{}
                                                })
                                                .then(() => {
                                                    this.getInvoiceData(invoice.invoice.id)
                                                    .then(() => {
                                                        this.lineItemsView.updateTotalAmountsWithLineItems();
                                                        this.recipientInfoBox.editionModal.hideModal();
                                                        this.refresh();
                                                    });
                                                })
                                                .catch(err => {
                                                    webix.alert({
                                                        title: err.message,
                                                        text: err.body.message,
                                                        type: "alert-error",
                                                        width: 500,
                                                        css:"cog-remove-tasks-modal"
                                                    });
                                                });
                                            }
                                        }
                                    },
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Create invoice"),
                                        type: "icon",
                                        icon: "far fa-file-invoice",
                                        width: 50,
                                        hidden:((invoice.cost_type.id !== IntranetCostType.QUOTE) || invoice.linked_invoice),
                                        css:"cog-icon",
                                        id: "create_invoice",
                                        on: {
                                            onItemClick: () => {
                                                this.createInvoice(invoice,IntranetCostType.CUSTOMER_INVOICE);
                                            }
                                        }
                                    },
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Create multiple invoices"),
                                        type: "icon",
                                        icon: "fa-regular fa-money-bills",
                                        width: 50,
                                        hidden:((invoice.cost_type.id !== IntranetCostType.QUOTE)),
                                        css:"cog-icon",
                                        id: "create_mulitple_invoices",
                                        on: {
                                            onItemClick: () => {
                                                this.createMultipleInvoices();
                                            }
                                        }
                                    },
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Create bill"),
                                        type: "icon",
                                        icon: "far fa-money-bill",
                                        width: 50,
                                        hidden:((invoice.cost_type.id !== IntranetCostType.PURCHASE_ORDER) || invoice.linked_invoice),
                                        css:"cog-icon",
                                        id: "create_bill",
                                        on: {
                                            onItemClick: () => {
                                                this.createInvoice(invoice,IntranetCostType.PROVIDER_BILL);
                                            }
                                        }
                                    },
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("Add discount or surcharge"),
                                        type: "icon",
                                        css:"cog-icon",
                                        icon: "fa fa-euro-sign blue-icon",
                                        width: 50,
                                        id: "invoice_add_discount",
                                        on: {
                                            onItemClick: () => {
                                                this.lineItemsView.openDiscountsModal(this.invoiceData);
                                            }
                                        }
                                    },
                                    {
                                        view: "button",
                                        tooltip:i18nHelper.getTranslation("View in old interface"),
                                        type: "icon",
                                        css:"cog-icon",
                                        icon: "fa fa-receipt blue-icon",
                                        width: 50,
                                        id: "invoice_old_link",
                                        hidden:!this.isInvoiceEditable,
                                        on: {
                                            onItemClick: () => {
                                                window.location.href = `${config.serverUrl}/intranet-invoices/view?invoice_id=${this.invoiceId}`;
                                            }
                                        }
                                    },
                                    { 
                                        view: "button", 
                                        tooltip:i18nHelper.getTranslation("Cancel financial document"),
                                        type: "icon", 
                                        css:"cog-icon",
                                        icon: "fa fa-ban", 
                                        id: "invoice_cancel_invoice", 
                                        hidden:!this.isInvoiceCancellable,
                                        width:50,
                                        on: {
                                            onItemClick: () => {
                                                const invoiceName = this.invoiceData.invoice.name;
                                                const confirmWarning = `${i18nHelper.getTranslation(`You_want_to_cancel_invoice`)}: ${invoiceName}<br/>`;
                                                webix.confirm({
                                                    title: i18nHelper.getTranslation(`any_are_you_sure_message`),
                                                    type: "confirm-warning",
                                                    text: confirmWarning,
                                                    width: 480
                                                })
                                                .then(()=> {
                                                    CognovisRestInvoiceService.postInvoiceCancellation({
                                                        invoiceId: this.invoiceId
                                                    })
                                                    .then(cancelInvoice => {
                                                        CognovisNavigator.goToObjectDetails(cancelInvoice.invoice.id, 'im_invoice');
                                                    })
                                                    .catch(err => {
                                                        webix.alert({
                                                            title: err.message,
                                                            text: err.body.message,
                                                            type: "alert-error",
                                                            width: 500,
                                                            css:"cog-remove-tasks-modal"
                                                        });
                                                    });
                                                });
                                            }
                                        }
                                    },
                                    { 
                                        view: "button", 
                                        tooltip:i18nHelper.getTranslation("Delete financial document"),
                                        type: "icon", 
                                        css:"cog-icon",
                                        icon: "fa fa-trash-alt", 
                                        id: "invoice_delete_invoice", 
                                        hidden:!this.isInvoiceDeletable,
                                        width:50,
                                        on: {
                                            onItemClick: () => {
                                                const invoiceName = this.invoiceData.invoice.name;
                                                const confirmWarning = `${i18nHelper.getTranslation(`You_want_to_remove`)}: ${invoiceName}<br/>`;
                                                webix.confirm({
                                                    title: i18nHelper.getTranslation(`any_are_you_sure_message`),
                                                    type: "confirm-warning",
                                                    text: confirmWarning,
                                                    width: 480
                                                })
                                                .then(()=> {
                                                    CognovisRestInvoiceService.deleteInvoice({
                                                        invoices:[this.invoiceId]
                                                    })
                                                    .then(() => {
                                                        const projectId = this.invoiceData.project.id;
                                                        CognovisNavigator.navigateTo(`/main/financial-overview.financial-overview?project_id=${projectId}`);
                                                    })
                                                    .catch(err => {
                                                        webix.alert({
                                                            title: err.message,
                                                            text: err.body.message,
                                                            type: "alert-error",
                                                            width: 500,
                                                            css:"cog-remove-tasks-modal"
                                                        });
                                                    });
                                                });
                                            }
                                        }
                                    },
                                    {
                                        view:"spacer",
                                        gravity:75
                                    },
                                    { 
                                        view: "button", 
                                        tooltip:i18nHelper.getTranslation("Mail financial document"),
                                        width:30, 
                                        align:"right", 
                                        type: "icon", 
                                        id: "invoice_mail_invoice", 
                                        icon: "fa fa-paper-plane blue-icon", 
                                        css:"cog-icon float-right",
                                        fillspace:true ,
                                        on: {
                                            onItemClick:() => {
                                                this.openSendInvoiceByEmailModal();
                                            }
                                        }
                                    },
                                ]
                            },
                            this.lineItemsView,
                            {
                                view: "spacer",
                                height: 20,
                                css: "spacer__invoice__view__last__row",
                            },
                            {
                                cols: [
                                    { 
                                        view:"layout",
                                        rows:[
                                            this.getBasicInfoLayout(),
                                            {
                                                view:"spacer",
                                                height:13
                                            },
                                            invoiceMessagesPortlet
                                        ]
                                    },
                                    { view: "spacer", width: 20 },
                                    { 
                                        view:"layout",
                                        rows:[
                                            this.getRecipientInfoLayout(),
                                            {
                                                view:"spacer",
                                                height:13
                                            },
                                            invoiceNotesPortlet
                                        ]
                                    },
                                ]
                            },
                            { view: "spacer", height: 50 }
                        ]
                    }
                }
                return layout;
            });
        })
    }
    
    getLayout(invoice:IInvoice): webix.ui.scrollviewConfig {
        return 
    }

    getInvoicePermissions():Promise<IPrivilege> {
        return CognovisRestService.getObjectPermission({
            objectId:this.invoiceData.invoice.id,
        })
        .then(res => {
            return res
        });
    }

    getInvoiceData(invoiceId: number): Promise<IInvoice> {
        return CognovisRestInvoiceService.getInvoice({ invoiceIds: [invoiceId] })
        .then(invoices => {
            if (invoices[0]) {
                const invoice = invoices[0];
                return invoice;
            }
        });
    }

    setupButtons(invoiceId: number): void {
        CognovisRestService.getMenuItems({
            parentMenuLabel: "webix_action_invoice",
            objectId: invoiceId
        })
        .then(response => {
            response.map(menuItem => {
                const actionButton = ($$(menuItem.label) as webix.ui.button);
                if(actionButton) {
                    //actionButton.define("tooltip", menuItem.name);
                }
            });
        });
    }

    setInvoiceEditable():Promise<boolean> {
        return CognovisRestService.getObjectPermission({
            objectId:this.invoiceData.invoice.id,
        })
        .then(res => {
            return res.write
        });
    }

    getBasicInfoFields():ICognovisInformationBoxField[] {
        const fields = 
        [
            {
                name:"project",
                translationKey:"Project",
                value:this.invoiceData.project.name,
                editConfig:false
            },
            {
                name:"quoteNumber",
                translationKey:"Quote_number",
                value:this.invoiceData.invoice?.name,
                editConfig:false
            },
            {
                name:"createdBy",
                translationKey:"Created_by",
                value:this.invoiceData.creation_user?.name,
                editConfig:false
            },
            {
                name:"created",
                translationKey:"Created",
                value:this.invoiceData.creation_date,
                editConfig:false
            },
            {
                name:"effectiveDate",
                translationKey:"Effective_date",
                value:this.invoiceData.effective_date,
                editConfig:{
                    type:"datepicker"
                }
            },
            {
                name:"deliveryDate",
                translationKey:"Delivery_date",
                value:this.invoiceData.delivery_date,
                editConfig:{
                    type:"datepicker"
                }
            },
            {
                name:"costCenter",
                translationKey:"Cost_center",
                value:this.invoiceData.cost_center,
                editConfig:{
                    type:"combo",
                    url: () => CognovisRestInvoiceService.getCostCenters({
                    })
                    .then(costCenters => {
                        const newDatasetForCombo: ICombobox[] = [];
                        costCenters.map(costCenter => {
                            newDatasetForCombo.push({
                                value:costCenter.cost_center.name,
                                id:costCenter.cost_center.id
                            });
                        });
                        return newDatasetForCombo;
                    })
                }            
            },
            {
                name:"template",
                translationKey:"Template",
                value:this.invoiceData.template,
                editConfig:{
                    type:"combo",
                    suggest:{
                        fitMaster:false,
                        minWidth:400
                    },
                    url: () => CognovisCategory.getCategory("Intranet Cost Template", true)
                }
            },
            {
                name:"paymentTerm",
                translationKey:"Payment_term",
                value:this.invoiceData.payment_term,
                editConfig:{
                    type:"combo",
                    url: () => CognovisCategory.getCategory("Intranet Payment Term")
                }
            },
            {
                name:"paymentMethod",
                translationKey:"Payment_method",
                value:this.invoiceData.payment_method,
                editConfig:{
                    type:"combo",
                    url: () => CognovisCategory.getCategory("Intranet Invoice Payment Method")
                }
            },
            {
                name:"vatType",
                translationKey:"VAT_type",
                value:this.invoiceData.vat_type,
                editConfig:{
                    type:"combo",
                    url: () => CognovisCategory.getCategory("Intranet VAT Type")
                }
            },
            {
                name:"status",
                translationKey:"Status",
                value:this.invoiceData.cost_status,
                editConfig:false
            },
            {
                name:"currency",
                translationKey:"Currency",
                value:this.invoiceData.currency_name,
                editConfig:false
            },
        ];

        const statusField = fields.find(field => field.name === "status");
        if (this.isInvoiceEditable) {
            statusField.editConfig = {
                type:"combo",
                url: () => CognovisCategory.getCategory("Intranet Cost Status")
            } 
        }
        
        return fields;
    }

    getRecipientInfoFields():ICognovisInformationBoxField[] {
        return [
            {
                name:"customer",
                translationKey:"Customer",
                value:this.invoiceData.company,
                editConfig:{
                    type:"combo",
                    url: () => CognovisRestCompanyService.getCompanies({
                        companyTypeIds:[IntranetCompanyType.CUSTOMER],
                        companyStatusIds:[IntranetCompanyStatus.ACTIVE]
                    })
                    .then(companyInfo => {
                        const customerOptionsForCombo = companyInfo.map(company => {
                            return {
                                id:company.company.id,
                                value:company.company.name
                            }
                        });
                        return customerOptionsForCombo;
                    }),
                    onChange:(data) => {
                        const companyId = data;
                        const combo = ($$("companyContatctId") as webix.ui.combo);
                        CognovisRestCompanyService.getCompanyContacts({
                            companyId:companyId
                        })
                        .then(companyContacts => {
                            const newDatasetForCombo: ICombobox[] = [];
                            companyContacts.map(contact => {
                                newDatasetForCombo.push({
                                    value:`${contact.first_names} ${contact.last_name}`,
                                    id:contact.contact.id
                                });
                            });
                            combo.define("options", newDatasetForCombo);
                            combo.setValue("");
                        })
                    }
                },
            },
            {
                name:"company_contact",
                value:this.invoiceData.company_contact,
                translationKey:"Contact",
                id:"companyContatctId",
                editConfig: {
                    type:"combo",
                    url:() => {
                        return CognovisRestCompanyService.getCompanyContacts({
                            companyId:this.invoiceData.company.id
                        })
                        .then(companyContacts => {    
                            const newDatasetForCombo: ICombobox[] = [];
                            companyContacts.map(contact => {
                                newDatasetForCombo.push({
                                    value:`${contact.first_names} ${contact.last_name}`,
                                    id:contact.contact.id
                                });
                            });
                            return newDatasetForCombo;
                        });
                    }
                }
            },
            {
                name:"address_line1",
                value:`${this.invoiceData.address_line1} ${this.invoiceData.address_line2}`,
                translationKey:"Street_and_number",
                editConfig:false
            },
            {
                name:"address_postal_code",
                value:this.invoiceData.address_postal_code,
                translationKey:"Postcode",
                editConfig:false
            },
            {
                name:"address_city",
                value:this.invoiceData.address_city,
                translationKey:"City",
                editConfig:false
            },
            {
                name:"address_country",
                value:this.invoiceData.address_country,
                translationKey:"Country",
                editConfig:false
            },
            {
                name:"email",
                value:`${this.invoiceData.email}`,
                translationKey:"Email",
                editConfig:false
            },
            {
                name:"telephone",
                value:this.invoiceData.phone,
                translationKey:"Telephone",
                editConfig:false
            },
        ];
        }

    getBasicInfoLayout():webix.ui.layoutConfig {
        const config:ICognovisInformationBoxConfig = {
            entityId:this.invoiceData.invoice.id,
            webixId:"basicData",
            title: i18nHelper.getTranslation("Basic Data"),
            editModalTitle:"Edit Basic Data",
            fields:this.getBasicInfoFields(),
            saveAction:(data) => {
                // Payment term, payment method and template id are only possible values to update in here
                // Rest of data is taken from original object
                let effectiveDate = "";
                if(data.effectiveDate) {
                    effectiveDate = DatetimeHelper.parseDateFromDatepicker(data.effectiveDate);
                }
                let deliveryDate = "";
                if(data.deliveryDate) {
                    deliveryDate = DatetimeHelper.parseDateFromDatepicker(data.deliveryDate);
                }
                CognovisRestInvoiceService.putInvoice({
                    invoiceId:this.invoiceData.invoice.id,
                    requestBody:{
                        company_id:this.invoiceData.company?.id,
                        company_contact_id:this.invoiceData.company_contact?.id,
                        cost_status_id:data.status,
                        cost_type_id:this.invoiceData.cost_type?.id,
                        template_id:data.template,
                        payment_method_id:data.paymentMethod,
                        payment_term_id:data.paymentTerm,
                        vat_type_id:data.vatType,
                        currency_iso:"EUR",
                        effective_date:effectiveDate,
                        cost_center_id:data.costCenter,
                        delivery_date:deliveryDate
                    } as any
                })
                .then(() => {
                    this.getInvoiceData(this.invoiceData.invoice.id)
                    .then(() => {
                        this.lineItemsView.updateTotalAmountsWithLineItems();
                        this.basicDataBox.editionModal.hideModal();
                        this.refresh();
                    })
                })
                .catch(err => {
                    webix.alert({
                        title: err.message,
                        text: err.body.message,
                        type: "alert-error",
                        width: 500,
                        css:"cog-remove-tasks-modal"
                    });
                })
            }
        }
        this.basicDataBox = new InformationBox(this.app,config, this.isInvoiceEditable);
        return {
            view: "layout",
            type: "clean",
            rows: [
                this.basicDataBox
            ]
        }
    }

    getRecipientInfoLayout():webix.ui.layoutConfig { 
        const config:ICognovisInformationBoxConfig = {
            entityId:this.invoiceData.invoice.id,
            webixId:"recipientInfo",
            title: i18nHelper.getTranslation("Recipient"),
            editModalTitle:"Edit Recipient Info",
            fields:this.getRecipientInfoFields(),
            saveAction:(data) => {
                // Payment term, payment method and template id are only possible values to update in here
                // Rest of data is taken from original object
                let effectiveDate = this.invoiceData.effective_date;
                if(!effectiveDate) {
                    effectiveDate = "";
                }
                let deliveryDate = this.invoiceData.delivery_date;
                if(!deliveryDate) {
                    deliveryDate = "";
                }
                CognovisRestInvoiceService.putInvoice({
                    invoiceId:this.invoiceData.invoice?.id,
                    requestBody:{
                        company_id:data.customer,
                        company_contact_id:data.company_contact,
                        cost_status_id:this.invoiceData.cost_status?.id,
                        cost_type_id:this.invoiceData.cost_type?.id,
                        template_id:this.invoiceData.template?.id,
                        payment_method_id:this.invoiceData.payment_method?.id,
                        payment_term_id:this.invoiceData.payment_term?.id,
                        currency_iso:"EUR",
                        effective_date:this.invoiceData.effective_date,
                        cost_center_id:this.invoiceData.cost_center?.id,
                        delivery_date:deliveryDate
                    }
                })
                .then(() => {
                    this.getInvoiceData(this.invoiceData.invoice.id)
                    .then(() => {
                        this.lineItemsView.updateTotalAmountsWithLineItems();
                        this.recipientInfoBox.editionModal.hideModal();
                        this.refresh();
                    });
                })
                .catch(err => {
                    webix.alert({
                        title: err.message,
                        text: err.body.message,
                        type: "alert-error",
                        width: 500,
                        css:"cog-remove-tasks-modal"
                    });
                })
            }
        };
        this.recipientInfoBox = new InformationBox(this.app,config, this.isInvoiceEditable);
        return {
            view: "layout",
            type: "clean",
            rows: [
                this.recipientInfoBox 
            ]         
        }
    }

    createInvoice(invoice:IInvoice, type:IntranetCostType): void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Invoice_is_being_created")});
        CognovisRestInvoiceService.postInvoiceCopy({
            sourceInvoiceIds:[invoice.invoice.id],
            targetCostTypeId: type,
            filedP: true
        })
        .then(newInvoice => {
            CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${newInvoice.invoice.id}&project_id=${newInvoice.project.id}`);
            setTimeout(() => {
                this.cognovisPleaseWaitWindow.hide();
                window.location.reload();
            },100);
        })        
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        })
        .finally(() => {
            this.cognovisPleaseWaitWindow.hide();
        })
    }

    createMultipleInvoices():void {
        // Open CreateMultipleInvoiceModal
        if(!this.createMultipleInvoicesModal) {
            this.createMultipleInvoicesModal = this.ui(CreateMultipleInvoiceModal) as CreateMultipleInvoiceModal;
        }
        this.createMultipleInvoicesModal.openModal();
    }

    openSendInvoiceByEmailModal():void {
        if(!this.sendInvoiceByEmailModal) {
            this.sendInvoiceByEmailModal = this.ui(SendMailModal) as SendMailModal;
        }
        this.sendInvoiceByEmailModal.setCustomAfterSendAction(() => {
            window.location.reload();
        });
        this.sendInvoiceByEmailModal.openModal(this.invoiceData.invoice.id);
    }
}