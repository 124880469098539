/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReferralSource {
    XING = 10000020,
    OUR_WEBSITE_ = 10000022,
    ACQUISITION_PHONE = 10000023,
    PERSONAL_CONTACT = 10000025,
    NETWORKING = 10000028,
    RECOMMENDATION = 10000429,
    OMR_2019 = 10000771,
    LEAD_FORENSICS = 10000773,
}
