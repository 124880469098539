/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetQuality {
    /**
     * Premium Quality
     */
    PREMIUM_QUALITY = 110,
    /**
     * High Quality
     */
    HIGH_QUALITY = 111,
    /**
     * Average Quality
     */
    AVERAGE_QUALITY = 112,
    /**
     * Draft Quality
     */
    DRAFT_QUALITY = 113,
}
