import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { CognovisRestInvoiceService, CognovisRestTimesheetService, IInvoice, ITimesheetEntry, WebixPortalTranslationService } from "../../../sources/openapi";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";

export default class AddLogggedHoursToQuoteModal extends CognovisBasicModal {

    idPrefix = "alhtqm";
    financialDocumentData:IInvoice;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    webixHelpers: WebixHelpers;
    afterSubmitAction:() => void;

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Import billable hours`), 740,660);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.webixHelpers = container.resolve(WebixHelpers);
        return mainLayout;
    }

    openModal(financialDocumentData:IInvoice, afterSubmitAction?:() => void):void {
        this.financialDocumentData = financialDocumentData
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
        this.getUninvoicedHours()
        .then(uninovoicedHours => {
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
            const modalContent = this.getContent(uninovoicedHours);
            const actionButtons = this.getActionButtons();
            this.setContent(modalContent, actionButtons as webix.ui.layoutConfig);
        })
    }


    closeModal():void {
        this.hide();
    }


    getUninvoicedHours():Promise<ITimesheetEntry[]> {
        return CognovisRestTimesheetService.getTimesheetEntry({projectId:this.financialDocumentData.project.id})
        .then(entries => {
            const onlyUninvoiced = entries.filter(entry => !entry["invoice"]);
            const uninvoicedEntriesWithDisplayField = onlyUninvoiced.map(entry => {
                const duration = this.webixHelpers.convertHoursDecimalToDuration(entry.hours.toString());
                const displayedData =  `${entry.task.name} - ${duration} (${entry.user.name})`;
                return {timeNameAndPerson:displayedData, ...entry}
            });
            return uninvoicedEntriesWithDisplayField
        })
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`,
                            align: "right",
                            click:() => {
                                this.saveHoursIntoFinancialDocument();
                            }
                        }  
                    ] 
                };
        return buttons;
    }


    getContent(timesheetEntries:ITimesheetEntry[]):webix.ui.layoutConfig {
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        rows:[
                            {
                                view:"layout",
                                rows:[
                                    {
                                        view:"layout",
                                        cols:[
                                            {
                                                view:"button",
                                                value:i18nHelper.getTranslation("Select all"),
                                                width:120,
                                                height:32,
                                                css:"cog-button-big margin-left-10",
                                                click:() => {
                                                    this.selectAll();
                                                }
                                            },
                                            {
                                                view:"spacer",
                                                gravity:1
                                            }
                                        ]
                                    },
                                    {
                                        view:"spacer",
                                        height:10
                                    },
                                    {
                                        view:"list",
                                        localId:`${this.idPrefix}UninvoicedHoursList`,
                                        borderless:true,
                                        type:{
                                            isChecked:function(obj){
                                                let checkmark = "fas fa-square";
                                                if(obj.isChecked) {
                                                    checkmark = "fas fa-check-square";
                                                }
                                                return `<span class='check webix_icon ${checkmark}'></span>`;
                                            }
                                        },
                                        template:`{common.isChecked()} #timeNameAndPerson#`,
                                        scheme: {
                                            $init: function(obj) {
                                              obj.isChecked = 0;
                                            }
                                        },
                                        data:timesheetEntries,
                                        on: {
                                            onItemClick:(id:number) =>  {
                                                const list = this.$$(`${this.idPrefix}UninvoicedHoursList`) as webix.ui.list;
                                                const item = list.getItem(id);
                                                item.isChecked = item.isChecked ? 0 : 1;
                                                list.updateItem(id, item);
                                            },
                                            onAfterRender:() =>{
                                                const list = this.$$(`${this.idPrefix}UninvoicedHoursList`) as webix.ui.list;
                                                if(list) {
                                                    if(this.isListEmpty()) {
                                                        webix.extend(list, webix.OverlayBox);
                                                        list["showOverlay"](i18nHelper.getTranslation("Nothing_in_here"));
                                                    } else {
                                                        list["hideOverlay"];
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                    },                    
                ]
            }
        };
        return layout
    }

    saveHoursIntoFinancialDocument():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const checkedItems = this.getCheckedItems();
        const onlyHourIds = checkedItems.map(task => task.hour_id);
        CognovisRestInvoiceService.putImportTimesheetTasks({
            invoiceId:this.financialDocumentData.invoice.id,
            requestBody:{
                hour_log_ids:onlyHourIds,
                only_unassigned:true
            }
        })
        .then(() => {
            this.cognovisPleaseWaitWindow.hide();
            this.closeModal();
            if(this.afterSubmitAction) {
                this.afterSubmitAction();
            }
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    isListEmpty():boolean {
        const list = this.$$(`${this.idPrefix}UninvoicedHoursList`) as webix.ui.list;
        if(list) {
            const items = list.serialize();
            if(items.length > 0) {
                return false
            } else {
                return true
            }    
        }
    }

    getCheckedItems():ITimesheetEntry[] {
        const list = this.$$(`${this.idPrefix}UninvoicedHoursList`) as webix.ui.list;
        const items = list.serialize();
        const onlyCheckedItems = items.filter(item => item["isChecked"]);
        return onlyCheckedItems;
    }

    selectAll():void {
        const list = this.$$(`${this.idPrefix}UninvoicedHoursList`) as webix.ui.list;
        const items = list.serialize();
        const totalItems = items.length;
        const totalCheckedItems = items.filter(item => item["isChecked"]).length;
        if(totalItems !== totalCheckedItems) {
            items.map(item => {
                item["isChecked"] = true;
            });
        } else {
            items.map(item => {
                item["isChecked"] = false;
            });      
        }
        list.refresh();
    }

}