/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetSkillBusinessSector {
    /**
     * Alles medizinische
     */
    AGRARWIRTSCHAFT = 10000435,
    ARCHITEKTUR = 10000436,
    AUGENOPTIK = 10000437,
    AUTOMOBILINDUSTRIE = 10000438,
    BAUTECHNIK = 10000439,
    CHEMIE = 10000441,
    DESIGN = 10000442,
    DRUCKDRUCKTECHNIK = 10000443,
    KLEINGERTEELEKTRONIK = 10000444,
    ENTSORGUNG = 10000445,
    ENERGIEGEWINNUNG = 10000446,
    FLUGZEUGBAULUFTFAHRT = 10000447,
    HRPERSONALMANAGEMENT = 10000449,
    INTERIEUR_MBEL = 10000451,
    KOSMETIKKRPERPFLEGE = 10000452,
    ERNHRUNG = 10000453,
    SHIPPING_LOGISTIK = 10000454,
    MASCHINENBAU = 10000455,
    MEDIZINTECHNIK = 10000456,
    FASHION = 10000457,
    PUBLIC_RELATIONS_CORP_COMMUNICATION = 10000458,
    SCHIFFSBAU = 10000461,
    SPIELWAREN = 10000464,
    BUCHHALTUNG = 10000467,
    IMMOBILIENRECHT = 10000468,
    STEUERRECHT = 10000471,
    STRAFRECHT = 10000472,
    ALTERNATIVE_MEDIZIN = 10000473,
    ORTHOPDIE = 10000477,
    PDIATRIE = 10000478,
    RADIOLOGIE = 10000480,
    TIERMEDIZIN = 10000482,
    ZAHNMEDIZIN = 10000483,
    BIOLOGIE = 10000485,
    MUSIK = 10000489,
    PDAGOGIK = 10000490,
    URKUNDENZERTIFIKATE = 10000493,
    TOURISMUS_REISEN = 10000495,
    UHRENSCHMUCK = 10000496,
    BANKFINANZWESEN = 10000499,
    VERSICHERUNGSWESEN = 10000502,
    PHARMAZIE = 10000504,
    KUNST = 10000507,
    PHYSIK = 10000508,
    POLITIK = 10000509,
    COMPUTERSPIELE = 10000514,
    INDUSTRIE = 10000552,
    CASINOSPORTWETTEN = 10000792,
    SPORT_UND_FITNESS = 10000810,
    DIGITALISIERUNGKI = 10000814,
    ERNHRUNGSWISSENSCHAFTKOTROPHOLOGIE = 10000815,
    HANDWERK = 10000816,
    ALLGEMEINES_ZIVILRECHT = 10000817,
    WIRTSCHAFTSRECHT = 10000818,
    ARBEITSRECHT_ = 10000819,
    SOFTWAREIT = 10000829,
    BABY_UND_KINDERBEDARF = 10000830,
    MEDIZIN = 10000831,
}
