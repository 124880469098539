/* eslint-disable security/detect-non-literal-fs-filename */
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
//import PMDetail from "views/project-info/pm-detail-modals/pm-detail-popup";
import ProjectDetails from "views/project-details";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import ProjectBasicInformationPortlet from "./project-basic-information";
import ProjectTeamPortlet from "./project-team";
import ProjectTimesheetPortlet from "./project-timesheet";
import { IntranetCustKolibriRestService, IBizObjectMember, IntranetProjectStatus, IntranetTmTool, ITransProject, WebixPortalProjectService, WebixPortalTranslationService, IntranetSkillRole, IntranetBizObjectRole, CognovisRestCompanyService, WebixPortalPortletService, IWebixPortlet } from "../../../sources/openapi";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import CognovisFileUploaderModal from "../../../sources/modules/file-helpers/cognovis-file-uploader-modal";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import { UserProfile } from "../../../sources/modules/cognovis-profile/profiles-types";
import DuplicateProjectModal from "./pm-duplicate-project-modal";
import ProjectSubProjects from "./project-subprojects";
import ObjectNotesPortlet from "views/portlets/object-notes-portlet";
import MessagesPortlet from "views/portlets/messages-portlet";
import { JetView } from "webix-jet";
import DynProjectInfoPortlet from "./dyn-project-info";
import { WebixAlertConfig } from "@xbs/webix-pro";

export default class PmProjectView extends ProjectDetails {

    //showPMDetail: PMDetail;
    webixHelperClass: WebixHelpers;
    projectId:number;
    currentProject:ITransProject;
    idPrefix = "po";
    newTimeTrackingModal:CognovisBasicModal;
    cloneProjectModal:CognovisBasicModal;
    duplicateProjectModal:DuplicateProjectModal;
    projectTakeOverModal:CognovisBasicModal;
    deliverProjectModal:CognovisBasicModal;
    chargeProjectModal:CognovisBasicModal;
    fileUploaderModal:CognovisFileUploaderModal;
    cognovisPleaseWaitWindow:CognovisPleaseWaitWindow;
    selectAutoEmailSenderModal:CognovisBasicModal;
    nukeProjectModal:CognovisBasicModal;


    config():webix.ui.scrollviewConfig {
        this.projectId = this.getParam("project_id", false);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        const layout = {
            view:"scrollview",
            gravity:1,
            css: "cog-content",
            autowidth:true,
            id:`${this.idPrefix}MainContainer`,
            body:{
                padding:13,
                rows:[
                    this.getToolbar(),
                    {
                        view:"layout",
                        id:`${this.idPrefix}MainContainerBody`,
                        rows:[]
                    }
                ]
            }
        } as webix.ui.layoutConfig;
        return layout
    }

    init():void {
        this.initializeView();
    }

    urlChange():void {
        const newProjectId = this.getParam("project_id", true);
        if(newProjectId !== this.projectId) {
            this.initializeView();
            this.projectId = newProjectId;
        }
        super.urlChange();
    }

    initializeView():void {
        WebixPortalTranslationService.getTransProjects({
            projectId:this.projectId
        })
        .then(projects => {
            if(projects[0]) {
                this.currentProject = projects[0];
                this.updateProjectStatusButtonLabel(this.currentProject);
            }
            const mainContainer = webix.$$(`${this.idPrefix}MainContainerBody`) as webix.ui.layout;
            mainContainer.getChildViews().map(view => {
                mainContainer.removeView(view);
            });
            mainContainer.reconstruct();
            WebixPortalPortletService.getWebixPortlets({objectId:this.projectId, pageUrl:"project-details"})
            .then(portlets => {
                const portletsArr = this.createPortlets(portlets);
                const rows = this.figureOutProperLayout(portletsArr);
                rows.map(row => {
                    mainContainer.addView(row);
                });
            });
        });
    }

    createPortlets(components:IWebixPortlet[]):webix.ui.layoutConfig[] {
        const views:webix.ui.layoutConfig[] = [];
        const sortedComponents = components.sort((a,b) => (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0))
        sortedComponents.map(webixPortlet => {
            let portlet;
            switch(webixPortlet.portlet_type.name) {
                case "ProjectBasicInfoPortlet":
                    portlet = new DynProjectInfoPortlet(this.app, this.projectId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    //portlet = new ProjectBasicInformationPortlet(this.app, this.projectId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    //portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "ProjectTimesheetPorlet":
                    portlet = new ProjectTimesheetPortlet(this.app, this.projectId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "ProjectTeamPortlet":
                    portlet = new ProjectTeamPortlet(this.app, this.projectId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "ProjectSubProjects":
                    portlet = new ProjectSubProjects(this.app, this.projectId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "UserNotesPortlet":
                    portlet = new ObjectNotesPortlet(this.app, this.projectId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "MessagesPortlet":
                    portlet = new MessagesPortlet(this.app, {contextId:this.projectId}, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
            }
            if(portlet) {
                views.push(portlet);
            }
        });
        
        return views;
    }

    convertJetViewToLayout(jetview:JetView, portletId:number, name:string, position:string):webix.ui.layoutConfig {
        return {
            view:"layout",
            name:name,
            componentPosition:position,
            portletId:portletId,
            rows:[
                jetview
            ]
        } as webix.ui.layoutConfig
    }

    figureOutProperLayout(portlets:webix.ui.layoutConfig[]):webix.ui.layoutConfig[] {
        const layoutArr:webix.ui.layoutConfig [] = [];
        const totalRows = Math.ceil(portlets.length / 2);
        const alreadyUsedLeftPortlets = [];
        const alreadyUsedRightPortlets = [];
        const leftPortlets = portlets.filter(portlet => portlet["componentPosition"] === "left");
        const rightPortlets = portlets.filter(portlet => portlet["componentPosition"] === "right");
        for(let index = 1; index <= totalRows; index ++) {
            let leftPortlet = {};
            let rightPortlet = {};
            // Find next unused left portlet
            leftPortlets.reverse().map(lPortlet => {
                if(alreadyUsedLeftPortlets.indexOf(lPortlet["portletId"]) === -1) {
                    leftPortlet = lPortlet;
                }
            });
            // Find next unused right portlet
            rightPortlets.reverse().map(rPortlet => {
                if(alreadyUsedRightPortlets.indexOf(rPortlet["portletId"]) === -1) {
                    rightPortlet = rPortlet;
                }
            });
            const layout = {
                view:"layout",
                height:0,
                rows:[
                    {
                        view:"spacer",
                        height:13
                    },
                    {
                        view:"layout",
                        cols:[
                            leftPortlet,
                            {
                                view:"spacer",
                                width:13
                            },
                            rightPortlet
                        ]
                    }
                ]
            } as webix.ui.layoutConfig
            if(leftPortlet) {
                alreadyUsedLeftPortlets.push(leftPortlet["portletId"]);
            }
            if(rightPortlet) {
                alreadyUsedRightPortlets.push(rightPortlet["portletId"]);
            }
            layoutArr.push(layout);
        };
        return layoutArr
    }

    getToolbar():webix.ui.toolbar {
        const projectToolbar = {
            view:"toolbar",
            height:50,
            paddingY:5,
            css:"cog-pm-project-main-header",
            borderless:true,
            cols:[
                { 
                    view:"button", 
                    id:`${this.idPrefix}ProjectStatusButton`,
                    label:"" , 
                    inputWidth:160,
                    width:160,
                    css: "cog-button-big",
                    click:() => {
                        this.executeProjectStatusButtonAction(this.currentProject);
                    }
                },
                {
                    view:"spacer",
                    width:2
                },
                { 
                    view:"button", 
                    id:`${this.idPrefix}SendInvoicesButton`,
                    label:i18nHelper.getTranslation("Send_credit_notes"), 
                    inputWidth:160,
                    hidden:true,
                    width:160,
                    css: "cog-button-big",
                    click:() => {
                        this.selectAutomaticEmailsSender(this.currentProject);
                    },
                },
                {
                    view:"spacer",
                    width:2
                },
                {
                    view:"button",
                    type:"icon",
                    width:32,
                    tooltip:`${i18nHelper.getTranslation("Create_SDL_Trados_or_MemoQ_project")}`,
                    inputWidth:32,
                    label:"",
                    icon:"fa fa-globe",
                    css:"cog-pm-project-action-button",
                    click:() => this.attemptToCreateSDLTradosOrMemoQProject(this.currentProject)

                },
                {
                    view:"button",
                    type:"icon",
                    width:32,
                    tooltip:`${i18nHelper.getTranslation("Open T:/ drive")}`,
                    inputWidth:32,
                    label:"",
                    icon:"fa fa-hdd-o",
                    css:"cog-pm-project-action-button",
                    click:() => this.openTDrive(this.currentProject)
                },
                {
                    view:"button",
                    type:"icon",
                    width:32,
                    tooltip:`${i18nHelper.getTranslation("Upload files")}`,
                    inputWidth:32,
                    label:"",
                    icon:"fa fa-folder-o",
                    css:"cog-pm-project-action-button",
                    click:() => this.openProjectFilesUploader(this.currentProject)
                },
                {
                    view:"button",
                    type:"icon",
                    width:32,
                    tooltip:`${i18nHelper.getTranslation("Take_over_project")}`,
                    inputWidth:32,
                    label:"",
                    icon:"fa fa-exchange-alt",
                    css:"cog-pm-project-action-button",
                    click:() => {
                        this.attemptToTakeOverProject(this.currentProject);
                    }
                },
                {
                    view:"button",
                    type:"icon",
                    width:32,
                    tooltip:`${i18nHelper.getTranslation("Duplicate_project")}`,
                    inputWidth:32,
                    label:"",
                    icon:"fas fa-clone",
                    css:"cog-pm-project-action-button",
                    hidden:!CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER),
                    click:() => {
                        this.attemptToDuplicateProject(this.currentProject)
                    }
                },
                {
                    view:"button",
                    type:"icon",
                    width:32,
                    tooltip:`${i18nHelper.getTranslation("Nuke_project")}`,
                    inputWidth:32,
                    label:"",
                    icon:"fa fa-bomb",
                    css:"cog-pm-project-action-button",
                    hidden:!CognovisProfile.isUserInGivenGroup(UserProfile.PROJECT_MANAGER),
                    click:() => {
                        this.attemptToNukeProject(this.currentProject);
                    }
                }
            ]
        };
        return projectToolbar as unknown as webix.ui.toolbar
    }

    getProjectInformationBox<T>(header:string, content:T[]): webix.ui.portletConfig {
        const mainPortlet = {
            view: "portlet",
            header:false,
            body:{
                rows:[
                    {
                        type:"header",
                        template:header,
                        css:{
                            "background-color":"#f2f2f2",
                            "border-bottom":"2px solid #59a8c2"
                        }
                    },
                    ...content
                ]
            }
        }
        return mainPortlet as unknown as webix.ui.portletConfig
    }
    


    updateProjectStatusButtonLabel(currentProject:ITransProject):void {
        let buttonLabel = "";
        const projectStatusButton = webix.$$(`${this.idPrefix}ProjectStatusButton`) as webix.ui.button;
        // Get proper label for button
        switch(currentProject.project_status.id) {
            case IntranetProjectStatus.OPEN:
                buttonLabel = i18nHelper.getTranslation("Deliver_project");
                //buttonLabel = "Deliver project";
                projectStatusButton.define("label", buttonLabel);
                projectStatusButton.refresh();
                break;
            case IntranetProjectStatus.POTENTIAL:
            case IntranetProjectStatus.QUALIFYING:
            case IntranetProjectStatus.INQUIRING:
            case IntranetProjectStatus.QUOTING:
            case IntranetProjectStatus.QUOTE_OUT:
                buttonLabel = i18nHelper.getTranslation("Start_project");
                //buttonLabel = "Start project";
                projectStatusButton.define("label", buttonLabel);
                projectStatusButton.refresh();
                break;
            case IntranetProjectStatus.DELIVERED:
            case IntranetProjectStatus.CLOSED:
                buttonLabel = i18nHelper.getTranslation("Charge_project");
                //buttonLabel = "Charge project";
                projectStatusButton.define("label", buttonLabel);
                projectStatusButton.refresh();
                break;
            default:
                projectStatusButton.define("label", i18nHelper.getTranslation("Project_was_charged"));
                projectStatusButton.refresh();
                //projectStatusButton.disable();
        }
        // Show/hide invoices button
        const sendInvoicesButton = webix.$$(`${this.idPrefix}SendInvoicesButton`) as webix.ui.button;
        if(currentProject.project_status.id === IntranetProjectStatus.DELIVERED) {
            sendInvoicesButton.show();
        } else {
            sendInvoicesButton.hide();
        }
    }


    executeProjectStatusButtonAction(currentProject:ITransProject):void {
        switch(currentProject.project_status.id) {
            case IntranetProjectStatus.OPEN:
                this.attemptToDeliverProject(currentProject);
                break;
            case IntranetProjectStatus.POTENTIAL:
            case IntranetProjectStatus.QUALIFYING:
            case IntranetProjectStatus.INQUIRING:
            case IntranetProjectStatus.QUOTING:
            case IntranetProjectStatus.QUOTE_OUT:
                //buttonLabel = i18nHelper.getTranslation("Start_project");
                this.startProject(currentProject)
                break;
            case IntranetProjectStatus.DELIVERED:
            case IntranetProjectStatus.CLOSED:
                this.chargeProject(currentProject);
                break;
            default:
                
                //projectStatusButton.disable();
        }
    }

    startProject(project:ITransProject):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        WebixPortalTranslationService.putTransProject({
            projectId:project.project.id,
            requestBody:{
                project_status_id:IntranetProjectStatus.OPEN,
                source_language_id:project.source_language.id,
                target_language_ids:project.target_language.map(tl => tl.id),
                subject_area_id:project.subject_area?.id,
                company_id:project.company.id,
                project_nr:project.project_nr,
                project_type_id:project.project_type.id,
                customer_contact_id:project.company_contact.id,
                final_company_id:project?.final_company?.id,
                company_project_nr:project.company_project_nr
            }
        })
        .then(() => {
            this.cognovisPleaseWaitWindow.hide();
            window.location.reload();
        });
    }


    attemptToDeliverProject(project:ITransProject):void {
        const modalBody = {
            body:{
                padding:13,
                rows:[
                    {
                        align:"center",
                        autowidth:true,
                        minWidth:480,
                        autoheight:true,
                        template:`${i18nHelper.getTranslation("You_are_about_to_close_project")} ${project.project.name}. ${i18nHelper.getTranslation("Continue")}`,
                    },
                ]
            }
        } as webix.ui.layoutConfig;     
        const translatedButtons = [
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Cancel"),
                click:() => {
                    this.deliverProjectModal.close();
                }
            },
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Yes"),
                click:() => {
                    this.deliverProject(project);
                }
            },
        ] as webix.ui.buttonConfig[];
        const buttonsLayout = {
                view:"layout",
                padding:0,
                cols:translatedButtons
        } as webix.ui.layoutConfig; 
        this.deliverProjectModal = this.ui(CognovisBasicModal) as CognovisBasicModal;
        this.deliverProjectModal.open(modalBody, buttonsLayout, `Close ${project.project.name} ?`);
    }

    deliverProject(project:ITransProject):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        WebixPortalTranslationService.putTransProject({
            projectId:project.project.id,
            requestBody:{
                project_status_id:IntranetProjectStatus.DELIVERED,
                source_language_id:project.source_language.id,
                target_language_ids:project.target_language.map(tl => tl.id),
                subject_area_id:project.subject_area?.id,
                company_id:project.company?.id,
                project_nr:project.project_nr,
                project_type_id:project.project_type?.id,
                customer_contact_id:project.company_contact?.id,
                final_company_id:project?.final_company?.id,
                company_project_nr:project.company_project_nr
            }
        })
        .then(() => {
            this.cognovisPleaseWaitWindow.hide();
            window.location.reload();
        });
    }

    chargeProject(project:ITransProject):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        WebixPortalTranslationService.putTransProject({
            projectId:project.project.id,
            requestBody:{
                project_status_id:IntranetProjectStatus.INVOICED
            }
        })
        .then(() => {
            this.cognovisPleaseWaitWindow.hide();
            window.location.reload();
        });
    }

    selectAutomaticEmailsSender(project:ITransProject):void {
        WebixPortalProjectService.getProjectMembers({
            projectId:project.project.id
        })
        .then(projectMembers => {
            CognovisRestCompanyService.getCompanies({companyId:8720})
            .then(companies => {
                console.log(UserStateManager.getCurrentUserData());
                const internalCompanyAccountingContact = {id:companies[0].accounting_contact.id, value:companies[0].accounting_contact.name};
                const currentUser = {id:UserStateManager.getCurrentlyLoggedUser().id, value:UserStateManager.getCurrentUserData().user.name};
                const onlyPMs = projectMembers.filter((member:IBizObjectMember) => member.role.id === IntranetBizObjectRole.PROJECT_MANAGER);
                const onlyPmsParsed = onlyPMs.map((member:IBizObjectMember) => ({id:member.member.user.id, value:member.member.user.name}));
                const senderCombo = {
                    view:"combo",
                    borderless:true,
                    options: [internalCompanyAccountingContact, currentUser, ...onlyPmsParsed],
                    value:internalCompanyAccountingContact.id.toString()
                };
                const modalBody = {
                    body:{
                        padding:13,
                        rows:[
                            {
                                align:"center",
                                autowidth:true,
                                minWidth:480,
                                autoheight:true,
                                borderless:true,
                                template:`${i18nHelper.getTranslation("lt_Select_automatic_emai")}`
                            },
                            senderCombo
                        ]
                    }
                } as webix.ui.layoutConfig;      
                const translatedButtons = [
                    {
                        view:"button",
                        gravity:50,
                        label:i18nHelper.getTranslation("Cancel"),
                        click:() => {
                            this.selectAutoEmailSenderModal.close();
                        }
                    },
                    {
                        view:"button",
                        gravity:50,
                        label:i18nHelper.getTranslation("Charge_project"),
                        click:() => {
                            const automaticEmailSenderCombo = webix.$$(senderCombo["id"]) as webix.ui.combo;
                            if(automaticEmailSenderCombo.getValue() !== "") {
                                this.saveAutomaticEmailSender(project, Number(automaticEmailSenderCombo.getValue()));
                            } else {
                                webix.alert({
                                    title: i18nHelper.getTranslation("Error"),
                                    text: i18nHelper.getTranslation("lt_Please_select_automat"),
                                    minWidth:500,
                                    type: "alert-error"
                                } as WebixAlertConfig);
                            }
                        },
                    },
                ] as webix.ui.buttonConfig[];
                const buttonsLayout = {
                    view:"layout",
                    padding:0,
                    cols:translatedButtons
            } as webix.ui.layoutConfig; 
                this.selectAutoEmailSenderModal = this.ui(CognovisBasicModal) as CognovisBasicModal;
                this.selectAutoEmailSenderModal.open(modalBody, buttonsLayout, `${i18nHelper.getTranslation("Charge_project")} ${project.project.name} ?`);
            });
        });
    }

    saveAutomaticEmailSender(project:ITransProject, senderId:number):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        IntranetCustKolibriRestService.putSendProviderBills({
            projectId:project.project.id,
            senderId:senderId
        })
        .then(() => {
            this.cognovisPleaseWaitWindow.hide();
            this.selectAutoEmailSenderModal.close();
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            this.selectAutoEmailSenderModal.close();
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    attemptToDuplicateProject(project:ITransProject):void {
        if(!this.duplicateProjectModal) {
            this.duplicateProjectModal = this.ui(DuplicateProjectModal) as DuplicateProjectModal;
        }
        this.duplicateProjectModal.openModal(project);
    }

    attemptToCloneProject(project:ITransProject):void {
        const modalBody = {
            body:{
                padding:13,
                rows:[
                    {
                        align:"center",
                        autowidth:true,
                        minWidth:480,
                        autoheight:true,
                        template:`${i18nHelper.getTranslation("Would_you_like_to_clone_project")} ${project.project.name} ?`,
                    },
                ]
            }
        } as webix.ui.layoutConfig;      
        const translatedButtons = [
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Cancel"),
                click:() => {
                    this.cloneProjectModal.close();
                }
            },
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Yes"),
                click:() => {
                    alert("cloned!")
                }
            },
        ] as webix.ui.buttonConfig[];
        const buttonsLayout = {
                view:"layout",
                padding:0,
                cols:translatedButtons
        } as webix.ui.layoutConfig;
        this.cloneProjectModal = this.ui(CognovisBasicModal) as CognovisBasicModal;
        this.cloneProjectModal.open(modalBody, buttonsLayout, `${i18nHelper.getTranslation("Clone_project")} ${project.project.name} ?`);
    }

    attemptToTakeOverProject(project:ITransProject):void {
        const modalBody = {
            body:{
                padding:13,
                rows:[
                    {
                        align:"center",
                        css:{
                            "text-align":"center"
                        },
                        autowidth:true,
                        minWidth:480,
                        autoheight:true,
                        template:`${i18nHelper.getTranslation("Would_you_like_to_take_over_project")} ${project.project.name} ?`,
                    },
                ]
            }
        } as webix.ui.layoutConfig;     
        const translatedButtons = [
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Cancel"),
                click:() => {
                    this.projectTakeOverModal.close();
                }
            },
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Yes"),
                click:() => {
                    WebixPortalProjectService.postProjectTakeOver({
                        projectId:this.currentProject.project.id,
                        newProjectLeadId:UserStateManager.getCurrentlyLoggedUser().id
                    })
                    .then(() => {
                        window.location.reload();
                    })
                }
            },
        ] as webix.ui.buttonConfig[];
        const buttonsLayout = {
                view:"layout",
                padding:0,
                cols:translatedButtons
        } as webix.ui.layoutConfig;
        this.projectTakeOverModal = this.ui(CognovisBasicModal) as CognovisBasicModal;
        this.projectTakeOverModal.open(modalBody, buttonsLayout, `${i18nHelper.getTranslation('Take_over')} ${project.project.name} ?`);
    }

    attemptToNukeProject(project:ITransProject):void {
        const modalBody = {
            body:{
                padding:13,
                rows:[
                    {
                        align:"center",
                        css:{
                            "text-align":"center"
                        },
                        autowidth:true,
                        minWidth:480,
                        autoheight:true,
                        template:`${i18nHelper.getTranslation("Are_you_sure_to_nuke_project")} ${project.project.name} ?`
                    },
                ]
            }
        } as webix.ui.layoutConfig;     
        const translatedButtons = [
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Cancel"),
                click:() => {
                    this.nukeProjectModal.close();
                }
            },
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Yes"),
                click:() => {
                    this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                    WebixPortalTranslationService.deleteTransProject({projectId:project.project.id})
                    .then(result => {
                        this.nukeProjectModal.close();
                        this.cognovisPleaseWaitWindow.hide();
                        window.location.href = "#!/projects";
                        window.location.reload();
                    })
                    .catch(err => {
                        this.cognovisPleaseWaitWindow.hide();
                        this.nukeProjectModal.close();
                        let message = i18nHelper.getTranslation("Unknown error");
                        if(err.body?.message) {
                            message = err.body?.message;
                        }
                        webix.alert({
                            title: i18nHelper.getTranslation("Error"),
                            text:message,
                            minWidth:500,
                            type: "alert-error"
                        } as WebixAlertConfig);
                    });
                }
            },
        ] as webix.ui.buttonConfig[];
        const buttonsLayout = {
                view:"layout",
                padding:0,
                cols:translatedButtons
        } as webix.ui.layoutConfig;
        this.nukeProjectModal = this.ui(CognovisBasicModal) as CognovisBasicModal;
        this.nukeProjectModal.open(modalBody, buttonsLayout, `${i18nHelper.getTranslation('Nuke_project')} ${project.project.name} ?`);
    }


    attemptToCreateSDLTradosOrMemoQProject(project:ITransProject):void {
        const modalBody = {
                body:{
                width:770,
                height:90,
                rows:[
                    {
                        view:"label",
                        align:"center",
                        label:i18nHelper.getTranslation("Would_you_rather_create_Trados_or_MemoQ_project"),
                        css:"modal-text-content"
                    },
                ]
            }
        } as webix.ui.layoutConfig;
        const translatedButtons = [
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Open_in_SDL_Trados"),
                click:() => {
                    this.createSDLTradosOrMemoQProject(project, IntranetTmTool.TRADOS_STUDIO_2017)
                }
            },
            {
                view:"button",
                gravity:50,
                label:i18nHelper.getTranslation("Open_in_MemoQ"),
                click:() => {
                    this.createSDLTradosOrMemoQProject(project, IntranetTmTool.MEMOQ)
                }
            },
        ] as webix.ui.buttonConfig[];
        const buttonsLayout = {
                view:"layout",
                padding:0,
                cols:translatedButtons
        } as webix.ui.layoutConfig;
        this.newTimeTrackingModal = this.ui(CognovisBasicModal) as CognovisBasicModal;
        this.newTimeTrackingModal.open(modalBody, buttonsLayout, `${i18nHelper.getTranslation("Convert")} ${project.project.name} ?`);      
            
    }

    createSDLTradosOrMemoQProject(project:ITransProject, tmTool:IntranetTmTool):void {
        IntranetCustKolibriRestService.getKolibriTmToolUrl({
            projectId: this.projectId,
            tmToolId: tmTool
        })
        .then(res => {
            this.newTimeTrackingModal.close();
            if (tmTool === IntranetTmTool.MEMOQ) {
                window.open(res["url"], '_blank').focus();
            } else {
                navigator.clipboard.writeText(res["url"]);
                window.open(res["url"], '_blank');
            }
        })
        .catch(() => {
            this.newTimeTrackingModal.close();
        })
    }

    openTDrive(project:ITransProject):void {
        IntranetCustKolibriRestService.getKolibriTUrls({
            projectId:project.project.id
        })
        .then(res => {
            const tDrive = res.find(url => url.type === "project");
            if(tDrive.url) {
                console.log(tDrive.url);
                navigator.clipboard.writeText(tDrive.url);
                window.open(tDrive.url, '_blank');
            }
        });
    }

    openProjectFilesUploader(project:ITransProject):void {
        if(!this.fileUploaderModal) {
            this.fileUploaderModal = this.ui(CognovisFileUploaderModal) as CognovisFileUploaderModal;
        }
        const modalTitle = `${project.project_nr}`;
        this.fileUploaderModal.openModal(project.project.id, "write", {allowFolderSelection:true, entityFolderId:project.project_folder.id}, modalTitle);
    }   


}
