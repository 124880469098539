import { IAssignment, IntranetUom, WebixPortalAssignmentService } from "../../../../openapi";
import CognovisBasicModal from "../../../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../../modules/i18n-helper/i18n-helper";
import { WebixHelpers } from "../../../../modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { CognovisCategory } from "../../../../../sources/modules/cognovis-category/cognovis-category";


export default class UpdateAssignmentRateModal extends CognovisBasicModal {

    webixHelpers:WebixHelpers;
    currentAssignment:IAssignment;
    afterSaveAction:() => void;

    init():void {
        this.webixHelpers = container.resolve(WebixHelpers);
    }

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Update_Assignment_Rate`), 640, 480);
        return mainLayout;
    }

    openModal(additionalData:ICognovisModalAdditionlData, afterSaveAction?:() => void):void {
        const cognovisBasicModal = this.$$("updateAssignmentRateModal") as webix.ui.window;
        if(afterSaveAction) {
            this.afterSaveAction = afterSaveAction;
        }
        if(cognovisBasicModal) {
            cognovisBasicModal.show();
        } else {
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
        }
        const actionButtons = this.getActionButtons();
        const assignment = additionalData.assignment as IAssignment;
        this.currentAssignment = assignment;
        const modalContent = this.getContent(assignment.rate, assignment.assignment_units, assignment.uom.id);
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig);
    }

    closeModal():void {
        this.close();
    }

    getContent(currentRate:number, currentUnits:number,currentUnitOfMeasure:IntranetUom):webix.ui.layoutConfig {
        const modal = {
                view:"form",
                localId:"updateAssignmentRateModal",
                elements:[
                    {
                        view: "text", 
                        localId:"uapRateInput",
                        name: "rate",
                        label: i18nHelper.getTranslation(`Rate`),
                        value:currentRate
                    },
                    {
                        view: "text", 
                        localId:"uapUnitsInput",
                        name: "units", 
                        label: i18nHelper.getTranslation(`Units`),
                        value:currentUnits
                    },
                    {
                        view: "combo",
                        localId:"uapUomInput",
                        placeholder: "Unit of Measure",
                        name: "uom",
                        value:currentUnitOfMeasure,
                        label:i18nHelper.getTranslation(`Unit_of_Measure`),
                        suggest: {
                            body: {
                                url: () =>
                                    CognovisCategory.getCategory(
                                        "Intranet UoM"
                                    ),
                            },
                        },
                    },
                ]
        }
        return modal;
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Cancel`)}`, 
                            align: "left",
                            click:() => {
                                this.close();
                            }
                        },
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`save`)}`,
                            align: "right",
                            click:() => {
                                const rateInput = this.$$("uapRateInput") as webix.ui.text;
                                const newRate = parseFloat(rateInput.getValue())
                                const unitsInput = this.$$("uapUnitsInput") as webix.ui.text;
                                const newUnits = parseFloat(unitsInput.getValue());
                                const uomInput = this.$$("uapUomInput") as webix.ui.combo;
                                const newUom = parseInt(uomInput.getValue());
                                this.saveNewRate(this.currentAssignment, newRate, newUnits, newUom);
                            }
                        },    
                    ] 
                };
        return buttons;
    }


    saveNewRate(assignment:IAssignment, newRate:number, newUnits:number, newUom:IntranetUom):void {
        const updatedAssignmentObj = {
            assignmentId:assignment.assignment.id,
            uom_id:newUom,
            assignmentDeadline:assignment.assignment_deadline,
            startDate:assignment.start_date,
            assignmentStatusId:assignment.assignment_status.id,
            rate:newRate,
            assignment_units:newUnits,
        };
        WebixPortalAssignmentService.putTranslationAssignments({
            assignmentId:updatedAssignmentObj.assignmentId,
            requestBody:updatedAssignmentObj
        })
        .then(() => {
            // Something will happen here
            if(this.afterSaveAction) {
                this.afterSaveAction();
            }
            this.close();
        })
    }
}