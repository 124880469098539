import { IFreelancerAssignment, IntranetFreelanceAssignmentStatus, WebixPortalAssignmentService } from "../../../sources/openapi";
import BaseView from "views/baseview";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { UserProfile } from "../../../sources/modules/cognovis-profile/profiles-types";
import CognovisProfile from "../../../sources/modules/cognovis-profile/cognovis-profile";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import { MyAssignmentsTopTabs } from "./my-assignments-top-tabs";

export default class MyClosedAssignments extends BaseView {

    idPrefix = "mca";
    requestedAssignments:IFreelancerAssignment[] = [];
    currentAssignments:IFreelancerAssignment[] = [];
    currentUserId:number;
    pricesVisible = true;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    myAssignmentsTopTabs:MyAssignmentsTopTabs;

    config():Promise<webix.ui.layoutConfig> {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.myAssignmentsTopTabs = container.resolve(MyAssignmentsTopTabs);
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const freelancerId = this.getParam("freelancer_id", false);
        if(CognovisProfile.isUserInGivenGroup(UserProfile.EMPLOYEE)) {
            this.pricesVisible = false;
        }   
        if(freelancerId) {
            this.currentUserId = freelancerId;
        } else {
            this.currentUserId = UserStateManager.getCurrentlyLoggedUser().id;
        }
        return this.getLayout()
        .then(layout => {
            return layout;
        });
    }

    getLayout():Promise<webix.ui.layoutConfig> {
        
        const closedAssignmentsStatusesIds = [
            IntranetFreelanceAssignmentStatus.WORK_DELIVERED,
            IntranetFreelanceAssignmentStatus.ASSIGNMENT_CLOSED
        ];
        return WebixPortalAssignmentService.getFreelancerAssignments({
            freelancerId:this.currentUserId
        })
        .then(freelancerAssignments => {        
            const closedAssignmentsTranslated = i18nHelper.getTranslation("Closed_assignments");
            const closedAssignments = freelancerAssignments.filter(flAssignment => closedAssignmentsStatusesIds.indexOf(flAssignment.assignment_status.id) > -1);
            const closedAssignmentsDatatable = this.getMyAssignmentsDatatableStructure(closedAssignments, "CurrentAssignmnetsDatable", `${closedAssignmentsTranslated}`);
            const layout = {
                view:"layout",
                padding:13,
                css:"cog-content",
                rows:[
                    this.myAssignmentsTopTabs.getTopTabs("closed-assignments"),
                    closedAssignmentsDatatable
                ]
            };
            this.cognovisPleaseWaitWindow.hide();
            return layout;
        });
    }


    getMyAssignmentsDatatableStructure(freelancerAssignments:IFreelancerAssignment[], tableId:string, tableTitle:string):webix.ui.layoutConfig {
        const columns = this.getMyAssignmentsDatatableColumns();
        const datatable = {
            view:"layout",
            rows:[
                {
                    type:"header",
                    template:tableTitle,
                    css: "cog-box-header",
                },
                {
                    view: "datatable",
                    tooltip:true,
                    css:"cog-clickable-table",
                    adjust:true,
                    hover:"cog-datatable-hover",
                    type: 'wide',
                    id:`${this.idPrefix}${tableId}`,
                    header:[tableTitle],
                    columns:columns,
                    data:freelancerAssignments,
                    on: {
                        onAfterLoad:function(){
                            this.sort("deadline", "asc");
                            this.markSorting("deadline", "asc");
                        },
                        onItemClick:(row) => {
                            const table = webix.$$(`${this.idPrefix}${tableId}`) as webix.ui.datatable;
                            const item = table.getItem(row);
                            const assignmentId = item.assignment.id;
                            CognovisNavigator.navigateTo(`main/assignments.assignment-details?assignment_id=${assignmentId}`);
                        }
                    }
                }
            ]

        } 
        return datatable
    }


    getMyAssignmentsDatatableColumns():webix.ui.datatableConfig {
        const columns = [
            {
                id:"assignmentType",
                header:i18nHelper.getTranslation("Type"),
                width:60,
                map:"#assignment_type.name#",
                tooltip:(obj) => {
                    const html = `${obj.assignment_type.name}<br/>${obj.source_language?.name} => ${obj.target_language?.name}<br/>${obj.assignment_units} ${obj.uom.name}`;
                    return html
                },
                template: (obj) => {
                    return `<span class='webix_icon table-action-icon ${obj.assignment_type.icon_or_color}'></span>`;
                }
            },
            {
                id:"projectNr",
                map:"#project_nr#",
                header:i18nHelper.getTranslation("Project_nr"),
            },
            {
                id:"projectName",
                header:i18nHelper.getTranslation("Project_name"),
                map:"#project.name#",
                sort:"string",
                fillspace:true
            },
            {
                id:"batchName",
                header:i18nHelper.getTranslation("Batch_name"),
                map:"#freelance_package.name#",
                sort:"string",
                fillspace:true
            },
            {
                id:"fee",
                header:i18nHelper.getTranslation("Price"),
                map:"#rate#",
                hidden:!this.pricesVisible,
                template:function(obj) {
                    return PriceHelper.formatAssignmentRate(obj.rate);
                }
            },
            {
                id:"deadline",
                header:i18nHelper.getTranslation("Deadline"),
                map:"#assignment_deadline#",
                width:140,
                template:(assignment) => {
                    let dateToDisplay = "";
                    if(assignment.assignment_deadline) {
                        const myFormat = webix.Date.dateToStr("%d.%m.%Y %H:%i", false);
                        const formattedDate = myFormat(new Date(assignment.assignment_deadline));
                        dateToDisplay = formattedDate;
                    }
                    return dateToDisplay
                }
            }
        ] as unknown as webix.ui.datatableConfig
        return columns;
    }
}