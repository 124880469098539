import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import {CognovisRestDynamicService, IViewColumn} from "../../../sources/openapi";
import DynviewsSingleColumnEditModal from "views/dynviews-settings/dynviews-single-column-edit-modal";
import DynviewsEditModal from "views/dynviews-settings/dynviews-edit-modal";

export default class DynviewColumnModal extends CognovisBasicModal {

    viewId: number;
    idPrefix = "dynvcm";
    dynviewSingleColumnEditModal:DynviewsSingleColumnEditModal;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle: string;
    afterSubmitAction: () => void;
    

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 760, 520);  // adjust size if needed
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(viewId?: number, afterSubmitAction?: () => void): void {
        this.modalTitle = i18nHelper.getTranslation("Edit Columns");
        const modalContent = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons, this.modalTitle);
        this.cognovisPleaseWaitWindow.hide();          
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        if(viewId) {
            this.viewId = viewId;
            this.loadColumns();
        } 
    }

    getContent(): webix.ui.layoutConfig {
        // Define your layout
        const layout = {
            view: "scrollview",
            id:`${this.idPrefix}ColumnsDatatableContainer`,
            body: {
                padding: 13,
                rows: [
                    this.getTopButtons(),
                    {
                        view: "datatable",
                        id: `${this.idPrefix}ColumnsDatatable`,
                        columns: [
                            {
                                id: "column_name",
                                header: i18nHelper.getTranslation("Column name"),
                                fillspace: true
                            },
                            {
                                width:42,
                                id:"view-details",
                                header:" ",
                                tooltip:i18nHelper.getTranslation("Edit view column"),
                                template:() => {
                                    return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-solid fa-magnifying-glass'></span>`;
                                }
                            },
                            {
                                width:42,
                                id:"action-delete",
                                header:" ",
                                tooltip:i18nHelper.getTranslation("Delete view column"),
                                template:() => {
                                    return `<span style='font-size:0.86rem;' class='webix_icon table-action-icon fa-sharp fa-regular fa-trash'></span>`;
                                }
                            }
                        ],
                        on: {
                            onItemClick: (row) => {
                                const table = webix.$$(`${this.idPrefix}ColumnsDatatable`) as webix.ui.datatable;
                                const item: IViewColumn = table.getItem(row);
                                switch (row.column) {
                                    case "view-details":
                                        this.openSingleColumnViewEditModal(item);
                                        break;
                                    case "action-delete":
                                        this.attemptToDeleteViewColumn(item);
                                        break;
                                }
                            }
                        }
                    }
                ]
            }
        };
        return layout;
    }

    getTopButtons():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:40,
            gravity:1,
            css:{
                "margin":"2px 0"
            },
            cols:[
                {
                    view:"spacer",
                    width:0,
                },
                {
                    view:"button",
                    width:160,
                    inputWidth:160,
                    inputHeight:34,
                    height:34,
                    css:"cog-button-big",
                    label:i18nHelper.getTranslation("New view column"),
                    click:() => {
                        this.openSingleColumnViewEditModal(undefined);
                    }
                }
            ]
        }
    }

    loadColumns(): void {
        CognovisRestDynamicService.getViewColumns({ viewId: this.viewId})
            .then(columns => {
                const datatable = ($$(`${this.idPrefix}ColumnsDatatable`) as webix.ui.datatable);
                if(datatable) {
                    datatable.clearAll();
                    datatable.define("data", columns);
                }
            });
    }

    getActionButtons(): webix.ui.layoutConfig {
        const buttons = {
            view: "layout",
            padding: 0,
            cols: [
                {
                    view: "button",
                    value: `${i18nHelper.getTranslation('Close')}`,
                    click: () => {
                        this.hide();
                    }
                }
            ]
        };
        return buttons;
    }

    openSingleColumnViewEditModal(column:IViewColumn): void {
        if(!this.dynviewSingleColumnEditModal) {
            this.dynviewSingleColumnEditModal = this.ui(DynviewsSingleColumnEditModal) as DynviewsSingleColumnEditModal;
        }
        this.dynviewSingleColumnEditModal.openModal(this.viewId, column, () => {
            this.refreshTable();
        });
    }

    attemptToDeleteViewColumn(viewColumn:IViewColumn):void {
        const declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_delete_view_column?");
        webix.confirm({
            title:viewColumn.column_name,
            type: "confirm-warning",
            text: declineOrAccept,
            width: 480
        })
            .then(() => {
                this.deleteView(viewColumn.column_id)
            });
    }

    deleteView(columnId:number):void {
        CognovisRestDynamicService.deleteViewColumn({
            columnId: columnId
        }).then(() => {
            this.refreshTable();
        });
    }

    refreshTable():void {
        const table = webix.$$(`${this.idPrefix}ColumnsDatatable`) as webix.ui.datatable;
        if(table) {
            this.showLoader();
            table.clearAll();
            this.loadColumns();
            this.hideLoader();
        }
    }

    showLoader():void {
        const container = webix.$$(`${this.idPrefix}ColumnsDatatableContainer`) as webix.ui.layout & {showProgress?:(text:string) => void}
        webix.extend(container, webix.ProgressBar);
        container.showProgress(i18nHelper.getTranslation("Please_wait"));
        if(container.showProgress) {
            container.showProgress(i18nHelper.getTranslation("Please_wait"));
        }
    }

    hideLoader():void {
        const container = webix.$$(`${this.idPrefix}ColumnsDatatableContainer`) as webix.ui.layout & {hideProgress?:() => void}
        container.hideProgress();
    }

}
