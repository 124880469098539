/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WebixNotificationType {
    ASSIGNMENT_OVERDUE = 4260,
    ASSIGNMENT_DELIVERED = 4261,
    ASSIGNMENT_ACCEPTED = 4262,
    ASSIGNMENT_DENIED = 4263,
    ASSIGNMENT_REQUEST_OUTSTANDING = 4264,
    PROJECT_OVERDUE = 4265,
    QUOTE_ACCEPTED = 4266,
    QUOTE_DENIED = 4267,
    PROJECT_DELIVERED = 4268,
    PROJECT_CONTACT_AGAIN = 4269,
    PACKAGE_FILE_MISSING = 4270,
    ASSIGNMENT_DEADLINE_CHANGE = 4271,
    FILE_WAS_UPLOADED = 4272,
    NOTE_WAS_ADDED = 4273,
    REQUEST_QUOTE_CHANGE = 4275,
    DEFAULT = 4280,
}
