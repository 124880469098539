/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetCompanyType {
    /**
     * \r\n
     */
    PROVIDER = 56,
    /**
     * Final Company
     */
    CUSTOMER = 57,
    FINAL_COMPANY = 10247,
    SMALL_BUSINESS = 10000301,
}
