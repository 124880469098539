import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { singleton } from "tsyringe";
import { CognovisRestCompanyService, ICompanyContact} from "../../../sources/openapi";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";


@singleton()
export class UsersTableBuilder  {

    idPrefix = "ut";
    fetchAction:<T>() => Promise<T[]>;

    buildUsersTable(tableId:string, visibleColumns:string[], filtersOff = false, fetchAction:<T>() => Promise<T[]>):webix.ui.datatableConfig {
        this.fetchAction = fetchAction;
        return {
            view: "datatable",
            id:`${this.idPrefix}UsersDatatable`,
            header:false,
            width:400,
            rowHeight:80,
            css:"cog-clickable-table",
            height:400,
            hover:"cog-datatable-hover",
            tooltip:true,
            columns:this.getUsersTableColumns(visibleColumns, filtersOff),
            ready() {
                (webix.ui({
                    view:"contextmenu", 
                    localId:`${this.idPrefix}UsersTableContextMenu`,
                    data: 
                    [
                        i18nHelper.getTranslation("Open_in_new_tab")
                    ],
                    on: {
                        onMenuItemClick:function() {
                            const mainContext = this.getContext();
                            const user = mainContext.obj.getItem(mainContext.id.row);
                            const url = `#!/main/users-overview.user-details?user_id=${user.contact.id}`;
                            // eslint-disable-next-line security/detect-non-literal-fs-filename
                            window.open(url, '_blank').focus();
                        }
                    }
                }) as webix.ui.contextmenu).attachTo(this);
            },
            url:this.fetchAction,
            on: {
                onItemClick:(row) => {
                    const table = webix.$$(`${this.idPrefix}UsersDatatable`) as webix.ui.datatable;
                    const item = table.getItem(row);
                    const userId = item.contact.id;
                    switch(row.column) {
                        case "actions-delete":
                            const confirmWarning = i18nHelper.getTranslation("lt_You_want_to_remove_the_contact") + `<br/>`;
                            webix.confirm({
                                title: i18nHelper.getTranslation("any_are_you_sure_message"),
                                type: "confirm-warning",
                                text: confirmWarning,
                                width: 480
                            })
                            .then(() => {
                                const table = webix.$$(`${this.idPrefix}UsersDatatable`) as webix.ui.datatable;
                                const user = table.getItem(row) as ICompanyContact
                                CognovisRestCompanyService.deleteCompanyContact(
                                    {
                                        contactId:userId
                                    }
                                )
                                .then(res => {
                                    this.refreshTableData();
                                });
                            });
                        break;
                        default:
                            CognovisNavigator.goToObjectDetails(userId, "user", item["company"]["id"]);
                        break;
                    }
                },
                onAfterLoad:() => {
                    const table = webix.$$(`${this.idPrefix}UsersDatatable`) as webix.ui.datatable;
                    if(table.serialize().length === 0) {
                        (table as webix.ui.datatable & {showOverlay:(message:string) => void}).showOverlay(i18nHelper.getTranslation("No data"));
                    }  
                }
            }
        };
    }

    buildUsersDataview(tableId:string, visibleColumns:string[], filtersOff = false, fetchAction:<T>() => Promise<T[]>):webix.ui.dataviewConfig {
        this.fetchAction = fetchAction;
        return {
            view: "dataview",
            width:400,
            css:"cog-clickable-table",
            minHeight:400,
            template:(obj) => {
                return `${obj.first_names} ${obj.last_name} ${this.getAvatarHTML(obj.portrait_url)}`;
            },
            id:`${this.idPrefix}${tableId}`,
            tooltip:true,
            url:this.fetchAction,
            xCount:2, 
            yCount:1,
            type:{
                width: 261,
                height: 150
            }
        };
    }

    refreshTableData():void {
        const table = webix.$$(`${this.idPrefix}UsersDatatable`) as webix.ui.datatable;
        table.clearAll();
        this.fetchAction()
        .then(res => {
            table.define("data", res);
            table.refresh();
        });
    }

    getUsersTableColumns(visibleColumns:string[], filtersOff = false):webix.ui.layoutConfig[] {
        const columns = [
            {
                name:"userId",
                hidden:true
            },
            {
                name:"avatar",
                template:(user) => {
                    return this.getAvatarHTML(user.portrait_url)
                }
            },
            {
                name:"firstNames",
                map:"#first_names#",
                hidden:true
            },
            {
                name:"lastName",
                map:"#last_name#",
                hidden:true
            },
            {
                name:"fullName",
                template:(user) => `${user.contact.name}`,
                fillspace:true
            },
            {  
                id:"actions-delete",
                name:"actions-delete",
                width:36,
                tooltip:false,
                template:() => {
                    return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-trash'></span>`;
                }
            }
        ];
        return columns
    }

    getAvatarHTML(portraitUrl:string) {
        return `<div style='padding-top:10px' class='anon-avatar-bg'><div class=''></div><img alt="" style='border-radius:50%; border:2px solid #ffffff; width:60px; height:60px;' src='${portraitUrl}'/></div>`;
    }

}