import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import CognovisBasicModal from "../cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../i18n-helper/i18n-helper";
import { CognovisRestDynamicService, IDynamicAttribute } from "../../../sources/openapi";
import { CognovisCategory } from "../cognovis-category/cognovis-category";


export default class DynfieldCreationModal extends CognovisBasicModal {

    idPrefix = "dcm";
    firstFocusFieldName:string;
    dynfields:IDynamicAttribute[] = [];
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    submitAction:(data:unknown, callback?:() => void) => void;
    customAfterSubmitAction:(data:unknown, callback?:() => void) => void;
    modalTittle = "";
    defaultModalTitle = i18nHelper.getTranslation("Edit");
    dynfieldsConfig:IDynfieldSetup;
    predefinedFields:webix.ui.baseviewConfig[];
    alreadySubmittedFields:string[] = [];
    defaultValues:{[key: string]: string | number};
    customRules:{field:string, rule:Function}[] = [];
    customEvents:{[key: string]: string | number | Function}[] = [];

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 0, 0);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(dynfieldsConfig:IDynfieldSetup, predefinedFields:webix.ui.baseviewConfig[], modalTitle:string, defaultValues:{[key: string]: string | number}, customAfterSubmitAction?:(data:unknown, callback?:() => void) => void):void {
        this.dynfieldsConfig = dynfieldsConfig;
        this.modalTittle = modalTitle;
        this.predefinedFields = predefinedFields;
        this.defaultValues = defaultValues;
        if(customAfterSubmitAction) {
            this.customAfterSubmitAction = customAfterSubmitAction;
        }
        const totalNumberOfDynfieldSetups = dynfieldsConfig.pages.length;
        dynfieldsConfig.pages.map((dynfieldSetup, index) => {
            this.getDynfields(dynfieldSetup.objectType, dynfieldSetup.pageUrl, dynfieldSetup.objectSubtypeId)
            .then(dynfields => {
                const temp = [];
                dynfields.map(df => {
                    temp.push(df);
                });
                this.dynfields = this.dynfields.concat(temp);
                if((index + 1) >= totalNumberOfDynfieldSetups) {
                    const modalContent = this.getContent();
                    const actionButtons = this.getActionButtons();
                    this.setContent(modalContent, actionButtons, this.modalTittle);
                    this.cognovisPleaseWaitWindow.hide();          
                    const modal = (this.getRoot() as webix.ui.window);
                    modal.show(); 
                    let height = ((this.dynfields.length + this.predefinedFields.length) * 65) + 40;
                    if(height >= 770) {
                        height = 770;
                    }
                    if(height <= 220) {
                        height = 220;
                    }
                    const root = (this.getRoot() as webix.ui.window);
                    root.config.height = height;
                    root.adjust();
                    this.afterShow();
                    this.bindCustomEvents();
                    this.setRequiredOrNotClasses();
                    this.dynfields = [];
                };
            });
        });
    }

    bindCustomEvents():void {
        const root = this.getRoot() as webix.ui.layout;
        const form = root.queryView({view:"form"}) as webix.ui.form;
        this.customEvents.map(event => {
            const field = form.queryView({name:event["field"]});
            if(field) {
                field.attachEvent(event["type"], event["action"]);
            }
        });   
    }

    updateButtonLabel(buttonId:string, newLabel:string) {
        const button = this.$$(`${this.idPrefix}${buttonId}`) as webix.ui.button;
        if(button) {
            button.define("value", newLabel);
            button.refresh();
        }
    }

    extractValuesFromObject<T>(object:T):{[key: string]: string} {
        const newObj = {};
        Object.keys(object).forEach(key => {
            if(object[key]) {
                if(object[key]["id"]) {
                    newObj[key] = object[key].id.toString();
                    newObj[`${key}_id`] = object[key].id.toString();
                    newObj[`${key}_name`] = object[key].name.toString();   
                } else {
                    newObj[key] = object[key];
                }
            }
        });
        return newObj
    }

    afterShow():void {
        // Nothing here
        // Just a blueprint
    }

    submit():void {
        // Nothing here
        // Just a blueprint
    }

    afterSubmit<T>(obj:T):void {
        // Nothing here
        // Just a blueprint
    }

    setRequiredOrNotClasses():void {
        const fields = this.collectAllFields();
        const rules = this.getFormRules();
        const root = this.getRoot() as webix.ui.layout;
        const form = root.queryView({view:"form"}) as webix.ui.form;
        fields.map(fieldName => {
            const field = form.queryView({name:fieldName}) as webix.ui.text;
            if(field) {
                webix.html.removeCss(field.getNode(), "mandatory");
            }
        });
        fields.map(fieldName => {
            if(rules[fieldName]) {
                const field = form.queryView({name:fieldName}) as webix.ui.text;
                if(field) {
                    webix.html.addCss(field.getNode(), "mandatory");
                }
            }
        });
    }

    collectAllFields():string[] {
        const fieldsArr = [];
        const root = this.getRoot() as webix.ui.layout;
        const form = root.queryView({view:"form"}) as webix.ui.form;
        const values = form.getValues();
        Object.keys(values).forEach(field => {
            fieldsArr.push(field);
        });
        return fieldsArr
    }

    rebuildForms(dynfieldsConfig:IDynfieldSetup):void {
        const totalNumberOfDynfieldSetups = dynfieldsConfig.pages.length;
        dynfieldsConfig.pages.map((dynfieldSetup, index) => {
            this.getDynfields(dynfieldSetup.objectType, dynfieldSetup.pageUrl, dynfieldSetup.objectSubtypeId)
            .then(dynfields => {
                const temp = [];
                dynfields.map(df => {
                    temp.push(df);
                });
                this.dynfields = this.dynfields.concat(temp);
                if((index + 1) >= totalNumberOfDynfieldSetups) {
                    let height = ((this.dynfields.length + this.predefinedFields.length) * 65) + 40;
                    if(height >= 770) {
                        height = 770;
                    }
                    if(height <= 220) {
                        height = 220;
                    }
                    const root = (this.getRoot() as webix.ui.window);
                    root.config.height = height;
                    root.adjust();
                    const container = this.$$(`${this.idPrefix}DynfielCreationModalForm`) as webix.ui.layout;
                    container.define("elements", this.buildFields());
                    container.reconstruct();
                    this.afterShow();
                    this.setRequiredOrNotClasses();
                    this.dynfields = [];
                };
            });
        });
    }

    getContent():webix.ui.layoutConfig {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        width:720,
                        localId:`${this.idPrefix}DynfieldCreationModalContainer`,
                        rows:[
                            {
                                view:"form",
                                borderless:true,
                                localId:`${this.idPrefix}DynfielCreationModalForm`,
                                elements:this.buildFields(),
                                rules:this.getFormRules()
                            }
                        ]
                    }                   
                ]
            }
        };
        return layout
    }

    getDynfields(objectType:string, pageUrl:string, objectSubtypeId?:number):Promise<IDynamicAttribute[]> {
        let params = {
            objectType:objectType,
            pageUrl:pageUrl
        }
        if(objectSubtypeId) {
            params["objectSubtypeId"] = objectSubtypeId;
        }
        return CognovisRestDynamicService.getDynamicAttributes(params)    
        .then(dynfields => {
            const sortedBySortOrder = dynfields.sort((a,b) => (a["sort_order"] > b["sort_order"]) ? 1 : ((b["sort_order"] > a["sort_order"]) ? -1 : 0))
            // Useful
            const fakeSectionHeadings = ["Personal Data", "Address", "Other"];
            dynfields = dynfields.map(field => {
                const random = Math.floor(Math.random() * 3);
                field.dynamic_subtypes.map(subtpye => {
                    subtpye.section_heading = fakeSectionHeadings[random];
                });
                return field;
            });
            return dynfields
        });
    }

    getFormRules():{[key:string]:() => boolean} {
        const rules = {};
        this.dynfields.map(dynfield => {
            let fieldIsRequired = false;
            dynfield.dynamic_subtypes.map(subtype => {
                if(subtype.required_p) {
                    fieldIsRequired = true;
                }
            });
            if(fieldIsRequired) {
                rules[dynfield.attribute.name] = webix.rules.isNotEmpty;
            }
        });
        this.customRules.map(rule => {
            rules[rule.field] = rule.rule;     
        });
        return rules;
    }

    buildFields():webix.ui.baseviewConfig[] {
        const fieldsArr = [];
        this.dynfields = this.executeSubtypesFiltering(this.dynfields, "display");
        this.dynfields.map(field => {
            //field.widget.name
            const newField = this.buildSpecificType(field);
            if(newField) {
                fieldsArr.push(newField);
            }
        });
        this.predefinedFields.map(predefinedField => {
            const newPredefinedField = this.getSingleFieldContainer(predefinedField);
            if(newPredefinedField) {
                fieldsArr.push(newPredefinedField);
            }
        });
        // Now we still need to make checks against subtypes

        return fieldsArr
    }

    executeSubtypesFiltering(dynfields:IDynamicAttribute[], neededDisplayMode:string):IDynamicAttribute[] {
        // We need to loop thru all subtypes of all dynfields to decide wheter such type of object should have such field.
        let filteredDynfields:IDynamicAttribute[] = [];
        const alreadyUsed = [];
        this.dynfieldsConfig.pages.map(dynfieldSetup => {
            switch(dynfieldSetup.objectType) {
                default:
                    dynfields.map(dynfield => {
                        let addFlag = false;
                        dynfield.dynamic_subtypes.map(subtype => {
                            if(this.hasProperDisplayMode(neededDisplayMode,subtype.display_mode)) {
                                addFlag = true;
                            }
                        });
                        if(addFlag && alreadyUsed.indexOf(dynfield.attribute.id) === -1) {
                            filteredDynfields.push(dynfield);
                            alreadyUsed.push(dynfield.attribute.id);
                        }
                    });
                    break;
            }
        });
        return filteredDynfields
    }

    hasProperDisplayMode(neededDisplayMode:string, subtypeDisplayMode:string):boolean {
        let final = false;
        switch(neededDisplayMode) {
            case "none":
                break;
            case "display":
                if(subtypeDisplayMode === "display" || subtypeDisplayMode === "edit") {
                    final = true;
                }
                break;
            case "edit":
                if(subtypeDisplayMode === "edit") {
                    final = true;
                }
                break;
            default:
                break;
        }
        return final
    }

    makeReadonly():void {
        const root = this.getRoot() as webix.ui.layout;
        const form = root.queryView({view:"form"}) as webix.ui.form;
        for (var key in form.elements) {
            const element = form.elements[key];
            element.define("disabled", true);
            element.refresh();
        };
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        {
                            view:"button",
                            localId:`${this.idPrefix}SaveButton`,
                            value:`${i18nHelper.getTranslation(`Save`)}`,
                            click:() => {
                                this.submit();
                            }
                        }
                    ] 
                };
        return buttons;
    }

    buildSpecificType(field:IDynamicAttribute):webix.ui.layoutConfig {
        // Figure out value
        let value:number | string = "";      
        const component = this.getWebixComponent(field, value as string);
        if(component) {
            const container = this.getSingleFieldContainer(component);
            return container
        }
    }
    
    getSingleFieldContainer(field:webix.ui.layoutConfig):webix.ui.layoutConfig {
        return {
            view:"layout",
            rows:[
                {
                    view:"spacer",
                    height:4
                },
                field,
                {
                    view:"spacer",
                    height:4
                }
            ]
        }
    }

    getWebixComponent(field:IDynamicAttribute, value:string):webix.ui.baseviewConfig {
        let webixComponent = {
            view:field.widget_type,
            name:field.attribute.name,
            label:i18nHelper.getTranslationWithKey(field.display_name, field.i18n_key),
            labelWidth:270,
            value:value,
            placeholder:""
        };
        if(field.widget_type === "datepicker") {
            webixComponent["format"] = "%d.%m.%Y";
        }
        if(field.widget_type === "datetimepicker") {
            webixComponent["view"] = "datepicker";
            webixComponent["format"] = "%d.%m.%Y %H:%i";
            webixComponent["timepicker"] = true;
        }
        if(field.widget_type === "richtext") {
            webixComponent["height"] = 200;
        }
        if(field.widget_data && field.widget_data.length > 0) {
            webixComponent["options"] = field.widget_data;
        }
        if(field.category_type) {
            const suggestBox = {
                body: {
                    url: () => CognovisCategory.getCategory(field.category_type),
                }
            };
            webixComponent["suggest"] = suggestBox;
        }
        if(this.firstFocusFieldName !== "" && webixComponent["name"] === this.firstFocusFieldName) {
            webixComponent["on"] = {
                onAfterRender:function() {
                    setTimeout(() => {
                        this.focus();
                    },100);
                }
            }
        }
        return webixComponent
    }

    closeModal():void {
        this.dynfields = [];
        this.hide();
    }

    validateIfNeeded(form:webix.ui.form):boolean {
        if(!this.dynfieldsConfig[0].checkForRequiredFields) {
            return true;
        } else {
            return form.validate();
        }
    }

    focusOnInput(fieldName:string) {
        const root = this.getRoot() as webix.ui.layout;
        const form = this.$$(`${this.idPrefix}DynfielCreationModalForm`) as webix.ui.form;
        const field = form.elements.find(value => value === fieldName) as webix.ui.text;  
        if(field) {
            field.focus();
        }
    }


}