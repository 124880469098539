import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { CognovisRestTimesheetService, WebixPortalProjectService } from "../../../sources/openapi";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import CognovisBasicModal from "../cognovis-basic-modal/cognovis-basic-modal";
import { DatetimeHelper } from "../datetime-helpers/datetime-helpers";
import { i18nHelper } from "../i18n-helper/i18n-helper";
import { WebixHelpers } from "../webix-helpers/webix-helpers";


export default class NewTimesheetEntryModal extends CognovisBasicModal {

    idPrefix = "ntem";
    selectedDate:Date;
    currentUser:{id:number, value:string, name:string};
    possibleProjects:{id:string, value:string}[];
    webixHelpers: WebixHelpers;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    afterSaveAction:() => void;

    config():webix.ui.windowConfig {
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        const currentUserId = UserStateManager.getCurrentlyLoggedUser().id;
        const loggedUserData = webix.storage.session.get("logged_user_data");
        this.currentUser =   {
            id:currentUserId,
            value:`${loggedUserData.first_names} ${loggedUserData.last_name}`,
            name:`${loggedUserData.first_names} ${loggedUserData.last_name}`
        };
        const mainLayout = super.getMainLayout(" ",920,480);
        return mainLayout;
    }

    openModal(selectedDate:Date, afterSaveAction?:() => void):void {
        this.selectedDate = selectedDate;
        if(afterSaveAction) {
            this.afterSaveAction = afterSaveAction;
        }
        WebixPortalProjectService.getUserProjects({
        })
        .then(projects => {
            this.possibleProjects = projects.map(project => ({id:project.project.id.toString(), value:`${project.project_nr} - ${project.project.name}`}));
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
            const modalContent = this.getContent();
            const prettyDate = selectedDate.toLocaleDateString('de-DE');
            const modalTitle = `${this.currentUser.name} ${prettyDate}`;
            const actionButtons = this.getActionButtons();
            this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle); 
        });
    }

    closeModal():void {
        this.hide();
    }

    getContent():webix.ui.layoutConfig {
        const layout = {
            view:"layout",
            rows:[
                this.getForm()
            ]
        };
        return layout;
    }

    getForm():webix.ui.formConfig {
        const form = {
            view:"form",
            localId:`${this.idPrefix}NewEntryForm`,
            elements:[
                {
                    view:"combo",
                    options: {
                        fitMaster:true,
                        width:800,
                        data:this.possibleProjects,
                    },
                    name:"new_entry_project",
                    label:i18nHelper.getTranslation("Project"),
                    on: {
                        onChange:(projectId:number) => {
                            const taskCombo = this.$$(`${this.idPrefix}TaskSelector`) as webix.ui.combo;
                            taskCombo.define("options", []);
                            taskCombo.refresh();
                            CognovisRestTimesheetService.getTimesheetTask({projectId:projectId})
                            .then(timesheetTasks => {
                                const preparedForCombo = timesheetTasks.map(task => ({id:task.task.id, value:task.task.name}));
                                taskCombo.define("options", preparedForCombo);
                                taskCombo.refresh();
                                if(preparedForCombo[0]) {
                                    taskCombo.setValue(preparedForCombo[0].id.toString());
                                }
                            });
                        }
                    }
                },
                {
                    view:"combo",
                    name:"new_entry_task",
                    localId:`${this.idPrefix}TaskSelector`,
                    label:i18nHelper.getTranslation("Task")
                },
                {
                    view:"text",
                    name:"new_entry_duration",
                    label:i18nHelper.getTranslation("Duration"),
                    placeholder:"e.g 1h 30m"
                },
                {
                    view:"textarea",
                    name:"new_entry_comment",
                    label:i18nHelper.getTranslation("Comment"),
                }
            ]
        };
        return form
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`,
                            align: "right",
                            click:() => {
                                const newEntryForm = this.$$(`${this.idPrefix}NewEntryForm`) as webix.ui.form;
                                const values = newEntryForm.getValues();
                                const taskId = values.new_entry_task;
                                const duration = values.new_entry_duration;
                                const comment = values.new_entry_comment;
                                this.saveNewEntry(taskId,duration,comment);
                            }
                        }, 
                    ] 
                };
        return buttons;
    }

    saveNewEntry(taskId:number, duration:string, comment?:string):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const parsedDay = this.webixHelpers.formatDate(this.selectedDate);
        const decimalHours = this.webixHelpers.convertDurationToHoursDecimal(duration);
        CognovisRestTimesheetService.postTimesheetEntry({
            projectId:taskId,
            requestBody:{
                day:parsedDay,
                hours:decimalHours,
                user_id:this.currentUser.id,
                note:comment
            }
        })
        .then(() => {
            this.cognovisPleaseWaitWindow.hide();
            if(this.afterSaveAction) {
                this.afterSaveAction();
            }
            this.closeModal();
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal",
            });
        });
    }
}