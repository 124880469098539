/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImReportId } from '../models/ImReportId';
import type { IntegerInt32 } from '../models/IntegerInt32';
import type { IntranetReportStatus } from '../models/IntranetReportStatus';
import type { IntranetReportTypeIds15120 } from '../models/IntranetReportTypeIds15120';
import type { IReport } from '../models/IReport';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestReportService {

    /**
     * @returns IReport Report with data
     * @throws ApiError
     */
    public static getReport({
        reportId,
        reportName,
    }: {
        /**
         * Report for which we want to retrieve the data
         */
        reportId?: ImReportId,
        /**
         * we can also query by report_name instea of id
         */
        reportName?: string,
    }): CancelablePromise<IReport> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/report',
            query: {
                'report_id': reportId,
                'report_name': reportName,
            },
        });
    }

    /**
     * Returns a list of reports a user has access to without the data
     * @returns IReport Array of reports without the columns or row information
     * @throws ApiError
     */
    public static getReports({
        reportTypeIds,
        reportStatusId,
        reportParentId,
    }: {
        /**
         * Type of reports
         */
        reportTypeIds?: IntranetReportTypeIds15120,
        /**
         * Status of reports
         */
        reportStatusId?: IntranetReportStatus,
        /**
         * report parent id
         */
        reportParentId?: IntegerInt32,
    }): CancelablePromise<Array<IReport>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reports',
            query: {
                'report_type_ids': reportTypeIds,
                'report_status_id': reportStatusId,
                'report_parent_id': reportParentId,
            },
        });
    }

}
