import { container } from "tsyringe";
import "../../styles/app.css";
import BaseView from "../baseview";
import "./account-info.style.css";
import { IJetApp, JetView } from "webix-jet";
import { IJetConfig } from "webix-jet/dist/types/interfaces";
import {
	CognovisRestService,
	ICategory,
    IntranetProjectStatus,
    IntranetProjectType,
    ITransProject,
    IWebixPortlet,
    WebixPortalPortletService,
    WebixPortalTranslationService
} from "../../../sources/openapi/index";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import AbsencesCreationModal from "./vacations-creation-modal";
import CognovisWorkCalendar from "../../modules/cognovis-work-calendar/cognovis-work-calendar";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import VacationsPortlet from "./vacations-portlet";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import ReportPortlet from "views/reports/report-portlet";

export default class AccountInfoView extends BaseView {

	idPrefix = "ai";
	webixHelpers: WebixHelpers;
	currentUser:{id:number, value:string, name:string};
	absencesCreationModal:AbsencesCreationModal;
	absencesTypes:ICategory[];
	cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

	constructor(app: IJetApp, name: string, config: IJetConfig) {
		super(app, name, config);
	}

	config():webix.ui.layoutConfig {
		this.webixHelpers = container.resolve(WebixHelpers);
		this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
		const loggedUserData = webix.storage.session.get("logged_user_data");
        let currentUserId = webix.storage.local.get("wjet_user").user_id;
        if(webix.storage.session.get("cognovis_faked_user_data")) {
            currentUserId = webix.storage.session.get("cognovis_faked_user_data").user_id;
        }
        this.currentUser =   {
            id:currentUserId,
            value:`${loggedUserData.first_names} ${loggedUserData.last_name}`,
            name:`${loggedUserData.first_names} ${loggedUserData.last_name}`
        };
        const layout = {
            view:"scrollview",
            gravity:1,
            css: "cog-content",
            autowidth:true,
            id:`${this.idPrefix}MainContainer`,
            body:{
                padding:13,
                id:`${this.idPrefix}MainContainerBody`,
                rows:[]
            }
        } as webix.ui.layoutConfig;
        return layout
    }
    
    init():void {
        const mainContainer = webix.$$(`${this.idPrefix}MainContainerBody`) as webix.ui.layout;
        WebixPortalPortletService.getWebixPortlets({pageUrl:"account-info"})
        .then(portlets => {
            const portletsArr = this.createPortlets(portlets);
            const rows = this.figureOutProperLayout(portletsArr);
            rows.map(row => {
                if(row) {
                    mainContainer.addView(row);
                }
            });
        })
    }

    createPortlets(components:IWebixPortlet[]):webix.ui.layoutConfig[] {
        const views:webix.ui.layoutConfig[] = [];
        const sortedComponents = components.sort((a,b) => (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0))
        sortedComponents.map(webixPortlet => {
            let portlet;
            switch(webixPortlet.portlet_type.name) {
                case "WorkCalendar":
                    portlet = new CognovisWorkCalendar(this.app, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "VacationsPortlet":
                    portlet = new VacationsPortlet(this.app, this.currentUser, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "ReportPortlet":
                    portlet =  new ReportPortlet(this.app, {reportId:0, reportName:"My Projects"}, webixPortlet.portlet_name, webixPortlet.portlet_id,i18nHelper.getTranslation(`${webixPortlet.portlet_name}`), this.getMyProjectsPortletAdditionalColumns(), this.getMyProjectsPortletAdditionalEvents());
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
            }
            if(portlet) {
                views.push(portlet);
            }
        });
        return views;
    }
    

    convertJetViewToLayout(jetview:JetView, portletId:number, name:string, position:string):webix.ui.layoutConfig {
        return {
            view:"layout",
            name:name,
            componentPosition:position,
            portletId:portletId,
            rows:[
                jetview
            ]
        } as webix.ui.layoutConfig
    }

    figureOutProperLayout(portlets:webix.ui.layoutConfig[]):webix.ui.layoutConfig[] {
        const layoutArr:webix.ui.layoutConfig [] = [];
        const totalRows = Math.ceil(portlets.length / 2) + 1;
        const alreadyUsedLeftPortlets = [];
        const alreadyUsedRightPortlets = [];
        const leftPortlets = portlets.filter(portlet => portlet["componentPosition"] === "left");
        const rightPortlets = portlets.filter(portlet => portlet["componentPosition"] === "right");
        for(let index = 1; index <= totalRows; index ++) {
            let leftPortlet = {};
            let rightPortlet = {};
            // Find next unused left portlet
            leftPortlets.reverse().map(lPortlet => {
                if(alreadyUsedLeftPortlets.indexOf(lPortlet["portletId"]) === -1) {
                    leftPortlet = lPortlet;
                }
            });
            // Find next unused right portlet
            rightPortlets.reverse().map(rPortlet => {
                if(alreadyUsedRightPortlets.indexOf(rPortlet["portletId"]) === -1) {
                    rightPortlet = rPortlet;
                }
            });
            const layout = {
                view:"layout",
                height:0,
                rows:[
                    {
                        view:"spacer",
                        height:13
                    },
                    {
                        view:"layout",
                        cols:[
                            leftPortlet,
                            {
                                view:"spacer",
                                width:13
                            },
                            rightPortlet
                        ]
                    }
                ]
            } as webix.ui.layoutConfig
            if(leftPortlet) {
                alreadyUsedLeftPortlets.push(leftPortlet["portletId"]);
            }
            if(rightPortlet) {
                alreadyUsedRightPortlets.push(rightPortlet["portletId"]);
            }
            layoutArr.push(layout);
        };
        return layoutArr
    }

    getMyProjectsPortletAdditionalEvents():{[key:string]:Function} {
        return {
            onItemClick:(row) => {
                const datatable = webix.$$(`rpReportDatatable`) as webix.ui.datatable;
                if(datatable) {
                    const item = datatable.getItem(row);
                    switch(row.column) {
                        case "action-reject":
                            if(item.fullObj_project_status_id && (Number(item.fullObj_project_status_id.id) === IntranetProjectStatus.POTENTIAL)) {
                                this.attemptToUpdateProjectStatus(item, IntranetProjectStatus.DECLINED);
                            }
                            break;
                        case "action-accept":
                            if(item.fullObj_project_status_id && (Number(item.fullObj_project_status_id.id) === IntranetProjectStatus.POTENTIAL)) {
                                this.attemptToUpdateProjectStatus(item, IntranetProjectStatus.OPEN);
                            }
                            break;
                    }
                }
            }
        }
    }

    getMyProjectsPortletAdditionalColumns():{width:number, id:string, header:string, tooltip:string, template:Function}[] {
        const columns = [
            {
                width:42,
                id:"action-accept",
                header:" ",
                tooltip:i18nHelper.getTranslation("Accept_project"),
                template:(obj) => {
                    if(obj.fullObj_project_status_id && (Number(obj.fullObj_project_status_id.id) === IntranetProjectStatus.POTENTIAL)) {
                        return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-check-circle accept'></span>`;
                    } else {
                        return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-check-circle disabled'></span>`;
                    }
                }
            },
            {
                width:42,
                id:"action-reject",
                header:" ",
                tooltip:i18nHelper.getTranslation("Reject_project"),
                template:(obj) => {
                    if(obj.fullObj_project_status_id && (Number(obj.fullObj_project_status_id.id) === IntranetProjectStatus.POTENTIAL)) {
                        return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-close-circle reject'></span>`;
                    } else {
                        return  `<span style='color:#59a8c2' class='webix_icon table-action-icon mdi mdi-close-circle disabled'></span>`;
                    }
                }
            }
        ];
       // return []
        return columns
    }

    attemptToUpdateProjectStatus(project:ITransProject, newProjectStatus:IntranetProjectStatus):void {
        let declineOrAccept = "";
        if(newProjectStatus === IntranetProjectStatus.OPEN) {
            declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_accept_project?");
        } 
        if(newProjectStatus === IntranetProjectStatus.DECLINED) {
            declineOrAccept = i18nHelper.getTranslation("Are_you_sure_you_want_to_decline_project?");
        }
        let projectName = project["project_id"]["name"];
        if(!projectName) {
            projectName = project["fullObj_project_id"]["name"]
        }
        webix.confirm({
            title: projectName,
            type: "confirm-warning",
            text: declineOrAccept,
            width: 480
        })
        .then(() => {
            this.updateProjectStatus(project, newProjectStatus)
        }); 
    }

    updateProjectStatus(project:ITransProject, newProjectStatus:IntranetProjectStatus):void {
        let projectId = project["project_id"].id;
        if(!projectId) {
            projectId = project["fullObj_project_id"].id;
        }
        WebixPortalTranslationService.putTransProject({
            projectId:project["fullObj_project_id"].id,
            requestBody:{
                project_status_id:newProjectStatus as number
            }
        })
        .then(() => {
            this.app.callEvent("action::reload-report-table", []);
        });
    }

}
