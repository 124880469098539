/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boolean0 } from '../models/Boolean0';
import type { Id } from '../models/Id';
import type { IError } from '../models/IError';
import type { ImNoteId } from '../models/ImNoteId';
import type { INote } from '../models/INote';
import type { INoteBody } from '../models/INoteBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CognovisRestNotesService {

    /**
     * Return the notes for an object
     * @returns INote Notes for the object as an array with user information
     * @throws ApiError
     */
    public static getNote({
        noteId,
        objectId,
        includeDeletedP,
    }: {
        /**
         * note which we want to retrieve
         */
        noteId?: ImNoteId,
        /**
         * object for which we want to retrieve the notes
         */
        objectId?: Id,
        /**
         * Should we included deleted notes?
         */
        includeDeletedP?: Boolean0,
    }): CancelablePromise<Array<INote>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/note',
            query: {
                'note_id': noteId,
                'object_id': objectId,
                'include_deleted_p': includeDeletedP,
            },
        });
    }

    /**
     * Create a new note
     * @returns INote Notes for the object as an array with user information
     * @throws ApiError
     */
    public static postNote({
        objectId,
        requestBody,
    }: {
        /**
         * Object we want to attach the note to
         */
        objectId: Id,
        /**
         * The note information we want to provide
         */
        requestBody: INoteBody,
    }): CancelablePromise<INote> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/note',
            query: {
                'object_id': objectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Purge a note from the database
     * @returns IError Array of errors found
     * @throws ApiError
     */
    public static deleteNote({
        noteId,
    }: {
        /**
         * Note we want to purge from the database
         */
        noteId: ImNoteId,
    }): CancelablePromise<Array<IError>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/note',
            query: {
                'note_id': noteId,
            },
        });
    }

    /**
     * @returns INote Notes for the object as an array with user information
     * @throws ApiError
     */
    public static putNote({
        noteId,
        requestBody,
    }: {
        /**
         * Note we want to update
         */
        noteId: ImNoteId,
        /**
         * The note information we want to provide
         */
        requestBody: INoteBody,
    }): CancelablePromise<INote> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/note',
            query: {
                'note_id': noteId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
