import { CognovisRestInvoiceService, IInvoice, IntranetCostType, ITransTask, WebixPortalTranslationService } from "../../../sources/openapi";
import { container } from "tsyringe";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";


export default class AddTaskToQuote extends CognovisBasicModal {

    idPrefix = "attqm";
    projectId:number;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    selectedTasks:ITransTask[];
    callbackAction:() => void;

    config(): webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Send E-Mail`), 640,440);
        return mainLayout;
    }

    init():void {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
    }

    openModal(projectId:number, taskIds:ITransTask[], callbackAction?:() => void):void {
        this.projectId = projectId;
        this.selectedTasks = taskIds;
        if(callbackAction) {
            this.callbackAction = callbackAction;
        }
        const modalTitle = i18nHelper.getTranslation("Select_quote");
        CognovisRestInvoiceService.getInvoice({ projectId:this.projectId, costTypeIds:[IntranetCostType.QUOTE] })
        .then(invoices => {
            invoices.map((invoice => invoice["nameAndGrandTotal"] = `${invoice.invoice.name} - ${invoice.first_names} ${invoice.last_name} ${PriceHelper.formatPrice(invoice.grand_total)}`));
            const modalContent = this.getContent(invoices);
            const actionButtons = this.getActionButtons();
            this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
        });
    }

    closeModal():void {
        this.hide();
    }

    getContent(documents:IInvoice[]):webix.ui.layoutConfig {
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}DocumentsContainer`,
                        rows:[
                            {
                                view:"layout",
                                paddingY:13,
                                paddingX:10,
                                rows:[
                                ]
                            },
                            {
                                view:"list",
                                localId:`${this.idPrefix}DocumentsList`,
                                borderless:true,
                                type:{
                                    isChecked:function(obj){
                                        let checkmark = "fas fa-square";
                                        if(obj.isChecked) {
                                            checkmark = "fas fa-check-square";
                                        }
                                        return `<span class='check webix_icon ${checkmark}'></span>`;
                                    }
                                },
                                template:`{common.isChecked()} #nameAndGrandTotal#`,
                                scheme: {
                                    $init: function(obj) {
                                      obj.isChecked = 0;
                                    }
                                },
                                data:documents,
                                on: {
                                    onItemClick:(id:number) =>  {
                                        const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
                                        const item = list.getItem(id);
                                        item.isChecked = item.isChecked ? 0 : 1;
                                        list.updateItem(id, item);
                                    },
                                    onAfterRender:() =>{
                                        const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
                                        if(this.isListEmpty()) {
                                            webix.extend(list, webix.OverlayBox);
                                            list["showOverlay"](i18nHelper.getTranslation("Nothing_in_here"));
                                        } else {
                                            list["hideOverlay"];
                                        }
                                    }
                                }
                            }
                        ]
                    },                    
                ]
            }
        };
        return layout
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Cancel_and_go_back`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`,
                            align: "right",
                            click:() => {
                                const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
                                const items = list.serialize();
                                const totalSelectedItems = items.filter(item => item.isChecked).length;
                                if(totalSelectedItems === 1) {
                                    this.saveTaskIntoQuote(this.selectedTasks);             
                                } else {
                                    webix.alert({
                                        title: i18nHelper.getTranslation("Error"),
                                        text: i18nHelper.getTranslation("Select_only_one_quote"),
                                        type: "alert-error",
                                        width: 500,
                                        css:"cog-remove-tasks-modal"
                                    }); 
                                }
                            }
                        },    
                    ] 
                };
        return buttons;
    }

    getCheckedItems():IInvoice[] {
        const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
        const items = list.serialize();
        const onlyCheckedItems = items.filter(item => item["isChecked"]);
        return onlyCheckedItems;
    }

    saveTaskIntoQuote(taskIds:ITransTask[]):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const checkedItems = this.getCheckedItems();
        const singleQuoteId = checkedItems[0].invoice.id;
        const onlyTaskIds = taskIds.map(task => task.task.id);
        WebixPortalTranslationService.postTransQuote({
            requestBody:{
                trans_tasks:onlyTaskIds,
                quote_id:singleQuoteId
            }
        })
        .then(quote => {
            this.cognovisPleaseWaitWindow.hide();
            CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${quote[0].invoice.id}&project_id=${quote[0].project.id}`);
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text: err,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    isListEmpty():boolean {
        const list = this.$$(`${this.idPrefix}DocumentsList`) as webix.ui.list;
        if(list) {
            const items = list.serialize();
            if(items.length > 0) {
                return false
            } else {
                return true
            }    
        }
    }

}