export default class ChatService {

    chatComponentId:string;
    chat:webix.ui.chat;

    constructor(chatComponentId:string) {
        this.chatComponentId = chatComponentId;
        this.chat = webix.$$(this.chatComponentId) as webix.ui.chat;
        this.init();
    }

    init():void {

    }

    getChats():Promise<any[]> {
        console.log("getting chats")
        return this.chat.getService("backend")
        .ready()
        .chats()
        .then(existingChats => {
            return existingChats
        });
    }

}