/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntranetProjectType {
    /**
     * Translation project with a Trans Only static workflow
     */
    TRANS_PROOF = 87,
    /**
     * Certified Translation project requiring certified translators
     */
    TRANS_PROOF_EDIT = 89,
    /**
     * Texterstellung für Landingpages auf Deutsch
     */
    TRANS_ONLY = 93,
    /**
     * Texterstellung für Landingpages in einer Fremdsprache
     */
    PROOF_ONLY = 95,
    /**
     * Von Kolibri gegebene Schulungen (SEO-Schulungen, etc.)
     */
    CERTIFIED_TRANSLATION = 2505,
    FOREIGN_EDITING = 10000011,
    GERMAN_EDITING = 10000014,
    KOLIBRI_INTERN = 10000098,
    CONTENT_DEUTSCH = 10000391,
    CONTENT_INT_ = 10000392,
    SCHULUNG = 10000808,
    TRANSCREATION = 10000813,
    MT = 10000994,
}
