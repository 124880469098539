import { JetView } from "webix-jet";

import "../server-error/server-error.style.css";

export default class NotFoundPage extends JetView {
  config(): webix.ui.scrollviewConfig {
    return {
      view: "scrollview",
      scroll: "y",
      body: {
        view: "layout",
        rows: [
          {
            view: "template",
            template: `
                <div class="server-error page-not-found">
                    <img src="/assets/images/error-404.png" alt="" />
                    <h1>Page Not Found</h1>
                      <a route="/main/projects.projects">Back</a>
              </div>
        `,
          },
        ],
      },
    };
  }
}
