import { UserProfile } from "./modules/cognovis-profile/profiles-types";

if (window._env_ == undefined) {
    window._env_ = require('../env.json');
} 

const serverUrl = window._env_.SERVER_URL;
const portalUrl = window._env_.PORTAL_URL;
const chatUrl = window._env_.CHAT_URL;
const logoUrl = window._env_.LOGO_URL;
const webixPackage = window._env_.WEBIX_PORTAL_PATH;
const lineItemEditMode = window._env_.LINE_ITEM_EDIT_MODE ? window._env_.LINE_ITEM_EDIT_MODE : 'inline';


export const config = {
    serverUrl: serverUrl,
    portalUrl: portalUrl,
    logoUrl: logoUrl,
    chatUrl:chatUrl,
    restUrl: serverUrl + "/cognovis-rest/",
    webixUrl: serverUrl + webixPackage,
    defaultContactAgainDays:2,
    sidebarSettings: {
        maximumProjectsOpened:3,
        maximumCompaniesOpened:1
    },
    linteItemEditMode:lineItemEditMode,
    newsletterUrls:[
        {
            language:"en_US",
            url:"https://www.kolibri.online/en/newsletter"
        },
        {
            language:"de_DE",
            url:"https://www.kolibri.online/newsletter"
        }
    ],
    maxUploadFileSize:150000000, //150 MB,
    paths:[    
        {
            objectType:`im_project`,
            url:`main/project-info.project-info?project_id=:objectId`,
            external:false
        },
        {
            objectType:`im_freelance_assignment`,
            url:`main/assignments.assignment-details?assignment_id=:objectId`,
            external:false
        },
        {
            objectType:`im_invoice`,
            url:`main/invoice-detail.invoice-detail?invoice_id=:objectId`,
            external:false
        },
        {
            objectType:`im_trans_invoice`,
            url:`main/invoice-detail.invoice-detail?invoice_id=:objectId`,
            external:false
        },
        {
            objectType:`user`,
            url:`main/users-overview.user-details?user_id=:objectId`,
            external:false
        },
        {
            objectType:"im_company",
            url:`main/companies-overview.company-details?company_id=:objectId`,
            external:false
        }
    ],
    newOfferFieldsSettings:[
        {
            role:UserProfile.PO_ADMIN,
            allFieldsVisible:true
        },
        {
            role:UserProfile.PROJECT_MANAGER,
            allFieldsVisible:true
        },
        {
            role:UserProfile.ACCOUNTING,
            allFieldsVisible:true
        },
        {
            role:UserProfile.EMPLOYEE,
            allFieldsVisible:true
        },
        {
            role:UserProfile.FREELANCER_MANAGER,
            allFieldsVisible:true
        },
        {
            role:UserProfile.SENIOR_MANAGER,
            allFieldsVisible:true
        },
        {
            role:UserProfile.CUSTOMER,
            visibleFields:["project_name", "project_type_id", "source_language_id", "target_language_ids", "description", "start_date", "deadline","additional_billing_info", "add_reference_file", "add_original_file", "submit_button"]
        }
    ]
};
