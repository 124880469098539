/* eslint-disable @typescript-eslint/no-explicit-any */
import { injectable } from "tsyringe";
import { config } from "../../config";

@injectable()
export class WebixHelpers {

    restUrl: string = config.restUrl;

    prepareDataForCombo(
        dataset: any[],
        idColumn: string,
        valueColumn: string,
        additionalKeys = []
    ): ICombobox[] {
        const newDatasetForCombo: ICombobox[] = [];
        let index = 0;
        dataset.forEach((entitiy: ICategory) => {
            newDatasetForCombo.push({
                id: entitiy[idColumn.toString()],
                value: entitiy[valueColumn.toString()],
            });
            additionalKeys.forEach((key) => {
                newDatasetForCombo[index.toString()][key.toString()] = entitiy[key.toString()];
            });
            index++;
        });

        return newDatasetForCombo;
    }

    getUrl(
        endpoint: string,
        additionlParams?: unknown,
        forceLoginInfo?: boolean
    ): string {
        let url =
            this.restUrl +
            endpoint +
            this.getUrlCredentials(forceLoginInfo);
        if (additionlParams) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const additionlParamsAsString = new URLSearchParams(
                additionlParams as any
            ).toString();
            url =
                this.restUrl +
                endpoint +
                this.getUrlCredentials(forceLoginInfo) +
                "&" +
                additionlParamsAsString;
        }
        return url;
    }

    getUrlWithApiKey(
        endpoint: string,
        additionlParams?: unknown,
    ): string {
        const bearerToken = webix.storage.local.get("cognovis_bearer_token");
        let url =
            this.restUrl +
            endpoint +
            "?api_key="+bearerToken
        if (additionlParams) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const additionlParamsAsString = new URLSearchParams(
                additionlParams as any
            ).toString();
            url =
                this.restUrl +
                endpoint +
                "&" +
                additionlParamsAsString;
        }
        return url;
    }

    getUrlCredentials(
        forceLoginInfo?: boolean,
        asObject?: boolean
    ): ICognovisCredentials | string {
        const loginInfo = webix.storage.local.get("wjet_user");
        const swaInfo = webix.storage.session.get("swa_user");
        // We need to prepare let in case we would later return object instead of string
        let urlWithCredentials = "";
        const credentialsAsObject = {
            format: "json",
            user_id: "",
            auto_login: "",
        };
        if (swaInfo && !forceLoginInfo) {
            urlWithCredentials =
                "?format=json&user_id=" +
                swaInfo.user_id +
                "&auto_login=" +
                swaInfo.token;
            credentialsAsObject.user_id = swaInfo.user_id;
            credentialsAsObject.auto_login = swaInfo.token;
        } else {
            if (loginInfo) {
                urlWithCredentials =
                    "?format=json&user_id=" +
                    loginInfo.user_id +
                    "&auto_login=" +
                    loginInfo.token;
                credentialsAsObject.user_id = loginInfo.user_id;
                credentialsAsObject.auto_login = loginInfo.token;
            }
        }
        if (asObject) {
            return credentialsAsObject;
        } else {
            return urlWithCredentials;
        }
    }

    formatDate(dateObj:Date):string {
        // adjust 0 before single digit date
        const date = ("0" + dateObj.getDate()).slice(-2);
        // current month
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        // current year
        const year = dateObj.getFullYear();
        // current hours
        const hours = dateObj.getHours();
        let hoursParsed = hours.toString();
        if(hours < 10) {
            hoursParsed = `0${hours}`;
        }
        // current minutes
        const minutes = dateObj.getMinutes();
        let minutesParsed = minutes.toString();
        if(minutes < 10) {
            minutesParsed = `0${minutes}`
        }
        // current seconds
        const seconds = dateObj.getSeconds();
        let secondsParsed = seconds.toString();
        if(seconds < 10) {
            secondsParsed = `0${seconds}`
        }
        // prints date & time in YYYY-MM-DD HH:MM:SS format
        const finalDate = year + "-" + month + "-" + date + " " + hoursParsed + ":" + minutesParsed + ":" + secondsParsed;
        return finalDate;
    }

    convertDurationToHoursDecimal(time:string):number {
        let hours:number = 0;
        let minutes:number = 0;
        // First we remove any witespaces 
        const noWhitespaces = time.replace(/ /g, '');
        if(noWhitespaces.indexOf("h") > -1) {
            const hoursSplitted = noWhitespaces.split('h');
            hours = Number(hoursSplitted[0]);
            if(hoursSplitted[1] && hoursSplitted[1].indexOf("m") > -1) {
                const minutesSplitted = hoursSplitted[1].split("m");
                minutes = Number(minutesSplitted[0]);
            }
        } else {
            if(noWhitespaces.indexOf("m") > -1) {
                const onlyMinutesSplitted = noWhitespaces.split("m");
                if(onlyMinutesSplitted) {
                    minutes = Number(onlyMinutesSplitted[0])
                }
            }
        }
        const hoursDecimal = ((hours*60) + minutes) / 60;
        return hoursDecimal;
    }

    convertHoursDecimalToDuration(time:string):string {
        const date = new Date(0,0);
        date.setMinutes(Math.round(+time * 60));
        const timeString = date.toTimeString().slice(0, 5);
        const splitted = timeString.split(":");
        const hours = Math.floor(Number(splitted[0]));
        const minutes = Math.floor(Number(splitted[1]));
        let result;
        if(minutes > 0) {
            result = `${hours}h ${minutes}m`;
        } else {
            result = `${hours}h`;
        }
        return result;
    }

    getHighestOccurance(arr:string[]):string {
        return arr.sort((a,b) =>
        arr.filter(v => v===a).length
        - arr.filter(v => v===b).length
        )
        .pop();
    }

    isValueInsideMulticombo(combo:webix.ui.multicombo, value:number):boolean {
        const popup = combo.getPopup() as webix.ui.popup;
        const list = popup.queryView({view:"list"}) as webix.ui.list;
        const comboOptions = list.serialize();
        return comboOptions.filter(comboOption => comboOption.id === value).length > 0
    }

    updateQueryStringParameter(uri:string, key:string, value:string):string {
        // eslint-disable-next-line security/detect-non-literal-regexp
        const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        const separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
          return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
          return uri + separator + key + "=" + value;
        }
      }

    addParamToCurrentUrl(paramName:string, paramValue:string):void {
        const newUrl = this.updateQueryStringParameter(window.location.href,paramName, paramValue);
        window.history.pushState("", "", newUrl);
    }

}
