import * as chat from "@xbs/chat";
import { promise } from "@xbs/webix-pro";
import { ITransProject, IUser, WebixPortalProjectService } from "../../openapi";

// This is backend for project chat
export default class ProjectChatBackend extends chat.services.Backend {
    
    idPrefix:"cc";
    currentChatUsers:IChatUser[] = [];
    currentProject?:ITransProject;
    projectId:number;

    users():Promise<IChatUser[]> {
        const chat = webix.$$(`ccChat`);
        this.currentProject = chat.config["currentProject"] as ITransProject;
        return WebixPortalProjectService.getProjectMembers({
            projectId:Number(this.currentProject.project.id)
        })
        .then(projectMembers => {
            const users = projectMembers.map(member => {
                if(member?.member?.user) {
                    return {
                        avatar: member.member.portrait_url,
                        email: member.member.user.name,
                        id: member.member.user.id,
                        name: member.member.user.name,
                        status: 1
                    }
                }
            });
            this.currentChatUsers = users;
            return promise.resolve(this.currentChatUsers);
        });
    }

    getUserActivityStatus(user:IUser):number {
        const twentyMinutes = 1200*20;
        if(user.seconds_since_last_request && user.seconds_since_last_request <= twentyMinutes) {
            return 2
        } else {
            return 1
        }
    }

    chats():void {
        const chat = webix.$$(`ccChat`);
        this.currentProject = chat.config["currentProject"] as ITransProject;
        if(this.currentProject?.project_nr) {
            return super.chats()
            .then(existingChats => {
                const isChatAlreadyCreated = existingChats.find(chat => chat.name === this.currentProject?.project_nr);
                if(!isChatAlreadyCreated) {
                    return WebixPortalProjectService.getProjectMembers({projectId:this.currentProject?.project?.id})
                    .then(projectMemebers => {
                        const projectMembersIds = projectMemebers.map(member => member.member.user.id);
                        return super.addGroupChat(this.currentProject?.project_nr, "",  projectMembersIds)
                        .then(res => {
                            // Add first message
                            return super.addMessage(res.id, this.prepareAutomaticHelloMessage(this.currentProject), 1)
                            .then(() => {
                                existingChats.push(res);
                                return promise.resolve(existingChats.filter(existingChat => existingChat.name === this.currentProject?.project_nr));
                            })
                        });
                    });
                } else {
                    return promise.resolve(existingChats.filter(existingChat => existingChat.name === this.currentProject?.project_nr))
                }
            });
        } else {
            return super.chats()
            .then(chats => {
                return promise.resolve(chats);
            })
        }
    }

    prepareAutomaticHelloMessage(project:ITransProject):string {
        return `Hello!\nMy name is ${project.project_lead.user.name} and I'm Project Manager of ${project.project_nr}`;
    }

}
 